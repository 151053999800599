import React from 'react';
import { useHistory } from 'react-router-dom';
import FicheThematiqueNoticeButton from '../../../components/FicheThematiqueNoticeButton/FicheThematiqueNoticeButton';
import { lienFicheThematiqueDMTG } from '../../../routes';

const FicheThematique = () => {
    const history = useHistory();

    const handleClick = () => {
        history.push(lienFicheThematiqueDMTG);
    }

    return (
        <FicheThematiqueNoticeButton
            style={{ marginRight: 18 }}
            onRequestClick={handleClick}
        />
    )

};

export default FicheThematique;