import { conditionAffichageEnfant, nombreEnfantsParConjoint } from "../../../views/Cession/CessionHaut/ProjectionCessionSociete/TableauSynthese/TableauSynthese";
import { blackColor, numberFormat } from "../../all_ppt_render";
import { currencyToSend } from '../../../views/DonationSuccession/UsufruitNP/UsufruitViager/UsufruitViager'

export const formattedCurrency = (currency) => {
    const value = currencyToSend(currency);
    return `${Math.abs(value) > 0 ? numberFormat(value) : '-'} €`;
};

export const calculPlusValueBrute = (valeurTitres, prixRevientFiscal) => {
    const val = (currencyToSend(valeurTitres) || 0) - (currencyToSend(prixRevientFiscal) || 0);

    return `${val > 0 ? numberFormat(val) : '-'} €`;
};

export const formatPercent = (pourcentage) => {
    const value = parseFloat(pourcentage) || 0;

    return `${[0, 0.00].includes(value) ? '-' : value} %`;
};

const hasValue = (pourcentageDetention, valeurTitres, prixRevientFiscal, plusValueBrute) => {
    return !(pourcentageDetention === '- %' && valeurTitres === '- €' && prixRevientFiscal === '- €' && plusValueBrute === '- €');
};

const generateTableauSynthese = (cession) => {
    const { identiteActionnaire: identite, projetCessionSociete: projet, operationsAvantCession: operations } = cession;
    const { conjoints, enfants, affichageNomPersonnalise: affichage } = identite;
    const { conjoints: conjointsProjet, total, enfantsFamiliauxPP, enfantsFamiliauxDP, holding } = projet;
    const { pp, np1, np2, somme } = total;
    const borderValue = 2;
    const headerTextProps = { 
        bold: true,
        color: blackColor,
        fill: 'FFFFFF',
        border: [
            {
                pt: 0,
                color: ''
            },
            {
                pt: borderValue,
                color: 'FFFFFF'
            },
            {
                pt: borderValue,
                color: '809DB4'
            },
            {
                pt: 0,
                color: ''
            }
        ]
    }

    const tableProps = (align) => {
        return {
            align: align,
            fontSize: 11,
            fontFace: 'Roboto',
            valign: 'middle',
            color: blackColor,
            margin: align === 'left' ? [ 0, 0, 0, 5 ] : [ 0, 15, 0, 0 ],
            border: [
                {
                    pt: 0,
                    color: ''
                },
                {
                    pt: borderValue,
                    color: 'FFFFFF'
                },
                {
                    pt: borderValue,
                    color: 'FFFFFF'
                },
                {
                    pt: 0,
                    color: ''
                },
            ]
        };
    };

    const totalValueProps = {
        fill: 'F3F3F3'
    }

    const rows = [
        [
            { 
                text: "Actionnaires familiaux", 
                options: { 
                    ...tableProps('left'),
                    ...headerTextProps
                }
            },
            { 
                text: "Répartition en %", 
                options: { 
                    ...tableProps('center'),
                    ...headerTextProps
                }
            },
            { 
                text: "Valeur des titres", 
                options: { 
                    ...tableProps('center'),
                    ...headerTextProps
                }
            },
            { 
                text: "Prix de revient fiscal", 
                options: { 
                    ...tableProps('center'),
                    ...headerTextProps
                }
            },
            { 
                text: "Plus-value brute", 
                options: { 
                    ...tableProps('center'),
                    ...headerTextProps
                }
            }
        ]
    ];

    // Population des conjoints
    conjoints.map((data, index) => {
        if(index === 0 || (index === 1 && conjoints[0].marieOuPasce === 'Oui')){
            let row = [];
            let nom = `${ affichage ? `${ data.nomPersonnalise !== "" ? data.nomPersonnalise : `Conjoint ${index + 1}` }` : `Conjoint ${index + 1}` } PP`;
            let pourcentageDetention = `${formatPercent(conjointsProjet[index].pourcentageDetention)}`;
            let valeurTitres = `${formattedCurrency(conjointsProjet[index].valeurTitres)}`;
            let prixRevientFiscal = `${formattedCurrency(conjointsProjet[index].prixRevientFiscal)}`;
            let plusValueBrute = `${calculPlusValueBrute(conjointsProjet[index].valeurTitres, conjointsProjet[index].prixRevientFiscal)}`;

            if(hasValue(
                pourcentageDetention,
                valeurTitres,
                prixRevientFiscal,
                plusValueBrute
            )){
                row.push(
                    { text: nom, options: { ...tableProps('left'), ...totalValueProps, bold: true } },
                    { text: pourcentageDetention, options: { ...tableProps('right'),  ...totalValueProps, bold: true} },
                    { text: valeurTitres, options: { ...tableProps('right'),  ...totalValueProps, bold: true } },
                    { text: prixRevientFiscal, options: { ...tableProps('right'),  ...totalValueProps, bold: true} },
                    { text: plusValueBrute, options: { ...tableProps('right'), ...totalValueProps, bold: true}},
                );
            }

            // Il faut tester si le tableau contient au moins une ligne
            if(row.length > 0)
                rows.push(row);
        }
    });

    // Population par la somme des enfants PP
    if(enfants.length > 1){
        let nom = 'Enfants PP';
        let pourcentageDetention = `${formatPercent(pp.pourcentageDetention)}`;
        let valeurTitres = `${formattedCurrency(pp.valeurTitres)}`;
        let prixRevientFiscal = `${formattedCurrency(pp.prixRevientFiscal)}`;
        let plusValueBrute = `${calculPlusValueBrute(pp.valeurTitres, pp.prixRevientFiscal)}`;

        if(hasValue(
            pourcentageDetention,
            valeurTitres,
            prixRevientFiscal,
            plusValueBrute
        )){
            rows.push([
                { text: nom, options: { ...tableProps('left'), bold: true, ...totalValueProps } },
                { text: pourcentageDetention, options: { ...tableProps('right'), bold: true, ...totalValueProps } },
                { text: valeurTitres, options: { ...tableProps('right'), bold: true, ...totalValueProps } },
                { text: prixRevientFiscal, options: { ...tableProps('right'), bold: true, ...totalValueProps } },
                { text: plusValueBrute, options: {...tableProps('right'), bold: true, ...totalValueProps }}
            ]);
        }
    }

    // Population par les Enfants PP
    enfants.map((data, index) => {
        let nom = enfants.length > 1 ? `   ${ affichage ? data.prenom !== "" ? data.prenom : `Enfant ${index + 1}` : `Enfant ${index + 1}` } PP` : `${ affichage ? data.prenom !== "" ? data.prenom : `Enfant ${index + 1}` : `Enfant ${index + 1}` } PP`;
        let pourcentageDetention = formatPercent(enfantsFamiliauxPP[index].pourcentageDetention);
        let valeurTitres = formattedCurrency(enfantsFamiliauxPP[index].valeurTitres);
        let prixRevientFiscal = formattedCurrency(enfantsFamiliauxPP[index].prixRevientFiscal);
        let plusValueBrute = calculPlusValueBrute(enfantsFamiliauxPP[index].valeurTitres, enfantsFamiliauxPP[index].prixRevientFiscal);
        let bold = enfants.length <= 1;
        let fill = enfants.length <= 1 ? totalValueProps.fill : 'FFFFFF';

        if(hasValue(
            pourcentageDetention,
            valeurTitres,
            prixRevientFiscal,
            plusValueBrute
        )){
            rows.push([
                { text: nom, options: { ...tableProps('left'), bold: bold, fill: fill }},
                { text: pourcentageDetention, options: { ...tableProps('right'), bold: bold, fill: fill } },
                { text: valeurTitres, options: { ...tableProps('right'), bold: bold, fill: fill } },
                { text: prixRevientFiscal, options: { ...tableProps('right'), bold: bold, fill: fill } },
                { text: plusValueBrute, options: { ...tableProps('right'), bold: bold, fill: fill }},
            ]);
        }
    });

    // Population par la somme des Conjoints USUF ou les enfants USUF
    conjointsProjet.map((donnee, indice) => {
        if(indice === 0 || (indice === 1 && conjoints[0].marieOuPasce === 'Oui')){
            let sommeNP = indice === 0 ? np1 : np2;

            let nb = nombreEnfantsParConjoint(enfants, indice);
            if(nb > 1){
                let nomS = `${ affichage ? conjoints[indice].nomPersonnalise !== "" ? conjoints[indice].nomPersonnalise : `Conjoint ${indice + 1}` : `Conjoint ${indice + 1}` } USUF / Enfants NP`;
                let pourcentageDetentionS = `${formatPercent(sommeNP.pourcentageDetention)}`;
                let valeurTitresS = `${formattedCurrency(sommeNP.valeurTitres)}`;
                let prixRevientFiscalS = `${formattedCurrency(sommeNP.prixRevientFiscal)}`;
                let plusValueBruteS = `${calculPlusValueBrute(sommeNP.valeurTitres, sommeNP.prixRevientFiscal)}`;

                if(hasValue(
                    pourcentageDetentionS,
                    valeurTitresS,
                    prixRevientFiscalS,
                    plusValueBruteS
                )){
                    rows.push([
                        { text: nomS, options: { ...tableProps('left'), bold: true, ...totalValueProps } },
                        { text: pourcentageDetentionS, options: { ...tableProps('right'), bold: true, ...totalValueProps } },
                        { text: valeurTitresS, options: { ...tableProps('right'), bold: true, ...totalValueProps } },
                        { text: prixRevientFiscalS, options: { ...tableProps('right'), bold: true, ...totalValueProps } },
                        { text: plusValueBruteS, options: { ...tableProps('right'), bold: true, ...totalValueProps }},
                    ]);
                }
            }

            enfantsFamiliauxDP[indice].enfants.map((data, index) => {
                let condition = conditionAffichageEnfant(enfants, indice, index);
                let nb = nombreEnfantsParConjoint(enfants, indice);
                
                if(condition){
                    let actFam = `${ affichage ? conjoints[indice].nomPersonnalise !== "" ? conjoints[indice].nomPersonnalise : `Conjoint ${indice + 1}` : `Conjoint ${indice + 1}` } USUF / ${ affichage ? enfants[index].prenom !== "" ? enfants[index].prenom : `Enfant ${index + 1}` : `Enfant ${index + 1}` } NP`;
                    let nom = nb > 1 ? `   ${actFam}` : actFam;
                    let pourcentageDetention = `${formatPercent(data.pourcentageDetention)}`;
                    let valeurTitres = `${formattedCurrency(data.valeurTitres)}`;
                    let prixRevientFiscal = `${formattedCurrency(data.prixRevientFiscal)}`;
                    let plusValueBrute = `${calculPlusValueBrute(data.valeurTitres, data.prixRevientFiscal)}`;
                    let bold = nb <= 1;
                    let fill = nb <= 1 ? totalValueProps.fill : 'FFFFFF';

                    if(hasValue(
                        pourcentageDetention,
                        valeurTitres,
                        prixRevientFiscal,
                        plusValueBrute
                    )){
                        rows.push([
                            { text: nom, options: { ...tableProps('left'), bold: bold, fill: fill } },
                            { text: pourcentageDetention, options: { ...tableProps('right'), bold: bold, fill: fill } },
                            { text: valeurTitres, options: { ...tableProps('right'), bold: bold, fill: fill } },
                            { text: prixRevientFiscal, options: { ...tableProps('right'), bold: bold, fill: fill } },
                            { text: plusValueBrute, options: {...tableProps('right'), bold: bold, fill: fill }},
                        ]);
                    }
                }
            });
        }
    });

    // Population par le HOLDING
    let nom = affichage ? holding.nomPersonnalise !== "" ? holding.nomPersonnalise : 'HOLDING IS' : `HOLDING IS`;
    let pourcentageDetention = `${formatPercent(holding.pourcentageDetention)}`;
    let valeurTitres = `${formattedCurrency(holding.valeurTitres)}`;
    let prixRevientFiscal = `${formattedCurrency(holding.prixRevientFiscal)}`;
    let plusValueBrute = `${calculPlusValueBrute(holding.valeurTitres, holding.prixRevientFiscal)}`;

    if(hasValue(
        pourcentageDetention,
        valeurTitres,
        prixRevientFiscal,
        plusValueBrute
    )){
        rows.push([
            { text: nom, options: { ...tableProps('left'), bold: true, ...totalValueProps } },
            { text: pourcentageDetention, options: { ...tableProps('right'), bold: true, ...totalValueProps } },
            { text: valeurTitres, options: { ...tableProps('right'), bold: true, ...totalValueProps } },
            { text: prixRevientFiscal, options: { ...tableProps('right'), bold: true, ...totalValueProps } },
            { text: plusValueBrute, options: { ...tableProps('right'), bold: true, ...totalValueProps }}
        ]);
    }

    // Population par le TOTAL
    let nomTotal = "";
    let totalPourcentageDetention = formatPercent(somme && somme.pourcentageDetention ? somme.pourcentageDetention : 0);
    let totalValeurTitres = formattedCurrency(somme && somme.valeurTitres ? somme.valeurTitres : 0);
    let totalPrixRevientFiscal = formattedCurrency(somme && somme.prixRevientFiscal ? somme.prixRevientFiscal : 0);
    let totalPlusValueBrute = calculPlusValueBrute(somme && somme.valeurTitres ? somme.valeurTitres : 0, somme && somme.prixRevientFiscal ? somme.prixRevientFiscal : 0);

    if(hasValue(
        totalPourcentageDetention,
        totalValeurTitres,
        totalPrixRevientFiscal,
        totalPlusValueBrute
    )){
        rows.push([
            { text: nomTotal, options: { ...tableProps('left')}},
            { text: totalPourcentageDetention, options: { ...tableProps('right'), bold: true, color: 'FFFFFF', fill: '404040' }},
            { text: totalValeurTitres, options: { ...tableProps('right'), bold: true, color: 'FFFFFF', fill: '404040'}},
            { text: totalPrixRevientFiscal, options: { ...tableProps('right'), bold: true, color: 'FFFFFF', fill: '404040' }},
            { text: totalPlusValueBrute, options: { ...tableProps('right'),bold: true,color: 'FFFFFF',fill: '404040' }},
        ]);
    }

    return rows;
};

export default generateTableauSynthese;