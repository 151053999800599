export const updateSuccession = (value) => {
    return {
        type: "UPDATE_SUCCESSION",
        payload: value
    }
}

export const updateSuccessionClient = (value) => {
    return {
        type: "UPDATE_SUCCESSION_CLIENT",
        payload: value
    }
}

export const updateSuccessionHeritier = (value) => {
    return {
        type: "UPDATE_SUCCESSION_HERITIER",
        payload: value
    }
}


export const updateSuccessionConfig = (value) => {
    return {
        type: "UPDATE_SUCCESSION_CONFIG",
        payload: value
    }
}

export const fetchClientList = (value) => {
    return {
        type: "POPULATE_CLIENTS",
        payload: value
    }
}

export const updateFiscaliteRevenus = (value) => {
    return {
        type: "UPDATE_FISCAlITE_REVENUS",
        payload: value
    }
}
