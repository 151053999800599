import { emptyRowFichePVImmo, rowPropsFichePVImmo, tableMarginFichePVImmo, titleValueStyleFichePVImmo } from "./tableauRevenuFoncier";

// Prix de cession
const texteValueLigne1 = [
    { text: "Le prix de cession est le prix mentionné dans l’acte de vente.", options: { breakLine: true } },
    { text: "", options: { breakLine: true } },
    { text: "Il est diminué des frais supportés par le vendeur pour la vente (diagnostics immobiliers, frais d’intermédiaires …)." },
]

// Prix d'acquisition
const texteLabelLigne2 = [
    { text: "-", options: { breakLine: true } },
    { text: "", options: { breakLine: true } },
    { text: "Prix d'acquisition"},
]

const texteValueLigne2 = [
    { text: "Le prix d’acquisition est le prix mentionné dans l’acte d’acquisition.", options: { breakLine: true } },
    { text: "", options: { breakLine: true } },
    { text: "Il est majoré :", options: { breakLine: true } },
    { text: "des frais d’acquisition : frais réels, ou forfait de 7,5% du prix d’acquisition (uniquement si l’acquisition était à titre onéreux)", options: { breakLine: true, bullet: { code: '25CF', color: '000000' } } },
    { text: "", options: { breakLine: true } },
    { text: "des travaux éligibles : frais réels, ou forfait de 15% (uniquement si le bien a été détenu plus de 5 ans)", options: { breakLine: true, bullet: { code: '25CF', color: '000000' } } },
]

// PV Brute
const texteLabelLigne3 = [
    { text: "=", options: { breakLine: true } },
    { text: "", options: { breakLine: true } },
    { text: "Plus-value brute"},
]

// Abattement
const texteLabelLigne4 = [
    { text: "-", options: { breakLine: true, bold: true } },
    { text: "Abattement", options: { breakLine: true, bold: true } },
    { text: "Durée de détention", options: { bold: false } }
]

const texteValueLigne4 = [
    { text: "La plus-value brute est diminuée d’abattements pour durée de détention." ,options: { breakLine: true } },
    { text: "", options: { breakLine: true } },
    { text: "La plus-value est ainsi ", options: { breakLine: false } },
    { text: "exonérée ", options: { breakLine: false, bold: true } },
    { text: "d’impôt sur le revenu après un délai de détention de 22 ans, et de prélèvements sociaux après un délai de détention de 30 ans." }
]

// PV Nette imposable
const texteLabelLigne5 = [
    { text: "=", options: { breakLine: true, bold: true } },
    { text: "", options: { breakLine: true, bold: true } },
    { text: "Plus-value nette imposable", options: { bold: false } }
]

const texteValueLigne5 = [
    { text: "La plus-value nette après abattement au titre de l’impôt sur le revenu est soumise à une ", options: { breakLine: false } },
    { text: "imposition de 19%", options: { breakLine: true, bold: true } },
    { text: "", options: { breakLine: true } },
    { text: "La plus-value nette après abattement au titre des prélèvements sociaux est soumise aux ", options: { breakLine: false } },
    { text: "prélèvements sociaux (17,2%).", options: { bold: true } },
]

export const pvParticulierData = [
    [
        { text: "Prix de cession", options: { ...titleValueStyleFichePVImmo } },
        { text: "", options: { fill: 'FFFFFF' } },
        { text: texteValueLigne1, options: { ...rowPropsFichePVImmo, margin: tableMarginFichePVImmo } }
    ],
    [ ...emptyRowFichePVImmo ],
    [
        { text: texteLabelLigne2, options: { ...titleValueStyleFichePVImmo } },
        { text: "", options: { fill: 'FFFFFF' } },
        { text: texteValueLigne2, options: { ...rowPropsFichePVImmo, margin: tableMarginFichePVImmo } }
    ],
    [ ...emptyRowFichePVImmo ],
    [
        { text: texteLabelLigne3, options: { ...titleValueStyleFichePVImmo } },
        { text: "", options: { fill: 'FFFFFF' } },
        { text: "", options: { ...rowPropsFichePVImmo, margin: tableMarginFichePVImmo, fill: 'FFFFFF' } }
    ],
    [ ...emptyRowFichePVImmo ],
    [
        { text: texteLabelLigne4, options: { ...titleValueStyleFichePVImmo } },
        { text: "", options: { fill: 'FFFFFF' } },
        { text: texteValueLigne4, options: { ...rowPropsFichePVImmo, margin: tableMarginFichePVImmo } }
    ],
    [ ...emptyRowFichePVImmo ],
    [
        { text: texteLabelLigne5, options: { ...titleValueStyleFichePVImmo } },
        { text: "", options: { fill: 'FFFFFF' } },
        { text: texteValueLigne5, options: { ...rowPropsFichePVImmo, margin: tableMarginFichePVImmo } }
    ]
]