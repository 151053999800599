import moment from "moment";
import { isCompleteDate } from "../../components/input/InputDate";
import { restApiUri } from "../../config";
import { formatDateEmp } from "./Emprunt/Emprunt";

const emprunt_uri = `${restApiUri}/api/emprunt`;
const usuf_eco_uri = `${restApiUri}/api/usufruit`;

export const cloneEmprunt = (data) => {
    const temp_date_emprunt =  moment(data.date_emprunt).format("DD/MM/YYYY");
    const temp_annee_impos = moment(data.annee_imposition).format("YYYY");
    delete data["date_emprunt"];
    delete data["annee_imposition"];
    const dataToSend = {
        // date_simulation: moment(new Date()).format("DD-MM-YYYY HH:mm"),
        date_emprunt: temp_date_emprunt,
        annee_imposition: temp_annee_impos,
        userId: JSON.parse(localStorage.getItem("user"))["id"],
        clientId: data.client && data.client.id ? data.client.id : null,
        ...data,

    };

    delete dataToSend['client']
    return dataToSend
}

export const constructEmprunt = (data) => {
    const temp_date_emprunt = ![null, undefined, ""].includes(data.date_emprunt) ? moment(new Date(data.date_emprunt)).format("DD/MM/YYYY") : "";
    const temp_annee_impos = moment(data.annee_imposition).format("YYYY");
    delete data["date_emprunt"];
    delete data["annee_imposition"];
    const dataToSend = {
        // date_simulation: moment(new Date()).format("DD-MM-YYYY HH:mm"),
        date_emprunt: temp_date_emprunt,
        annee_imposition: temp_annee_impos,
        userId: JSON.parse(localStorage.getItem("user"))["id"],
        clientId: data.client && data.client.id ? data.client.id : null,
        ...data,

    };
    return dataToSend;
}

export const saveEmprunt = async (emprunt, id=undefined) => {
    const alternativeEmprunt = { ...emprunt };
    const dateData = formatDateEmp(alternativeEmprunt.date_emprunt);
    const annee = alternativeEmprunt.annee_imposition;

    alternativeEmprunt.date_emprunt = dateData;
    alternativeEmprunt.annee_imposition = `${annee}-01-01`;
    const url = id ? `${emprunt_uri}/${id}` : emprunt_uri;
    const query = await fetch(url, {
        method: id ? 'PUT' : 'POST',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${localStorage.getItem("token")}`
        },
        body: JSON.stringify(alternativeEmprunt),
        cache: 'no-cache'
    })
    return query.json();
}

export const getEmprunt = async (id=undefined) => {
    if(!id) {
        return;
    }
    const query = await fetch(`${emprunt_uri}/findOne/${id}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${localStorage.getItem("token")}`
        },
    })

    return query.json();
}

export const delEmprunt = async (id) => {
console.log("🚀 ~ file: ImmoDataControl.js ~ line 64 ~ delEmprunt ~ id", id)
    const query = await fetch(`${emprunt_uri}/${id}`, {
        method: "DELETE",
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          //Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      });
    return query.json();
}

export const cloneUsuf = (data) => {
    const dataToSend = {
        userId: JSON.parse(localStorage.getItem("user"))["id"],
        clientId: data.client && data.client.id ? data.client.id : null,
        ...data,
    };

    delete dataToSend['client']
    return dataToSend
}

export const constructUsuf = (data) => {
    const resp_data = {
        idSimulation: data.id,
        "duree_demembrement": data.duree_demembrement,
        "valeur_pp": data.valeur_pp,
        "taux_redenement_1er_annee": data.taux_redenement_1er_annee,
        "taux_valo_revenu": data.taux_valo_revenu,
        "taux_valo_bien": data.taux_valo_bien
    }

    return resp_data;
}

export const saveUsufEco = async (data, id = undefined) => {
    const uri = id ? `${usuf_eco_uri}/UsufruitEco/${id}` : `${usuf_eco_uri}/addUsufruitEco`;
    const query = await fetch(uri, {
        method: id ? 'PUT' : 'POST',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${localStorage.getItem("token")}`
        },
        body: JSON.stringify(data),
        cache: 'no-cache'
    })

    return query;
}

export const getUsufEco = async ( id = undefined ) => {
    if(!id) {
        return ;
    }

    const query = await fetch(`${usuf_eco_uri}/findOneUsufruitEco/${id}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${localStorage.getItem("token")}`
        },
    })

    return query;
}

export const delUsufEco = async (id) => {
    const query = await fetch(`${usuf_eco_uri}/UsufruitEco/${id}`, {
        method: "DELETE",
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          //Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      });
    return query.json();
}
