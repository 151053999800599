import { numberFormat } from "../../all_ppt_render";

const generateGraph = (
    pptx, 
    slide, 
    x, 
    y, 
    w, 
    h, 
    label1, 
    label2, 
    value1, 
    value2, 
    color1, 
    color2, 
    title, 
    realValue1, 
    realValue2,
    type
) => {
    const data = [
        {
            name: label1,
            labels: [label1, `${value1} %`, `${numberFormat(realValue1)} €`, label2, `${value2} %`, `${numberFormat(realValue2)} €`],
            values: [value1, 0, 0, value2, 0, 0]
        },
    ];

    const colorNoLegend = 'F2F2F2';

    slide.addChart(pptx.ChartType.pie, data, {
        x: x,
        y: y,
        w: w,
        h: h,
        showLegend: true,
        showPercent: false,
        showTitle: true,
        title: title,
        chartColors: [color1, colorNoLegend, colorNoLegend, color2, colorNoLegend, colorNoLegend],
        legendFontFace: 'Roboto',
        legendFontSize: 10,
        titleFontSize: 14,
        titleFontFace: 'Roboto',
        titleFontBold: true
        
    });
};

export default generateGraph;