import React from 'react'
import Information from './../../../../../components/Information/Information';

const Info = ({title, content, style}) => {
    return (
        <Information
            title={title}
            text={content}
            style={style}
        />
    )
}

export default Info;