import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        //width: '100%'
    },

    child: {
        width: '20%',
        marginRight: '1%'
    }
});

export default useStyles;