import { rowPropsFichePVImmo, tableMarginFichePVImmo } from "./tableauRevenuFoncier"

const border = [
    {
        pt: 1,
        color: 'FFFFFF'
    },
    {
        pt: 1,
        color: 'FFFFFF'
    },
    {
        pt: 1,
        color: 'FFFFFF'
    },
    {
        pt: 1,
        color: 'FFFFFF'
    }
]

const abattementRow = (value1, value2, value3) => {
    return [
        { text: value1, options: { ...rowPropsFichePVImmo, align: 'center', border: [...border], margin: tableMarginFichePVImmo } },
        { text: value2, options: { ...rowPropsFichePVImmo, align: 'center', border: [...border], margin: tableMarginFichePVImmo } },
        { text: value3, options: { ...rowPropsFichePVImmo, align: 'center', border: [...border], margin: tableMarginFichePVImmo } },
    ]
}

export const tableauAbattement1 = [
    [
        { text: "Délai de détention", options: { color: 'FFFFFF', bold: true, align: 'center', fill: '8497B0', border: [...border], margin: tableMarginFichePVImmo } },
        { text: "IR", options: { color: 'FFFFFF', bold: true, align: 'center', fill: '8497B0', border: [...border], margin: tableMarginFichePVImmo } },
        { text: "PS", options: { color: 'FFFFFF', bold: true, align: 'center', fill: '8497B0', border: [...border], margin: tableMarginFichePVImmo } }
    ],
    [ ...abattementRow("Jusqu’à la 5e année", "0 %", "0 %") ],
    [ ...abattementRow("6e année", "6 %", "1,65 %") ],
    [ ...abattementRow("7e année", "12 %", "3,3 %") ],
    [ ...abattementRow("8e année", "18 %", "4,95 %") ],
    [ ...abattementRow("9e année", "24 %", "6,6 %") ],
    [ ...abattementRow("10e année", "30 %", "8,25 %") ],
    [ ...abattementRow("11e année", "36 %", "9,9 %") ],
    [ ...abattementRow("12e année", "42 %", "11,55 %") ],
    [ ...abattementRow("13e année", "48 %", "13,2 %") ],
    [ ...abattementRow("14e année", "54 %", "14,85 %") ],
    [ ...abattementRow("15e année", "60 %", "16,5 %") ],
    [ ...abattementRow("16e année", "66 %", "18,15 %") ],
    [ ...abattementRow("17e année", "72 %", "19,8 %") ]
]

export const tableauAbattement2 = [
    [
        { text: "Délai de détention", options: { color: 'FFFFFF', bold: true, align: 'center', fill: '8497B0', border: [...border], margin: tableMarginFichePVImmo } },
        { text: "IR", options: { color: 'FFFFFF', bold: true, align: 'center', fill: '8497B0', border: [...border], margin: tableMarginFichePVImmo } },
        { text: "PS", options: { color: 'FFFFFF', bold: true, align: 'center', fill: '8497B0', border: [...border], margin: tableMarginFichePVImmo } }
    ],
    [ ...abattementRow("18e année", "78 %", "21,45 %") ],
    [ ...abattementRow("19e année", "84 %", "23,1 %") ],
    [ ...abattementRow("20e année", "90 %", "24,75 %") ],
    [ ...abattementRow("21e année", "96 %", "26,4 %") ],
    [ ...abattementRow("22e année", "100 %", "28 %") ],
    [ ...abattementRow("23e année", "100 %", "37 %") ],
    [ ...abattementRow("24e année", "100 %", "46 %") ],
    [ ...abattementRow("25e année", "100 %", "55 %") ],
    [ ...abattementRow("26e année", "100 %", "64 %") ],
    [ ...abattementRow("27e année", "100 %", "73 %") ],
    [ ...abattementRow("28e année", "100 %", "82 %") ],
    [ ...abattementRow("29e année", "100 %", "91 %") ],
    [ ...abattementRow("30e année", "100 %", "100 %") ],
]