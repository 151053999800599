const tableauParPage = (tableau, nbLigneParPage) => {
    let tabs = [];
    tabs[0] = new Array();
    tabs[1] = new Array();

    let realData = [];

    for(let i=0; i<tableau.length; i++){
        if(i <= (nbLigneParPage - 1)){
            tabs[0] = [
                ...tabs[0],
                tableau[i]
            ]
        }
        else{
            tabs[1] = [
                ...tabs[1],
                tableau[i]
            ];
        }
    }

    if(tabs.length > 1){
        const lastLine = tabs[0][tabs[0].length - 1];
        /*lastLine.map((data, index) => {
            tabs[0][tabs[0].length - 1][index]['options']['border'][2] = {
                pt: 1,
                color: '808080'
            };
        });*/
    }

    realData = tabs;

    if(realData[1].length > 0){
        realData[1].unshift(tableau[0]);
    }


    return realData;
};

export default tableauParPage;