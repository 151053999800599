import { blackColor } from "../../all_ppt_render";

const lastLabel = (scenario) => {
    if(scenario === 1){
        return [
            { text: "30 %", options: { bold: true, fontSize: 19 } }
        ];
    }
    else if(scenario === 2){
        return [
            { text: "17.20 % jusqu’à", options: { breakLine: true, fontSize: 15 } },
            { text: "500.000 € puis ", options: { breakLine: true, fontSize: 15 } },
            { text: "30 %", options: { fontSize: 20, bold: true } }
        ];
    }
    else if(scenario === 3){
        return [
            { text: "17.20 % jusqu’à", options: { breakLine: true, fontSize: 15 } },
            { text: "500.000 € puis ", options: { breakLine: true, fontSize: 15 } },
            { text: "62.2 %", options: { fontSize: 20, bold: true, breakLine: true } },
            { text: "avec TMI 45%" }
        ];
    }
    else if(scenario === 4){
        return [
            { text: "23.95 %", options: { fontSize: 20, bold: true, breakLine: true } },
            { text: "avec TMI 45%" }
        ];
    }
}; 

const generateChoixFlatTax = (slide, scenario, x, y, width) => {
    slide.addText(scenario === 1 || scenario === 2 ? [ { text: "FLAT TAX"} ] : [ { text: "BARÈME IR" } ], {
        x: x,
        y: y,
        fontFace: 'Gill Sans MT',
        w: width,
        h: '14%',
        valign: 'middle',
        fontSize: 16,
        align: 'center',
        color: 'ADB9CA',
        line: {
            color: 'ADB9CA'
        }
    });

    if(scenario === 2 || scenario === 3){
        slide.addText("Avec abatt 500.000 €", {
            x: x,
            y: y + 1.3,
            w: width,
            h: '4%',
            align: 'center',
            fill: 'FBE5D6',
            fontFace: 'Gill Sans MT',
            color: blackColor,
            fontSize: 12
        });
    }
    
    if(scenario === 4){
        slide.addText("Avec abatt 85%", {
            x: x,
            y: y + 1.6,
            w: width,
            h: '3.5%',
            align: 'center',
            fill: 'FBE5D6',
            fontFace: 'Gill Sans MT',
            color: blackColor,
            fontSize: 12
        });
    }
    
    slide.addText(lastLabel(scenario), {
        x: x,
        y: y + 2.01,
        fontFace: 'Gill Sans MT',
        w: width,
        h: '13%',
        fontSize: 16,
        align: 'center',
        fill: 'DEEBF7',
        color: '28366D',
        line: {
            color: 'DEEBF7'
        }
    });

    slide.addText(scenario, {
        x: x + 0.55,
        y: y - 0.25,
        fontFace: 'Gill Sans MT',
        fontSize: 19.3,
        w: "6%",
        h: "6%",
        color: 'FFFFFF',
        fill: 'ADB9CA',
        align: 'center',
        valign: 'middle'
    });
};

export default generateChoixFlatTax;