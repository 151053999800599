import { useContext, useEffect, useState } from "react";
import { CImg, CTextarea } from "@coreui/react";
import { SimulateurContext } from "../../store/context/simulateur";
import { updatePostItData } from "../../store/actions/simulateur";
import BoutonFermetureBlocPostIt from '../../assets/images/fermeture-personnalisation-information-simulation.svg';
import BoutonOuvertureFermeture from '../../assets/images/arrow-up.svg';
import BoutonValidationPostIt from '../../assets/images/validation-personnalisation-information-simulation.svg'
import { OrdinaryTooltip } from "../InfoBulle/Tooltip/Tooltip";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { lienAV, lienBilanPatrimonial, lienCAPI, lienCessionSociete, lienComparaison, lienCompteTitres, lienFiscaliteRevenus, lienIFI, lienIS, lienPEA, lienPlusValueImmobiliere, lienPropositionFinanciere, lienSuccession } from "../../routes";
import { DataControlContext } from "../../store/context/dataControl";
import CommentairePostIt from "./CommentairePostIt";
import ModalConfirmation from "../ModalConfirmation/ModalConfirmation";
import PostitService from "../../services/postitService";
import Switch from "../input/Switch/Switch";
import "./styles/postit.scss";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

// Function to check if a key has a value in an array of objects
const hasValueForKey = (arr, key) => {
  for (let i = 0; i < arr.length; i++) {
    // Check if the key exists in the current object and if its value is not undefined
    if (arr[i].hasOwnProperty(key) && arr[i][key] !== undefined) {
      return true; // Key has a value in at least one object
    }
  }
  return false; // Key does not have a value in any object
}

export const sortPostitByIndexCase = (elements, key = "indexCase") => {
  if (hasValueForKey(elements, key)) {
    return elements.sort((a, b) => a[key] - b[key]);
  }
  return elements;
}

// Style du DIV selon les mouvements de la souris
export const divStyle = (background, x, y) => {
  const diffX = 56;
  const diffY = 125;

  return `
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${background};
    height: 2rem;
    width: 2rem;
    border-radius: 100%;
    transform-origin: 100% 100%;
    transform: translate(-50%, -50%);
    position:absolute;
    left:${x - diffX}px;
    top:${y - diffY}px;
    z-index:1000;
    cursor:pointer
  `
}

const Postit = (props) => {
  const { simulateurState, simulateurDispatch } = useContext(SimulateurContext);
  const { headerMenuIndex, setHeaderMenuIndex } = useContext(DataControlContext);

  const [visibleBloc, setVisibleBloc] = useState(true);
  const [postItToDeleteId, setPostItToDeleteId] = useState(null);
  const [postItToUpdate, setPostItToUpdate] = useState(null);
  const [postItToEliminate, setPostItToEliminate] = useState(null);

  const [cardIndex, setCardIndex] = useState({
    source: null,
    destination: null
  })

  const [visibleDeletePostItDialog, setVisibleDeletePostItDialog] = useState(false);
  const [visiblePostItIdNull, setVisiblePostItIdNull] = useState(false);
  const location = useLocation();
  const history = useHistory();

  const { pathname } = location;

  // Les données du POST IT
  // eslint-disable-next-line no-unused-vars
  const { state: withPostIt, currentCommentValue, elements, hasPosted, currentPostItId, displayBlocPostIt } = simulateurState?.postItData || {};

  // Annulation des informations renseignées concernantle POST IT
  const handleCancelUpdatePostIt = () => {
    let hasPosted = simulateurState?.postItData?.hasPosted;

    // Suppression de post-it s'il y en a
    if (postItToDeleteId) {
      const elementToDelete = document.getElementById(postItToDeleteId);

      if (elementToDelete) {
        // eslint-disable-next-line no-unused-vars
        hasPosted = false;
        elementToDelete.remove();
      }
    }

    // Réinitialisation du formulaire à chaîne vide
    simulateurDispatch(updatePostItData({
      ...(simulateurState?.postItData || {}),
      currentCommentValue: '',
      hasPosted: false,
      currentPostItId: '',
      currentPin: {
        id: -1,
        idPostit: '',
        idSimulation: '',
        date: '',
        commentaire: '',
        isSet: false,
        userId: ''
      }
    }))

  }

  // Validation des informations renseignées concernant le POST IT
  const handleValidateClick = async () => {
    if (hasPosted) {
      const currentElements = [...elements];
      let currentPin = simulateurState?.postItData.currentPin;

      // Cas Ajout de Post-it
      if (!postItToUpdate) {

        // console.log(simulateurState?.postItData.currentPin);

        if (!currentPin.isSet) {
          currentPin.isSet = true;
          currentPin.commentaire = currentCommentValue;
        }

        /** Référencement d'onglet pour les SIMULATEURS multi-pages */
        // PEA
        if (pathname.includes(lienPEA)) {
          currentPin.type = 'pea';
          if (pathname === '/simulateurs/pea' && simulateurState.simulation?.id) {
            currentPin.tabIndex = 1;
          }
        }

        // CT
        if (pathname.includes(lienCompteTitres)) {
          currentPin.type = 'ct';
          if (pathname === '/simulateurs/ct' && simulateurState.simulation?.id) {
            currentPin.tabIndex = 1;
          }
        }

        // AV
        if (pathname.includes(lienAV)) {
          currentPin.type = 'av';
          if (pathname === '/simulateurs/av' && simulateurState.simulation?.id) {
            currentPin.tabIndex = 1;
          }
        }

        // CAPI
        if (pathname.includes(lienCAPI)) {
          currentPin.type = 'cp';
          if (pathname === '/simulateurs/cp' && simulateurState.simulation?.id) {
            currentPin.tabIndex = 1;
          }
        }

        // IS
        if (pathname.includes(lienIS)) {
          currentPin.type = 'is';
          if (pathname === '/simulateurs/is' && simulateurState.simulation.id) {
            currentPin.tabIndex = 1;
          }
        }

        // COMPARAISON
        if (pathname.includes(lienComparaison)) {
          currentPin.type = 'comparaisons';
        }

        // PROPOSITION FINANCIERE
        if (pathname.includes(lienPropositionFinanciere)) {
          currentPin.tabIndex = headerMenuIndex.propositionFinanciere;
        }

        // Cession de société
        if (pathname.includes(lienCessionSociete)) {
          currentPin.tabIndex = headerMenuIndex.cs;
        }

        // Succession
        if (pathname.includes(lienSuccession)) {
          currentPin.tabIndex = headerMenuIndex.succession;
        }

        // Bilan patrimonial
        if (pathname.includes(lienBilanPatrimonial)) {
          currentPin.tabIndex = headerMenuIndex.bilanPatrimonial;
        }

        // Fiscalité des revenus
        if (pathname.includes(lienFiscaliteRevenus)) {
          currentPin.tabIndex = headerMenuIndex.patrimoineFiscalite.fiscaliteRevenus;
        }

        // IFI
        if (pathname.includes(lienIFI)) {
          currentPin.tabIndex = headerMenuIndex.patrimoineFiscalite.ifi;
        }

        // Plus-value immobilière
        if (pathname.includes(lienPlusValueImmobiliere)) {
          currentPin.tabIndex = headerMenuIndex.pvImmobiliere;
        }

        /** Fin Référencement */

        const pinCreated = await PostitService.createPostit(currentPin);
        currentPin = pinCreated;
        currentElements.push({ ...currentPin })
        setPostItToDeleteId(null);
      }

      // Cas Modification de Post-it
      else {
        // eslint-disable-next-line array-callback-return
        currentElements.map((element, index) => {
          if (element.id === postItToUpdate.id) {
            element.commentaire = currentCommentValue;
            PostitService.updatePostit(postItToUpdate.id, element)
          }
        })
        setPostItToUpdate(null);
      }

      simulateurDispatch(updatePostItData({
        ...(simulateurState?.postItData || {}),
        currentCommentValue: '',
        hasPosted: false,
        elements: currentElements,
        currentPin
      }))

      // console.log(currentElements);
    }
  }

  // Anticipation de comportement de CLICK sur les épingles de POST IT
  // useEffect(() => {
  //   elements?.map((element) => {
  //     let epinglePostIt = document.getElementById(`post-it-${element.id}-icon`);

  //     if (epinglePostIt) {
  //       epinglePostIt.onclick = () => {
  //         simulateurDispatch(updatePostItData({
  //           ...(simulateurState?.postItData || {}),
  //           currentPostItId: element.id
  //         }))
  //       }
  //     }
  //   })
  // }, [
  //   // simulateurState?.postItData?.displayBlocPostIt,
  //   JSON.stringify(elements)
  // ])

  // eslint-disable-next-line no-unused-vars
  const fetchPostIt = async () => {
    if (Number.isInteger(String(pathname).split('/')[String(pathname).split('/').length - 1])) {
      const type = String(pathname).split('/')[String(pathname).split('/').length - 2];
      const idSimulation = String(pathname).split('/')[String(pathname).split('/').length - 1];
      const postitValues = await PostitService.getPostitsByType(
        type,
        localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user'))?.id : null,
        idSimulation
      )
      // console.log(postitValues);
      simulateurDispatch(updatePostItData({
        ...(simulateurState?.postItData || {}),
        elements: sortPostitByIndexCase(postitValues)
      }))
    }
  }

  const handleRequestVisualize = (data, isEdit) => {

    let menu = ["bilanPatrimonial"];

     /** Référencement d'onglet pour les SIMULATEURS multi-pages */
     const simulateurs = ['/simulateurs/is', '/simulateurs/cp', '/simulateurs/av', '/simulateurs/ct', '/simulateurs/pea'];
     let isSwitchTab = false;
     let simulateurSimulerTab = simulateurs.find(x => pathname.includes(x));
    //  let redirectPath = pathname;
     if (simulateurSimulerTab) {
       const indexTab = simulateurs.find(x => x === pathname) && simulateurState.simulation?.id ? 1 : 0
        // console.log(pathname, simulateurSimulerTab + '/' + simulateurState.simulation?.id, indexTab, data.tabIndex);
        if (indexTab !== data.tabIndex) {
          isSwitchTab = true;
          // redirectPath = indexTab === 0 ? simulateurSimulerTab : simulateurSimulerTab + '/' + simulateurState.simulation?.id
        }
     }
    // COMPARAISON

    // PROPOSITION FINANCIERE
    if (pathname.includes(lienPropositionFinanciere)) {
      menu = ["propositionFinanciere"]
    }

    // Cession de société
    if (pathname.includes(lienCessionSociete)) {
      menu = ["cs"]
    }

    // Succession
    if (pathname.includes(lienSuccession)) {
      menu = ["succession"]
    }

    // Bilan patrimonial
    if (pathname.includes(lienBilanPatrimonial)) {
      menu = ["bilanPatrimonial"]
    }

    // Fiscalité des revenus
    // Succession
    if (pathname.includes(lienFiscaliteRevenus)) {
      menu = ["patrimoineFiscalite", "fiscaliteRevenus"]
    }

    // IFI
    if (pathname.includes(lienIFI)) {
      menu = ["patrimoineFiscalite", "ifi"]
    }

    // Plus-value immobilière

    if (pathname.includes(lienPlusValueImmobiliere)) {
      menu = ["pvImmobiliere"]
    }

    const headerMenu = JSON.parse(JSON.stringify(headerMenuIndex));
    if (menu.length === 2) {
      headerMenu[menu[0]][menu[1]] = data.tabIndex;
    } else {
      headerMenu[menu[0]] = data.tabIndex;
    }
    setHeaderMenuIndex(headerMenu);


    if (isEdit) {
      simulateurDispatch(updatePostItData({
        ...(simulateurState?.postItData || {}),
        currentCommentValue: data.commentaire,
        hasPosted: true,
        currentPostItId: data.idPostit,
        requestView: true
      }));
    } else {
      simulateurDispatch(updatePostItData({
        ...(simulateurState?.postItData || {}),
        currentPostItId: data.idPostit,
        requestView: true,
        currentCommentValue: "",
        hasPosted: false,
      }))
    }

    if (isSwitchTab) {
      // history.push(redirectPath)
      for (const button of simulateurState.buttons) {
        if (button.text === "Simuler" && button.type === "SIMULATE") {
          // button.onClick();
          simulateurDispatch({ type: button.type });
        }

        if (button.text === "Retour" && button.type === "BACK") {
          // button.onClick();
          simulateurDispatch({ type: button.type });
        }
      }
    }


  }

  // Réinitialisation des épingles de POST IT à chaque changement de pathname
  useEffect(() => {

    history.listen((location, action) => {
      // Réinitialisation des éléments de contexte
      simulateurDispatch(updatePostItData({
        displayBlocPostIt: false,
        currentPostItId: null,
        hasPosted: false,
        state: false,
        simulationType: '',
        requestView: false,
        displayPostitIdNull: false,
        simulationId: null,
        currentCommentValue: '',
        elements: [],
        switchOn: false,
        changingTab: '',
        currentPin: {
          id:  1,
          idPostit: '',
          idSimulation: '',
          date: '',
          commentaire: '',
          isSet: false,
          userId: ''
        }
      }))
    });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname])

  useEffect(() => {
    const onHandleSwitch = async () => {

      const id = Number(String(window.location.href).split('/')[String(window.location.href).split('/').length - 1]);
      const type = id ? String(window.location.href).split('/')[String(window.location.href).split('/').length - 2] : String(window.location.href).split('/')[String(window.location.href).split('/').length - 1];
      const identifiant = id ? id :
              simulateurState?.postItData.simulationId ? simulateurState?.postItData.simulationId :
              simulateurState.simulation?.id ? simulateurState.simulation?.id : null;

      if (!identifiant) {
        return;
      }

      const userId = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user'))?.id : null
      const currentElements = await PostitService.getPostitsByType(
        type === 'formulaire' ? String(window.location.href).split('/')[String(window.location.href).split('/').length - 2] : type,
        userId,
        identifiant);

      let menu = ["bilanPatrimonial"];

      // PROPOSITION FINANCIERE
      if (pathname.includes(lienPropositionFinanciere)) {
        menu = ["propositionFinanciere"]
      }

      // Cession de société
      if (pathname.includes(lienCessionSociete)) {
        menu = ["cs"]
      }

      // Succession
      if (pathname.includes(lienSuccession)) {
        menu = ["succession"]
      }

      // Bilan patrimonial
      if (pathname.includes(lienBilanPatrimonial)) {
        menu = ["bilanPatrimonial"]
      }

      // Fiscalité des revenus
      // Succession
      if (pathname.includes(lienFiscaliteRevenus)) {
        menu = ["patrimoineFiscalite", "fiscaliteRevenus"]
      }

      // IFI
      if (pathname.includes(lienIFI)) {
        menu = ["patrimoineFiscalite", "ifi"]
      }

      // Plus-value immobilière

      if (pathname.includes(lienPlusValueImmobiliere)) {
        menu = ["pvImmobiliere"]
      }

      const simulateurs = ['/simulateurs/is', '/simulateurs/cp', '/simulateurs/av', '/simulateurs/ct', '/simulateurs/pea'];
      let isSimulateurSimuler = false;
      let simulateurSimulerTab = 0;
      if (simulateurs.find(x => pathname.includes(x))) {
        isSimulateurSimuler = true;
        simulateurSimulerTab = simulateurs.find(x => x === pathname) && simulateurState.simulation?.id ? 1 : 0;
      }

      const sortedElements = sortPostitByIndexCase(currentElements);

      if (Array.isArray(sortedElements) && simulateurState?.postItData.switchOn) {
        const headerMenu = JSON.parse(JSON.stringify(headerMenuIndex));
        if (menu.length === 2) {
          const headerMenuVal = !isSimulateurSimuler ? headerMenu[menu[0]][menu[1]] : simulateurSimulerTab;
          simulateurDispatch(updatePostItData({
            ...(simulateurState?.postItData || {}),
            elements: sortedElements.filter(x => x.tabIndex === headerMenuVal)
          }))
        } else {
          const headerMenuVal = !isSimulateurSimuler ? headerMenu[menu[0]] : simulateurSimulerTab;
          simulateurDispatch(updatePostItData({
            ...(simulateurState?.postItData || {}),
            elements: sortedElements.filter(x => x.tabIndex === headerMenuVal)
          }));
        }
      } else {

          simulateurDispatch(updatePostItData({
            ...(simulateurState?.postItData || {}),
            elements: sortedElements
          }))
      }
    }

    onHandleSwitch();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [simulateurState?.postItData.switchOn, simulateurState?.postItData.changingTab])

  // useEffect(() => {
  //   if (simulateurState?.postItData?.displayPostitIdNull) {
  //     setVisiblePostItIdNull(true);
  //     simulateurDispatch(updatePostItData({
  //       ...(simulateurState?.postItData || {}),
  //       displayPostitIdNull: false
  //     }))
  //   }
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [simulateurState?.postItData?.displayPostitIdNull])

  // Fermeture immédiate du bloc de POST-IT si on ouvre le fenêtre de personnalisation de simulation
  // useEffect(() => {
  //   if (simulateurState?.postItData?.displayBlocPostIt) {
  //     simulateurDispatch(updatePostItData({
  //       ...(simulateurState?.postItData || {}),
  //       displayBlocPostIt: false
  //     }))
  //   }
  // }, [simulateurState?.showFormulairePersonnalisationSimulation])

  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = async (result) => {
    const indiceMenu = {
        source: null,
        destination: null
    }

    // dropped outside the list
    if (!result.destination) {
        return;
    }

    const currentElements = reorder(
        elements,
        result.source.index,
        result.destination.index
    );

    indiceMenu.source = result.source.index;
    indiceMenu.destination = result.destination.index;

    const elementsMapped = currentElements.map((el, index) => {
      el.indexCase = String(index).toString()
      return el
    })

    // console.log(elementsMapped);

    setCardIndex(indiceMenu);
    simulateurDispatch(updatePostItData({
      ...(simulateurState?.postItData || {}),
      elements: elementsMapped
    }))

    const pinsUpdated = await PostitService.updatePostitMultiple({
      postIts: elementsMapped
    });

    if (!pinsUpdated) {
      console.log("une erreur s'est survenu");
    }
  }

  const getItemStyle = (isDragging, draggableStyle) => {
    return {
        userSelect: "none",
        ...draggableStyle
    }
  }

  const getListStyle = isDraggingOver => ({});

  return (
    <>
      {/** Fenêtre de confirmation de suppression de POST IT */}
      <ModalConfirmation
        visible={visibleDeletePostItDialog}
        setVisible={setVisibleDeletePostItDialog}
        text={`Souhaitez-vous vraiment supprimer ce Post-it ${elements?.findIndex(x => x.id === postItToEliminate?.id) + 1} ?`}
        onOKClick={() => {
          if (postItToEliminate) {
            const currentPostit = postItToEliminate;
            let currentElements = [...elements];
            currentElements = currentElements.filter((element) => element.id !== postItToEliminate.id);
            PostitService.deletePostit(postItToEliminate.id);
            currentPostit.isSet = false;
            simulateurDispatch(updatePostItData({
              ...(simulateurState?.postItData || {}),
              elements: currentElements,
              currentPin: currentPostit,
              currentPostItId: currentPostit.idPostit
            }))
          }
        }}
      />
      {/** Fenêtre de confirmation de POST IT ID simulation NULL*/}
      <ModalConfirmation
        visible={visiblePostItIdNull}
        setVisible={setVisiblePostItIdNull}
        text={<>
            <div>Vous devez sauvegarder pour ajouter un postit  pour cette simulation</div>
            <div>Souhaitez-vous sauvegarder ?</div>
        </>}
        onOKClick={() => {
          if (postItToEliminate) {

          }
        }}
      />
      <div
        style={{
          position: 'fixed',
          zIndex: 1000,
          background: 'rgb(242,249,252)',
          right: 0,
          bottom: 60,
          borderRadius: '10px 10px 0 0',
          height: visibleBloc ? '74.6vh' : '160px',
          maxHeight: '74.6vh',
          overflowY: 'hidden',
          overflowX: 'hidden'
        }}>

        {/** Premier bloc */}
        <div style={{ padding: 10 }}>
          {/** Flèche permettant de minimiser ou maximiser le CONTENU du POST-IT */}
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 5, paddingRight: 10 }}>
            <img
              alt="visible bloc"
              onClick={() => {setVisibleBloc(!visibleBloc)}}
              width={15}
              src={BoutonOuvertureFermeture}
              style={{ rotate: !visibleBloc && '180deg', cursor: 'pointer' }}
            />
          </div>

          {/** Formulaire d'écriture de COMMENTAIRE */}
          <div style={{ position: 'relative' }}>
            <CTextarea
              disabled={!hasPosted}
              placeholder='Rédiger un commentaire'
              value={currentCommentValue}
              onChange={(event) => {
                simulateurDispatch(updatePostItData({
                  ...(simulateurState?.postItData || {}),
                  currentCommentValue: event.target.value
                }))
              }}
              style={{ width: '350px', height: '100px', resize: 'none' }}
            />
          </div>

          {/** Les boutons d'actions ANNULATION D'ECRITURE et SAUVEGARDE */}
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 5 }}>
            {/** Bouton d'annulation des modifications */}
            <div style={{ marginRight: 15 }}>
              {
                (currentCommentValue !== "" || hasPosted) ? (
                  <OrdinaryTooltip arrow placement='bottom' title="Annuler">
                    <div>
                      <CImg src={BoutonFermetureBlocPostIt} width={15} onClick={handleCancelUpdatePostIt} style={{ cursor: 'pointer' }} />
                    </div>
                  </OrdinaryTooltip>
                ) : (
                  <svg xmlns="http://www.w3.org/2000/svg" width="15" viewBox="0 0 19.715 19.716">
                    <g id="Group_40868" data-name="Group 40868" transform="translate(-382.146 -71.657)">
                      <line id="Line_499" data-name="Line 499" y2="19.881" transform="translate(399.033 74.485) rotate(45)" fill="none" stroke='#D8D8D8' strokeLinecap="round" strokeWidth="4" />
                      <line id="Line_500" data-name="Line 500" y2="19.881" transform="translate(399.033 88.544) rotate(135)" fill="none" stroke='#D8D8D8' strokeLinecap="round" strokeWidth="4" />
                    </g>
                  </svg>
                )
              }
            </div>

            {/** Bouton d'ajout des informations de POST IT */}
            <div>
              {
                (hasPosted && currentCommentValue !== "") ? (
                  <OrdinaryTooltip arrow title="Valider" placement='bottom'>
                    <div>
                      <CImg width={20} src={BoutonValidationPostIt} onClick={handleValidateClick} style={{ cursor: 'pointer' }} />
                    </div>
                  </OrdinaryTooltip>
                ) : (
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 24.891 18.886">
                    <path
                      id="Icon_feather-check"
                      data-name="Icon feather-check"
                      d="M25.237,9,12.012,23.059,6,16.668"
                      transform="translate(-3.173 -6.173)"
                      fill="none"
                      stroke="#D8D8D8"
                      strokeLinecap="round"
                      strokeLinejoin="round" strokeWidth="4"
                    />
                  </svg>
                )
              }
            </div>
          </div>
        </div>

        {/** Les commentaires POST-IT */}
        {
          elements?.length > 0 && (
            <div className="post-it-commentary">
              <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="droppable">
                      {(provided, snapshot) => (
                          <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                              style={getListStyle(snapshot.isDraggingOver)}
                          >
                              {elements.map((data, index) => (
                                  <Draggable key={data.id} draggableId={String(data.id)} index={index}>
                                      {(provided, snapshot) => (
                                          <div
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                              style={getItemStyle(
                                                  snapshot.isDragging,
                                                  provided.draggableProps.style
                                              )}
                                          >
                                              <CommentairePostIt
                                                key={`PostItComment${data.idPostit}`}
                                                active={currentPostItId === data.idPostit}
                                                id={index + 1}
                                                date={data.date}
                                                commentaire={data.commentaire}
                                                onRequestVisualise={() => handleRequestVisualize(data)}
                                                onRequestEdit={() => {
                                                  handleRequestVisualize(data, true);
                                                  setPostItToUpdate(data);
                                                }}
                                                onRequestDelete={() => {
                                                  handleRequestVisualize(data);
                                                  setPostItToEliminate(data);

                                                  setVisibleDeletePostItDialog(!visibleDeletePostItDialog);
                                                }}
                                              />
                                          </div>
                                      )}
                                  </Draggable>
                              ))}
                              {provided.placeholder}
                          </div>
                      )}
                  </Droppable>
              </DragDropContext>
              {/* {
                elements?.map((data, index) => {
                  return (
                    <CommentairePostIt
                      key={`PostItComment${data.idPostit}`}
                      active={currentPostItId === data.idPostit}
                      id={index + 1}
                      date={data.date}
                      commentaire={data.commentaire}
                      onRequestVisualise={() => handleRequestVisualize(data)}
                      onRequestEdit={() => {
                        simulateurDispatch(updatePostItData({
                          ...(simulateurState?.postItData || {}),
                          currentCommentValue: data.commentaire,
                          hasPosted: true
                        }))

                        setPostItToUpdate(data)
                      }}
                      onRequestDelete={() => {
                        setPostItToEliminate(data);

                        setVisibleDeletePostItDialog(!visibleDeletePostItDialog);
                      }}
                    />
                  )
                })
              } */}
            </div>
          )
        }

        {/* Switch post-it */}
        {
          visibleBloc && (
            <div className="post-it-switch">
                <OrdinaryTooltip placement='top' title={`${simulateurState?.postItData.switchOn ? 'Afficher tous les commentaires' : 'Afficher uniquement les commentaires en cours'}`} arrow>
                <div className="post-it-switch-container">
                    <Switch
                      checked={simulateurState?.postItData.switchOn}
                      onChange={(event) => {
                        simulateurDispatch(updatePostItData({
                          ...(simulateurState?.postItData || {}),
                          switchOn: !simulateurState?.postItData.switchOn
                        }))
                      }}
                    />
                </div>
                </OrdinaryTooltip>
              </div>
          )
        }
      </div>
    </>
  )
}

export default Postit;
