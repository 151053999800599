import React, { Component } from "react";
import NumberFormat from 'react-number-format';
import { CInput } from '@coreui/react';

export default class InputDateCession extends Component {
    render() {
        return(
            <NumberFormat
                id={this.props.id}
                customInput={CInput}
                // style={{textAlign:'right'}}
                style={{...this.props.style,textAlign:'right', fontSize:13}}
                format="##/##/####"
                placeholder="jj/mm/aaaa"
                onKeyPress={this.props.onKeyPress}
                onKeyDown={event=> {
                    if (event.keyCode === 13) {
                        if(this.props.onKeyPress)
                            this.props.onKeyPress()
                        const form = event.target.form;
                        let index = Array.prototype.indexOf.call(form, event.target);
                        if(form.elements[index + 1])
                            form.elements[index + 1].focus();
                        event.preventDefault();
                    }
                }}
                onBlur={this.props.onBlur}
                onFocus={this.props.onFocus}
                disabled={this.props.disabled}
                invalid={this.props.invalid}
                value={this.props.value}
                onChange={this.props.onChange}
                className= {this.props.className? this.props.className : "col-lg-12"}
            />
        )
    }
}