const titleByIndex = (index) => {
    switch(index){
        case 0 :
            return "";
        case 1 :
            return "Prénom";
        case 2 :
            return "Les parents ?";
        case 3 :
            return "Enfant fiscalement à charge";
        case 4 :
            return [{text: "Revenus de l'année de cession imposables", options: { breakLine: true }}, {text: "à la CEHR"}];
        case 5 :
            return "Situation familiale";
        default :
            return "L'enfant présente un handicap ?";
    }
};

const rowsConjointsProps = {
    fontSize: 13,
    fontFace: 'Gill Sans MT',
    valign: 'middle'
};

const dashedBorderBottom = (type='dashed') => {
    return [
        {
            pt: 0,
            color: '808080',
        },
        {
            pt: 0,
            color: 'FFFFFF',
        },
        {
            pt: type === 'dashed' ? 0.7 : 1.6,
            color: '808080',
            type: type === 'dashed' ? 'dash' : 'solid'
        },
        {
            pt: 0,
            color: '808080',
        },
    ]
};

const enfantsParPage = (rowsEnfants, enfants) => {
    let titres = [];
    let noms = [];
    let parents = [];
    let enfantsCharge = [];
    let revenusAnnee = [];
    let situationsFamiliales = [];
    let handicaps = [];
    
    let indexTitres = 0;
    let indexNoms = 0;
    let indexParents = 0;
    let indexEnfantsCharge = 0;
    let indexRevenusAnnee = 0;
    let indexSituationsFamiliales = 0;
    let indexHandicaps = 0;

    rowsEnfants.map((donnee, indice) => {
            let currentLine = donnee;
            currentLine.shift();
    
            let i = 0;
    
            while(i < currentLine.length){
                if( i%4 === 0){
                    let data = [];

                    // A transformer en boucle
                    if(currentLine[i]) data.push(currentLine[i]);
                    if(currentLine[i+1]) data.push(currentLine[i+1]);
                    if(currentLine[i+2]) data.push(currentLine[i+2]);
                    if(currentLine[i+3]) data.push(currentLine[i+3]);
                    
                    if(currentLine[i+4]) data.push(currentLine[i+4]);
                    if(currentLine[i+5]) data.push(currentLine[i+5]);
                    if(currentLine[i+6]) data.push(currentLine[i+6]);
                    if(currentLine[i+7]) data.push(currentLine[i+7]);

    
                    data.unshift({ text: titleByIndex(indice), options: { align: 'left', ...rowsConjointsProps, fill: indice !== 0 ? 'F2F2F2' : 'FFFFFF', border: indice !== 0 ? dashedBorderBottom(indice !== rowsEnfants.length - 1 ? 'dashed' : 'normal') : [] } })
                    
                    if(indice === 0){
                        titres[indexTitres] = data;
                        indexTitres++;
                    }
                    else if(indice === 1){
                        noms[indexNoms] = data;
                        indexNoms++;
                    }
                    else if(indice === 2){
                        parents[indexParents] = data;
                        indexParents++;
                    }
                    else if(indice === 3){
                        enfantsCharge[indexEnfantsCharge] = data;
                        indexEnfantsCharge++;
                    }
                    else if(indice === 4){
                        revenusAnnee[indexRevenusAnnee] = data;
                        indexRevenusAnnee++
                    }
                    else if(indice === 5){
                        situationsFamiliales[indexSituationsFamiliales] = data;
                        indexSituationsFamiliales++;
                    }
                    else if(indice === 6){
                        handicaps[indexHandicaps] = data;
                        indexHandicaps++;
                    }

                    i += 8;
                }
                
            }
    });

    const nbPageEnfants = Math.ceil(enfants.length / 2);
    let finalData = [];
    for(let i=0; i<nbPageEnfants; i++){
        finalData[i] = [
            titres[i],
            noms[i],
            parents[i],
            enfantsCharge[i],
            revenusAnnee[i],
            situationsFamiliales[i],
            handicaps[i]
        ];
    }

    return finalData;
};

export default enfantsParPage;