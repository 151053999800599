import { blackColor } from "../../all_ppt_render";

const generateBox1 = (pptx, slide, x, y, label, value, pourcentageValue, background, secondBackground, width) => {
    const textColor = '808080';
    let dataChartAreaLine = [
        {
            name: "POURCENTAGE",
            labels: ["REAL", "NOT REAL"],
            values: [pourcentageValue, 100 - pourcentageValue],
        },
    ];
    
    let realLabel =  [
        { text: label, options: { breakLine: true, color: textColor } },
        { text: " ", options: { breakLine: true } },
        { text: " ", options: { breakLine: true } },
        { text: `${value} €`, options: { color: blackColor } }
    ];
    
    slide.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
        x: x,
        y: y,
        w: width,
        h: label !== 'Optimisation fiscale' ? '32%' : "14%",
        rectRadius: 0.05,
        line: {
            color: 'D8D8D8'
        }
    });

    slide.addText(realLabel
        , {
        x: x,
        y: y,
        bold: true,
        fontFace: 'Gill Sans MT',
        w: width,
        h: '14%',
        valign: 'middle',
        fontSize: 13
    });
    
    slide.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
        x: x + 0.1,
        y: y + 0.35,
        w: "6%",
        fill: background,
        h: '0.75%',
        rectRadius: 0.05,
        line: {
            color: 'D8D8D8'
        }
    });
    

    if(secondBackground){
        slide.addText(`${pourcentageValue} %`, {
            x: x,
            y: y + 1.82,
            w: width,
            color: background,
            align: 'center',
            fontSize: 10,
            h: '2%'
        });
        
        slide.addChart(
            pptx.ChartType.doughnut, 
            dataChartAreaLine,
            { 
                x: x + 0.26, 
                y: y + 1.15, 
                w: 1.5, 
                h: 1.5,
                showPercent: false,
                holeSize: 90,
                chartColors: [ background, secondBackground ]
            }
        );
    }
};

export default generateBox1;