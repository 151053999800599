import { createContext, useReducer, useState } from "react"
import { donationsDutreilDefaultData } from "./defaultData/boxDonationsSuccession/donationsDutreil";
import { propositionFinanciereDefaultData } from "./defaultData/boxFinanciere/propositionFinanciere";
import { empruntDefaultData } from "./defaultData/boxImmobiliere/emprunt";
import { ifiDefaultData } from "./defaultData/boxPatrimoineFiscalite/ifi";
import { plusValueImmobiliereDefaultData } from "./defaultData/plusValueImmobiliere";
import { successionDefaultData } from "./defaultData/succession";
import { autresDonationsDefaultData } from './defaultData/boxDonationsSuccession/autresDonations';
import { donsFamiliauxExoneresDefaultData } from "./defaultData/boxDonationsSuccession/donsFamiliauxExoneres";
import { fiscaliteRevenusDefaultData } from './defaultData/boxPatrimoineFiscalite/fiscaliteRevenus';
import { cessionSocieteDefaultData } from "./defaultData/cessionSociete";
import { autreDefaultData, configurationExportDefaultData } from "./defaultData/autre";
import { ficheClientDefaultData } from "./defaultData/ficheClient";

const initialState  = {
    clientsList: [],
    client: {},
    resSuccession: {},
    succession: {},
    resFiscalite: {},
};

const initSimulateurHeaderMenuIndex = {
    cs: 0,
    pvImmobiliere: 0,
    succession: 0,
    propositionFinanciere: 0,
    bilanPatrimonial: 0,
    patrimoineFiscalite: {
        fiscaliteRevenus: 0,
        ifi: 0
    },
    buttonFilter: 0,
    empruntSituationDu: ""
}

const init_profil_utilisateur = {
    id: null,
    nom: "",
    fonction: "",
    telephone: "",
    email: "",
    adresse: "",
    societe: "",
    avecMentionLegale: true,
    mentionLegale: ""
}

const initCessionControlState = {
    activeSimu: false
}

const init_usufruit_economique = {
    idSimulation: null,
    activeSimu: false,
    "duree_demembrement": 0,
    "valeur_pp": 0,
    "taux_redenement_1er_annee": 0,
    "taux_valo_revenu": 0,
    "taux_valo_bien": 0
}

export const DataControlContext = createContext(initialState)

const reducer = (state, {type, payload}) => {
  switch (type) {
    case 'UPDATE_SUCCESSION':
        return { ...state, succession: payload }
    case 'UPDATE_SUCCESSION_CLIENT':
      return { ...state, client: payload }
    case 'UPDATE_SUCCESSION_HERITIER':
      return { ...state, resSuccession: payload }
    case 'POPULATE_CLIENTS':
        return { ...state, clientsList: payload }
    case 'UPDATE_FISCAlITE_REVENUS':
        const result = { ...state, resFiscalite: payload }
        return result
    default:
      return state;
  }
}

export const DataControlProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    // Box Financière
    const [ propositionFinanciereState, setPropositionFinanciereState ] = useState(propositionFinanciereDefaultData);

    // Box Cession de société
    const [cessionSocieteState, setCessionSocieteState] = useState(cessionSocieteDefaultData);

    // Box Donations et Succession
    const [donsFamiliauxExoneresState, setDonsFamiliauxExoneresState] = useState(donsFamiliauxExoneresDefaultData);
    const [donationDutreilState, setDonationDutreilState] = useState(donationsDutreilDefaultData);
    const [autreDonationState, setAutreDonationState] = useState(autresDonationsDefaultData);
    const [successionState, setSuccessionState] = useState(successionDefaultData);

    // Box Patrimoine et fiscalité
    const [fiscaliteState, setFiscaliteState ] = useState(fiscaliteRevenusDefaultData)
    const [ ifiState, setIfiState ] = useState(ifiDefaultData);

    // Box Immobilière
    const [ empruntState, setEmpruntState ] = useState(empruntDefaultData);
    const [ usufruitEconomiqueState, setUsufruitEconomiqueState ] = useState(init_usufruit_economique);
    const [ pvImmobiliereState, setPvImmobiliereState ] = useState(plusValueImmobiliereDefaultData);

    // Autres
    const [ficheClientState, setFicheClientState] = useState(ficheClientDefaultData);
    const [ profilUtilisateurState, setProfilUtilisateurState ] = useState(init_profil_utilisateur);
    const [ cessionControlState, setCessionControlState ] = useState(initCessionControlState);
    const [ headerMenuIndex, setHeaderMenuIndex ] = useState(initSimulateurHeaderMenuIndex);
    const [autreState, setAutreState] = useState(autreDefaultData);
    const [configurationExportState, setConfigurationExportState] = useState(configurationExportDefaultData);


  return <DataControlContext.Provider
            value={{
                dataControlState: state,
                dataControlDispatch: dispatch,

                // Box Financière
                propositionFinanciereState, setPropositionFinanciereState,

                // Box Cession de société
                cessionSocieteState, setCessionSocieteState,

                // Box Donations et Succession
                donsFamiliauxExoneresState, setDonsFamiliauxExoneresState,
                donationDutreilState, setDonationDutreilState,
                autreDonationState, setAutreDonationState,
                successionState, setSuccessionState,

                // Box Patrimoine et Fiscalité
                fiscaliteState, setFiscaliteState,
                ifiState, setIfiState,

                // Box Immobilière
                empruntState, setEmpruntState,
                usufruitEconomiqueState, setUsufruitEconomiqueState,
                pvImmobiliereState, setPvImmobiliereState,

                // Autres
                ficheClientState, setFicheClientState,
                profilUtilisateurState, setProfilUtilisateurState,
                headerMenuIndex, setHeaderMenuIndex,
                cessionControlState, setCessionControlState,
                autreState, setAutreState,
                configurationExportState, setConfigurationExportState
            }}
        >
            {children}
        </DataControlContext.Provider>
}
