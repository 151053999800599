import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
    root: {

    },
    infoContainer: {
        display: 'flex',
        flexDirection: 'row',
        background: "rgb(225,232,248)",
        border: "1px solid rgb(118,143,211)",
        padding: 10,
        borderRadius: 5,
        fontWeight: 'bold',
        width: "82%"
    },
    infoIcon: {
        marginTop: 5,
        lineHeight: 2.5
    }
});

export default useStyles;