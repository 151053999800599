import React from 'react';

const Etape = (props) => {
    const { simulateur, text, bannerText } = props;

    const backgroundBySimulateur = (simu) => {
        switch(simu){
            case simu = 'PEA' :
                return 'linear-gradient(to right, #EF3F75,#F16360)';
            case simu = 'CT' :
                return 'linear-gradient(to right, #17AACE,#1D51A1)';
            case simu = 'AV' :
                return 'linear-gradient(to right, #EDC413,#F67A0B)';
            case simu = 'CAPI' :
                return 'linear-gradient(to right, #82BB4B,#279757)';
            default :
                return 'linear-gradient(to right, #8E5FA8,#1D51A1)'
        }
    }

    const style = {
        root: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between'
        },
        text: {
            color: '#5271C7',
            fontWeight: 'bold',
            fontSize: 14
        },
        bloc: {
            background: backgroundBySimulateur(simulateur),
            borderRadius: 5,
            width: '30px',
            height: 4
        },
        banner: {
            background: '#28366D',
            color: '#FFFFFF',
            borderRadius: 5,
            padding: 10,
            width: '25%',
            textAlign: 'center'
        }
    }

    return (
        <div style={style.root}>
            <div style={{ width: '50%' }} >
                <div style={style.text} >{text}</div>
                <div style={style.bloc} ></div>
            </div>
            {
                bannerText && (
                    <div style={{ width: '50%', display: 'flex', justifyContent: 'flex-end' }} >
                        <div style={style.banner} >{bannerText}</div>
                    </div>
                )
            }
        </div>
    );
}

export default Etape;