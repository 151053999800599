import React, { useContext } from "react";
import { CSelect } from '@coreui/react';
import { SimulateurContext } from "../../store/context/simulateur";
import { activePageBlock } from "../../store/actions/simulateur";
import PostitPin from "../Postit/PostitPin";

const InputSelect = (props) => {
    const { simulateurState, simulateurDispatch } = useContext(SimulateurContext);

    // On Change
    const handleChange = (event) => {
        // On met à jour l'état de changement de FORMULAIRE
        simulateurDispatch(activePageBlock())

        // Changement de valeur contenue dans le FORMULAIRE
        props.onChange(event);
    }

    return (
      <div className={props.className ? props.className : "col-lg-12"} style={{ position: 'relative', padding: 0, marginRight: props.marginRight ? props.marginRight : 0, ...props.style}}>
        <CSelect
            id={props.id}
            custom={props.custom}
            value={props.value}
            onKeyPress={props.onKeyPress}
            onKeyDown={event => {
                if (event.keyCode === 13) {
                    const form = event.target.form;
                    let index = Array.prototype.indexOf.call(form, event.target);
                    if (form.elements[index + 1])
                        form.elements[index + 1].focus();
                    event.preventDefault();
                }
            }}
            onChange={handleChange}
            disabled={props.disabled}
            style={{ width: "100%", border: props.invalid && '1px solid #FF0000', background: props.disabled && 'rgb(224,227,230)', fontSize: 13, ...props.customStyle }}
        >
            {
                props.children
            }
        </CSelect>
          {
            !props?.hidePin && (
              <PostitPin
                // pinTop="-43px"
                // pinRight="-6px"
                // pinTopHover="-49px"
                // pinRightHover="-11px"
                // pinSelectedTopHover="-45px"
                // pinSelectedRightHover="-11px"
                isPostitShow={simulateurState?.postItData?.displayBlocPostIt}
                pinId={props.id} />
            )

          }

      </div>
    )
}

export default InputSelect
