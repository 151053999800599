import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
    root: {

    },
    guide: {
        fontWeight: 'bold',
        marginTop: '0px',
        marginBottom: '10px',
        marginLeft: '16px',
        fontSize: 15,
        textTransform: "uppercase"
    }
});

export default useStyles;