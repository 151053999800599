import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

export const OrdinaryTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: "rgb(221,227,252)",
      color: '#28366D',
      maxWidth: '350px',
      fontSize: theme.typography.pxToRem(12),
      fontWeight: 'bold',
      fontFamily: 'Roboto',
      border: '1px solid rgb(221,227,252)'
    },
}))(Tooltip);

export const ErrorToolTip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#FFCED7",
    color: 'rgb(229,83,83)',
    maxWidth: '350px',
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #FFCED7'
  },
}))(Tooltip);