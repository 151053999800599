import React, { useEffect } from 'react';
import PageHypothesePvImmo from '../PageHypothesePvImmo/PageHypothesePvImmo';

const CessionIndirecte = ({data, resultat}) => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <PageHypothesePvImmo 
            type={1}
            hypothese={data}
            resultat={resultat}
        />
    )
}

export default CessionIndirecte;