
import React from 'react';
import { scroller } from 'react-scroll';
import './style.scss'

const TitleAndLevel = (props) => {
    const { title, subtitle, text, steps, withPlan } = props;

    const classes = {
        title: {
            color: "#32396C",
            fontSize: 20,
            marginTop: "2%",
            fontWeight: "bold",
            marginBottom: '1%'
        },
        subtitle: {
            marginBottom: '0.5%',
            color: '#28366D',
            fontSize: 16,
            fontWeight: 'bold'
        },
        texte: {
            color: 'rgb(103,106,115)',//"#5271C7",
            fontSize: 14
        },
        stepsBloc: {
            marginTop: "1.5%",
            fontSize: 15,
            fontWeight: "600"
        },
        li: {
            listStyle: 'none',
            marginBottom: 7.5
        }
    }

    const scrollToId = (lien, type) => {
        scroller.scrollTo(lien, {
            duration: 800,
            delay: 0,
            smooth: "easeInOutQuart",
            offset: type !== 'level' ? -95 : -97
        });
    }

    return (
        <>
            {
                title && (
                    <div style={classes.title}>
                        {title}
                    </div>
                )
            }
            {
                subtitle && (
                    <div style={classes.subtitle} >
                        {subtitle}
                    </div>
                )
            }
            {
                text && (
                    <div style={classes.texte} >
                        {text}
                    </div>
                )
            }

            {
                withPlan && (
                    <div style={classes.stepsBloc}>
                        <ul>
                            {
                                steps?.map((data, index) => {
                                    return (
                                        <li key={index} style={{ listStyle: 'none', marginBottom: 12, color: '#28366D' }}>
                                            {
                                                data?.levels?.length > 0 ? (
                                                    <>
                                                        {`PARTIE ${index + 1} : ${data.title}`}
                                                        <ul>
                                                            {
                                                                data.levels.map((donnee, indice) => {
                                                                    return (
                                                                        <li key={index +'-'+ indice} onClick={() => { scrollToId(donnee.href, 'level') }} className='lienAncre' style={{ marginBottom: 6, fontWeight: 'normal', color: '#28366D' }} ><a style={{ textDecoration: ' none' }} >{donnee.text}</a></li>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                    </>
                                                ) : (
                                                    <span className='lien-partie' onClick={() => scrollToId(data.href)} >{`Partie ${index + 1} : ${data.title}`}</span>
                                                )
                                            }

                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                )
            }
        </>
    )
}

export default TitleAndLevel;
