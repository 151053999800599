import { CImg } from '@coreui/react';
import React from 'react';
import ErreurConnexionSimultanee from '../../assets/images/erreur-connexion-simultanee.svg';
import LogoSimulabox from '../../assets/images/simuConnex.svg';
import { useHistory } from 'react-router';
import './style.scss';

const ExpiredSessionComponent = () => {
    const history = useHistory();

    return (
        <div style={{ height: window.innerHeight, display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', width: '370px', margin: '0 auto' }}>
            {/** Message d'erreur */}
            <div style={{ display: 'flex', alignItems: 'center', background: 'rgb(255,237,236)', borderRadius: 5, marginBottom: 10, padding: 15, color: 'rgb(236,97,91)', fontWeight: 'bold' }}>
                <CImg src={ErreurConnexionSimultanee} style={{ width: 60 }} />
                <div style={{ marginLeft: 15, fontSize: 12 }}>Votre session a été interrompue automatiquement en raison d'une connexion simultanée détectée sur votre compte.</div>
            </div>

            {/** Logo de SIMULABOX */}
            <div style={{ width: '100%', height: '360px', marginBottom: 10, background: 'rgb(21,37,83)', borderRadius: 5, padding: 10, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <CImg
                    src={LogoSimulabox}
                    style={{ width: '60%', borderBottomLeftRadius: 10, borderTopLeftRadius: 10 }}
                />
            </div>

            {/** Bouton Se connecter */}
            <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                <div className='bouton-se-connecter' onClick={() => history.push('/login') } style={{ width: '130px', background: 'rgb(27,90,166)', color: '#FFFFFF', padding: 8, borderRadius: 5, textAlign: 'center', cursor: 'pointer' }}>Se connecter</div>
            </div>
        </div>
    )
}

export default ExpiredSessionComponent;