import React from 'react';
import useStyles from './style';
import { numberFormat } from '../../../../../../extra/all_ppt_render';
import CircleGraph from '../../../../../../components/simulateur/CircleGraph';

const titleScenario = (number) => {
    if (number === 1) return <div>Imposition à la flat-tax sans régime de faveur</div>;
    else if (number === 2) return <div>Optimisation des régimes fiscaux</div>
    else if (number === 3) return <div>Des frais et droits de donation par <u style={{fontWeight:'bold'}}> les donateurs</u></div>;
    else return <div>Des frais et droits de donation par <u style={{fontWeight:'bold'}}> les donataires</u></div>;
};

const impots = (number) => {
    if (number === 1 || number === 2) return "Impôts sur PV";
    else return "Impôts et droits";
};

const Scenario = (props) => {
    const classes = useStyles();
    const data = props.data;

    return (
        <div className={classes.root}>
            <div className={classes.part1}>
                <div style={{ color: "#373737" }}>{`SCENARIO ${props.number}`}</div>
                <div style={{ height: '42px', color: "#7F869A", fontWeight:'300' }} >{titleScenario(props.number)}</div>
            </div>

            <div style={{ alignItems: "center", fontSize:13 }} className={classes.part2} >
                <div style={{
                    color: "#373737",
                    borderRadius: "0px 23px 23px 0px",
                    display: "flex",
                    flexDirection: "column",
                    background: "#FFCED7",
                    justifyContent: "center",
                    paddingLeft: 8,
                    height: 62
                }} >
                    <div style={{ background: "#F04F6C", width: 4, height: 62, borderRadius: 4, position: "absolute", marginLeft: -15 }}></div>
                    <span style={{ fontWeight: 'bold', textAlign: "left", fontSize: 19, color: "#F04F6C", marginTop:-3 }}>
                        {
                            data &&
                                data.impot_sur_plus_value ? numberFormat(Math.round(data.impot_sur_plus_value)) : 0
                        } €
                    </span>
                    <div style={{ width: 100 }}>
                        {impots(props.number)}
                    </div>
                </div>

                <div style={{ color: "rgb(238,88,76)", fontWeight: 'bold' }} >
                    <CircleGraph
                        style={{ height: '80px', width: '80px' }}
                        titre={props.number === 1 || props.number === 2 ? "Impôts sur PV" : "Impôts et droits"}
                        pourcentage={data && data.taux_impot_sur_pv ? parseFloat(data.taux_impot_sur_pv).toFixed(2) : 0}
                        baseColor="#F04F6C"
                        baseColorOpacity="#FFCED7"
                    />
                </div>
            </div>

            <div className={classes.part3} style={{fontSize:13}} >
                <div style={{
                    color: "#373737",
                    borderRadius: "0px 23px 23px 0px",
                    display: "flex",
                    flexDirection: "column",
                    background: "#C3E8D3",
                    justifyContent: "center",
                    paddingLeft: 8,
                    height: 62
                }} >
                    <div style={{ background: "#279757", width: 4, height: 62, borderRadius: 4, position: "absolute", marginLeft: -15 }}></div>
                    <span style={{ fontWeight: 'bold', textAlign: "left", fontSize: 19, color: "#279757", marginTop: -3 }}>
                        {
                            data &&
                                data.net_cession ? numberFormat(Math.round(data.net_cession)) : 0
                        } €
                    </span>
                    <div style={{ width: 100 }}>
                        Net de cession
                    </div>
                </div>

            </div>

            <div className={classes.part4} style={{fontSize:13}}>
                {
                    props.number === 1 ? (
                        <>
                            <div style={{ fontWeight: 'bold', color: "#373737" }} >SITUATION DE REFERENCE</div>
                        </>
                    ) : (
                        <>
                            <div style={{
                                color: "#373737",
                                borderRadius: "0px 23px 23px 0px",
                                display: "flex",
                                flexDirection: "column",
                                background: "#F5F0D9",
                                justifyContent: "center",
                                paddingLeft: 8,
                                height: (props.number === 3 || props.number === 4) ? 90 : 62 
                            }} >
                                <div style={{ background: "#EDC413", width: 4, height:(props.number === 3 || props.number === 4) ? 90 : 62, borderRadius: 4, position: "absolute", marginLeft: -15 }}></div>
                                <span style={{ fontWeight: 'bold', textAlign: "left", fontSize: 19, color: "#EDC413", marginTop: -3 }}>
                                    {
                                        data &&
                                            data.gain_fiscale ? numberFormat(Math.round(data.gain_fiscale)) : 0
                                    } €
                                </span>
                                <div style={{ width: 100 }}>
                                    Gain fiscal
                                </div>
                                {(props.number === 3 || props.number === 4) && (<div style={{ display:"flex",justifyContent:"end",marginRight:17,marginTop:6,color:"#9C9C9C",fontSize:12 }} >Donation incluse</div>)}
                            </div>
                        </>
                    )
                }
            </div>
        </div>
    );
};

export default Scenario;