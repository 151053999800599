import React, { useContext } from 'react';
import CIcon from '@coreui/icons-react'
import { CCard, CCardBody, CCol, CForm, CFormGroup, CRow, CLabel } from '@coreui/react';
import { InputCurrency, InputAge } from '../../../components/input';
import Table from './Table/Table';
import { numberFormat } from '../../../extra/all_ppt_render';
import Information from '../../../components/Information/Information';
import { LayoutContext } from '../../../App2';
import { currencyToSend } from './UsufruitViager/UsufruitViager';
import { boxDonationTitleStyle } from '..';
import { PieChart, Pie, Sector, Cell, ResponsiveContainer, Legend, Label, Tooltip } from 'recharts';
import FirstForm from '../Donation/FirstBloc/FirstForm/FirstForm';
import InfoBulle from '../../../components/InfoBulle/InfoBulle';
import FicheThematique from '../FicheThematique/FicheThematique';
import { formatToUniqueId } from '../../../utils';

const style = {
    root: {
        display: 'flex',
        flexDirection: 'column',
        // marginTop: 10
    },
    secondContent: {
        marginTop: 20,
        display: 'flex',
        flexDirection: 'row',
        width: '100%'
    },
    tableContent: {
        display: 'flex',
        flexDirection: 'row',
        width: '60%',
        fontSize: 13
    }
};

const UsufruitNPLayout = (props) => {
    let {
        type,
        firstForm,
        lastForm,
        rows,
        rows2,
        rows3,
        headerWithHeight,
        circleGrapheData
    } = props;


    // Les données du Graphe
    const { layoutState, setLayoutState } = useContext(LayoutContext);
    const { simulateurState } = layoutState;

    const petitCarre = () => {
        return (
            <div style={{ width: 25, height: 8, backgroundColor: 'rgb(244,127,79)' }}></div>
        )
    }

    const pie = {
        labels: [
            circleGrapheData[0] && circleGrapheData[0].label ? `${circleGrapheData[0].label}/${circleGrapheData[0].value} %/${numberFormat(circleGrapheData[0].realValue)} €` : "",
            circleGrapheData[1] && circleGrapheData[1].label ? `${circleGrapheData[1].label}/${circleGrapheData[1].value} %/${numberFormat(circleGrapheData[1].realValue)} €` : "",
        ],
        datasets: [
            {
                data: [
                    circleGrapheData[0] && circleGrapheData[0].value ? circleGrapheData[0].value : 0,
                    circleGrapheData[1] && circleGrapheData[1].value ? circleGrapheData[1].value : 0
                ],
                backgroundColor: [
                    'rgb(244,127,79)',
                    'rgb(238,70,139)',
                ],
                hoverBackgroundColor: [
                    'rgb(244,127,79)',
                    'rgb(238,70,139)',
                ],
            }],
    };

    const dataPie = [
        { name: "usuFruit", value: circleGrapheData[0] && circleGrapheData[0].value ? circleGrapheData[0].value : 0 },
        { name: "NP", value: circleGrapheData[1] && circleGrapheData[1].value ? circleGrapheData[1].value : 0 }
    ]

    const COLORS = ['rgb(244,127,79)', 'rgb(238,70,139)']

    const handleFormChange = (event, type) => {
        const { value } = event.target;

        if (type === 'first') {
            simulateurState[props.type === 'usufruit-viager' ? 'usufNPusufViager' : 'usufNPusufTemporaire'].firstForm.value = value;
        }
        else {
            simulateurState[props.type === 'usufruit-viager' ? 'usufNPusufViager' : 'usufNPusufTemporaire'].lastForm.value = value;
        }

        props.onSimulateurStateChange((prevState) => {
            return {
                ...prevState,
                simulateurState
            }
        });
    };

    const factory = (entry) => {
        const data = entry.split("/")
        const d1 = data[0];
        const d2 = data[1];
        const d3 = data[2];
        return (
            <div style={{ lineHeight: 0.8, marginTop: 2 }}>
                <b> <p style={{ textAlign: "left" }}>{d1}</p> </b> <br />
                <b> <p style={{ textAlign: "left" }}>{d2}</p> </b> <br />
                <p style={{ textAlign: "left" }}>{d3}</p> <br />
            </div>
        )
    }

    const contentLegend = (payload) => {
        // const { payload } = props;
        return (
            <div style={{ marginRight: 0 }}>
                {
                    payload.map((entry, index) => (
                        <div style={{ marginTop: 15 }}>
                            <div style={{ width: 11, height: 11, backgroundColor: `${COLORS[index % COLORS.length]}`, position: "absolute", marginLeft: -24, marginTop: 0 }}></div>
                            <div key={`item-${index}`} style={{ color: "#393939", display: 'block' }}><p>{factory(entry)}</p></div>
                        </div>
                    ))
                }
            </div>
        )
    }

    return (
        <div style={style.root} >
            <div id="formulairePEA">
                <CCol xl={12}>
                    <CForm className="col-lg-12">
                        <CCard>
                            <CCardBody>
                                <CRow>
                                    <CCol sm={12}>
                                        <div style={{
                                            background: "rgb(250,250,250)",
                                            padding: 8,
                                            borderLeft: '6px solid',
                                            borderImage: 'linear-gradient(rgb(244,127,79),rgb(238,70,139))',
                                            borderImageSlice: 1,
                                            borderRadius: '5px',
                                            boxSizing: 'content-box'
                                        }}
                                        >
                                            <CFormGroup className="form-inline col-lg-12">
                                                <CLabel style={{ fontWeight: 'bold', fontSize: 13 }} htmlFor="nom" className="col-lg-4 col-md-6 col-sm-7">{firstForm.label}</CLabel>
                                                <div className='col-lg-2 pl-0 pr-0' style={{ display: 'flex', justifyContent: 'flex-end' }} >
                                                    <InputAge
                                                        type={type}
                                                        id={`${formatToUniqueId("UsufruitNPLayout first")}-${type}-age`}
                                                        className="col-lg-6"
                                                        value={parseInt(firstForm.value, 10) <= 0 ? "" : firstForm.value}
                                                        onChange={(event) => handleFormChange(event, 'first')}
                                                        style={{ fontSize: 13 }}
                                                    />
                                                </div>
                                                {
                                                    type === 'usufruit-temporaire' && (
                                                        <InfoBulle
                                                            information="La durée de l'usufruit temporaire est de 30 ans maximum"
                                                        />
                                                    )
                                                }
                                            </CFormGroup>
                                            <CFormGroup className="form-inline col-lg-12">
                                                <CLabel style={{ fontWeight: 'bold', fontSize: 13 }} htmlFor="nom" className="col-lg-4 col-md-6 col-sm-7">{lastForm.label}</CLabel>
                                                <InputCurrency
                                                    id={`${formatToUniqueId("UsufruitNPLayout contreValeur")}-${type}`}
                                                    className="col-lg-2 col-md-5 col-sm-5"
                                                    value={lastForm.value}
                                                    onChange={(event) => handleFormChange(event, 'last')}

                                                />
                                            </CFormGroup>
                                        </div>
                                        {
                                            (type === 'usufruit-viager' || type === 'usufruit-temporaire') && (
                                                <div style={style.secondContent}>
                                                    <div style={style.tableContent}>
                                                        <div style={{ width: '33%', marginRight: '2%' }} >
                                                            <Table
                                                                headerWithHeight={headerWithHeight}
                                                                isTable1={true}
                                                                tableTitle={type === 'usufruit-viager' ? "Age de l'usufruitier" : "Durée de l'usufruit"}
                                                                data={rows}
                                                            />
                                                        </div>
                                                        <div style={{ width: '33%', marginRight: '2%' }} >
                                                            <Table
                                                                headerWithHeight={headerWithHeight}
                                                                tableTitle={type === 'usufruit-viager' ? "Valeur fiscale de l'usufruit" : "Valeur fiscale de l'usufruit à durée fixe"}
                                                                data={rows2}
                                                            />
                                                        </div>
                                                        <div style={{ width: '33%' }} >
                                                            <Table
                                                                headerWithHeight={headerWithHeight}
                                                                tableTitle="Valeur fiscale de la nue-propriété"
                                                                data={rows3}
                                                            />
                                                        </div>
                                                    </div>
                                                    {
                                                        ((currencyToSend(firstForm.value) !== 0) || (currencyToSend(lastForm.value)) !== 0) && (
                                                            <>

                                                                <div style={{ width: "40%", padding: 0 }}>
                                                                    {/* <div style={{width: "40%", marginLeft:105, display:"flex"}}> */}
                                                                    <h6 className="text-center mt-3" style={{ fontSize: 13 }}><b>Valorisations fiscales</b></h6>
                                                                    {/* </div> */}
                                                                    <ResponsiveContainer width={'100%'} height={350}>
                                                                        <PieChart width={400} height={400} margin={{ right: 80 }}>
                                                                            <Pie isAnimationActive={true} data={dataPie} dataKey="value" cx="45%" cy="45%" outerRadius="40%" startAngle={-270} fill="#8884d8">
                                                                                {dataPie.map((entry, index) => (
                                                                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                                                                ))}
                                                                            </Pie>
                                                                            <Tooltip />
                                                                            <Legend style={{ fontSize: 13 }} layout="vertical" verticalAlign="middle" align="right" content={contentLegend(pie.labels)}></Legend>
                                                                        </PieChart>
                                                                    </ResponsiveContainer>

                                                                </div>
                                                            </>
                                                        )
                                                    }
                                                </div>
                                            )
                                        }
                                    </CCol>
                                </CRow>
                            </CCardBody>
                        </CCard>

                        {/** Les informations */}
                        <div>
                            <div style={{ marginBottom: 15 }}>
                                <Information
                                    text={
                                        <>
                                            {
                                                type === 'usufruit-viager' ? (
                                                    <>
                                                        <b><u>La valorisation fiscale de l'usufruit</u></b> et de la nue-propriété est <b>obligatoire</b> pour l'ensemble des droits d'enregistrement et notamment pour le calcul de la valeur imposable aux droits de donation et de succession. Elle est déterminée en application du barème de l'article 669 du Code Général des Impôts(CGI) en fonction de l'âge de l'usufruitier s'agissant d'un usufruit viager. Pour les opérations à titre onéreux (vente, apport, remboursement, etc), la valorisation fiscale peut servir de référence mais elle n'est pas obligatoire et une valorisation économique est souvent privilégiée.
                                                    </>) : (
                                                    <>
                                                        <b><u>La valorisation fiscale de l'usufruit</u></b> et de la nue-propriété est <b>obligatoire</b> pour l'ensemble des droits d'enregistrement et notamment pour le calcul de la valeur imposable aux droits de donation et de succession. Elle est déterminée en application du barème de l'article 669 du Code Général des Impôts(CGI) en fonction de l'âge de l'usufruitier s'agissant d'un usufruit viager. Pour les opérations à titre onéreux (vente, apport, remboursement, etc), la valorisation fiscale peut servir de référence mais elle n'est pas obligatoire et une valorisation économique est souvent privilégiée.
                                                    </>
                                                )
                                            }
                                        </>
                                    }
                                />
                            </div>
                            {
                                type === 'usufruit-temporaire' && (
                                    <div>
                                        <Information
                                            title={<u>La valorisation fiscale de l'usufruit à durée fixe(ou usufruit temporaire)</u>}
                                            text={
                                                <>
                                                    <ol style={{ margin: 0, padding: 0, paddingLeft: 11 }}>
                                                        <li>La valeur fiscale de l'usufruit temporaire est estimée à 23% de la valeur en pleine propriété pour chaque période de 10 ans, sans fraction, ni prorata. Mais, la valeur ainsi calculée ne peut pas, le cas échéant, dépasser la valeur fiscale de l'usufruit viager qui constitue une évaluation maximale.</li>
                                                        <li>Si l'usufruitier viager décède avant l'arrivée du terme prévu pour l'usufruit temporaire, l'usufruit prendra fin dès la date du décès.</li>
                                                        <li>Lorsque l'usufruitier est une personne morale, la durée de l'usufruit ne peut pas être supérieure à 30 ans.</li>
                                                    </ol>
                                                </>
                                            }
                                        />
                                    </div>
                                )
                            }
                        </div>
                    </CForm>
                </CCol>
            </div>
        </div>
    );
};

export default UsufruitNPLayout;
