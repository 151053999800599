import { restApiUri } from "../../../config";

const succession_simulation_uri = `${restApiUri}/api/succession/simuler_succession`;
const succession_uri = `${restApiUri}/api/succession`;
const client_uri = `${restApiUri}/api/client`;


export const cloneSuccession = (data) => {
    const dataToSend = {
        userId: JSON.parse(localStorage.getItem("user"))["id"],
        clientId: data.client ? data.client.id : null,
        ...data,
    };
    delete dataToSend['client']
    return dataToSend
}


export const getClient = async (id) => {
        const query = id != null ? await fetch(`${client_uri}/${id}`, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${localStorage.getItem("token")}`
        }
    }) : {}

    return query.json();
}

export const launchSimulation = async (config) => {
    const query = await fetch(succession_simulation_uri, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${localStorage.getItem("token")}`
        },
        body: JSON.stringify(config),
        cache: 'no-cache'
    })

    return query.json();
}


export const saveSuccession = async (succession, id=undefined) => {
    const url = id ? `${succession_uri}/${id}` : succession_uri;
    const query = await fetch(url, {
        method: id ? 'PUT' : 'POST',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${localStorage.getItem("token")}`
        },
        body: JSON.stringify(succession),
        cache: 'no-cache'
    })
    return query.json();
}

export const getSuccession = async (id=undefined) => {
    if(!id) {        
        return;
    }
    const query = await fetch(`${succession_uri}/findOne/${id}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${localStorage.getItem("token")}`
        },
    })

    return query.json();
}