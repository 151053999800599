export const search_chart = ["32 32", `
<g id="svg_1" transform="translate(0, 32) scale(0.02, -0.02)">
<path d="M396 1409 c-170 -40 -336 -208 -375 -379 -13 -58 -13 -202 0 -260 41
-178 208 -342 389 -381 31 -7 96 -9 154 -7 110 6 176 26 254 79 25 17 46 29
47 27 1 -2 26 -30 54 -62 43 -50 51 -65 51 -99 0 -38 7 -47 123 -162 132 -131
163 -156 204 -161 47 -5 113 44 146 110 28 52 7 78 -241 310 -62 58 -64 59
-111 53 -45 -5 -49 -4 -100 41 l-53 47 30 46 c54 79 74 144 80 255 2 58 0 123
-7 154 -39 181 -203 348 -381 389 -54 13 -210 12 -264 0z m332 -104 c175 -87
276 -275 250 -468 -32 -240 -267 -418 -510 -385 -241 32 -419 267 -386 510 5
38 22 92 44 136 110 226 377 317 602 207z"/>
<path d="M361 1138 c-8 -21 -62 -147 -94 -220 -10 -24 -18 -28 -53 -28 -49 0
-74 -21 -54 -45 16 -19 113 -21 127 -2 5 6 27 53 47 102 21 50 41 93 45 97 3
4 28 -90 55 -208 27 -118 55 -223 62 -232 22 -29 38 3 79 158 21 80 41 151 45
158 4 7 21 -28 39 -82 43 -124 55 -125 107 -16 37 79 38 80 74 80 45 0 69 22
50 45 -10 12 -28 15 -64 13 l-51 -3 -31 -65 c-29 -61 -31 -63 -38 -37 -3 15
-20 66 -37 113 -27 72 -34 85 -52 82 -17 -3 -27 -26 -62 -158 l-42 -155 -11
40 c-6 22 -27 112 -47 200 -20 88 -41 165 -47 172 -16 20 -38 15 -47 -9z"/>
  </g>
`]
