import React from "react";
import "./style.scss";

const Switch = (props) => {
  const {
    checked,
    onChange,
    background,
    borderColor
  } = props;

  return (
    <div className="toggle-switchGreen" onClick={onChange}>
      <input type="checkbox" checked={checked} readOnly />
      <span className="switchGreen" style={{ background: background, borderColor: borderColor }} />
    </div>
  );
}

export default Switch;
