import React, { createContext, useContext, useEffect, useState } from 'react';
import IdentiteActionnaire from './IdentiteActionnaire/IdentiteActionnaire';
import OperationAvantCession from './OperationAvantCession/OperationAvantCession';
import ProjetCessionSociete, { calculIPV } from './ProjectionCessionSociete/ProjetCessionSociete';
import ResultatSimulation from './ResultatSimulation/ResultatSimulation';
import { useHistory, useParams } from 'react-router-dom';
import Loading from '../../../components/loader';
import { numberFormat } from '../../../extra/all_ppt_render';
import { LayoutContext } from '../../../App2';
import { restApiUri } from '../../../config'
import aide from '../../../assets/images/aide.png';
import ResultatsDetailles from './ResultatsDetailles/ResultatsDetailles';
import '../../../containers/header.scss';
import { SimulateurContext } from '../../../store/context/simulateur';
import { updateSimulation } from '../../../store/actions/simulateur';
import { DataControlContext } from '../../../store/context/dataControl';
import SimulateurContainerWithMenu from '../../../components/SimulateurContainerWithMenu/SimulateurContainerWithMenu';

// Données initialisées pour chaque section => DEFAULT DATA
let idSimulation = undefined;
let nom = "brouillon";
let commentaire = "";
let terme = "Fin d'année";
let clientId = 1;
export let userId = JSON.parse(localStorage.getItem('user')) && JSON.parse(localStorage.getItem('user')).id ? JSON.parse(localStorage.getItem('user')).id : null;

const valueByPrenom = (enfants, prenom) => {
    const value = enfants.find((element) => element.nom === prenom);
    const index = enfants.findIndex((element) => element.nom === prenom);

    return {
        value,
        index
    };
};

const identiteActionnaire = {
    conjoints: [
        {
            nomPersonnalise: '',
            dateNaissance: '',
            age: 0,
            dateApproximative: false,
            marieOuPasce: "Oui",
            revenuAnneeCession: 0,
            donationPP: 0,
            apportTitres: 0,
            soldeValeurTitresPP: 0,
            soldeValeurTitresUSUF: 0,
            valeurFiscale: 0,
            fraisDonation: 0,
            tauxMarginalImposition: 0
        },
        // On prévoit en avance le Conjoint 2 et on l'affiche une fois que le marieOuPasce pour le Conjoint1 est à TRUE
        {
            nomPersonnalise: '',
            dateNaissance: '',
            age: 0,
            dateApproximative: false,
            revenuAnneeCession: 0,
            donationPP: 0,
            apportTitres: 0,
            soldeValeurTitresPP: 0,
            soldeValeurTitresUSUF: 0,
            valeurFiscale: 0,
            fraisDonation: 0,
            tauxMarginalImposition: 0
        }
    ],
    nombreEnfants: 0,
    enfants: [],
    affichageNomPersonnalise: true,
    idSimulationOnSave: undefined,
    dateSimulation: null
};

const projetCessionSociete = {
    prixCession: "",
    actionnairesFamiliauxPP: "",
    actionnairesFamiliauxDP: "",
    conjoints: [
        {
            pourcentageDetention: "",
            valeurTitres: "",
            prixRevientFiscal: "",
            regimeFiscal: "Non éligible",
            pourcentage85: 100,
            eligible500: "Non éligible",
            ipv: {
                default: 0,
                real: 0
            }
        },
        {
            pourcentageDetention: "",
            valeurTitres: "",
            prixRevientFiscal: "",
            regimeFiscal: "Non éligible",
            pourcentage85: 100,
            eligible500: "Non éligible",
            ipv: 0
        },
    ],
    enfantsFamiliauxPP: [],
    holding: {
        nomPersonnalise: "",
        pourcentageDetention: "",
        valeurTitres: "",
        prixRevientFiscal: "",
        regimeFiscal: "Régime des PV LT",
        anneeOuverture: "2022 et suivants"
    },
    enfantsFamiliauxDP: [
        {
            conjoint: 1,
            enfants: []
        },
        {
            conjoint: 2,
            enfants: []
        },
    ],
    total: {},
    idSimulationOnSave: undefined
};

const operationsAvantCession = {
    apports: [],
    donations: [
        {
            conjointDonateur: 1,
            enfants: []
        },
        {
            conjointDonateur: 2,
            enfants: []
        },
    ],
    synthese: undefined,
    idSimulationOnSave: undefined
};

const tb1 = {};
const tb2 = {};
const syntheseDetaille = {};

export const cessionHautData = {
    idSimulation,
    id: nom,
    commentaire,
    terme,
    clientId,
    userId,
    identiteActionnaire,
    projetCessionSociete,
    operationsAvantCession,
    tb1,
    tb2,
    syntheseDetaille
};


export const CessionContext = createContext({
    cessionHautData,
    setCessionHautData: (cessionHautData) => { }
}
);

// Structuration des données reçues par un API au cas où c'est une modification
export const structurationData = (donnee) => {
    const identite = identiteActionnaire;
    const conjoint1 = donnee.conjoint1;
    const conjoint2 = donnee.conjoint2;
    const enfants = donnee.liste_enfant;

    /************Informations de la simulation */
    nom = donnee.identifiant_simulation;
    commentaire = donnee.commentaire;
    terme = donnee.terme_simulation;
    clientId = donnee.clientId;
    userId = donnee.userId;

    //**********IDENTITE DES ACTIONNAIRES */

    // Pour le conjoint 1
    identite.conjoints[0].nomPersonnalise = conjoint1.nom !== "" ? conjoint1.nom : "Conjoint 1";
    identite.conjoints[0].age = conjoint1.age;
    identite.conjoints[0].dateApproximative = conjoint1.date_approximatif === 'true';
    identite.conjoints[0].dateNaissance = conjoint1.date_naiss;
    identite.conjoints[0].marieOuPasce = conjoint1.marie ? "Oui" : "Non";
    identite.conjoints[0].revenuAnneeCession = conjoint1.revenu ? numberFormat(conjoint1.revenu) : 0;
    identite.conjoints[0].donationPP = numberFormat(donnee.liste_donation[0].conjoint.donation_pp);
    identite.conjoints[0].valeurFiscale = numberFormat(donnee.liste_donation[0].conjoint.valeur_fiscale);
    identite.conjoints[0].apportTitres = numberFormat(conjoint1.apport_titre);
    identite.conjoints[0].soldeValeurTitresPP = 0;
    identite.conjoints[0].soldeValeurTitresUSUF = 0; // A revoir
    identite.conjoints[0].fraisDonation = 0;
    identite.affichageNomPersonnalise = true;
    identite.dateSimulation = donnee.date_simulation;

    // Pour le conjoint 2 s'il y a de Conjoint 2
    if (conjoint2 && conjoint1.marie) {
        identite.conjoints[1].nomPersonnalise = conjoint2.nom !== "" ? conjoint2.nom : "Conjoint 2";
        identite.conjoints[1].age = conjoint2.age;
        identite.conjoints[1].dateApproximative = conjoint2.date_approximatif === 'true';
        identite.conjoints[1].dateNaissance = conjoint2.date_naiss;
        identite.conjoints[1].revenuAnneeCession = conjoint2.revenu ? numberFormat(conjoint2.revenu) : 0;
        identite.conjoints[1].donationPP = numberFormat(donnee.liste_donation[1].conjoint.donation_pp);
        identite.conjoints[1].valeurFiscale = numberFormat(donnee.liste_donation[1].conjoint.valeur_fiscale);
        identite.conjoints[1].apportTitres = numberFormat(conjoint2.apport_titre);
        identite.conjoints[1].soldeValeurTitresPP = 0;
        identite.conjoints[1].soldeValeurTitresUSUF = 0;
        identite.conjoints[1].fraisDonation = 0;
    }

    // Nombre d'enfants
    identite.nombreEnfants = enfants.length;

    // Enfants
    const enfs = [];
    enfants.map((data, index) => {
        let enf = new Object();
        enf.id = data.id;
        enf.age = data.age ? data.age : 0;
        enf.dateNaissance = "";
        enf.enfantCharge = data.enfant_a_charge === 0 ? "Non" : data.enfant_a_charge === 1 ? "Oui" : "Rattaché sur option";
        enf.handicap = data.handicap ? true : false;
        enf.parents = data.parent === 0 ? "Enfant commun" : data.parent === 1 ? "Enfant du conjoint 1" : "Enfant du conjoint 2";
        enf.prenom = data.prenom !== "" ? data.prenom : `Enfant ${index + 1}`;
        enf.revenuAnnee = data.si_detache_revenu ? data.si_detache_revenu : 0;
        enf.situationFamiliale = data.si_detache_situation_familliale === 0 ? "Personne seule" : "Marié ou pacsé";
        enf.dateApproximative = false;

        enfs.push(enf);
    });
    identite.enfants = enfs;

    /***********PROJET DE CESSION DE SOCIETE */
    const projet = projetCessionSociete;

    // Prix de la cession
    projet.prixCession = numberFormat(donnee.prix_cession);

    // Actionnaires familiaux PP
    projet.actionnairesFamiliauxPP = donnee.pp.taux_pp;

    // Actionnaires familiaux NP
    projet.actionnairesFamiliauxDP = donnee.np.taux_np;

    // Les Conjoints en PROJET
    const conjoint1Projet = donnee.pp.conjoint1;
    const conjoint2Projet = donnee.pp.conjoint2;

    // CONJOINT 1
    projet.conjoints[0].pourcentageDetention = conjoint1Projet.pourcentage_detention;
    projet.conjoints[0].valeurTitres = conjoint1Projet.valeur_titre;
    projet.conjoints[0].prixRevientFiscal = conjoint1Projet.prix_fiscal;
    projet.conjoints[0].regimeFiscal = conjoint1Projet.regime_fiscale ? "Eligible" : "Non éligible";

    projet.conjoints[0].eligible500 = conjoint1Projet.eligible_abattement ? "Eligible" : "Non éligible";
    projet.conjoints[0].pourcentage85 = conjoint1Projet.taux_eligible;

    // CONJOINT 2
    if (conjoint2Projet && conjoint1.marie) {
        projet.conjoints[1].pourcentageDetention = conjoint2Projet.pourcentage_detention;
        projet.conjoints[1].valeurTitres = conjoint2Projet.valeur_titre;
        projet.conjoints[1].prixRevientFiscal = conjoint2Projet.prix_fiscal;
        projet.conjoints[1].regimeFiscal = conjoint2Projet.regime_fiscale ? "Eligible" : "Non éligible";

        projet.conjoints[1].eligible500 = conjoint2Projet.eligible_abattement ? "Eligible" : "Non éligible";
        projet.conjoints[1].pourcentage85 = conjoint2Projet.taux_eligible;
    }

    // Les enfants familiaux PP
    const enfantsPP = donnee.pp.liste_enfant;
    const tabEnfantsPP = [];

    enfantsPP.map((data, index) => {
        let enf = new Object();
        enf.pourcentageDetention = data.pourcentage_detention;
        enf.valeurTitres = data.valeur_titre;
        enf.prixRevientFiscal = data.prix_fiscal;
        enf.regimeFiscal = data.regime_fiscale ? "Eligible" : "Non éligible";
        enf.apportTitres = data.apport_titre;

        tabEnfantsPP.push(enf);
    });

    projet.enfantsFamiliauxPP = tabEnfantsPP;

    // HOLDING
    const holding = donnee.pp.holding;
    const hold = new Object();
    hold.nomPersonnalise = holding.nom;
    hold.pourcentageDetention = holding.pourcentage_detention;
    hold.valeurTitres = holding.valeur_titre;
    hold.prixRevientFiscal = holding.prix_fiscal;
    hold.regimeFiscal = holding.regime_fiscale ? "Régime des PV LT" : "Régime IS de droit commun";
    hold.anneeOuverture = donnee.pp.annee_ouverture === 2022 ? "2022 et suivants" : donnee.pp.annee_ouverture;

    projet.holding = hold;

    // Les enfants en USUFRUITIER
    const enfantsDP1 = donnee.np.conjoint1.liste_enfant;
    //const enfantsDP2 = donnee.np.conjoint1.liste_enfant;
    const enfantsDP2 = donnee.np.conjoint2.liste_enfant;

    const enfantsUSUF1 = [];
    const enfantsUSUF2 = [];

    for (let i = 0; i < enfants.length; i++) {
        let enf = new Object();
        let condAff = valueByPrenom(enfantsDP1, enfants[i].prenom);

        enf.pourcentageDetention = condAff.value ? condAff.value.pourcentage_detention : "";
        enf.valeurTitres = condAff.value ? condAff.value.valeur_titre : "";
        enf.prixRevientFiscal = condAff.value ? condAff.value.prix_fiscal : "";
        enf.regimeFiscal = condAff.value ? condAff.value.regime_fiscale ? "Eligible" : "Non éligible" : "Non éligible";
        enf.apportTitres = condAff.value ? condAff.value.apport_titre : "";

        enfantsUSUF1.push(enf);
    }
    projet.enfantsFamiliauxDP[0].enfants = enfantsUSUF1;


    if (enfantsDP2 && enfantsDP2.length && conjoint1.marie) {
        for (let i = 0; i < enfants.length; i++) {
            let enf = new Object();
            let condAff = valueByPrenom(enfantsDP2, enfants[i].prenom);

            enf.pourcentageDetention = condAff.value ? condAff.value.pourcentage_detention : "";
            enf.valeurTitres = condAff.value ? condAff.value.valeur_titre : "";
            enf.prixRevientFiscal = condAff.value ? condAff.value.prix_fiscal : "";
            enf.regimeFiscal = condAff.value ? condAff.value.regime_fiscale ? "Eligible" : "Non éligible" : "Non éligible";
            enf.apportTitres = condAff.value ? condAff.value.apport_titre : "";

            enfantsUSUF2.push(enf);
        }

        projet.enfantsFamiliauxDP[1].enfants = enfantsUSUF2;
    }

    // %IPV
    const ipvs = [{
        default: calculIPV(projet.conjoints, conjoint1.marie).default,
        real: parseFloat(donnee.payement_plus_value.conjoint1)
    }];
    if (conjoint1.marie) {
        ipvs.push(parseFloat(donnee.payement_plus_value.conjoint2))
    }

    ipvs.map((data, index) => {
        if (index === 0) {
            projet.conjoints[index].ipv.default = data.default;
            projet.conjoints[index].ipv.real = data.real;
        }
        else projet.conjoints[index].ipv = data;
    });

    /********Opérations avant cession */
    const operations = operationsAvantCession;

    const donations = donnee.liste_donation;
    const donationsConjoint1 = donations[0];
    const donationsConjoint2 = donations[1];

    const listeEnfants1 = donationsConjoint1 && donationsConjoint1.liste_enfant ? donationsConjoint1.liste_enfant : [];
    const listeEnfants2 = donationsConjoint2 && donationsConjoint2.liste_enfant ? donationsConjoint2.liste_enfant : [];

    const donations1 = [];
    const donations2 = [];

    for (let i = 0; i < enfants.length; i++) {
        let enf = new Object();
        let condAff = valueByPrenom(listeEnfants1, enfants[i].prenom);

        enf.valeurFiscale = condAff.value ? condAff.value.valeur_fiscale : "";
        enf.anneeDonation = condAff.value ? condAff.value.annee : 2020;
        //enf.abattementFiscal = condAff.value ? condAff.value.abattement_fiscale : 100000;
        enf.abattementFiscal = condAff.value && condAff.value.valeur_fiscale && condAff.value.valeur_fiscale > 0 ? condAff.value.abattement_fiscale : 0;
        enf.soldeValeurTitresPP = "";
        enf.soldeValeurTitresUSUF = "";
        enf.donationPP = condAff.value ? condAff.value.donation_pp : "";
        enf.donationNP = condAff.value ? condAff.value.donation_np : "";
        enf.fraisDonation = "";

        donations1.push(enf);
    }
    operations.donations[0].enfants = donations1;

    // Pour le Conjoint 2 s'il y a de Conjoint 2
    if (listeEnfants2.length && conjoint1.marie) {
        for (let i = 0; i < enfants.length; i++) {
            let enf = new Object();
            let condAff = valueByPrenom(listeEnfants2, enfants[i].prenom);

            enf.valeurFiscale = condAff.value ? condAff.value.valeur_fiscale : "";
            enf.anneeDonation = condAff.value ? condAff.value.annee : 2020;
            //enf.abattementFiscal = condAff.value ? condAff.value.abattement_fiscale : 100000;
            enf.abattementFiscal = condAff.value && condAff.value.valeur_fiscale && condAff.value.valeur_fiscale > 0 ? condAff.value.abattement_fiscale : 0;
            enf.soldeValeurTitresPP = "";
            enf.soldeValeurTitresUSUF = "";
            enf.donationPP = condAff.value ? condAff.value.donation_pp : "";
            enf.donationNP = condAff.value ? condAff.value.donation_np : "";
            enf.fraisDonation = "";

            donations2.push(enf);
        }

        operations.donations[1].enfants = donations2;
    }

    return {
        id: nom,
        commentaire,
        terme,
        clientId,
        userId,
        identiteActionnaire: identite,
        projetCessionSociete: projet,
        operationsAvantCession: operations
    }
};

const CessionHaut = () => {
    const { id, scenario } = useParams();
    const { cessionControlState, setCessionControlState } = useContext(DataControlContext)
    const history = useHistory()
    const [cessionHaut, setCessionHaut] = useState({
        idSimulation,
        id: nom,
        commentaire,
        terme,
        clientId,
        userId,
        identiteActionnaire,
        projetCessionSociete,
        operationsAvantCession,
        tb1,
        tb2,
        syntheseDetaille
    });

    const { layoutState } = useContext(LayoutContext);
    const { simulateurState, simulateurDispatch } = useContext(SimulateurContext);
    const { menuLevel: level } = layoutState;
    const currentMenuLevel = level.cs;

    const [loading, setLoading] = useState(false);

    const [developpedIcon, setDeveloppedIcon] = useState(false);

    const getSimulationCession = async (id) => {
        const query = await fetch(`${restApiUri}/api/cession/findCession/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        });

        return await query.json();
    };

    const redirectTo = () => {

        switch (currentMenuLevel) {
            case 1:
                history.push(`/cession/identite_actionnaire`)
                break
            case 2:
                history.push(`/cession/projet_cession`)
                break
            case 3:
                history.push(`/cession/operation_avant_cession`)
                break
            case 4:
                history.push(`/cession/resultat_synthetique`)
                break
            case 5:
                history.push(`/cession/resultat_detaille`)
                break
            default:
                break
        }
    }

    useEffect(() => {
        if (id) {
            idSimulation = id;
            setCessionHaut((prevState) => {
                return {
                    ...prevState,
                    idSimulation
                }
            });
            setLoading(true);
            getSimulationCession(id)
                .then((response) => {
                    const data = structurationData(response);
                    setCessionHaut(data);
                    simulateurDispatch(updateSimulation({
                        clientId: data.clientId,
                        commentaire: data.commentaire,
                        terme_simulation: data.terme,
                        identifiant_simulation: data.id,
                        userId: userId
                    }));
                })
                .finally(() => {
                    setLoading(false)
                    redirectTo()
                })
        }
        else redirectTo()
    }, [currentMenuLevel]);

    useEffect(() => {
        if (cessionControlState.activeSimu == false) {
            cessionControlState.activeSimu = true;
            setCessionControlState(cessionControlState);
            const data = {
                idSimulation,
                id: nom,
                commentaire,
                terme,
                clientId,
                userId,
                identiteActionnaire: {
                    conjoints: [
                        {
                            nomPersonnalise: '',
                            dateNaissance: '',
                            age: 0,
                            dateApproximative: false,
                            marieOuPasce: "Oui",
                            revenuAnneeCession: 0,
                            donationPP: 0,
                            apportTitres: 0,
                            soldeValeurTitresPP: 0,
                            soldeValeurTitresUSUF: 0,
                            valeurFiscale: 0,
                            fraisDonation: 0,
                            tauxMarginalImposition: 0
                        },
                        // On prévoit en avance le Conjoint 2 et on l'affiche une fois que le marieOuPasce pour le Conjoint1 est à TRUE
                        {
                            nomPersonnalise: '',
                            dateNaissance: '',
                            age: 0,
                            dateApproximative: false,
                            revenuAnneeCession: 0,
                            donationPP: 0,
                            apportTitres: 0,
                            soldeValeurTitresPP: 0,
                            soldeValeurTitresUSUF: 0,
                            valeurFiscale: 0,
                            fraisDonation: 0,
                            tauxMarginalImposition: 0
                        }
                    ],
                    nombreEnfants: 0,
                    enfants: [],
                    affichageNomPersonnalise: false,
                    idSimulationOnSave: undefined,
                },
                projetCessionSociete: {
                    prixCession: "",
                    actionnairesFamiliauxPP: "",
                    actionnairesFamiliauxDP: "",
                    conjoints: [
                        {
                            pourcentageDetention: "",
                            valeurTitres: "",
                            prixRevientFiscal: "",
                            regimeFiscal: "Non éligible",
                            pourcentage85: 100,
                            eligible500: "Non éligible",
                            ipv: {
                                default: 0,
                                real: 0
                            }
                        },
                        {
                            pourcentageDetention: "",
                            valeurTitres: "",
                            prixRevientFiscal: "",
                            regimeFiscal: "Non éligible",
                            pourcentage85: 100,
                            eligible500: "Non éligible",
                            ipv: 0
                        },
                    ],
                    enfantsFamiliauxPP: [],
                    holding: {
                        nomPersonnalise: "",
                        pourcentageDetention: "",
                        valeurTitres: "",
                        prixRevientFiscal: "",
                        regimeFiscal: "Régime des PV LT",
                        anneeOuverture: "2022 et suivants"
                    },
                    enfantsFamiliauxDP: [
                        {
                            conjoint: 1,
                            enfants: []
                        },
                        {
                            conjoint: 2,
                            enfants: []
                        },
                    ],
                    total: {},
                    idSimulationOnSave: undefined
                },
                operationsAvantCession: {
                    apports: [],
                    donations: [
                        {
                            conjointDonateur: 1,
                            enfants: []
                        },
                        {
                            conjointDonateur: 2,
                            enfants: []
                        },
                    ],
                    synthese: undefined,
                    idSimulationOnSave: undefined
                },
                tb1: {},
                tb2: {},
                syntheseDetaille: {}
            }
            setCessionHaut(data);
        }
    }, [])

    useEffect(() => {
    }, [cessionHaut])

    return !loading ? (
        <CessionContext.Provider value={{ cessionHaut, setCessionHaut }}>
            <div style={{ paddingTop: currentMenuLevel !== 5 ? '15px' : 0 }} >
                {/* {
                    currentMenuLevel === 1 && (<IdentiteActionnaire />)
                }

                {
                    currentMenuLevel === 2 && (<ProjetCessionSociete id={id} />)
                }

                {
                    currentMenuLevel === 3 && (<OperationAvantCession />)
                }

                {
                    currentMenuLevel === 4 && (<ResultatSimulation />)
                }
                {
                    currentMenuLevel === 5 && (<ResultatsDetailles />)
                } */}
                <SimulateurContainerWithMenu
                    menus={[
                        {
                            label: "Identité des actionnaires",
                            content: (
                                <IdentiteActionnaire />
                            )
                        },
                        {
                            label: "Projet de cession de société",
                            content: (
                                <ProjetCessionSociete id={id} />
                            )
                        },
                        {
                            label: "Opérations avant cession",
                            content: (
                                <OperationAvantCession />
                            )
                        },
                        {
                            label: "Résultats synthétiques",
                            content: (
                                <ResultatSimulation />
                            )
                        },
                        {
                            label: "Résultats détaillés",
                            content: (
                                <ResultatsDetailles />
                            )
                        }
                    ]}
                />
            </div>
        </CessionContext.Provider>
    )
        : (<Loading />)
};

export default CessionHaut;