import React, { Component } from "react";
import NumberFormat from 'react-number-format';

export default class TextCurrency extends Component {
    render() {
        return(
            <NumberFormat
                id={this.props.id}
                style={{textAlign:'right'}}
                displayType='text'
                value={this.props.value}
                thousandSeparator={' '}
            />
        )
    }
}