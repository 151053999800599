import React from 'react';
import { leftBackground, plusValueBackground, rightBackground, WhiteTableSpace } from '../TableRevenuFoncier/TableRevenuFoncier';
import OrangeInfo from '../../../../../assets/images/svg/PvImmo/ORANGE_INFO.svg';
import './style.scss';

const TableLocationMeublee = () => {
    return (
        <table cellPadding={10} cellSpacing={10} style={{ width: '100%', textAlign: 'left' }} >
            <tr style={{ marginBottom: 20 }} >
                <td style={{ fontWeight: 'bold', background: leftBackground, textAlign: 'center' }} >Recettes</td>
                <td></td>
                <td colSpan={3} style={{ background: rightBackground }} >Les loyers pris en compte sont les loyers <b>encaissables au titre de l’année d’imposition</b>, même perçus une autre année.</td>
            </tr>
            <WhiteTableSpace />
            <tr>
                <td style={{ fontWeight: 'bold', background: leftBackground, textAlign: 'center' }} >Charges déductibles</td>
                <td></td>
                <td colSpan={3} style={{ background: rightBackground }} >
                    <div style={{ marginBottom: 30 }} >
                        <div style={{ fontWeight: 'bold', textDecoration: 'underline', marginBottom: 5 }} >Régime micro BIC (LMP et LMNP)</div>
                        <div style={{ marginBottom: 5 }} >Régime applicable par principe aux loueurs en entreprises individuelles et EURL si les recettes annuelles du foyer fiscal sont inférieures à 72.600€ <i>(176.200€ pour les chambres d’hôtes et meublés de tourisme classés)</i>.</div>
                        <div style={{ marginBottom: 5 }} >Les recettes sont diminuées d’un <b>abattement de 50%</b> <i>(71% pour les chambres d’hôtes et meublés de tourisme classés)</i>.</div>
                        <div>Les personnes morales ne peuvent pas bénéficier du micro BIC.</div>
                    </div>
                    <div>
                        <div style={{ fontWeight: 'bold', textDecoration: 'underline', marginBottom: 5 }} >Régime réel (LMP et LMNP)</div>
                        <div style={{ marginBottom: 5 }} >Régime applicable sur option si les recettes du foyer fiscal sont inférieures à 72.600€/176.200€, et applicable par principe au-delà.</div>
                        <div style={{ marginBottom: 5 }} >Les recettes sont diminuées des charges décaissables au titre de l’année d’imposition :</div>
                        <ul>
                            <li><b>Frais d’acquisition</b> (déduits ou amortis) ;</li>
                            <li><b>Tous les travaux</b> (déduits ou amortis) ;</li>
                            <li>
                                <b>Amortissement du bien immobilier</b> (sauf terrain) et meubles;
                                <img src={OrangeInfo} width={25} />
                            </li>
                            <li>Intérêts et frais d’emprunt ;</li>
                            <li>Charges de copropriété, frais de gestion, primes d’assurance, taxes foncières, cotisations sociales le cas échéant.</li>
                        </ul>
                    </div>
                </td>
            </tr>
            <WhiteTableSpace />
            <tr>
                <td style={{ width: '10%' }} ></td>
                <td style={{ width: '2%' }} ></td>
                <td style={{ width: '41.5%', background: 'rgb(132,151,176)', fontWeight: 'bold', color: '#FFFFFF', textAlign: 'center' }} >LOUEUR EN MEUBLÉ NON PROFESSIONNEL<br />LMNP</td>
                <td style={{ width: '2%' }} ></td>
                <td style={{ width: '41.5%', background: 'rgb(132,151,176)', fontWeight: 'bold', color: '#FFFFFF', textAlign: 'center' }} >LOUEUR EN MEUBLÉ PROFESSIONNEL<br />LMP</td>
            </tr>
            <tr>
                <td style={{ fontWeight: 'bold', background: leftBackground, textAlign: 'center' }} >
                    <div style={{ marginBottom: 10 }} >=</div>
                    <div>Net</div>
                </td>
                <td style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', paddingTop: 0 }} >
                    <div style={{ background: 'rgb(237,125,49)', borderRadius: 10, width: '5px', height: '40px', marginBottom: 30 }} ></div>
                    <div style={{ background: 'rgb(101,191,127)', borderRadius: 10, width: '5px', height: '100px' }} ></div>
                </td>
                <td style={{ background: rightBackground }} >
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }} >
                        <div style={{ marginBottom: 10, paddingBottom: 10, borderBottom: '2px solid #FFFFFF' }} >
                            <div style={{ fontWeight: 'bold', textDecoration: 'underline' }} >Déficit</div>
                            <div>Imputé <b>sur les BIC de location meublée des 10 années suivantes</b>.</div>
                        </div>
                        <div>
                            <div style={{ fontWeight: 'bold', textDecoration: 'underline' }} >Bénéfice</div>
                            <div>Soumis au barème progressif de l’IR (0% à 45%).</div>
                            <div>Deux situations sont possibles pour les charges sociales :</div>
                            <ul>
                                <li>si les recettes de location saisonnière {'>'} 23.000€ : <b>cotisations sociales</b>.</li>
                                <li>sinon : prélèvements sociaux (17,2%).</li>
                            </ul>
                        </div>
                    </div>
                </td>
                <td></td>
                <td className='cellule' style={{ background: rightBackground }}>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }} >
                        <div style={{ marginBottom: 10, paddingBottom: 10, borderBottom: '2px solid #FFFFFF' }} >
                            <div style={{ fontWeight: 'bold', textDecoration: 'underline' }} >Déficit</div>
                            <div>Imputé <b>sur le revenu global</b> sans limite.</div>
                        </div>
                        <div>
                            <div style={{ fontWeight: 'bold', textDecoration: 'underline' }} >Bénéfice</div>
                            <div>Le bénéfice est soumis au barème progressif de l’IR (0% à 45%) et aux <b>cotisations sociales</b>.</div>
                        </div>
                    </div>
                </td>
            </tr>
            <WhiteTableSpace />
            <tr>
                <td style={{ fontWeight: 'bold', background: 'rgb(226,240,217)', textAlign: 'center' }} >Plus-value</td>
                <td></td>
                <td style={{ background: plusValueBackground }} >L’imposition relève du régime des <b>plus-values immobilières des particuliers</b>.</td>
                <td></td>
                <td style={{ background: plusValueBackground }}>L’imposition relève du régime des <b>plus-values professionnelles</b>.</td>
            </tr>
        </table>
    )
}

export default TableLocationMeublee;