import React, { useState, useContext } from 'react';
import { CFooter, CImg } from '@coreui/react';
import { useHistory, useLocation } from 'react-router-dom';
import { LayoutContext } from '../App2';
import { SimulateurContext } from '../store/context/simulateur'
import { DataControlContext } from '../store/context/dataControl';
import { OrdinaryTooltip } from '../components/InfoBulle/Tooltip/Tooltip';
import './footer.scss';
import { lienAV, lienAideCessionSociete, lienAutresDonations, lienBilanPatrimonial, lienCAPI, lienCompteTitres, lienDonationsEntreprise, lienDonsFamiliauxExoneres, lienEmprunt, lienFicheClient, lienFicheThematique, lienFicheThematiqueAssuranceVie, lienFicheThematiqueCapi, lienFicheThematiqueCessionSociete, lienFicheThematiqueClauseBeneficiaireAV, lienFicheThematiqueClauseBeneficiaireDemembreeAV, lienFicheThematiqueClauseBeneficiaireDemembreeAV990I, lienFicheThematiqueCompteTitres, lienFicheThematiqueDMTG, lienFicheThematiqueIFI, lienFicheThematiquePEA, lienFicheThematiqueRevLocatif, lienFicheThematiqueSocieteIS, lienFicheThematiqueStrategiesLimitationIR, lienFiscaliteRevenus, lienFormulaireAV, lienFormulaireCAPI, lienFormulaireCompteTitres, lienFormulaireIS, lienFormulairePEA, lienIFI, lienIS, lienListeClient, lienListeSimulations, lienNoticeCalculCessionSociete, lienPEA, lienPlusValueImmobiliere, lienSimulateurCessionSociete, lienSuccession, lienUsufruitEconomique, lienUsufruitTemporaire, lienUsufruitViager } from '../routes';
import FicheThematiqueIcon from '../assets/images/fiche-thematique-icon.svg';
import NoticeCalculIcon from '../assets/images/notice-calcul-icon.svg';
import BoutonAjout from '../assets/images/bouton-ajout.svg';
import BoutonPrecedent from '../assets/images/bouton-precedent.svg';
import BoutonSuivant from '../assets/images/bouton-suivant.svg';
import BoutonSimuler from '../assets/images/bouton-simuler.svg';
import BoutonExport from '../assets/images/bouton-export.svg';
import BoutonPostIt from '../assets/icons/svg/postit/post-it.svg';
import BoutonScrollVersLeHaut from '../assets/images/bouton-redirection-vers-le-haut.svg';
import BoutonAide from '../assets/images/bouton-aide.svg';
import { desactivePageBlock, displayOrHideCalculatrice, updatePostItData } from '../store/actions/simulateur';

export const DIMENSION_LARGEUR_BOUTON = '40px';

const TheFooter = () => {
  const location = useLocation();
  const history = useHistory();
  const [exportText, setExportText] = useState('Export');
  const [visible, setVisible] = useState(false);
  const currentLink = location.pathname;

  const { simulateurState, simulateurDispatch } = useContext(SimulateurContext)
  const { headerMenuIndex, setHeaderMenuIndex } = useContext(DataControlContext);
  const { layoutState } = useContext(LayoutContext);
  const { footerButtons: { cs: cessionFooterButtons, usufNPusufViager, usufNPusufTemporaire, donsExoneres, donationsOrdinaires, donationsEntreprise, succession, ficheClient, bilanPatrimonial, fiscaliteRevenu, ft, emprunt, usufruitEconomique, ifi, pvImmo, accueilPropositionFinanciere, propositionFinanciere } } = layoutState;

  // Page des simulateurs BOX Financière
  const peaPage = currentLink.includes(lienFormulairePEA) || currentLink.includes(lienPEA);
  const compteTitresPage = currentLink.includes(lienFormulaireCompteTitres) || currentLink.includes(lienCompteTitres);
  const assuranceViePage = currentLink.includes(lienFormulaireAV) || currentLink.includes(lienAV);
  const capiPage = currentLink.includes(lienFormulaireCAPI) || currentLink.includes(lienCAPI);
  const societeISPage = currentLink.includes(lienFormulaireIS) || currentLink.includes(lienIS);
  const comparaisonStructuresPage = currentLink.includes("/comparaisons/formulaire") || currentLink.includes("/comparaisons/resultat_financier") || currentLink.includes("/comparaisons/resultat_transmission");

  // Page des simulateurs BOX Cession de société
  const cessionPage = currentLink.includes(lienSimulateurCessionSociete);

  // Page des simulateurs BOX Donation et Succession
  const usufNPusufViagerPage = currentLink.includes(lienUsufruitViager);
  const usufNPusufTemporairePage = currentLink.includes(lienUsufruitTemporaire);
  const donsExoneresPage = currentLink.includes(lienDonsFamiliauxExoneres);
  const donationsEntreprisePage = currentLink.includes(lienDonationsEntreprise);
  const donationsOrdinairesPage = currentLink.includes(lienAutresDonations);
  const successionPage = currentLink.includes(lienSuccession);

  // Page des simulateurs BOX Patrimoine et Fiscalité
  const bilanPatrimonialPage = currentLink.includes(lienBilanPatrimonial);
  const fiscaliteRevenuPage = currentLink.includes(lienFiscaliteRevenus);
  const ifiPage = currentLink.includes(lienIFI);

  // Page des simulateurs BOX Immobilière
  const empruntPage = currentLink.includes(lienEmprunt);
  const usufruitEconomiquePage = currentLink.includes(lienUsufruitEconomique);
  const pvImmoPage = currentLink.includes(lienPlusValueImmobiliere);

  // Page correspondante à la Fiche Client
  const ficheClientPage = currentLink.includes(lienFicheClient);

  // Page des fiches thématiques
  const ftPEA = currentLink.includes(lienFicheThematiquePEA);
  const ftCT = currentLink.includes(lienFicheThematiqueCompteTitres);
  const ftAV = currentLink.includes(lienFicheThematiqueAssuranceVie);
  const ftCAPI = currentLink.includes(lienFicheThematiqueCapi);
  const ftIS = currentLink.includes(lienFicheThematiqueSocieteIS);
  const ftCS = currentLink.includes(lienFicheThematiqueCessionSociete);
  const ftDMTG = currentLink.includes(lienFicheThematiqueDMTG);
  const ftIFI = currentLink.includes(lienFicheThematiqueIFI);
  const ftPvImmo = currentLink.includes(lienFicheThematiqueRevLocatif);
  const ftClauseBeneficiaireAv = currentLink.includes(lienFicheThematiqueClauseBeneficiaireAV);
  const ftClauseBeneficiaireDemembreeAv = currentLink.includes(lienFicheThematiqueClauseBeneficiaireDemembreeAV);
  const ftClauseBeneficiaireDemembreeAv990I = currentLink.includes(lienFicheThematiqueClauseBeneficiaireDemembreeAV990I);
  const ftStrategiesLimitationIR = currentLink.includes(lienFicheThematiqueStrategiesLimitationIR);

  const accueilPropositionFinancierePage = currentLink.includes('/accueil-proposition-financiere');
  const propositionFinancierePage = currentLink.includes('/proposition-financiere') && currentLink !== "/proposition-financiere-aide";
  const resultatPropositionFinancierePage = propositionFinancierePage && headerMenuIndex.propositionFinanciere === 2;

  // Le FOOTER sur la page d'aide et Notice de la cession de société ne doit pas avoir de picto.
  const buttons = cessionPage ?
    cessionFooterButtons :
    usufNPusufViagerPage ? usufNPusufViager :
      usufNPusufTemporairePage ? usufNPusufTemporaire :
        donsExoneresPage ? donsExoneres :
          donationsOrdinairesPage ? donationsOrdinaires :
            donationsEntreprisePage ? donationsEntreprise :
              ficheClientPage ? ficheClient :
                bilanPatrimonialPage ? bilanPatrimonial :
                  successionPage ? succession :
                    fiscaliteRevenuPage ? fiscaliteRevenu :
                      ftPEA ? ft.pea :
                        ftCT ? ft.ct :
                          ftAV ? ft.av :
                            ftCAPI ? ft.capi :
                              ftIS ? ft.is :
                                ftCS ? ft.cs :
                                  ftDMTG ? ft.dmtg :
                                    empruntPage ? emprunt :
                                      usufruitEconomiquePage ? usufruitEconomique :
                                        ifiPage ? ifi :
                                          ftIFI ? ft.ifi :
                                            ftPvImmo ? ft.pvImmo :
                                              ftClauseBeneficiaireAv ? ft.clauseBeneficiaireAv :
                                                ftClauseBeneficiaireDemembreeAv ? ft.clauseBeneficiaireDemembreeAv :
                                                  pvImmoPage ? pvImmo :
                                                    accueilPropositionFinancierePage ? accueilPropositionFinanciere :
                                                      propositionFinancierePage ? propositionFinanciere :
                                                        simulateurState.buttons;

  const clickCondition = (bilanPatrimonialPage || cessionPage || usufNPusufViagerPage || usufNPusufTemporairePage || donsExoneresPage || donationsOrdinairesPage || donationsEntreprisePage || successionPage || fiscaliteRevenuPage || ftPEA || ftCT || ftAV || ftCAPI || ftIS || ftCS || ftDMTG || ftIFI || ftPvImmo || ftClauseBeneficiaireAv || empruntPage || usufruitEconomiquePage || ifiPage || pvImmoPage || ficheClientPage || accueilPropositionFinancierePage || propositionFinancierePage);

  // Condition d'affichage du bouton d'aide
  const conditionAffichageBoutonAide = cessionPage || comparaisonStructuresPage || propositionFinancierePage;

  // Condition d'affichage du bouton de fiche thématique
  const conditionAffichageBoutonFicheThematique = peaPage ||
    compteTitresPage ||
    assuranceViePage ||
    capiPage ||
    societeISPage ||
    cessionPage ||
    donsExoneresPage ||
    donationsEntreprisePage ||
    donationsOrdinairesPage ||
    successionPage ||
    fiscaliteRevenuPage ||
    ifiPage;

  // Condition d'affichage Bouton Notice de calcul
  const conditionAffichageBoutonNoticeCalcul = cessionPage

  // Condition d'affichage du Bouton d'export
  const conditionAffichageBoutonExportCessionSociete = cessionPage && headerMenuIndex.cs === 4;
  const conditionAffichageBoutonExportSuccession = successionPage && [3, 4].includes(headerMenuIndex.succession);
  const conditionAffichageBoutonExportBilanPatrimonial = bilanPatrimonialPage && headerMenuIndex.bilanPatrimonial === 0;
  const conditionAffichageBoutonExportFiscaliteRevenus = fiscaliteRevenuPage && headerMenuIndex.patrimoineFiscalite?.fiscaliteRevenus === 6;
  const conditionAffichageBoutonExportIFI = ifiPage && headerMenuIndex.patrimoineFiscalite?.ifi === 3;
  const conditionAffichageBoutonExportPlusValueImmobiliere = pvImmoPage && headerMenuIndex.pvImmobiliere === 4;

  // Condition d'affichage du Bouton d'export sur l'ensemble de tous les simulateurs
  const conditionAffichageBoutonExport = simulateurState.tableRows.length > 0 ||
    conditionAffichageBoutonExportCessionSociete ||
    usufNPusufViagerPage ||
    usufNPusufTemporairePage ||
    donsExoneresPage ||
    donationsOrdinairesPage ||
    donationsEntreprisePage ||
    conditionAffichageBoutonExportBilanPatrimonial ||
    conditionAffichageBoutonExportSuccession ||
    conditionAffichageBoutonExportFiscaliteRevenus ||
    ftPEA ||
    ftCT ||
    ftAV ||
    ftCAPI ||
    ftIS ||
    ftCS ||
    ftDMTG ||
    ftIFI ||
    ftPvImmo ||
    ftClauseBeneficiaireAv ||
    ftClauseBeneficiaireDemembreeAv ||
    ftClauseBeneficiaireDemembreeAv990I ||
    ftStrategiesLimitationIR ||
    empruntPage ||
    usufruitEconomiquePage ||
    conditionAffichageBoutonExportIFI ||
    ficheClientPage ||
    conditionAffichageBoutonExportPlusValueImmobiliere ||
    resultatPropositionFinancierePage;

  const conditionAffichageBoutonPostIt = comparaisonStructuresPage ? false : true; // currentLink.includes(lienBilanPatrimonial);

  // Clic sur le bouton d'aide
  const handleBoutonAideClick = () => {
    let lienAide = '';

    // Cession de société
    if (currentLink.includes(lienSimulateurCessionSociete)) lienAide = lienAideCessionSociete;

    // Comparaison de structures
    if (comparaisonStructuresPage) lienAide = '/comparaison-structures-aide';

    // Proposition financière
    if (propositionFinancierePage) lienAide = '/proposition-financiere-aide';

    history.push(lienAide);
  }

  // Clic sur le bouton fiche thématique
  const handleBoutonFicheThematiqueClick = () => {
    // Spécifiquement pour la SUCCESSION, on réinitialise le niveau de menu à 0 si celui-ci ne l'est pas encore
    if (successionPage && headerMenuIndex.succession !== 0) {
      setHeaderMenuIndex((prevState) => {
        return {
          ...prevState,
          succession: 0
        }
      })
    }

    let lienFT = ''

    // PEA
    if (peaPage) lienFT = lienFicheThematiquePEA;

    // CT
    if (compteTitresPage) lienFT = lienFicheThematiqueCompteTitres;

    // AV
    if (assuranceViePage) lienFT = lienFicheThematiqueAssuranceVie;

    // CAPI
    if (capiPage) lienFT = lienFicheThematiqueCapi;

    // IS
    if (societeISPage) lienFT = lienFicheThematiqueSocieteIS;

    // Cession de société
    if (cessionPage)
      lienFT = lienFicheThematiqueCessionSociete;

    // Les simulateurs de donations et succession
    if (donsExoneresPage || donationsEntreprisePage || donationsOrdinairesPage || successionPage)
      lienFT = lienFicheThematiqueDMTG;

    // IFI
    if (ifiPage)
      lienFT = lienFicheThematiqueIFI;

    // Stratégies de limitation IR
    if (fiscaliteRevenuPage)
      lienFT = lienFicheThematiqueStrategiesLimitationIR;

    history.push(lienFT);
  }

  // Clic sur le bouton Notice de calcul
  const handleBoutonNoticeCalculClick = () => {
    let lienNoticeCalcul = '';

    // Cession de société
    if (currentLink.includes(lienSimulateurCessionSociete)) lienNoticeCalcul = lienNoticeCalculCessionSociete;

    history.push(lienNoticeCalcul);
  }

  // Toggle visible sur le Bouton vers le Haut
  const toggleVisible = () => {
    if (!visible && window.pageYOffset > 400) {
      setVisible(true)
    }
    else if (visible && window.pageYOffset <= 400) {
      setVisible(false)
    }
  };

  // Rédirection vers le Haut
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  window.addEventListener('scroll', toggleVisible);

  const buttonImageStyle = {
    width: 40
  }

  const conditionAffichageBoutonsGauche = conditionAffichageBoutonAide || conditionAffichageBoutonFicheThematique || conditionAffichageBoutonNoticeCalcul;
  const conditionAffichageTexteSimulabox = buttons.length === 0 && !currentLink.includes(`${lienFicheThematique}/`) && !conditionAffichageBoutonsGauche;
  const positionDepuisLesCotes = 10;

  const isFicheClientSimulation = ficheClientPage && headerMenuIndex.ficheClient === 1;

  const showCalculator = () => {
    if (!simulateurState?.showCalculatrice) {
      // Get the body element
      const bodyElement = document.body;
      if (bodyElement) {
        const rect = bodyElement.getBoundingClientRect();
        const elementDeplacable = document.querySelector('.element-deplacable');

        if (elementDeplacable) {
          elementDeplacable.style.top = Number(Math.abs(rect.top) + 100) + 'px';
        }
      }
    }
    simulateurDispatch(displayOrHideCalculatrice({
      showCalculatrice: !simulateurState?.showCalculatrice
    }))
  }

  const onShowPostIt = () => {
    const currentSimulateurState = simulateurState;
    currentSimulateurState.postItData.displayBlocPostIt = !currentSimulateurState?.postItData?.displayBlocPostIt;
    simulateurDispatch(updatePostItData(currentSimulateurState))
  }

  return (
    <CFooter
      fixed={true}
      className='shadow'
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: '#FFFFFF',
        zIndex: 5,
        borderTop: 'transparent'
      }}
    >
      <div style={{ width: '100%', display: 'flex', alignItems: 'center', flexDirection: "row", position: 'relative', gap: '15px' }}>
        {/** Bloc de gauche */}
        <div style={{ width: '100%' }} className='position-gauche'>
          {
            conditionAffichageTexteSimulabox && (
              <div>&copy; Simulabox {new Date().getFullYear()}</div>
            )
          }
          {
            conditionAffichageBoutonsGauche && (
              <div style={{ display: 'flex' }}>
                {
                  conditionAffichageBoutonAide && (
                    <div>
                      <OrdinaryTooltip arrow title='Aide' placement='top'>
                        <div style={{ marginRight: '15px' }}>
                          <CImg
                            className="footerButton"
                            src={BoutonAide}
                            style={buttonImageStyle}
                            onClick={handleBoutonAideClick}
                          />
                        </div>
                      </OrdinaryTooltip>
                    </div>
                  )
                }
                {
                  conditionAffichageBoutonFicheThematique && (
                    <div>
                      <OrdinaryTooltip arrow title='Fiche thématique' placement='top'>
                        <div style={{ marginRight: '15px' }}>
                          <CImg
                            className="footerButton"
                            src={FicheThematiqueIcon}
                            style={buttonImageStyle}
                            onClick={handleBoutonFicheThematiqueClick}
                          />
                        </div>
                      </OrdinaryTooltip>
                    </div>
                  )
                }
                {
                  conditionAffichageBoutonNoticeCalcul && (
                    <div>
                      <OrdinaryTooltip arrow title='Documentation' placement='top'>
                        <div>
                          <CImg
                            className="footerButton"
                            src={NoticeCalculIcon}
                            style={buttonImageStyle}
                            onClick={handleBoutonNoticeCalculClick}
                          />
                        </div>
                      </OrdinaryTooltip>
                    </div>
                  )
                }
              </div>
            )
          }
        </div>

        {/** Bloc de bouton */}
        { buttons.length > 0 && (
          <div style={{ width: '100%', display: 'flex', justifyContent: 'center', flexDirection: "row" }}>
            {
              buttons?.map((button, i) => {
                let conditionAffichageBouton = button.type !== 'CLONE_SIMULATION';
                let marginLeftValue = i !== 0 ? 20 : 0;



                let conditionAvecBoutonPrecedent = ['BACK', 'COMP_HSF1', 'COMP_HST1', 'PREVIOUS', 'COMP_RSF1'].includes(button.type);
                let conditionAvecBoutonEnregistrement = ['ADD', 'SAVE_SIMULATION', 'CLIENT', 'NOTE'].includes(button.type);
                let conditionAvecBoutonSuivant = ['COMP_HST', 'COMP_RSF2', 'COMP_HST2', 'COMP_RST1', 'COMP_HSF', 'PF_RI', 'NEXT', 'PF_PI2'].includes(button.type);
                let src = conditionAvecBoutonPrecedent ? BoutonPrecedent : conditionAvecBoutonEnregistrement ? BoutonAjout : conditionAvecBoutonSuivant ? BoutonSuivant : BoutonSimuler

                return (conditionAffichageBouton && !isFicheClientSimulation) && (
                  <div key={`BoutonFooter${i}`} style={{ marginLeft: marginLeftValue }} onClick={() => { simulateurDispatch(desactivePageBlock()); clickCondition ? button.onClick() : simulateurDispatch({ type: button.type })}}>
                    <OrdinaryTooltip arrow title={button.text} placement='top'>
                      <div>
                        <CImg className="footerButton" src={src} style={buttonImageStyle} />
                      </div>
                    </OrdinaryTooltip>
                  </div>
                )
              })
            }
          </div>
        )}

        {/** Bloc de droite */}
        {/** Bloc export */}
        <div style={{ width: '59.55%', display: 'flex', flexDirection: "row", position: 'relative', justifyContent: 'flex-end' }}>
          {/** Bouton des EXPORTS */}
          <div style={{ visibility: (conditionAffichageBoutonExport && !isFicheClientSimulation) ? 'visible' : 'hidden' }} onClick={() => { simulateurDispatch(desactivePageBlock()); simulateurDispatch({ type: 'EXPORT_PPT' })}}>
            <OrdinaryTooltip placement='top' title='Export' arrow>
              <div>
                <CImg className='footerButton' src={BoutonExport} style={buttonImageStyle} />
              </div>
            </OrdinaryTooltip>
          </div>
        </div>

        <div style={{ width: ((conditionAffichageBoutonExport && !isFicheClientSimulation) || buttons.length > 0) && !String(currentLink).match(`${lienFicheThematique}/`) ? "9px" : "5px" }} className='vertical-line-footer'></div>

        <div style={{
          width: "auto",
          display: 'flex',
          flexDirection: "row",
          position: 'relative',
          justifyContent: 'flex-end'}}>

          {/** Bouton calculatrice */}
          <div style={{ paddingRight: 5 }} onClick={showCalculator}>
            <OrdinaryTooltip placement='top' title='Calculatrice' arrow>
              <div className='calculatrice-icon-content' style={{ position: 'relative' }}>
                <svg id="calculatrice-icon" data-name="calculatrice icon" xmlns="http://www.w3.org/2000/svg" width="39" height="39" viewBox="0 0 39 39">
                  <defs>
                    <linearGradient id="linear-gradient" x1="0.307" y1="-0.145" x2="0.572" y2="2.43" gradientUnits="objectBoundingBox">
                      <stop offset="0" stopColor="#6e7a98"/>
                      <stop offset="1" stopColor="#293450"/>
                    </linearGradient>
                    <linearGradient id="linear-gradient-2" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                      <stop offset="0" stopColor="#18a9cd"/>
                      <stop offset="0.481" stopColor="#1991c1"/>
                      <stop offset="1" stopColor="#1c50a2"/>
                    </linearGradient>
                  </defs>
                  <g id="calculatrice_hover" data-name="calculatrice hover">
                  <rect id="Rectangle_21773" data-name="Rectangle 21773" width="39" height="39" rx="4" fill="url(#linear-gradient)"/>
                  <rect id="Rectangle_21774" data-name="Rectangle 21774" width="9" height="9" rx="2" transform="translate(4 15)" fill="#727d9b"/>
                  <rect id="Rectangle_21777" data-name="Rectangle 21777" width="9" height="9" rx="2" transform="translate(16 15)" fill="#727d9b"/>
                  <rect id="Rectangle_21779" data-name="Rectangle 21779" width="7" height="20" rx="2" transform="translate(28 15)" fill="url(#linear-gradient-2)"/>
                  <rect id="Rectangle_21780" data-name="Rectangle 21780" width="8.349" height="31" rx="2" transform="translate(35 3.651) rotate(90)" fill="#7b87a7"/>
                  <rect id="Rectangle_21775" data-name="Rectangle 21775" width="9" height="9" rx="2" transform="translate(4 26)" fill="#727d9b"/>
                  <rect id="Rectangle_21776" data-name="Rectangle 21776" width="9" height="9" rx="2" transform="translate(16 26)" fill="#727d9b"/>
                  <rect id="Rectangle_21786" data-name="Rectangle 21786" width="2" transform="translate(35 7) rotate(90)" fill="#8794b9"/>
                  <rect id="Rectangle_21787" data-name="Rectangle 21787" width="1" transform="translate(35 10) rotate(90)" fill="#8794b9"/>
                  </g>
                </svg>
              </div>
            </OrdinaryTooltip>
          </div>
        </div>

         {/* Bouton post it */}
         <div style={{
          width: "auto",
          display: 'flex', flexDirection: "row",
          position: 'relative',
          justifyContent: 'flex-end' }}>
          {/** Bouton des POST_IT */}
          <div
            style={{ visibility: conditionAffichageBoutonPostIt && buttons.length > 0 ? 'visible' : 'hidden' }}
            onClick={() => simulateurDispatch(updatePostItData({ ...(simulateurState?.postItData), displayBlocPostIt: !simulateurState?.postItData?.displayBlocPostIt }))}
            // onClick={onShowPostIt}
          >
            <OrdinaryTooltip placement='top' title='Post-it' arrow>
              <div>
                <CImg className='footerButton' src={BoutonPostIt} style={buttonImageStyle} />
              </div>
            </OrdinaryTooltip>
          </div>
        </div>

        {/** Bouton de SCROLL Vers le HAUT */}
        <div style={{ visibility: visible ? 'visible' : 'hidden' }} onClick={scrollToTop}>
          <CImg src={BoutonScrollVersLeHaut} style={{ ...buttonImageStyle, cursor: 'pointer' }} />
        </div>
      </div>
      {/*
        conditionAffichageTexteSimulabox &&
        <div className="d-flex flex-row justify-content-between pl-4">
          <div style={{ display: 'flex' }}>
            <span className="ml-1" style={{ alignSelf: 'center' }}>&copy; Simulabox {new Date().getFullYear()}</span>
          </div>
        </div>
      */}

    </CFooter>
  )
}

export default React.memo(TheFooter)
