import React, { useContext } from "react";
import { CSelect } from '@coreui/react';
import { SimulateurContext } from "../../store/context/simulateur";
import { activePageBlock } from "../../store/actions/simulateur";
import PostitPin from "../Postit/PostitPin";

const SelectDonation = (props) => {
    const { simulateurState, simulateurDispatch } = useContext(SimulateurContext);

    const handleChange = (event) => {
        // On met à jour l'état de changement de FORMULAIRE
        simulateurDispatch(activePageBlock())

        // Changement de valeur contenue dans le FORMULAIRE
        props.onChange(event);
    }

    const range = (start, end) => {
        if (start === end) return [start];
        return [start, ...range(parseInt(start) + 1, end)];
    }

    return (
      <div className={props.className ? props.className : "col-lg-12"} style={{ position: 'relative', padding: 0, marginRight: props.marginRight ? props.marginRight : 0, ...props.style}}>
        <CSelect
            id={props.id}
            custom
            style={{ width: "100%", fontSize: 13 }}
            onKeyDown={event => {
                if (event.keyCode === 13) {
                    const form = event.target.form;
                    let index = Array.prototype.indexOf.call(form, event.target);
                    if (form.elements[index + 1])
                        form.elements[index + 1].focus();
                    event.preventDefault();
                }
            }}
            disabled={props.disabled || props.options && props.options.length === 1}
            value={props.value}
            onChange={handleChange}
        >
            {
                !props.options &&
                <option value='' selected key={-1}>Sélectionnez</option>
            }
            {
                !props.options && range(parseInt(new Date().getFullYear()) - 15, new Date().getFullYear()).map((year, i) => {
                    return <option value={year} key={i}>{year}</option>
                })
            }
            {
                props.options && props.options.map((currency, i) => {
                    return <option value={currency} key={i}>{new Intl.NumberFormat("fr-CA").format(currency)}</option>
                })
            }
        </CSelect>
        {
            !props?.hidePin && (
              <PostitPin
                isPostitShow={simulateurState?.postItData?.displayBlocPostIt}
                pinId={props.id} />
            )

          }
      </div>
    )
}

export default SelectDonation
