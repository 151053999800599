import { useContext, useEffect, useState } from "react";
import { SimulateurContext } from "../../store/context/simulateur";
import { updateShowShortcutSimulationState, updateSimulation } from "../../store/actions/simulateur";

import Loading from '../loader'

import { ReactComponent as BoutonRaccourci } from '../../assets/icons/svg/shortcut/bouton-raccourci.svg';
import { ReactComponent as BoutonDupliquer } from '../../assets/icons/svg/shortcut/dupliquer.svg';
import { ReactComponent as BoutonFicheClient } from '../../assets/icons/svg/shortcut/fiche-client.svg';
import { ReactComponent as BoutonSimulation } from '../../assets/icons/svg/shortcut/simulation.svg';

import { DataControlContext } from "../../store/context/dataControl";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { lienAV, lienAutresDonations, lienBilanPatrimonial, lienCAPI, lienCompteTitres, lienDonationsEntreprise, lienDonsFamiliauxExoneres, lienEmprunt, lienFicheClient, lienFicheThematiqueAssuranceVie, lienFicheThematiqueCapi, lienFicheThematiqueCessionSociete, lienFicheThematiqueClauseBeneficiaireAV, lienFicheThematiqueClauseBeneficiaireDemembreeAV, lienFicheThematiqueCompteTitres, lienFicheThematiqueDMTG, lienFicheThematiqueIFI, lienFicheThematiquePEA, lienFicheThematiqueRevLocatif, lienFicheThematiqueSocieteIS, lienFiscaliteRevenus, lienFormulaireAV, lienFormulaireCAPI, lienFormulaireComparaison, lienFormulaireCompteTitres, lienFormulaireIS, lienFormulairePEA, lienIFI, lienIS, lienListeClient, lienPEA, lienPlusValueImmobiliere, lienPropositionFinanciere, lienResultatFinancierComparaison, lienResultatTransmissionComparaison, lienSimulateurCessionSociete, lienSuccession, lienUsufruitEconomique, lienUsufruitTemporaire, lienUsufruitViager } from "../../routes";
import { LayoutContext } from "../../App2";
import ModalConfirmation from "../ModalConfirmation/ModalConfirmation";
import { handleClickConditionSaveSimulation, handleSimulationClone } from "../../views/simulations/simulationsTools";
import { isNumeric } from "../../store/utilsFunc";
import { UtilsContext } from "../../store/context/utils";
import { addToast } from "../../store/actions/utils/toast";

import './Styles.scss';

const Shortcuts = () => {
  const history = useHistory();
  const location = useLocation();

  const errorMessageDuplication = `Des erreurs se sont survenues pendant la duplication de la simulation.`;
  const successMessageDuplication = `La duplication a été enregistrée avec succès`;
  const errorMessageFicheClient = <span>Pour aller sur la fiche client, il faut au préalable “Personnaliser l’enregistrement” en sélectionnant le client</span>;
  const errorMessageListeSimulation = <span>Pour aller sur la liste des simulations du client, il faut au préalable “Personnaliser l’enregistrement” en sélectionnant le client</span>;
  const errorMessageDuplicationSimulation = <span>Pour dupliquer la simulation, il faut au préalable enregistrer la simulation en cours</span>;

  const { simulateurState, simulateurDispatch } = useContext(SimulateurContext);

  const {
    headerMenuIndex,
    setHeaderMenuIndex,
  } = useContext(DataControlContext);

  const { utilsState, utilsDispatch } = useContext(UtilsContext);
  const { layoutState, setLayoutState } = useContext(LayoutContext)

  const currentLink = location.pathname;

  let currentPathname = location?.pathname;

  const { showFormulairePersonnalisationSimulation, showShortcutSimulation } = simulateurState;

  const [showSimulationDuplication, setShowSimulationDuplication] = useState(false);

  const [itemSimulation, setItemSimulation] = useState({
    id: null,
    category: ''
  });

  const [loading, setLoading] = useState(false);

  // Concernant le MODAL de la fiche thématique
  const [nomPrenomClient, setNomPrenomClient] = useState("")

  const { menuLevel, bilanPatrimonial, ficheClient } = layoutState;

  const { footerButtons: { cs: cessionFooterButtons, usufNPusufViager, usufNPusufTemporaire, donsExoneres, donationsOrdinaires, donationsEntreprise, succession, fiscaliteRevenu, ft, emprunt, usufruitEconomique, ifi, pvImmo, accueilPropositionFinanciere, propositionFinanciere } } = layoutState;
   // Page des simulateurs BOX Cession de société
   const cessionPage = currentLink.includes(lienSimulateurCessionSociete);

   // Page des simulateurs BOX Donation et Succession
   const usufNPusufViagerPage = currentLink.includes(lienUsufruitViager);
   const usufNPusufTemporairePage = currentLink.includes(lienUsufruitTemporaire);
   const donsExoneresPage = currentLink.includes(lienDonsFamiliauxExoneres);
   const donationsEntreprisePage = currentLink.includes(lienDonationsEntreprise);
   const donationsOrdinairesPage = currentLink.includes(lienAutresDonations);
   const successionPage = currentLink.includes(lienSuccession);

   // Page des simulateurs BOX Patrimoine et Fiscalité
   const bilanPatrimonialPage = currentLink.includes(lienBilanPatrimonial);
   const fiscaliteRevenuPage = currentLink.includes(lienFiscaliteRevenus);
   const ifiPage = currentLink.includes(lienIFI);

   // Page des simulateurs BOX Immobilière
   const empruntPage = currentLink.includes(lienEmprunt);
   const usufruitEconomiquePage = currentLink.includes(lienUsufruitEconomique);
   const pvImmoPage = currentLink.includes(lienPlusValueImmobiliere);

   // Page correspondante à la Fiche Client
   const ficheClientPage = currentLink.includes(lienFicheClient);

   // Page des fiches thématiques
   const ftPEA = currentLink.includes(lienFicheThematiquePEA);
   const ftCT = currentLink.includes(lienFicheThematiqueCompteTitres);
   const ftAV = currentLink.includes(lienFicheThematiqueAssuranceVie);
   const ftCAPI = currentLink.includes(lienFicheThematiqueCapi);
   const ftIS = currentLink.includes(lienFicheThematiqueSocieteIS);
   const ftCS = currentLink.includes(lienFicheThematiqueCessionSociete);
   const ftDMTG = currentLink.includes(lienFicheThematiqueDMTG);
   const ftIFI = currentLink.includes(lienFicheThematiqueIFI);
   const ftPvImmo = currentLink.includes(lienFicheThematiqueRevLocatif);
   const ftClauseBeneficiaireAv = currentLink.includes(lienFicheThematiqueClauseBeneficiaireAV);
   const ftClauseBeneficiaireDemembreeAv = currentLink.includes(lienFicheThematiqueClauseBeneficiaireDemembreeAV);

   const accueilPropositionFinancierePage = currentLink.includes('/accueil-proposition-financiere');
   const propositionFinancierePage = currentLink.includes('/proposition-financiere') && currentLink !== "/proposition-financiere-aide";

  // Le FOOTER sur la page d'aide et Notice de la cession de société ne doit pas avoir de picto.
  const buttons = cessionPage ?
    cessionFooterButtons :
    usufNPusufViagerPage ? usufNPusufViager :
      usufNPusufTemporairePage ? usufNPusufTemporaire :
        donsExoneresPage ? donsExoneres :
          donationsOrdinairesPage ? donationsOrdinaires :
            donationsEntreprisePage ? donationsEntreprise :
              ficheClientPage ? ficheClient :
                bilanPatrimonialPage ? bilanPatrimonial :
                  successionPage ? succession :
                    fiscaliteRevenuPage ? fiscaliteRevenu :
                      ftPEA ? ft.pea :
                        ftCT ? ft.ct :
                          ftAV ? ft.av :
                            ftCAPI ? ft.capi :
                              ftIS ? ft.is :
                                ftCS ? ft.cs :
                                  ftDMTG ? ft.dmtg :
                                    empruntPage ? emprunt :
                                      usufruitEconomiquePage ? usufruitEconomique :
                                        ifiPage ? ifi :
                                          ftIFI ? ft.ifi :
                                            ftPvImmo ? ft.pvImmo :
                                              ftClauseBeneficiaireAv ? ft.clauseBeneficiaireAv :
                                                ftClauseBeneficiaireDemembreeAv ? ft.clauseBeneficiaireDemembreeAv :
                                                  pvImmoPage ? pvImmo :
                                                    accueilPropositionFinancierePage ? accueilPropositionFinanciere :
                                                      propositionFinancierePage ? propositionFinanciere :
                                                        simulateurState.buttons;

  const iconsShortuct = [
    {
      label: <span>Fiche client</span>,
      icon: <BoutonFicheClient />,
      requestClick: (e) => {
        e.preventDefault();
        if (!simulateurState?.simulation?.clientId) {
          utilsDispatch(addToast({ type: 'danger', title: 'VALIDATION', description: errorMessageFicheClient, autoDelete: true }));
          return;
        }

        const headerMenu = JSON.parse(JSON.stringify(headerMenuIndex));

        headerMenu.ficheClient = 0;

        setHeaderMenuIndex(headerMenu);

        simulateurDispatch(updateShowShortcutSimulationState({ showShortcutSimulation: false }))
        history.push(`${lienFicheClient}/${simulateurState?.simulation?.clientId}`)
      }
    },
    {
      label: <span>Liste des <br /> simulations</span>,
      icon: <BoutonSimulation />,
      requestClick: (e) => {
        e.preventDefault();
        if (!simulateurState?.simulation?.clientId) {
          utilsDispatch(addToast({ type: 'danger', title: 'VALIDATION', description: errorMessageListeSimulation, autoDelete: true }));
          return;
        }

        simulateurDispatch(updateShowShortcutSimulationState({ showShortcutSimulation: false }))

        const headerMenu = JSON.parse(JSON.stringify(headerMenuIndex));

        headerMenu.ficheClient = 1;

        setHeaderMenuIndex(headerMenu);

        history.push(`${lienFicheClient}/${simulateurState?.simulation?.clientId}`)
      }
    },
    {
      label: <span>Dupliquer <br /> la simulation</span>,
      icon: <BoutonDupliquer />,
      requestClick: (e) => {
        e.preventDefault();
        if (!itemSimulation.id) {
          utilsDispatch(addToast({ type: 'danger', title: 'VALIDATION', description: errorMessageDuplicationSimulation, autoDelete: true }));
          return;
        }

        setShowSimulationDuplication(true);

        simulateurDispatch(updateShowShortcutSimulationState({ showShortcutSimulation: false }))
      }
    }
  ];

  const handleDuplication = (event, save) => {

    setLoading(true);

    if (save) {
      if (buttons.length > 0) {
        for (const button of buttons) {
          if (handleClickConditionSaveSimulation(currentLink)) {
            button.onClick()
          } else {
            simulateurDispatch({ type: button.type })
          }
        }
      }
    }

    handleSimulationClone(itemSimulation).then((data) => {
      if (!data.message) {
        setLoading(false);
      }

      utilsDispatch(addToast({ type: 'success', title: 'VALIDATION', description: successMessageDuplication, autoDelete: true }));

      simulateurDispatch(updateSimulation({ id: data.id, identifiant_simulation: data.identifiant_simulation, commentaire: data.commentaire }))

      let idSimulation = currentLink.split('/')[currentLink.split('/').length - 1];

      let redirectedFilterLink = currentLink;
      if (itemSimulation.category === 'CMP') {
        if (String(currentLink).match(/resultat_financier|resultat_transmission/)) {
          redirectedFilterLink = currentLink.replace(/resultat_financier|resultat_transmission/, 'formulaire');
        }
      } else {
        redirectedFilterLink = currentLink.replace('/formulaire','');
      }

      setHeaderMenuIndex({
        cs: 0,
        pvImmobiliere: 0,
        succession: 0,
        propositionFinanciere: 0,
        bilanPatrimonial: 0,
        patrimoineFiscalite: {
            fiscaliteRevenus: 0,
            ifi: 0
        }
      });

      if (isNumeric(idSimulation)) {
        idSimulation = parseInt(idSimulation);
        // remove id from currentLink to replace by redirect link
        const redirectLink = redirectedFilterLink.split('/').slice(0, -1).join("/");

        history.push(`${redirectLink}/${data.id}`);
      } else {
        history.push(`${redirectedFilterLink}/${data.id}`);
      }

      if (itemSimulation.category === "CMP") {
        simulateurDispatch({ type: 'UPDATE_COMP_STEP', payload: 1 });
        simulateurDispatch({ type: 'COMP_SSI' })
      }

      // location.reload();


    }).catch((e) => {
      setLoading(false);
      utilsDispatch(addToast({ type: 'danger', title: 'ERREUR', description: errorMessageDuplication }))
    })

  }

  useEffect(() => {
    let nom = "";

    let idSimulation = currentLink.split('/')[currentLink.split('/').length - 1];

    if (isNumeric(idSimulation)) {
      idSimulation = parseInt(idSimulation);
    } else {
      idSimulation = simulateurState?.postItData?.simulationId
    }

    // PEA
    if (currentLink.includes(lienPEA) || currentLink.includes(lienFormulairePEA)) {
      setItemSimulation({
        id: idSimulation,
        category: "PEA",
      })
      nom = "PEA";
    }

    // Compte titres
    if (currentLink.includes(lienCompteTitres) || currentLink.includes(lienFormulaireCompteTitres)) {
      nom = "Compte titres"
      setItemSimulation({
        id: idSimulation,
        category: "CT",
      })
    }

    // Assurance-vie
    if (currentLink.includes(lienAV) || currentLink.includes(lienFormulaireAV)) {
      nom = "Assurance-vie"
      setItemSimulation({
        id: idSimulation,
        category: "AV",
      })
    }

    // Contrat de capitalisation
    if (currentLink.includes(lienCAPI) || currentLink.includes(lienFormulaireCAPI)) {
      nom = "Contrat de capitalisation";
      setItemSimulation({
        id: idSimulation,
        category: "CP",
      })
    }

    // Société IS
    if (currentLink.includes(lienIS) || currentLink.includes(lienFormulaireIS)) {
      nom = "Société à l'IS";
      setItemSimulation({
        id: idSimulation,
        category: "IS",
      })
    }

    // Cession de société
    if (currentLink.includes(lienSimulateurCessionSociete)) {
      nom = "Cession de société";
      setItemSimulation({
        id: idSimulation,
        category: "CS",
      })
    }

    // Comparaisons
    if (currentLink.includes(lienFormulaireComparaison) || currentLink.includes(lienResultatFinancierComparaison) || currentLink.includes(lienResultatTransmissionComparaison)) {
      nom = "Comparaison de structures";
      setItemSimulation({
        id: idSimulation,
        category: "CMP",
      })
    }

    // IFI
    if (currentLink.includes(lienIFI)) {
      nom = "Impôt sur la fortune immobilière";
      setItemSimulation({
        id: idSimulation,
        category: "IFI",
      })
    }

    // Proposition financière
    if (currentLink.includes("/proposition-financiere")) {
      nom = "Proposition financière";
      setItemSimulation({
        id: idSimulation,
        category: "PF",
      })
    }

    // Donation familiaux
    if (currentLink.includes(lienDonsFamiliauxExoneres)) {
      nom = "Donation familiaux";
      setItemSimulation({
        id: idSimulation,
        category: "DF",
      })
    }

    // Donation entreprise
    if (currentLink.includes(lienDonationsEntreprise)) {
      nom = "Donation entreprise";
      setItemSimulation({
        id: idSimulation,
        category: "DE",
      })
    }

    // Autre Donation
    if (currentLink.includes(lienAutresDonations)) {
      nom = "Autre Donation";
      setItemSimulation({
        id: idSimulation,
        category: "DO",
      })
    }

    // Usuf viager
    if (currentLink.includes(lienUsufruitViager)) {
      nom = "Usuf viager";
      setItemSimulation({
        id: idSimulation,
        category: "USUFVIAGER",
      })
    }

    // Usuf fixe
    if (currentLink.includes(lienUsufruitTemporaire)) {
      nom = "Usuf fixe";
      setItemSimulation({
        id: idSimulation,
        category: "USUFFIXE",
      })
    }

    // Usuf eco
    if (currentLink.includes(lienUsufruitEconomique)) {
      nom = "Usuf eco";
      setItemSimulation({
        id: idSimulation,
        category: "USUFECO",
      })
    }

    // Bilan pat
    if (currentLink.includes(lienBilanPatrimonial)) {
      nom = "Bilan pat";
      setItemSimulation({
        id: idSimulation,
        category: "BILAN_PAT",
      })
    }

    // succession
    if (currentLink.includes(lienSuccession)) {
      nom = "Succession";
      setItemSimulation({
        id: idSimulation,
        category: "SUCC",
      })
    }

    // Fiscalité revenu
    if (currentLink.includes(lienFiscaliteRevenus)) {
      nom = "Fiscalité revenu";
      setItemSimulation({
        id: idSimulation,
        category: "FT",
      })
    }

    // Emprunt
    if (currentLink.includes(lienEmprunt)) {
      nom = "Emprunt";
      setItemSimulation({
        id: idSimulation,
        category: "EMP",
      })
    }

     // Plus-value Immobilier
     if (currentLink.includes(lienPlusValueImmobiliere)) {
      nom = "Plus-value Immobilier";
      setItemSimulation({
        id: idSimulation,
        category: "IMMO",
      })
    }

    // Liste des clients
    if (currentLink === lienListeClient) nom = "Liste de vos clients";

    // Fiche Client
    if (currentLink.includes(lienFicheClient)) nom = nomPrenomClient || "Fiche client";

  }, [currentLink, nomPrenomClient, simulateurState?.postItData?.simulationId])

  return (
    <>
        {loading && <Loading />}
        {/** Fenêtre de confirmation de validation duplication */}
        <ModalConfirmation
          visible={showSimulationDuplication}
          setVisible={setShowSimulationDuplication}
          text={<>
              <div>Êtes-vous sur de vouloir dupliquer la simulation ? si oui, vous allez etre redirigé
                automatiquement sur la copie de cette simulation</div>
          </>}
          buttonCloseText={'Enregistrer et dupliquer'}
          buttonValidationText={'Dupliquer'}
          buttonCancelText={'Annuler'}
          onOKClick={(e) => handleDuplication(e, false)}
          onNOKClick={(e) => handleDuplication(e, true)}
          onCancelClick={() => { setShowSimulationDuplication(false); }}
          background={'#7A86A1'}
          color={'white'}
        />
        <div
          id="shortcut-simulation-content"
          style={{
            visibility: currentPathname.match(lienFicheClient) ? 'hidden' : 'visible',
            borderRadius: '50%',
            background: showShortcutSimulation ? '#D6E0EA' : 'transparent'
          }}
          className='shortcut-simulation' >
          <div
            className='shortcut-simulation-btn'
            onClick={() => {
              simulateurDispatch(updateShowShortcutSimulationState({ showShortcutSimulation: !showShortcutSimulation }))
            }}>
            <BoutonRaccourci />
          </div>
          {
            showShortcutSimulation && (
              <div className='shortcut-simulation-content'>
                { iconsShortuct.map((el, index) => (
                  <div
                  style={{ cursor: [0, 1].includes(index) && simulateurState?.simulation?.clientId ? 'pointer' : itemSimulation.id ? 'pointer' : 'default'  }}
                  onClick={(e) => el.requestClick(e)}
                  className='shortcut-simulation-content-item'
                  key={index + '_shortcut'}>
                  <div>
                    { el.icon }
                  </div>
                  <div className='shortcut-simulation-content-item-label'>
                    { el.label }
                  </div>
                  </div>
                ))}
              </div>
            )
          }
        </div>
    </>
  )
}

export default Shortcuts;
