import React from 'react';
import { useContext } from 'react';
import DataBlock from '../../../../components/DataBlock/DataBlock';
import { InputCheckBox } from '../../../../components/input';
import { DataControlContext } from '../../../../store/context/dataControl';
import { plusValueImmobiliereDefaultData } from '../../../../store/context/defaultData/plusValueImmobiliere';

const style = {
    radioDiv: {
        display: 'flex',
        marginBottom: 10
    },
    span: {
        marginTop: 5,
        marginLeft: 10
    }
}

const Configuration = () => {
    const { pvImmobiliereState, setPvImmobiliereState } = useContext(DataControlContext);
    const { config } = pvImmobiliereState;
    const { cession_directe, cession_indirecte, cession_sci_ir } = config;

    const handleCheckChange = (event, prop) => {
        let { value } = event.target;
        const pvImmo = JSON.parse(JSON.stringify(pvImmobiliereState));

        value = parseInt(value, 10);
        pvImmo.config[prop] = value ? 0 : 1;

        if(value === 0){
            pvImmo[prop === 'cession_directe' ? 'form1' : prop === 'cession_indirecte' ? 'form2' : 'form3'] = plusValueImmobiliereDefaultData[prop === 'cession_directe' ? 'form1' : prop === 'cession_indirecte' ? 'form2' : 'form3'];
        }

        setPvImmobiliereState(pvImmo);
    }

    return (
        <DataBlock
            title="Type de cession"
            defaultDisplay={true}
        >
            <div>
                <div style={style.radioDiv} >
                    <InputCheckBox
                        style={{ width: 28, height: 28 }}
                        checked={cession_directe === 1}
                        value={cession_directe}
                        onChange={(event) => { handleCheckChange(event, 'cession_directe') }}
                    />
                    <span style={style.span} >Cession d'un actif immobilier détenu à titre personnel (cession directe)</span>
                </div>
                
                <div style={style.radioDiv} >
                    <InputCheckBox
                        style={{ width: 28, height: 28 }}
                        checked={cession_indirecte === 1}
                        value={cession_indirecte}
                        onChange={(event) => { handleCheckChange(event, 'cession_indirecte') }}
                    />
                    <span style={style.span} >Cession d'un actif immobilier détenu au travers d'une SCI soumise à l'IR (cession indirecte)</span>
                </div>
                
                <div style={{ ...style.radioDiv }} >
                    <InputCheckBox
                        style={{ width: 28, height: 28 }}
                        checked={cession_sci_ir === 1}
                        value={cession_sci_ir}
                        onChange={(event) => { handleCheckChange(event, 'cession_sci_ir') }}
                    />
                    <span style={style.span} >Cession de parts de SCI soumise à l'IR</span>
                </div>
            </div>
        </DataBlock>
    )
}

export default Configuration;