import React, { useContext, useEffect, useState } from 'react';
import CIcon from '@coreui/icons-react';
import './style.scss';
import Caracteristique from './Caracteristique/Caracteristique';
import ImpactsIFI from './ImpactIFI/ImpactIFI';
import TableauAmortissement from './TableauAmortissement/TableauAmortissement';
import ChiffreCle from './ChiffreCle/ChiffreCle';
import { LayoutContext } from '../../../App2';
import { DataControlContext } from '../../../store/context/dataControl';
import Loading from '../../../components/loader';
import { restApiUri } from '../../../config';
import { UtilsContext } from '../../../store/context/utils';
import { constructEmprunt, getEmprunt, saveEmprunt } from '../ImmoDataControl';
import { SimulateurContext } from '../../../store/context/simulateur';
import { desactivePageBlock, resetAction, updatePostItData, updateSimulation } from '../../../store/actions/simulateur';
import { useParams } from 'react-router';
import exportPPTEmprunt from '../../../extra/InvestissementsImmobiliers/Emprunt';
import { empruntDefaultData } from '../../../store/context/defaultData/boxImmobiliere/emprunt';
import { messageSuccesEnregistrementSimulation } from '../../../utils';
import ScrollButton from '../../../components/Button/scrollToTop';
import PostitService from '../../../services/postitService';
import { sortPostitByIndexCase } from '../../../components/Postit/Postit';
import PersonnalisationExport from '../../../components/PersonnalisationExport/PersonnalisationExport';
import { loadCustomExportPpt, saveCustomExportPpt } from '../../../helpers/customExportPpt';

export const formatDateEmp = (data, splitFormat = '/', tiret = "-") => {
    const realDate = data.split(splitFormat);
    if (realDate.length === 1) {
      return data;
    }

    return realDate[0] && realDate[1] && realDate[2] ? `${realDate[2]}${tiret}${realDate[1]}${tiret}${realDate[0]}` : '';
}

const Emprunt = () => {
    const { empruntState, setEmpruntState, profilUtilisateurState, configurationExportState, setConfigurationExportState } = useContext(DataControlContext);
    const { layoutState, setLayoutState } = useContext(LayoutContext);
    const [loading, setLoading] = useState(false);
    const [resultat, setResultat] = useState({});
    const [openIFI, setOpenIFI] = useState(false);
    const [withoutCalcul, setWithoutCalcul] = useState(false);
    const [withCalcul, setWithCalcul] = useState(true);
    const [emp, setEmp] = useState(empruntDefaultData)
    const [type, setType] = useState(1);
    const { footerButtons } = layoutState;
    const { simulateurState, simulateurDispatch } = useContext(SimulateurContext);

    // Visibilité de la fenêtre de personnalisation de l'export
    const [showPersonnalisationExport, setShowPersonnalisationExport] = useState(false);


    const { utilsDispatch } = useContext(UtilsContext);
    const [idAfterInsertion, setIdAfterInsertion] = useState(undefined);
    const { id } = useParams();

    // Fonction de sauvegarde de la simulation
    const save = () => {
        const userId = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user'))?.id : null;
        const dataToSend = {
          userId,
          type: 'emprunt',
          configurationExport: configurationExportState?.emprunt || null,
        }

        saveCustomExportPpt(dataToSend);

        setLoading(true);
        const data = {
            ...empruntState,
            "identifiant_simulation": simulateurState.simulation.identifiant_simulation,
            "terme_simulation": "fin",
            "commentaire": simulateurState.simulation.commentaire,
            "clientId": simulateurState.simulation.clientId,
            "userId": JSON.parse(localStorage.getItem("user")).id,
            // configurationExport: configurationExportState?.emprunt || null

        }
        // const identifiant = id ? id : idAfterInsertion ? idAfterInsertion : simulateurState?.postItData.simulationId;
        const identifiant = id ? id : idAfterInsertion ? idAfterInsertion : simulateurState?.postItData.simulationType === 'emprunt' ? simulateurState?.postItData.simulationId : undefined;
        saveEmprunt(data, identifiant).then((response) => {
            if (response) {
                if (response.id) {
                    setIdAfterInsertion(response.id);
                    setEmpruntState((prevData) => {
                        return { ...prevData, ...empruntState };
                    });

                    simulateurDispatch(updatePostItData({
                      ...(simulateurState?.postItData || {}),
                      simulationId: response.id,
                      simulationType: 'emprunt'
                    }))

                }

                utilsDispatch({
                    type: "ADD_TOAST",
                    payload: {
                        type: "success",
                        title: "OK",
                        description: messageSuccesEnregistrementSimulation(simulateurState?.simulation?.identifiant_simulation)
                    },
                });

                simulateurDispatch(desactivePageBlock())
            }
        }).catch((error) => console.error(error))
            .finally(() => setLoading(false));

    }

    // Récupération de SIMULATION EXISTANTE ou réinitialisation en cas de NOUVELLE SIMULATION
    useEffect(() => {
        loadCustomExportPpt("emprunt").then((data) => {
          if (data.id) {
            setConfigurationExportState((prevState) => {
              return {
                ...prevState,
                emprunt: data.configurationExport,
            }
            })
          }
        }).catch((err) => {
          console.log(err);
        });

        const fetchPins = async (type, userId, idSimulation) => {
          try {
            const pins = await PostitService.getPostitsByType(type, userId, idSimulation)

            simulateurDispatch(updatePostItData({
              ...(simulateurState?.postItData || {}),
              elements: sortPostitByIndexCase(pins)
            }))
          } catch (error) {
            console.log(error);
          }
        }
        if (id) {
            setLoading(true);
            getEmprunt(id).then((response) => {
                setIdAfterInsertion(response.id);

                const tempEmprunt = constructEmprunt({ idSimulation: response.id, ...response });
                const data = {
                    clientId: response.client ? response.client.id : null,
                    commentaire: response.commentaire,
                    terme_simulation: response.terme,
                    identifiant_simulation: response.identifiant_simulation,
                    userId: response.userId,
                }
                delete response["id"];

                simulateurDispatch(updateSimulation(data));
                tempEmprunt.afficher_tableau_annuel = false;
                tempEmprunt.withCalcul = true;
                setEmpruntState(tempEmprunt)

            })
                .finally(() => {
                    setLoading(false);
                    fetchPins("emprunt", localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user'))?.id : null, id);
                });
        }
        else {
            simulateurDispatch(updateSimulation({
                identifiant_simulation: 'brouillon',
                clientId: null,
                commentaire: ""
            }));
            setEmpruntState(empruntDefaultData);
        }
    }, [])

    // Bouton du FOOTER
    useEffect(() => {
        setLayoutState((prevState) => {
            footerButtons.emprunt = [
                {
                    type: 'ADD',
                    text: 'Enregistrer',
                    onClick: () => {
                        save();
                    }
                }
            ];

            return {
                ...prevState,
                footerButtons
            };
        });
    }, [
        JSON.stringify(configurationExportState?.emprunt),
        JSON.stringify(simulateurState),
        JSON.stringify(empruntState),
        id,
        idAfterInsertion
    ]);

    // Scroll vertical au début à chaque premier chargement
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    // Simulation
    useEffect(() => {
        if (withCalcul) {
            setLoading(true);
            const alternativeEmprunt = { ...emp };
            const dateData = formatDateEmp(alternativeEmprunt.date_emprunt);
            const annee = alternativeEmprunt.annee_imposition;

            alternativeEmprunt.date_emprunt = dateData;
            alternativeEmprunt.annee_imposition = `${annee}-01-01`;

            fetch(`${restApiUri}/api/emprunt/simuler_emprunt`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                },
                body: JSON.stringify(alternativeEmprunt)
            })
                .then((response) => response.json())
                .then((response) => {
                    if (response) {
                        setResultat(response);
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    console.error(error)
                })
                .finally(() => { setLoading(false) })
        }
    }, [
        JSON.stringify(emp),
        withCalcul
    ])

    // Export
    useEffect(() => {
        if (simulateurState.action === 'export_ppt') {
            // exportPPTEmprunt(profilUtilisateurState, empruntState, resultat, type);
            setShowPersonnalisationExport(!showPersonnalisationExport);
            simulateurDispatch(resetAction());
        }
    }, [
        simulateurState.action,
        JSON.stringify(empruntState),
        JSON.stringify(resultat),
        type
    ]);

    // Tableau d'amortissement annualisé ou pas
    useEffect(() => {
        const emp = JSON.parse(JSON.stringify(empruntState));

        emp.afficher_tableau_annuel = type === 2;

        setEmpruntState(emp);
    }, [type])

    // à chaque fois que le contexte change => On met à jour l'état de l'EMPRUNT
    useEffect(() => {
        setEmp(empruntState);
    }, [JSON.stringify(empruntState)])

    return (
        <>
            <div className='racine'>
                {loading && <Loading />}
                {/** Fenêtre de personnalisation de l'export Emprunt */}
            {
                showPersonnalisationExport && (
                    <PersonnalisationExport
                        data={configurationExportState?.emprunt}
                        onRequestDataChange={(data) => {
                            const configExport = JSON.parse(JSON.stringify(configurationExportState));

                            configExport.emprunt = data;

                            setConfigurationExportState(configExport);
                        }}
                        onRequestExport={() => {
                          exportPPTEmprunt(profilUtilisateurState, empruntState, resultat, type, configurationExportState?.emprunt?.elements);
                          setShowPersonnalisationExport(false);
                        }}
                        onRequestClose={() => setShowPersonnalisationExport(!showPersonnalisationExport)}
                    />
                )
            }
                <div className='caracteristique'>
                    <Caracteristique
                        withoutCalcul={withoutCalcul}
                        setWithoutCalcul={setWithoutCalcul}
                        nombreTotalVersement={resultat?.matrice?.nb_total_de_versements || 0}
                        setWithCalcul={setWithCalcul}
                    />
                </div>
                <div className='chiffreCle' >
                    <ChiffreCle
                        withoutCalcul={withoutCalcul}
                        data={!withoutCalcul ? resultat && resultat.chiffre_cle ? resultat.chiffre_cle : {} : {}}
                    />
                </div>
                <div className='tableauAmortissement'>
                    <TableauAmortissement
                        openIFI={openIFI}
                        data={!withoutCalcul ? resultat?.[type === 1 ? 'tableau_amortissement' : 'tableau_amortissement_annualise'] || [] : []}
                        type={type}
                        setType={setType}
                        conditionAffichageTableauAmortissementAnnualise={empruntState.periode_echeance !== 3}
                        periodicite={empruntState.periode_echeance}
                        somme={resultat?.somme_tableau_amortissement || {}}
                    />
                </div>
                <div className='impactsIFI' style={{ marginBottom: 10 }} >
                    <ImpactsIFI
                        openIFI={openIFI}
                        setOpenIFI={setOpenIFI}
                        withoutCalcul={withoutCalcul}
                        data={!withoutCalcul ? resultat && resultat.impact_ifi ? resultat.impact_ifi : {} : {}}
                        setWithCalcul={setWithCalcul}
                    />
                </div>
            </div>
        </>
    )
}

export default Emprunt;
