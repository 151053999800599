import { CButton, CImg } from '@coreui/react';
import React from 'react';
import EyeIcon from '../../assets/images/eye.svg'

const Eye = ({
    style,
    onRequestClick
}) => {
    const eyeStyle = {
        display: 'flex',
        justifyContent: 'center',
        cursor: 'pointer',
        width: '20px',
        marginLeft: 10,
        marginRight: 10,
        ...(style || {})
    }

    return (
        <CButton onClick={onRequestClick} style={{ ...eyeStyle }}>
            <CImg src={EyeIcon} style={{ width: '20px' }} />
        </CButton>
    )
}

export default Eye;