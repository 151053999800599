import { CCard, CCardBody, CCardHeader, CCol, CForm, CRow } from '@coreui/react';
import React, { useContext, useEffect, useState } from 'react';
import Div1Component from './Div1Component/Div1Component';
import useStyles from './style';
import { FaInfoCircle } from 'react-icons/fa';
import { CessionContext } from '../CessionHaut';
import { numberFormat } from '../../../../extra/all_ppt_render';
import { fetchAllResult, getData } from '../ProjectionCessionSociete/ProjetCessionSociete';
import { structureDonationData } from '../OperationAvantCession/DonationParConjoint/DonationParConjoint';
import { LayoutContext } from '../../../../App2';
import { UtilsContext } from '../../../../store/context/utils'
import Loading from '../../../../components/loader';
import { restApiUri } from '../../../../config'
import TableauBord2 from './TableauBord2/TableauBord2';
import { SimulateurContext } from '../../../../store/context/simulateur';
import Information from '../../../../components/Information/Information';
import { messageErreurEnregistrementSimulation, messageSuccesEnregistrementSimulation } from '../../../../utils';
import ScrollButton from '../../../../components/Button/scrollToTop';
import { updatePostItData } from '../../../../store/actions/simulateur';

export const structurationDataSimulation = (cession, simulateurState) => {
    const simulation = new Object();
    const {
        identiteActionnaire: identite,
        projetCessionSociete: projet,
        operationsAvantCession: operations
    } = cession;

    simulation["identifiant_simulation"] = simulateurState.simulation ? simulateurState.simulation.identifiant_simulation : 'brouillon';
    simulation["terme_simulation"] = cession.terme;
    simulation["commentaire"] = simulateurState.simulation ? simulateurState.simulation.commentaire : '';
    simulation["clientId"] = simulateurState.simulation ? simulateurState.simulation.clientId : null;
    // simulation["userId"] = cession.userId;
    simulation["userId"] = JSON.parse(localStorage.getItem('user')).id
    return {
        ...simulation,
        ...fetchAllResult(identite.conjoints, identite.enfants, projet, operations, identite.affichageNomPersonnalise ? 1 : 0),
        //...structureDonationData(identite.conjoints, identite.enfants, operations),
    };
};

export const getResultatSimulation = async (data) => {
    const query = await fetch(`${restApiUri}/api/cession/simuler_cession`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${localStorage.getItem("token")}`
        },
        body: JSON.stringify({
            ...data,
        }),
        cache: 'no-cache'
    });

    return query.json();
};

export const addOrUpdateSimulation = async (cession, simulateurState, id) => {
    const query = await fetch(`${restApiUri}/api/cession${id ? "/" + id : ""}`, {
        method: id ? 'PUT' : 'POST',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${localStorage.getItem("token")}`
        },
        body: JSON.stringify(structurationDataSimulation(cession, simulateurState)),
        cache: 'no-cache'
    });

    return query.json();
};

export const saveSimulationCession = async (
    cession,
    simulateurState,
    onRequestStart,
    onRequestFinish,
    onRequestSuccess,
    onRequestFail,
    id
) => {
    onRequestStart();
    addOrUpdateSimulation(cession, simulateurState, id)
        .then((response) => {

            if (response) {
                onRequestSuccess(response);
            }
        })
        .catch((error) => onRequestFail())
        .finally(() => onRequestFinish())
};

const ResultatSimulation = () => {
    const classes = useStyles();
    const { layoutState, setLayoutState } = useContext(LayoutContext);
    const { cessionHaut, setCessionHaut } = useContext(CessionContext);
    const { utilsDispatch } = useContext(UtilsContext)
    const { simulateurState } = useContext(SimulateurContext)

    const { footerButtons, menuLevel } = layoutState;
    const [resultat, setResultat] = useState(null);

    const { projetCessionSociete: projet, identiteActionnaire: identite, operationsAvantCession: operations, idSimulation } = cessionHaut;
    let { tb1, tb2 } = cessionHaut;

    const [loading, setLoading] = useState(false);
    const [load, setLoad] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    // Cette méthode est très importante pour les SAUTS de section
    useEffect(() => {
        setLoading(true);

        // Récupération des résultats synthétiques
        getData(fetchAllResult(identite.conjoints, identite.enfants, projet, operations))
            .then((response) => {
                if (response) {
                    setResultat(response.resultat2);
                }
            })
            .catch((error) => console.error(error))
            .finally(() => setLoading(false))
    }, []);

    useEffect(() => {
        setLayoutState((prevState) => {
            footerButtons.cs = [
                {
                    type: 'PREVIOUS',
                    text: "Précédent",
                    onClick: () => {
                        setLayoutState((prev) => {
                            menuLevel.cs = 3;

                            return {
                                ...prev,
                                menuLevel
                            };
                        })
                    }
                },
                {
                    type: "ADD",
                    text: "Enregistrer",
                    onClick: () => {
                        saveSimulationCession(
                            cessionHaut,
                            simulateurState,
                            () => { setLoad(true) },
                            () => { setLoad(false) },
                            (response) => {
                                const id = response.id;
                                if (id) {
                                    identite.idSimulationOnSave = id;
                                    projet.idSimulationOnSave = id;
                                }
                                utilsDispatch({ type: 'ADD_TOAST', payload: { type: 'success', title: 'OK', description: messageSuccesEnregistrementSimulation(simulateurState?.simulation?.identifiant_simulation) } })
                            },
                            () => { utilsDispatch({ type: 'ADD_TOAST', payload: { type: 'danger', title: 'ERREUR', description: messageErreurEnregistrementSimulation(simulateurState?.simulation?.identifiant_simulation) } }) },
                            idSimulation || identite.idSimulationOnSave || projet.idSimulationOnSave
                        )
                    }
                },
                {
                    type: 'NEXT',
                    text: "Suivant",
                    onClick: () => {
                        setLayoutState((prev) => {
                            menuLevel.cs = 5;

                            return {
                                ...prev,
                                menuLevel
                            };
                        })
                    }
                },
            ]

            return {
                ...prevState,
                footerButtons
            };
        });
    }, [cessionHaut, simulateurState]);

    useEffect(() => {
        if (resultat) {
            tb1 = resultat.tableau_de_bord_1;
            tb2 = resultat.tableau_de_bord_2;

            setCessionHaut((prevState) => {
                return {
                    ...prevState,
                    tb1,
                    tb2
                }
            });
        }
    }, [resultat]);

    // Il faut revoir toutes les valeurs !
    return (
        <>
            <div>
                {load || loading && <Loading />}
                <div id="resultatSimulation" className="formulaire">
                    <CCol xl={12}>
                        <CForm>
                            <div className='structureBlock' style={{ padding: 15, marginBottom: 12, backgroundColor: "#fff", borderRadius: 7 }}>
                                <div style={{ paddingBottom: 8 }}>
                                    <div style={{ textTransform: "uppercase", fontSize: 15, fontWeight: "700" }}>Tableau de bord n°1</div>
                                    <div style={{ color: "#7F869A", fontWeight: "700", marginBottom: 9, fontSize: 15 }}>
                                        Imposition dans la situation actuelle à la flat tax sans régime de faveur
                                        <div style={{ position: 'absolute', width: 33, height: 4, background: "linear-gradient(285deg,#EDC413,#F67C0B)", borderRadius: 4, marginTop: -2 }}></div>
                                    </div>
                                </div>
                                <div>
                                    <CRow>
                                        <CCol sm={12}>
                                            <div className={classes.divRoot}>
                                                <div className={classes.div1}>
                                                    <div style={{ flex: 1, marginRight: "1%", marginBottom: '2%', width: '19%' }} >
                                                        <Div1Component
                                                            label="Prix de cession"
                                                            value={
                                                                resultat &&
                                                                    resultat.tableau_de_bord_1 &&
                                                                    resultat.tableau_de_bord_1.prix_cession ? numberFormat(Math.round(resultat.tableau_de_bord_1.prix_cession)) : 0
                                                            }
                                                            pourcentage={
                                                                resultat &&
                                                                    resultat.tableau_de_bord_1 &&
                                                                    resultat.tableau_de_bord_1.taux_prix_cession ? Math.round(resultat.tableau_de_bord_1.taux_prix_cession) : 0
                                                            }
                                                            color="#585C66"
                                                            colorTitle='#7F869A'
                                                            colorGraph="#8E5FA8"
                                                            background="#fff"
                                                            opacity="#D2C5EB"

                                                        />
                                                    </div>
                                                    <div style={{ flex: 1, marginRight: '1%', marginBottom: '2%', width: '19%' }}>
                                                        <Div1Component
                                                            label="PV brute"
                                                            value={
                                                                resultat &&
                                                                    resultat.tableau_de_bord_1 &&
                                                                    resultat.tableau_de_bord_1.plus_value_brute ? numberFormat(Math.round(resultat.tableau_de_bord_1.plus_value_brute)) : 0
                                                            }
                                                            pourcentage={`${resultat &&
                                                                resultat.tableau_de_bord_1 &&
                                                                resultat.tableau_de_bord_1.taux_plus_value_brute ? Math.round(resultat.tableau_de_bord_1.taux_plus_value_brute) : 0}`}
                                                            color="#585C66"
                                                            colorTitle='#7F869A'
                                                            colorGraph="#318FCC"
                                                            background="#fff"
                                                            opacity="#7f90bc"
                                                        />
                                                    </div>
                                                    <div style={{ flex: 1, marginRight: "1%", marginBottom: '2%', width: '19%' }}  >
                                                        <Div1Component
                                                            label="Impôts sur PV"
                                                            value=
                                                            {
                                                                resultat &&
                                                                    resultat.tableau_de_bord_1 &&
                                                                    resultat.tableau_de_bord_1.impot_sur_plus_value ? numberFormat(Math.round(resultat.tableau_de_bord_1.impot_sur_plus_value)) : 0
                                                            }
                                                            pourcentage={
                                                                resultat &&
                                                                    resultat.tableau_de_bord_1 &&
                                                                    resultat.tableau_de_bord_1.taux_impot_sur_plus_value ? parseFloat(resultat.tableau_de_bord_1.taux_impot_sur_plus_value).toFixed(2) : 0
                                                            }
                                                            color="#585C66"
                                                            colorTitle='#7F869A'
                                                            colorGraph="#EF4F6C"
                                                            background="#fff"
                                                            opacity="#FFCED7"
                                                        />
                                                    </div>
                                                    <div style={{ flex: 1, marginRight: '1%', marginBottom: '2%', width: '19%' }} >
                                                        <Div1Component
                                                            label="Net de cession"
                                                            value={
                                                                resultat &&
                                                                    resultat.tableau_de_bord_1 &&
                                                                    resultat.tableau_de_bord_1.net_cession ? numberFormat(Math.round(resultat.tableau_de_bord_1.net_cession)) : 0
                                                            }
                                                            pourcentage={
                                                                resultat &&
                                                                    resultat.tableau_de_bord_1 &&
                                                                    resultat.tableau_de_bord_1.taux_net_cession ? Math.round(resultat.tableau_de_bord_1.taux_net_cession) : 0
                                                            }
                                                            color="#585C66"
                                                            colorTitle='#7F869A'
                                                            colorGraph="#279757"
                                                            background="#fff"
                                                            opacity="rgb(196,231,206)"
                                                        />
                                                    </div>
                                                    <div style={{ flex: 1, marginBottom: '2%', width: '19%' }}>
                                                        <Div1Component
                                                            label="Optimisation fiscale"
                                                            value={
                                                                resultat &&
                                                                    resultat.tableau_de_bord_1 &&
                                                                    resultat.tableau_de_bord_1.optimisation_fiscal ? numberFormat(Math.round(resultat.tableau_de_bord_1.optimisation_fiscal)) : 0
                                                            }
                                                            color="#EBC213"
                                                            colorTitle='#EBC213'
                                                            colorGraph='#EBC213'
                                                            background="#FFFAE2"
                                                        />
                                                    </div>
                                                </div>
                                                <Information
                                                    text="Les gains fiscaux sont calculés par rapport au scénario de référence (cession dans la situation actuelle fiscalisée à la flat tax) et compte tenu des hypothèses envisagées en termes d'apports / donations avant cession. Il s'agit de gains immédiats estimés post apport / donation / cession et avant succession."
                                                />
                                                {/*<div className={classes.div3Container}>
                                                <div className={classes.infoIcon} ><FaInfoCircle color='#1D51A1' size={26} className="mb-1 mr-2" /></div>
                                                <div style={{ color: "rgb(82,113,199)", fontSize:13 }} >
                                                    Les gains fiscaux sont calculés par rapport au scénario de référence (cession dans la situation actuelle fiscalisée à la flat tax) et compte tenu des hypothèses envisagées en termes d'apports / donations avant cession. Il s'agit de gains immédiats estimés post apport / donation / cession et avant succession.
                                                </div>
                                            </div>*/}
                                            </div>
                                        </CCol>
                                    </CRow>
                                </div>
                            </div>
                        </CForm>
                    </CCol>
                    {/**Tableau de bord n°2 */}
                    <CCol>
                        <CForm>
                            <div className='structureBlock' style={{ padding: 15, marginBottom: 12, backgroundColor: "#fff", borderRadius: 7 }}>
                                <div style={{ textTransform: "uppercase", fontSize: 15, fontWeight: "700" }}>
                                    Tableau de bord n°2
                                </div>
                                <div style={{ paddingTop: 0, paddingBottom: 8, fontSize: 15 }}>
                                    <CRow>
                                        <CCol sm={12}>
                                            <TableauBord2
                                                data={
                                                    resultat &&
                                                        resultat.tableau_de_bord_2 ? resultat.tableau_de_bord_2 : undefined
                                                }
                                            />
                                        </CCol>
                                    </CRow>
                                </div>
                            </div>
                        </CForm>
                    </CCol>
                </div>
            </div>
        </>
    );
};

export default ResultatSimulation;
