import React, { Component } from "react";
import NumberFormat from 'react-number-format';
import { CInput } from '@coreui/react';

export default class InputTextSuccession extends Component {
    render() {
        return(
            <CInput
                id={this.props.id}
                value={this.props.value}
                onKeyDown={event=> {
                    if (event.keyCode === 13) {
                        const form = event.target.form;
                        if( form ) {
                            let index = Array.prototype.indexOf.call(form, event.target);
                            if(form.elements[index + 1])
                                form.elements[index + 1].focus();
                            event.preventDefault();
                        }
                    }
                }}
                placeholder={this.props.placeholder}
                onChange={this.props.onChange}
                className= {this.props.className? this.props.className : "col-lg-3 mr-3"}
                disabled={this.props.disabled}
                style={{...this.props.style,textAlign:'right',fontSize:13}}
            />
        )
    }
}