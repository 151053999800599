export const updateListClientSelect = (payload) => {
    return {
        type: "UPDATE_CLIENT_LIST",
        payload
    }
}

export const refreshHeader = () => {
    return {
        type: "REFRESH_HEADER",
    }
}
