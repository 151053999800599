import React, { useContext, useEffect, useState } from 'react';
import { LayoutContext } from '../../../../App2';
import { restApiUri } from '../../../../config';
import UsufruitNPLayout from '../UsufruitNPLayout';
import Loading from '../../../../components/loader';
import { numberFormat } from '../../../../extra/all_ppt_render';
import { useParams } from 'react-router-dom';
import { moveSpaceIntoCurrency } from '../../../Cession/CessionHaut/OperationAvantCession/ApportsAvantCession/ApportsAvantCession';
import { UtilsContext } from '../../../../store/context/utils';
import { SimulateurContext } from '../../../../store/context/simulateur';
import { userId } from '../../../Cession/CessionHaut/CessionHaut';
import { desactivePageBlock, resetAction, updatePostItData, updateSimulation } from '../../../../store/actions/simulateur';
import exportUsufruitViager from '../../../../extra/DonationsSuccession/UsufruitNP/usufruitViager';
import { DataControlContext } from '../../../../store/context/dataControl';
import { messageSuccesEnregistrementSimulation } from '../../../../utils';
import PostitService from '../../../../services/postitService';
import { sortPostitByIndexCase } from '../../../../components/Postit/Postit';

export const currencyToSend = (currency) => {
    return typeof currency === 'string' ? ( currency === "" ? 0 : moveSpaceIntoCurrency(currency) ) : currency;
};

const UsufruitViager = () => {
    const { profilUtilisateurState } = useContext(DataControlContext);
    const { layoutState, setLayoutState } = useContext(LayoutContext);
    const { simulateurState: etatSimulateur, simulateurDispatch } = useContext(SimulateurContext);
    const { simulation } = etatSimulateur;
    const { utilsDispatch } = useContext(UtilsContext);
    const { footerButtons, simulateurState : data } = layoutState;
    const { usufNPusufViager : simulateurState } = data;
    const [ loading, setLoading ] = useState(false);
    const { id } = useParams();
    const defaultData = {
        firstForm: {
            label: "Age de l'usufruitier",
            value: 0
        },
        lastForm: {
            label: "Contre-valeur en pleine-propriété",
            value: 0
        }
      };

    const simulerUsufruitViager = async (age, contreValeurPP) => {
        const data = {
            age: age,
            contre_valeur: contreValeurPP
        };

        const query = await fetch(`${restApiUri}/api/usufruit/simulerUsufruitViager`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
            body: JSON.stringify(data)
        });

        return query.json();
    };

    const structurationResponse = (response) => {
        const rows = [];
        const rows2 = [];
        const rows3 = [];

        const tableau = response.tableau;
        const valorisationsFiscales = response.valorisation_fiscale;

        tableau.map((data, index) => {
            rows.push(data.age_usufruit);
            rows2.push({
                label: `${data.taux_valeur_fiscale} %`,
                value: `${numberFormat(data.valeur_fiscale)} €`
            });
            rows3.push({
                label: `${data.taux_valeur_fiscale_np} %`,
                value: `${numberFormat(data.valeur_fiscale_np)} €`
            });
        });

        const graphData = [
            {
                label: "Usufruit Viager",
                realValue: valorisationsFiscales.usuf_viager,
                value: valorisationsFiscales.taux_usuf_viager,
            },
            {
                label: "Nue-propriété",
                realValue: valorisationsFiscales.usuf_viager_np,
                value: valorisationsFiscales.taux_usuf_viager_np
            }
        ];

        return {
            rows,
            rows2,
            rows3,
            graphData
        };
    };

    const saveSimulation = async (data, id=undefined) => {
        const query = await fetch(`${restApiUri}/api/usufruit/${ id ? `UsufruitViager/${id}` : `addUsufruitViager` }`, {
            method: id ? 'PUT' : 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
            body: JSON.stringify(data)
        });

        return query.json();
    };

    const fetchSimulation = async (id) => {
        const query = await fetch(`${restApiUri}/api/usufruit/findOneUsufruitViager/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        });

        return query.json();
    };

    // Données utilisées par le Graphe
    const [ circleGrapheData, setCircleGrapheData ] = useState([]);

    // Les données des 3 tableaux
    const [ rows, setRows ] = useState([]);
    const [ rows2, setRows2 ] = useState([]);
    const [ rows3, setRows3 ] = useState([]);
    let [ idAfterInsertion, setIdAfterInsertion ] = useState(undefined);

    useEffect(() => {
        const fetchPins = async (type, userId, idSimulation) => {
          try {
            const pins = await PostitService.getPostitsByType(type, userId, idSimulation)

            simulateurDispatch(updatePostItData({
              ...(etatSimulateur?.postItData || {}),
              elements: sortPostitByIndexCase(pins)
            }))
          } catch (error) {
            console.log(error);
          }
        }
        if(id || idAfterInsertion){
            setLoading(true)
            fetchSimulation(id || idAfterInsertion)
            .then((response) => {
                if(response){
                    data.usufNPusufViager.firstForm.value = response.age;
                    data.usufNPusufViager.lastForm.value = response.contre_valeur;

                    simulateurDispatch(updateSimulation({
                        identifiant_simulation: response.identifiant_simulation,
                        clientId: response.clientId,
                        commentaire: response.commentaire
                    }));

                    setLayoutState((prevState) => {
                        return {
                            ...prevState,
                            simulateurState: data
                        }
                    })

                }
            })
            .catch((error) => console.error(error))
            .finally(() => {
              setLoading(false);
              fetchPins("usufruit-viager", localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user'))?.id : null, id);
            })
        }
        else{
            data.usufNPusufViager = defaultData;
            setLayoutState((prevState) => {
                return {
                    ...prevState,
                    simulateurState: data
                }
            });
            simulateurDispatch(updateSimulation({
                identifiant_simulation: "brouillon",
                clientId: null,
                commentaire: ""
            }))
        }
    }, []);

    useEffect(() => {

        setLoading(true);
        simulerUsufruitViager(currencyToSend(simulateurState.firstForm.value), currencyToSend(simulateurState.lastForm.value))
        .then((response) => {
            if(response){
                const { rows: lignes, rows2: lignes2, rows3: lignes3, graphData } = structurationResponse(response);
                const map_ligne2 = lignes2.map(data => {
                    if(data.value.includes("undefined")){
                        return {
                            ...data,
                            value: data.value.replace("undefined", "0")};
                    }
                    return data;
                })
                const map_lignes3 = lignes3.map(data => {
                    if(data.value.includes("undefined")){
                        return {
                            ...data,
                            value: data.value.replace("undefined", "0")};
                    }
                    return data;
                })
                setRows(lignes);
                setRows2(map_ligne2);
                setRows3(map_lignes3);
                setCircleGrapheData(graphData);
            }
        })
        .catch((error) => console.error(error))
        .finally(() => setLoading(false))
    }, [
        simulateurState.firstForm.value,
        simulateurState.lastForm.value
    ]);

    useEffect(() => {
        setLayoutState((prevState) => {
            footerButtons.usufNPusufViager = [
                {
                    type: 'ADD',
                    text: 'Enregistrer',
                    onClick: () => {
                        const dataToSend = {
                            identifiant_simulation: simulation.identifiant_simulation,
                            clientId: simulation.clientId,
                            commentaire: simulation.commentaire,
                            terme_simulation: "fin",
                            age: currencyToSend(simulateurState.firstForm.value),
                            contre_valeur: currencyToSend(simulateurState.lastForm.value),
                            userId: JSON.parse(localStorage.getItem('user'))?.id || null
                        };

                        // const identifiant = id ? id : idAfterInsertion ? idAfterInsertion : etatSimulateur?.postItData.simulationId;
                        const identifiant = id ? id : idAfterInsertion ? idAfterInsertion : etatSimulateur?.postItData.simulationType === 'usufruit-viager' ? etatSimulateur?.postItData.simulationId : undefined;
                        setLoading(true);
                        saveSimulation(dataToSend, identifiant)
                        .then((response) => {
                            if(response){
                                if(response.id){
                                    setIdAfterInsertion(response.id);
                                    idAfterInsertion = response.id;
                                    simulateurDispatch(updatePostItData({
                                      ...(etatSimulateur?.postItData || {}),
                                      simulationId: response.id,
                                      simulationType: 'usufruit-viager'
                                    }))
                                };
                                utilsDispatch({type: 'ADD_TOAST', payload: {type: 'success', title:'OK', description: messageSuccesEnregistrementSimulation(simulation?.identifiant_simulation) } })

                                simulateurDispatch(desactivePageBlock())
                            }
                        })
                        .catch((error) => console.error(error))
                        .finally(() => setLoading(false))
                    }
                },
                {
                    type: 'CLONE_SIMULATION',
                    text: 'Enregistrer sous',
                    onClick: () => {}
                },
            ]

            return {
                ...prevState,
                footerButtons
            }
        });
    }, [
        simulateurState.firstForm.value,
        simulateurState.lastForm.value,
        JSON.stringify(simulation)
    ]);

    useEffect(() => {
        if(etatSimulateur.action === 'export_ppt'){
            exportUsufruitViager(profilUtilisateurState, simulateurState, rows, rows2, rows3, circleGrapheData);
            simulateurDispatch(resetAction());
        }
    }, [ etatSimulateur ])

    return (
        <>
            { loading && <Loading /> }
            <UsufruitNPLayout
                type="usufruit-viager"
                firstForm={simulateurState.firstForm}
                lastForm={simulateurState.lastForm}
                onSimulateurStateChange={setLayoutState}
                circleGrapheData={circleGrapheData}
                rows={rows}
                rows2={rows2}
                rows3={rows3}
            />
        </>
    );
};

export default UsufruitViager;
