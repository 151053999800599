import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
    divRoot: {
        display: 'flex',
        flexDirection: 'column',
    },

    div1: {
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
        justifyContent: 'center',
        // marginBottom: "5px"
    },
    infoContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        background: "rgb(217,243,229)",
        border: "1px solid rgb(48,190,112)",
        padding: 10,
        borderRadius: '15px',
        fontWeight: 'bold',
        width: "100%",
        marginTop: 15,
    },

    div2: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: 15,
        width: '100%',
    },

    div3: {
        marginTop: 15,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%',
        textAlign: 'center',
        fontWeight: 'bold'
    },
    sousDiv: {
        marginTop: -10,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        width: '60%',
        textAlign: 'center',
        fontWeight: 'bold',
        marginLeft: 75
    },
    infoIcon: {
        lineHeight: 3
    },

    allInfoDiv2: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },

    div2Section1 : {
        display: 'flex',
        flexDirection: 'row'
    },
    div2Section2 : {
        display: 'flex',
        flexDirection: 'row'
    },
    div2Container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: 20,
        fontWeight: 'bold',
        width: "100%",
        marginTop: 35,
        marginBottom: 5,
        borderRadius: 10,
        fontSize: 15,
        color: "rgb(64,64,64)",
        background: 'rgb(242,198,98)'
    },
    div2Label: {
        textAlign: 'center',
        background: 'rgb(252,230,228)',
        width: '70%',
        padding: 20
    },
    div2Value: {
        textAlign: 'center',
        background: 'rgb(238,88,76)',
        width: '30%',
        padding: 20,
    },


    div3Container: {
        display: 'flex',
        flexDirection: 'row',
        width: "100%",
        padding: 15,
        background: "rgb(225,232,248)",
        border: "1px solid rgb(118,143,211)",
        borderRadius: "10px",
        alignItems:'center',
        marginTop: -23
    },
    
    div5Container: {
        display: 'flex',
        flexDirection: 'row',
        width: "100%",
        padding: 15,
        background: 'rgb(242,242,242)',
        marginTop: 40
    },
    div3Label: {
        textAlign: 'center',
        background: 'rgb(217,243,229)',
        color: 'rgb(0,176,80)',
        width: '70%',
        padding: 25,
        borderRadius: "15px 0 0 15px"
    },
    div3Value: {
        textAlign: 'center',
        background: 'rgb(0,176,80)',
        width: '30%',
        padding: 25,
        color: '#FFFFFF',
        borderRadius: '15px 15px 15px 15px'
    },

    div4Container: {
        display: 'flex',
        flexDirection: 'row',
        width: '70%',
        justifyContent: 'space-around',
        position: 'relative',
        top: -15
    },

    div4Content: {
        display: 'flex',
        flexDirection: 'column',
        background: 'rgb(0,176,80)',
        color: '#FFFFFF',
        fontWeight: 'bold',
        borderRadius: '15px',
        textAlign: 'center',
        padding: 25,
        width: "25%",
    },
    
    divFact: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        marginTop: 5,
    },
});

export default useStyles;