import React, { useContext, useEffect, useRef, useState } from 'react'
import { ReactComponent as PostitPinGray } from '../../assets/icons/svg/postit/postitpin-gray.svg';
import { ReactComponent as PostitPinGrayHover } from '../../assets/icons/svg/postit/postitpin-gray-hover.svg';
import { ReactComponent as PostitPinYellow } from '../../assets/icons/svg/postit/postitpin-yellow.svg';
import { ReactComponent as PostitPinYellowHover } from '../../assets/icons/svg/postit/postitpin-yellow-hover.svg';
import './styles/postitpin.scss';
// import { dateAuFormatComplet } from '../../utils';
// import moment from 'moment';
import { SimulateurContext } from '../../store/context/simulateur';
import { updatePostItData } from '../../store/actions/simulateur';
import { useParams } from 'react-router-dom';
import PostitService from '../../services/postitService';
// import { LayoutContext } from '../../App2';
import { UtilsContext } from '../../store/context/utils';
import { addToast } from '../../store/actions/utils/toast';
// import { DataControlContext } from '../../store/context/dataControl';

const PostiPinManager = ({ pin, isCommenting, isPostitShow}) => {
  if (pin.isSet && isCommenting) {
    return <PostitPinYellowHover className='post-it-pin-yellow-hover' />
  } else if (pin.isSet && !isCommenting) {
    return <PostitPinYellow className='post-it-pin-yellow' />
  } else if (!pin.isSet && isCommenting && isPostitShow) {
    return <PostitPinGrayHover className='post-it-pin-gray-hover' />
  } else if (isPostitShow) {
    return <PostitPinGray className='post-it-pin-gray' />
  }

  return <></>
}

const PostitPin = ({
  pinId,
  isPostitShow,
  pinTop = "-8px",
  pinRight = "-7px",
  pinTopHover = "-14px",
  pinRightHover = "-11px",
  pinSelectedTopHover = "-11px",
  pinSelectedRightHover = "-11px"}) => {

  const { simulateurState, simulateurDispatch } = useContext(SimulateurContext);
  const { utilsState, utilsDispatch } = useContext(UtilsContext)

  const [commenting, SetCommenting] = useState(false);

  const { id } = useParams();

  const [pin, SetPin] = useState({
    idPostit: pinId,
    date: new Date(),
    commentaire: '',
    isSet: false,
    type: id ? String(window.location.href).split('/')[String(window.location.href).split('/').length - 2] : String(window.location.href).split('/')[String(window.location.href).split('/').length - 1],
    idSimulation: 0,
    userId: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user'))?.id : null,
    tabIndex: 0
  });

  const pinRef = useRef(null);

  const handleComment = (event) => {
    const currentIdSimulation = id ? id :
            ![null, undefined].includes(simulateurState?.postItData.simulationId) ? simulateurState?.postItData.simulationId :
            simulateurState.simulation.id ? simulateurState.simulation.id : null;

    if (pin.isSet) {
      SetCommenting((value) => !value);
      simulateurDispatch(updatePostItData({
        ...(simulateurState?.postItData || {}),
        currentPostItId: !commenting ? pin.idPostit : null,
        hasPosted: false,
        currentPin: pin
      }))

      if (pin.isSet && !simulateurState?.postItData.displayBlocPostIt) {
        simulateurDispatch(updatePostItData({
          ...(simulateurState?.postItData || {}),
          displayBlocPostIt: true,
          currentPostItId: pin.idPostit,
        }))
      }

      return;
    }

    if (!id) {
      // displayPostitIdNull
      // simulateurDispatch(updatePostItData({
      //   ...(simulateurState?.postItData || {}),
      //   displayPostitIdNull: true
      // }))
      if (currentIdSimulation !== null) {
        simulateurDispatch(updatePostItData({
          ...(simulateurState?.postItData || {}),
          simulationId: null,
        }))
      } else {
        utilsDispatch(addToast({ type: 'danger', title: 'INFORMATION', description: "Vous devez sauvegarder pour ajouter un postit  pour cette simulation" }))
        return;
      }
    }

    const currentPin = pin;
    currentPin.idSimulation = currentIdSimulation;

    const hasPosted = !commenting;

    simulateurDispatch(updatePostItData({
      ...(simulateurState?.postItData || {}),
      hasPosted,
      currentPostItId: pin.idPostit,
      currentPin
    }))

    SetCommenting((value) => !value);

    if (pin.isSet && !simulateurState?.postItData.displayBlocPostIt) {
      simulateurDispatch(updatePostItData({
        ...(simulateurState?.postItData || {}),
        displayBlocPostIt: true,
        currentPostItId: pin.idPostit,
      }))
    }
  }

  useEffect(() => {
    if (!isPostitShow) {
      SetCommenting(false);
    }
  }, [isPostitShow])

  useEffect(() => {
    if (simulateurState?.postItData.currentPin) {
      if (simulateurState?.postItData.currentPin.idPostit === pin.idPostit) {
        SetPin(simulateurState?.postItData.currentPin);
      }
    }
    if (commenting && pin.idPostit !== simulateurState?.postItData.currentPostItId) {
      SetCommenting(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [simulateurState?.postItData.currentPin])

  useEffect(() => {
    if (simulateurState?.postItData.hasPosted && pin.idPostit !== simulateurState?.postItData.currentPostItId) {
      SetCommenting(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [simulateurState?.postItData.hasPosted])

  useEffect(() => {
    setTimeout(() => {
      if (simulateurState?.postItData.requestView && pin.idPostit === simulateurState?.postItData.currentPostItId) {
        SetCommenting(true);
        simulateurDispatch(updatePostItData({
          ...(simulateurState?.postItData || {}),
          requestView: false
        }))
      } else if (simulateurState?.postItData.requestView && pin.idPostit !== simulateurState?.postItData.currentPostItId) {
        SetCommenting(false);
        simulateurDispatch(updatePostItData({
          ...(simulateurState?.postItData || {}),
          requestView: false
        }))
      }
    }, 300);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [simulateurState?.postItData.requestView])

  useEffect(() => {
    if (simulateurState?.postItData.elements) {
      const currentPin = pin;
      const currentElements = [...simulateurState?.postItData.elements];
      if (currentElements.length > 0) {
        const findPin = currentElements.find(el => currentPin.idPostit === el.idPostit)
        if (findPin) {
          SetPin(findPin);
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[simulateurState?.postItData.elements])

  useEffect(() => {
    // eslint-disable-next-line no-unused-vars
    const fetchPosit = async () => {
      if (!id) return;
      try {
        const postitById = await PostitService.getPostitById(pin.idPostit, pin.userId, id);
        if (postitById) {
            // console.log(postitById);
            const currentPin = postitById;
            SetPin(currentPin);

            const currentElements = [...simulateurState?.postItData.elements];

            if (!currentElements.find(el => currentPin.id === el.id)) {
              currentElements.push(currentPin);
            }

            simulateurDispatch(updatePostItData({
              ...(simulateurState?.postItData || {}),
              currentPostItId: currentPin.idPostit,
              currentPin,
              elements: currentElements
            }))
        }
      } catch (error) {}
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div style={{
      "--pin-top": !commenting ? pinTop : pinTopHover,
      "--pin-right": !commenting ? pinRight : pinRightHover,
      "--pin-top-hover": pinSelectedTopHover,
      "--pin-right-hover": pinSelectedRightHover
      }}
      onClick={(event) => handleComment(event)}
      ref={pinRef}
      className={`post-it-pin-container ${commenting ? 'active' : 'not-active'}`}>
      <PostiPinManager
        isPostitShow={isPostitShow}
        pin={pin}
        isCommenting={commenting} />
    </div>
  )
}

export default PostitPin;
