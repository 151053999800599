import React from "react";
import "./SwitchGreen.css";

const SwitchGreen = (props) => {
  const { isToggled, onToggleChange, bgColor, border } = props;

  return (
    <label className="toggle-switchGreen">
      <input type="checkbox" checked={isToggled} onChange={onToggleChange} />
      <span className="switchGreen" style={{background:bgColor, borderColor:border}}/>
    </label>
  );
}

export default SwitchGreen;