import React, { useContext, useState } from 'react';
import ButtonImportAbbove from './ButtonImportAbbove';
import { restApiUri } from '../../config';
import Loading from '../../components/loader';
import { UtilsContext } from '../../store/context/utils';
import { addToast } from '../../store/actions/utils/toast';
import { updateClientList } from '../../store/actions/simulateur';
import { SimulateurContext } from '../../store/context/simulateur';

const Abbove = ({ showInfo, setShowInfo}) => {

  const [loading, setLoading] = useState(false);
  const { utilsDispatch } = useContext(UtilsContext);

  const { simulateurDispatch } = useContext(SimulateurContext);

  const clientsFetch = () => {
    const user = JSON.parse(localStorage.getItem('user'))
    fetch(`${restApiUri}/api/client/findClient?recherche=&poleId=` + user.pole.id + "&userId=" + user.id, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        //Authorization: `Bearer ${localStorage.getItem("token")}`
      },
    })
      .then(resp => resp.json())
      .then(data => {
        if (data.message) {
        } else if (data.mesClientsPole.length > 0 || data.autreClientPole.length > 0) {
          let customers = data.mesClientsPole.concat(data.autreClientPole);

          customers.sort(function (a, b) {

            let keyA = a.nom === '' ? a.prenom : a.nom;
            let keyB = b.nom === '' ? b.prenom : b.nom;

            if (keyA < keyB) return -1
            if (keyA > keyB) return 1

            return 0
          })

          simulateurDispatch(updateClientList(customers))
        }

      }).catch(e => console.log(e))
  }

  const handleImport = (file) => {
    // Handle the imported JSON data here
    setLoading(true);
    const user = JSON.parse(localStorage.getItem('user'));

    const formData = new FormData()
    formData.append('uploaded_file', file);
    formData.append('poleId', user.pole.id)

    fetch(`${restApiUri}/api/abbove/lancer_mapping_json`, {
      method: "POST",
      // headers: {
      //   'Content-Type': 'multipart/form-data',
      //   //Authorization: `Bearer ${localStorage.getItem("token")}`
      // },
      body: formData
    }).catch((err) => {
      console.log(err);
      utilsDispatch(addToast({ type: 'danger', title: 'ERREUR', description: `Une erreur s'est survenue lors de l'importation du fichier JSON`, autoDelete: true }));
      setLoading(false);
    })
    .then(resp => resp.json())
    .then((data) => {
      if (data) {
        if(!data?.isClientAdded) {
          utilsDispatch(addToast({ type: 'success', title: 'VALIDATION', description: `Mise à jour de la fiche client ${data?.fiche_client?.client?.nom} ${data?.fiche_client?.client?.prenom} réussie`, autoDelete: false }));

        } else {
          utilsDispatch(addToast({ type: 'success', title: 'VALIDATION', description: `Importation de la fiche client ${data?.fiche_client?.client?.nom} ${data?.fiche_client?.client?.prenom} réussie`, autoDelete: false }));
        }
        utilsDispatch(addToast({ type: 'success', title: 'VALIDATION', description: `Importation du bilan patrimonial ${data?.bilan_patrimoniale?.identifiant_simulation} réussie`, autoDelete: false }));
        clientsFetch();
      }
      setLoading(false);
    })

    // console.log('Fiche client:', fiche_client);
    // console.log('Bilan pat:', bilan_patrimonial);
  };

  return (
    <div>
      {loading && <Loading />}
      <ButtonImportAbbove setShowInfo={setShowInfo} onImport={handleImport} />
    </div>
  );
};

export default Abbove;
