import React, { useContext, useEffect, useState } from 'react';
import DataGrid from 'react-data-grid';
import { Header } from '../../../../../../components/datagrid';
import { Editors } from 'react-data-grid-addons';
import "./style.scss";
import { CessionContext } from '../../../CessionHaut';
import { moveSpaceIntoCurrency } from '../../ApportsAvantCession/ApportsAvantCession';

    export const customFormatter = (text,align=null) => {
        const style = {};
        style.textAlign = align;

        return (
            <div style={style}>{text}</div>
        );
    };

    /**
     * props.type => title, valeurFiscale, annee, abattementFiscal.
     */
    const DonationParConjointTable = (props) => {
        const { DropDownEditor } = Editors;
        const abattementList = [
            "150 000",
            "100 000",
            "50 000"
        ];

        const { cessionHaut, setCessionHaut } = useContext(CessionContext);

        const { identiteActionnaire, operationsAvantCession: operations } = cessionHaut;
        const { conjoints, enfants : childs } = identiteActionnaire;
        const { donations } = operations;
        const enfants = props.data;

        const [ columns, setColumns ] = useState([]);
        const [ listeAnnee, setListeAnnee ] = useState([]);
        
        const rows = [];

        const [ lignes, setLignes ] = useState(rows);

        const onGridRowsUpdated = ({ fromRow, toRow, updated }) => {
            setLignes( (prevState) => {
                const rows = prevState.slice();
                for (let i = fromRow; i <= toRow; i++) {
                    rows[i] = { ...rows[i], ...updated };
                }

                return rows;
            });
        };

        useEffect( () => {
            const conjoint = props.conjoint - 1;
            const colonnes = [];
            const ligne = new Object();
            const lignes = [];
            let value = "";

            donations[conjoint].enfants.map((data, index) => {
                colonnes.push(
                    { 
                        key: `col${ index + 1 }`, 
                        width: 155,
                        editable: props.label === 'titre' ? false : true,
                        editor: true,//props.label === 'valeurFiscale' ? true : <DropDownEditor options={["2020", "2021"]} />,
                        formatter: Header,
                        //headerRenderer: customFormatter(childs[index].prenom, 'center')
                    }
                );

                if(props.label === 'valeurFiscale') value = data.valeurFiscale;
                else if(props.label === 'anneeDonation') value = data.anneeDonation;
                else if(props.label === 'abattementFiscal') value = data.abattementFiscal;
                else if(props.label === 'donationPP') value = data.donationPP;
                else if(props.label === 'donationNP') value = data.donationNP;
                else if(props.label === 'fraisDonation') value = data.fraisDonation;
                else value = `Enfant ${index + 1}`;

                ligne[`col${index + 1}`] = value;
            });

            lignes.push(ligne);



            setColumns(colonnes);
            setLignes(lignes);
        } , [] );

        /*useEffect( () => {
            if(lignes.length > 0){
                let donationsData = [];
                let donationObj = new Object();
                const valeursFiscales = lignes[0];
                const anneesDonation = lignes[1];
                const abattementsFiscaux = lignes[2];

                for(let prop in valeursFiscales){
                    let value = new Object();
                    value.valeur_fiscale = valeursFiscales[prop];
                    value.annee = anneesDonation[prop];
                    value.abattement_fiscale = abattementsFiscaux[prop];

                    donationsData.push(value);
                }

                for(let i = 0 ; i<donationsData.length ; i++){
                    donationsData[i].id = i + 1;
                    donationsData[i].nom = childs[i].prenom;
                    donationsData[i].handicape = childs[i].handicap ? 1 : 0;
                    donationsData[i].donation_pp = 0;
                    donationsData[i].donation_np = 0;
                }
                const data = new Object();
                const conj = new Object();

                conj.nom = conjoints[props.conjoint - 1].nomPersonnalise;
                conj.age = parseInt(conjoints[props.conjoint - 1].age, 10);
                if((props.conjoint - 1) === 0) conj.marie = conjoints[0].marieOuPasce === "Oui" ? 1 : 0;
                conj.revenu = moveSpaceIntoCurrency(conjoints[props.conjoint - 1].revenuAnneeCession);
                conj.donation_pp == 0;
                
                data.liste_enfant = donationsData;
                data.conjoint = conj;


                donationObj["liste_donation"] = data;

            }
        }, [ lignes ] );*/

        return (
            <div id="tableDonation">
                <DataGrid
                    columns={columns}
                    rowGetter={i => lignes[i]}
                    rowsCount={lignes.length}
                    minHeight={80}
                    rows={rows}
                    enableCellSelect={true}
                    onGridRowsUpdated={onGridRowsUpdated}
                    rowHeight={40}
                />
            </div>
        );
    }

export default DonationParConjointTable;