import React, { useContext } from "react";
import { SimulateurContext } from "../../store/context/simulateur";
import { activePageBlock } from "../../store/actions/simulateur";

const InputCheckBox = (props) => {
    const { simulateurDispatch } = useContext(SimulateurContext);

    const handleChange = (event) => {
        // On met à jour l'état de changement de FORMULAIRE
        simulateurDispatch(activePageBlock())

        // Changement de valeur contenue dans le FORMULAIRE
        props.onChange(event);
    }

    return (
        <input
            id={props.id}
            type="checkbox"
            value={props.value}
            checked={props.checked}
            onKeyDown={event => {
                if (event.keyCode === 13) {
                    const form = event.target.form;
                    let index = Array.prototype.indexOf.call(form, event.target);
                    if (form.elements[index + 1])
                        form.elements[index + 1].focus();
                    event.preventDefault();
                }
            }}
            onChange={handleChange}
            style={props.style}
            disabled={props.disabled}
        />
    )
}

export default InputCheckBox