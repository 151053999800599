import React, { useContext } from "react";
import NumberFormat from 'react-number-format';
import { CInput } from '@coreui/react';
import { activePageBlock } from "../../../../../store/actions/simulateur";
import { SimulateurContext } from "../../../../../store/context/simulateur";

const FirstForm = (props) => {
    const { simulateurDispatch } = useContext(SimulateurContext);

    const handleChange = (event) => {
        // On met à jour l'état de changement de FORMULAIRE
        simulateurDispatch(activePageBlock())

        // Changement de valeur contenue dans le FORMULAIRE
        props.onChange(event);
    }

    return(
        <NumberFormat
            id={props.id}
            customInput={CInput}
            style={{textAlign:'right', ...props.style}}
            allowNegative={false}
            isAllowed={(values) => {
                const {value} = values;
                const max = props.type === 'usufruit-viager' ? 150 : 31;
                return props.min ? value > props.min && value < max : (!value || value < max);
            }}
            onKeyDown={event=> {
                if (event.keyCode === 13) {
                    const form = event.target.form;
                    let index = Array.prototype.indexOf.call(form, event.target);
                    if(form.elements[index + 1])
                        form.elements[index + 1].focus();
                    event.preventDefault();
                }
            }}
            disabled={props.disabled}
            invalid={props.invalid}
            value={props.value}
            onChange={handleChange}
            className= {props.className? props.className : "col-lg-2 mr-2"}
            placeholder= { props.placeholder ? props.placeholder : "" }
        />
    )
};

export default FirstForm;