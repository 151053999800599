import { CImg } from '@coreui/react';
import React from 'react';
import FicheThematiqueIcon from '../../assets/images/fiche-thematique-icon.svg';
import NoticeCalculIcon from '../../assets/images/notice-calcul-icon.svg';
import { OrdinaryTooltip } from '../InfoBulle/Tooltip/Tooltip';
import './style.scss';

const FicheThematiqueNoticeButton = ({ onRequestClick, style, type }) => {
    const ButtonIcon = type === 'NOTICE' ? NoticeCalculIcon : FicheThematiqueIcon;

    const handleClick = () => {
        if (onRequestClick) {
            onRequestClick();
        }
    }

    return (
        <OrdinaryTooltip
            title={<div style={{ textAlign: 'center' }}>{type === 'NOTICE' ? 'Info calcul' : 'Fiche thématique'}</div>}
            arrow
            placement='bottom'
        >
            <div style={{ display: 'flex', alignItems: 'center', ...(style || {}) }}>
                <CImg className='bouton-fiche' src={ButtonIcon} onClick={handleClick} width={60} />
            </div>
        </OrdinaryTooltip>
    )
}

export default FicheThematiqueNoticeButton;