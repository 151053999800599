import React, { createContext, useReducer } from 'react';

const initialState = { donations_enfants: [], donations_petits_enfants: [], enfants: [] }

export const ClientContext = createContext(initialState)

const reducer = (state, {type, payload}) => {
  switch (type) {

    case 'ADD_DONATION_ENFANT':
        return {...state, donations_enfants: [...state.donations_enfants, payload]}
    case 'UPDATE_DONATION_ENFANT':
        let newDE = [...state.donations_enfants]
        newDE[payload.i] = { ...newDE[payload.i], ...payload}
        return {...state, donations_enfants: newDE}
    case 'DELETE_DONATION_ENFANT':
        return {...state, donations_enfants: state.donations_enfants.filter((donation, i) => i !== payload)}

    case 'ADD_DONATION_PETIT_ENFANT':
      return {...state, donations_petits_enfants: [...state.donations_petits_enfants, payload]}
    case 'UPDATE_DONATION_PETIT_ENFANT':
      let newDPE = [...state.donations_petits_enfants]
      newDPE[payload.i] = { ...newDPE[payload.i], ...payload}
      return {...state, donations_petits_enfants: newDPE}
    case 'DELETE_DONATION_PETIT_ENFANT':
      return {...state, donations_petits_enfants: state.donations_petits_enfants.filter((donation, i) => i !== payload)}

    case 'DELETE_ENFANT':
        return {...state, enfants: state.enfants.filter(e => e.id !== payload)}
    case 'UPDATE_ENFANTS':
        let index = -1
        let newE = [...state.enfants]
        state.enfants.forEach((enfant, i) => {
            if(enfant.index === payload.index)
            index = i
        })
        if(index !== -1) {
            newE[index] = {...state.enfants[index], ...payload}
        }
        else newE.push(payload)
        return newE
    
    default:
      return state
  }
}

export const ClientProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return <ClientContext.Provider value={{ clientState: state, clientDispatch: dispatch }}>{children}</ClientContext.Provider>
}