import React, { useEffect, useContext, useState } from 'react';
import { CCol, CCard, CCardBody, CRow, CFormGroup, CLabel, CForm } from '@coreui/react';
import useStyles from './style';
import { InputText, InputCurrency, InputAge, InputCheckBox, InputSelect, InputDate } from '../../../../components/input';
import Conjoint from './Conjoint/Conjoint';
import { CessionContext } from '../CessionHaut';
import moment from 'moment';
import { LayoutContext } from '../../../../App2';
import { SimulateurContext } from '../../../../store/context/simulateur';
import { UtilsContext } from '../../../../store/context/utils';
import Loading from '../../../../components/loader';
import { useParams } from 'react-router';
import { saveSimulationCession } from '../ResultatSimulation/ResultatSimulation';
import ToggleSwitch from '../../../../components/SwitchGreen/SwitchGreen';
import { calculAgeSelonDateNaissance, calculDateNaissanceSelonAge, formatToUniqueId, messageErreurEnregistrementSimulation, messageSuccesEnregistrementSimulation } from '../../../../utils';
import ScrollButton from '../../../../components/Button/scrollToTop';
import { updatePostItData } from '../../../../store/actions/simulateur';


export const chargeEnfantsValue = (age) => {
    let value = "";
    if (age === 0 || age === "") {
        value = "Non";
    }
    else {
        if (parseInt(age, 10) <= 18) value = "Oui";
        else if (parseInt(age, 10) > 25) value = "Non";
        else value = "Rattaché sur option";
    }

    return value;
};

export const calculPP = (conjoints, enfantsFamiliauxPP, holding, conjs) => {
    let somme = 0;
    const hold = holding.pourcentageDetention === "" || holding.pourcentageDetention === 0 ? 0 : parseFloat(holding.pourcentageDetention);

    conjoints.map((data, index) => {
        if (index === 0 || (index === 1 && conjs[0].marieOuPasce === "Oui")) {
            somme += data.pourcentageDetention === "" || data.pourcentageDetention === 0 ? 0 : parseFloat(data.pourcentageDetention);
        }
    });

    enfantsFamiliauxPP.map((data, index) => {
        somme += data.pourcentageDetention === "" || data.pourcentageDetention === 0 ? 0 : parseFloat(data.pourcentageDetention);
    });

    return {
        pp: somme + hold,
    };
};

const IdentiteActionnaire = () => {
    const classes = useStyles();

    // Remplacement de REDUX par Context de REACT
    const { cessionHaut, setCessionHaut } = useContext(CessionContext);
    const { simulateurState, simulateurDispatch } = useContext(SimulateurContext);
    const { utilsDispatch } = useContext(UtilsContext);
    const { layoutState, setLayoutState } = useContext(LayoutContext);

    const { idSimulation, identiteActionnaire: identite, projetCessionSociete: projet, operationsAvantCession: operations } = cessionHaut;
    let { conjoints, enfants } = identite;
    const { footerButtons, menuLevel } = layoutState;

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    // Données concernant les enfants
    useEffect(() => {
        if (identite.nombreEnfants > 0) {
            setCessionHaut((prevState) => {
                // Les valeurs précédentes
                const lastChilds = prevState.identiteActionnaire.enfants;
                const lastChildsPP = prevState.projetCessionSociete.enfantsFamiliauxPP;
                const lastChildsNP = prevState.projetCessionSociete.enfantsFamiliauxDP;
                const lastDonations = prevState.operationsAvantCession.donations;

                // Pour garder les informations qu'on peut garder au cas où le nombre d'enfants change.
                const newLength = identite.nombreEnfants;
                const lastLength = lastChilds.length;


                if (lastLength === newLength) return prevState;

                else if (lastLength < newLength) {
                    const diff = newLength - lastLength;
                    let enfs = [];
                    let enfsPP = [];
                    let enfsDP1 = [];
                    let enfsDP2 = [];
                    let donations1 = [];
                    let donations2 = [];

                    let enfantIndex = lastLength;

                    for (let i = 0; i < diff; i++) {
                        // Population des informations concernant les enfants PP.
                        let identifiant = enfantIndex + 1;

                        enfs[i] = {
                            id: identifiant,
                            age: "",
                            dateNaissance: "",
                            enfantCharge: "Non",
                            handicap: false,
                            parents: "Enfant commun",
                            prenom: `Enfant ${identifiant}`,
                            revenuAnnee: "",
                            situationFamiliale: "Personne seule",
                            dateApproximative: false
                        }
                        enfantIndex += 1;

                        enfsPP[i] = {
                            id: identifiant,
                            pourcentageDetention: "",
                            valeurTitres: "",
                            prixRevientFiscal: "",
                            regimeFiscal: "Non éligible",
                            apportTitres: ""
                        }

                        enfsDP1[i] = {
                            id: identifiant,
                            pourcentageDetention: "",
                            valeurTitres: "",
                            prixRevientFiscal: "",
                            regimeFiscal: "Non éligible",
                            apportTitres: 0,
                        };

                        enfsDP2[i] = {
                            id: identifiant,
                            pourcentageDetention: "",
                            valeurTitres: "",
                            prixRevientFiscal: "",
                            regimeFiscal: "Non éligible",
                            apportTitres: 0,
                        };

                        donations1[i] = {
                            id: identifiant,
                            valeurFiscale: "",
                            anneeDonation: new Date().getFullYear() - 1,
                            abattementFiscal: 0,
                            soldeValeurTitresPP: "",
                            soldeValeurTitresUSUF: "",
                            donationPP: "",
                            donationNP: "",
                            fraisDonation: "",
                            tauxMarginalImposition: 0
                        };

                        donations2[i] = {
                            id: identifiant,
                            valeurFiscale: "",
                            anneeDonation: new Date().getFullYear() - 1,
                            abattementFiscal: 0,
                            soldeValeurTitresPP: "",
                            soldeValeurTitresUSUF: "",
                            donationPP: "",
                            donationNP: "",
                            fraisDonation: "",
                            tauxMarginalImposition: 0
                        }
                    }

                    enfs.unshift(...lastChilds);
                    enfsPP.unshift(...lastChildsPP);
                    enfsDP1.unshift(...lastChildsNP[0].enfants);
                    enfsDP2.unshift(...lastChildsNP[1].enfants);
                    donations1.unshift(...lastDonations[0].enfants);
                    donations2.unshift(...lastDonations[1].enfants);

                    identite.enfants = enfs;
                    projet.enfantsFamiliauxPP = enfsPP;
                    projet.enfantsFamiliauxDP[0].enfants = enfsDP1;
                    projet.enfantsFamiliauxDP[1].enfants = enfsDP2;
                    operations.donations[0].enfants = donations1;
                    operations.donations[1].enfants = donations2;
                    return {
                        ...prevState,
                        identiteActionnaire: identite,
                        projetCessionSociete: projet,
                        operationsAvantCession: operations
                    };
                }
                else {
                    const diff = lastLength - newLength;
                    for (let i = diff; i > 0; i--) {
                        let tab = lastChilds;
                        let tabPP = lastChildsPP;
                        let tabNP1 = lastChildsNP[0].enfants;
                        let tabNP2 = lastChildsNP[1].enfants;
                        let tabDonations1 = lastDonations[0].enfants;
                        let tabDonations2 = lastDonations[1].enfants;

                        tab.pop();
                        tabPP.pop();
                        tabNP1.pop();
                        tabNP2.pop();
                        tabDonations1.pop();
                        tabDonations2.pop();

                        identite.enfants = tab;
                        projet.enfantsFamiliauxPP = tabPP;
                        projet.enfantsFamiliauxDP[0].enfants = tabNP1;
                        projet.enfantsFamiliauxDP[1].enfants = tabNP2;
                        operations.donations[0].enfants = tabDonations1;
                        operations.donations[1].enfants = tabDonations2;
                    }

                    return {
                        ...prevState,
                        identiteActionnaire: identite,
                        projetCessionSociete: projet,
                        operationsAvantCession: operations
                    };
                }
            });
        }
        else{
            // Au cas où le nombre des enfants  === 0
            identite.enfants = [];
            projet.enfantsFamiliauxPP = [];
            projet.enfantsFamiliauxDP[0].enfants = [];
            projet.enfantsFamiliauxDP[1].enfants = [];
            operations.donations[0].enfants = [];
            operations.donations[1].enfants = [];

            setCessionHaut((prevState) => {
                return {
                    ...prevState,
                    identiteActionnaire: identite,
                    projetCessionSociete: projet,
                    operationsAvantCession: operations
                }
            });

        }
    }, [identite.nombreEnfants, conjoints[0].marieOuPasce === "Oui"]);

    useEffect(() => {
        setLayoutState((prevState) => {
            footerButtons.cs = [
                {
                    type: 'ADD',
                    text: 'Enregistrer',
                    onClick: () => {
                        saveSimulationCession(
                            cessionHaut,
                            simulateurState,
                            () => { setLoading(true) },
                            () => { setLoading(false) },
                            (response) => {
                                const id = response.id;
                                if (id) {
                                    identite.idSimulationOnSave = id;
                                    projet.idSimulationOnSave = id;
                                    simulateurDispatch(updatePostItData({
                                      ...(simulateurState?.postItData || {}),
                                      simulationId: response.id,
                                      simulationType: 'identite-actionnaire'
                                    }))
                                }
                                utilsDispatch({ type: 'ADD_TOAST', payload: { type: 'success', title: 'OK', description: messageSuccesEnregistrementSimulation(simulateurState?.simulation?.identifiant_simulation) } })
                            },
                            () => { utilsDispatch({ type: 'ADD_TOAST', payload: { type: 'danger', title: 'ERREUR', description: messageErreurEnregistrementSimulation(simulateurState?.simulation?.identifiant_simulation) } }) },
                            idSimulation || identite.idSimulationOnSave
                        )
                    }
                },
                {
                    type: 'NEXT',
                    text: 'Suivant',
                    onClick: () => {
                        setLayoutState((prev) => {
                            menuLevel.cs = 2;

                            return {
                                ...prev,
                                menuLevel
                            };
                        })
                    }
                }
            ];

            return {
                ...prevState,
                footerButtons
            };
        });
    }, [cessionHaut, simulateurState]);

    const prenomConjoint1 = conjoints[0] &&
    ![null, undefined, ""].includes(conjoints[0].nomPersonnalise) ? conjoints[0].nomPersonnalise : 'Conjoint 1';

    const prenomConjoint2 = conjoints[1] &&
    ![null, undefined, ""].includes(conjoints[1].nomPersonnalise) ? conjoints[1].nomPersonnalise : 'Conjoint 2';

    const parentsDataList = [
        /*"Enfant commun",
        "Enfant du conjoint 1",
        "Enfant du conjoint 2",*/
        {
            value: "Enfant commun",
            label: "Enfant commun"
        },
        {
            value: "Enfant du conjoint 1",
            label: `Enfant ${ ["Conjoint 1"].includes(prenomConjoint1) ? 'du' : 'de' } ${prenomConjoint1}`
        },
        {
            value: `Enfant du conjoint 2`,
            label: `Enfant ${ ["Conjoint 2"].includes(prenomConjoint2) ? 'du' : 'de' } ${prenomConjoint2}`
        }
    ];

    // Quand les informations concernant les Conjoints changent de valeur.
    const handleConjointChange = (event, index, prop) => {
        const { value } = event.target;
        if (prop === 'marieOuPasce') identite.conjoints[index][prop] = event.target.value;//!(identite.conjoints[index][prop]);
        else if (prop === 'dateApproximative') identite.conjoints[index][prop] = event.target.checked;
        else identite.conjoints[index][prop] = value;
        setCessionHaut((prevState) => ({
            ...prevState,
            identiteActionnaire: identite
        }));
    };

    // Quand le nombre d'enfants change
    const handleNombreEnfantsChange = (event) => {
        const { value } = event.target;
        identite.nombreEnfants = parseInt(value, 10);
        setCessionHaut((prevState) => ({
            ...prevState,
            identiteActionnaire: identite
        }));
    };

    // Qaund les informations concernant les enfants changent
    const handleEnfantsChange = (event, index, prop) => {
        const { value } = event.target;
        if (prop === 'handicap' || prop === 'dateApproximative') identite.enfants[index][prop] = !(identite.enfants[index][prop]);
        else identite.enfants[index][prop] = value;

        if (prop === 'enfantCharge') {
            if (value !== "Non") {
                identite.enfants[index]['revenuAnnee'] = "";
                identite.enfants[index]['situationFamiliale'] = "Personne seule";
            }
        }

        setCessionHaut((prevState) => ({
            ...prevState,
            identiteActionnaire: identite,
            projetCessionSociete: projet
        }));
    };

    const onAgeChange = (value) => {
        let dateNaissance = moment();
        dateNaissance.subtract(value, 'years');
        return dateNaissance.format("DD/MM/YYYY");
    };

    const marginTopBetweenForm = 5;

    return (
        <>
        <div style={{ background: "white", paddingTop: 11, paddingBottom: 25, borderRadius: 13 }} className='structureBlock'>
            {loading && <Loading />}
            <div className={classes.guide}>
                Renseigner l’identité de l'actionnaire cédant, de son conjoint et leurs enfants
            </div>

            <div id="formulairePEA" className="formulaire" style={{ backgroundColor: "#F4F4F4", paddingTop: 10, paddingBottom: 4 }}>
                <CCol xl={12}>
                    <CForm action="/cession/haut/identiteActionnaire" method="post" className="col-lg-12" id="form_pea">
                        <div>
                            <div>
                                <CRow style={{ marginRight: -24, marginLeft: -20 }}>
                                    <CCol sm="12">
                                        <Conjoint
                                            number={1}
                                            name={conjoints[0].nomPersonnalise}
                                            dob={conjoints[0].dateNaissance}
                                            age={conjoints[0].age}
                                            dateApproximative={conjoints[0].dateApproximative}
                                            situation={conjoints[0].marieOuPasce}
                                            cessionRevenu={conjoints[0].revenuAnneeCession}
                                            onNameChange={(event) => handleConjointChange(event, 0, 'nomPersonnalise')}
                                            onDobChange={(event) => {
                                                handleConjointChange(event, 0, 'dateNaissance');
                                                event.target.value = calculAgeSelonDateNaissance(event.target.value);
                                                handleConjointChange(event, 0, 'age');
                                            }}
                                            onAgeChange={(event) => {
                                                handleConjointChange(event, 0, 'age');
                                                if (conjoints[0].dateApproximative) {
                                                    event.target.value = calculDateNaissanceSelonAge(event.target.value);
                                                    handleConjointChange(event, 0, 'dateNaissance');
                                                }
                                            }}
                                            onDateApproximativeChange={(event) => handleConjointChange(event, 0, 'dateApproximative')}
                                            onSituationChange={(event) => handleConjointChange(event, 0, 'marieOuPasce')}
                                            onCessionRevenuChange={(event) => handleConjointChange(event, 0, 'revenuAnneeCession')}
                                            marieOuPasce = {conjoints[0].marieOuPasce}
                                        />

                                        {
                                            enfants.length > 0 && (
                                                <>
                                                    <div className="breadcrumb" style={{ borderBottomStyle: "dashed" }}></div>
                                                    <div id="stepHeaderTitle">
                                                        <CFormGroup className="form-inline col-lg-12">
                                                            <CLabel htmlFor="enfant" id="labeldentiteAct4" className="label_title col-lg-4 col-md-6 col-sm-7 d-flex flex-direction-row align-items-center justify-content-start pl-0">Enfant.s</CLabel>
                                                            <span className="col-lg-2 col-md-5 col-sm-5"></span>
                                                        </CFormGroup>
                                                    </div>
                                                </>
                                            )
                                        }

                                        {
                                        enfants.length > 0 &&

                                            <div style={{ display: 'flex', flex: 1, flexDirection: 'row', marginTop: 10, fontSize: 13 }}>
                                                <div id="labeldentiteAct" className="col-lg-4 col-md-4 col-sm-4" style={{ display: 'flex', flexDirection: 'column', marginTop: -38 }}>
                                                    <CLabel style={{marginTop: marginTopBetweenForm, minHeight: 25 }} className="label_title d-flex flex-direction-row align-items-center mt-1">&nbsp;</CLabel>
                                                    <CLabel style={{marginTop: /*marginTopBetweenForm*/4, minHeight: 25, lineHeight: '25px' }}>Prénom</CLabel>
                                                    <CLabel style={{marginTop: marginTopBetweenForm, minHeight: 25, lineHeight: '25px' }}>Les parents ?</CLabel>
                                                    <CLabel style={{marginTop: /*marginTopBetweenForm*/2, minHeight: 25, lineHeight: '25px' }}>
                                                        Enfant fiscalement à charge ?
                                                    </CLabel>
                                                    <CLabel style={{marginTop: /*marginTopBetweenForm*/-1, minHeight: 25, paddingLeft: 10, lineHeight: '25px' }} className="text-muted" >
                                                        Revenu fiscal de référence
                                                    </CLabel>
                                                    <CLabel style={{marginTop: marginTopBetweenForm, minHeight: 25, verticalAlign: 'middle', paddingLeft: 10, lineHeight: '25px' }} className="text-muted" >
                                                        Situation familiale
                                                    </CLabel>
                                                    <CLabel style={{minHeight: 25, marginTop: /*marginTopBetweenForm*/1, lineHeight: '25px' }}>
                                                        L' enfant présente un handicap ?
                                                    </CLabel>
                                                </div>
                                                <div id="form_benificiaire" style={{ overflowX: 'auto', display: 'flex', flexDirection: 'column', flex: 1, paddingLeft: 1, marginTop: -42 }}>
                                                    <CLabel className="label_title d-flex flex-direction-row align-items-center mt-1">&nbsp;</CLabel>
                                                    <CFormGroup className="form-inline" style={{ display: 'flex', flexWrap: 'nowrap', flexDirection: 'row' }}>
                                                        {
                                                            enfants.map((data, i) => {
                                                                let prenom = ![null, undefined, ""].includes(data.prenom) ? data.prenom : `ENFANT ${i+1}`

                                                                return (
                                                                    <div key={i} className={`col-12 col-xxl-3 col-xl-3 col-lg-2 col-md-4 col-sm-4 px-0 ${i > 0 ? "ml-2" : ""} `} style={{ marginTop: "-34px" }}>
                                                                        <div style={{ borderBottom: "5px solid gray", fontWeight: 'bold',  color: "#393939", padding: 10, height: 30, marginBottom: 10, display: 'flex', justifyContent: 'center', alignItems: 'center' }} className="text-center col-lg-12" id="benificiaire_num">{prenom.toString().toUpperCase()}</div>
                                                                        <CFormGroup className="form-inline " style={{ justifyContent: 'center', paddingRight: 0 }}>

                                                                            <InputText
                                                                                id={`${formatToUniqueId("IdentieteActionnaire prenom")}-text-${i}`}
                                                                                value={data.prenom !== `Enfant ${i + 1}` ? data.prenom : ""}
                                                                                placeholder={`Enfant ${i + 1}`}
                                                                                className="col-lg-12 col-md-12 col-sm-12"
                                                                                onChange={(event) => handleEnfantsChange(event, i, 'prenom')}
                                                                                capitalize={true}
                                                                            />
                                                                        </CFormGroup>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </CFormGroup>
                                                    <CFormGroup className="form-inline" style={{ display: 'flex', flexWrap: 'nowrap', flexDirection: 'row' }}>
                                                        {
                                                            enfants.map((don, i) => {
                                                                return (
                                                                    <div key={i} className={`col-12 col-xxl-3 col-xl-3 col-lg-2 col-md-4 col-sm-4 px-0 ${i > 0 ? "ml-2" : ""} `} >
                                                                        <CFormGroup className="form-inline " style={{ justifyContent: 'center', paddingRight: 0 }}>

                                                                            <InputSelect
                                                                                id={`${formatToUniqueId("IdentiteActioinnaire parents")}-select-${i}`}
                                                                                custom
                                                                                value={don.parents}
                                                                                onChange={(event) => handleEnfantsChange(event, i, 'parents')}
                                                                                className="col-lg-12 col-md-12 col-sm-12"
                                                                            >
                                                                                {
                                                                                    parentsDataList.map((donnee, index) => {
                                                                                        if (index === 2) {
                                                                                            if (conjoints[0].marieOuPasce === "Oui")
                                                                                                return <option key={index} value={donnee.value} selected={don.parents === donnee.value} >{donnee.label}</option>
                                                                                        }
                                                                                        else return <option key={index} value={donnee.value} selected={don.parents === donnee.value} >{donnee.label}</option>
                                                                                    })
                                                                                }
                                                                            </InputSelect>
                                                                        </CFormGroup>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </CFormGroup>
                                                    <CFormGroup className="form-inline" style={{ display: 'flex', flexWrap: 'nowrap', flexDirection: 'row' }}>
                                                        {
                                                            enfants.map((donnee, i) => {
                                                                return (
                                                                    <div key={i} className={`col-12 col-xxl-3 col-xl-3 col-lg-2 col-md-4 col-sm-4 px-0 ${i > 0 ? "ml-2" : ""} `} >
                                                                        <CFormGroup className="form-inline " style={{ justifyContent: 'center', paddingRight: 0 }}>
                                                                            <div className="col-lg-12 col-md-12 col-sm-12" style={{ padding: 0, textAlign: "right", marginRight: i === 0 && 1 }} >
                                                                                <CFormGroup className="form-inline col-lg-2" style={{ display: "flex", justifyContent: "end", padding: 0 }}>

                                                                                    <ToggleSwitch
                                                                                        isToggled={donnee.enfantCharge === 'Oui'}
                                                                                        onToggleChange={ () => {
                                                                                        let value = '';
                                                                                        let currentEnfantCharge = donnee.enfantCharge;

                                                                                        if(currentEnfantCharge === 'Oui') value = 'Non';
                                                                                        else value = 'Oui'

                                                                                        identite.enfants[i].enfantCharge = value;

                                                                                        setCessionHaut((prevState) => {
                                                                                            return {
                                                                                                ...prevState,
                                                                                                identiteActionnaire: identite
                                                                                            }
                                                                                        });
                                                                                    }}
                                                                                />
                                                                            </CFormGroup>
                                                                        </div>
                                                                        </CFormGroup>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </CFormGroup>
                                                    <CFormGroup className="form-inline" style={{ display: 'flex'/*, marginTop: 10, marginBottom: 5*/, flexWrap: 'nowrap', flexDirection: 'row' }}>
                                                        {
                                                            enfants.map((data, i) => {
                                                                return (
                                                                    <div key={i} style={{ visibility: data.enfantCharge === "Non" ? 'visible' : 'hidden' }} className={`col-12 col-xxl-3 col-xl-3 col-lg-2 col-md-4 col-sm-4 px-0 ${i > 0 ? "ml-2" : ""} `} >
                                                                        <CFormGroup className="form-inline " style={{ justifyContent: 'center', paddingRight: 0 }}>

                                                                            <InputCurrency
                                                                                id={`${formatToUniqueId("IdentiteActionnaire")}-${i}`}
                                                                                value={data.enfantCharge === "Oui" ? 0 : data.revenuAnnee}
                                                                                onChange={(event) => handleEnfantsChange(event, i, 'revenuAnnee')}
                                                                                className="col-lg-12 col-md-12 col-sm-12"
                                                                            />
                                                                        </CFormGroup>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </CFormGroup>
                                                    <CFormGroup className="form-inline" style={{ display: 'flex', flexWrap: 'nowrap', flexDirection: 'row' }}>
                                                        {
                                                            enfants.map((donnee, i) => {
                                                                let dataToDisplay = ["Personne seule", "Marié ou pacsé"];
                                                                return (
                                                                    <div key={i} style={{ visibility: donnee.enfantCharge === "Non" ? 'visible' : 'hidden' }} className={`col-12 col-xxl-3 col-xl-3 col-lg-2 col-md-4 col-sm-4 px-0 ${i > 0 ? "ml-2" : ""} `} >
                                                                        <CFormGroup className="form-inline " style={{ justifyContent: 'center', paddingRight: 0 }}>
                                                                            <InputSelect id={`${formatToUniqueId("IdentiteActioinnaire enfants")}-select-${i}`} custom readOnly={enfants[i].enfantCharge === "Non"} value={donnee.enfantCharge === "Oui" ? "Personne seule" : donnee.situationFamiliale} onChange={(event) => handleEnfantsChange(event, i, 'situationFamiliale')} className="col-lg-12 col-md-12 col-sm-12">
                                                                                {
                                                                                    dataToDisplay.map((data, index) => {
                                                                                        return (
                                                                                            <option key={index} selected={donnee.situationFamiliale === data}>{data}</option>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </InputSelect>
                                                                        </CFormGroup>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </CFormGroup>
                                                    <CFormGroup className="form-inline" style={{ display: 'flex', flexWrap: 'nowrap', flexDirection: 'row' }}>
                                                        {
                                                            enfants.map((donnee, i) => {
                                                                return (
                                                                    <div key={i} className={`col-12 col-xxl-3 col-xl-3 col-lg-2 col-md-4 col-sm-4 px-0 ${i > 0 ? "ml-2" : ""} `} >
                                                                        <CFormGroup className="form-inline " style={{ justifyContent: 'center', paddingRight: 0 }}>
                                                                            <div className="col-lg-12 col-md-12 col-sm-12" style={{ padding: 0, textAlign: "right", marginRight: i === 0 && 1 }} >
                                                                                <CFormGroup className="form-inline col-lg-2" style={{ display: "flex", justifyContent: "end", padding: 0 }}>

                                                                                    <ToggleSwitch
                                                                                        isToggled={donnee.handicap}
                                                                                        onToggleChange={ () => {
                                                                                            let currentHandicap = donnee.handicap;

                                                                                            identite.enfants[i].handicap = !currentHandicap;

                                                                                            setCessionHaut((prevState) => {
                                                                                                return {
                                                                                                    ...prevState,
                                                                                                    identiteActionnaire: identite
                                                                                                }
                                                                                            });
                                                                                        }}
                                                                                    />
                                                                                </CFormGroup>
                                                                            </div>
                                                                        </CFormGroup>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </CFormGroup>
                                                </div>
                                            </div>
                                        }
                                    </CCol>
                                </CRow>
                            </div>
                        </div>
                    </CForm>
                </CCol>
            </div>
        </div>
        {/*<div style={{ marginTop: 8, marginBottom: 8, background: "white", paddingTop: 11, paddingBottom: 25, borderRadius: 13 }} className='structureBlock'>
            <div className={classes.guide}>
                CHOISIR VOTRE AFFICHAGE DANS LE TABLEAU DES RÉSULTATS
            </div>

            <div id="formulairePEA" className="formulaire" style={{ backgroundColor: "#F4F4F4", paddingTop: 10, paddingBottom: 4 }}>
                <CCol xl={12}>
                    <CForm action="/cession/haut/identiteActionnaire" method="post" className="col-lg-12" id="form_pea">
                        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 8 }} >
                            <div style={{ marginRight: 15  }} >
                                <ToggleSwitch
                                    isToggled={identite.affichageNomPersonnalise}
                                    onToggleChange={ () => {
                                        const currentValue = identite.affichageNomPersonnalise;

                                        identite.affichageNomPersonnalise = !currentValue;

                                        setCessionHaut((prevState) => {
                                            return {
                                                ...prevState,
                                                identiteActionnaire: identite
                                            }
                                        });
                                    } }
                                />
                            </div>
                            <div style={{ fontWeight: 'bold', marginTop:4, fontSize: 13 }} >Activer pour afficher les noms personnalisés</div>
                        </div>
                    </CForm>
                </CCol>
            </div>
        </div>*/}
        </>
    );
};


export default IdentiteActionnaire;
