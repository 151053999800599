import { restApiUri } from "../../../../config";

export const saveSimulateur = async (simulateurState, id, api) => {
    const url = `${restApiUri}${api}${id ? id : ""}`;
    const query = await fetch(url, {
        method: id ? 'PUT' : 'POST',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${localStorage.getItem("token")}`
        },
        body: JSON.stringify(simulateurState),
        cache: 'no-cache'
    })

    return query.json();
}

export const getSimulateur = async (api, id) => {
    const url = `${restApiUri}${api}${id}`;
    const query = await fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${localStorage.getItem("token")}`
        },
        cache: 'no-cache'
    })

    return query.json();
}

export const deleteSimulateur = async (api, id) => {
    const query = await fetch(`${restApiUri}${api}${id}`, {
        method: "DELETE",
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        }
    });

    return query.json();
}