import React, { useContext } from 'react'
import {
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarNavDropdown,
  CSidebarNavItem,
  CImg
} from '@coreui/react'

import CIcon from '@coreui/icons-react'
import { SimulateurContext } from '../store/context/simulateur'

// sidebar nav config
import logo from '../assets/icons/svg/logoAvecS.svg'
import logoMinifie from '../assets/icons/svg/logoS.svg'
import { UtilsContext } from '../store/context/utils'
import { toggleSidebar } from '../store/actions/utils/sidebar'
import { withRouter } from 'react-router'
import { NOM_BOX, SIMU } from '../constant'
import { LayoutContext } from '../../src/App2';
import { setSimulation, updatePostItData, updateSimulation } from '../store/actions/simulateur'
import { DataControlContext } from '../store/context/dataControl'
import { useHistory } from "react-router-dom";
import { successionDefaultData } from '../store/context/defaultData/succession'
import { ifiDefaultData } from '../store/context/defaultData/boxPatrimoineFiscalite/ifi'
import { empruntDefaultData } from '../store/context/defaultData/boxImmobiliere/emprunt'
import { fiscaliteRevenusDefaultData } from '../store/context/defaultData/boxPatrimoineFiscalite/fiscaliteRevenus'
import { lienBilanPatrimonial, lienCessionSociete, lienDonation, lienDonationSuccession, lienEmprunt, lienFiscaliteRevenus, lienIFI, lienImmobiliere, lienPatrimoineFiscalite, lienPlusValueImmobiliere, lienSuccession, lienUsufruitEconomique, lienUsufruitNuePropriete } from '../routes'
import { completeDate } from '../utils'
import { now } from '../extra/all_ppt_render'
import { configurationExportDefaultData } from '../store/context/defaultData/autre'

const TheSidebar = (props) => {
  const history = useHistory();

  const { simulateurDispatch } = useContext(SimulateurContext);
  const { utilsState, utilsDispatch } = useContext(UtilsContext)
  const { layoutState, setLayoutState } = useContext(LayoutContext);
  const { menuLevel } = layoutState;
  const c1 = '#17AACE'
  const c2 = '#1D51A1'
  const {
    headerMenuIndex, setHeaderMenuIndex,
    successionState, setSuccessionState,
    fiscaliteState, setFiscaliteState,
    empruntState, setEmpruntState,
    usufruitEconomiqueState, setUsufruitEconomiqueState,
    cessionControlState, setCessionControlState,
    ifiState, setIfiState,
    configurationExportState, setConfigurationExportState
  } = useContext(DataControlContext);
  const { bilanPatrimonial, simulateurState: dataSimulateur } = layoutState;

  const handleClickBoxImmobilier = () => {
    history.push(lienImmobiliere);
  }

  const handleClickBilan = () => {
    history.push(lienPatrimoineFiscalite);
  }

  const handleClickDonation = () => {
    history.push(lienDonationSuccession);
  }

  const handleClickInvestiFi = () => {
    history.push("/accueilSCP");
  }

  const changeStateSuccession = () => {
    if (successionState.activeSimu) {
      successionState.activeSimu = false;
      setSuccessionState((prev) => { return { ...prev, ...successionState } });
    }
  }

  const changeStateFiscalite = () => {
    if (fiscaliteState.activeSimu) {
      fiscaliteState.activeSimu = false;
      setFiscaliteState((prev) => { return { ...prev, ...fiscaliteState } });
    }
  }

  const changeStateBP = () => {
    // if(bilanPatrimonial.activeSimu){
    bilanPatrimonial.activeSimu = false;
    const reinitBP = {
      indexTab: 0,
      idBilan: undefined,
      activeSimu: false,
      affichage: 1,
      situationDu: "",
      activeForm: false,
      patrimoineGlobal: {
        avecImportClient: 0,
        situationFamiliale: 0,
        repartitionBrutNet: 0,
      },
      professionnel: {
        actifsProfessionnels: []
      },
      immobilier: {
        residencesPrincipales: [],
        residencePrincipaleCollapse: [],
        residenceSecondaires: [],
        immobilierLocatif: [],
        biensRurauxEtForestiers: [],
        monumentsHistoriques: [],
        scpi: [],
        societesImmobiliers: [],
      },
      financier: {
        comptesEpargnesLiquidites: [],
        comptesTitres: [],
        peaPmes: [],
        perins: [],
        nonCotes: [],
        capis: [],
        avs: [],
        assuranceDeces: [],
        autresActifsFinanciers: [],
        passifs: []

      },
      autres: {
        actifs: []
      }
    }
    // layoutState.bilanPatrimonial = reinitBP;
    setLayoutState((prev) => { return { ...prev, bilanPatrimonial: reinitBP } });
    // }
  }

  const changeEmprunState = () => {
    setEmpruntState(empruntDefaultData);
    reinitialisationInformationSimulation();
  }

  const changeUsufEcoState = () => {
    usufruitEconomiqueState.activeSimu = false;
    setUsufruitEconomiqueState((prev) => { return { ...prev, ...usufruitEconomiqueState } })
    reinitialisationInformationSimulation();
  }

  const changeCessionState = () => {
    cessionControlState.activeSimu = false;
    setCessionControlState((prev) => {
      const data = { ...prev, ...cessionControlState }
      return data
    });
  }

  const changeIfiState = () => {
    setIfiState(ifiDefaultData);
    simulateurDispatch(updateSimulation({
      identifiant_simulation: 'brouillon',
      clientId: null,
      commentaire: ""
    }));
  }

  const changeStatusCurrSimu = (simu) => {
    switch (simu) {
      case SIMU.SUCC:
        changeStateFiscalite();
        changeStateBP();
        changeEmprunState();
        changeUsufEcoState();
        changeCessionState();
        // changeIfiState();
        break;
      case SIMU.FISC:
        changeStateSuccession();
        changeStateBP();
        changeEmprunState();
        changeUsufEcoState();
        changeCessionState();
        // changeIfiState();
        break;
      case SIMU.BILP:
        changeStateFiscalite();
        changeStateSuccession();
        changeEmprunState();
        changeUsufEcoState();
        changeCessionState();
        // changeIfiState();
        break;
      case SIMU.EMP:
        changeStateFiscalite();
        changeStateSuccession();
        changeStateBP();
        changeUsufEcoState();
        changeCessionState();
        // changeIfiState();
        break;
      case SIMU.USUF_ECO:
        changeEmprunState();
        changeStateFiscalite();
        changeStateSuccession();
        changeStateBP();
        changeCessionState();
        // changeIfiState();
        break;
      case SIMU.CESS:
        changeEmprunState();
        changeStateFiscalite();
        changeStateSuccession();
        changeStateBP();
        changeUsufEcoState();
        // changeIfiState();
        break;
      case SIMU.IFI:
        changeStateFiscalite();
        changeStateSuccession();
        changeStateBP();
        changeEmprunState();
        changeUsufEcoState();
        changeCessionState();
      default:
        changeStateFiscalite();
        changeStateSuccession();
        changeStateBP();
        changeEmprunState();
        changeUsufEcoState();
        changeCessionState();
        // changeIfiState();
        break;
    }
  }

  const setToggleSidebar = () => {
    const val = [true, 'responsive'].includes(utilsState.sidebarShow) ? false : 'responsive'
    utilsDispatch(toggleSidebar(val))
  }

  const reinitialisationInformationSimulation = () => {

    console.log("reinitialise");

    simulateurDispatch(updateSimulation({
      identifiant_simulation: "brouillon",
      clientId: null,
      commentaire: "",
      idClient: undefined,
      nomClient: undefined,
      versionOriginale: undefined,
      versions: undefined
    }));

    // Réinitialisation des éléments de contexte
    simulateurDispatch(updatePostItData({
      displayBlocPostIt: false,
      currentPostItId: null,
      hasPosted: false,
      state: false,
      simulationType: '',
      requestView: false,
      displayPostitIdNull: false,
      simulationId: null,
      currentCommentValue: '',
      elements: [],
      switchOn: false,
      changingTab: '',
      currentPin: {
        id:  1,
        idPostit: '',
        idSimulation: '',
        date: '',
        commentaire: '',
        isSet: false,
        userId: ''
      }
    }))
  };

  const menus = JSON.parse(localStorage.getItem('user'))?.menus;

  // Quand on est dans le simulateur Cession de société, cette fonction sert à positionner toujours l'utilisateur dans la première section
  const setToFirstSection = () => {
    // Réinitialisation des informations de simulation au cas où c'est la cession de société ou la succession
    reinitialisationInformationSimulation();

    setLayoutState((prevState) => {
      menuLevel.cs = 1;

      return {
        ...prevState,
        menuLevel
      }
    });
  };

  const conditionActiveCS = () => {
    let condition = false;
    if (props && props.location && props.location.pathname) {
      condition = props.location.pathname.includes('/accueilcession') ||
        props.location.pathname.includes('/identite_actionnaire') ||
        props.location.pathname.includes('/projet_cession') ||
        props.location.pathname.includes('/operation_avant_cession') ||
        props.location.pathname.includes('/resultat_synthetique') ||
        (props.location.pathname.includes('/resultat_detaille') && props.location.pathname.includes('/cession')) ||
        props.location.pathname.includes('/cession/fiche/notice') ||
        props.location.pathname.includes('/cession/fiche/aide')
    }

    return condition;
  }

  // Valeur par défaut pour la Comparaison de structure
  const comparaisonStructuresToDefaultData = () => {
    reinitialisationInformationSimulation();
    simulateurDispatch(setSimulation(null))
    simulateurDispatch({ type: 'UPDATE_COMP_STEP', payload: 1 });
    simulateurDispatch({ type: 'COMP_SSI' });
  }

  // Valeur par défault pour la succession.
  const successionToDefaultData = () => {
    const menu = JSON.parse(JSON.stringify(headerMenuIndex));
    menu.succession = 0;

    reinitialisationInformationSimulation();
    setHeaderMenuIndex(menu);
    setSuccessionState(successionDefaultData);
    setConfigurationExportState(configurationExportDefaultData);
  }

  // Valeur par défaut pour le Bilan patrimonial
  const bilanPatrimonialToDefaultData = () => {
    reinitialisationInformationSimulation();
    setLayoutState((prevState) => {
      return {
        ...prevState,
        bilanPatrimonial: {
          indexTab: 0,
          idBilan: undefined,
          activeSimu: false,
          affichage: 1,
          situationDu: completeDate(now),
          activeForm: false,
          patrimoineGlobal: {
            avecImportClient: 0,
            situationFamiliale: 0,
            repartitionBrutNet: 0,
            versionFicheClient: null
          },
          professionnel: {
            actifsProfessionnels: []
          },
          immobilier: {
            residencesPrincipales: [],
            residencePrincipaleCollapse: [],
            residenceSecondaires: [],
            immobilierLocatif: [],
            biensRurauxEtForestiers: [],
            monumentsHistoriques: [],
            scpi: [],
            societesImmobiliers: [],
          },
          financier: {
            comptesEpargnesLiquidites: [],
            comptesTitres: [],
            perins: [],
            peaPmes: [],
            nonCotes: [],
            capis: [],
            avs: [],
            assuranceDeces: [],
            autresActifsFinanciers: [],
            passifs: []

          },
          autres: {
            actifs: []
          }
        }
      }
    })
  }

  // Valeur par défaut pour la fiscalité des revenus
  const fiscaliteRevenusToDefaultData = () => {
    reinitialisationInformationSimulation();
    setFiscaliteState(fiscaliteRevenusDefaultData);
  }

  // Valeur par défaut pour IFI
  const ifiToDefaultData = () => {
    reinitialisationInformationSimulation();
    setIfiState({
      withCalcul: true,
      idSimulation: null,
      activeSimu: false,
      infoSimulation: {
        identifiant_simulation: "brouillon",
        clientId: null,
        commentaire: ""
      },
      "indexTab": 0,
      "anneeFiscale": new Date().getFullYear(),
      "affichageMessage": 0,
      "form1": {
        "configuration": {
          "client": null,
          "idBilan": null,
          "idFiscalite": null,
          "bilan_patrimonial": null,
          "fiscalite": null,
          "date_importation_bilan_patrimonial": "",
          "date_importation_fiscalite": "",
          "import_bilan_patrimonial": 0,
          "import_fiscalite_revenus": 0
        }
      },
      "form2": {
        "dejaDansForm2": 0,
        "souhaitez_vous_desactiver_le_plafonnement_des_dettes": 0,
        "residence_principale": null,
        "autre_actif_immo": [],
        "rp": {
          "intitule": "",
          "actif": 0,
          "passif": 0,
          "avec": 0
        },
        "rp_added": null,
        "autresImmos": [],
        "autresImmosAdded": [],
        "biens_ruraux_ou_forestiers": {
          "bois_forets_parts": [],
          "biens_ruraux_loues_long_terme": [],
          "parts_GFA_GAF": []
        },
        "dettes_fiscales_autres_dettes_deductibles": []
      },
      "form2Copie": {
        "dejaDansForm2": 0,
        "souhaitez_vous_desactiver_le_plafonnement_des_dettes": 0,
        "residence_principale": null,
        "autre_actif_immo": [],
        "rp": {
          "intitule": "",
          "actif": 0,
          "passif": 0,
          "avec": 0
        },
        "rp_added": null,
        "autresImmos": [],
        "autresImmosAdded": [],
        "biens_ruraux_ou_forestiers": {
          "bois_forets_parts": [],
          "biens_ruraux_loues_long_terme": [],
          "parts_GFA_GAF": []
        },
        "dettes_fiscales_autres_dettes_deductibles": []
      },
      "form3": {
        "dons_aux_organismes_interets_general": 0,
        "activer_calcul_plafonnement": 0,
        "revenus_pris_en_compte_pour_plafonnement": 0,
        "impots_sur_les_revenus_retenus_pour_plafonnement": 0,
        "revenus_pris_en_compte_pour_plafonnement_avec_import_donnees": 0,
        "impots_sur_les_revenus_retenus_pour_plafonnement_avec_import_donnees": 0,
        "dejaDansForm3": 0,
        "dejaDansForm3ActivationCalcul": 0
      },
      "form5": {
        "revenus_et_produits": {
          "traitements_et_salaires": {
            "montant_brut": 0,
            "frais_professionnels": 0
          },
          "pension_retraites_rentes": {
            "montant_net": 0
          },
          "rentes_viageres_a_titre_onereux": {
            "montant_net": 0
          },
          "renumeration_des_gerants_et_associes": {
            "montant_brut": 0,
            "frais_professionnels": 0
          },
          "benefices_agricoles": {
            "montant_net": 0
          },
          "Benefices_industriels_et_commerciaux": {
            "montant_net": 0
          },
          "Benefices_non_commerciaux": {
            "montant_net": 0
          },
          "revenus_fonciers": {
            "montant_net": 0
          },
          "revenus_de_capitaux_mobiliers": {
            "montant_brut": 0,
            "frais_professionnels": 0
          },
          "plus_values_y_compris_celles_exonerees_IR": {
            "montant_net": 0
          },
          "produits_soumis_un_prelevement_liberatoire_IR": {
            "montant_net": 0
          },
          "deficit_categoriel": {
            "montant_net": 0
          },
          "revenus_exoneres_IR_et_autres_revenus": {
            "revenus_de_placements_exoneres_IR": {
              "montant_net": 0
            },
            "plus_values_immobilieres_exonerees_IR": {
              "montant_net": 0
            },
            "participation_des_salaries": {
              "montant_net": 0
            },
            "abondement_de_entreprise_sur_un_PEE": {
              "montant_net": 0
            },
            "autres_revenus": {
              "montant_net": 0
            },
            "revenus_percus_a_etranger": {
              "montant_brut": 0,
              "frais_professionnels": 0
            }
          }
        },
        "impot_sur_les_revenus": {
          "ir": {
            "proportionnel": {
              "montant_net": 0
            },
            "montant_net": 0
          },
          "ps_a_la_source_revenus_activite": {
            "montant_net": 0
          },
          "ps_a_la_source_revenus_capitaux": {
            "montant_net": 0
          },
          "ps_par_voie_role": {
            "montant_net": 0
          },
          "contribution_exceptionnelle_sur_hauts_revenus": {
            "montant_net": 0
          },
          "impots_taxes_sur_plus_value_immo": {
            "montant_net": 0
          },
          "taxe_sur_cession_terrains_nus": {
            "montant_net": 0
          },
          "taxe_sur_metaux_precieux": {
            "montant_net": 0
          },
          "contribution_saliare_sur_gain_levee": {
            "montant_net": 0
          },
          "contribution_sociale_liberation": {
            "montant_net": 0
          },
          "contribution_sur_retraites_chapeaux": {
            "montant_net": 0
          }
        },
        "import_data": 0
      },
      "form5AvecImport": {
        "revenus_et_produits": {
          "traitements_et_salaires": {
            "montant_brut": 0,
            "frais_professionnels": 0
          },
          "pension_retraites_rentes": {
            "montant_net": 0
          },
          "benefices_agricoles": {
            "montant_net": 0
          },
          "Benefices_industriels_et_commerciaux": {
            "montant_net": 0
          },
          "Benefices_non_commerciaux": {
            "montant_net": 0
          },
          "revenus_fonciers": {
            "montant_net": 0
          },
          "revenus_de_capitaux_mobiliers": {
            "montant_brut": 0,
            "frais_professionnels": 0
          },
          "plus_values_y_compris_celles_exonerees_IR": {
            "montant_net": 0
          },
          "produits_soumis_un_prelevement_liberatoire_IR": {
            "montant_net": 0
          },
          "deficit_categoriel": {
            "montant_net": 0
          },
          "revenus_exoneres_IR_et_autres_revenus": {
            "plus_values_immobilieres_exonerees_IR": {
              "montant_net": 0
            },
            "autres_revenus": {
              "montant_net": 0
            }
          }
        },
        "impot_sur_les_revenus": {
          "ir": {
            "proportionnel": {
              "montant_net": 0
            },
            "montant_net": 0
          },
          "ps_a_la_source_revenus_capitaux": {
            "montant_net": 0
          },
          "ps_par_voie_role": {
            "montant_net": 0
          },
          "contribution_exceptionnelle_sur_hauts_revenus": {
            "montant_net": 0
          }
        }
      },
      "form5Copie": {
        "revenus_et_produits": {
          "traitements_et_salaires": {
            "montant_brut": 0,
            "frais_professionnels": 0
          },
          "pension_retraites_rentes": {
            "montant_net": 0
          },
          "benefices_agricoles": {
            "montant_net": 0
          },
          "Benefices_industriels_et_commerciaux": {
            "montant_net": 0
          },
          "Benefices_non_commerciaux": {
            "montant_net": 0
          },
          "revenus_fonciers": {
            "montant_net": 0
          },
          "revenus_de_capitaux_mobiliers": {
            "montant_brut": 0,
            "frais_professionnels": 0
          },
          "plus_values_y_compris_celles_exonerees_IR": {
            "montant_net": 0
          },
          "produits_soumis_un_prelevement_liberatoire_IR": {
            "montant_net": 0
          },
          "deficit_categoriel": {
            "montant_net": 0
          },
          "revenus_exoneres_IR_et_autres_revenus": {
            "plus_values_immobilieres_exonerees_IR": {
              "montant_net": 0
            },
            "autres_revenus": {
              "montant_net": 0
            }
          }
        },
        "impot_sur_les_revenus": {
          "ir": {
            "proportionnel": {
              "montant_net": 0
            },
            "montant_net": 0
          },
          "ps_a_la_source_revenus_capitaux": {
            "montant_net": 0
          },
          "ps_par_voie_role": {
            "montant_net": 0
          },
          "contribution_exceptionnelle_sur_hauts_revenus": {
            "montant_net": 0
          }
        }
      },
      "form6": {
        "residence_principale": {
          "intitule": "",
          "emprunt_bien_personnel_conjoint1": {
            "table_capital": [],
            "table_passif": []
          },
          "emprunt_bien_commun_conjoint": {
            "table_capital": [],
            "table_passif": []
          },
          "emprunt_bien_personnel_conjoint2": {
            "table_capital": [],
            "table_passif": []
          },
          "valeur_brute_imposable": {
            "valeur_des_droits_en_PP_et_USUF": 0,
            "abattement_residence_principale": 0
          },
          "passif_deductible": {
            "capital_restant_du_sur_PP_et_USUF": 0,
            "passif_theorique_IFI": 0
          }
        },
        "autres_actifs_immo": []
      },
      "form6Copie": {
        "residence_principale": {
          "intitule": "",
          "emprunt_bien_personnel_conjoint1": {
            "table_capital": [],
            "table_passif": []
          },
          "emprunt_bien_commun_conjoint": {
            "table_capital": [],
            "table_passif": []
          },
          "emprunt_bien_personnel_conjoint2": {
            "table_capital": [],
            "table_passif": []
          },
          "valeur_brute_imposable": {
            "valeur_des_droits_en_PP_et_USUF": 0,
            "abattement_residence_principale": 0
          },
          "passif_deductible": {
            "capital_restant_du_sur_PP_et_USUF": 0,
            "passif_theorique_IFI": 0
          }
        },
        "autres_actifs_immo": []
      }
    });
  }

  return (
    <CSidebar
      minimize={!utilsState.sidebarShow}

      className="shadow"
      onShowChange={(val) => utilsDispatch(toggleSidebar(val))}
      style={{ background: `linear-gradient(180deg, ${c1}, ${c2})` }}
    >
      <CSidebarBrand style={{ backgroundColor: 'white' }} to="/" className="d-md-down-none">
        {
          utilsState.sidebarShow &&
          <CImg
            src={logo}
            name="logo-negative"
            style={{ width: '100%', paddingTop: 5, paddingBottom: 5, objectFit: 'contain', height: !utilsState.sidebarShow ? 55 : 55 }}
          />
        }
        {
          !utilsState.sidebarShow &&
          <CImg
            src={logoMinifie}
            name="logo-negative"
            style={{ width: '100%', paddingTop: 5, paddingBottom: 5, objectFit: 'contain', height: !utilsState.sidebarShow ? 55 : 55 /*104*/ }}
          />
        }
      </CSidebarBrand>

      <CSidebarNav style={{ paddingTop: 6.5 }}>
        <CIcon
          onClick={setToggleSidebar}
          style={{ transform: 'rotate(90deg)', cursor: 'pointer', margin: 10, marginLeft: 12, marginBottom: 23.5, padding: 2, width: 30, height: 30, color: 'white' }}
          name="cil-menu"
        />
        {
          menus?.map((data, index) => {
            // BOX Financière
            if (parseInt(data.ordre, 10) === 3 && data.nom === NOM_BOX.FI)
              return (
                <CSidebarNavDropdown
                  key={index + '-sidebar'}
                  className={`${[/\/simulateurs*/, /\/comparaisons*/, /\/accueil-proposition-financiere*/, /\/proposition-financiere*/, /\/accueilSCP*/].some(reg => reg.test(props.location ? props.location.pathname : '')) ? 'active' : 'titre-box'}`}
                  name='BOX Financière'
                  icon='cil-chart-line'
                  onClick={() => handleClickInvestiFi()}
                >
                  <CSidebarNavItem onClick={(e) => { e.stopPropagation(); changeStatusCurrSimu(SIMU.BOX_FI) }} className={`${props.location && props.location.pathname.includes('/simulateurs') ? 'active' : 'simu'}`} name='Simulateurs' to='/simulateurs' />
                  <CSidebarNavItem onClick={(e) => { e.stopPropagation(); changeStatusCurrSimu(SIMU.BOX_FI); comparaisonStructuresToDefaultData() }} className={`${props.location && props.location.pathname.includes('/comparaisons') ? 'active' : 'simu'}`} name='Comparaison de structures' to='/comparaisons/formulaire' />
                  <CSidebarNavItem onClick={(e) => { e.stopPropagation(); changeStatusCurrSimu(SIMU.BOX_FI) }} className={`${props.location && props.location.pathname.includes('/accueil-proposition-financiere') ? 'active' : 'simu'}`} name='Proposition financière' to='/proposition-financiere' />
                </CSidebarNavDropdown>
              )

            // BOX Cession de société
            if (parseInt(data.ordre, 10) === 5 && data.nom === NOM_BOX.CESSION)
              return (
                <CSidebarNavItem
                  key={index + '-sidebar'}
                  onClick={() => { setToFirstSection() }}
                  className={`${[/\/cession-societe*/].some(reg => reg.test(props.location ? props.location.pathname : '')) ? 'active' : 'simu-alternative'}`}
                  name='BOX Cession de société'
                  icon='cil-industry'
                  to={lienCessionSociete}
                />
              )

            // BOX Donation et succession
            if (parseInt(data.ordre, 10) === 2 && data.nom === NOM_BOX.DONATION)
              return (
                <CSidebarNavDropdown key={index + '-sidebar'} className={`${[/\/usufruit-nue-propriete*/, /\/donation*/, /\/succession*/, /\/box-donation-succession*/].some(reg => reg.test(props.location ? props.location.pathname : '')) ? 'active' : ''}`} name='BOX Donation et Succession' icon='donation' onClick={() => handleClickDonation()}>
                  <CSidebarNavItem onClick={(e) => { e.stopPropagation(); changeStatusCurrSimu(SIMU.DON) }} className={`${props.location && props.location.pathname.includes('/usufruit-nue-propriete') ? 'active' : 'simu'}`} name='Usufruit et nue-propriété' to={lienUsufruitNuePropriete} />
                  <CSidebarNavItem onClick={(e) => { e.stopPropagation(); changeStatusCurrSimu(SIMU.DON) }} className={`${props.location && props.location.pathname.includes('/donation') ? 'active' : 'simu'}`} name='Donation' to={lienDonation} />
                  <CSidebarNavItem onClick={(e) => { e.stopPropagation(); successionToDefaultData() }} className={`${props.location && props.location.pathname.includes('/succession') ? 'active' : 'simu'}`} name='Succession' to={lienSuccession} />
                </CSidebarNavDropdown>
              )

            // BOX Patrimoine et Fiscalité
            if (parseInt(data.ordre, 10) === 1 && data.nom === NOM_BOX.BILAN_PAT)
              return (
                <CSidebarNavDropdown key={index + '-sidebar'} className={`${[/\/bilan*/, /\/fiscalite-revenu*/, /\/ifi*/, /\/accueilPatrimonial&fiscalite*/].some(reg => reg.test(props.location ? props.location.pathname : '')) ? 'active' : ''}`} name='BOX Patrimoine et Fiscalité' icon="cil-sync" onClick={() => handleClickBilan()}>
                  <CSidebarNavItem onClick={(e) => { e.stopPropagation(); changeStateBP(); bilanPatrimonialToDefaultData() }} className={`${props.location && props.location.pathname.includes('/bilan') ? 'active' : 'simu'}`} name='Bilan patrimonial' to={lienBilanPatrimonial} />
                  <CSidebarNavItem onClick={(e) => { e.stopPropagation(); fiscaliteRevenusToDefaultData() }} className={`${props.location && props.location.pathname.includes('/fiscalite-revenu') ? 'active' : 'simu'}`} name='Fiscalité des revenus' to={lienFiscaliteRevenus} />
                  <CSidebarNavItem onClick={(e) => { e.stopPropagation(); ifiToDefaultData() }} className={`${props.location && props.location.pathname.includes('/ifi') ? 'active' : 'simu'}`} name='IFI' to={lienIFI} />
                </CSidebarNavDropdown>
              )

            // BOX Immobilière
            if (parseInt(data.ordre, 10) === 4 && data.nom == NOM_BOX.IMMO)
              return (
                <CSidebarNavDropdown key={index + '-sidebar'} className={`${[/\/emprunt*/, /\/usufruit-economique*/, /\/pv-immobiliere*/, /\/investissements-immobiliers*/].some(reg => reg.test(props.location ? props.location.pathname : '')) ? 'active' : ''}`} name='BOX Immobilière' icon='cil-house' onClick={() => handleClickBoxImmobilier()}>
                  <CSidebarNavItem onClick={(e) => { e.stopPropagation(); changeEmprunState() }} className={`${props.location && props.location.pathname.includes('/emprunt') ? 'active' : 'simu'}`} name='Emprunt' to={lienEmprunt} />
                  <CSidebarNavItem onClick={(e) => { e.stopPropagation(); changeUsufEcoState() }} className={`${props.location && props.location.pathname.includes('/usufruit-economique') ? 'active' : 'simu'}`} name='Usufruit économique' to={lienUsufruitEconomique} />
                  <CSidebarNavItem onClick={(e) => { e.stopPropagation(); }} className={`${props.location && props.location.pathname.includes('/pv-immobiliere') ? 'active' : 'simu'}`} name='Plus-value immobilière' to={lienPlusValueImmobiliere} />
                </CSidebarNavDropdown>
              )
          })
        }

      </CSidebarNav>
    </CSidebar>
  )
}

export default React.memo(withRouter(TheSidebar))
