import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
    root: {

    },
    table: {
        width: "100%",
        borderLeft: "2px solid gray",
        borderTop: "2px solid gray"
    },
    th: {
        //borderLeft: '1px solid white'
    },
    td: {

    }
});

export default useStyles;