import React, { Fragment, useContext, useEffect, useState } from 'react';
import { CCardBody, CCol, CFormGroup, CLabel, CRow, CForm, CCard } from '@coreui/react';
import { InputCurrency, InputRate } from '../../../../components/input';
import EnfantConjointHolding, { structurationTotalSynthese } from './EnfantConjointHolding/EnfantConjointHolding';
import ActionnaireFamilial from './ActionnaireFamilial/ActionnaireFamilial';
import { FaInfoCircle } from 'react-icons/fa';
import useStyles from './style';
import TableauSynthese, { conditionAffichageEnfant, nombreEnfantsParConjoint } from './TableauSynthese/TableauSynthese';
import { CessionContext } from '../CessionHaut';
import { moveSpaceIntoCurrency } from '../OperationAvantCession/ApportsAvantCession/ApportsAvantCession';
import Loading from '../../../../components/loader';
import { LayoutContext } from '../../../../App2';
import { UtilsContext } from '../../../../store/context/utils'
import { restApiUri } from '../../../../config'
import HtmlTooltip from '../IdentiteActionnaire/Conjoint/HTMLTooltip/HTMLTooltip';
import { SimulateurContext } from '../../../../store/context/simulateur';
import { useParams } from 'react-router';
import { saveSimulationCession } from '../ResultatSimulation/ResultatSimulation';
import { structureDonationData } from '../OperationAvantCession/DonationParConjoint/DonationParConjoint';
import { Height } from '@material-ui/icons';
import InfoBulle from '../../../../components/InfoBulle/InfoBulle';
import Information from '../../../../components/Information/Information';
import { formatToUniqueId, messageErreurEnregistrementSimulation, messageSuccesEnregistrementSimulation } from '../../../../utils';
import ScrollButton from '../../../../components/Button/scrollToTop';
import { updatePostItData } from '../../../../store/actions/simulateur';

export const getData = async (data) => {
    const query = await fetch(`${restApiUri}/api/cession/lancer_simulation_cession`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${localStorage.getItem("token")}`
        },
        body: JSON.stringify({
            ...data,
        }),
        cache: 'no-cache'
    });

    return query.json();
};

export const fetchAllResult = (conjoints, enfants, projetCession, operations, avecNomPersonnalise = false) => {
    // Structuration des données pour qu'elles conforment à celles de l'API.
    let finalData = {}; // Ceci est la donnée finale à retourner

    // Pour les conjoints
    conjoints.map((data, index) => {
        if (index === 0 || (index === 1 && conjoints[0].marieOuPasce === "Oui")) {
            let conj = new Object();
            conj.nom = data.nomPersonnalise !== '' ? data.nomPersonnalise : `Conjoint ${index + 1}`;
            conj.age = parseInt(data.age, 10);
            conj.date_naiss = data.dateNaissance;
            conj.date_approximatif = data.dateApproximative;
            conj.revenu = moveSpaceIntoCurrency(data.revenuAnneeCession);
            if (index !== 1) conj.marie = data.marieOuPasce === "Oui" ? 1 : 0;
            conj.donation_pp = typeof data.donationPP === 'string' ? (data.donationPP === "" ? 0 : moveSpaceIntoCurrency(data.donationPP)) : data.donationPP;
            conj.apport_titre = typeof data.apportTitres === 'string' ? (data.apportTitres === "" ? 0 : moveSpaceIntoCurrency(data.apportTitres)) : data.apportTitres;
            if (index === 0) {
                conj.avec_nom_personnalise = avecNomPersonnalise ? 1 : 0;
            }

            finalData["conjoint" + (index + 1)] = conj;
        }
    });

    // Pour le enfants
    const tableauEnfants = [];
    enfants.map((data, index) => {
        let enf = new Object();
        enf.id = index + 1;
        enf.prenom = data.prenom !== '' ? data.prenom : `Enfant ${index + 1}`;
        enf.parent = data.parents === "Enfant commun" ? 0 : data.parents === "Enfant du conjoint 1" ? 1 : 2;
        enf.enfant_a_charge = data.enfantCharge === "Non" ? 0 : data.enfantCharge === "Oui" ? 1 : 2;
        enf.si_detache_revenu = data.enfantCharge === "Oui" ? 0 : typeof data.revenuAnnee === 'string' ? (data.revenuAnnee === "" ? 0 : moveSpaceIntoCurrency(data.revenuAnnee)) : data.revenuAnnee;
        enf.si_detache_situation_familliale = data.enfantCharge === "Oui" ? 0 : data.situationFamiliale === "Personne seule" ? 0 : 1;
        enf.handicap = data.handicap ? 1 : 0;

        tableauEnfants.push(enf);
    });
    finalData["liste_enfant"] = tableauEnfants;
    // Pour le prix de la cession
    finalData["prix_cession"] = projetCession.prixCession === "" ? 0 : moveSpaceIntoCurrency(projetCession.prixCession);

    // Pour le PP => Pleine propriété.
    let ppObject = new Object();
    ppObject["taux_pp"] = projetCession.actionnairesFamiliauxPP === "" ? 0 : parseFloat(projetCession.actionnairesFamiliauxPP);
    projetCession.conjoints.map((data, index) => {
        if (index === 0 || (index === 1 && conjoints[0].marieOuPasce === "Oui")) {
            let conj = new Object();
            conj.nom = conjoints[index].nomPersonnalise !== '' ? conjoints[index].nomPersonnalise : `Conjoint ${index + 1}`;
            conj.pourcentage_detention = data.pourcentageDetention === "" ? 0 : parseFloat(data.pourcentageDetention);
            conj.valeur_titre = !isNaN(data.valeurTitres) ? data.valeurTitres : (data.valeurTitres === "" ? 0 : moveSpaceIntoCurrency(data.valeurTitres));
            conj.prix_fiscal = typeof data.prixRevientFiscal === 'string' ? (data.prixRevientFiscal === "" ? 0 : moveSpaceIntoCurrency(data.prixRevientFiscal)) : data.prixRevientFiscal;
            conj.regime_fiscale = data.regimeFiscal === "Non éligible" ? 0 : 1;
            conj.taux_eligible = data.regimeFiscal === "Non éligible" ? 0 : data.pourcentage85 === "" ? 0 : parseFloat(data.pourcentage85);
            conj.eligible_abattement = data.eligible500 === 'Non éligible' ? 0 : 1;

            ppObject["conjoint" + (index + 1)] = conj;
        }
    });

    ppObject["holding"] = {
        nom: projetCession.holding.nomPersonnalise,
        pourcentage_detention: projetCession.holding.pourcentageDetention === "" ? 0 : parseFloat(projetCession.holding.pourcentageDetention),
        valeur_titre: !isNaN(projetCession.holding.valeurTitres) ? projetCession.holding.valeurTitres : (projetCession.holding.valeurTitres === "" ? 0 : moveSpaceIntoCurrency(projetCession.holding.valeurTitres)),
        prix_fiscal: typeof projetCession.holding.prixRevientFiscal === 'string' ? (projetCession.holding.prixRevientFiscal === "" ? 0 : moveSpaceIntoCurrency(projetCession.holding.prixRevientFiscal)) : projetCession.holding.prixRevientFiscal,
        regime_fiscale: projetCession.holding.regimeFiscal === "Régime IS de droit commun" ? 0 : 1
    };

    const anneeOuvertureExcercice = projetCession.holding.anneeOuverture;
    const anneeActuelle = new Date().getFullYear();

    if (anneeActuelle >= 2023) {
        if ([2020, 2021, '2020', '2021'].includes(anneeOuvertureExcercice)) {
            projetCession.holding.anneeOuverture = anneeOuvertureExcercice;
        }
        else {
            projetCession.holding.anneeOuverture = '2022 et suivants';
        }
    }

    ppObject["annee_ouverture"] = (projetCession.holding.anneeOuverture === '2022 et suivants') ? 2022 : parseInt(projetCession.holding.anneeOuverture, 10);
    const enfantsPPTableau = [];
    projetCession.enfantsFamiliauxPP.map((data, index) => {
        let enf = new Object();
        enf.id = index + 1;
        enf.nom = enfants[index].prenom !== '' ? enfants[index].prenom : `Enfant ${index + 1}`;
        enf.pourcentage_detention = data.pourcentageDetention === "" ? 0 : parseFloat(data.pourcentageDetention);
        enf.valeur_titre = !isNaN(data.valeurTitres) ? data.valeurTitres : (data.valeurTitres === "" ? 0 : moveSpaceIntoCurrency(data.valeurTitres));
        enf.prix_fiscal = typeof data.prixRevientFiscal === 'string' ? (data.prixRevientFiscal === "" ? 0 : moveSpaceIntoCurrency(data.prixRevientFiscal)) : data.prixRevientFiscal;
        enf.regime_fiscale = data.regimeFiscal === "Non éligible" ? 0 : 1;
        enf.apport_titre = typeof data.apportTitres === 'string' ? (data.apportTitres === "" ? 0 : moveSpaceIntoCurrency(data.apportTitres)) : data.apportTitres;

        enfantsPPTableau.push(enf);
    });
    ppObject["liste_enfant"] = enfantsPPTableau;
    finalData["pp"] = ppObject;

    // Pour le NP => Nue-propriété
    let npObject = new Object();
    npObject["taux_np"] = projetCession.actionnairesFamiliauxDP === "" ? 0 : parseFloat(projetCession.actionnairesFamiliauxDP);

    projetCession.enfantsFamiliauxDP.map((donnee, index) => {
        if (index === 0 || (index === 1 && conjoints[0].marieOuPasce === "Oui")) {
            const tab = [];
            donnee.enfants.map((data, indice) => {
                if (conditionAffichageEnfant(enfants, index, indice)) {
                    let enf = new Object();
                    enf.id = indice + 1;
                    enf.nom = enfants[indice].prenom !== '' ? enfants[indice].prenom : `Enfant ${enfants[indice].id}`;
                    enf.pourcentage_detention = data.pourcentageDetention === "" ? 0 : parseFloat(data.pourcentageDetention);
                    enf.valeur_titre = !isNaN(data.valeurTitres) ? data.valeurTitres : (data.valeurTitres === "" ? 0 : moveSpaceIntoCurrency(data.valeurTitres));
                    enf.prix_fiscal = typeof data.prixRevientFiscal === 'string' ? (data.prixRevientFiscal === "" ? 0 : moveSpaceIntoCurrency(data.prixRevientFiscal)) : data.prixRevientFiscal;
                    enf.regime_fiscale = data.regimeFiscal === "Non éligible" ? 0 : 1;
                    enf.apport_titre = !isNaN(data.apportTitres) ? data.apportTitres : (data.apportTitres === "" ? 0 : moveSpaceIntoCurrency(data.apportTitres));

                    tab.push(enf);
                }
            });

            npObject["conjoint" + (index + 1)] = new Object();
            npObject["conjoint" + (index + 1)]["liste_enfant"] = tab;
        }
    });

    finalData["np"] = npObject;

    const payementPlusValue = new Object();
    payementPlusValue.conjoint1 = parseFloat(projetCession.conjoints[0].ipv.real !== "" ? projetCession.conjoints[0].ipv.real : 0);
    if (conjoints[0].marieOuPasce === 'Oui')
        payementPlusValue.conjoint2 = 100 - parseFloat(projetCession.conjoints[0].ipv.real !== "" ? projetCession.conjoints[0].ipv.real : 0);

    finalData["payement_plus_value"] = payementPlusValue;

    finalData = {
        ...finalData,
        ...structureDonationData(conjoints, enfants, operations)
    };

    return finalData;
};

export const calculActionnairesFamiliauxPP = (conjointsP, enfantsP, holdingP, marieOuPasce) => {
    let somme = 0;
    conjointsP.map((data, index) => {
        if (index === 0 || (index === 1 && marieOuPasce)) {
            somme += parseFloat(data.pourcentageDetention !== "" ? data.pourcentageDetention.replaceAll(" ", "") : 0);
        }
    });

    enfantsP.map((data, index) => {
        somme += parseFloat(data.pourcentageDetention !== "" ? data.pourcentageDetention.replaceAll(" ", "") : 0);
    });

    somme += parseFloat(holdingP.pourcentageDetention !== "" ? holdingP.pourcentageDetention.replaceAll(" ", "") : 0);

    return somme;
};

export const calculIPV = (conjoints, marie) => {
    // Test de chaîne vide si jamais il y aura des regressions
    const ipv1 = new Object();
    ipv1.default = (parseFloat(conjoints[0].pourcentageDetention) * 100) /
        (parseFloat(conjoints[0].pourcentageDetention) + parseFloat(marie ? conjoints[1].pourcentageDetention : 0));

    return ipv1;
};

const ProjetCessionSociete = (props) => {
    const classes = useStyles();
    const { layoutState, setLayoutState } = useContext(LayoutContext);
    const { simulateurState, simulateurDispatch } = useContext(SimulateurContext);
    const { utilsDispatch } = useContext(UtilsContext)
    const { footerButtons, menuLevel } = layoutState;

    const [load, setLoad] = useState(false);
    const { cessionHaut, setCessionHaut } = useContext(CessionContext);
    const { identiteActionnaire: identite, projetCessionSociete: projet, idSimulation, operationsAvantCession: operations } = cessionHaut;
    const { conjoints: projetConjoints } = projet;
    const { conjoints, enfants } = identite;
    const {
        prixCession,
        actionnairesFamiliauxPP,
        actionnairesFamiliauxDP,
        conjoints: conjointsProjet,
        enfantsFamiliauxPP,
        enfantsFamiliauxDP,
        holding,
    } = projet;

    const prenomConjoint1 = conjoints[0] &&
        ![null, undefined, ''].includes(conjoints[0].nomPersonnalise) ? conjoints[0].nomPersonnalise : 'Conjoint 1';

    const prenomConjoint2 = conjoints[1] &&
        ![null, undefined, ''].includes(conjoints[1].nomPersonnalise) ? conjoints[1].nomPersonnalise : 'Conjoint 2';

    const [currentData, setCurrentData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isIpv1RealUpdated, setIsIpv1RealUpdated] = useState(false);

    const { id } = useParams();

    const handleIpv1Change = (event) => {
        const { value } = event.target;
        setIsIpv1RealUpdated(true);

        setCessionHaut((prevState) => {
            projet.conjoints[0].ipv.real = value;
            if (conjoints[0].marieOuPasce === 'Oui')
                projet.conjoints[1].ipv = 100 - parseFloat(value);

            return {
                ...prevState,
                projetCessionSociete: projet
            };
        });
    };

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    // Données et calculs
    useEffect(() => {
        setLoading(true);
        getData(fetchAllResult(conjoints, enfants, projet, operations))
            .then((response) => {
                projet.total = structurationTotalSynthese(response.resultatFormulaire, conjoints);
                setCessionHaut((prevState) => ({
                    ...prevState,
                    projetCessionSociete: projet,
                }));
                setCurrentData(response.resultatFormulaire);
            })
            .catch((error) => console.log(error))
            .finally(() => { setLoading(false) })
    }, []);

    // Prise en charge des footers
    useEffect(() => {
        setLayoutState((prevState) => {
            footerButtons.cs = [
                {
                    type: 'PREVIOUS',
                    text: "Précédent",
                    onClick: () => {
                        setLayoutState((prev) => {
                            menuLevel.cs = 1;

                            return {
                                ...prev,
                                menuLevel
                            };
                        })
                    }
                },
                {
                    type: 'ADD',
                    text: "Enregistrer",
                    onClick: () => {
                        saveSimulationCession(
                            cessionHaut,
                            simulateurState,
                            () => { setLoad(true) },
                            () => { setLoad(false) },
                            (response) => {
                                const id = response.id;
                                if (id) {
                                    identite.idSimulationOnSave = id;
                                    projet.idSimulationOnSave = id;
                                    simulateurDispatch(updatePostItData({
                                      ...(simulateurState?.postItData || {}),
                                      simulationId: response.id,
                                      simulationType: 'projet-cs'
                                    }))
                                }
                                utilsDispatch({ type: 'ADD_TOAST', payload: { type: 'success', title: 'OK', description: messageSuccesEnregistrementSimulation(simulateurState?.simulation?.identifiant_simulation) } })
                            },
                            () => { utilsDispatch({ type: 'ADD_TOAST', payload: { type: 'danger', title: 'ERREUR', description: messageErreurEnregistrementSimulation(simulateurState?.simulation?.identifiant_simulation) } }) },
                            idSimulation || projet.idSimulationOnSave
                        )
                    }
                },
                {
                    type: 'NEXT',
                    text: "Suivant",
                    onClick: () => {
                        setLayoutState((prev) => {
                            menuLevel.cs = 3;

                            return {
                                ...prev,
                                menuLevel
                            };
                        })
                    }
                },
            ]

            return {
                ...prevState,
                footerButtons
            };
        });
    }, [cessionHaut, simulateurState]);

    const handlePrixCessionChange = (event) => {
        fetchAllResult(conjoints, enfants, projet, operations)
        const { value } = event.target;
        projet.prixCession = value;
        setCessionHaut((prevState) => ({
            ...prevState,
            projetCessionSociete: projet
        }));
    };

    let sommeActionnairesFamiliauxDemembrement = 0;

    // Calcul de la somme du taux des actionnaires familiaux en demembrement de propriété.
    useEffect(() => {
        enfantsFamiliauxDP.map((donnee, indice) => {
            if (indice === 0 || (indice === 1 && conjoints[0].marieOuPasce === "Oui")) {
                donnee.enfants.map((data, index) => {
                    sommeActionnairesFamiliauxDemembrement += parseFloat(data.pourcentageDetention !== "" ? data.pourcentageDetention : 0);
                });
            }
        });

        projet.actionnairesFamiliauxDP = sommeActionnairesFamiliauxDemembrement;
        setCessionHaut((prevState) => ({
            ...prevState,
            projetCessionSociete: projet
        }));
    }, [enfantsFamiliauxDP]);

    // Message d'erreur si PP > 100
    useEffect(() => {
        if (parseFloat(actionnairesFamiliauxPP) > 100) {
            utilsDispatch({ type: 'ADD_TOAST', payload: { type: 'danger', title: 'ERREUR', description: "Le taux correspondant aux actionnaires familiaux en pleine propriété ne doit pas être supérieure à 100 %" } });
        }
    }, [actionnairesFamiliauxPP]);

    // Message d'erreur si NP > 100
    useEffect(() => {
        if (parseFloat(actionnairesFamiliauxDP) > 100) {
            utilsDispatch({ type: 'ADD_TOAST', payload: { type: 'danger', title: 'ERREUR', description: "Le taux correspondant aux actionnaires familiaux en démembrement de propriété ne doit pas être supérieure à 100 %" } });
        }
    }, [actionnairesFamiliauxDP]);

    // Message d'erreur si PP + NP > 100
    useEffect(() => {
        if ((parseFloat(actionnairesFamiliauxPP) + parseFloat(actionnairesFamiliauxDP)) > 100) {
            utilsDispatch({ type: 'ADD_TOAST', payload: { type: 'danger', title: 'ERREUR', description: "La somme du taux correspondant aux actionnaires en pleine propriété et de celui en démembrement de propriété ne doit pas être supérieure à 100 %" } });
        }
    }, [actionnairesFamiliauxPP, actionnairesFamiliauxDP]);

    return !loading && currentData ? (
        <>
            {load && <Loading />}
            <div className="formulaire">
                <CCol xl={12}>
                    <CForm action="/simulateur/pea" method="post" className="col-lg-12" id="form_pea" >
                        <div>
                            <div>
                                <CRow style={{ backgroundColor: "#fff", padding: '12px 0 12px 47px', borderRadius: 4 }}>
                                    <CFormGroup className="form-inline pr-0 pl-0 mb-0">
                                        <div style={{ display: "flex", flexDirection: "column" }}>
                                            <CLabel style={{ paddingLeft: 0, minWidth: 363, maxWidth: 363 }} className="label_title_cession labelSpace col-lg-4 col-md-6 col-sm-7 d-flex flex-direction-row justify-content-start">Prix de cession de la société ou de la participation</CLabel>
                                            <div style={{ background: "linear-gradient(265deg,#80BA4B,#2B9957)", width: 28, height: 4, borderRadius: 4 }}></div>
                                        </div>
                                        <InputCurrency
                                            id={`${formatToUniqueId("ProjetCessionSociete 2")}`}
                                            value={prixCession}
                                            onChange={handlePrixCessionChange}
                                            className="col-lg-2 col-md-2 col-sm-2 mr-1"
                                        />
                                    </CFormGroup>
                                </CRow>
                                <CRow className="structureBlock" style={{ background: "#fff", borderRadius: 4, marginTop: 8, marginBottom: 8, paddingBottom: 5 }}>

                                    <CCol sm={12} style={{ padding: '12px 0 7px 47px' }}>


                                        {/* <div className="breadcrumb" style={{borderBottomStyle:"dashed"}}></div> */}
                                        <CFormGroup className="form-inline pr-0 pl-0">
                                            <div>
                                                <CLabel style={{ paddingLeft: 0, minWidth: 363, maxWidth: 363 }} className="label_title_cession labelSpace col-lg-4  d-flex flex-direction-row justify-content-start">Actionnaires familiaux en pleine propriété</CLabel>
                                                <div style={{ background: "#EE468B", width: 28, height: 4, borderRadius: 4 }}></div>
                                            </div>
                                            <div className="col-lg-2 mr-1 d-flex justify-content-end" style={{ padding: 0 }} >
                                                <InputRate
                                                    id={`${formatToUniqueId("ProjetCessionSociete actionnairesFamiliauxPP")}-rate`}
                                                    className="col-lg-6"
                                                    value={actionnairesFamiliauxPP}
                                                    disabled={true}
                                                    invalid={actionnairesFamiliauxPP > 100}
                                                />
                                            </div>

                                        </CFormGroup>
                                    </CCol>


                                    <CCol style={{ padding: '12px 0 0px 47px', background: "#FAFAFA" }}>
                                        <br></br>

                                        <EnfantConjointHolding
                                            type="CONJOINT"
                                            data={conjoints[0].marieOuPasce === "Oui" ? conjointsProjet : [conjointsProjet[0]]}
                                            isIpv1RealUpdated={id ? true : isIpv1RealUpdated}
                                            prenomConjoint1={prenomConjoint1}
                                            prenomConjoint2={prenomConjoint2}
                                        />
                                        <br></br>

                                        {
                                            // On affiche ceci uniquement quand il y a des Enfants en PP
                                            enfantsFamiliauxPP.length > 0 && (
                                                <>
                                                    <EnfantConjointHolding
                                                        type="ENFANT"
                                                        data={enfantsFamiliauxPP}
                                                        prenomConjoint1={prenomConjoint1}
                                                        prenomConjoint2={prenomConjoint2}
                                                    />
                                                    <br></br>
                                                </>
                                            )
                                        }

                                        <div style={{ marginTop: 18 }}>
                                            <EnfantConjointHolding
                                                type="HOLDING"
                                                data={[holding]}
                                                prenomConjoint1={prenomConjoint1}
                                                prenomConjoint2={prenomConjoint2}
                                            />
                                        </div>
                                    </CCol>
                                    <span style={{ backgroundColor: "#fff", width: '100%', height: 3, position: "absolute", marginTop: enfantsFamiliauxPP.length > 0 ? 263 : 255 }}></span>
                                    <span style={{ backgroundColor: "#fff", width: '100%', height: 3, position: "absolute", marginTop: 414 }}></span>
                                    {
                                        enfantsFamiliauxPP.length > 0 && (
                                            <span style={{ backgroundColor: "#fff", width: '100%', height: 3, position: "absolute", marginTop: 655 }}></span>
                                        )
                                    }
                                    {/* <div className="breadcrumb" style={{borderBottomStyle:"dashed"}}></div> */}
                                </CRow>
                                {
                                    (nombreEnfantsParConjoint(enfants, 0) > 0 || nombreEnfantsParConjoint(enfants, 1) > 0) && (
                                        <CRow className="structureBlock" style={{ background: "#fff", padding: '12px 0 10px 32px', borderRadius: 4, marginTop: 8, marginBottom: 8 }}>
                                            <CCol>
                                                {
                                                    // On affiche ceci si et seuleument si au moins un des conjoints a un enfant
                                                    (nombreEnfantsParConjoint(enfants, 0) > 0 || nombreEnfantsParConjoint(enfants, 1) > 0) && (
                                                        <>
                                                            <CFormGroup className="form-inline pr-0 pl-0">
                                                                <div>
                                                                    <CLabel style={{ paddingLeft: 0, minWidth: 442, maxWidth: 442 }} className="label_title_cession labelSpace col-lg-4 col-md-6 col-sm-7 d-flex flex-direction-row justify-content-start">Les actionnaires familiaux en démembrement de propriété</CLabel>
                                                                    <div style={{ background: "linear-gradient(265deg,#80BA4B,#2B9957)", width: 28, height: 4, borderRadius: 4 }}></div>
                                                                </div>
                                                                <div className="col-lg-1 col-md-2 col-sm-2 mr-1 d-flex justify-content-end" style={{ padding: 0 }} >
                                                                    <InputRate
                                                                        disabled={true}
                                                                        id={`${formatToUniqueId("ProjetCessionSociete actionnairesFamiliauxDemembrement")}-rate`}
                                                                        value={actionnairesFamiliauxDP}
                                                                        className="col-lg-12"
                                                                        invalid={actionnairesFamiliauxDP > 100}
                                                                    />
                                                                </div>
                                                            </CFormGroup>

                                                            <br></br>
                                                        </>
                                                    )
                                                }

                                                {
                                                    // On affiche uniquement ceci quand le nombre d'enfants du Conjoint 1 > 0
                                                    nombreEnfantsParConjoint(enfants, 0) > 0 && (
                                                        <>
                                                            <ActionnaireFamilial
                                                                data={enfantsFamiliauxDP[0].enfants}
                                                            />
                                                            <br></br>
                                                        </>
                                                    )
                                                }

                                                {
                                                    // On affiche uniquement ceci quand le nombre d'enfants du Conjoint 2 est > 0
                                                    conjoints[0].marieOuPasce === "Oui" && nombreEnfantsParConjoint(enfants, 1) > 0 && (
                                                        <>
                                                            <ActionnaireFamilial
                                                                data={enfantsFamiliauxDP[1].enfants}
                                                                conjoint2={true}
                                                            />

                                                        </>
                                                    )
                                                }

                                                {
                                                    // On affiche l'information si et seulement si au moins l'un des deux conjoints a un enfant (NP)
                                                    (nombreEnfantsParConjoint(enfants, 0) > 0 || nombreEnfantsParConjoint(enfants, 1) > 0) && (
                                                        <div style={{ width: '80%', marginTop: 5, marginBottom: 5 }}>
                                                            <Information
                                                                text={<div style={{ fontWeight: 'bold' }}>Hypothèses de cession des titres démembrés avec report du démembrement du prix de cession et les actifs acquis en remploi (en présence d'une clause de remploi)</div>}
                                                            />
                                                        </div>
                                                    )
                                                }
                                            </CCol>
                                        </CRow>
                                    )
                                }

                                <CRow className="structureBlock" style={{ background: "#fff", padding: '7px 0 7px 32px', borderRadius: 4, marginTop: 8, marginBottom: 8 }}>
                                    <CCol>
                                        <CFormGroup className="form-inline col-lg-12">
                                            <CLabel htmlFor="tableauSynthese" className="label_title col-lg-12 col-md-10 col-sm-11 d-flex flex-direction-row align-items-center justify-content-start pl-0">
                                                Le paiement de l'impôt de plus-value
                                            </CLabel>
                                        </CFormGroup>

                                        <CFormGroup className="form-inline">
                                            <div style={{ display: "flex", flexDirection: "column" }}>
                                                <CLabel style={{ fontWeight: 'bold', padding: 0 }} htmlFor="montant_investi" className="col-lg-12 col-md-12 col-sm-12">
                                                    Répartition de la prise en charge des impôts sur la plus-value dans le cadre du foyer fiscal {["Conjoint 1"].includes(prenomConjoint1) ? "du" : "de"} {prenomConjoint1.toString().toUpperCase()}
                                                    <div style={{ background: "#EE468B", width: 28, height: 4, borderRadius: 4 }}></div>
                                                </CLabel>
                                            </div>
                                        </CFormGroup>
                                        <br></br>

                                        <CFormGroup className="form-inline pr-0 pl-0">
                                            <CLabel id="" style={{ padding: 0, maxWidth: 363, minWidth: 363 }} className="col-lg-4 col-md-6 col-sm-7">% IPV pris en charge par {["Conjoint 1"].includes(prenomConjoint1) ? 'le' : ''} {prenomConjoint1.toString().toUpperCase()} - valeur par défaut</CLabel>
                                            <div className="col-lg-2 col-md-2 col-sm-2 d-flex justify-content-end" style={{ padding: 0 }} >
                                                <InputRate
                                                    id={`${formatToUniqueId("ProjetCessionSociete IPV pris en charge")}-rate`}
                                                    disabled={true}
                                                    value={conjointsProjet[0].ipv.default}
                                                    className="col-lg-6"
                                                />
                                            </div>
                                            <InfoBulle
                                                information="Hypothèse d'une répartition du paiement de l'IPV proportionnelle aux participations respectives des conjoints."
                                            />
                                        </CFormGroup>
                                        {
                                            conjoints[0].marieOuPasce === 'Oui' && (
                                                <>
                                                    <CFormGroup className="form-inline pr-0 pl-0">
                                                        <CLabel id="" style={{ padding: 0, maxWidth: 363, minWidth: 363 }} className="col-lg-4 col-md-6 col-sm-7">% IPV pris en charge par {["Conjoint 1"].includes(prenomConjoint1) ? 'le' : ''} {prenomConjoint1.toString().toUpperCase()} - <span style={{ fontWeight: '300' }} >valeur retenue</span></CLabel>
                                                        <div className="col-lg-2 col-md-2 col-sm-2 mr-1 d-flex justify-content-end" style={{ padding: 0 }} >
                                                            <InputRate
                                                                id={`${formatToUniqueId("ProjetCessionSociete IPV pris en charge 2")}-rate`}
                                                                disabled={conjoints[0].marieOuPasce === "Non"}
                                                                value={conjointsProjet[0].ipv.real/* ? conjointsProjet[0].ipv.real : conjointsProjet[0].ipv.default*/}
                                                                className="col-lg-6"
                                                                onChange={handleIpv1Change}
                                                            />
                                                        </div>

                                                    </CFormGroup>

                                                    <CFormGroup className="form-inline pr-0 pl-0">
                                                        <CLabel id="" style={{ padding: 0, maxWidth: 363, minWidth: 363 }} className="col-lg-4 col-md-6 col-sm-7">% IPV pris en charge par {["Conjoint 2"].includes(prenomConjoint2) ? 'le' : ''} {prenomConjoint2.toString().toUpperCase()}</CLabel>
                                                        <div className="col-lg-2 col-md-2 col-sm-2 mr-1 d-flex justify-content-end" style={{ padding: 0 }} >
                                                            <InputRate
                                                                id={`${formatToUniqueId("ProjetCessionSociete IPV pris en charge 3")}-rate`}
                                                                disabled={true}
                                                                value={100 - conjointsProjet[0].ipv.real}
                                                                className="col-lg-6"
                                                            />
                                                        </div>

                                                    </CFormGroup>
                                                </>
                                            )
                                        }

                                        {/* <div className="breadcrumb" style={{ borderBottomStyle: "dashed" }}></div> */}
                                    </CCol>
                                </CRow>
                                <CRow className="structureBlock" style={{ background: "#fff", paddingBottom: 10, borderRadius: 4, marginTop: 8, marginBottom: 8 }}>
                                    <CCol>
                                        <CFormGroup className="form-inline col-lg-12">
                                            <div style={{ display: "flex", flexDirection: "column", padding: '12px 0 7px 32px' }}>
                                                <CLabel htmlFor="tableauSynthese" className="label_title col-lg-12 col-md-10 col-sm-11 d-flex flex-direction-row align-items-center justify-content-start pl-0">
                                                    Tableau de synthèse
                                                </CLabel>
                                                <div style={{ background: "linear-gradient(265deg,#80BA4B,#2B9957)", width: 28, height: 4, borderRadius: 4 }}></div>
                                            </div>
                                        </CFormGroup>

                                        <TableauSynthese />
                                    </CCol>
                                </CRow>
                            </div>
                        </div>
                    </CForm>
                </CCol>
            </div>
        </>
    )
        :
        (
            <Loading />
        )
};

export default ProjetCessionSociete;
