import generateDoughnut from "../../../components/doughnut/Doughnut";
import { blackColor, numberFormat } from "../../all_ppt_render";

const grayColor = '666666';

const titleByScenario = (scenario) => {
    if(scenario === 1){
        return [
            { text: "SCENARIO 1", options: { breakLine: true, color: blackColor } },
            { text: "Imposition à la flat-tax sans régime de faveur", options: { breakLine: true, color: '7F869A' } },
            //{ text: "", options: { breakLine: true } },
        ]
    }
    else if(scenario === 2){
        return [
            { text: "SCENARIO 2", options: { breakLine: true, color: blackColor } },
            { text: "Optimisation des régimes fiscaux", options: { breakLine: true, color: '7F869A' } },
            //{ text: "" }
        ];
    }
    else if(scenario === 3){
        return [
            { text: "SCENARIO 3", options: { breakLine: true, color: blackColor } },
            { text: "Prise en charge des frais de donation par les ", options: { color: '7F869A' } },
            { text: "donateurs", options: { underline: true, color: '7F869A' } }
        ];
    }
    else if(scenario === 4){
        return [
            { text: "SCENARIO 4", options: { breakLine: true, color: blackColor } },
            { text: "Prise en charge des frais de donation par les ", options: { color: '7F869A' } },
            { text: "donataires", options: { underline: true, color: '7F869A' } }
        ];
    }
};

const impotsByScenario = (scenario) => {
    if(scenario === 1 || scenario === 2) return "Impôts sur PV";
    if(scenario === 3 || scenario === 4) return "Impôts et droits";
};

const generateScenario = (pptx, slide, x, y, scenario, width, data) => {
    slide.addText(titleByScenario(scenario), {
        x: x,
        y: y,
        fontFace: 'Gill Sans MT',
        fontSize: 12,
        valign: 'middle',
        align: 'left',
        w: width,
        h: '12%',
        bold: true
    });


    // Impôts sur la plus-value
    slide.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
        x: x,
        y: y + 1.06,
        w: "0.5%",
        h: '9%',
        fill: 'F04F6C',
        rectRadius: 0.05
    });
    
    slide.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
        x: x + 0.1,
        y: y + 1.06,
        w: "12%",
        h: '9%',
        fill: 'FFCED7',
        rectRadius: 0.05
    });
    
    slide.addText(
        [
            { text: `${numberFormat(Math.round(data.impot_sur_plus_value))} €`, options: { bold: true, breakLine: true, color: 'F04F6C' } },
            { text: "", options: { breakLine: true } },
            { text: impotsByScenario(scenario), color: blackColor },
        ]
        , {
        x: x + 0.1,
        y: y + 1.06,
        fontFace: 'Gill Sans MT',
        fontSize: 11,
        valign: 'middle',
        align: 'left',
        w: "12%",
        h: '9%'
    });

    generateDoughnut(
        pptx,
        slide,
        x + 0.75,
        y + 0.82,
        data && data.taux_impot_sur_pv ? data.taux_impot_sur_pv.toFixed(2) : 0,
        'F04F6C',
        'FFCED7',
        2.5,
        1.2,
        80
    );

    slide.addText(`${data && data.taux_impot_sur_pv ? data.taux_impot_sur_pv.toFixed(2) : 0} %`, {
        x: x + 1.64,
        y: y + 1.3,
        w: "7%",
        h: 0.3,
        bold: true,
        fontSize: 11,
        fontFace: 'Gill Sans MT',
        color: blackColor
    })

    // Net de cession
    y += 0.8;
    slide.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
        x: x,
        y: y + 1.06,
        w: "0.5%",
        h: '9%',
        fill: '279757',
        rectRadius: 0.05
    });
    
    slide.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
        x: x + 0.1,
        y: y + 1.06,
        w: "12%",
        h: '9%',
        fill: 'C3E8D3',
        rectRadius: 0.05
    });
    
    slide.addText(
        [
            { text: `${numberFormat(Math.round(data.net_cession))} €`, options: { bold: true, breakLine: true, color: '279757' } },
            { text: "", options: { breakLine: true } },
            { text: "Net de cession", options: { color: blackColor } },
        ]
        , {
        x: x + 0.1,
        y: y + 1.06,
        fontFace: 'Gill Sans MT',
        fontSize: 11,
        valign: 'middle',
        align: 'left',
        w: "12%",
        h: '9%'
    });

    // Gain fiscal
    y += 0.8;
    if(scenario !== 1){
        slide.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
            x: x,
            y: y + 1.06,
            w: "0.5%",
            h: [3, 4].includes(scenario) ? '13%' : '9%',
            fill: 'EDC413',
            rectRadius: 0.05
        });
        
        slide.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
            x: x + 0.1,
            y: y + 1.06,
            w: "12%",
            h: [3, 4].includes(scenario) ? '13%' : '9%',
            fill: 'F5F0D9',
            rectRadius: 0.05
        });

        const gainFiscalData = [
            { text: `${numberFormat(Math.round(data.gain_fiscale))} €`, options: { bold: true, breakLine: true, color: 'EDC413' } },
            { text: "", options: { breakLine: true } },
            { text: "Gain fiscal", options: { color: blackColor } },
        ];

        if([3, 4].includes(scenario)){
            gainFiscalData.push(
                { text: "", options: { breakLine: true } },
                { text: "Donation incluse", color: '9C9C9C' }
            )
        }
        
        slide.addText(gainFiscalData
            , {
            x: x + 0.1,
            y: y + 1.06,
            fontFace: 'Gill Sans MT',
            fontSize: 11,
            valign: 'middle',
            align: 'left',
            w: "12%",
            h: [3, 4].includes(scenario) ? '13%' : '9%'
        });
    }
    else{
        slide.addText("SITUATION DE REFERENCE", {
            x: x,
            y: y + 1.06,
            fontSize: 11,
            fontFace: 'Gill Sans MT',
            color: blackColor,
            bold: true,
            w: 2.4,
            h: 0.3
        });
    }
};

export default generateScenario;