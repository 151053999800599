import React, { useContext } from "react";
import NumberFormat from 'react-number-format';
import { CInput } from '@coreui/react';
import { SimulateurContext } from "../../store/context/simulateur";
import { activePageBlock } from "../../store/actions/simulateur";
import PostitPin from "../Postit/PostitPin";

const CustomInput = (props) => {
    const { isPostitShow, className, id, hidePin, ...inputProps } = props

    return (
        <div className={className} style={{ padding: 0, position: 'relative' }}>
            <CInput {...inputProps} style={{ width: '100%', paddingRight: 30, textAlign: 'right', paddingLeft: 19, fontSize: 13, ...inputProps.style }} />
            {
              !hidePin && (
                <PostitPin
                // pinTop="-43px"
                  // pinRight="17px"
                // pinTopHover="-49px"
                  // pinRightHover="12px"
                // pinSelectedTopHover="-45px"
                  // pinSelectedRightHover="12px"
                  isPostitShow={isPostitShow}
                  pinId={id} />
              )
            }
        </div>
    )
}

const InputCurrencyWithoutEuro = (props) => {
    const { simulateurState, simulateurDispatch } = useContext(SimulateurContext);

    const handleChange = (event) => {
        // On met à jour l'état de changement de FORMULAIRE
        simulateurDispatch(activePageBlock())

        // Changement de valeur contenue dans le FORMULAIRE
        props.onChange(event);
    }

    return (
        <NumberFormat
            id={props.id}
            customInput={CustomInput}
            style={{ ...props.style }}
            decimalScale={2}
            isAllowed={(values) => {
                const { value } = values;
                // eslint-disable-next-line eqeqeq
                return props.min ? value > props.min : value >= 0 || value == '' || value == '.';
            }}
            allowNegative={props.allowNegative ? props.allowNegative : false}
            disabled={props.disabled}
            onKeyDown={event => {
                if (event.keyCode === 13) {
                    const form = event.target.form;
                    let index = Array.prototype.indexOf.call(form, event.target);
                    if (form.elements[index + 1])
                        form.elements[index + 1].focus();
                    event.preventDefault();
                }
            }}
            invalid={props.invalid}
            // eslint-disable-next-line eqeqeq
            value={props.value == '.' ? '0.' : props.value}
            onChange={handleChange}
            onBlur={props.onBlur}
            className={props.className ? props.className : "col-lg-2"}
            thousandSeparator={' '}
            isPostitShow={simulateurState?.postItData?.displayBlocPostIt}
            hidePin={props.hidePin}
        />
    )
}

export default InputCurrencyWithoutEuro;
