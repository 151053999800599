import React from 'react';
import { OrdinaryTooltip } from '../../InfoBulle/Tooltip/Tooltip';
import { CImg } from '@coreui/react';

const Fleche = ({
    hidden,
    type,
    onRequestClick
}) => {
    const text = type === 'next' ? 'Page suivante' : type === 'previous' ? 'Page précédente' : type === 'first-page' ? 'Première page' : 'Dernière page';
    const arrowColor = hidden ? '#A8ACB5' : '#585C66';

    const handleClick = () => {
        if(!hidden && onRequestClick){
            onRequestClick()
        }
    }

    return (
        <OrdinaryTooltip arrow placement='bottom' title={text}>
            <div onClick={handleClick} style={{ cursor: 'pointer', marginRight: ['first-page', 'next'].includes(type) ? 10 : type === 'previous' ? 25 : 0 }}>
                {
                    type === 'next' ? (
                        <svg xmlns="http://www.w3.org/2000/svg" width={7.5} viewBox="0 0 9.721 17.004">
                            <path id="Icon_ionic-ios-arrow-up" data-name="Icon ionic-ios-arrow-up" d="M14.692,14.181l6.429,6.434a1.215,1.215,0,0,0,1.716-1.721L15.552,11.6a1.213,1.213,0,0,0-1.676-.035l-7.335,7.32A1.215,1.215,0,1,0,8.258,20.61Z" transform="translate(20.972 -6.188) rotate(90)" fill={arrowColor} />
                        </svg>
                    ) :
                        type === 'previous' ? (
                            <svg xmlns="http://www.w3.org/2000/svg" width={7.5} viewBox="0 0 9.721 17.004">
                                <path id="Icon_ionic-ios-arrow-up" data-name="Icon ionic-ios-arrow-up" d="M8.5,6.791,14.933.357a1.215,1.215,0,0,1,1.716,1.721L9.365,9.367A1.213,1.213,0,0,1,7.689,9.4L.354,2.083A1.215,1.215,0,0,1,2.07.362Z" transform="translate(9.721) rotate(90)" fill={arrowColor} />
                            </svg>
                        ) :
                            type === 'first-page' ? (
                                <svg xmlns="http://www.w3.org/2000/svg" width={15} viewBox="0 0 19.442 17.004">
                                    <g id="Group_40849" data-name="Group 40849" transform="translate(-3279.418 -231.498)">
                                        <path id="Icon_ionic-ios-arrow-up" data-name="Icon ionic-ios-arrow-up" d="M8.5,6.791,14.933.357a1.215,1.215,0,0,1,1.716,1.721L9.365,9.367A1.213,1.213,0,0,1,7.689,9.4L.354,2.083A1.215,1.215,0,0,1,2.07.362Z" transform="translate(3298.861 231.498) rotate(90)" fill={arrowColor} />
                                        <path id="Icon_ionic-ios-arrow-up-2" data-name="Icon ionic-ios-arrow-up" d="M8.5,6.791,14.933.357a1.215,1.215,0,0,1,1.716,1.721L9.365,9.367A1.213,1.213,0,0,1,7.689,9.4L.354,2.083A1.215,1.215,0,0,1,2.07.362Z" transform="translate(3289.139 231.498) rotate(90)" fill={arrowColor} />
                                    </g>
                                </svg>
                            ) : (
                                <svg xmlns="http://www.w3.org/2000/svg" width={15} viewBox="0 0 19.442 17.004">
                                    <g id="Group_40850" data-name="Group 40850" transform="translate(-3399.139 -231.498)">
                                        <path id="Icon_ionic-ios-arrow-up" data-name="Icon ionic-ios-arrow-up" d="M14.692,14.181l6.429,6.434a1.215,1.215,0,0,0,1.716-1.721L15.552,11.6a1.213,1.213,0,0,0-1.676-.035l-7.335,7.32A1.215,1.215,0,1,0,8.258,20.61Z" transform="translate(3420.111 225.311) rotate(90)" fill={arrowColor} />
                                        <path id="Icon_ionic-ios-arrow-up-2" data-name="Icon ionic-ios-arrow-up" d="M14.692,14.181l6.429,6.434a1.215,1.215,0,0,0,1.716-1.721L15.552,11.6a1.213,1.213,0,0,0-1.676-.035l-7.335,7.32A1.215,1.215,0,1,0,8.258,20.61Z" transform="translate(3429.832 225.311) rotate(90)" fill={arrowColor} />
                                    </g>
                                </svg>
                            )
                }
            </div>
        </OrdinaryTooltip>
    )
}

export default Fleche;