export const setSimulation = (payload) => {
    return {
        type: "SET_CURRENT_SIMULATION",
        payload
    }
}

export const updateSimulation = (payload) => {
    return {
        type: "UPDATE_CURRENT_SIMULATION",
        payload
    }
}

export const addButton = (payload) => {
    return {
        type: "ADD_FOOTER_BUTTON",
        payload
    }
}

export const deleteButton = (payload) => {
    return {
        type: "DELETE_FOOTER_BUTTON",
        payload
    }
}

export const resetAction = () => {
    return {
        type: "RESET_ACTION"
    }
}

export const addPdfLoading = () => {
    return {
        type: "ADD_PDF_LOADING"
    }
}

export const deletePdfLoading = () => {
    return {
        type: "REMOVE_PDF_LOADING"
    }
}

export const updateTableRows = (payload) => {
    return {
        type: "UPDATE_TABLE_ROWS",
        payload
    }
}

export const setStep = (payload) => {
    return {
        type: "UPDATE_CMP_STEP",
        payload
    }
}

export const updateShowPersonnalisationSimulationState = (payload) => {
    return {
        type: 'UPDATE_SHOW_PERSONNALISATION_SIMULATION_STATE',
        payload
    }
}

export const updateShowShortcutSimulationState = (payload) => {
  return {
      type: 'UPDATE_SHOW_SHORTCUT_SIMULATION_STATE',
      payload
  }
}

export const updatePostItData = (payload) => {
    return {
        type: 'UPDATE_POST_IT_DATA',
        payload
    }
}

export const updateClientList = (payload) => {
  return {
      type: 'UPDATE_CLIENT_LIST',
      payload
  }
}

export const updateListSimulationsData = (payload) => {
  return {
      type: 'UPDATE_LIST_SIMULATION_DATA',
      payload
  }
}

export const displayOrHideCalculatrice = (payload) => {
    return {
        type: 'DISPLAY_HIDE_CALCULATRICE',
        payload
    }
}

export const enableOrDisableCalculatriceKeyBoard = (payload) => {
  return {
      type: 'ENABLE_DISABLE_KEYBOARD_CALCULATRICE',
      payload
  }
}

export const updateComportementSortieSimulateurData = (payload) => {
    return {
        type: 'UPDATE_COMPORTEMENT_SORTIE_SIMULATEUR_DATA',
        payload
    }
}

export const activePageBlock = () => {
    return {
        type: 'ACTIVE_PAGE_BLOCK'
    }
}

export const desactivePageBlock = () => {
    return {
        type: 'DESACTIVE_PAGE_BLOCK'
    }
}
