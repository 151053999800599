import { useEffect, useRef } from 'react';
import './ModalRightSidebar.scss';

const ModalRightSidebar = ({ isOpen, closeSidebar, children, title, width, background, hideOverlay }) => {

  const overlayRef = useRef();

  useEffect(() => {
    const overlay = overlayRef.current;
    overlay.className = isOpen ? "modal-open" : "";
    if (hideOverlay) {
      overlay.className = isOpen ? "box-shadow-abbove modal-open" : "";
    }
  }, [isOpen]);

  const onClosesidebar = () => {
    closeSidebar();
  }

  return (
    <div ref={overlayRef} className=''>
      <div className={`modal-sidebar ${isOpen ? "open" : ""}`} style={{ width: width ? width: "auto", background: background ? background : "#f3f6f9" }}>
        <div className='sidebar-header'>
          <div onClick={() => { onClosesidebar() }} className='cross-stand-alone-container'>
            <span className="cross-stand-alone"></span>
          </div>
          <div className='sidebar-title'>
            <span>{title}</span>
          </div>
        </div>
        <div className="sidebar-content">
          {/* Sidebar content */}
          { children }
        </div>
      </div>
    </div>
  )
}

export default ModalRightSidebar;
