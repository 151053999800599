import React, { createContext, useReducer } from 'react'
import { restApiUri } from '../../config'
import checkIcon from '../../assets/icons/success.png'
import errorIcon from '../../assets/icons/error.png'
import infoIcon from '../../assets/icons/I-infos.svg'
import warningIcon from '../../assets/icons/warning.svg'
import exitSuccess from '../../assets/icons/exit_success.png'
import exitError from '../../assets/icons/exit_error.png'
import exitInfo from '../../assets/icons/exit_info.svg'

const initialState = { toasts: [], currentUser: null, sidebarShow: false/*'responsive'*/ }

export const UtilsContext = createContext(initialState)

const getToast = (id, type, title, description, autoDelete) => {
    let toastProperties = {
      id,
      title,
      description,
      autoDelete
    }
    switch(type) {
      case 'success':
        toastProperties = {
          ...toastProperties,
          title: title ? title : 'OK',
          backgroundColor: '#e2ffdf',
          color: '#677566',
          exitIcon: exitSuccess,
          icon: checkIcon
        }
        break
      case 'danger':
        toastProperties = {
          ...toastProperties,
          title: 'ERREUR',
          backgroundColor: '#ffdfdf',
          color: '#C58080',
          exitIcon: exitError,
          icon: errorIcon
        }
        break
      case 'info':
        toastProperties = {
          ...toastProperties,
          title: 'INFORMATION',
          backgroundColor: '#CCDDEB',
          icon: infoIcon,
          exitIcon: exitInfo,
          color: '#5E7384'
        }
        break
      case 'warning':
        toastProperties = {
          ...toastProperties,
          backgroundColor: '#f0ad4e',
          icon: warningIcon
        }
        break
      default:
        break
    }
    return toastProperties
}

const logout = () => {
  const id = JSON.parse(localStorage.getItem('user'))?.id

  if(id){
    fetch(`${restApiUri}/api/auth/changerEtat/`+ id, {
      method: "PUT",
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    })
    .then(resp => resp.json())
  }
}

const reducer = (state, { type, payload }) => {
    switch (type) {
        case 'LOGIN_USER':
            return {...state, currentUser: JSON.parse(localStorage.getItem("user"))}
        case 'LOGOUT_USER':
            logout()
            localStorage.clear()
            return {...state, currentUser: null}
        case 'DELETE_TOAST':
            return {...state, toasts: state.toasts.filter(toast => toast.id !== payload)}
        case 'ADD_TOAST':{
            return {...state, toasts: [...state.toasts, getToast(state.toasts.length + 1, payload.type, payload.title, payload.description, payload.autoDelete)]}
        }
        case 'TOGGLE_SIDEBAR':
            return {...state, sidebarShow: payload}
        default:
            return state
    }
}

export const UtilsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return <UtilsContext.Provider value={{ utilsState: state, utilsDispatch: dispatch }}>{children}</UtilsContext.Provider>
}
