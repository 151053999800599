import React from 'react';
import { border, LineValue } from '../TableAbattementDureeDetention/TableAbattementDureeDetention';

const TableAbattementDureeDetention2 = () => {
    return (
        <table cellPadding={4} cellSpacing={10} style={{ fontSize: 13, width: '100%' }} >
            <tr>
                <td style={{ ...border, width: '38%', background: 'rgb(132,151,176)', fontWeight: 'bold', color: '#FFFFFF', textAlign: 'center' }} >Délai de détention</td>
                <td style={{ ...border, width: '31%', background: 'rgb(132,151,176)', fontWeight: 'bold', color: '#FFFFFF', textAlign: 'center' }} >IR</td>
                <td style={{ ...border, width: '31%', background: 'rgb(132,151,176)', fontWeight: 'bold', color: '#FFFFFF', textAlign: 'center' }} >PS</td>
            </tr>

            <LineValue 
                col1="18e année"
                col2="78 %"
                col3="21,45 %"
            />
            <LineValue 
                col1="19e année"
                col2="84 %"
                col3="23,1 %"
            />
            <LineValue 
                col1="20e année"
                col2="90 %"
                col3="24,75 %"
            />
            <LineValue 
                col1="21e année"
                col2="96 %"
                col3="26,4 %"
            />
            <LineValue 
                col1="22e année"
                col2="100 %"
                col3="28 %"
            />
            <LineValue 
                col1="23e année"
                col2="100 %"
                col3="37 %"
            />
            <LineValue 
                col1="24e année"
                col2="100 %"
                col3="46 %"
            />
            <LineValue 
                col1="25e année"
                col2="100 %"
                col3="55 %"
            />
            <LineValue 
                col1="26e année"
                col2="100 %"
                col3="64 %"
            />
            <LineValue 
                col1="27e année"
                col2="100 %"
                col3="73 %"
            />
            <LineValue 
                col1="28e année"
                col2="100 %"
                col3="82 %"
            />
            <LineValue 
                col1="29e année"
                col2="100 %"
                col3="91 %"
            />
            <LineValue 
                col1="30e année"
                col2="100 %"
                col3="100 %"
            />
        </table>
    )
}

export default TableAbattementDureeDetention2;