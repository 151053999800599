import moment from "moment";
import { numberFormat } from "../../all_ppt_render";

const dataProps = {
    fontSize: 9,
    fill: 'F2F2F2',
    align: 'right',
    valign: 'middle',
    margin: [3, 10, 3, undefined],
    border: [
        {
            pt: 0,
            color: ''
        },
        {
            pt: 1,
            color: 'FFFFFF'
        },
        {
            pt: 1,
            color: 'FFFFFF'
        },
        {
            pt: 0,
            color: ''
        },
    ]
}

const dataAmortissementWithPagination = (rows, tableauAmortissementAnnualise, conditionMtIFI) => {
    let allData = [];
    let i = 0;

    if (rows.length > 24) {
        const indexes = [11, 23, 35, 47, 59, 71, 83, 95, 107, 119];
        let tab = [];

        rows.map((data, index) => {
            tab.push(data);

            if (index > 0 && (index % 23 === 0)) {
                //if(indexes.includes(index)){
                allData[i] = tab;
                tab = [];
                i += 1;
            }
        });

        let nbElement = 0;
        allData.map((data, index) => {
            nbElement += data.length;
        });

        if (nbElement !== rows.length) {
            const othersActifs = [];

            for (let j = nbElement; j < rows.length; j++) {
                othersActifs.push(rows[j]);
            }

            allData.push(othersActifs)
        }
    }
    else {
        allData = [rows];
    }

    const marginValue = !tableauAmortissementAnnualise ? 5 : 10;
    const titleMargins = [marginValue, 0, marginValue, 0]

    allData.map((data, index) => {
        let titres = [
            { text: "Échéance", options: { ...dataProps, align: 'center', fill: 'D6DCE5', color: '28366D', margin: titleMargins } },
            { text: !tableauAmortissementAnnualise ? "Date échéance" : 'Année', options: { ...dataProps, align: 'center', fill: 'D6DCE5', color: '28366D', margin: titleMargins } },
            { text: "Capital restant dû avant échéance", options: { ...dataProps, align: 'center', fill: 'D6DCE5', color: '28366D', margin: titleMargins } },
            { text: "Intérêts", options: { ...dataProps, align: 'center', fill: 'D6DCE5', color: '28366D', margin: titleMargins } },
            { text: "Capital remboursé", options: { ...dataProps, align: 'center', fill: 'D6DCE5', color: '28366D', margin: titleMargins } },
            { text: "Assurance", options: { ...dataProps, align: 'center', fill: 'D6DCE5', color: '28366D', margin: titleMargins } },
            { text: [{ text: "Échéance", options: { breakLine: true } }, { text: "hors assurance" }], options: { ...dataProps, align: 'center', fill: 'D6DCE5', color: '28366D', margin: titleMargins } },
            { text: [{ text: "Échéance", options: { breakLine: true } }, { text: "avec assurance" }], options: { ...dataProps, align: 'center', fill: 'D6DCE5', color: '28366D', margin: titleMargins } },
        ];

        if (!tableauAmortissementAnnualise) {
            titres.push(
                { text: "Capital restant dû après échéance", options: { ...dataProps, align: 'center', fill: '8497B0', color: 'FFFFFF', margin: titleMargins } },
            )
        }

        if(conditionMtIFI){
            titres.push(
                { text: "Mt déductible pour l'IFI", options: { ...dataProps, align: 'center', fill: '8497B0', color: 'FFFFFF', margin: titleMargins } }
            )
        }

        allData[index].unshift(titres);
    });

    return allData;
}

const rowsAmortissements = (donnee, tableauAmortissementAnnualise, conditionMtIFI, total) => {
    const rows = [];

    donnee.map((data, indice) => {
        let amort = [
            { text: data?.[tableauAmortissementAnnualise ? 'num_annee' : 'periode'] || "0", options: { ...dataProps, fill: 'F2F2F2', align: 'center', margin: [] } },
            { text: !tableauAmortissementAnnualise ? data.date_echeance ? moment(new Date(data.date_echeance)).format('DD/MM/YYYY') || 'JJ/MM/AAAA' : "JJ/MM/AAAA" : data?.annee || "", options: { ...dataProps, fill: 'F2F2F2' } },
            { text: `${data.capital_restant_avant ? numberFormat(data.capital_restant_avant) : '-'} €`, options: { ...dataProps, fill: 'F2F2F2' } },
            { text: `${data.interet ? numberFormat(data.interet) : '-'} €`, options: { ...dataProps, fill: 'F2F2F2' } },
            { text: `${data.capital_rembourse ? numberFormat(data.capital_rembourse) : '-'} €`, options: { ...dataProps, fill: 'F2F2F2' } },
            { text: `${data.assurance ? numberFormat(data.assurance) : '-'} €`, options: { ...dataProps, fill: 'F2F2F2' } },
            { text: `${data.echeance_hors_assurance ? numberFormat(data.echeance_hors_assurance) : '-'} €`, options: { ...dataProps, fill: 'F2F2F2' } },
            { text: `${data.echeance_avec_assurance ? numberFormat(data.echeance_avec_assurance) : '-'} €`, options: { ...dataProps, fill: 'F2F2F2' } },
        ];

        if (!tableauAmortissementAnnualise) {
            amort.push(
                { text: `${data.capital_restant ? numberFormat(data.capital_restant) : '-'} €`, options: { ...dataProps, fill: 'F2F2F2' } }
            )
        }

        if(conditionMtIFI){
            amort.push(
                { text: `${data.montant_deductible ? numberFormat(data.montant_deductible) : '-'} €`, options: { ...dataProps, fill: 'F2F2F2' } }
            )
        }

        rows.push(amort);
    })

    // Ligne de total
    const ligneTotal = [
        { text: "", options: { ...dataProps, fill: 'FFFFFF', colspan: 3 } },
        { text: `${numberFormat(total?.interet || 0)} €`, options: { ...dataProps, color: 'FFFFFF', fill: '9C9C9C', bold: true } },
        { text: `${numberFormat(total?.capital_rembourse || 0)} €`, options: { ...dataProps, color: 'FFFFFF', fill: '9C9C9C', bold: true } },
        { text: `${numberFormat(total?.assurance || 0)} €`, options: { ...dataProps, color: 'FFFFFF', fill: '9C9C9C', bold: true } },
        { text: `${numberFormat(total?.echeance_hors_assurance || 0)} €`, options: { ...dataProps, color: 'FFFFFF', fill: '9C9C9C', bold: true } },
        { text: `${numberFormat(total?.echeance_avec_assurance || 0)} €`, options: { ...dataProps, color: 'FFFFFF', fill: '9C9C9C', bold: true } }
    ];
    
    if(!tableauAmortissementAnnualise){
        ligneTotal.push({ text: "", options: { ...dataProps, fill: 'FFFFFF' } })
    }

    if(conditionMtIFI){
        ligneTotal.push({ text: "", options: { ...dataProps, fill: 'FFFFFF' } })
    }

    rows.push(ligneTotal)

    return dataAmortissementWithPagination(rows, tableauAmortissementAnnualise, conditionMtIFI);
}

export default rowsAmortissements;