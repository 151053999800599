import React from 'react';
import { GreenBlock } from '../../PageHypothesePvImmo/Synthese/Synthese';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import EmojiVert from '../../../../../assets/images/svg/PvImmo/EMOJI_VERT.svg';
import EmojiJaune from '../../../../../assets/images/svg/PvImmo/EMOJI_JAUNE.svg';
import EmojiRouge from '../../../../../assets/images/svg/PvImmo/EMOJI_ROUGE.svg';
import { CImg } from '@coreui/react';
import { numberFormat } from '../../../../../extra/all_ppt_render';

const style = {
    vert: {
        width: '28px',
        height: '5px',
        borderRadius: '2px',
        backgroundColor: 'rgb(84, 169, 80)',
        marginTop: 2,
        marginBottom: 2
    },
    bloc1: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: 15,
        borderRadius: '13px',
        boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
        marginBottom: 10
    },
    texteGris: {
        fontSize: 13,
        color: 'rgb(129,133,145)',
        marginBottom: 3
    },
    texteVert: {
        fontSize: 16,
        color: 'rgb(26,166,107)',
        fontWeight: 'bold'
    },
    texteOrange: {
        fontSize: 16,
        color: 'rgb(255,72,72)',
        fontWeight: 'bold'
    },
    texteNoir: {
        fontWeight: 'bold',
        fontSize: 14,
        width: '60%',
        textAlign: 'right',
        marginBottom: 7
    }
}

const LineIR = ({ value, label }) => {
    return (
        <div style={{ display: 'flex', width: '100%', marginBottom: 5 }} >
            <div style={{ width: '80px', fontWeight: 'bold', display: 'flex', justifyContent: 'flex-end', textAlign: 'right' }} >{value} €</div>
            <div style={{ width: '20%', color: 'rgb(129,133,145)', marginLeft: '5%', fontSize: 11, display: 'flex', flexDirection: 'column', justifyContent: 'center' }} >{label}</div>
        </div>
    )
}

const natureBienCede = (type, index) => {
    if (type === 2) return <>Cession de parts de SCI à l'IR</>;
    else {
        if (index === 0) return <>Autres biens immobiliers<br />(hors résidence principale)</>;
        else if (index === 1) return "Terrains à bâtir";
        else if(index === 2) return "Bois et forêts";
        else return "Construction";
    }
}

const BlocComparaison = ({ hypothese, type, data, emoji }) => {
    return (
        <div style={{ fontFace: 'Roboto' }} >
            <div style={{ marginBottom: 15 }} >
                <div style={{ fontWeight: 'bold', fontSize: 13 }} >{type === 0 ? 'CESSION DIRECTE' : type === 1 ? 'CESSION INDIRECTE' : "CESSION DE PARTS DE SCI SOUMISE À L'IR"}</div>
                <div style={style.vert} ></div>
                <div style={{ fontSize: 12, height: '25px', visibility: type !== 2 ? 'visible' : 'hidden' }} >{type === 0 ? "Cession d'un actif immobilier détenu à titre personnel" : type === 1 ? "Cession d'un actif immobilier détenu au travers d'une SCI soumise à l'IR" : "A"}</div>
            </div>

            <div style={{ ...style.bloc1, background: '#FFFFFF', marginTop: 10, paddingTop: 20 }} >
                <div style={{ display: 'flex', justifyContent: 'space-between' }} >
                    <div style={{ width: '50%' }} >
                        <div style={style.texteGris} >Plus-value brute</div>
                        <div style={style.texteVert} >
                            {
                                data.plus_value_brute ? numberFormat(data.plus_value_brute) : 0
                            } €
                        </div>
                        <div style={{ background: 'rgb(196,202,214)', width: '30%', height: '3px', marginTop: 5, marginBottom: 10 }} ></div>
                        <div style={{ ...style.texteGris, fontSize: 11 }} >Prix de cession corrigé</div>
                        <div style={style.texteNoir} >
                            {
                                data.prix_cession_corrige ? numberFormat(data.prix_cession_corrige) : 0
                            } €
                        </div>
                        <div style={{ ...style.texteGris, fontSize: 11 }} >Prix d'acquisition corrigé</div>
                        <div style={style.texteNoir} >
                            {
                                data.prix_acquisition_corrige ? numberFormat(data.prix_acquisition_corrige) : 0
                            } €
                        </div>
                    </div>
                    <div style={{ width: '50%' }} >
                        <div style={style.texteGris} >Nature du bien cédé</div>
                        <div><GreenBlock width="100%" height='55px' texte={data.nature_bien_cede !== undefined ? natureBienCede(type, data.nature_bien_cede) : ""} /></div>
                        <div style={{ ...style.texteGris, marginTop: 12, fontSize: 13 }} >Nb d'années de détention</div>
                        <div style={{ fontSize: '16px', fontWeight: 'bold' }} >
                            {
                                (hypothese.nature_bien_cede !== 3) ? (
                                    <>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }} >
                                            <div>
                                                {
                                                    data.nb_annee_pleines_detention &&
                                                    data.nb_annee_pleines_detention[0] &&
                                                    data.nb_annee_pleines_detention[0].annee ? data.nb_annee_pleines_detention[0].annee : 0
                                                }
                                            </div>
                                            <div style={{ textAlign: 'left', fontSize: 13 }} >&nbsp;</div>
                                        </div>

                                        <div style={{ display: 'flex', justifyContent: 'space-between' }} >
                                            <div>&nbsp;</div>
                                            <div style={{ textAlign: 'left', fontSize: 13 }} >&nbsp;</div>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        {/** Terrain */}
                                        <div style={{ display: 'flex', marginBottom: 10 }} >
                                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', fontSize: 11, color: 'rgb(129,133,145)', fontWeight: 'normal', width: '45%' }} >Terrain</div>
                                            <div style={{ width: '20%', textAlign: 'right' }} >
                                                {
                                                    data.nb_annee_pleines_detention &&
                                                    data.nb_annee_pleines_detention[0] &&
                                                    data.nb_annee_pleines_detention[0].annee ? data.nb_annee_pleines_detention[0].annee : 0
                                                }
                                            </div>
                                        </div>

                                        {/** Construction */}
                                        <div style={{ display: 'flex' }} >
                                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', fontSize: 11, color: 'rgb(129,133,145)', fontWeight: 'normal', width: '45%' }} >Construction</div>
                                            <div style={{ width: '20%', textAlign: 'right' }} >
                                                {
                                                    data.nb_annee_pleines_detention &&
                                                    data.nb_annee_pleines_detention[1] &&
                                                    data.nb_annee_pleines_detention[1].annee ? data.nb_annee_pleines_detention[1].annee : 0
                                                }
                                            </div>
                                        </div>
                                    </>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>

            <div style={{ ...style.bloc1, background: '#FFFFFF' }} >
                <div style={{ display: 'flex' }} >
                    <div style={{ width: '60%' }} >
                        <div style={style.texteGris} >Impositions sur la PV immobilière</div>
                        <div style={style.texteOrange} >
                            {
                                data.impositions_plus_value_immo ? numberFormat(data.impositions_plus_value_immo) : 0
                            } €
                        </div>
                        <div style={{ background: 'rgb(196,202,214)', width: '25%', height: '3px', marginTop: 5, marginBottom: 10 }} ></div>
                        <div>
                            <LineIR value={data.ir ? numberFormat(data.ir) : 0} label="IR" />
                            <LineIR value={data.ps ? numberFormat(data.ps) : 0} label="PS" />
                            <LineIR value={data.sur_taxe ? numberFormat(data.sur_taxe) : 0} label="Surtaxe" />
                            <LineIR value={data.cehr ? numberFormat(data.cehr) : 0} label="CEHR" />
                        </div>
                    </div>
                    <div style={{ width: '40%', position: 'relative' }} >
                        <div style={{ width: 80, height: 80, marginTop: 30, }} >
                            <CircularProgressbarWithChildren
                                value={data.rapport_impot_plus_value_brute ? parseFloat(data.rapport_impot_plus_value_brute).toFixed(2) : 0}
                                styles={buildStyles({
                                    textColor: "#F16360",
                                    pathColor: "#F16360",
                                    trailColor: "#E1B3BC",
                                    textSize: 20

                                })}>
                                <strong style={{ fontSize: '16px', color: '#F16360' }}>{`${data.rapport_impot_plus_value_brute ? parseFloat(data.rapport_impot_plus_value_brute).toFixed(2) : 0} %`}</strong>
                            </CircularProgressbarWithChildren>
                        </div>
                        <div style={{ ...style.texteGris, marginTop: 5, textAlign: 'center', position: 'absolute', left: "-9%" }} >
                            Rapport impôts<br />
                            sur plus-value brute
                        </div>
                    </div>
                </div>
            </div>

            <div style={{ ...style.bloc1, background: '#FFFFFF' }} >
                <div style={{ display: 'flex', justifyContent: 'space-between' }} >
                    <div>
                        <div style={style.texteGris} >Prix net de cession</div>
                        <div style={style.texteVert} >
                            {
                                data.prix_net_cession ? numberFormat(data.prix_net_cession) : 0
                            } €
                        </div>
                    </div>
                    {
                        emoji && (
                            <div>
                                <CImg src={emoji === 'HAUT' ? EmojiVert : emoji === 'INTERMEDIAIRE' ? EmojiJaune : EmojiRouge} style={{ width: 60 }} />
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default BlocComparaison;