import React from 'react';

const ElementCalculatrice = ({ element, onRequestElementClick, indexElement }) => {
    return (
        <div
            className={
              `element-calculatrice${[0, 1, 2, 3, 4, 13, 14, 18, 19].includes(indexElement) ? '-operation' : ''}
              calculator-element-container-item
              element-calculatrice${[8, 9].includes(indexElement) ? '-operation-orange' : ''}
            `}
            style={{
                // marginRight: !['/', 'X', '-', '+', '=', '*'].includes(element.key) ? 10 : 0,
                background: element.key === '='&& 'transparent linear-gradient(180deg, #18A9CD 0%, #1898C5 24%, #1A6DB0 71%, #1C50A2 100%)'
            }}
            onClick={onRequestElementClick}
        >{element.value}
        </div>
    )
}

export default ElementCalculatrice;
