import React from 'react';

export const leftBackground = 'rgb(214,220,229)';
export const rightBackground = '#E7EAEF';
export const plusValueBackground = '#EEFCE5';
export const WhiteTableSpace = () => {
    return (
        <tr>
            <td></td>
            <td></td>
            <td></td>
        </tr>
    )
}

const TableRevenuFoncier = () => {
    return (
        <table cellPadding={10} cellSpacing={10} style={{ width: '100%', textAlign: 'left' }} >
            <tr style={{ marginBottom: 20 }} >
                <td style={{ fontWeight: 'bold', background: leftBackground, textAlign: 'center', width: '10%' }} >Recettes</td>
                <td style={{ width: '2%' }} ></td>
                <td style={{ background: rightBackground, width: '85%' }} >Les loyers pris en compte sont les loyers <b>encaissés au cours de l’année d’imposition</b>, même s’ils se rapportent à une autre année.</td>
            </tr>
            <WhiteTableSpace />
            <tr>
                <td style={{ fontWeight: 'bold', background: leftBackground, textAlign: 'center' }} >
                    <div style={{ marginBottom: 10 }} >-</div>
                    <div>Charges déductibles</div>
                </td>
                <td></td>
                <td style={{ background: rightBackground }} >
                    <div style={{ marginBottom: 10 }} >
                        <div style={{ fontWeight: 'bold', textDecoration: 'underline' }} >Régime micro foncier</div>
                        <div>Régime applicable par principe si les recettes locatives du foyer fiscal sont inférieures à 15.000€.</div>
                        <div>Les recettes sont diminuées d’un <b>abattement de 30%</b>.</div>
                    </div>
                    <div>
                        <div style={{ fontWeight: 'bold', textDecoration: 'underline' }} >Régime réel</div>
                        <div>Régime applicable sur option si les recettes locatives du foyer fiscal sont inférieures à 15.000€, et applicable par principe au-delà.</div>
                        <div>Les recettes sont diminuées des charges réellement décaissées pendant l’année d’imposition :</div>
                        <ul>
                            <li>Travaux de réparation, entretien et amélioration ;</li>
                            <li>Intérêts et frais d’emprunt ;</li>
                            <li>Charges de copropriété, frais de gestion, primes d’assurance, taxes foncières.</li>
                        </ul>
                    </div>
                </td>
            </tr>
            <WhiteTableSpace />
            <tr>
                <td style={{ fontWeight: 'bold', background: leftBackground, textAlign: 'center' }} >
                    <div style={{ marginBottom: 10 }} >=</div>
                    <div>Net</div>
                </td>
                <td style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', paddingTop: 0 }} >
                    <div style={{ background: 'rgb(237,125,49)', borderRadius: 10, width: '5px', height: '100px', marginBottom: 5 }} ></div>
                    <div style={{ background: 'rgb(101,191,127)', borderRadius: 10, width: '5px', height: '40px' }} ></div>
                </td>
                <td style={{ background: rightBackground }} >
                    <div style={{ marginBottom: 10 }} >
                        <div style={{ fontWeight: 'bold', textDecoration: 'underline' }} >Déficit</div>
                        <ul>
                            <li>
                                Imputé <b>sur le revenu global</b> jusqu’à 10.700€ (hors déficit lié aux intérêts d’emprunt)<br />
                                <div style={{ fontStyle: 'italic' }}>Le bien doit être loué nu jusqu’au 31 décembre de la 3e année suivant l’imputation du déficit</div>
                            </li>
                            <li>Imputé <b>sur les revenus fonciers des 10 années suivantes</b> pour la fraction du déficit {'>'} 10.700€ ou liée aux intérêts d’emprunt</li>
                        </ul>
                    </div>
                    <div>
                        <div style={{ fontWeight: 'bold', textDecoration: 'underline' }} >Bénéfice</div>
                        <div>Soumis au barème progressif de l’IR (0% à 45%) et aux prélèvements sociaux (17,2%).</div>
                    </div>
                </td>
            </tr>
            <WhiteTableSpace />
            <tr>
                <td style={{ fontWeight: 'bold', background: 'rgb(226,240,217)', textAlign: 'center' }} >
                    <div>Plus-value</div>
                </td>
                <td></td>
                <td style={{ background: plusValueBackground }} >
                    En cas de vente d’un bien source de revenus fonciers, l’imposition relève du régime des <b>plus-values immobilières des particuliers</b>.
                </td>
            </tr>
        </table>
    )
}

export default TableRevenuFoncier;