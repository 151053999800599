export const dataFiscale1 = [
    {
        annee: new Date(1996, 3, 1),
        rappelFiscal: 10
    },
    {
        annee: new Date(2006, 0, 1),
        rappelFiscal: 6   
    },
    {
        annee: new Date(2011, 7, 1),
        rappelFiscal: 10  
    },
    {
        annee: new Date(2012, 7, 16),
        rappelFiscal: 15   
    }
]

export const dataFiscale2 = [
    {
        annee: new Date(2001, 0, 1),
        abattementParentEnfant: {
            enfant: 300000,
            petitEnfant: 100000,
        },
        abattementDonataireHandicape: 300000,
        limiteSuperieure5: 50000,
        limiteSuperieure10: 75000,
        limiteSuperieure15: 100000,
        limiteSuperieure20: 3400000,
        limiteSuperieure30: 5600000,
        limiteSuperieure3540: 11200000
    },
    {
        annee: new Date(2002, 0, 1),
        abattementParentEnfant: {
            enfant: 46000,
            petitEnfant: 15000
        },
        abattementDonataireHandicape: 46000,
        limiteSuperieure5: 7600,
        limiteSuperieure10: 11400,
        limiteSuperieure15: 15000,
        limiteSuperieure20: 520000,
        limiteSuperieure30: 850000,
        limiteSuperieure3540: 1700000
    },
    {
        annee: new Date(2005, 0, 1),
        abattementParentEnfant: {
            enfant: 50000,
            petitEnfant: 30000,
        },
        abattementDonataireHandicape: 50000,
        limiteSuperieure5: 7600,
        limiteSuperieure10: 11400,
        limiteSuperieure15: 15000,
        limiteSuperieure20: 520000,
        limiteSuperieure30: 850000,
        limiteSuperieure3540: 1700000
    },
    {
        annee: new Date(2007, 7, 22),
        abattementParentEnfant: {
            enfant: 150000,
            petitEnfant: 30000
        },
        abattementDonataireHandicape: 150000,
        limiteSuperieure5: 7600,
        limiteSuperieure10: 11400,
        limiteSuperieure15: 15000,
        limiteSuperieure20: 520000,
        limiteSuperieure30: 850000,
        limiteSuperieure3540: 1700000
    },
    {
        annee: new Date(2008, 0, 1),
        abattementParentEnfant: {
            enfant: 151950,
            petitEnfant: 30390
        },
        abattementDonataireHandicape: 151950,
        limiteSuperieure5: 7699,
        limiteSuperieure10: 11548,
        limiteSuperieure15: 15195,
        limiteSuperieure20: 526760,
        limiteSuperieure30: 861050,
        limiteSuperieure3540: 1722100
    },
    {
        annee: new Date(2009, 0, 1),
        abattementParentEnfant: {
            enfant: 156359,
            petitEnfant: 31272
        },
        abattementDonataireHandicape: 156359,
        limiteSuperieure5: 7922,
        limiteSuperieure10: 11883,
        limiteSuperieure15: 15636,
        limiteSuperieure20: 542043,
        limiteSuperieure30: 866032,
        limiteSuperieure3540: 1722064
    },
    {
        annee: new Date(2010, 0, 1),
        abattementParentEnfant: {
            enfant: 156974,
            petitEnfant: 31395
        },
        abattementDonataireHandicape: 156794,
        limiteSuperieure5: 7953,
        limiteSuperieure10: 11930,
        limiteSuperieure15: 15697,
        limiteSuperieure20: 544173,
        limiteSuperieure30: 889514,
        limiteSuperieure3540: 1779029
    },
    {
        annee: new Date(2011, 0, 1),
        abattementParentEnfant: {
            enfant: 159325,
            petitEnfant: 31865
        },
        abattementDonataireHandicape: 159325,
        limiteSuperieure5: 8072,
        limiteSuperieure10: 12109,
        limiteSuperieure15: 15932,
        limiteSuperieure20: 552324,
        limiteSuperieure30: 902838,
        limiteSuperieure3540: 1805677
    },
    {
        annee: new Date(2012, 7, 17),
        abattementParentEnfant: {
            enfant: 100000,
            petitEnfant: 31865
        },
        abattementDonataireHandicape: 0,
        limiteSuperieure5: 0,
        limiteSuperieure10: 0,
        limiteSuperieure15: 0,
        limiteSuperieure20: 0,
        limiteSuperieure30: 0,
        limiteSuperieure3540: 0
    }
];

export const tarifDMTG = [ 5, 10, 15, 20, 30, 40, 45 ];

export const tauxConversionFRF = 6.55957;

export const abattementSuccessionPetitEnfant = 1594;

export const dataFiscale3 = [ 5/100, 10/100, 15/100, 20/100, 30/100, 40/100, 45/100 ]

export const dataFiscale4 = [
    {
        annee: new Date(2006,0,1),
        seuil: 30000
    },
    {
        annee: new Date(2008,0,1),
        seuil: 30390
    },
    {
        annee: new Date(2009,0,1),
        seuil: 31272
    },
    {
        annee: new Date(2010,0,1),
        seuil: 31395
    },
    {
        annee: new Date(2011,0,1),
        seuil: 31865
    }
]

export const couleurStandard = 'rgb(118,129,146)';
export const couleurRouge = 'rgb(255,0,0)';