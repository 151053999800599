import { createContext, useReducer } from "react"

export const TheHeaderContext = createContext({})

const reducer = (state, {type, payload}) => {
  switch (type) {
    case 'UPDATE_CLIENT_LIST':
        return { ...state, client: payload }
    case 'REFRESH_HEADER':
        return { ...state }
    default:
      return state;
  }
}

export const HeaderProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, {})

  return <TheHeaderContext.Provider value={{ clientListState: state, headerDispatch: dispatch }}>{children}</TheHeaderContext.Provider>
}
