import AbboveImage from '../../assets/images/Abbove_image.png';
import './abbove-style.scss';

const AbboveDecouverte = () => {
  return (
    <>
      <div className="abbove-container">
        <div className="abbove-container-content">
          <h2>Faites-en plus avec Abbove</h2>
          <div>
            <span>Devenez le référent patrimonial de vos clients.</span>
          </div>
          <h3>Soyez connecté avec votre client et son patrimoine</h3>
          <div>
            <img height="300px" src={AbboveImage} alt="Abbove" />
          </div>
          <div style={{ fontWeight: 'bold', marginTop: 20 }}>
            <div>
              Abbove est la plateforme d’accompagnement patrimonial collaborative qui place
            </div>
            <div>
              votre expertise au coeur de la stratégie et des choix patrimoniaux des clients-clés.
            </div>
          </div>
          <div>
            Cliquez sur le bouton ci-dessous si vous souhaitez<br /> recevoir plus d’informations et activer cet outil.
          </div>
          <div style={{ marginTop: 40 }}>
            <a href="https://fr.abbove.com/" target='_blank' rel='noreferrer' className="abbove-btn">Accédez à Abbove</a>
          </div>
        </div>
      </div>
    </>
  )
}

export default AbboveDecouverte;
