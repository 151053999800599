import React, { Component } from "react";
import NumberFormat from 'react-number-format';
import { CInput } from '@coreui/react';

const CustomInput = (props) => {
    const { className, ...inputProps } = props
    return (
        <div className={className} style={{padding: 0}}>
            <CInput {...inputProps} style={{ width: '100%', paddingRight: 30, textAlign: 'right', paddingLeft:19, fontSize:13, ...inputProps.style }}/>
            <span style={{position: "absolute", right: 16, top: 7, color: inputProps && inputProps.style && inputProps.style.color ? inputProps.style.color : "#768192"}}>€</span>
        </div>
    )
}

export default class InputCurrencySuccession extends Component {
    render() {
        return(
            <NumberFormat
                id={this.props.id}
                customInput={CustomInput}
                style={{...this.props.style}}
                decimalScale={2}
                isAllowed={(values) => {
                    const {value} = values;
                    return this.props.min? value > this.props.min : value >= 0 || value == '' || value == '.';
                }}
                allowNegative={this.props.allowNegative ? this.props.allowNegative : false}
                disabled={this.props.disabled}
                onKeyDown={event=> {
                    if (event.keyCode === 13) {
                        const form = event.target.form;
                        let index = Array.prototype.indexOf.call(form, event.target);
                        if(form.elements[index + 1])
                            form.elements[index + 1].focus();
                        event.preventDefault();
                    }
                }}
                invalid={this.props.invalid}
                // value={this.props.value == 0? '' : this.props.value == '.'? '0.' : this.props.value}
                value={this.props.value == '.'? '0.' : this.props.value}
                onChange={this.props.onChange}
                className= {this.props.className? this.props.className : "col-lg-2"}
                thousandSeparator={' '}
            />
        )
    }
}