import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
    root: {
        /*margin: 0,
        padding: 0,*/
        paddingTop: 0,
        paddingLeft: 0,
        display: 'flex',
        justifyContent: 'space-between',
        color: 'rgb(89,92,99)',
        //width: '100%'
    },

    child: {
        width: '9%',
        //background: 'rgb(241,241,241)',
        borderRight: '2px solid rgb(241,241,241)',
        borderBottom: '2px solid rgb(241,241,241)',
        fontWeight: 'bold',
        textAlign: 'center'
    },
});

export default useStyles;