const moveSpaceIntoCurrency = (value) => {
    if (!value) {
        return 0;
    }

    let valeur = '';
    for (let i = 0; i < value.length; i++) {
        if (!isNaN(parseInt(value[i]))) valeur += value[i];
    }

    return parseInt(valeur, 10);
};

export const currencyToSend = (currency) => {
    return typeof currency === 'string' ? (currency === "" ? 0 : moveSpaceIntoCurrency(currency)) : currency;
};

export const isNumeric = (value) => {
  return /^-?\d+$/.test(value);
}
