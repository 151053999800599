import { CCol, CForm, CFormGroup, CLabel, CRow } from '@coreui/react';
import moment from 'moment';
import React, { useContext } from 'react';
import DataBlock from '../../../../components/DataBlock/DataBlock';
import { InputDate } from '../../../../components/input';
import { DataControlContext } from '../../../../store/context/dataControl';
import Bloc1 from './Bloc1/Bloc1';
import Bloc2 from './Bloc2/Bloc2';
import Bloc3 from './Bloc3/Bloc3';
import Bloc32 from './Bloc32/Bloc32';
import Bloc33 from './Bloc33/Bloc33';
import './style.scss';
import { formatToUniqueId } from '../../../../utils';
import EmpruntDataBlock from '../../../../components/DataBlock/EmpruntDataBlock';

const ChiffreCle = (props) => {
    const { data, withoutCalcul } = props;

    const { empruntState, setEmpruntState } = useContext(DataControlContext);
    const {
        montant_emprunt,
        taux_annuel,
        duree,
        date_emprunt,
        type_emprunt,
        type_amortissement,
        periode_echeance,
        nb_periode,
        taux_deces,
        taux_prime,
        assiette_prime,
        frais_dossier_en_pourcentage,
        frais_dossier_en_euro
    } = empruntState;

    return (
        <EmpruntDataBlock
            title="CHIFFRES CLÉS"
            // titleHeight="100px"
            defaultDisplay={true}

        >
            <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 15 }}>
              <div style={{ display: 'flex', flexDirection: 'row', gap: 25 }}>
                  {/** Date de l'emprunt */}
                  <CFormGroup className="form-inline m-0" style={{ display: 'flex', flexDirection: 'row', gap: 10, justifyContent: 'space-between'}}>
                      <CLabel style={{ fontSize: 13, fontWeight: 'normal' }}>Date de l'emprunt</CLabel>
                      <InputDate
                          id={`${formatToUniqueId("ChifreCle date_emprunt t")}-date`}
                          disabled={true}
                          className="col-lg-2 col-md-5 col-sm-5"
                          value={data && data.date_emprunt ? moment(new Date(data.date_emprunt)).format('DD/MM/YYYY') : ""}
                      />
                  </CFormGroup>

                  {/** Terme de l'emprunt */}
                  <CFormGroup className="form-inline m-0" style={{ display: 'flex', flexDirection: 'row', gap: 10, justifyContent: 'space-between' }}>
                      <CLabel style={{ fontSize: 13, fontWeight: 'normal', marginRight: 5 }}>Terme de l'emprunt</CLabel>
                      <InputDate
                          id={`${formatToUniqueId("ChifreCle date_emprunt tt")}-date`}
                          disabled={true}
                          className="col-lg-2 col-md-5 col-sm-5"
                          value={data && data.terme_emprunt ? moment(new Date(data.terme_emprunt)).format('DD/MM/YYYY') : ""}
                      />
                  </CFormGroup>
              </div>
            </div>
            <div className='racineBloc'>
                <div className='div1' >
                    <Bloc1
                        data={data}
                    />
                </div>
                <div className='div2' >
                    <Bloc2
                        data={data}
                    />
                </div>
                <div className='div3' >
                    {
                        // Premier cas
                        (type_emprunt === 2 || (type_emprunt === 0 && nb_periode === 0)) && (
                            <Bloc3
                                withoutCalcul={withoutCalcul}
                                data={data}
                            />
                        )
                    }

                    {
                        // 2nd cas
                        (type_emprunt === 0 && nb_periode > 0) && (
                            <Bloc32
                                withoutCalcul={withoutCalcul}
                                data={data}
                            />
                        )
                    }

                    {
                        // 3ème cas
                        (type_emprunt === 1) && (
                            <Bloc33
                                withoutCalcul={withoutCalcul}
                                data={data}
                            />
                        )
                    }
                </div>
            </div>
        </EmpruntDataBlock>
    )
}

export default ChiffreCle;
