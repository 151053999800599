import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { deleteToast } from '../../store/actions/utils/toast'
import { UtilsContext } from '../../store/context/utils'
import './index.css';
import { CImg } from '@coreui/react';

const Toast = props => {
    
    const {utilsState,utilsDispatch} = useContext(UtilsContext)
    const { position, autoDelete, dismissTime } = props

    useEffect(() => {
        const interval = setInterval(() => {
            if (autoDelete && utilsState.toasts.length > 0) {
                utilsDispatch(deleteToast(utilsState.toasts[0].id))
            }
        }, dismissTime);
        
        return () => {
           utilsState.toasts.length > 0 && clearInterval(interval)
        }

        // eslint-disable-next-line
    }, [utilsState.toasts, autoDelete, dismissTime])

    return (
        <>
            <div className={`notification-container ${position}`}>
                {
                    utilsState.toasts.map((toast, i) =>     
                        <div 
                            key={i}
                            className={`notification toast ${position}`}
                            style={{ backgroundColor: toast.backgroundColor, width: 500 }}
                        >
                            <div className="notification-image">
                                <img src={toast.icon} alt="" />
                            </div>
                            <div style={{flex: 1}}>
                                <p className="notification-title" style={toast.color? {color: toast.color} : {}}>{toast.title}</p>
                                <p className="notification-message" style={toast.color? {color: toast.color} : {}}>
                                    {toast.description}
                                </p>
                            </div>
                            {
                                toast.exitIcon &&
                                <CImg className='button' src={toast.exitIcon} style={{width: 20}} onClick={() => utilsDispatch(deleteToast(toast.id))} />
                            }
                            {
                                !toast.exitIcon &&
                                <button onClick={() => utilsDispatch(deleteToast(toast.id))}>
                                    X
                                </button>
                            }
                        </div>
                    )
                }
            </div>
        </>
    );
}

Toast.propTypes = {
    position: PropTypes.string,
    autoDelete: PropTypes.bool,
    dismissTime: PropTypes.number
}

export default Toast;