const baseURL = process.env.REACT_APP_URL;

// Utility function to handle HTTP response status
const handleResponse = async (response) => {
  if (!response.ok) {
    const error = await response.text();
    throw new Error(error);
  }
  return response.json();
};

const CustomExportPptService = {
  addPpt: async (data) => {
    try {
      const response = await fetch(`${baseURL}/api/affichagePpt`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      return handleResponse(response);
    } catch (error) {
      console.error('Error adding PPT:', error);
      throw error;
    }
  },

  findByUserAndType: async (type, userId) => {
    try {
      const response = await fetch(`${baseURL}/api/affichagePpt/findAllByUserAndType?type=${type}&userId=${userId}`, {
        method: 'GET',
      });
      return handleResponse(response);
    } catch (error) {
      console.error('Error finding by user and type:', error);
      throw error;
    }
  },

  findOnePpt: async (id) => {
    try {
      const response = await fetch(`${baseURL}/api/affichagePpt/${id}`, {
        method: 'GET',
      });
      return handleResponse(response);
    } catch (error) {
      console.error('Error finding one PPT:', error);
      throw error;
    }
  },

  updatePpt: async (id, updatedData) => {
    try {
      const response = await fetch(`${baseURL}/api/affichagePpt/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedData),
      });
      return handleResponse(response);
    } catch (error) {
      console.error('Error updating PPT:', error);
      throw error;
    }
  },

  deletePpt: async (id) => {
    try {
      const response = await fetch(`${baseURL}/api/affichagePpt/${id}`, {
        method: 'DELETE',
      });
      return handleResponse(response);
    } catch (error) {
      console.error('Error deleting PPT:', error);
      throw error;
    }
  },
};

export default CustomExportPptService;

