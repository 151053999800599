import React, { useEffect, useState } from 'react';
import { numberFormat } from '../../../../../extra/all_ppt_render';
import BlocResultatDetaille from './BlocResultatDetaille/BlocResultatDetaille';

const padding = 10;
const paddingLeft = 20;

const Title = () => {
    return (
        <div style={{ display: 'flex', paddingRight: 20, background: 'rgb(244,244,244)' }} >
            <div style={{ width: '50%' }} ></div>
            <div style={{ width: '16.67%', padding: 10, color: 'rgb(68,84,106)', textAlign: 'right', fontWeight: 'bold' }} >Terrain</div>
            <div style={{ width: '16.67%', padding: 10, color: 'rgb(68,84,106)', textAlign: 'right', fontWeight: 'bold' }} >Construction</div>
        </div>
    )
}

const Commentaire = ({ values, value }) => {
    return (value || values.length > 0) && (
        <div style={{ borderRadius: '8px 8px 8px 8px' }} >
            <div style={{ paddingLeft: paddingLeft, paddingTop: padding, paddingBottom: padding, background: '#FFFFFF', fontWeight: 'bold' }} >&nbsp;</div>
            <div style={{ color: 'rgb(169,154,149)', padding: 10, background: 'rgb(254,246,235)', display: 'flex', flexDirection: 'column', justifyContent: 'center' }} >
                <div style={{ fontWeight: 'bold', marginBottom: 5 }} >Commentaire</div>
                <ul style={{ paddingLeft: 15,  }} >
                {
                    values &&
                    values.map((data, index) => {
                        return (
                            <li style={{ marginBottom: (values.length - 1 === index) ? 0 : 3 }} >{data}</li>
                        )
                    })
                }
                </ul>
                {
                    value && (
                        <div>{value}</div>
                    )
                }
            </div>
        </div>
    )
}

const Fiscalite = ({
    hypothese,
    ir,
    ps,
    surtaxe,
    cehr,
    ifi
}) => {
    const boldOrange = 'rgb(255,72,72)';
    const fillOrange = 'rgb(255,223,224)';
    const marginBetweenBlock = 20;

    const marginBetweenCadre = 7;
    const marginLastCadre = 0.1;

    const conditionSurAbattementApplicable = [70, 85].includes(hypothese.abattemennt_exceptionnel);
    // const dataC51 = ir.plus_value_nette_imposable_ir ? ir.plus_value_nette_imposable_ir : 0;
    const dataC51 = surtaxe.plus_value_imposable ? surtaxe.plus_value_imposable : 0;

    // Impôts sur les revenus
    const [bloc1IR, setBloc1IR] = useState([
        {
            label: "Abattement pour durée de détention",
            value: ir.abattement_duree_detention ? numberFormat(ir.abattement_duree_detention) : 0,
            value2: ir.abattement_duree_detention_construction ? numberFormat(ir.abattement_duree_detention_construction) : 0,
            withPercent: true,
            unite: '€'
        }
    ]
    )

    // PS
    const [bloc1PS, setBloc1PS] = useState([
        {
            label: "Abattement pour durée de détention",
            value: ps.abattement_duree_detention ? numberFormat(ps.abattement_duree_detention) : 0,
            value2: ps.abattement_duree_detention_construction ? numberFormat(ps.abattement_duree_detention_construction) : 0,
            withPercent: true,
            unite: '€'
        },
        {
            label: "Abattement exceptionnel",
            value: ps.abattement_exceptionnel ? numberFormat(ps.abattement_exceptionnel) : 0,
            value2: ps.abattement_exceptionnel_construction ? numberFormat(ps.abattement_exceptionnel_construction) : 0,
            withPercent: true,
            unite: '€'
        },
        {
            label: "Taux global d'abattement",
            value: ps.taux_global_abattement ? parseFloat(ps.taux_global_abattement).toFixed(2) : 0,
            value2: ps.taux_global_abattement_construction ? parseFloat(ps.taux_global_abattement_construction).toFixed(2) : 0,
            unite: "%"
        },
    ]
    )

    // CEHR
    const [blocCEHR, setBlocCEHR] = useState([
        {
            label: "Seuil d'imposition à la CEHR",
            value: cehr.seuil_imposition_cehr ? numberFormat(cehr.seuil_imposition_cehr) : 0,
            unite: '€'
        },
        {
            label: "Revenu fiscal de référence hors plus-value immobilière",
            value: cehr.rfr_annee_hors_pv ? numberFormat(cehr.rfr_annee_hors_pv) : 0,
            unite: '€'
        },
        {
            label: "Réintégration plus-value immobilière",
            value: cehr.reintegration_plus_value_immo ? numberFormat(cehr.reintegration_plus_value_immo) : 0,
            unite: '€'
        },
        {
            label: "Revenu fiscal de référence avec plus-value immobilière",
            value: cehr.rfr_avec_pv_immo ? numberFormat(cehr.rfr_avec_pv_immo) : 0,
            unite: '€'
        },
        {
            label: "CEHR sur plus-value immobilière avant lissage éventuel",
            value: cehr.cehr_sur_pv_immo_avant_lissage ? numberFormat(cehr.cehr_sur_pv_immo_avant_lissage) : 0,
            unite: '€',
            valueBold: true,
            labelColor: boldOrange,
            valueColor: boldOrange
        },
        {
            label: "Base imposable pour lissage",
            value: cehr.base_imposable_lissage ? numberFormat(cehr.base_imposable_lissage) : 0,
            unite: '€'
        },
        {
            label: "CEHR sur plus-value immobilière après lissage éventuel",
            value: cehr.cehr_annee_n_hors_pv_immo_apres_lissage ? numberFormat(cehr.cehr_annee_n_hors_pv_immo_apres_lissage) : 0,
            unite: '€',
            valueBold: true,
            labelColor: boldOrange,
            valueColor: boldOrange
        }
    ]
    )

    // Changement sur l'abattement applicable
    useEffect(() => {
        if (conditionSurAbattementApplicable) {
            setBloc1IR([
                {
                    label: "Abattement pour durée de détention",
                    value: ir.abattement_duree_detention ? numberFormat(ir.abattement_duree_detention) : 0,
                    value2: ir.abattement_duree_detention_construction ? numberFormat(ir.abattement_duree_detention_construction) : 0,
                    withPercent: true,
                    valuePercent: ir.abattement_duree_detention_taux ? ir.abattement_duree_detention_taux : 0,
                    unite: '€'
                },
                {
                    label: "Abattement exceptionnel",
                    value: ir.abattement_exceptionnel ? numberFormat(ir.abattement_exceptionnel) : 0,
                    value2: ir.abattement_exceptionnel_construction ? numberFormat(ir.abattement_exceptionnel_construction) : 0,
                    withPercent: true,
                    valuePercent: ir.abattement_exceptionnel_taux ? ir.abattement_exceptionnel_taux : 0,
                    unite: '€'
                },
                {
                    label: "Taux global d'abattement",
                    value: ir.taux_global_abattement ? parseFloat(ir.taux_global_abattement).toFixed(2) : 0,
                    value2: ir.taux_global_abattement_construction ? parseFloat(ir.taux_global_abattement_construction).toFixed(2) : 0,
                    unite: "%"
                }
            ])

            setBloc1PS([
                {
                    label: "Abattement pour durée de détention",
                    value: ps.abattement_duree_detention ? numberFormat(ps.abattement_duree_detention) : 0,
                    value2: ps.abattement_duree_detention_construction ? numberFormat(ps.abattement_duree_detention_construction) : 0,
                    withPercent: true,
                    valuePercent: ps.abattement_duree_detention_taux ? ps.abattement_duree_detention_taux : 0,
                    unite: '€'
                },
                {
                    label: "Abattement exceptionnel",
                    value: ps.abattement_exceptionnel ? numberFormat(ps.abattement_exceptionnel) : 0,
                    value2: ps.abattement_exceptionnel_construction ? numberFormat(ps.abattement_exceptionnel_construction) : 0,
                    withPercent: true,
                    valuePercent: ps.abattement_exceptionnel_taux ? ps.abattement_exceptionnel_taux : 0,
                    unite: '€'
                },
                {
                    label: "Taux global d'abattement",
                    value: ps.taux_global_abattement ? parseFloat(ps.taux_global_abattement).toFixed(3) : 0,
                    value2: ps.taux_global_abattement_construction ? parseFloat(ps.taux_global_abattement_construction).toFixed(3) : 0,
                    unite: "%"
                }
            ])
        }
        else {
            setBloc1IR([
                {
                    label: "Abattement pour durée de détention",
                    value: ir.abattement_duree_detention ? numberFormat(ir.abattement_duree_detention) : 0,
                    value2: ir.abattement_duree_detention_construction ? numberFormat(ir.abattement_duree_detention_construction) : 0,
                    valuePercent: ir.abattement_duree_detention_taux ? ir.abattement_duree_detention_taux : 0,
                    withPercent: true,
                    unite: '€'
                }
            ])

            setBloc1PS([
                {
                    label: "Abattement pour durée de détention",
                    value: ps.abattement_duree_detention ? numberFormat(ps.abattement_duree_detention) : 0,
                    value2: ps.abattement_duree_detention_construction ? numberFormat(ps.abattement_duree_detention_construction) : 0,
                    withPercent: true,
                    valuePercent: ps.abattement_duree_detention_taux ? ps.abattement_duree_detention_taux : 0,
                    unite: '€'
                }
            ])
        }
    }, [
        hypothese.abattemennt_exceptionnel,
        JSON.stringify(ir),
        JSON.stringify(ps)
    ])

    // Changement sur l'activation du mécanisme de lissage de la CEHR
    useEffect(() => {
        if (hypothese.activer_mecanisme_lissage_cehr) {
            setBlocCEHR([
                {
                    label: "Seuil d'imposition à la CEHR",
                    value: cehr.seuil_imposition_cehr ? numberFormat(cehr.seuil_imposition_cehr) : 0,
                    unite: '€'
                },
                {
                    label: "Revenu fiscal de référence hors plus-value immobilière",
                    value: cehr.rfr_annee_hors_pv ? numberFormat(cehr.rfr_annee_hors_pv) : 0,
                    unite: '€'
                },
                {
                    label: "Réintégration plus-value immobilière",
                    value: cehr.reintegration_plus_value_immo ? numberFormat(cehr.reintegration_plus_value_immo) : 0,
                    unite: '€'
                },
                {
                    label: "Revenu fiscal de référence avec plus-value immobilière",
                    value: cehr.rfr_avec_pv_immo ? numberFormat(cehr.rfr_avec_pv_immo) : 0,
                    unite: '€'
                },
                {
                    label: "CEHR sur plus-value immobilière avant lissage éventuel",
                    value: cehr.cehr_sur_pv_immo_avant_lissage ? numberFormat(cehr.cehr_sur_pv_immo_avant_lissage) : 0,
                    unite: '€',
                    valueBold: true,
                    labelColor: boldOrange,
                    valueColor: boldOrange
                },
                {
                    label: "Base imposable pour lissage",
                    value: cehr.base_imposable_lissage ? numberFormat(cehr.base_imposable_lissage) : 0,
                    unite: '€'
                },
                {
                    label: "CEHR sur plus-value immobilière après lissage éventuel",
                    value: cehr.cehr_annee_n_hors_pv_immo_apres_lissage ? numberFormat(cehr.cehr_annee_n_hors_pv_immo_apres_lissage) : 0,
                    unite: '€',
                    valueBold: true,
                    labelColor: boldOrange,
                    valueColor: boldOrange
                }
            ])
        }
        else {
            setBlocCEHR([
                {
                    label: "Seuil d'imposition à la CEHR",
                    value: cehr.seuil_imposition_cehr ? numberFormat(cehr.seuil_imposition_cehr) : 0,
                    unite: '€'
                },
                {
                    label: "Revenu fiscal de référence hors plus-value immobilière",
                    value: cehr.rfr_annee_hors_pv ? numberFormat(cehr.rfr_annee_hors_pv) : 0,
                    unite: '€'
                },
                {
                    label: "Réintégration plus-value immobilière",
                    value: cehr.reintegration_plus_value_immo ? numberFormat(cehr.reintegration_plus_value_immo) : 0,
                    unite: '€'
                },
                {
                    label: "Revenu fiscal de référence avec plus-value immobilière",
                    value: cehr.rfr_avec_pv_immo ? numberFormat(cehr.rfr_avec_pv_immo) : 0,
                    unite: '€'
                },
                {
                    label: "CEHR sur plus-value immobilière avant lissage éventuel",
                    value: cehr.cehr_sur_pv_immo_avant_lissage ? numberFormat(cehr.cehr_sur_pv_immo_avant_lissage) : 0,
                    unite: '€',
                    valueBold: true,
                    labelColor: boldOrange,
                    valueColor: boldOrange
                }
            ]
            )
        }
    }, [
        hypothese.activer_mecanisme_lissage_cehr,
        JSON.stringify(cehr)
    ])

    const construction = hypothese.nature_bien_cede === 3;
    const [ commentaire, setCommentaire ] = useState({
        ir: [],
        ps: [],
        surtaxe: []
    });

    useEffect(() => {
        const construction = hypothese.nature_bien_cede === 3;
        const comms = JSON.parse(JSON.stringify(commentaire));
        const commsIR = [];
        const commsPS = [];
        const commsSurtaxe = [];

        // Pour IR
        if(construction){
            commsIR.push(`Pour le terrain, le taux de l’abattement pour durée de détention est de ${ir.abattement_duree_detention_taux ? parseFloat(ir.abattement_duree_detention_taux).toFixed(2) : 0} %.`);
            commsIR.push(`Pour la construction, le taux de l’abattement pour durée de détention est de ${ir.abattement_duree_detention_taux_construction ? parseFloat(ir.abattement_duree_detention_taux_construction).toFixed(2) : 0} %.`);
            if(conditionSurAbattementApplicable){
                commsIR.push(`Le taux de l’abattement exceptionnel est de ${ir.abattement_exceptionnel_taux ? parseFloat(ir.abattement_exceptionnel_taux).toFixed(2) : 0} %.`);
            }
        }
        if(conditionSurAbattementApplicable){
            commsIR.push("L'abattement exceptionnel est appliqué sur la plus-value après déduction de l'abattement pour durée de détention.");
        }
        if(hypothese.type_cession === 0 && [2].includes(hypothese.nature_bien_cede)){
            commsIR.push("Le forfait forestier est de 10 € par hectare vendu. Il s'applique uniquement pour le calcul de la plus-value imposable à l'IR.");
        }

        // Pour PS
        if(construction){
            commsPS.push(`Pour le terrain, le taux de l’abattement pour durée de détention est de ${ps.abattement_duree_detention_taux ? parseFloat(ps.abattement_duree_detention_taux).toFixed(2) : 0} %.`);
            commsPS.push(`Pour la construction, le taux de l’abattement pour durée de détention est de ${ps.abattement_duree_detention_taux_construction ? parseFloat(ps.abattement_duree_detention_taux_construction).toFixed(2) : 0} %.`);
            if(conditionSurAbattementApplicable){
                commsPS.push(`Le taux de l’abattement exceptionnel est de ${ps.abattement_exceptionnel_taux ? parseFloat(ps.abattement_exceptionnel_taux).toFixed(2) : 0} %.`);
            }
        }

        if(conditionSurAbattementApplicable){
            commsPS.push("L'abattement exceptionnel est appliqué sur la plus-value après déduction de l'abattement pour durée de détention.");
        }

        // Pour le Surtaxe
        if(hypothese.type_cession !== undefined){
            if([1].includes(hypothese.nature_bien_cede)){
                commsSurtaxe.push(`Pas de surtaxe sur la plus-value de cession de terrains à bâtir.`);
            }

            if([0, 2, 3].includes(hypothese.nature_bien_cede) && dataC51 < 50000){
                commsSurtaxe.push(`Pas de surtaxe sur la plus-value immobilière nette imposable à l'IR n'excédant pas 50 000 €.`)
            }

            if(([0, 2, 3].includes(hypothese.nature_bien_cede) && dataC51 > 50000)){
                commsSurtaxe.push(`Surtaxe sur la plus-value immobilière nette imposable à l'IR supérieure à 50 000 €.`)
            }
        }

        comms.ir = commsIR;
        comms.ps = commsPS;
        comms.surtaxe = commsSurtaxe;

        setCommentaire(comms);

    }, [ JSON.stringify(hypothese), dataC51 ])

    const defaultSurtaxe = [
        {
            label: "Plus-value imposable (par cédant)",
            value: surtaxe.plus_value_imposable ? numberFormat(surtaxe.plus_value_imposable) : 0,
            unite: '€'
        },
        {
            label: <><span style={{ fontWeight: 'bold' }} >Surtaxe </span><span>(par cédant)</span></>,
            value: surtaxe.sur_taxe_par_cedant ? numberFormat(surtaxe.sur_taxe_par_cedant) : 0,
            unite: '€',
            valueBold: true,
            labelColor: boldOrange,
            valueColor: boldOrange
        }
    ]

    return (
        <div>
            {/** Impôt sur le revenu */}
            <div style={{ display: 'flex', width: '100%', marginBottom: marginBetweenBlock }} >
                <div style={{ width: '48%' }} >
                    <div style={{ paddingLeft: paddingLeft, paddingTop: padding, paddingBottom: padding, borderRadius: '8px 8px 0 0', color: boldOrange, background: fillOrange, fontWeight: 'bold' }} >IMPOT SUR LE REVENU</div>
                    {
                        (hypothese.nature_bien_cede === 3) && (
                            <Title />
                        )
                    }
                    <div style={{ padding: 20, paddingTop: hypothese.nature_bien_cede === 3 ? 0 : 20, background: 'rgb(244,244,244)' }} >
                        <BlocResultatDetaille
                            construction={hypothese.nature_bien_cede === 3}
                            marginBottom={marginBetweenCadre}
                            values={bloc1IR}
                        />

                        {
                            (hypothese.type_cession === 0 && [2].includes(hypothese.nature_bien_cede)) && (
                                <BlocResultatDetaille
                                    construction={hypothese.nature_bien_cede === 3}
                                    marginBottom={marginBetweenCadre}
                                    values={[
                                        {
                                            label: "Forfait forestier",
                                            value: ir.forfait_forestier ? numberFormat(ir.forfait_forestier) : 0,
                                            unite: '€'
                                        }
                                    ]}
                                />
                            )
                        }

                        <BlocResultatDetaille
                            construction={hypothese.nature_bien_cede === 3}
                            marginBottom={marginLastCadre}
                            values={[
                                {
                                    label: "Plus-value nette imposable à l'IR",
                                    value: ir.plus_value_nette_imposable_ir ? numberFormat(ir.plus_value_nette_imposable_ir) : 0,
                                    value2: ir.plus_value_nette_imposable_ir_construction ? numberFormat(ir.plus_value_nette_imposable_ir_construction) : 0,
                                    unite: '€'
                                },
                                {
                                    label: "Taux forfaitaire IR",
                                    value: ir.taux_forfait_ir ? parseFloat(ir.taux_forfait_ir).toFixed(2) : 0,
                                    value2: ir.taux_forfait_ir_construction ? parseFloat(ir.taux_forfait_ir_construction).toFixed(2) : 0,
                                    unite: '%'
                                },
                                {
                                    label: "IR",
                                    value: ir.ir ? numberFormat(ir.ir) : 0,
                                    value2: ir.ir_construction ? numberFormat(ir.ir_construction) : 0,
                                    value3: ir.total ? numberFormat(ir.total) : 0,
                                    unite: "€",
                                    labelBold: true,
                                    valueBold: true,
                                    labelColor: boldOrange,
                                    valueColor: boldOrange
                                },
                            ]}
                        />
                    </div>
                </div>
                <div style={{ marginLeft: '2%', width: '48%' }} >
                    <Commentaire
                        values={commentaire.ir}
                    />
                </div>
            </div>

            {/** Prélèvements sociaux */}
            <div style={{ display: 'flex', width: '100%', marginBottom: marginBetweenBlock }} >
                <div style={{ width: '48%' }} >
                    <div style={{ paddingLeft: paddingLeft, paddingTop: padding, paddingBottom: padding, borderRadius: '8px 8px 0 0', color: boldOrange, background: fillOrange, fontWeight: 'bold' }} >PRÉLÈVEMENTS SOCIAUX</div>
                    {
                        (hypothese.nature_bien_cede === 3) && (
                            <Title />
                        )
                    }
                    <div style={{ padding: 20, paddingTop: hypothese.nature_bien_cede === 3 ? 0 : 20, background: 'rgb(244,244,244)' }} >
                        <BlocResultatDetaille
                            construction={hypothese.nature_bien_cede === 3}
                            marginBottom={marginBetweenCadre}
                            values={bloc1PS}
                        />

                        <BlocResultatDetaille
                            construction={hypothese.nature_bien_cede === 3}
                            marginBottom={marginLastCadre}
                            values={[
                                {
                                    label: "Plus-value nette imposable aux PS",
                                    value: ps.plus_value_nette_imposable_ir ? numberFormat(ps.plus_value_nette_imposable_ir) : 0,
                                    value2: ps.plus_value_nette_imposable_ir_construction ? numberFormat(ps.plus_value_nette_imposable_ir_construction) : 0,
                                    unite: '€'
                                },
                                {
                                    label: "Taux PS",
                                    value: ps.taux_ps ? parseFloat(ps.taux_ps).toFixed(2) : 0,
                                    value2: ps.taux_ps_construction ? parseFloat(ps.taux_ps_construction).toFixed(2) : 0,
                                    unite: '%'
                                },
                                {
                                    label: "PS",
                                    value: ps.ps ? numberFormat(ps.ps) : 0,
                                    value2: ps.ps_construction ? numberFormat(ps.ps_construction) : 0,
                                    value3: ps.total ? numberFormat(ps.total) : 0,
                                    unite: "€",
                                    labelBold: true,
                                    valueBold: true,
                                    labelColor: boldOrange,
                                    valueColor: boldOrange
                                },
                            ]}
                        />
                    </div>
                </div>
                <div style={{ marginLeft: '2%', width: '48%' }} >
                    <Commentaire
                        values={commentaire.ps}
                    />
                </div>
            </div>

            {/** Surtaxe */}
            <div style={{ display: 'flex', width: '100%', marginBottom: marginBetweenBlock }} >
                <div style={{ width: '48%' }} >
                    <div style={{ paddingLeft: paddingLeft, paddingTop: padding, paddingBottom: padding, borderRadius: '8px 8px 0 0', color: boldOrange, background: fillOrange, fontWeight: 'bold' }} >SURTAXE</div>
                    <div style={{ padding: 20, background: 'rgb(244,244,244)' }} >
                        <BlocResultatDetaille
                            marginBottom={marginLastCadre}
                            values={
                                hypothese.bien_detenu === 0 ? 
                                defaultSurtaxe :
                                [
                                    ...defaultSurtaxe,
                                    {
                                        label: "Surtaxe totale",
                                        value: surtaxe.sur_taxe_total ? numberFormat(surtaxe.sur_taxe_total) : 0,
                                        labelBold: true,
                                        valueBold: true,
                                        labelColor: boldOrange,
                                        valueColor: boldOrange,
                                        unite: '€'
                                    },
                                ] 
                            }
                        />
                    </div>
                </div>
                {
                    (hypothese.type_cession !== undefined) && (
                        <div style={{ marginLeft: '2%', width: '48%' }} >
                            <Commentaire
                                values={commentaire.surtaxe}
                            />
                        </div>
                    )
                }
            </div>

            {/** CEHR */}
            <div style={{ display: 'flex', width: '100%', marginBottom: marginBetweenBlock }} >
                <div style={{ width: '48%' }} >
                    <div style={{ paddingLeft: paddingLeft, paddingTop: padding, paddingBottom: padding, borderRadius: '8px 8px 0 0', color: boldOrange, background: fillOrange, fontWeight: 'bold' }} >CONTRIBUTION EXCEPTIONNELLE SUR LES HAUTS REVENUS</div>
                    <div style={{ padding: 20, background: 'rgb(244,244,244)' }} >
                        <BlocResultatDetaille
                            marginBottom={marginLastCadre}
                            values={blocCEHR}
                        />
                    </div>
                </div>
                <div style={{ marginLeft: '2%', width: '48%' }} >
                    <Commentaire
                        value={<div style={{ marginTop: -15 }} >Pour le calcul de la CEHR, prise en compte de la plus-value immobilière de cession, <b>pour son montant net imposable à l'IR</b> : après abattement ou exonération s'agissant de la résidence principale.</div>}
                    />
                </div>
            </div>

            {/** IMPACT SUR LE PLAFONNEMENT IFI */}
            {
                (hypothese.impact_plafonnement_ifi === 1) && (
                    <div style={{ display: 'flex', width: '100%' }} >
                        <div style={{ width: '48%' }} >
                            <div style={{ paddingLeft: paddingLeft, paddingTop: padding, paddingBottom: padding, borderRadius: '8px 8px 0 0', color: boldOrange, background: fillOrange, fontWeight: 'bold' }} >IMPACT SUR LE PLAFONNEMENT IFI</div>
                            <div style={{ padding: 20, background: 'rgb(244,244,244)' }} >
                                <BlocResultatDetaille
                                    marginBottom={marginLastCadre}
                                    values={[
                                        {
                                            label: "Plus-value prise en compte pour le calcul du plafonnement de l'IFI",
                                            value: ifi.pv_compte_calcul_plafonnement_ifi ? numberFormat(ifi.pv_compte_calcul_plafonnement_ifi) : 0,
                                            unite: '€'
                                        }
                                    ]}
                                />
                            </div>
                        </div>
                        <div style={{ marginLeft: '2%', width: '48%' }} >
                            <Commentaire
                                value={<div style={{ marginTop: -15 }} >Pour le calcul du plafonnement de l'IFI, prise en compte de la plus-value immobilière de cession, <b>pour son montant brut</b> : avant abattement et avant exonération s'agissant de la résidence principale.</div>}
                            />
                        </div>
                    </div>
                )
            }
        </div>
    )
}

export default Fiscalite;