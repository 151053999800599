import React, { useEffect, useState } from 'react';
import './style.scss';

const SimulateurContainerWithMenu = ({
    menus,
    scenarioErreur,
    onRequestCatchScenarioErreur,
    onRequestMenuChange,
}) => {
    const [currentMenu, setCurrentMenu] = useState(0);
    menus = menus?.filter((element) => element?.content !== null );

    // à chaque fois que le menu change
    useEffect(() => {
        if(onRequestMenuChange){
            onRequestMenuChange(currentMenu, menus?.[currentMenu]?.resultPage || false);
        }
    }, [ currentMenu ])

    return (
        <div className='simulateur-top-menu'>
            {/** Les menus */}
            <div className='menu'>
                {
                    menus?.map((menu, index) => {
                        return (
                            <div 
                                key={`Menu${index+1}`} 
                                className={`menu-item${currentMenu === index ? '-active' : ''}`}
                                onClick={() => {
                                    if(!scenarioErreur){
                                        if (!menu.noContent) {
                                            setCurrentMenu(index)
                                        }
                                    }
                                    else{
                                        if(onRequestCatchScenarioErreur){
                                            onRequestCatchScenarioErreur(true);
                                        }
                                    }
                                }}
                            >
                                {menu.label}
                            </div>
                        )
                    })
                }
            </div>

            {/** Le contenu du menu sélectionné */}
            <div className='contenu' style={menus?.[currentMenu]?.style}>
                {
                    menus?.[currentMenu]?.content || <></>
                }
            </div>
        </div>
    )
}

export default SimulateurContainerWithMenu;