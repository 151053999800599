import React, { Component } from "react";
import NumberFormat from 'react-number-format';
import { CInput } from '@coreui/react';

const CustomInput = (props) => {
    const { className, ...inputProps } = props
    return (
        <div className={className} style={{padding: 0, display:"flex"}}>
            <CInput {...inputProps} style={inputProps.style, {width: '100%', paddingRight: 40, textAlign: 'right',fontSize: 13}}/>
            <span style={{position: "absolute", right: 13, top: 7,fontSize: 13, color:"#727272"}}>ans</span>
        </div>
    )
}

export default class InputAgeAvcAns extends Component { 

    render() {
        return(
            <NumberFormat
                id={this.props.id}
                customInput={CustomInput}
                style={{textAlign:'right', ...this.props.style, fontSize:13}}
                allowNegative={false}
                isAllowed={(values) => {
                    const {value} = values;
                    return this.props.min? value > this.props.min && value < 150 : (!value || value < 150);
                }}
                onKeyDown={event=> {
                    if (event.keyCode === 13) {
                        const form = event.target.form;
                        let index = Array.prototype.indexOf.call(form, event.target);
                        if(form.elements[index + 1])
                            form.elements[index + 1].focus();
                        event.preventDefault();
                    }
                }}
                disabled={this.props.disabled}
                invalid={this.props.invalid}
                value={this.props.value}
                onChange={this.props.onChange}
                className= {this.props.className? this.props.className : "col-lg-2 mr-2"}
                
                placeholder= { this.props.placeholder ? this.props.placeholder : "" }
            />
        )
    }
}