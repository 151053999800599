import React, { useContext, useState } from 'react';
import useStyles from './style';
import { CessionContext } from '../../CessionHaut';
import Loading from '../../../../../components/loader';
import { numberFormat } from '../../../../../extra/all_ppt_render';
import { idDernierEnfantConjoint, nombreEnfantsParConjoint } from '../../ProjectionCessionSociete/TableauSynthese/TableauSynthese';
import tableBorderBottom from '../../ProjectionCessionSociete/TableauSynthese';

export const negativeCurrency = (currency) => {
    if(currency < 0) return `(${numberFormat(Math.abs(currency))}) €`;
    else if(currency === 0) return `- €`;
    else return `${numberFormat(currency)} €`;
};

const TableauDonation = (props) => {
    const classes = useStyles();

    const titres = [
        "Actionnaires familiaux",
        // "Noms personnalisés",
        "Valeur des titres",
        "Apports des titres",
        "Donation de titres",
        "Solde valeur des titres"
    ];
    
    const [ currentTitles, setCurrentTitles ] = useState(titres);

    const color = '#707070';

    const blackFill = "#585C66";
    
    const { cessionHaut } = useContext(CessionContext);
    const { identiteActionnaire: identite, projetCessionSociete: projet, operationsAvantCession: operations } = cessionHaut;
    const { total } = projet;
    const { pp, np1, np2, somme } = total;
    const { conjoints, enfants, affichageNomPersonnalise: affichage } = identite;
    const { enfantsFamiliauxDP: demembrement, enfantsFamiliauxPP, holding, conjoints: conjointsProjet } = projet;
    const { synthese } = operations;

    const [ loading, setLoading ] = useState(false);

    const sommeNPByIndex = (conjointIndex) => {
        if(conjointIndex === 0) return synthese.somme.np1;
        else return synthese.somme.np2;
    };

    const VerticalWhiteSpace = () => {
        const h = 8;

        return (
            <tr>
                <td height={h} style={{background: '#FFFFFF'}} ></td>
                <td height={h} style={{background: '#FFFFFF'}} ></td>
                <td height={h} style={{background: '#FFFFFF'}} ></td>
                <td height={h} style={{background: '#FFFFFF'}} ></td>
                <td height={h} style={{background: '#FFFFFF'}} ></td>
                {/* <td height={h} style={{background: '#FFFFFF'}} ></td> */}
            </tr>
        );
    };

    const borderedColumn = {
        borderRight: '2px solid #585C66',
        borderLeft: '2px solid #585C66'
    };

    const columnWidth = (index) => {
        switch(index){
            case 0 :
                return "15%";
            case 1 :
                return "10%";
            case 2 :
                return "10%";
            case 3 :
                return "9%";
            case 4 :
                return "9%";
            // default :
            //     return "12%"
        }
    };

        return !loading && operations.synthese ? (
            <div className='structureBlock' style={{ display: 'flex', padding:8, color:color, borderRadius: 8 }} >
                <table
                    id="tbSynthese"
                    cellSpacing={5} 
                    cellPadding={5} 
                    style={{ 
                        width: "100%", fontSize: 13
                        // borderTop: "2px solid gray"
                    }}
                >
                    <tr style={{ borderBottom: "2px solid #809DB4" }} >
                        {
                            currentTitles.map((data, index) => {
                                return (
                                    <th 
                                        width={columnWidth(index)}
                                        key={index} 
                                        className={classes.th}
                                        style={{
                                            textAlign: index > 0 ? 'center' : 'left', 
                                            background: (index === 2 || index === 3) ? "#585C66" : "#FFFFFF",
                                            borderRight: index === 2 ? "2px solid #FFFFFF" : index === 3 && "2px solid #585C66",
                                            borderTop : (index === 2 || index === 3) && "2px solid #585C66",
                                            color: (index === 2 || index === 3) ? "#FFFFFF" : '#585C66'
                                        }}
                                    >
                                        {data}
                                    </th>
                                )
                            })
                        }
                    </tr>
                    {
                        synthese.conjoints.map((data, index) => {
                            return (
                                <tr key={index} style={{ ...tableBorderBottom(true) }} >
                                    <th style={{background:"#F3F3F3", borderBottom:'1px solid white', borderRight:"3px solid #fff"}}>{data.actionnaireFamilial}</th>
                                    {/* <td style={{ textAlign: "left",background:"#F3F3F3", borderBottom:'1px solid white' }} >{`${ data.nomPersonnalise}`}</td> */}
                                    <td style={{ textAlign: "right", paddingRight:'22px',background:"#F3F3F3", fontWeight:"bold", borderBottom:'1px solid white' }} >{`${data.valeurTitres}`}</td>
                                    <td style={{ textAlign: "right", color: color,...borderedColumn, paddingRight:'22px',background:"#F3F3F3", fontWeight:"bold", borderBottom:'1px solid white' }} >{`${data.apportTitres}`}</td>
                                    <td style={{ textAlign: "right", color: color, ...borderedColumn, paddingRight:'22px',background:"#F3F3F3" , fontWeight:"bold", borderBottom:'1px solid white' }} >{`${data.donationTitres}`}</td>
                                    <td style={{ textAlign: "right", paddingRight:'22px',background:"#F3F3F3", fontWeight:"bold" , borderBottom:'1px solid white' }} >{`${data.soldeValeurTitres}`}</td>
                                </tr>
                                )
                        })
                    }
                    {
                        enfants.length > 1 && (
                            <tr style={{ ...tableBorderBottom(true) }} >
                                <th style={{background:"#F3F3F3", borderBottom:'1px solid white', borderRight:"3px solid #fff"}}>{synthese.somme.pp.actionnaireFamilial}</th>
                                {/* <td style={{background:"#F3F3F3", borderBottom:'1px solid white'}}></td> */}
                                <td style={{ textAlign: "right", paddingRight:'22px',background:"#F3F3F3", fontWeight:"bold", borderBottom:'1px solid white' }} >{`${synthese.somme.pp.valeurTitres}`}</td>
                                <td style={{ textAlign: "right", color: color, ...borderedColumn, paddingRight:'22px',background:"#F3F3F3", fontWeight:"bold", borderBottom:'1px solid white' }}  >{`${synthese.somme.pp.apportTitres}`}</td>
                                <td style={{ textAlign: "right", color: color, ...borderedColumn, paddingRight:'22px',background:"#F3F3F3", fontWeight:"bold" , borderBottom:'1px solid white'}}  >{`${synthese.somme.pp.donationTitres}`}</td>
                                <td style={{ textAlign: "right", paddingRight:'22px',background:"#F3F3F3", fontWeight:"bold" , borderBottom:'1px solid white'}}  >{`${synthese.somme.pp.soldeValeurTitres}`}</td>
                            </tr>
                        )
                    }
                    {
                        synthese.enfantsPP.map((data, index) => {
                            return (
                                <tr key={index} style={{ ...tableBorderBottom(index + 1 === enfants.length) }} >
                                    <td style={{ fontWeight: enfants.length > 1 ? 'normal' : 'bold', paddingLeft: enfants.length > 1 ? 15 : 5, borderBottom:'1px solid white',background:"#FCFCFC", borderRight:"3px solid #fff" }} >{data.actionnaireFamilial}</td>
                                    {/* <td style={{ textAlign: "left", borderBottom:'1px solid white' }} >{`${data.nomPersonnalise}`}</td> */}
                                    <td style={{ textAlign: "right", paddingRight:'22px', borderBottom:'1px solid white',background:"#FCFCFC"  }} >{`${data.valeurTitres}`}</td>
                                    <td style={{ textAlign: "right", color: color, ...borderedColumn, paddingRight:'22px', borderBottom:'1px solid white',background:"#FCFCFC"  }}  >{`${data.apportTitres}`}</td>
                                    <td style={{ textAlign: "right", color: color, ...borderedColumn, paddingRight:'22px', borderBottom:'1px solid white',background:"#FCFCFC"  }}  >{`${data.donationTitres}`}</td>
                                    <td style={{ textAlign: "right", paddingRight:'22px', borderBottom:'1px solid white',background:"#FCFCFC"  }}  >{`${data.soldeValeurTitres}`}</td>
                                </tr>
                            )
                        })
                    }
                    {
                        synthese.enfantsNP.map((data, index) => {
                            if(index === 0 || (index === 1 && conjoints[0].marieOuPasce === "Oui" ))
                                return (
                                    <>
                                        {
                                            nombreEnfantsParConjoint(enfants, index) > 1 && (
                                                <tr style={{ ...tableBorderBottom(true) }} >
                                                    <th style={{background:"#F3F3F3", borderBottom:'1px solid white', borderRight:"3px solid #fff"}}>{sommeNPByIndex(index).actionnaireFamilial}</th>
                                                    {/* <td style={{ textAlign: "center",background:"#F3F3F3", borderBottom:'1px solid white' }} >{``}</td> */}
                                                    <td style={{ textAlign: "right", paddingRight:'22px',background:"#F3F3F3", fontWeight:"bold" , borderBottom:'1px solid white'}} >{`${sommeNPByIndex(index).valeurTitres}`}</td>
                                                    <td style={{ textAlign: "right", color: color, ...borderedColumn, paddingRight:'22px',background:"#F3F3F3", fontWeight:"bold", borderBottom:'1px solid white' }}  >{`${sommeNPByIndex(index).apportTitres}`}</td>
                                                    <td style={{ textAlign: "right", color: color, ...borderedColumn, paddingRight:'22px',background:"#F3F3F3", fontWeight:"bold", borderBottom:'1px solid white' }}  >{`${sommeNPByIndex(index).donationTitres}`}</td>
                                                    <td style={{ textAlign: "right", paddingRight:'22px',background:"#F3F3F3", fontWeight:"bold", borderBottom:'1px solid white' }}  >{`${sommeNPByIndex(index).soldeValeurTitres}`}</td>
                                                     
                                                </tr>
                                            )
                                        }
                                        {
                                            data.map((donnee, indice) => {
                                                return (
                                                    <tr style={{ ...tableBorderBottom(`${conjoints[index].nomPersonnalise} USUF / ${idDernierEnfantConjoint(enfants, index).nom} NP` === donnee.nomPersonnalise) }} >
                                                        <td style={{ fontWeight: nombreEnfantsParConjoint(enfants, index) > 1 ? 'normal' : 'bold', paddingLeft: nombreEnfantsParConjoint(enfants, index) > 1 ? 15 : 5, borderBottom:'1px solid white', borderRight:"3px solid #fff",background:"#FCFCFC"  }}>{donnee.actionnaireFamilial}</td>
                                                        {/* <td style={{ textAlign: "left", borderBottom:'1px solid white' }} >{donnee.nomPersonnalise}</td> */}
                                                        <td style={{ textAlign: "right", paddingRight:'22px', borderBottom:'1px solid white',background:"#FCFCFC"  }} >{`${donnee.valeurTitres}`}</td>
                                                        <td style={{ textAlign: "right", color: color, ...borderedColumn, paddingRight:'22px', borderBottom:'1px solid white',background:"#FCFCFC"   }} >{`${donnee.apportTitres}`}</td>
                                                        <td style={{ textAlign: "right", color: color, ...borderedColumn, paddingRight:'22px', borderBottom:'1px solid white',background:"#FCFCFC"  }} >{`${donnee.donationTitres}`}</td>
                                                        <td style={{ textAlign: "right", paddingRight:'22px', borderBottom:'1px solid white',background:"#FCFCFC"  }} >{`${donnee.soldeValeurTitres}`}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </>
                                )
                        })
                    }
                        <tr>
                            <td style={{ background: blackFill, color: '#FFFFFF', borderRight: '2px solid #585C66', paddingLeft: 15, fontWeight:"bold", borderRight:"3px solid #fff"  }} >SOUS-TOTAL actionnaires personnes physiques</td>
                            <td style={{ background: blackFill, color: '#FFFFFF', borderRight: '2px solid white', textAlign: 'right', paddingRight: '22px' , fontWeight:"bold"}} >{synthese.personnePhysique.valeurTitres}</td>
                            <td style={{ background: blackFill, color: '#FFFFFF', borderRight: '2px solid white', textAlign: 'right', paddingRight: '22px' }} >{synthese.personnePhysique.apportTitres}</td>
                            <td style={{ background: blackFill, color: '#FFFFFF', borderRight: '2px solid white', textAlign: 'right', paddingRight: '22px' }} >{synthese.personnePhysique.donationTitres}</td>
                            <td style={{ background: blackFill, color: '#FFFFFF', textAlign: 'right', paddingRight: '22px' }} >{synthese.personnePhysique.soldeValeurTitres}</td>
                        </tr>
                        {/* <VerticalWhiteSpace /> */}
                        <tr>
                            <th style={{ background: '#fff',background:"#FCFCFC", borderRight:"3px solid #fff" }} >{affichage ? ( holding.nomPersonnalise !== "" ? holding.nomPersonnalise : 'HOLDING IS' ) : 'HOLDING IS'}</th>
                            {/* <td style={{ background: '#fff', textAlign: 'left' }} >{holding.nomPersonnalise}</td> */}
                            <th style={{ background: '#fff', textAlign: 'right', paddingRight:'22px',background:"#FCFCFC" }} >{`${synthese.holding.valeurTitres} `}</th>
                            <th style={{ background: '#FFFFFF', ...borderedColumn,background:"#FCFCFC" }} ></th>
                            <th style={{ background: '#FFFFFF', ...borderedColumn,background:"#FCFCFC" }} ></th>
                            <th style={{ background: '#fff', textAlign: 'right', paddingRight:'22px',background:"#FCFCFC" }} >{`${synthese.holding.soldeValeurTitres} `}</th>
                        </tr>
                        {/* <VerticalWhiteSpace /> */}
                        <tr>
                            <td style={{ background: '#fff', fontWeight: 'bold', borderRight:"3px solid #fff",background:"#FCFCFC" }} >NEWCO IS (bénéficiaire.s des apports avant cession)</td>
                            <th style={{ background: '#FFFFFF', borderTop: '2px solid #FFFFFF', ...borderedColumn,background:"#FCFCFC" }} ></th>
                            <th style={{ background: '#fff', textAlign: 'right', paddingRight:'22px', ...borderedColumn,background:"#FCFCFC" }} >{`${synthese.newco.apportTitres} `}</th>
                            <th style={{ background: '#FFFFFF', borderTop: '2px solid #FFFFFF', ...borderedColumn,background:"#FCFCFC" }} ></th>
                            <th style={{ background: '#fff', textAlign: 'right', paddingRight:'22px',background:"#FCFCFC" }} >{`${synthese.newco.soldeValeurTitres} `}</th>
                        </tr>
                        {/* <VerticalWhiteSpace /> */}
                        <tr>
                            <td style={{ background: blackFill, color: '#FFFFFF', borderRight: '2px solid #585C66',  paddingLeft: 15, fontWeight:"bold", borderRight:"3px solid #fff" }} >SOUS-TOTAL actionnaires personnes morales</td>
                            <td style={{ background: blackFill, color: '#FFFFFF', borderRight: '2px solid #fff', textAlign: 'right', paddingRight: '22px', fontWeight:"bold" }} >{synthese.personneMorale.valeurTitres}</td>
                            <td style={{ background: blackFill, color: '#FFFFFF', borderRight: '2px solid #585C66', textAlign: 'right', paddingRight: '22px' }} >{synthese.personneMorale.apportTitres}</td>
                            <td style={{ background: '#FFFFFF', color: '#FFFFFF', borderRight: '2px solid #585C66', textAlign: 'right', paddingRight: '22px' }} ></td>
                            <td style={{ background: blackFill, color: '#FFFFFF', textAlign: 'right', paddingRight: '22px' }} >{synthese.personneMorale.soldeValeurTitres}</td>
                        </tr>
                        <VerticalWhiteSpace />
                        <tr style={{ }} >
                            {/* <td></td> */}
                            <td style={{  background: blackFill, color: "#FFFFFF", borderRight: '2px solid #585C66',paddingLeft:14, fontWeight:"bold" , borderRight:"3px solid #fff"}} >TOTAL</td>
                            <td style={{ textAlign: 'right', background: blackFill, color: "#FFFFFF", paddingRight:'22px', borderRight: '2px solid #FFFFFF', fontWeight:"bold"  }} >{`${synthese.somme.somme.valeurTitres}`}</td>
                            <td style={{ textAlign: 'right', background: blackFill, color: "#FFFFFF", paddingRight:'22px', borderRight: '2px solid #FFFFFF'  }} >{`${synthese.somme.somme.apportTitres}`}</td>
                            <td style={{ textAlign: 'right', background: blackFill, color: "#FFFFFF" , paddingRight:'22px', borderRight: '2px solid #FFFFFF' }} >{`${synthese.somme.somme.donationTitres}`}</td>
                            <td style={{ textAlign: 'right', background: blackFill, color: "#FFFFFF", paddingRight:'22px'  }} >{`${synthese.somme.somme.soldeValeurTitres}`}</td>
                        </tr>
                </table>
            </div>
            ) : (<Loading />)
};

export default TableauDonation;