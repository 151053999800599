import React from 'react';
import ButtonWithAction from '../Button/ButtonWithAction';
import HorlogePostIt from '../../assets/images/horloge-post-it.svg';
import PostItJaune from '../../assets/images/post-it-jaune.svg'
import { OrdinaryTooltip } from '../InfoBulle/Tooltip/Tooltip';

const CommentairePostIt = ({
    active,
    id,
    date,
    commentaire,
    onRequestVisualise,
    onRequestEdit,
    onRequestDelete
}) => {
    return (
        <div
            style={{
                marginBottom: 15,
                fontSize: 13,
                paddingLeft: 10,
                paddingRight: 10,
                borderLeft: `4px solid ${active ? 'rgb(255,162,5)' : 'rgb(242,249,252)'}`
            }}>
            {/** Titre avec les actions */}
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 5 }}>
                {/** Titre et Date */}
                <div>
                    <div style={{ fontWeight: 'bold' }}>{`Post-it ${id}`}</div>
                    <div style={{ color: '#8D979C', display: 'flex', alignItems: 'center' }}>
                        <img src={HorlogePostIt} width={15} style={{ marginRight: 5, marginTop: -4 }} />
                        { new Date(date).toLocaleString() }
                    </div>
                </div>

                {/** Les actions à effectuer */}
                <div style={{ display: 'flex' }}>
                    <div style={{ marginRight: 15 }}>
                        <OrdinaryTooltip arrow title="Voir" placement='bottom'>
                            <div>
                                <img src={PostItJaune} style={{ width: 15, cursor: 'pointer' }} onClick={onRequestVisualise} />
                            </div>
                        </OrdinaryTooltip>
                    </div>
                    <div style={{ marginRight: 15, cursor: 'pointer' }}>
                        <ButtonWithAction type='EDIT' onRequestClick={onRequestEdit} />
                    </div>

                    <div style={{ cursor: 'pointer' }}>
                        <ButtonWithAction type='DELETE' onRequestClick={onRequestDelete} />
                    </div>
                </div>
            </div>

            {/** Commentaire */}
            <div
                style={{
                    color: active ? '#585C66 ' : '#8D979C',
                    width: '350px'
                }}
            >{commentaire}</div>
        </div>
    )
}

export default CommentairePostIt;
