import React, { Fragment } from 'react';
import { FaInfoCircle } from 'react-icons/fa';
import { HtmlTooltipError } from '../../views/Cession/CessionHaut/IdentiteActionnaire/Conjoint/HTMLTooltip/HTMLTooltip';

const InfoBulleError = (props) => {
    const { information, style, placement } = props;
    const sheet = style ? style : {};

    return (
        <HtmlTooltipError
            title={
                <Fragment>
                    <div style={{ textAlign: 'center' }} >
                        {information}
                    </div>
                </Fragment>
            }
            placement={placement || "right-start"}
            arrow
        >
            <div style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center', paddingLeft: 8, ...sheet }} >
                <FaInfoCircle color='rgb(229,83,83)' size={22} style={{ cursor: "pointer" }} />
            </div>
        </HtmlTooltipError>
    );
};

export default InfoBulleError;