import CustomExportPptService from "../services/CustomExportPptService";

export const saveCustomExportPpt = (dataToSend) => {
  CustomExportPptService.addPpt(dataToSend).catch((err) => {
    console.log(err);
  }).then((data) => {
    // console.log(data);
  })
}

export const loadCustomExportPpt = (type) => {
  return new Promise((resolve, reject) => {
    const userId = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user'))?.id : null;
    CustomExportPptService.findByUserAndType(type, userId)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err)
      })
  })
}
