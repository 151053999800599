import { blackColor } from "../../../all_ppt_render";

export const labelBackgroundFichePVImmo = 'D6DCE5';
export const valueBackgroundFichePVImmo = 'F5F6F8';
export const greenLabelBackgroundFichePVImmo = 'E2F0D9';
export const noteBackgroundFichePVImmo = 'FFF8E5';
export const barreOrangeBackgroundFichePVImmo = 'ED7F31';
export const barreGreenBackgrounndFichePVImmo = '65BF7F';
export const tableMarginFichePVImmo = [ 5, 5, 5, 5 ];

export const rowPropsFichePVImmo = {
    fontSize: 11,
    fontFace: 'Roboto',
    valign: 'middle',
    color: blackColor,
    fill: valueBackgroundFichePVImmo
}

export const emptyRowFichePVImmo = [
    { text: "", options: { fill: 'FFFFFF', colspan: 3, margin: [ 0, 0, 0, 0 ] } }
]

export const titleValueStyleFichePVImmo = {
    ...rowPropsFichePVImmo, align: 'center', fill: labelBackgroundFichePVImmo, bold: true
}

// Recettes
const texteValueLigne1 = [
    { text: "Les loyers pris en compte sont les loyers ", options: { breakLine: false } },
    { text: "encaissés au cours de l’année d’imposition", options: { breakLine: false, bold: true } },
    { text: ", même s’ils se rapportent à une autre année.", options: {} }
]

// Charges déductibles
const texteLabelLigne2 = [
    { text: '-', options: { breakLine: true } },
    { text: '', options: { breakLine: true } },
    { text: "Charges déductibles", options: {} }
]

const texteValueLigne2 =[
    { text: "Régime micro foncier", options: { breakLine: true, bold: true, underline: { color: '000000', style: 'sng' } } },
    { text: "Régime applicable par principe si les recettes locatives du foyer fiscal sont inférieures à 15.000€.", options: { breakLine: true } },
    { text: "Les recettes sont diminuées d’un ", options: { breakLine: false } },
    { text: "abattement de 30%.", options: { breakLine: true, bold: true } },
    { text: "", options: { breakLine: true } },
    { text: "Régime réel", options: { breakLine: true, bold: true, underline: { color: '000000', style: 'sng' } } },
    { text: "Régime applicable sur option si les recettes locatives du foyer fiscal sont inférieures à 15.000€, et applicable par principe au-delà.", options: { breakLine: true } },
    { text: "Les recettes sont diminuées des charges réellement décaissées pendant l’année d’imposition :", options: { breakLine: true } },
    { text: "Travaux de réparation, entretien et amélioration ;", options: { bullet: { code: '25CF' }, breakLine: true } },
    { text: "Intérêts et frais d’emprunt ;", options: { bullet: { code: '25CF' }, breakLine: true } },
    { text: "Charges de copropriété, frais de gestion, primes d’assurance, taxes foncières.", options: { bullet: { code: '25CF' }, breakLine: true } }
]

// Net
const texteLabelLigne3 = [
    { text: "=", options: { breakLine: true } },
    { text: "", options: { breakLine: true } },
    { text: "Net", options: {} }
]

const texteValueLigne3 = [
    { text: "Déficit", options: { breakLine: true, bold: true, underline: { color: '000000', style: 'sng' } } },
    { text: "Imputé ", options: { breakLine: false,  bullet: { code: '25CF' } } },
    { text: "sur le revenu global ", options: { breakLine: false, bold: true } },
    { text: "jusqu’à 10.700€ (hors déficit lié aux intérêts d’emprunt)", options: { breakLine: true } },
    { text: " ", options: { bullet: true, color: labelBackgroundFichePVImmo } },
    { text: "Le bien doit être loué nu jusqu’au 31 décembre de la 3e année suivant l’imputation du déficit",  options: { italic: true, breakLine: true } },
    
    { text: "Imputé ", options: { breakLine: false, bullet: { code: '25CF' } } },
    { text: "sur les revenus fonciers des 10 années suivantes ", options: { breakLine: false, bold: true } },
    { text: "pour la fraction du déficit > 10.700€ ou liée aux intérêts d’emprunt", options: { breakLine: true } },
    { text: "", options: { breakLine: true } },
    { text: "Bénéfice", options: { breakLine: true, bold: true, underline: { color: '000000', style: 'sng' } } },
    { text: "Soumis au barème progressif de l’IR (0% à 45%) et aux prélèvements sociaux (17,2%).", options: {} }
]

// Plus-value
const texteValueLigne4 = [
    { text: "En cas de vente d’un bien source de revenus fonciers, l’imposition relève du régime des ", options: { breakLine: false } },
    { text: "plus-values immobilières des particuliers.", options: { breakLine: false, bold: true } }
]

export const revenusFonciersDataFichePVImmo = [
    [
        { text: "Recettes", options: { ...titleValueStyleFichePVImmo } },
        { text: "", options: { fill: 'FFFFFF' } },
        { text: texteValueLigne1, options: { ...rowPropsFichePVImmo, margin: tableMarginFichePVImmo } },
    ],
    [ ...emptyRowFichePVImmo ],
    [
        { text: texteLabelLigne2, options: { ...titleValueStyleFichePVImmo } },
        { text: "", options: { fill: 'FFFFFF' } },
        { text: texteValueLigne2, options: { ...rowPropsFichePVImmo, margin: tableMarginFichePVImmo } },
    ],
    [ ...emptyRowFichePVImmo ],
    [
        { text: texteLabelLigne3, options: { ...titleValueStyleFichePVImmo } },
        { text: "", options: { fill: 'FFFFFF' } },
        { text: texteValueLigne3, options: { ...rowPropsFichePVImmo, margin: tableMarginFichePVImmo } },
    ],
    [ ...emptyRowFichePVImmo ],
    [
        { text: "Plus-value", options: { ...titleValueStyleFichePVImmo, fill: greenLabelBackgroundFichePVImmo } },
        { text: "", options: { fill: 'FFFFFF' } },
        { text: texteValueLigne4, options: { ...rowPropsFichePVImmo, fill: 'F8FBF5', margin: tableMarginFichePVImmo } },
    ]
]