import React, { useState, useRef, useEffect, useContext } from 'react'
import {
  TheContent,
  TheSidebar,
  TheFooter,
  TheHeader
} from './index'

import IdleTimer from 'react-idle-timer';
import { useHistory, useLocation } from 'react-router-dom';

import Toast from '../components/toast'
import { UtilsContext } from '../store/context/utils';
import { restApiUri } from '../config'
import { lienBilanPatrimonial, lienFicheThematique, lienIFI, lienPageAccueilSimulabox } from '../routes';
import { logout } from '../store/actions/utils/login';
import { SimulateurContext } from '../store/context/simulateur';
import { updatePostItData } from '../store/actions/simulateur';
import ModalConfirmation from '../components/ModalConfirmation/ModalConfirmation';
import { DataControlContext } from '../store/context/dataControl';
import Postit from '../components/Postit/Postit';


// Style du DIV selon les mouvements de la souris
export const divStyle = (background, x, y) => {
  const diffX = 56;
  const diffY = 125;

  return `
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: ${background};
      height: 2rem;
      width: 2rem;
      border-radius: 100%;
      transform-origin: 100% 100%;
      transform: translate(-50%, -50%);
      position:absolute;
      left:${x - diffX}px;
      top:${y - diffY}px;
      z-index:1000;
      cursor:pointer
  `
}

const TheLayout = (props) => {
  const { utilsState, utilsDispatch } = useContext(UtilsContext)
  const { simulateurState, simulateurDispatch } = useContext(SimulateurContext);
  const { headerMenuIndex } = useContext(DataControlContext);
  const history = useHistory()
  const location = useLocation()
  const [loading, setLoading] = useState(true)
  const [timeout, setTimedOut] = useState(1000 * 60 * 60)
  const [isTimedOut, setIsTimedOut] = useState(false)
  const [postItToDeleteId, setPostItToDeleteId] = useState(null);
  const [visibleDeletePostItDialog, setVisibleDeletePostItDialog] = useState(false);
  const [postItToEliminate, setPostItToEliminate] = useState(null);
  const [coordinates, setCoordinates] = useState({
    x: 0,
    y: 0
  })
  const [indexMenuBySimulateur, setIndexMenuBySimulateur] = useState(null);

  const idleTimer = useRef(null)
  const { socket, withRedirection, setWithRedirection } = props;

  // Les données du POST IT
  const { displayBlocPostIt } = simulateurState?.postItData || {};
  const cursor = useRef(null);
  const { pathname } = location;

  const onAction = (e) => {
    setIsTimedOut(false)
  }

  const onActive = (e) => {
    setIsTimedOut(false)
  }

  const changeStateInBDD = () => {
    fetch(`${restApiUri}/api/auth/changerEtat/` + JSON.parse(localStorage.getItem('user')).id, {
      method: "PUT",
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    })
      .then(resp => resp.json())
      .catch(error => console.error(error))
  }

  const onIdle = (e) => {
    if (!isTimedOut) {
      idleTimer.current.reset();
      setIsTimedOut(true)
      changeStateInBDD();
      setTimeout(() => {
        utilsDispatch(logout())
        history.push('/login');
        localStorage.clear()
      }, 500)
    }
  }

  const getProfile = async (token) => {
    return await fetch(`${restApiUri}/api/auth/profileUser`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
      .then(resp => resp.json())
      .then(data => {
        return data
      })
  }

  useEffect(() => {
    if (withRedirection) {
      history.push('/session-interrompue')
      setWithRedirection(false)
    }
  }, [withRedirection])

  useEffect(() => {
    async function fetchProfile() {
      if (props.location.search.includes("key")) {
        setLoading(true)
        const key = props.location.search.split("key=")[1].split("&")[0]
        const data = await getProfile(key)
        if (!data.error) {
          localStorage.setItem("key", key)
          localStorage.setItem("user", JSON.stringify(data))
          localStorage.setItem("token", key)
          setLoading(false)
        }
        else localStorage.clear()
      }
      else if (!localStorage.getItem("key")) {
        setLoading(false)
        if (!localStorage.getItem('token') || !localStorage.getItem('user') || !JSON.parse(localStorage.getItem('user')).pole) {
          localStorage.setItem('to', props.location.pathname.replace('login', '') || '/')
          history.push('/login')
        }
      }
    }
    fetchProfile()
  }, [])

  // A chaque fois qu'il y a de changement sur les menus
  useEffect(() => {
    let index = undefined;

    // Pour le Bilan patrimonial
    if (pathname.includes(lienBilanPatrimonial)) {
      index = headerMenuIndex.bilanPatrimonial
    }

    setIndexMenuBySimulateur(index);

  }, [pathname, JSON.stringify(headerMenuIndex)])

  // Fermeture immédiate du bloc de personnalisation de simulation si on ouvre le bloc de Post It
  /*useEffect(() => {
    if(simulateurState?.showFormulairePersonnalisationSimulation){
      simulateurDispatch(updateShowPersonnalisationSimulationState({ showFormulairePersonnalisationSimulation: false }))
    }
  }, [simulateurState?.postItData?.displayBlocPostIt])*/

  return (
    <>
      {
        !loading &&
        <div className="c-app c-default-layout">

          <Toast
            autoDelete={utilsState?.toasts?.find((element) => element.autoDelete === false) ? false : true}
            dismissTime={3000}
            position="top-right"
          />
          <IdleTimer
            ref={idleTimer}
            element={document}
            onActive={onActive}
            onIdle={onIdle}
            onAction={onAction}
            debounce={250}
            timeout={timeout} />
          {
            !localStorage.getItem("key") &&
            <TheSidebar />
          }
          <div className="c-wrapper" style={{ zIndex: '1' }}>
            {
              !localStorage.getItem("key") &&
              <TheHeader socket={socket} />
            }
            <div className="c-body" style={{ paddingTop: '10px', background: location.pathname.includes(`${lienFicheThematique}/`) ? '#F3F6F9' : (location.pathname === lienPageAccueilSimulabox) ? '#F3F6F9' : '#F8F8F8' }}>
              <TheContent
                cursor={cursor}
                socket={socket}
                setPostItToDeleteId={setPostItToDeleteId}
                setCoordinates={setCoordinates}
              />
            </div>
            <TheFooter />
          </div>

          {/** Les commentaires sur les POST-IT */}
          {
            simulateurState?.postItData?.displayBlocPostIt && (
              <Postit />
            )
          }
        </div>
      }
    </>
  )
}

export default TheLayout
