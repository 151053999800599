const anneesDeficitsAnterieurs = (nombreAnnee=6) => {
  const debutAnnee = nombreAnnee === 6 ? 2014 : 2010;
  const currentAnnee = new Date().getFullYear();
  const annees = [];
  let id = 1;

  for(let i=debutAnnee; i<currentAnnee; i++){
    annees.push({
      id: id,
      annee: i,
      valeur: 0
    })

    id += 1;
  }

  return JSON.parse(JSON.stringify(annees))
}

export const fiscaliteRevenusDefaultData = JSON.parse(JSON.stringify({
    withCalcul: true,
    inResultPage: false,
    activeSimu: false,
    csgModified: false,
    idSimulation: null,
    annee_en_cours:`${new Date().getFullYear() - 1}-12-12`,
    foyer_fiscal: {
        "annee": new Date().getFullYear() - 1,
        "annee_revenu": new Date().getFullYear() - 1,
        "situation_familial": 1,
        "nb_enfant_a_charge": 0,
        "nb_enfant_a_charge_en_residence": 0,
        "statut_parent_isole": 0,
        "nb_demi_part_supp": 0,
        "nb_quart_part_supp": 0,
        "domicile_fiscal": 0
    },
    revenu_activite_retraite: {
        "traitement_salaire_pensions_retraite": {
            "traitement_salaire_revenu_gerance": {
                "conjoint1": 0,
                "conjoint2": 0,
                "personne_a_charge": 0,
                "montant_frais_reel_personne_a_charge": 0,
                "frais_pro_personne_a_charge": 0,
                "frais_pro_conjoint1": 0,
                "frais_pro_conjoint2": 0,
                "montant_frais_reel_conjoint1": 0,
                "montant_frais_reel_conjoint2": 0,
                "personne_a_charge_suite": []

            },
            "personne_retraite": 0,
            "personne_retraite_conjoint1": 0,
            "personne_retraite_conjoint2": 0
        },
        "revenu_activite_professionnelles": {
            "revenu_agricoles": {
                "regime_micro_BA": {
                    "recette_brute_sans_abattement": 0
                },
                "regime_benefice_reel": {
                    "revenu_imposable": 0,
                    "deficit": 0,
                    "revenu_imposer_aux_prelevement_sociaux": 0
                }
            },
            "bic_professionnels": {
                "regime_micro_BIC": {
                    "recette_brute_sans_abattement": {
                        "vente_marchandise": 0,
                        "prestation_service": 0
                    }
                },
                "regime_benefice_reel": {
                    "revenu_imposable": 0,
                    "deficit": 0,
                    "revenu_imposer_aux_prelevement_sociaux": 0
                }
            },
            "bnc_professionnels": {
                "regime_micro_BNC": {
                    "recette_brute_sans_abattement": 0
                },
                "regime_benefice_reel": {
                    "revenu_imposable": 0,
                    "deficit": 0,
                    "revenu_imposer_aux_prelevement_sociaux": 0
                }
            }
        },
        "revenu_activite_non_professionnelles": {
            "bic_non_professionnels": {
                "regime_micro_BIC": {
                    "recette_brute_sans_abattement": {
                        "vente_marchandise": 0,
                        "prestation_service": 0
                    }
                },
                "regime_benefice_reel": {
                    "revenu_imposable": 0,
                    "deficit": 0,
                    "deficit_anterieur": anneesDeficitsAnterieurs(),
                    "revenu_imposer_aux_prelevement_sociaux": 0
                }
            },
            "bnc_non_professionnels": {
                "regime_micro_BNC": {
                    "recette_brute_sans_abattement": 0
                },
                "regime_benefice_reel": {
                    "revenu_imposable": 0,
                    "deficit": 0,
                    "deficit_anterieur": anneesDeficitsAnterieurs(),
                    "revenu_imposer_aux_prelevement_sociaux": 0
                }
            }
        },
        "autre_revenu_gains_calcul_CEHR": {
            "autre_revenu_gains": 0
        }
    },
    "revenu_patrimoine": {
        "revenu_locatifs": {
            "revenu_locations_nues": {
                "regime_fiscal": 0,
                "regime_micro_foncier": {
                    "recette_brute_sans_abattement": 0
                },
                "regime_reel": {
                    "limite_imputation_deficit_foncier_rev": 10700,
                    "revenu_foncier_imposable": 0,
                    "deficit_imputable_revenu_foncier": 0,
                    "deficit_imputable_revenu_global": 0,
                    "deficits_anterieurs": anneesDeficitsAnterieurs()
                }
            },
            "revenu_locations_meublees": {
                "regime_fiscal": 0,
                "regime_micro_BIC": {
                    "recette_brute_sans_abattement": {
                        "location_hote": 0,
                        "autre_location": 0
                    }
                },
                "regime_benefice_reel": {
                    "revenu_imposable": 0,
                    "deficit": 0,
                    "revenu_soumis_contribution_sociales": 0,
                    "deficit_anterieur": anneesDeficitsAnterieurs(10)
                }
            }
        },
        "revenu_gain_financiers": {
            "option_fiscale": 0,
            "interet": {
                "interet_brut": 0
            },
            "dividendes": {
                "dividendes_brut_ouvrant": 0,
                "dividendes_brut_pas_ouvrant": 0,
                "dividendes_brut_associe": 0
            },
            "plus_value_financiere": {
                "plus_value_nette_sans_abattement": 0,
                "abattement_commun": 0,
                "moins_values": 0,
                "deficits_anterieurs": anneesDeficitsAnterieurs()
            },
            "produits_contrats_av": {
                "prime_versees": 0
            },
            "autres_produits_contrats_av": {
                "produit_soumis_bareme_IR": 0,
                "produits_soumis_au_PFL_sur_option": 0,
                "montant_PFL": 0
            }
        },
        "autre_revenus_gains_calcul_CEHR": {
            "plus_value_immo": 0,
            "autre_revenus_gains": 0
        },
        "frais_et_charges": {
            "deductibles_si_option_bareme": 0
        }
    },
    "charges_deductibles": {
        "csg_deductible": {
            "sur_revenu_2019": {
                "reporter_montant_imposition": 0
            }
        },
        "epargne_retraite": {
            "versement_deductible": 0
        },
        "pensions_alimentaire": {
            "montant_total": 0,
            "nb_enfant": 0
        },
        "autre_pensions_alimentaire": {
            "montant_total": 0,
            "montant_majorition": 0
        },
        "deduction_diverses": {
            "certaines_charges": 0,
            "csg_deductible": 0,
            "rachat_de_trimestres": 0,
            "autres_deductions_diverses" : 0,
            "deficit": anneesDeficitsAnterieurs()
        }
    },
    "reduction_et_credit_impot": {
        "dons": {
            "dons_organismes": 0,
            "autres_dons": 0
        },
        "emploi_salarie_domicile": 0,
        "retrait_contrat": {
            "credit_impot": 0
        },
        "autre_reduction_impot": {
            "soumis_plafonnement_global": 0
        },
        "autre_reduction_impot_ri": {
            "exlus_plafonnement_global": 0
        },
        "autres_credit_impot": {
            "soumis_plafonnement_global": 0
        },
        "RISOFICA_et_Outre_mer": {
            "soumis_plafonnement_global": 0
        },
        "avantage_fiscaux_relevant_2009_a_2012": [
            {
                "id": 0,
                "annee": 2012,
                "RI_CI_vise_plafonnement": 0
            },
            {
                "id": 1,
                "annee": 2011,
                "RI_CI_vise_plafonnement": 0
            },
            {
                "id": 2,
                "annee": 2010,
                "RI_CI_vise_plafonnement": 0
            },
            {
                "id": 3,
                "annee": 2009,
                "RI_CI_vise_plafonnement": 0
            }
        ]
    },
    "autres": {
        "syteme_quotient": {
            "revenu_exceptionnels_net": 0,
            "revene_differes_net": [
                {
                    "id": 0,
                    "valeur": 0,
                    "nb_annee": 0
                },
                {
                    "id": 1,
                    "valeur": 0,
                    "nb_annee": 0
                },
                {
                    "id": 2,
                    "valeur": 0,
                    "nb_annee": 0
                }
            ],
            "activer_mecanisme_lissage_CEHR": 0,
            "revene_fiscal_annee_n_1": 0,
            "revene_fiscal_annee_n_2": 0
        }
    },
    "calcul1": {
        "calculette1": {
            "situation_existante": {
                "deficit_anterieur": anneesDeficitsAnterieurs()
            },
            "revenus_et_charges_annee": {
                "dont_travaux_deductible_renovation_energetique": 0,
                "revnus_fonciers_brut": 0,
                "charges_deductibles_sauf_interet_emprunt": 0,
                "interet_emprunt": 0,
                "limite_importation_rev_global": 10700
            },
            "cocher_importer_donnees_IR": 0
        },
        "calculette2": {
            "situation_existante": {
                "deficit_anterieur": anneesDeficitsAnterieurs()
            },
            "plus_moins_value_nette": 0,
            "montant_reporter": {
                "plus_values_nettes_sans_abattement": 0,
                "moins_values": 0
            },
            "cocher_importer_donnees_IR": 0
        }
    },
    "dejaDansChargesDeductibles": false,
    "currentTab": 0,
    "resultatExport": {
        "resume": {},
        "detail": {}
    }
}))
