import moment from 'moment';
import { divStyle } from './containers/TheLayout';

const date = new Date();

// Date d'aujourd'hui
export const today = `${date.getDate()}/${1 + date.getMonth()}/${date.getFullYear()}`;

// Complete date
export const completeDate = (date) => {
    const currentDate = date.split('/');
    let day = currentDate[0];
    let month = currentDate[1];
    const year = currentDate[2];

    if (day.length === 1) day = `0${day}`;
    if (month.length === 1) month = `0${month}`;

    return `${day}/${month}/${year}`;
};

const intituleMoisSelonIndice = (indice) => {
    indice = parseInt(indice) || 0;

    switch (indice) {
        case 1:
            return "Janvier";

        case 2:
            return "Février";

        case 3:
            return "Mars";

        case 4:
            return "Avril";

        case 5:
            return "Mai";

        case 6:
            return "Juin";

        case 7:
            return "Juillet";

        case 8:
            return "Août";

        case 9:
            return "Septembre";

        case 10:
            return "Octobre";

        case 11:
            return "Novembre";

        default:
            return "Décembre"
    }
}

// Date d'aujourd'hui en Français
export const dateAuFormatComplet = (date) => {
    const aujourdhui = date ? date.split('/') : completeDate(today).split("/");
    const jour = parseInt(aujourdhui[0], 10);
    const mois = parseInt(aujourdhui[1], 10);
    const annee = parseInt(aujourdhui[2], 10);

    return `${(jour > 0 && jour < 10) ? '0' : ''}${jour} ${intituleMoisSelonIndice(mois)} ${annee}`
}

// Couleur de fond des simulateurs
export const COULEUR_FOND_SIMULATEUR = '#F8F8F8';

// Couleur de fond des données importées dans la plateforme
export const COULEUR_FOND_DONNEES_IMPORTEES = '#DBEDD0';

// Couleur de fond des données importées et qui ont subit des changements
export const COULEUR_FOND_DONNEES_IMPORTEES_AVEC_CHANGEMENT = "#F5E2D4";

// Nom simulation
export const nomSimulationEnFormat = (nomSimulation) => {
    return ![null, undefined, ""].includes(nomSimulation) ? nomSimulation : 'brouillon';
}

// Message de succès Enregistrement de simulation. (Création et Modification)
export const messageSuccesEnregistrementSimulation = (nomSimulation, client = false) => {
    return "Enregistrement confirmé.";
}

// Message d'erreur sur l'enregistrement de simulation
export const messageErreurEnregistrementSimulation = (nomSimulation, client = false) => {
    // return `Des erreurs se sont survenues pendant l'enregistrement de la simulation ${nomSimulationEnFormat(nomSimulation)}.`;
    return <>Des erreurs sont survenues pendant l'enregistrement {client ? 'du client' : <>de la simulation <b>{nomSimulationEnFormat(nomSimulation)}</b></>}.</>
}

// Message de succès DUPLICATION de simulation
export const messageSuccesDuplicationSimulation = (nomSimulation) => {
    // return `La simulation ${nomSimulationEnFormat(nomSimulation)} a été dupliquée avec succès.`;
    return <>La simulation <b>{nomSimulationEnFormat(nomSimulation)}</b> a été dupliquée avec succès.</>
}

// Message d'erreur DUPLICATION de simulation
export const messageErreurDuplicationSimulation = (nomSimulation) => {
    return `Des erreurs se sont survenues pendant la duplication de la simulation ${nomSimulationEnFormat(nomSimulation)}.`;
}

// Message de succès Suppression de simulation
export const messageSuccesSuppressionSimulation = (nomSimulation) => {
    return `La simulation ${nomSimulationEnFormat(nomSimulation)} a été supprimée avec succès.`
}

// Message d'erreur Suppression de simulation
export const messageErreurSuppressionSimulation = (nomSimulation) => {
    return `Des erreurs se sont survenues pendant la suppression de la simulation ${nomSimulationEnFormat(nomSimulation)}.`;
}

// Message d'erreur sur les connexions simultanées
export const messageErreurConnexionSimulatanee = "Message d'erreur pour les connexions simultanées.";

// Message d'importation réussie du BP
export const messageImportationReussieBP = "Importation réussie du bilan patrimonial";

// Message d'erreur UNIQUEMENT pour les SIMULATEURS FINANCIERS sur l'Année de l'investissement
export const messageErreurAnneeInvestissementBoxFinanciere = "Erreur sur l'année de l'investissement qui doit correspondre à l'année en cours ou à une année ultérieure.";

// Durée de déconnexion après le message d'erreur de connexion simultanée
export const dureeAttenteAvantDeconnexion = 1000;

// Rangement par ordre alphabétique
export const elementsParOrdreAlphabetique = (elements, key) => {
    return elements?.sort((a, b) => a?.[key].localeCompare(b?.[key]));
}

// Rangement par ordre anti-alphabétique
export const elementsParOrdreAntiAlphabetique = (elements, key) => {
    return elements?.sort((a, b) => b?.[key].localeCompare(a?.[key]));
}

// Date par ordre chronologique
export const dateParOrdreChronologique = (elements, key) => {
    return elements?.sort((a, b) => new Date(a?.[key]) - new Date(b?.[key]))
}

// Date par ordre chronologique
export const dateParOrdreAntiChronologique = (elements, key) => {
    return elements?.sort((a, b) => new Date(b?.[key]) - new Date(a?.[key]))
}

// Ceci est un algorithme utilisé par la fonction ci-dessous
const capitalize = (word) => {
    const lower = word.toLowerCase();
    return word.charAt(0).toUpperCase() + lower.slice(1);
}

// Texte en format CAPITAL
export const texteFormatCapital = (word) => {
    if (word && word !== "") {
        const words = word.split(" ");
        const mots = [];

        if (words.length > 0) {
            words.map((data, index) => {
                mots.push(capitalize(data));
                if (index !== words.length - 1)
                    mots.push(" ");
            })
        }

        return mots.join('');
    }
    else return "";
}

// Fonction de calcul d'âge selon la date de naissance fournie
export const calculAgeSelonDateNaissance = (value) => {
    const realValue = value.split("/");
    let age = 0;

    if (realValue[0] !== "" && realValue[1] !== "  " && realValue[2] !== "    ") {
        // Valeurs nécéssaires sur les mois
        const moisNaissance = parseInt(moment(value, 'DD/MM/YYYY').month()) + 1;
        const moisActuel = 1 + new Date().getMonth();

        // Valeurs nécéssaires sur les jours
        const jourNaissance = parseInt(realValue[0]);
        const jourActuel = parseInt(completeDate(today).split("/")[0]);

        age = new Date().getFullYear() - parseInt(moment(value, 'DD/MM/YYYY').year());

        if (moisNaissance > moisActuel) {
            age -= 1;
        }
        else if (moisNaissance === moisActuel) {
            if (jourNaissance > jourActuel) {
                age -= 1;
            }
        }

    }

    return age > 0 ? age : 0;
};

// Date de naissance éventuelle selon l'âge fourni
export const calculDateNaissanceSelonAge = (value) => {
    let date_nais = moment();
    date_nais.subtract(value, 'years');
    return date_nais.format("DD/MM/YYYY");
};

// Ajout ou suppression de POST IT
export const addDeletePostIt = (document, ImagePostIt, elements, menuIndex, setId) => {
    const elementsOnglet = elements?.filter((element) => element.tabIndex === menuIndex) || []
    const elementsHorsOnglet = elements?.filter((element) => element.tabIndex !== menuIndex) || []

    // Ajout de POST-IT si ce n'est pas encore affiché
    elementsOnglet.map((element) => {
        let postItToAdd = document.getElementById(`post-it-${element.id}-icon`);

        if (!postItToAdd) {
            const content = document.getElementById('right-main-content');
            const div = document.createElement('div');
            const newElement = document.createElement('img');
            const { id: newId, x, y } = element;

            newElement.src = ImagePostIt;
            newElement.width = 15;

            if (content?.style) {
                content.style.position = 'relative';
            }
            else {
                content.style = {};
                content.style.position = 'relative'
            }

            // ID du nouveau élément => ID du DIV
            div.id = `post-it-${newId}-icon`;

            // Style du DIV
            div.style = divStyle('transparent', x, y);

            // On mouse enter event
            div.onmouseenter = () => {
                div.style = divStyle('#FDEBCE', x, y);
            }

            // On mouse leave event
            div.onmouseleave = () => {
                div.style = divStyle('transparent', x, y);
            }

            div.appendChild(newElement);

            // On Click event on DIV element
            div.onclick = () => {
                setId(newId)
            }

            content.prepend(div);
        }
    })


    // Suppression des éléments hors onglet
    elementsHorsOnglet.map((element) => {
        let postItToDelete = document.getElementById(`post-it-${element.id}-icon`);

        if (postItToDelete) {
            postItToDelete.remove();
        }
    })
}

export function generateUniqueId() {
  // Generate a random number and convert it to base 36
  const randomNumber = Math.random().toString(36).substr(2, 9);

  // Get the current timestamp
  const timestamp = new Date().getTime().toString(36);

  // Combine the timestamp and random number to create a unique ID
  const uniqueId = timestamp + randomNumber;

  return uniqueId;
}

export const formatToUniqueId = (value) => {
  return String(value).normalize('NFD').replace(/[\u0300-\u036f]/g, '').trim().toLowerCase().replace(/[\s()°']/g, '-');
}


export const stringToBoolean = (stringValue) => {
  console.log(stringValue);
  switch(stringValue?.toLowerCase()?.trim()){
      case "true":
      case "yes":
      case "1":
        return true;

      case "false":
      case "no":
      case "0":
      case null:
      case undefined:
        return false;

      default:
        return JSON.parse(stringValue);
  }
}
