
const generateDoughnut = (pptx, slide, x, y, pourcentageValue, background, secondBackground,width,height,holesize) => {
    let dataChartAreaLine = [
        {
            labels: ["Jan","gt"],
            values: [pourcentageValue, 100 - pourcentageValue],
        },
    ];

    slide.addChart(
        pptx.ChartType.doughnut,
        dataChartAreaLine,
        {
            x: x,
            y: y,
            w: width,
            h: height,
            showLegend: false,
            showPercent: false,
            holeSize: holesize,
            chartColors: [background, secondBackground]
        }
    );
}


export default generateDoughnut;