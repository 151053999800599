import { CImg } from '@coreui/react';
import React from 'react';
import InfoIcon from '../../assets/images/i-fixe.svg';
import ErrorInfoIcon from '../../assets/images/i-fixe-error.svg';
import RendementIcon from '../../assets/images/info-rendement.svg';
import CalculIcon from '../../assets/images/info-calcul.svg';

const Information = ({ title, text, error, icon, style }) => {
    const InformationIcon = error ? ErrorInfoIcon : icon === 'rendement' ? RendementIcon : icon === 'calcul' ? CalculIcon : InfoIcon;
    const iconWidth = ['rendement', 'calcul'].includes(icon) ? 40 : 30;

    return (
        <div style={{
            display: 'flex',
            alignItems: 'center',
            background: error ? "#FFF4F6" : "rgb(221,227,252)",
            padding: 10,
            borderRadius: 5,
            color: error ? "rgb(212,114,94)" : "#28366D",
            width: '100%',
            ...(style || {})
        }}
        >
            <div><CImg src={InformationIcon} style={{ width: iconWidth, marginRight: 15 }} /></div>
            <div>
                {
                    title && (<div style={{ fontWeight: 'bold', fontSize: 14, marginBottom: 3 }}>{title}</div>)
                }
                <div style={{ fontSize: 13 }}>{text}</div>
            </div>
        </div>
    );
};

export default Information;