import React from 'react';
import { ChartDonut } from '@patternfly/react-charts';

const CircleGraph = (props) => {
    const { titre, pourcentage, baseColor, baseColorOpacity, style } = props;

    return (
        <div style={style}>
          <ChartDonut
            ariaDesc="Graphe en forme de cercle"
            constrainToVisibleArea={true}
            data={[{ x: titre, y: Math.round(pourcentage) }, { x: "Autres", y: 100 - Math.round(pourcentage) }]}
            title={`${pourcentage} %`}
            allowTooltip={true}
            colorScale={[baseColor,baseColorOpacity]}
            width={160}
            height={160}
          />
        </div>
    );
};

export default CircleGraph;