export const empruntDefaultData = JSON.parse(JSON.stringify({
    "montant_emprunt": 0,
    "taux_annuel": 0,
    "duree": 0,
    "date_emprunt": "",
    "type_emprunt": 0, 
    "type_amortissement": 0,
    "periode_echeance": 0,
    "nb_periode": 0,
    "taux_deces": 0,
    "taux_prime": 0,
    "assiette_prime": 0,
    "frais_dossier_en_pourcentage": 0,
    "frais_dossier_en_euro": 0,
    "rechercher_autre_date": "",
    "avec_assurance_amortiss": 0,
    "avec_assurance_amortiss_sup": 0,
    "annee_imposition": "",
    "valeur_immobilier": 0,
    "abattement_residence_principale": 0
}))