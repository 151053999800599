import CIcon from '@coreui/icons-react';
import React, { useState } from 'react';

const EmpruntDataBlock = ({
    title,
    secondTitle,
    secondTitlePositionFromTop,
    subtitle,
    children,
    rightTitle,
    horizontalShapeBackground,
    horizontalShapePositionFromTop,
    withCloseButton,
    defaultDisplay,
    bodyStyle,
    titleHeight,
    closeButtonColor,
    onRequestCloseButtonClick,
    closeButtonStyle
}) => {
    const [display, setDisplay] = useState(defaultDisplay || false);
    const styleContent = bodyStyle || {};
    const styleCloseButton = closeButtonStyle || {}

    const style = {
        root: {
            marginBottom: title === "CONTRATS D'ASSURANCE-VIE" ? '100px' : '15px',
            background: "#FFFFFF",
            borderRadius: '13px',
            boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
            display: 'flex',
            flexDirection: 'column'

        },
        title: {
            fontWeight: 'bold',
            marginTop: 0,
            borderRadius: display ? '13px 13px 0 0' : '13px',
            padding: '15px',
            fontSize: '15px',
            backgroundColor: '#FFFFFF',
            position: 'relative',
            height: titleHeight || '50px'
        },
        secondTitle: {
            fontWeight: 'normal',
            position: 'absolute',
            fontSize: 13,
            top: secondTitlePositionFromTop
        },
        barreVerticale: {
            width: '28px',
            height: '5px',
            borderRadius: '2px',
            backgroundColor: horizontalShapeBackground || 'rgb(84, 169, 80)',
            position: 'absolute',
            top: horizontalShapePositionFromTop || '33px'
        },
        bloc: {
            background: 'rgb(250,250,250)',
            paddingLeft: '16px',
            paddingTop: '20px',
            paddingBottom: '20px',
            width: '100%',
            fontSize: 13,
            fontStyle: 'Roboto',
            marginBottom: 5,
            borderRadius: '0 0 13px 13px'
        }
    }

    return (
        <div style={style.root}>
            <div style={style.title}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }} >
                    <div>{title}</div>
                    {
                        (rightTitle || withCloseButton) && (
                            <div style={{ paddingRight: '4px', display: 'flex' }} >
                                <>
                                    {
                                        rightTitle && (<div style={{ marginRight: withCloseButton ? 5 : 0 }} >{rightTitle}</div>)
                                    }
                                    {
                                        withCloseButton && (
                                            <div style={{ ...styleCloseButton }}>
                                                <CIcon
                                                    name={display ? "cil-X" : "cil-menu"}
                                                    size={'xl'}
                                                    style={{ color: closeButtonColor || 'grey', cursor: 'pointer' }}
                                                    onClick={() => {
                                                        if(onRequestCloseButtonClick){
                                                            onRequestCloseButtonClick(!display);
                                                        }
                                                        setDisplay(!display);
                                                    }}
                                                />
                                            </div>
                                        )
                                    }
                                </>
                            </div>
                        )
                    }
                </div>
                <div></div>
                {
                    secondTitle && (
                        <div style={style.secondTitle}>{secondTitle}</div>
                    )
                }
                {
                    subtitle && (
                        <div style={{ marginTop: 2 }} >{subtitle}</div>
                    )
                }
            </div>
            {
                display && (
                    <div style={{ ...style.bloc, ...styleContent }}>{children}</div>
                )
            }
        </div>
    )
}

export default EmpruntDataBlock;
