import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        width: '99%',
        marginLeft:"1%"
    },

    child: {
        width: '19%',
        marginRight: '8px',
    }
});

export default useStyles;