import { rowPropsFichePVImmo, tableMarginFichePVImmo, titleValueStyleFichePVImmo } from "./tableauRevenuFoncier";

const value1Ligne1 = [
    { text: "= Prix d’acquisition – Valeur Nette Comptable", options: { breakLine: true, bold: true } },
    { text: "Soumise au barème progressif de l’IR (0% à 45%) et aux ", options: { breakLine: false } },
    { text: "cotisations sociales.", options: { breakLine: true, bold: true } },
    { text: "", options: { breakLine: true } },
    { text: "Si le bien a été détenu moins de 2 ans, l’intégralité de la plus-value est une plus-value de court terme.", options: { italic: true } }
]

const value2Ligne1 = [
    { text: "= Prix de cession – Valeur Nette Comptable", options: { bold: true, breakLine: true } },
    { text: "Soumise à l’impôt sur les sociétés au taux de 15% jusqu’à 38.120€, puis 25%." }
]

const value1Ligne2 = [
    { text: "= Prix de cession – Prix d’acquisition", options: { breakLine: true, bold: true } },
    { text: "Soumise à une imposition de 12,8% et aux prélèvements sociaux de 17,2%.", options: { breakLine: true } },
    { text: "", options: { breakLine: true } },
    { text: "Article 151 septies du CGI : ", options: { breakLine: false } },
    { text: "exonération d’impôt et de prélèvements sociaux ", options: { breakLine: false, bold: true } },
    { text: "possible après 5 ans d’exploitation en LMP, si les recettes sont inférieures à 90.000€ (exonération totale) ou 126.000€ (exonération partielle)", options: { breakLine: true } },
    { text: "Les cotisations sociales restent dues.", options: { bold: true } }
]

export const pvProfessionnelleData = [
    [
        { text: "", options: {} },
        { text: "", options: {} },
        { text: "LOUEUR EN MEUBLÉ PROFESSIONNEL - LMP", options: { ...titleValueStyleFichePVImmo, color: 'FFFFFF', fill: '8497B0' } },
        { text: "", options: {} },
        { text: "IMPÔT SUR LES SOCIÉTÉS", options: { ...titleValueStyleFichePVImmo, color: 'FFFFFF', fill: '8497B0' } },
    ],
    [
        { text: '', options: { fill: 'FFFFFF', colspan: 5, margin: [ 0, 0, 0, 0 ] } }
    ],
    [
        { text: [
            { text: 'Plus-value', options: { breakLine: true } },
            { text: "court terme" }
        ], 
          options: { ...titleValueStyleFichePVImmo } },
        { text: "", options: { fill: 'FFFFFF' } },
        { text: value1Ligne1, options: { ...rowPropsFichePVImmo, margin: tableMarginFichePVImmo } },
        { text: "", options: { fill: 'FFFFFF' } },
        { text: value2Ligne1, options: { ...rowPropsFichePVImmo, margin: tableMarginFichePVImmo, valign: 'top' } }
    ],
    [
        { text: '', options: { fill: 'FFFFFF', colspan: 5, margin: [ 0, 0, 0, 0 ] } }
    ],
    [
        { text: [
            { text: 'Plus-value', options: { breakLine: true } },
            { text: "long terme" }
        ], 
          options: { ...titleValueStyleFichePVImmo } },
        { text: "", options: { fill: 'FFFFFF' } },
        { text: value1Ligne2, options: { ...rowPropsFichePVImmo, margin: tableMarginFichePVImmo } },
        { text: "", options: { fill: 'FFFFFF' } },
        { text: "Régime spécifique à la cession de certains actifs, non applicable aux investissements locatifs.", options: { ...rowPropsFichePVImmo, margin: tableMarginFichePVImmo, italic: true } }
    ],
    [
        { text: '', options: { fill: 'FFFFFF', colspan: 5, margin: [ 0, 0, 0, 0 ] } }
    ],
    [
        { text: "", options: { fill: 'FFFFFF' } },
        { text: "", options: { fill: 'FFFFFF' } },
        { text: "La plus-value est imposable en cas de transmission du bien par donation ou succession", options: { ...rowPropsFichePVImmo, margin: tableMarginFichePVImmo, bold: true } },
        { text: "", options: { fill: 'FFFFFF' } },
        { text: "", options: { fill: 'FFFFFF' } }
    ],
]