export const getTermeColonneIndexByName = (column, category) => {
    if(category === 'PEA') {
        if(column === 'dureeDeProjection')
            return 1
        else if(column === 'annee')
            return 2
        else if(column === 'valorisationDebut' || column === 'valorisationFinAventRetrait')
            return 3
        else if(column === 'redementAnnuelNet')
            return 4
        else if(column === 'montantRetrait')
            return 6
        else if(column === 'retraitBrutReel')
            return 7
        else if(column === 'gainRetire')
            return 8
        else if(column === 'tauxImposition')
            return 9
        else if(column === 'fiscaliteRetrait')
            return 10
        else if(column === 'retraitNet')
            return 11
        else if(column === 'tauxMoyenImposition')
            return 12
    }
    else if(category === 'CT') {
        if(column === 'dureeDeProjection')
            return 1
        else if(column === 'annee')
            return 2
        else if(column === 'valorisationDebut')
            return 3
        else if(column === 'versementComplementaireAnnuel')
            return 4
        else if(column === 'redementAnnuelBrut')
            return 5
        else if((column === 'fiscalitePFUPS'))
            return 6
        else if(column === 'redementAnnuelNet')
            return 7
        else if(column === 'valorisationFinAventRetrait')
            return 8
        else if(column === 'montantRetrait')
            return 9
        else if(column === 'retraitBrutReel')
            return 10
        else if(column === 'gainRetire')  
            return 11
        else if(column === 'fiscaliteRetrait')
            return 12
        else if(column === 'retraitNet')
            return 13
        else if(column === 'tauxMoyenImposition')
            return 14
    }
}

export const getTable1ColonneIndexByName = (column) => {
    if(column === 'ancienete')
        return 1
    else if(column === 'annee')
        return 2
    else if(column === 'valorisation_debut_annee')
        return 3
    else if(column === 'pourcentage_en_uc')
        return 4
    else if(column === 'versementComplementaireNet')
        return 5
    else if(column === 'produit_capitalise_bruit')
        return 6
    else if(column === 'ps_sur_fond_eu')
        return 7
    else if(column === 'rendement_annuel_net')
        return 8
    else if(column === 'valorisation_fin_annee_avant_retraite')
        return 9
    else if(column === 'montant_retrait_souhaiter')
        return 10
    else if(column === 'retrait_brut_fin_annee')  
        return 11
    else if(column === 'impot_sur_retrait_PFUPS')
        return 12
    else if(column === 'retrait_net_percu')
        return 13
}

export const getTable2ColonneIndexByName = (column) => {
    if(column === 'ancienete')
        return 1
    else if(column === 'annee')
        return 2
    else if(column === 'valorisation_fin_annee_avant_retraite')
        return 3
    else if(column === 'retrait_brut_fin_annee')
        return 4
    else if(column === 'interet_brut_retire')
        return 5
    else if(column.toLowerCase() === 'pfu')
        return 6
    else if(column.toLowerCase() === 'ps')
        return 7
    else if(column === 'impot_sur_retrait_PFUPS')
        return 8
    else if(column === 'taux_moyen_imposition')
        return 9
    else if(column === 'retrait_net_percu')
        return 10
    else if(column === 'credit_impot_remborsable')  
        return 11
}

export const getTable3ColonneIndexByName = (column) => {
    if(column === 'ancienete')
        return 1
    else if(column === 'annee')
        return 2
    else if(column === 'pourcentage_en_eu')
        return 3
    else if(column === 'valorisation_debut_annee_fond_eu')
        return 4
    else if(column === 'produit_capitalise_nets_PS_eu')
        return 5
    else if(column === 'retrait_brut_fin_annee_eu')
        return 6
    else if(column === 'pourcentage_en_uc')
        return 7
    else if(column === 'valorisation_debut_annee_fond_uc')
        return 8
    else if(column ==='produit_capitalise_nets_PS_uc')
        return 9
    else if(column === 'retrait_brut_fin_annee_uc')
        return 10
}

export const getTable4ColonneIndexByName = (column) => {
    if(column === 'ancienete')
        return 1
    else if(column === 'annee')
        return 2
    else if(column === 'montant_investi')
        return 3
    else if(column === 'rendement_cumule')
        return 4
    else if(column === 'retrait_cumule')
        return 5
    else if(column === 'total')
        return 6
    else if(column === 'fraction_fiscale_capital')
        return 7
    else if(column === 'fraction_fiscale_produit')
        return 8
    else if(column === 'fiscalite_cumule')
        return 9
    else if(column === 'fiscalite_cumule_retrait')
        return 10
}