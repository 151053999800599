import React, { useContext } from "react";
import NumberFormat from 'react-number-format';
import { CInput } from '@coreui/react';
import { SimulateurContext } from "../../store/context/simulateur";
import { activePageBlock } from "../../store/actions/simulateur";
import PostitPin from "../Postit/PostitPin";

const InputYear = (props) => {
    const { simulateurState, simulateurDispatch } = useContext(SimulateurContext);

    const handleChange = (event) => {
        // On met à jour l'état de changement de FORMULAIRE
        simulateurDispatch(activePageBlock())

        // Changement de valeur contenue dans le FORMULAIRE
        props.onChange(event);
    }

    return (
        <div className={props.className ? props.className : "col-lg-2 mr-2 input_custom"} style={{ position: 'relative', padding: 0, marginRight: props.marginRight ? props.marginRight : 0, ...props.style}}>
          <NumberFormat
              id={props.id}
              customInput={CInput}
              style={{ width: "100%", textAlign: 'right', paddingRight: 15, fontSize: 13 }}
              format="####"
              placeholder="yyyy"
              isAllowed={(values) => {
                  const { value } = values;
                  return props.min ? value >= props.min : true;
              }}
              onKeyDown={event => {
                  if (event.keyCode === 13) {
                      const form = event.target.form;
                      let index = Array.prototype.indexOf.call(form, event.target);
                      if (form.elements[index + 1])
                          form.elements[index + 1].focus();
                      event.preventDefault();
                  }
              }}
              disabled={props.disabled}
              invalid={props.invalid}
              value={props.value}
              onChange={handleChange}

          />
          <PostitPin
            // pinTop="-8px"
            // pinRight="1px"
            // pinTopHover="-15px"
            // pinRightHover="-3px"
            // pinSelectedTopHover="-11px"
            // pinSelectedRightHover="-3px"
            isPostitShow={simulateurState?.postItData?.displayBlocPostIt}
            pinId={props.id} />
        </div>
    )
}

export default InputYear;
