import { CFormGroup, CLabel } from '@coreui/react';
import React, { useState } from 'react';
import { InputSelect } from '../../../../../components/input';
import { formatToUniqueId } from '../../../../../utils';

const Aide = () => {
    const [situation, setSituation] = useState(2);
    const mgBottom = 7;

    const listeData = [
        {
            value: 0,
            label: "Démembrement de propriété",
            title: "Démembrement de propriété",
            information: (
                <div>
                    <div style={{ marginBottom: mgBottom }} >En cas de cession en pleine propriété après réunion de la propriété : la durée de détention doit être décomptée à partir de la 1ère des deux acquisitions.</div>
                    <div>En cas de cession conjointe de la nue-propriété et de l'usufruit, chacun des titulaires des droits démembrés est redevable de l'imposition sur la plus-value relative à ses droits. Les modalités de la cession (avec répartition du prix de vente ou avec clause de remploi en démembrement) sont sans incidence sur la fiscalité. S'agissant d'une opération à titre onéreux, la valorisation des droits de chacun est indiquée dans l'acte de cession (sachant que la valorisation fiscale ne s'impose pas pour les opérations à titre onéreux). Enfin, il est précisé qu'à défaut de clause de remploi prévu dans l'acte de cession, la cession conjointe des droits démembrés se traduira par une répartition du prix de vente et la fin du démembrement. </div>
                </div>
            )
        },
        {
            value: 1,
            label: "Acquisition en VEFA",
            title: "Immeuble acquis en l'état futur d'achèvement",
            information: (
                <div>La durée de détention est décomptée à partir de la date de conclusion du contrat.</div>
            )
        },
        {
            value: 2,
            label: "Construction",
            title: "Immeubles construits par le cédant",
            information: (
                <div>
                    <div style={{ marginBottom: mgBottom }} >Il convient de ventiler la plus-value.</div>
                    <div style={{ marginBottom: mgBottom }} >Pour la fraction de la plus-value afférente au terrain, il est tenu compte de la date d'acquisition du terrain indépendamment de la date du début d'exécution des travaux de construction.</div>
                    <div>Pour la fraction de la plus-value afférente à la construction, il s'agit de la date du début d'exécution des travaux de construction. La preuve du début d'exécution des travaux résulte, en principe, de la délivrance du récépissé de la déclaration d'ouverture de chantier faite en mairie. Toutefois, lorsque cette déclaration n'a pas été déposée, la preuve peut résulter soit des mémoires des entrepreneurs de maçonnerie ou de terrassement, s'ils sont suffisamment probants, soit des bons de commande délivrés par les sociétés de construction à condition qu'ils comportent l'indication des acomptes à payer (montants et dates) par rapport à l'état d'avancement des travaux et qu'ils soient appuyés des justifications relatives aux paiements effectués.</div>
                </div>
            )
        },
        {
            value: 3,
            label: "Bail à construction",
            title: "Immeubles bâtis acquis par accession à l'issue d'un bail à construction ou d'un bail ordinaire (construction sur sol d'autrui)",
            information: (
                <div>
                    <div style={{ marginBottom: mgBottom }} >Il convient de ventiler la plus-value.</div>
                    <div style={{ marginBottom: mgBottom }} >Pour la fraction de la plus-value afférente au terrain, la durée de détention est décomptée à partir de la date effective d'acquisition par le bailleur.</div>
                    <div>Pour la fraction de la plus-value afférente à la construction réalisée dans le cadre d'un bail à construction ou d'un bail ordinaire : date d'expiration du bail, initial ou prorogé, ou de sa résiliation anticipée quand bien même l'accession se réaliserait au fur et à mesure de la construction ou de l'incorporation au sol des constructions.</div>
                </div>
            )
        },
        {
            value: 4,
            label: "Acquisition en tontine",
            title: "Bien immobilier recueilli en vertu d'une clause de tontine",
            information: (
                <div>Le bénéficiaire de la clause de tontine est réputé propriétaire de la totalité de l'immeuble depuis la date d'acquisition effectuée en commun : la plus-value qu'il réalise ultérieurement à l'occasion de la cession de cet immeuble doit être déterminée à partir du prix d'acquisition payé par les acquéreurs en commun. La date de cette acquisition constitue le point de départ de l'abattement pour durée de détention.</div>
            )
        },
    ]

    const handleFormChange = (event) => {
        let { value } = event.target;
        value = parseInt(value);

        setSituation(value);
    }

    return (
        <div>
            <div style={{ padding: 10, marginBottom: 5 }} >
                <CFormGroup className='form-inline'>
                    <CLabel style={{ fontWeight: 'bold' }} className='col-lg-1' >Liste</CLabel>
                    <InputSelect
                        id={`${formatToUniqueId("Aide Liste")}-select`}
                        className='col-lg-3'
                        custom
                        onChange={handleFormChange}
                    >
                        {
                            listeData.map((data, index) => {
                                return (
                                    <option key={`ListeAide${index + 1}`} value={data.value} selected={situation === data.value} >{data.label}</option>
                                )
                            })
                        }
                    </InputSelect>
                </CFormGroup>
            </div>
            <div style={{ background: 'rgb(244,244,244)', padding: 15, display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'justify' }} >
                <div style={{ fontWeight: 'bold', marginBottom: 10 }} >{listeData.find((element) => element.value === situation ).title}</div>
                <div style={{ fontSize: 13 }} >{listeData.find((element) => element.value === situation ).information}</div>
            </div>
        </div>
    )
}

export default Aide;
