import React, { useContext, useEffect } from 'react';
import Etape from '../../Etape/Etape';
import { styleFicheThematique } from '../../style';
import TitleAndLevel from '../../TitleAndLevel/TitleAndLevel';
import TableLocationMeublee from './TableLocationMeublee/TableLocationMeublee';
import TableRevenuFoncier, { leftBackground } from './TableRevenuFoncier/TableRevenuFoncier';
import OrangeInfo from '../../../../assets/images/svg/PvImmo/ORANGE_INFO.svg';
import TableImpotSociete from './TableImpotSociete/TableImpotSociete';
import TablePVImmoParticulier from './TableauPVImmoParticulier/TableauPVImmoParticulier';
import TableAbattementDureeDetention from './TableAbattementDureeDetention/TableAbattementDureeDetention';
import TableAbattementDureeDetention2 from './TableAbattementDureeDetention2/TableAbattementDureeDetention2';
import TablePlusValueProfessionnelle from './TablePlusValueProfessionnelle/TablePlusValueProfessionnelle';
import TableSynthese from './TableSynthese/TableSynthese';
import { SimulateurContext } from '../../../../store/context/simulateur';
import { resetAction } from '../../../../store/actions/simulateur';
import exportFichePVImmo from '../../../../extra/InvestissementsImmobiliers/PlusValueImmobiliere/ficheThematique';
import { LayoutContext } from '../../../../App2';
import { useHistory } from 'react-router';
import TableSynthese2 from './TableSynthese2/TableSynthese2';
import { DataControlContext } from '../../../../store/context/dataControl';
import ScrollButton from '../../../../components/Button/scrollToTop';

const PVImmo = () => {
    const history = useHistory();
    const classes = styleFicheThematique;
    const { simulateurState, simulateurDispatch } = useContext(SimulateurContext);
    const { layoutState, setLayoutState } = useContext(LayoutContext);
    const { profilUtilisateurState } = useContext(DataControlContext);
    const { footerButtons } = layoutState;

    useEffect(() => {
        window.scrollTo(0, 0);
        setLayoutState((prevState) => {
            footerButtons.ft.pvImmo = [
                {
                    type: 'PREVIOUS',
                    text: 'Précédent',
                    onClick: () => { history.goBack() }
                }
            ];

            return {
                ...prevState,
                footerButtons
            }
        })
    }, [])

    useEffect(() => {
        if (simulateurState.action === 'export_ppt') {
            exportFichePVImmo(profilUtilisateurState);
            simulateurDispatch(resetAction());
        }
    }, [simulateurState.action])

    const steps = [
        {
            title: "Imposition des revenus locatifs",
            levels: [
                {
                    href: 'part1',
                    text: "Revenus fonciers"
                },
                {
                    href: 'part2',
                    text: "Location meublée - BIC"
                },
                {
                    href: 'part3',
                    text: "Impôt sur les sociétés"
                }
            ]
        },
        {
            title: "Imposition de la plus-value",
            levels: [
                {
                    href: "part4",
                    text: "Plus-value immobilière des particuliers"
                },
                {
                    href: "part5",
                    text: "Plus-value professionnelle"
                }
            ]
        },
        {
            title: "Synthèse",
            levels: [
                {
                    href: "part6",
                    text: "Fiscalité des revenus locatifs"
                },
                {
                    href: "part7",
                    text: "Fiscalité des plus-values de cession"
                }
            ]
        }
    ]

    return (
        <div style={classes.root} >
            <div>
                <TitleAndLevel
                    title="IMPOSITION DES INVESTISSEMENTS LOCATIFS"
                    withPlan={true}
                    steps={steps}
                />
            </div>

            <div style={{ marginTop: 50 }} id='titre1' >
                <TitleAndLevel
                    subtitle="PARTIE 1 : IMPOSITION DES REVENUS LOCATIFS"
                    text=""
                />
            </div>

            <div style={classes.content} id="part1" >
                <div style={classes.etape} >
                    <Etape simulateur='IPV' text="REVENUS FONCIERS​" />
                </div>
                <div style={{ fontSize: 13, width: '100%', display: 'flex' }} >
                    <div style={{ width: '80%' }} >
                        <TableRevenuFoncier />
                    </div>
                    <div style={{ width: '20%', textAlign: 'left', padding: 5, paddingTop: 0 }} >
                        <div style={{ fontWeight: 'bold', background: 'rgb(255,248,229)', padding: 5 }} >Sont soumis au régime des revenus fonciers</div>
                        <div style={{ background: 'rgb(255,248,229)', padding: 5 }}>Les revenus issus de la location d’immeubles nus, perçus par une personne physique ou une personne morale soumise à l’impôt sur le revenu.</div>
                    </div>
                </div>
            </div>

            <div style={classes.content} id="part2" >
                <div style={classes.etape} >
                    <Etape simulateur='IPV' text="LOCATION MEUBLÉE -  BIC" />
                </div>
                <div style={{ fontSize: 13, width: '100%', display: 'flex', marginBottom: 20 }} >
                    <div style={{ width: '80%' }} >
                        <TableLocationMeublee />
                    </div>
                    <div style={{ padding: 5, paddingTop: 0, width: '20%' }} >
                        <div style={{ background: 'rgb(255,248,229)', padding: 5, marginBottom: 50, textAlign: 'left' }} >
                            <div style={{ fontWeight: 'bold', marginBottom: 5 }} >Sont soumis au régime BIC</div>
                            <div>Les revenus issus de la location d’immeubles meublés, et perçus par une personne physique (en entreprise individuelle) ou une personne morale commerciale soumise à l’impôt sur le revenu.</div>
                        </div>

                        <div style={{ padding: 5, position: 'relative', background: 'rgb(251,229,214)', marginBottom: 70 }} >
                            <img src={OrangeInfo} width={25} style={{ position: 'absolute', top: -15, left: -6 }} />
                            <div style={{ fontWeight: 'bold', marginBottom: 5, textAlign: 'left' }} >Limite de déduction des amortissements au régime réel</div>
                            <div style={{ textAlign: 'left' }}>Les amortissements déductibles sont limités à la différence entre les loyers et les charges afférentes au bien. L’amortissement ne peut ainsi pas à lui seul créer un déficit.</div>
                        </div>

                        <div style={{ padding: 5, background: 'rgb(255,248,229)', textAlign: 'left' }} >
                            <div style={{ fontWeight: 'bold', marginBottom: 15, textAlign: 'left' }} >STATUT DU LOUEUR EN MEUBLÉ AU SENS DE L’IR ET DE LA PLUS-VALUE</div>
                            <div style={{ marginBottom: 10 }} >Au sens de l’impôt sur le revenu et de la plus-value, le loueur en meublé peut être considéré comme <b>professionnel (LMP)</b> ou <b>non professionnel (LMNP)</b>. Le passage d’un statut à l’autre est <b>automatique</b>.</div>
                            <div>Le <b>foyer fiscal</b> est LMP si deux conditions sont remplies :</div>
                            <ul>
                                <li>Les recettes de location meublée perçues par le foyer fiscal sont supérieures à 23.000€</li>
                                <li>Les recettes de location meublée sont supérieures aux autres revenus professionnels du foyer fiscal</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div style={{ display: 'flex', width: '100%', fontSize: 13 }} >
                    <div style={{ background: 'rgb(255,248,229)', padding: 10, width: '40%', marginRight: '5%', textAlign: 'left' }} >
                        <div style={{ marginBottom: 30 }} >
                            <div style={{ fontWeight: 'bold' }} >SPÉCIFICITÉS AU TITRE DE L’IFI</div>
                            <div style={{ width: '52px', height: '5px', background: 'rgb(68,84,106)', borderRadius: 5 }} ></div>
                        </div>
                        <div>
                            <div style={{ fontWeight: 'bold' }} >Que le loueur soit considéré comme un LMP ou LMNP, les biens donnés en location meublée peuvent être exonérés d’IFI en tant que biens professionnels si trois conditions sont respectées :</div>
                            <ul>
                                <li>Les recettes de location meublée perçues par le foyer fiscal sont supérieures à 23.000€</li>
                                <li>Les revenus nets tirés de la location meublée sont supérieurs aux autres revenus professionnels du foyer fiscal.</li>
                                <li style={{ listStyle: 'none', fontStyle: 'italic' }} >Il s’agit des revenus nets : si la location meublée présente un deficit, l’exonération est impossible.</li>
                                <li>L’activité de location meublée est <b>exercée à titre professionnel et principal</b>  par le contribuable ou un membre de son foyer fiscal IFI.</li>
                            </ul>
                        </div>
                    </div>
                    <div style={{ background: 'rgb(255,248,229)', padding: 10, width: '40%', textAlign: 'left' }} >
                        <div style={{ marginBottom: 30 }} >
                            <div style={{ fontWeight: 'bold' }} >SPÉCIFICITÉS DE LA LOCATION MEUBLÉE EXERCÉE PAR UNE PERSONNE MORALE (EURL, SARL, SNC)</div>
                            <div style={{ width: '52px', height: '5px', background: 'rgb(68,84,106)', borderRadius: 5 }} ></div>
                        </div>
                        <div>
                            <div style={{ marginBottom: 35 }} >
                                <div style={{ fontWeight: 'bold' }} >Imposition des revenus</div>
                                <div>Régime réel uniquement.</div>
                            </div>

                            <div>
                                <div style={{ fontWeight: 'bold' }} >Charges sociales</div>
                                <div>L’affiliation à un régime de sécurité sociale ne dépend pas du statut LMP/LMNP, mais des conditions d’affiliation de droit commun des gérants. Notamment, un gérant minoritaire de SARL est redevable des prélèvements sociaux, tandis qu’un gérant majoritaire est redevable des cotisations sociales.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style={classes.content} id="part3" >
                <div style={classes.etape} >
                    <Etape simulateur='IPV' text="IMPÔT SUR LES SOCIÉTÉS" />
                </div>
                <div style={{ marginTop: -7, marginBottom: 10, fontSize: 13 }} >Régime d’imposition des revenus perçus par une <b>entreprise individuelle ou une personne morale soumise à l’impôt sur les sociétés</b>, qu’il s’agisse d’une location nue ou meublée.</div>
                <div style={{ fontSize: 13 }} >
                    <TableImpotSociete />
                </div>
            </div>

            <div style={{ marginTop: 50 }} id='titre1' >
                <TitleAndLevel
                    subtitle="PARTIE 2 : IMPOSITION DE LA PLUS-VALUE"
                    text=""
                />
            </div>

            <div style={classes.content} id="part4" >
                <div style={classes.etape} >
                    <Etape simulateur='IPV' text="PLUS-VALUE IMMOBILIÈRE DES PARTICULIERS" />
                </div>
                <div style={{ marginTop: -7, marginBottom: 10, fontSize: 13 }} >La plus-value est constituée de la <b>différence entre le prix de cession et le prix d’acquisition</b>. Chacune de ces valeurs est retraitée afin de tenir compte de différents frais et d’abattements pour durée de détention.</div>
                <div style={{ fontSize: 13, marginBottom: 25 }} >
                    <TablePVImmoParticulier />
                </div>
                <div>
                    <div style={{ fontWeight: 'bold', marginBottom: 10 }} >ABATTEMENTS POUR DURÉE DE DETENTION</div>
                    <div style={{ display: 'flex', width: '90%' }} >
                        <div style={{ width: '35%', marginRight: 20 }} >
                            <TableAbattementDureeDetention />
                        </div>
                        <div style={{ width: '35%' }} >
                            <TableAbattementDureeDetention2 />
                        </div>
                    </div>
                </div>
            </div>

            <div style={classes.content} id="part5" >
                <div style={classes.etape} >
                    <Etape simulateur='IPV' text="PLUS-VALUE PROFESSIONNELLE" />
                </div>
                <div style={{ marginTop: -7, marginBottom: 10, fontSize: 13 }} >
                    <div>La vente d’un bien immobilier par une société soumise à l’impôt sur les sociétés ou par un LMP entraîne le calcul d’une <b>plus-value professionnelle</b>.</div>
                    <div>Ce régime distingue deux plus-values, de court et long terme.</div>
                    <div>Les modalités de calcul et d’imposition sont cependant différentes selon qu’il s’agisse d’une activité soumise aux BIC ou à l’impôt sur les sociétés.</div>
                </div>
                <div style={{ fontSize: 13, marginBottom: 25 }} >
                    <TablePlusValueProfessionnelle />
                </div>
            </div>

            <div style={{ marginTop: 50 }} id='titre1' >
                <TitleAndLevel
                    subtitle="PARTIE 3 : SYNTHÈSE"
                    text=""
                />
            </div>

            <div style={classes.content} id="part6" >
                <div style={classes.etape} >
                    <Etape simulateur='IPV' text="FISCALITÉ DES REVENUS LOCATIFS" />
                </div>
                <div style={{ fontSize: 13, marginBottom: 25 }} >
                    <TableSynthese />
                </div>
            </div>

            <div style={classes.content} id="part7" >
                <div style={classes.etape} >
                    <Etape simulateur='IPV' text="FISCALITÉ DES PLUS-VALUES DE CESSION" />
                </div>
                <div style={{ fontSize: 13, marginBottom: 25 }} >
                    <TableSynthese2 />
                </div>
            </div>
        </div>
    )
}

export default PVImmo;