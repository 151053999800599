import { CRow } from '@coreui/react';
import React from 'react';
import { numberFormat } from '../../../../../extra/all_ppt_render';
import Scenario from './Scenario/Scenario';
import useStyles from './style';

// props.data contient les données
const TableauBord2 = (props) => {
    const classes = useStyles();
    const data = props.data;

    return (
        <div className={classes.root} style={{ height: '100%' }} >
            <div className={classes.actuelle} className="col-lg-6" style={{ paddingRight: 10, paddingLeft: 0, height: '100%', borderRight:"1px dashed" }} >
                <div className={classes.title}>Situation actuelle</div>
                <div className={classes.line}></div>

                <div className={classes.actuelleContent} style={{ height: '21%' }} >
                    <div className="col-lg-12" style={{ marginTop: 6, padding: 0 }} >
                        <div style={{ display: 'grid', gridTemplateColumns: "1fr 1fr 1fr", textAlign: 'left', paddingLeft: 0, fontSize: 13 }} >
                            <div  className='structureBlock' style={{ background: '#fff', color: "#FFFFFF", padding: 15, width: "90%", borderRadius: 5 }} >
                                <div style={{color:"#7F869A", paddingBottom:4}}>Prix de cession</div>
                                <div style={{background:'#8E5FA8', width:30, height:4, borderRadius:4, position:'absolute',marginTop:-6}}></div>
                                <div style={{ fontWeight: 'bold', fontSize: 19, color:"#585C66" }} >
                                    {
                                        data &&
                                            data.prix_cession ? numberFormat(Math.round(data.prix_cession)) : 0
                                    } €
                                </div>
                            </div>
                            <div  className='structureBlock' style={{ background: '#fff', color: "#FFFFFF", padding: 15, width: "90%", borderRadius: 5 }} >
                                <div style={{color:"#7F869A",paddingBottom:4}}>Plus-value brute</div>
                                <div style={{background:'#318FCC', width:30, height:4, borderRadius:4, position:'absolute',marginTop:-6}}></div>
                                <div style={{ fontWeight: "bold", fontSize: 19, color:"#585C66" }} >
                                    {
                                        data &&
                                            data.plus_value_brute ? numberFormat(Math.round(data.plus_value_brute)) : 0
                                    } €
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className={classes.actuelleContent2}>
                        <div className='compareBlock' style={{ width: "48%", marginTop:7, borderRadius:5, height:336 }}>
                            <Scenario
                                number={1}
                                data={
                                    data &&
                                        data.scenario_1 ? data.scenario_1 : undefined
                                }
                            />
                        </div>
                        <div className='compareBlock' style={{ width: "48%", marginTop:7, borderRadius:5, height:336 }}>
                            <Scenario
                                number={2}
                                data={
                                    data &&
                                        data.scenario_2 ? data.scenario_2 : undefined
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className={classes.apres} className="col-lg-6" style={{ paddingLeft: 10, paddingRight: 0, height: '100%' }} >
                <div className={classes.title}>Cession après apports / donations</div>
                <div className={classes.line2}></div>

                <div className={classes.apresContent} style={{ height: '21%' }} >
                    <div className="col-lg-12" style={{ marginTop: 6, padding: 0 }} >
                        <div style={{ display: 'grid', gridTemplateColumns: "1fr 1fr 1fr", textAlign: 'left' , fontSize: 13}} >
                            <div className='structureBlock' style={{ background: '#fff', color: "#FFFFFF", padding: 15, width: "90%", borderRadius: 5 }} >
                                <div style={{color:"#7F869A",paddingBottom:4}}>Apports à Holding</div>
                                <div style={{background:'#707070', width:30, height:4, borderRadius:4, position:'absolute',marginTop:-5}}></div>
                                <div style={{ fontWeight: 'bold', fontSize: 19, color:"#585C66" }} >
                                {
                                    data &&
                                    data.apport_holding ? numberFormat(Math.round(data.apport_holding)) : 0
                                } €
                                </div>
                            </div>
                            <div className='structureBlock' style={{ background: '#fff', color: "#FFFFFF", padding: 15, width: "90%", borderRadius: 5 }} >
                                <div style={{color:"#7F869A",paddingBottom:4}}>Donation en PP</div>
                                <div style={{background:'#707070', width:30, height:4, borderRadius:4, position:'absolute',marginTop:-6}}></div>
                                <div style={{ fontWeight: "bold", fontSize: 19, color:"#585C66" }} >
                                {
                                    data &&
                                    data.donation_pp ? numberFormat(Math.round(data.donation_pp)) : 0
                                } €
                                </div>
                            </div>
                            <div className='structureBlock' style={{ background: '#fff', color: "#FFFFFF", padding: 15, width: "90%", borderRadius: 5 }} >
                                <div style={{color:"#7F869A",paddingBottom:4}}>Donation en NP</div>
                                <div style={{background:'#707070', width:30, height:4, borderRadius:4, position:'absolute',marginTop:-6}}></div>
                                <div style={{ fontWeight: "bold", fontSize: 19, color:"#585C66" }} >
                                {
                                    data &&
                                    data.donation_np ? numberFormat(Math.round(data.donation_np)) : 0
                                } €
                                </div>
                            </div>
                        </div>
                        {/* <CRow style={{ background: 'rgb(232,232,232)', borderRadius: 5, marginBottom: 5, padding: 5 }} >
                            <div className="col-lg-6"  style={{ textAlign: 'left' }} >Apports à Holding</div>
                            <div className="col-lg-6" style={{ textAlign: 'right', fontWeight: 'bold' }} >
                                {
                                    data &&
                                    data.apport_holding ? numberFormat(Math.round(data.apport_holding)) : 0
                                } €
                            </div>
                        </CRow>
                        
                        <CRow  style={{ background: 'rgb(209,209,209)', borderRadius: 5, marginBottom: 5, padding: 5 }}>
                            <div className="col-lg-6" style={{ textAlign: 'left' }} >Donation en PP</div>
                            <div className="col-lg-6" style={{ textAlign: 'right', fontWeight: 'bold' }} >
                                {
                                    data &&
                                    data.donation_pp ? numberFormat(Math.round(data.donation_pp)) : 0
                                } €
                            </div>
                        </CRow>
                        
                        <CRow  style={{ background: 'rgb(209,209,209)', borderRadius: 5, padding: 5 }}>
                            <div className="col-lg-6" style={{ textAlign: 'left' }} >Donation en NP (contre-valeur en PP)</div>
                            <div className="col-lg-6" style={{ textAlign: 'right', fontWeight: 'bold' }} >
                                {
                                    data &&
                                    data.donation_np ? numberFormat(Math.round(data.donation_np)) : 0
                                } €
                            </div>
                        </CRow> */}
                    </div>
                    <div className={classes.apresContent2}>
                        <div className='compareBlock' style={{ width: "48%", marginTop:7, borderRadius:5 }}>
                            <Scenario
                                number={3}
                                data={
                                    data &&
                                        data.scenario_3 ? data.scenario_3 : undefined
                                }
                            />
                        </div>
                        <div className='compareBlock' style={{ width: "48%", marginTop:7, borderRadius:5 }}>
                            <Scenario
                                number={4}
                                data={
                                    data &&
                                        data.scenario_4 ? data.scenario_4 : undefined
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TableauBord2;