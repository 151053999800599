import React, { useContext, useState } from 'react';
import useStyles from './style';
import { numberFormat } from '../../../../../extra/all_ppt_render';
import { CessionContext } from '../../CessionHaut';
import { moveSpaceIntoCurrency } from '../../OperationAvantCession/ApportsAvantCession/ApportsAvantCession';
import Loading from '../../../../../components/loader';
import tableBorderBottom from '.';

export const nombreEnfantsParConjoint = (enfants, conjointIndex) => {
    let nombre = 0;

    enfants.map((data, index) => {
        if(data.parents === "Enfant commun" || data.parents === `Enfant du conjoint ${conjointIndex + 1}`)
            nombre++;
    })

    return nombre;
};

export const conditionAffichageEnfant = (enfs, parentIndex, enfantIndex) => {
    let cond = false;
    if(enfs && enfs[enfantIndex]) {
        if(enfs[enfantIndex].parents === `Enfant du conjoint ${parentIndex + 1}` || enfs[enfantIndex].parents === "Enfant commun") cond = true;
        else cond = false;
    }
    return cond;
};

export const idDernierEnfantConjoint = (enfants, conjointIndex) => {
    let idDernier = 0;
    let nomDernier = '';

    enfants.map((data, index) => {
        if(data.parents === "Enfant commun" || data.parents === `Enfant du conjoint ${conjointIndex+1}`){
            idDernier = data.id;
            nomDernier = data.prenom;
        }
    });

    return {
        id: idDernier,
        nom: nomDernier
    };
};

const TableauSynthese = () => {
    const classes = useStyles();

    const titres = [
        "Actionnaires familiaux",
        "Répartition en %",
        "Valeur des titres",
        "Prix de revient fiscal",
        "Plus-value brute"
    ];
    
    const { cessionHaut } = useContext(CessionContext);
    const { identiteActionnaire: identite, projetCessionSociete: projet } = cessionHaut;
    const { total } = projet;
    const { pp, np1, np2, somme } = total;
    const { conjoints, enfants } = identite;
    const { enfantsFamiliauxDP: demembrement, enfantsFamiliauxPP, holding, conjoints: conjointsProjet } = projet;

    const [ loading, setLoading ] = useState(false);
    const { affichageNomPersonnalise: affichage } = identite;

    const calculPlusValueBrute = (valeurTitres, prixRevientFiscal) => {
        return numberFormat(( !isNaN(valeurTitres) ? valeurTitres : (valeurTitres !== "" ?  moveSpaceIntoCurrency(valeurTitres) : 0) ) - ( !isNaN(prixRevientFiscal) ? prixRevientFiscal : (prixRevientFiscal !== "" ? moveSpaceIntoCurrency(prixRevientFiscal) : 0) ));
    };

        return !loading ? (
            <div className='structureBlock' style={{ display: 'flex', background:"#fff",padding:"12px 7px 10px 7px", borderRadius:8, fontSize: 13 }} >
                <table
                    id="tbSynthese"
                    cellSpacing={5} 
                    cellPadding={5} 
                    style={{ 
                        width: "100%",
                        border:'none'
                    }}
                >
                    <tr style={{ borderBottom: "2px solid #809DB4" }} >
                        {
                            titres.map((data, index) => {
                                return (
                                    <th 
                                        key={index} 
                                        className={classes.th}
                                        style={{ textAlign: index >= 1 ? "center" : "left" }}
                                    >
                                        {data}
                                    </th>
                                )
                            })
                        }
                    </tr>
                    {
                        conjointsProjet.map((data, index) => {
                            let plusValueBrute = calculPlusValueBrute(data.valeurTitres, data.prixRevientFiscal);
                            if( index === 0 || ( index === 1 && conjoints[0].marieOuPasce === "Oui"  ) )
                                return (
                                    <tr key={index} style={{ ...tableBorderBottom(true) }} >
                                        <th style={{background:"#F3F3F3", borderBottom:'1px solid #fff'}}>
                                            {`${ affichage ? `${conjoints[index].nomPersonnalise !== "" ? conjoints[index].nomPersonnalise : `Conjoint ${index + 1}`}` : `Conjoint ${index + 1}` } PP`}
                                        </th>
                                        <th style={{ textAlign: "center",background:"#F3F3F3",borderLeft:"2px solid #fff", borderBottom:'1px solid #fff' }} >{`${ data.pourcentageDetention !== "" ? data.pourcentageDetention : "0"} %`}</th>
                                        <th style={{ textAlign: "right",background:"#F3F3F3",borderLeft:"2px solid #fff", borderBottom:'1px solid #fff'  }} >{`${ !isNaN(data.valeurTitres) ? numberFormat(data.valeurTitres) : (data.valeurTitres !== "" ? data.valeurTitres : "-  ") } €`}</th>
                                        <td style={{ textAlign: "right",background:"#F3F3F3",borderLeft:"2px solid #fff", borderBottom:'1px solid #fff'  }} >{`${ !isNaN(data.prixRevientFiscal) ? numberFormat(data.prixRevientFiscal) : (data.prixRevientFiscal !== "" ? data.prixRevientFiscal : "-  ") } €`}</td>
                                        <th style={{ textAlign: "right",background:"#F3F3F3",borderLeft:"2px solid #fff", borderBottom:'1px solid #fff'  }} >{`${plusValueBrute} €`}</th>        
                                    </tr>
                                )
                        })
                    }
                    {
                        enfants.length > 1 && (
                            <tr style={{ ...tableBorderBottom(true) }} >
                                <th style={{background:"#F3F3F3", borderBottom:'1px solid #fff'}}>{`Enfants PP`}</th>
                                <th style={{ textAlign: 'center',background:"#F3F3F3", borderBottom:'1px solid #fff',borderLeft:"2px solid #fff" }} >{`${pp.pourcentageDetention} %`}</th>
                                <th style={{ textAlign: "right",background:"#F3F3F3", borderBottom:'1px solid #fff',borderLeft:"2px solid #fff" }}  >{`${numberFormat(pp.valeurTitres)} €`}</th>
                                <td style={{ textAlign: "right",background:"#F3F3F3", borderBottom:'1px solid #fff',borderLeft:"2px solid #fff" }}  >{`${numberFormat(pp.prixRevientFiscal)} €`}</td>
                                <th style={{ textAlign: "right",background:"#F3F3F3", borderBottom:'1px solid #fff',borderLeft:"2px solid #fff" }}  >{`${ !isNaN(pp.plusValueBrute) ? numberFormat(pp.plusValueBrute) : 0} €`}</th>        
                            </tr>
                        )
                    }
                    {
                        enfants.map((data, index) => {
                            let plusValueBrute = calculPlusValueBrute(enfantsFamiliauxPP[index].valeurTitres, enfantsFamiliauxPP[index].prixRevientFiscal);
                            return (
                                <tr key={index} style={{ fontWeight: enfants.length > 1 ? 'normal' : 'bold', ...tableBorderBottom(index + 1 === enfants.length ? true : false) }} >
                                    <td style={{ paddingLeft: enfants.length > 1 ? 15 : 5,borderBottom:'1px solid #fff' }} >
                                        {`${ affichage ? `${data.prenom !== "" ? data.prenom : `Enfant ${index + 1}`  }` : `Enfant ${index + 1}` } PP`}
                                    </td>
                                    <td style={{ textAlign: "center",borderBottom:'1px solid #fff' }} >{`${ enfantsFamiliauxPP[index].pourcentageDetention !== "" ? enfantsFamiliauxPP[index].pourcentageDetention : "0" } %`}</td>
                                    <td style={{ textAlign: "right",borderBottom:'1px solid #fff' }}  >{`${ !isNaN(enfantsFamiliauxPP[index].valeurTitres) ? numberFormat(enfantsFamiliauxPP[index].valeurTitres) : (enfantsFamiliauxPP[index].valeurTitres !== "" ? enfantsFamiliauxPP[index].valeurTitres : "-  ") } €`}</td>
                                    <td style={{ textAlign: "right", fontWeight: 'normal',borderBottom:'1px solid #fff' }}  >{`${ !isNaN(enfantsFamiliauxPP[index].prixRevientFiscal) ? numberFormat(enfantsFamiliauxPP[index].prixRevientFiscal) : (enfantsFamiliauxPP[index].prixRevientFiscal !== "" ? enfantsFamiliauxPP[index].prixRevientFiscal : "-  ") } €`}</td>
                                    <td style={{ textAlign: "right",borderBottom:'1px solid #fff' }}  >{`${plusValueBrute} €`}</td> 
                                </tr>
                            )
                        })
                    }
                    {
                        [np1, np2].map((data, index) => {
                            if(index === 0 || (index === 1 && conjoints[0].marieOuPasce === "Oui" ))
                                return (
                                    <>
                                        {
                                            nombreEnfantsParConjoint(enfants, index) > 1 && (
                                                <tr style={{ ...tableBorderBottom(true) }} >
                                                    <th style={{background: '#F3F3F3', borderBottom:'1px solid #fff'}}>
                                                        {`${ affichage ? `${conjoints[index].nomPersonnalise !== "" ? conjoints[index].nomPersonnalise : `Conjoint ${index + 1}` }` : `Conjoint ${index + 1}` } USUF / Enfants NP`}
                                                    </th>
                                                    <th style={{ textAlign: "center",background:"#F3F3F3", borderBottom:'1px solid #fff',borderLeft:"2px solid #fff" }} >{`${data.pourcentageDetention} %`}</th>
                                                    <th style={{ textAlign: "right",background:"#F3F3F3", borderBottom:'1px solid #fff',borderLeft:"2px solid #fff" }}  >{`${numberFormat(data.valeurTitres)} €`}</th>
                                                    <td style={{ textAlign: "right",background:"#F3F3F3", borderBottom:'1px solid #fff',borderLeft:"2px solid #fff" }}  >{`${numberFormat(data.prixRevientFiscal)} €`}</td>
                                                    <th style={{ textAlign: "right",background:"#F3F3F3", borderBottom:'1px solid #fff',borderLeft:"2px solid #fff" }}  >{`${ !isNaN(data.plusValueBrute) ? numberFormat(data.plusValueBrute) : 0} €`}</th>
                                                </tr>
                                            )
                                        }
                                        {
                                            demembrement[index].enfants.map((donnee, indice) => {
                                                let plusValueBrute = calculPlusValueBrute(donnee.valeurTitres, donnee.prixRevientFiscal);

                                                return conditionAffichageEnfant(enfants, index, indice)  ? (
                                                    <tr style={{ fontWeight: nombreEnfantsParConjoint(enfants, index) > 1 ? 'normal' : 'bold' , ...tableBorderBottom(idDernierEnfantConjoint(enfants, index).id === indice + 1) }} >
                                                        <td style={{ paddingLeft: nombreEnfantsParConjoint(enfants, index) > 1 ? 15 : 5, borderBottom:'1px solid #fff' }} >
                                                            {`${ affichage ? `${conjoints[index].nomPersonnalise !== "" ? conjoints[index].nomPersonnalise : `Conjoint ${index + 1}`  }` : `Conjoint ${index + 1}` } USUF / ${ affichage ? `${enfants[indice].prenom !== "" ? enfants[indice].prenom : `Enfant ${indice + 1}`}` : `Enfant ${indice + 1}` } NP`}
                                                        </td>
                                                        <td style={{ textAlign: "center", borderBottom:'1px solid #fff' }} >{`${donnee.pourcentageDetention !== "" ? donnee.pourcentageDetention : "0"} %`}</td>
                                                        <td style={{ textAlign: "right", borderBottom:'1px solid #fff' }} >{`${ !isNaN(donnee.valeurTitres) ? numberFormat(donnee.valeurTitres) : ( donnee.valeurTitres !== "" ? numberFormat(moveSpaceIntoCurrency(donnee.valeurTitres)) : "-  " ) } €`}</td>
                                                        <td style={{ textAlign: "right", fontWeight: 'normal', borderBottom:'1px solid #fff' }} >{`${ !isNaN(donnee.prixRevientFiscal) ? numberFormat(donnee.prixRevientFiscal) : ( donnee.prixRevientFiscal ? donnee.prixRevientFiscal : "-  " ) } €`}</td>
                                                        <td style={{ textAlign: "right", borderBottom:'1px solid #fff' }} >{`${plusValueBrute} €`}</td>        
                                                    </tr>
                                                ) : <></>
                                            })
                                        }
                                    </>
                                )
                        })
                    }
                    {
                        holding && (
                            <tr style={{ ...tableBorderBottom(true) }} >
                                <th style={{background: '#F3F3F3', borderBottom:'1px solid #fff'}}>
                                    {
                                        affichage ? (holding.nomPersonnalise !== "" ? holding.nomPersonnalise : 'HOLDING IS') : 'HOLDING IS'
                                    }
                                </th>
                                <th style={{ textAlign: "center",background:"#F3F3F3", borderBottom:'1px solid #fff',borderLeft:"2px solid #fff" }} >{`${ holding.pourcentageDetention !== "" ? holding.pourcentageDetention : "0" } %`}</th>
                                <th style={{ textAlign: "right",background:"#F3F3F3",borderLeft:"2px solid #fff" }}  >{`${ !isNaN(holding.valeurTitres) ? numberFormat(holding.valeurTitres) : (holding.valeurTitres !== "" ? holding.valeurTitres : "-  ") } €`}</th>
                                <td style={{ textAlign: "right",background:"#F3F3F3", borderLeft:"2px solid #fff" }}  >{`${ !isNaN(holding.prixRevientFiscal) ? numberFormat(holding.prixRevientFiscal) : (holding.prixRevientFiscal !== "" ? holding.prixRevientFiscal :"-  " ) } €`}</td>
                                <th style={{ textAlign: "right",background:"#F3F3F3",borderLeft:"2px solid #fff" }}  >{`${ calculPlusValueBrute(holding.valeurTitres, holding.prixRevientFiscal) } €`}</th>
                            </tr>
                        )
                    }
                            <tr style={{  }} >
                                <td></td>
                                <td style={{ textAlign: 'center', background: "rgb(64,64,64)", color: "#FFFFFF" }} >{`${somme.pourcentageDetention} %`}</td>
                                <td style={{ textAlign: 'right', background: "rgb(64,64,64)", color: "#FFFFFF"  }} >{`${numberFormat(somme.valeurTitres)} €`}</td>
                                <td style={{ textAlign: 'right', background: "rgb(64,64,64)", color: "#FFFFFF"  }} >{`${numberFormat(somme.prixRevientFiscal)} €`}</td>
                                <td style={{ textAlign: 'right', background: "rgb(64,64,64)", color: "#FFFFFF"  }} >{`${ !isNaN(somme.plusValueBrute) ? numberFormat(somme.plusValueBrute) : 0} €`}</td>
                            </tr>
                </table>
            </div>
            ) : (<Loading />)
};

export default TableauSynthese;