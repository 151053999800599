import React from 'react';
import { completeDate, now, numberFormat } from '../../../../../extra/all_ppt_render';
import './style.scss';
import { formatDateEmp } from '../../Emprunt';

const Bloc2 = (props) => {
    const  { data } = props;

    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }} >
            <div style={{ fontWeight: 'bold', color: '#7a86a1', borderBottom: '2px solid #e7edf3', padding: "18px 15px 15px 15px" }} >Capital restant dû</div>
            <div style={{ height: 200, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', padding: '15px 15px 25px 15px' }} >

                <div style={{ display: 'flex', height: '50px', flexDirection: 'column' }} >
                    <div style={{ color: '#7a86a1', fontWeight: 'bold' }}>Montant en euros</div>
                    <div style={{ color: 'rgb(23,157,199)', fontWeight: 'bold', fontSize: 20 }} >
                        {
                            data &&
                            data.montant_en_euro ? numberFormat(data.montant_en_euro) : 0
                        } €
                    </div>

                    <div style={{ display: 'flex', whiteSpace: 'nowrap'}} >
                        <div>
                            <div>À la date du : <span style={{ color: 'rgb(127,134,154)' }} >{completeDate(now)}</span></div>
                        </div>
                    </div>
                </div>

                { (data?.montant_en_euro_a_date_bp >= 0) && (
                  <div style={{ display: 'flex', height: '50px', flexDirection: 'column', flex: '1 1 0px', marginTop: 27 }} >
                      <div style={{ color: '#7a86a1', fontWeight: 'bold' }}>Montant en euros</div>
                      <div style={{ color: 'rgb(23,157,199)', fontWeight: 'bold', fontSize: 20 }} >
                          {
                              data &&
                              data.montant_en_euro ? numberFormat(data?.montant_en_euro_a_date_bp) : 0
                          } €
                      </div>

                      <div style={{ display: 'flex', whiteSpace: 'nowrap' }} >
                          <div>
                              <div>À la date du : <span style={{ color: 'rgb(127,134,154)' }} >{formatDateEmp(data?.date_bilan_patrimoine, '-', '/')}</span></div>
                          </div>
                      </div>
                  </div>
                )}




            </div>
        </div>
    )
};

export default Bloc2;
