export const styleFicheThematique = {
    root: {
        marginLeft: 0,
        paddingLeft: 35,
        paddingTop: 15,
        fontFamily:'Roboto',
        textAlign: "justify",
        marginBottom: "3%",
        position: 'relative'
        // background: '#FFFFFF'
    },

    content: {
        marginTop: "1%",
        padding: 5,
        paddingLeft: 22,
        paddingRight: 22,
        background: '#FFFFFF'//'rgb(248,248,248)'
    },

    subtileIR: {
      color: "rgb(82, 113, 199)",
      fontWeight: "bold",
      fontSize: "14px"
    },

    contentIR: {
      marginTop: "1%",
      padding: 5,
      paddingLeft: 22,
      paddingRight: 22,
      paddingBottom: 20,
      background: '#FFFFFF'//'rgb(248,248,248)'
  },

    etape: {
        paddingTop: 14,
        marginBottom: 12
    },

    tab: {
        fontSize: 13,
        marginLeft: 40,
        marginBottom: 13
    }
}
