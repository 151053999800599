import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    div1: {
        background: '#FFFFFF',
        fontWeight: 'bold',
        padding: 20,
        textAlign: 'center',
        borderRadius: 15
    },
    div2: {
        padding: 20,
        textAlign: 'center',
        borderRadius: "10px 10px 0 0"
    },
    div3: {

    },
    div4: {
        
    }
});

export default useStyles;