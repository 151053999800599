import React, { useContext } from "react";
import { CSelect } from '@coreui/react';
import { SimulateurContext } from "../../store/context/simulateur";
import { activePageBlock } from "../../store/actions/simulateur";
import PostitPin from "../Postit/PostitPin";

const SelectDuration = (props) => {
    const { simulateurState, simulateurDispatch } = useContext(SimulateurContext);

    const handleChange = (event) => {
        // On met à jour l'état de changement de FORMULAIRE
        simulateurDispatch(activePageBlock())

        // Changement de valeur contenue dans le FORMULAIRE
        props.onChange(event);
    }

    return (
      <div className={props.className ? props.className : "col-lg-2 mr-2"} style={{ position: 'relative', padding: 0, marginRight: props.marginRight ? props.marginRight : 0, ...props.style}}>
        <CSelect
            id={props.id}
            style={{ fontSize: 13, width: "100%" }}
            custom={props.custom}
            onKeyDown={event => {
                if (event.keyCode === 13) {
                    const form = event.target.form;
                    let index = Array.prototype.indexOf.call(form, event.target);
                    if (form.elements[index + 1])
                        form.elements[index + 1].focus();
                    event.preventDefault();
                }
            }}
            disabled={props.disabled}
            defaultValue={props.value}
            onChange={handleChange}
        >
            <option value={10} selected={props.value === 10}>10</option>
            <option value={15} selected={props.value === 15}>15</option>
            <option value={20} selected={props.value === 20}>20</option>
            <option value={25} selected={props.value === 25}>25</option>
            <option value={30} selected={props.value === 30}>30</option>
            <option value={35} selected={props.value === 35}>35</option>
            <option value={40} selected={props.value === 40}>40</option>
        </CSelect>
          {
            !props?.hidePin && (
              <PostitPin
                // pinTop="-43px"
                // pinRight="-6px"
                // pinTopHover="-49px"
                // pinRightHover="-11px"
                // pinSelectedTopHover="-45px"
                // pinSelectedRightHover="-11px"
                isPostitShow={simulateurState?.postItData?.displayBlocPostIt}
                pinId={props.id} />
            )

          }
      </div>
    )
}

export default SelectDuration
