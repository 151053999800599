import { numberFormat } from "../../all_ppt_render";
import { generateTitleAndSubtitle } from "../../utils";
import { natureBienPvImmo, titleLabel } from "./hypothese";

export const generatePageSynthese = (pptx, slide, hypothese, resultat) => {
    const x = 0.7;
    const y = 1;
    const pourcentage = resultat.rapport_impot_plus_value_brute ? parseFloat(resultat.rapport_impot_plus_value_brute).toFixed(2) : 0;

    const dataChartAreaLine = [
        {
            name: "POURCENTAGE",
            labels: ["Rapports impôts sur PV Brute", ""],
            values: [ pourcentage, 100 - pourcentage ],
        },
    ];

    generateTitleAndSubtitle(
        "SIMULATION",
        pptx,
        slide,
        {
            text: titleLabel(hypothese.type_cession),
            width: hypothese.type_cession === 0 ? 9.44 : hypothese.type_cession === 1 ? 10.75 : 4.35
        },
        undefined,
        undefined,
        {
          text: 'PLUS-VALUES IMMOBILIÈRES DES PARTICULIERS'
        }
    )

    // IPV
    slide.addText('IMPOSITION DE LA PLUS-VALUE IMMOBILIÈRE', {
        x: x - 0.3,
        y: y + 0.4,
        w: 5,
        h: 0.4,
        fontFace: 'Roboto',
        align: 'left',
        fontSize: 13,
        bold: true,
        color: '4472C4'
    })

    slide.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
        x: x - 0.2,
        y: y + 0.8,
        fill: '4472C4',
        w: 0.5,
        h: 0.05,
        rectRadius: 0.4,
        line: {
            color: '4472C4'
        }
    });

    // MOT SYNTHESE
    slide.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
        x: x + 9,
        y: y + 0.4,
        fill: '4472C4',
        w: 1.5,
        h: 0.3,
        rectRadius: 0.05,
        line: {
            color: '4472C4'
        }
    });

    slide.addText("SYNTHÈSE", {
        x: x + 9,
        y: y + 0.4,
        align: 'center',
        valign: 'middle',
        w: 1.5,
        h: 0.3,
        fontFace: 'Roboto',
        fontSize: 12,
        bold: true,
        color: 'FFFFFF'
    })

    // Côté GAUCHE
    slide.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
        x: x - 0.2,
        y: y + 1.2,
        w: 5,
        h: 5,
        rectRadius: 0.06,
        line: {
            color: '808080'
        }
    });

    slide.addText("Nature du bien cédé", {
        x: x,
        y: y + 1.4,
        fontFace: 'Roboto',
        w: 2.5,
        h: 0.3,
        bold: true,
        fontSize: 12,
        color: '343434'
    })

    slide.addText(`${ resultat.nature_bien_cede && resultat.nature_bien_cede.type ? resultat.nature_bien_cede.type : ""  }`, {
        shape: pptx.ShapeType.roundRect,
        x: x + 0.1,
        y: y + 1.7,
        fill: "ECF1F9",
        color: "44546A",
        bold: true,
        fontSize: 12,
        fontFace: 'Roboto',
        rectRadius: 0.05,
        valign: 'middle',
        w: 2.4,
        h: 0.5
    });

    if([0, 1].includes(hypothese.type_cession)){
        slide.addText(natureBienPvImmo(resultat.nature_bien_cede && resultat.nature_bien_cede.bien_cede ? resultat.nature_bien_cede.bien_cede : 0), {
            shape: pptx.ShapeType.roundRect,
            x: x + 0.1,
            y: y + 2.25,
            fill: "ECF1F9",
            color: "44546A",
            bold: true,
            fontSize: 12,
            fontFace: 'Roboto',
            rectRadius: 0.05,
            valign: 'middle',
            w: 2.4,
            h: 0.5
        });
    }

    slide.addText("Nb d'années de détention", {
        x: x,
        y: y + 3,
        fontFace: 'Roboto',
        w: 2.5,
        h: 0.3,
        bold: true,
        fontSize: 12,
        color: '343434'
    })

    // A part la CONSTRUCTION
    if(hypothese.nature_bien_cede !== 3){
        slide.addText(
            `${
                resultat.nb_annee_pleines_detention &&
                resultat.nb_annee_pleines_detention[0] &&
                resultat.nb_annee_pleines_detention[0].annee ? resultat.nb_annee_pleines_detention[0].annee : 0
            }`, {
            shape: pptx.ShapeType.roundRect,
            x: x + 0.1,
            y: y + 3.3,
            fill: "ECF1F9",
            color: "44546A",
            bold: true,
            fontSize: 12,
            fontFace: 'Roboto',
            rectRadius: 0.05,
            valign: 'middle',
            w: 2.4,
            h: 0.5
        })
    }
    else{
        slide.addText(`${
            resultat.nb_annee_pleines_detention &&
            resultat.nb_annee_pleines_detention[0] &&
            resultat.nb_annee_pleines_detention[0].annee ? resultat.nb_annee_pleines_detention[0].annee : 0
        }`, {
            shape: pptx.ShapeType.roundRect,
            x: x + 0.1,
            y: y + 3.3,
            fill: "ECF1F9",
            color: "44546A",
            bold: true,
            fontSize: 12,
            fontFace: 'Roboto',
            rectRadius: 0.05,
            valign: 'middle',
            w: 2.2,
            h: 0.5
        })

        slide.addText("terrain", {
            shape: pptx.ShapeType.roundRect,
            x: x + 1.3,
            y: y + 3.3,
            fill: "ECF1F9",
            color: "44546A",
            bold: true,
            fontSize: 10,
            fontFace: 'Roboto',
            rectRadius: 0.05,
            align: 'right',
            valign: 'middle',
            w: 1,
            h: 0.5
        })

        slide.addText(`${
            resultat.nb_annee_pleines_detention &&
            resultat.nb_annee_pleines_detention[1] &&
            resultat.nb_annee_pleines_detention[1].annee ? resultat.nb_annee_pleines_detention[1].annee : 0
        }`, {
            shape: pptx.ShapeType.roundRect,
            x: x + 2.4,
            y: y + 3.3,
            fill: "ECF1F9",
            color: "44546A",
            bold: true,
            fontSize: 12,
            fontFace: 'Roboto',
            rectRadius: 0.05,
            valign: 'middle',
            w: 2.2,
            h: 0.5
        })

        slide.addText("construction", {
            shape: pptx.ShapeType.roundRect,
            x: x + 3.5,
            y: y + 3.3,
            fill: "ECF1F9",
            color: "44546A",
            bold: true,
            fontSize: 10,
            fontFace: 'Roboto',
            rectRadius: 0.05,
            align: 'right',
            valign: 'middle',
            w: 1.1,
            h: 0.5
        })

    }

    slide.addText("Plus-value brute", {
        x: x,
        y: y + 4.05,
        fontFace: 'Roboto',
        w: 2.5,
        h: 0.3,
        bold: true,
        fontSize: 12,
        color: '343434'
    })

    slide.addText(`${ resultat.plus_value_brute ? numberFormat(resultat.plus_value_brute) : 0 } €`, {
        shape: pptx.ShapeType.roundRect,
        x: x + 0.1,
        y: y + 4.35,
        fill: "DAE3F3",
        color: "4472C4",
        bold: true,
        fontSize: 15,
        fontFace: 'Roboto',
        rectRadius: 0.05,
        valign: 'middle',
        w: 4.2,
        h: 0.5
    });

    slide.addText(`${ resultat.prix_cession_corrige ? numberFormat(resultat.prix_cession_corrige) : 0 } €`, {
        shape: pptx.ShapeType.roundRect,
        x: x + 0.1,
        y: y + 4.95,
        fill: "ECF1F9",
        color: "4472C4",
        fontSize: 12,
        fontFace: 'Roboto',
        rectRadius: 0.05,
        valign: 'middle',
        w: 2.4,
        h: 0.5
    });

    slide.addText("Prix de cession corrigé", {
        shape: pptx.ShapeType.roundRect,
        x: x + 1.9,
        y: y + 4.95,
        fill: "44546A",
        color: "FFFFFF",
        fontSize: 12,
        fontFace: 'Roboto',
        rectRadius: 0.05,
        valign: 'middle',
        w: 2.4,
        h: 0.5
    });

    slide.addText(`${ resultat.prix_acquisition_corrige ? numberFormat(resultat.prix_acquisition_corrige) : 0 } €`, {
        shape: pptx.ShapeType.roundRect,
        x: x + 0.1,
        y: y + 5.5,
        fill: "ECF1F9",
        color: "4472C4",
        fontSize: 12,
        fontFace: 'Roboto',
        rectRadius: 0.05,
        valign: 'middle',
        w: 2.4,
        h: 0.5
    });

    slide.addText("Prix d'acquisition corrigé", {
        shape: pptx.ShapeType.roundRect,
        x: x + 1.9,
        y: y + 5.5,
        fill: "44546A",
        color: "FFFFFF",
        fontSize: 12,
        fontFace: 'Roboto',
        rectRadius: 0.05,
        valign: 'middle',
        w: 2.4,
        h: 0.5
    });

    // Côté droit
    slide.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
        x: x + 5,
        y: y + 1.2,
        w: 5.5,
        h: 3.5,
        rectRadius: 0.06,
        line: {
            color: '808080'
        }
    });

    slide.addText("Imposition sur la plus-value immobilière", {
        x: x + 5.2,
        y: y + 1.4,
        fontFace: 'Roboto',
        w: 3.5,
        h: 0.3,
        bold: true,
        fontSize: 12,
        color: '343434'
    });

    slide.addText(`${ resultat.impositions_plus_value_immo ? numberFormat(resultat.impositions_plus_value_immo) : 0 } €`, {
        shape: pptx.ShapeType.roundRect,
        x: x + 5.3,
        y: y + 1.7,
        fill: "FACCCB",
        color: "F06460",
        bold: true,
        fontSize: 15,
        fontFace: 'Roboto',
        rectRadius: 0.05,
        valign: 'middle',
        w: 2.9,
        h: 0.5
    });

    slide.addText(`${resultat.ir ? numberFormat(resultat.ir) : 0} €`, {
        shape: pptx.ShapeType.roundRect,
        x: x + 5.3,
        y: y + 2.35,
        fill: "FDEEEE",
        color: "F06460",
        fontSize: 12,
        fontFace: 'Roboto',
        rectRadius: 0.05,
        valign: 'middle',
        w: 2,
        h: 0.5
    });

    slide.addText("IR", {
        shape: pptx.ShapeType.roundRect,
        x: x + 7,
        y: y + 2.35,
        fill: "44546A",
        color: "FFFFFF",
        fontSize: 12,
        fontFace: 'Roboto',
        rectRadius: 0.05,
        valign: 'middle',
        w: 1.2,
        h: 0.5
    });

    slide.addText(`${resultat.ps ? numberFormat(resultat.ps) : 0} €`, {
        shape: pptx.ShapeType.roundRect,
        x: x + 5.3,
        y: y + 2.9,
        fill: "FDEEEE",
        color: "F06460",
        fontSize: 12,
        fontFace: 'Roboto',
        rectRadius: 0.05,
        valign: 'middle',
        w: 2,
        h: 0.5
    });

    slide.addText("PS", {
        shape: pptx.ShapeType.roundRect,
        x: x + 7,
        y: y + 2.9,
        fill: "44546A",
        color: "FFFFFF",
        fontSize: 12,
        fontFace: 'Roboto',
        rectRadius: 0.05,
        valign: 'middle',
        w: 1.2,
        h: 0.5
    });

    slide.addText(`${resultat.sur_taxe ? numberFormat(resultat.sur_taxe) : 0} €`, {
        shape: pptx.ShapeType.roundRect,
        x: x + 5.3,
        y: y + 3.45,
        fill: "FDEEEE",
        color: "F06460",
        fontSize: 12,
        fontFace: 'Roboto',
        rectRadius: 0.05,
        valign: 'middle',
        w: 2,
        h: 0.5
    });

    slide.addText("Surtaxe", {
        shape: pptx.ShapeType.roundRect,
        x: x + 7,
        y: y + 3.45,
        fill: "44546A",
        color: "FFFFFF",
        fontSize: 12,
        fontFace: 'Roboto',
        rectRadius: 0.05,
        valign: 'middle',
        w: 1.2,
        h: 0.5
    });

    slide.addText(`${resultat.cehr ? numberFormat(resultat.cehr) : 0} €`, {
        shape: pptx.ShapeType.roundRect,
        x: x + 5.3,
        y: y + 4,
        fill: "FDEEEE",
        color: "F06460",
        fontSize: 12,
        fontFace: 'Roboto',
        rectRadius: 0.05,
        valign: 'middle',
        w: 2,
        h: 0.5
    });

    slide.addText("CEHR", {
        shape: pptx.ShapeType.roundRect,
        x: x + 7,
        y: y + 4,
        fill: "44546A",
        color: "FFFFFF",
        fontSize: 12,
        fontFace: 'Roboto',
        rectRadius: 0.05,
        valign: 'middle',
        w: 1.2,
        h: 0.5
    });

    // Graphe
    slide.addChart(
        pptx.ChartType.doughnut,
        dataChartAreaLine,
        {
            x: x + 8.6,
            y: y + 2.6,
            w: 1.5,
            h: 1.5,
            showPercent: false,
            holeSize: 90,
            chartColors: ['F06460', 'E0B3BD']
        }
    );

    slide.addText(`${pourcentage} %`, {
        x: x + 8.9,
        y: y + 3.14,
        fontFace: 'Roboto',
        fontSize: 16,
        bold: true,
        align: 'center',
        w: 1,
        h: 0.4,
        color: 'F06460'
    })

    slide.addText(
        [
            { text: "Rapport impôts sur", options: { breakLine: true } },
            { text: "plus-value brute" }
        ],
        {
            x: x + 8.2,
            y: y + 4,
            w: 2.3,
            h: 0.5,
            fontFace: 'Roboto',
            fontSize: 10,
            align: 'center',
            color: '44546A'
        }
    )

    slide.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
        x: x + 5,
        y: y + 4.95,
        w: 5.5,
        h: 1.24,
        rectRadius: 0.06,
        line: {
            color: '808080'
        }
    });


    slide.addText("Prix net de cession", {
        x: x + 5.2,
        y: y + 5.15,//4.05
        fontFace: 'Roboto',
        w: 2.5,
        h: 0.3,
        bold: true,
        fontSize: 12,
        color: '343434'
    })

    slide.addText(`${resultat.prix_net_cession ? numberFormat(resultat.prix_net_cession) : 0} €`, {
        shape: pptx.ShapeType.roundRect,
        x: x + 5.3,
        y: y + 5.45,
        fill: "DAE3F3",
        color: "4472C4",
        bold: true,
        fontSize: 15,
        fontFace: 'Roboto',
        rectRadius: 0.05,
        valign: 'middle',
        w: 2.9,
        h: 0.5
    });

}
