import React, { useContext, useEffect, useState } from "react";
import ReactDataGrid from "react-data-grid";
import { customFormatter } from "../DonationParConjoint/DonationParConjointTable/DonationParConjointTable";
import { numberFormat } from '../../../../../extra/all_ppt_render';
import "./style.scss";
import CIcon from '@coreui/icons-react';
import { TextCurrency } from "../../../../../components/input";
import { CessionContext } from "../../CessionHaut";
import { fetchAllResult, getData } from "../../ProjectionCessionSociete/ProjetCessionSociete";
import Loading from "../../../../../components/loader";
import { restApiUri } from '../../../../../config'
import { nombreEnfantsParConjoint, conditionAffichageEnfant } from "../../ProjectionCessionSociete/TableauSynthese/TableauSynthese";
import { structurationSyntheseDonation } from "../DonationParConjoint/DonationParConjoint";

// Fonction qui enlève les espaces sur une chaîne de caractère (utilisée sur les montants)
export const moveSpaceIntoCurrency = (value) => {
  if (!value) {
    return 0;
  }

  let valeur = '';
  for (let i = 0; i < value.length; i++) {
    if (!isNaN(parseInt(value[i])) || value[i] === '-' || value[i] === '.') valeur += value[i];
  }

  return valeur.includes('.') ? parseFloat(valeur) : parseInt(valeur, 10);
};

export const getApportData = async (data) => {
  const query = await fetch(`${restApiUri}/api/cession/initCalculApport`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem("token")}`
    },
    body: JSON.stringify({
      ...data,
    }),
    cache: 'no-cache'
  });

  return query.json();
};

const formatting = (value, column, otherLabel = undefined) => {
  //if(value && value.row && value.row.actionnaireFamilial){
  let weight = '';
  let condition = '';
  let color = '';
  let textAlign = '';
  let paddingRight = '';

  if(value && value.row && value.row.actionnaireFamilial){
    weight = value.row.fontWeight === 'bold' ? 'bold' : 'normal';
    condition = value.row.fontWeight === 'bold';
    color = value.row.fontWeight === 'bold' ? "#808080" : "rgb(128,128,128)";

    textAlign = ["actionnaireFamilial", "nomPersonnalise"].includes(column) ? 'left' : column === "apportTitres" ? 'center' : 'right';

    paddingRight = ["valeurTitres", "pvReport", "soldeValeurTitre"].includes(column) ? 12 : 0;
  }
  else {
    color = 'FFFFFF';
    weight = 'bold';
    paddingRight = 12;
  }

  if (value && value.row && value.row.actionnaireFamilial) {
    /*weight = (value.row.actionnaireFamilial === "Enfants PP" ||
      value.row.actionnaireFamilial === "Conjoint 1 PP" ||
      value.row.actionnaireFamilial === "Conjoint 2 PP" ||
      value.row.actionnaireFamilial === "Conjoint 1 USUF / Enfants NP" ||
      value.row.actionnaireFamilial === "Conjoint 2 USUF / Enfants NP" ||
      otherLabel && otherLabel.includes(value.row.actionnaireFamilial)) ? "bold" : "normal";*/


    /*condition = value.row.actionnaireFamilial === "Conjoint 1" ||
      value.row.actionnaireFamilial === "Conjoint 1 PP" ||
      value.row.actionnaireFamilial === "Conjoint 2 PP" ||
      value.row.actionnaireFamilial === "Conjoint 2" ||
      value.row.actionnaireFamilial === "Enfants PP" ||
      value.row.actionnaireFamilial === "Conjoint 1 USUF / Enfants NP" ||
      value.row.actionnaireFamilial === "Conjoint 2 USUF / Enfants NP" ||
      otherLabel && otherLabel.includes(value.row.actionnaireFamilial)*/

    /*color = value.row.actionnaireFamilial === "Conjoint 1" ||
      value.row.actionnaireFamilial === "Conjoint 2" ||
      value.row.actionnaireFamilial === "Enfants PP" ||
      value.row.actionnaireFamilial === "Conjoint 1 USUF / Enfants NP" ||
      value.row.actionnaireFamilial === "Conjoint 2 USUF / Enfants NP" ||
      otherLabel && otherLabel.includes(value.row.actionnaireFamilial) ? "#808080" : "rgb(128,128,128)";*/

    /*textAlign = column === "actionnaireFamilial" ||
      column === "nomPersonnalise" ? "left" :
      column === "apportTitres" ? "center" : "right";

    paddingRight = column === "valeurTitres" ||
      column === "pvReport" ||
      column === "soldeValeurTitre" ? 12 : 0;*/
  }
  /*else {
    color = 'FFFFFF';
    weight = 'bold';
    paddingRight = 12;
  }*/

  return (
    <div
      style={{
        fontWeight: ["actionnaireFamilial", "valeurTitres", "apportTitres", "pvReport", "soldeValeurTitre"].includes(column) ? weight : "normal",
        textAlign: textAlign, marginLeft: column === "actionnaireFamilial" ? condition ? 10 : 18 : 0,
        color: color,
        paddingRight: paddingRight
      }}
    >
      {value.row[column]}
    </div>
  );

  return null
}

const apportColumnFormat = (value) => {
  if (value && value.row) {
    return (
      <div className="widget-HeaderCell__value" style={{ marginRight: 42, fontWeight: value.row.fontWeight, color: value.row.actionnaireFamilial ? '#585C66' : '#FFFFFF' }} >
        <TextCurrency value={value.value} />
        {
          value.row.modifiable && (
            <CIcon name="cil-pencil" size={'xs'} style={{ position: "absolute", right: 15 }} />
          )
        }
      </div>
    )
  }
};

const formatValue = (value) => {
  if (value === 'ERREUR' || value === 'Erreur') return 'ERREUR';
  else if (value < 0) return `(${numberFormat(Math.abs(Math.round(value)))})`;
  else return numberFormat(Math.round(value));
};

const hasOnlyOneChild = (enfants, conjointIndex) => {
  return nombreEnfantsParConjoint(enfants, conjointIndex) === 1 ? true : false;
};

const ownChild = (enfants, conjointIndex) => {
  let value = "";
  enfants.map((data, index) => {
    let cond = conditionAffichageEnfant(enfants, conjointIndex, index);

    if (cond) value = `Conjoint ${conjointIndex + 1} USUF / Enfant ${index + 1} NP`;
  });

  return value;
};

// Composant (Tableau)
const ApportsAvantCessionTable = ({ prenomConjoint1, prenomConjoint2 }) => {
  // Definition des states et variables nécéssaires.
  const { cessionHaut, setCessionHaut } = useContext(CessionContext);
  const { identiteActionnaire: identite, projetCessionSociete: projet, operationsAvantCession: operations } = cessionHaut;
  const { enfants, conjoints, affichageNomPersonnalise: affichage } = identite;

  const [loading, setLoading] = useState(false);
  const [currentData, setCurrentData] = useState(null);
  const [b, setB] = useState([]);

  // Les colonnes du tableau
  const columns = [
    {
      key: "actionnaireFamilial",
      name: "Actionnaires familiaux",
      frozen: true,
      width: 220,
      headerRenderer: (value) => customFormatter(value.column.name, 'left'),
      formatter: (value) => {
        return formatting(
          value,
          "actionnaireFamilial",
          [
            enfants.length === 1 ? "Enfant 1 PP" : '-',
            hasOnlyOneChild(enfants, 0) ? ownChild(enfants, 0) : '-',
            conjoints[0].marieOuPasce === 'Oui' && hasOnlyOneChild(enfants, 1) ? ownChild(enfants, 1) : '-'
          ],
        );
      }
    },
    {
      key: "valeurTitres",
      name: "Situation actuelle",
      width: 200,
      headerRenderer: (value) => customFormatter(value.column.name, 'center'),
      formatter: (value) => {
        return formatting(
          value,
          "valeurTitres",
          [
            enfants.length === 1 ? "Enfant 1 PP" : '-',
            hasOnlyOneChild(enfants, 0) ? ownChild(enfants, 0) : '-',
            conjoints[0].marieOuPasce === 'Oui' && hasOnlyOneChild(enfants, 1) ? ownChild(enfants, 1) : '-'
          ]
        )
      }
    },
    {
      key: 'apportTitres',
      name: "Apports des titres",
      width: 200,
      editable: true,
      formatter: (value) => apportColumnFormat(
        value,
        [
          enfants.length === 1 ? "Enfant 1 PP" : '-',
          hasOnlyOneChild(enfants, 0) ? ownChild(enfants, 0) : '-',
          conjoints[0].marieOuPasce === 'Oui' && hasOnlyOneChild(enfants, 1) ? ownChild(enfants, 1) : '-'
        ]
      ),
      editor: true,
      headerRenderer: (value) => customFormatter(value.column.name, 'center')
    },
    {
      key: "pvReport",
      name: "PV en report",
      width: 200,
      headerRenderer: (value) => customFormatter(value.column.name, 'center'),
      formatter: (value) => {
        return formatting(
          value,
          "pvReport",
          [
            enfants.length === 1 ? "Enfant 1 PP" : '-',
            hasOnlyOneChild(enfants, 0) ? ownChild(enfants, 0) : '-',
            conjoints[0].marieOuPasce === 'Oui' && hasOnlyOneChild(enfants, 1) ? ownChild(enfants, 1) : '-'
          ]
        )
      }
    },
    {
      key: "soldeValeurTitre",
      name: "Solde valeur des titres",
      width: 200,
      headerRenderer: (value) => customFormatter(value.column.name, 'center'),
      formatter: (value) => {
        return formatting(
          value,
          "soldeValeurTitre",
          [
            enfants.length === 1 ? "Enfant 1 PP" : '-',
            hasOnlyOneChild(enfants, 0) ? ownChild(enfants, 0) : '-',
            conjoints[0].marieOuPasce === 'Oui' && hasOnlyOneChild(enfants, 1) ? ownChild(enfants, 1) : '-'
          ]
        )
      }
    }
  ];

  const lookingForIndexByNom = (prenom) => {
    return enfants.findIndex((element) => element.prenom === prenom);
  };

  const onGridRowsUpdated = ({ fromRow, toRow, updated }) => {
    const rows = b.slice();
    for (let i = fromRow; i <= toRow; i++) {
      rows[i] = { ...rows[i], ...updated };
    }

    for (let j = 0; j < rows.length; j++) {
      let currentRow = rows[j];

      if (currentRow.params && currentRow.params.type && currentRow.params.index !== null) {
        // Pour les Conjoints
        if (currentRow.params.type === 'CNJ') {
          identite.conjoints[currentRow.params.index].apportTitres = parseInt(currentRow.apportTitres, 10);
        }

        // Pour les Enfants PP
        if (currentRow.params.type === 'ENF_PP') {
          projet.enfantsFamiliauxPP[currentRow.params.index].apportTitres = parseInt(currentRow.apportTitres, 10);
        }

        // Pour les Enfants USUF du Conjoint 1
        if (currentRow.params.type === "ENF_NP1") {
          projet.enfantsFamiliauxDP[currentRow.conjointIndex].enfants[currentRow.params.index].apportTitres = parseInt(currentRow.apportTitres, 10);
        }

        // Pour les Enfants USUF du Conjoint 2
        if (currentRow.params.type === "ENF_NP2") {
          projet.enfantsFamiliauxDP[currentRow.conjointIndex].enfants[currentRow.params.index].apportTitres = parseInt(currentRow.apportTitres, 10);
        }
      }

      setCessionHaut((prevState) => ({
        ...prevState,
        identiteActionnaire: identite,
        projetCessionSociete: projet
      }));
    }
  };

  const tableLinesLength = () => {
    let length = 0;

    conjoints.map((data, index) => {
      if (index === 0 || (index === 1 && conjoints[0].marieOuPasce === 'Oui')) {
        length++;
      }
    });

    if (enfants.length > 1) length++;

    length += enfants.length;

    conjoints.map((data, index) => {
      if (index === 0 || (index === 1 && conjoints[0].marieOuPasce === 'Oui')) {
        if (nombreEnfantsParConjoint(enfants, index) > 1) length++;

        length += nombreEnfantsParConjoint(enfants, index);
      }
    });

    length++;

    return length;
  };

  useEffect(() => {
    setLoading(true);

    getData(fetchAllResult(conjoints, enfants, projet, operations))
      .then((response) => {
        const dataTableDonation = response.table_donation_avant_cession;

        setCurrentData(response);

        /*if(conjoints[0].marieOuPasce === 'Oui' && enfants.length === 0){
          console.log("Miditra ato ka")
          operations.synthese = structurationSyntheseDonation(enfants, conjoints, dataTableDonation, affichage, projet.holding.nomPersonnalise)
        
          setCessionHaut((prevState) => {
            return {
              ...prevState,
              operationsAvantCession: operations
            }
          })
        }*/
      })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false))

  }, [cessionHaut]);

  // Un seul cas uniquement => Personne seule sans enfants
  useEffect(() => {
    if (conjoints[0].marieOuPasce === 'Non' && enfants.length === 0) {
      setLoading(true);
      getData(fetchAllResult(conjoints, enfants, projet, operations))
        .then((response) => {
          const dataTableDonation = response.table_donation_avant_cession;

          operations.synthese = structurationSyntheseDonation(enfants, conjoints, dataTableDonation, affichage, projet.holding.nomPersonnalise)

          setCessionHaut((prevState) => {
            return {
              ...prevState,
              operationsAvantCession: operations
            }
          })
        })
        .catch((error) => console.error(error))
        .finally(() => setLoading(false))
    }
  }, [conjoints[0].apportTitres]);


  useEffect(() => {
    if (currentData) {
      const allData = currentData.resultatFormulaire.apport_avant_cession;
      const finalData = [];
      // Pour les conjoints
      [allData["conjoint1_pp"], allData["conjoint2_pp"]].map((data, index) => {
        if (index === 0 || (index === 1 && conjoints[0].marieOuPasce === "Oui")) {
          let conj = new Object();
          conj.actionnaireFamilial = `${affichage ? `${data.nom !== "" ? data.nom : `Conjoint ${index + 1}`}` : `Conjoint ${index + 1}`} PP`;
          conj.nomPersonnalise = data.nom;
          conj.valeurTitres = numberFormat(data.valeur_titre);
          conj.apportTitres = data.apport_titre;
          conj.pvReport = formatValue(data.pv_report);
          conj.soldeValeurTitre = formatValue(data.solde_valeur_titre);
          conj.fontWeight = 'bold';
          conj.modifiable = true;
          conj.params = {
            type: 'CNJ',
            index: index
          };

          finalData.push(conj);
        }
      });

      // Somme des enfants PP
      let enfantsPP = new Object();
      enfantsPP.actionnaireFamilial = "Enfants PP";
      enfantsPP.valeurTitres = numberFormat(allData["somme_enfant_pp"].valeur_titre);
      enfantsPP.apportTitres = allData["somme_enfant_pp"].apport_titre;
      enfantsPP.pvReport = formatValue(allData["somme_enfant_pp"].pv_report);
      enfantsPP.soldeValeurTitre = formatValue(allData["somme_enfant_pp"].solde_valeur_titre);
      enfantsPP.fontWeight = 'bold';
      enfantsPP.modifiable = false;
      enfantsPP.params = {
        type: "ENF_PP_TOTAL",
        index: null
      };

      if (enfants.length > 1)
        finalData.push(enfantsPP);

      // Pour les enfants en PP
      allData["table_enfant_pp"].map((data, index) => {
        let enf = new Object();
        enf.index = index;
        enf.actionnaireFamilial = `${affichage ? `${data.nom !== "" ? data.nom : `Enfant ${index + 1}`}` : `Enfant ${index + 1}`} PP`;
        enf.nomPersonnalise = data.nom;
        enf.valeurTitres = numberFormat(data.valeur_titre);
        enf.apportTitres = data.apport_titre;
        enf.pvReport = formatValue(data.pv_report);
        enf.soldeValeurTitre = formatValue(data.solde_valeur_titre);
        enf.fontWeight = allData["table_enfant_pp"].length === 1 ? 'bold' : 'normal';
        enf.modifiable = true;
        enf.params = {
          type: "ENF_PP",
          index: index
        };

        finalData.push(enf);
      });


      // Pour les enfants en NP
      [allData["somme_enfant_np_c1"], allData["somme_enfant_np_c2"]].map((donnee, index) => {
        if (index === 0 || (index === 1 && conjoints[0].marieOuPasce === "Oui")) {
          let conj = new Object();
          conj.actionnaireFamilial = `${index === 0 ? prenomConjoint1 : prenomConjoint2} USUF / Enfants NP`;
          conj.valeurTitres = numberFormat(donnee.valeur_titre);
          conj.apportTitres = donnee.apport_titre;
          conj.pvReport = formatValue(donnee.pv_report);
          conj.soldeValeurTitre = formatValue(donnee.solde_valeur_titre);
          conj.fontWeight = 'bold';
          conj.modifiable = false;
          conj.params = {
            type: `ENF_NP${index + 1}_TOTAL`,
            index: null
          };

          if (nombreEnfantsParConjoint(enfants, index) > 1)
            finalData.push(conj);

          const childrens = index === 0 ? allData["table_enfant_np_conjoint1"] : allData["table_enfant_np_conjoint2"];

          childrens.map((data, indice) => {
            if (conditionAffichageEnfant(enfants, index, lookingForIndexByNom(data.nom))) {

              let enf = new Object();
              enf.index = lookingForIndexByNom(data.nom);
              enf.conjointIndex = index;
              enf.actionnaireFamilial = `${affichage ? `${conjoints[index].nomPersonnalise !== "" ? conjoints[index].nomPersonnalise : `Conjoint ${index + 1}`}` : `Conjoint ${index + 1}`} USUF / ${affichage ? `${data.nom !== "" ? data.nom : `Enfant ${data.id}`}` : `Enfant ${data.id}`} NP`;
              enf.nomPersonnalise = `${conjoints[index].nomPersonnalise} USUF / ${enfants[lookingForIndexByNom(data.nom)].prenom} NP`;
              enf.valeurTitres = numberFormat(data.valeur_titre);
              enf.apportTitres = data.apport_titre;
              enf.pvReport = formatValue(data.pv_report);
              enf.soldeValeurTitre = formatValue(data.solde_valeur_titre);
              enf.fontWeight = childrens.length === 1 ? 'bold' : 'normal';
              enf.modifiable = true;
              enf.params = {
                type: `ENF_NP${index + 1}`,
                index: lookingForIndexByNom(data.nom)
              };

              finalData.push(enf);
            }
          });
        }
      });

      // Pour le total
      const total = new Object();
      total.apportTitres = numberFormat(allData["somme_tous"].apport_titre);
      total.pvReport = formatValue(allData["somme_tous"].pv_report);
      total.soldeValeurTitre = formatValue(allData["somme_tous"].solde_valeur_titre);
      total.params = {
        type: 'TOTAL',
        index: null
      };
      finalData.push(total);

      setB(finalData);
    }
  }, [currentData]);

  return (
    <div id="tableauApportsAvantCession" style={{ width: '100%', fontSize: 13 }} >
      {loading && (<Loading />)}
      <ReactDataGrid
        columns={columns}
        rowGetter={i => currentData ? b[i] : []}
        rowsCount={currentData ? b.length : 0}
        onGridRowsUpdated={onGridRowsUpdated}
        enableCellSelect={true}
        minHeight={35 * (tableLinesLength() + 1)}
      />
    </div>
  );
};

export default ApportsAvantCessionTable;
