import CIcon from '@coreui/icons-react'
import React from 'react'
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import { numberFormat } from '../../../../../extra/all_ppt_render';
import GreenArrow from '../../../../../assets/images/svg/PvImmo/ARROW.svg';
import { CImg } from '@coreui/react';

const style = {
    root: {
        display: 'flex',
        width: '100%'
    },
    gauche: {
        width: '30%',
        borderRadius: '15px',
        boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
        padding: 15,
        paddingLeft: 30,
        marginRight: 20
    },
    droite: {
        width: '60%'
    },
    haut: {
        marginBottom: 25,
        borderRadius: '15px',
        height: 260,
        padding: 15,
        paddingLeft: 40,
        boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px'
    },
    bas: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: 15,
        paddingLeft: 30,
        height: 100,
        borderRadius: '15px',
        width: '40%',
        boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px'
    },
    texteGris: {
        color: 'rgb(129,133,145)',
        marginBottom: 3
    }
}

export const GreenBlock = ({ texte, width, height }) => {
    return (
        <div style={{ height: height && height, marginBottom: 5, fontWeight: 'bold', width: width ? width : '225px', display: 'flex', padding: 5, borderRadius: '10px', background: 'rgb(227,242,235)', color: 'rgb(29,164,106)' }} >
            
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }} >
                <img src={GreenArrow} width={18} />
            </div>
            <div style={{ fontSize: 13, marginLeft: 3, display: 'flex', flexDirection: 'column', justifyContent: 'center' }} >{texte}</div>
        </div>
    )
}

const LittleBlockIr = ({label, value}) => {
    const padding = 8;

    const css = {
        gauche: {
            fontWeight: 'bold', 
            fontSize: 15, 
            color: '#FFFFFF', 
            padding: padding, 
            display: 'flex', 
            flexDirection: 'column', 
            justifyContent: 'center', 
            background: 'rgb(88,92,103)',
            border: '2px solid rgb(88,92,103)',
            width: '40%'
        },
        droite: {
            // fontWeight: 'bold', 
            fontSize: 15, 
            padding: padding, 
            display: 'flex', 
            flexDirection: 'column', 
            justifyContent: 'center', 
            border: '2px solid rgb(88,92,103)',
            textAlign: 'right',
            width: '50%',
            paddingRight: 25

        }
    }
    return (
        <div style={{ marginBottom: 5, display: 'flex', width: '80%' }} >
            <div style={css.gauche} >{label}</div>
            <div style={css.droite} >{value} €</div>
        </div>
    )
}

const natureByIndex = (index) => {
    switch(index){
        case 0 :
            return <>Autres biens immobiliers<br/>(hors résidence principale)</>
        case 1 :
            return "Terrains à bâtir";
        case 2 :
            return "Bois et forêts";
        default :
            return "Construction";
    }
}

const Synthese = ({
    type,
    hypothese,
    data
}) => {
    return (
        <div style={style.root} >
            <div style={style.gauche} >
                <div style={{ ...style.texteGris }} >Nature du bien cédé</div>
                <GreenBlock width={type !== 2 ? '200px' : '225px'} texte={data.nature_bien_cede && data.nature_bien_cede.type ? data.nature_bien_cede.type : ""} />
                <div style={{ visibility: type !== 2 ? 'visible' : 'hidden' }} >
                    <GreenBlock width={type !== 2 ? '200px' : '225px'} texte={data.nature_bien_cede !== undefined && data.nature_bien_cede.bien_cede !== undefined ? natureByIndex(data.nature_bien_cede.bien_cede) : ""} />
                </div>

                <div style={{ ...style.texteGris, marginTop: 20 }} >Nb d'années de détention</div>
                <div style={{ fontSize: '16px', fontWeight: 'bold' }} >
                    {
                        (hypothese.nature_bien_cede !== 3) ? (
                            <>
                                {
                                    data.nb_annee_pleines_detention &&
                                    data.nb_annee_pleines_detention[0] &&
                                    data.nb_annee_pleines_detention[0].annee ? data.nb_annee_pleines_detention[0].annee : 0
                                }
                            </>
                        ) : (
                            <>
                                {/** Terrain */}
                                <div style={{ display: 'flex', marginBottom: 10, width: '100%' }} >
                                    <div style={{ fontWeight: 'normal', fontSize: 11, width: '100px', color: 'rgb(129,133,145)', display: 'flex', flexDirection: 'column', justifyContent: 'center' }} >Terrain</div>
                                    <div style={{ width: '15%', textAlign: 'right' }} >
                                    {
                                        data.nb_annee_pleines_detention &&
                                        data.nb_annee_pleines_detention[0] &&
                                        data.nb_annee_pleines_detention[0].annee ? data.nb_annee_pleines_detention[0].annee : 0
                                    }
                                </div>
                                </div>

                                {/** Construction */}
                                <div style={{ display: 'flex', width: '100%' }} >
                                    <div style={{ fontWeight: 'normal', fontSize: 11, width: '100px', color: 'rgb(129,133,145)', display: 'flex', flexDirection: 'column', justifyContent: 'center' }} >Construction</div>
                                    <div style={{ width: '15%', textAlign: 'right' }} >
                                    {
                                        data.nb_annee_pleines_detention &&
                                        data.nb_annee_pleines_detention[1] &&
                                        data.nb_annee_pleines_detention[1].annee ? data.nb_annee_pleines_detention[1].annee : 0
                                    }
                                </div>
                                </div>
                            </>
                        )
                    }
                </div>

                <div style={{ ...style.texteGris, marginTop: 20 }} >Plus-value brute</div>
                <div style={{ fontSize: '16px', fontWeight: 'bold', color: '#1AA66B' }} >
                    {
                        data.plus_value_brute ? numberFormat(data.plus_value_brute) : 0
                    } €
                </div>

                <div style={{ background: 'rgb(196,202,214)', width: '10%', height: '3px', marginTop: 10, marginBottom: 10 }} ></div>

                <div style={{ ...style.texteGris, fontSize: 11 }} >Prix de cession corrigé</div>
                <div style={{ width: '34%', fontSize: '14px', fontWeight: 'bold', textAlign: 'right' }} >
                    {
                        data.prix_cession_corrige ? numberFormat(data.prix_cession_corrige) : 0
                    } €
                </div>

                <div style={{ ...style.texteGris, marginTop: 5, fontSize: 11 }} >Prix d'acquisition corrigé</div>
                <div style={{ width: '34%', fontSize: '14px', fontWeight: 'bold', textAlign: 'right' }} >
                    {
                        data.prix_acquisition_corrige ? numberFormat(data.prix_acquisition_corrige) : 0
                    } €
                </div>
            </div>
            <div style={style.droite} >
                <div style={{ ...style.haut, marginBottom: hypothese.nature_bien_cede === 3 ? 37 : 25 }} >
                    <div style={{ ...style.texteGris }} >Impositions sur la plus-value immobilière</div>
                    <div style={{ display: 'flex', width: '100%' }} >
                        <div style={{ width: '50%' }} >
                            <div style={{ fontWeight: 'bold', fontSize: '16px', color: 'rgb(250,74,74)' }} >
                                {
                                    data.impositions_plus_value_immo ? numberFormat(data.impositions_plus_value_immo) : 0
                                } €
                            </div>
                            <div style={{ width: 115, height: 115, marginTop: 15 }} >
                                <CircularProgressbarWithChildren
                                    value={data.rapport_impot_plus_value_brute ? parseFloat(data.rapport_impot_plus_value_brute).toFixed(2) : 0}
                                    styles={buildStyles({
                                        textColor: "#F16360",
                                        pathColor: "#F16360",
                                        trailColor: "#E1B3BC",
                                        textSize: 20

                                    })}>
                                    <strong style={{ fontSize: '16px', color: '#F16360' }}>{`${data.rapport_impot_plus_value_brute ? parseFloat(data.rapport_impot_plus_value_brute).toFixed(2) : 0} %`}</strong>
                                </CircularProgressbarWithChildren>
                            </div>
                            <div style={{ marginTop: 15 }} >Rapport impôts sur plus-value brute</div>
                        </div>
                        <div style={{ width: '50%' }} >
                            <LittleBlockIr 
                                label="IR"
                                value={data.ir ? numberFormat(data.ir) : 0}
                            />
                            <LittleBlockIr 
                                label="PS"
                                value={data.ps ? numberFormat(data.ps) : 0}
                            />
                            <LittleBlockIr 
                                label="Surtaxe"
                                value={data.sur_taxe ? numberFormat(data.sur_taxe) : 0}
                            /> 
                            <LittleBlockIr 
                                label="CEHR"
                                value={data.cehr ? numberFormat(data.cehr) : 0}
                            />
                        </div>
                    </div>
                </div>
                <div style={style.bas} >
                    <div style={{ ...style.texteGris, marginBottom: 6 }} >Prix net de cession</div>
                    <div style={{ fontSize: '16px', color: '#1AA66B', fontWeight: 'bold' }} >
                        {
                            data.prix_net_cession ? numberFormat(data.prix_net_cession) : 0
                        } €
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Synthese;