import React from 'react';
import { numberFormat } from '../../../../../extra/all_ppt_render';
import './style.scss';

const Bloc1 = (props) => {
    const  { data } = props;

    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }} >
            <div style={{ fontWeight: 'bold', color: '#7a86a1', borderBottom: '2px solid #e7edf3', padding: "18px 15px 15px 15px" }} >Coût de l'emprunt</div>
            <div style={{ height: 200, display: 'flex', flexDirection: 'row', justifyContent: 'center', padding: '15px 15px 25px 15px' }} >
                <div style={{ display: 'flex', flexDirection: 'column', flex: "1 1 0px" , borderRight: '2px solid #e7edf3', justifyContent: 'space-between' }}>
                  <div style={{ display: 'flex', height: '50px', flexDirection: 'column' }} >
                      <div style={{ color: '#7a86a1', fontWeight: 'bold' }}>Coût total</div>
                      <div style={{ color: 'rgb(23,157,199)', fontWeight: 'bold', fontSize: 20 }} >
                          {
                              data &&
                              data.cout_total ? numberFormat(data.cout_total) : 0
                          } €
                      </div>
                  </div>

                  <div style={{ textAlign: 'end', paddingRight: 15 }}>
                    <div style={{ color: 'rgb(23,157,199)', fontWeight: 'bold', display: 'flex', justifyContent: 'flex-end', fontSize: 13 }} >
                      <div style={{ background: '#e0ebf3', padding: '3px 10px', borderRadius: '30px', display: 'flex', alignItems: 'center', gap: 5 }}>
                        <div className='add-chevron-right'>
                          <span className="chevron right"></span>
                        </div>
                        <div>
                          {
                              data &&
                              data.TAEG ? parseFloat(data.TAEG).toFixed(2) : 0
                          } %
                        </div>
                      </div>
                    </div>
                    <div style={{ marginTop: 13, color: '#7a86a1' }} >TAEG</div>
                    <div style={{ color: '#7a86a1' }}>
                        <div >
                            Taux annuel effectif global
                        </div>

                    </div>
                  </div>

                </div>
                <div style={{ display: 'flex', flex: "1 1 0px", paddingLeft: 20 }}>
                  <div style={{ display: 'flex',  flexDirection: 'column', justifyContent: 'space-between' }} >
                      <div >
                          <div style={{ color: '#7a86a1'}}>Frais de dossier</div>
                          <div style={{ color: 'rgb(240,79,108)', marginBottom: 2, fontSize: 20,  }} >
                          {
                              data &&
                              data.frais_dossier ? numberFormat(data.frais_dossier) : 0
                          } €
                          </div>

                      </div>
                      <div >
                          <div style={{ color: '#7a86a1'}}>Coût intérêts</div>
                          <div style={{ color: 'rgb(240,79,108)', marginBottom: 2, fontSize: 20, }} >
                          {
                              data &&
                              data.cout_interet ? numberFormat(data.cout_interet) : 0
                          } €
                          </div>
                      </div>
                      <div >
                          <div style={{ color: '#7a86a1'}}>Coût assurance</div>
                          <div style={{ color: 'rgb(240,79,108)', marginBottom: 2, fontSize: 20,}} >
                          {
                              data &&
                              data.cout_assurance ? numberFormat(data.cout_assurance) : 0
                          } €
                          </div>

                      </div>
                  </div>
                </div>



            </div>
        </div>
    )
};

export default Bloc1;
