import React from 'react';
import InfoIcon from '../../assets/images/i-info-bulle.svg';
import InfoIcon2 from '../../assets/images/i-info-bulle-2.svg';
import InfoErrorIcon from '../../assets/images/i-info-bulle-error.svg';
import HelpIcon from '../../assets/images/help-bulle.svg';
import { CImg } from '@coreui/react';
import { ErrorToolTip, OrdinaryTooltip } from './Tooltip/Tooltip';

const InfoBulle = ({ type, information, placement, onRequestClick, marginLeft, style, help, exception }) => {
    const typeInfoBulle = type || 'ordinary';

    const handleClick = () => {
        if (onRequestClick) {
            onRequestClick();
        }
    }

    const Tooltip = typeInfoBulle === 'ordinary' ? OrdinaryTooltip : ErrorToolTip;
    const InformationIcon = exception ? InfoIcon2 : HelpIcon;
    const background = typeInfoBulle === 'ordinary' ? '#28366D' : 'rgb(234,58,61)';

    return (
        <Tooltip
            title={<div>{information}</div>}
            placement={placement || "right-start"}
            arrow
        >
            <div style={{ marginLeft: marginLeft !== undefined ? marginLeft : 10, ...(style || {}) }}>
                {
                    (exception || help) ? (
                        <CImg onClick={handleClick} src={InformationIcon} style={{ width: '20px', cursor: 'pointer' }} />
                    ) : (
                        <svg onClick={handleClick} style={{ cursor: 'pointer' }} id="Icon_ionic-ios-information-circle-outline" data-name="Icon ionic-ios-information-circle-outline" width="20" height="20" viewBox="0 0 29.25 29.25">
                            <path id="Path_34499" data-name="Path 34499" d="M18,3.375A14.625,14.625,0,1,0,32.625,18,14.623,14.623,0,0,0,18,3.375Z" transform="translate(-3.375 -3.375)" fill={background} />
                            <g id="Icon_feather-info" data-name="Icon feather-info" transform="translate(80.625 -3.719)">
                                <path id="Path_34505" data-name="Path 34505" d="M18,25.879V18" transform="translate(-84 -1.194)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" />
                                <path id="Path_34506" data-name="Path 34506" d="M18,12h0" transform="translate(-84)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" />
                            </g>
                        </svg>
                    )
                }
            </div>
        </Tooltip>
    )
};

export default InfoBulle;
