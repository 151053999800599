export const cessionSocieteDefaultData = JSON.parse(JSON.stringify({
    "conjoint1": {
        "nom": "Conjoint 1",
        "age": 0,
        "date_naiss": "",
        "date_approximatif": 0,
        "revenu": 0,
        "marie": 1,
        "donation_pp": 0,
        "apport_titre": 0,
        "avec_nom_personnalise": 0
    },
    "conjoint2": {
        "nom": "Conjoint 2",
        "age": 0,
        "date_naiss": "",
        "date_approximatif": 0,
        "revenu": 0,
        "donation_pp": 0,
        "apport_titre": 0
    },
    "nb_enfant": 0,
    "liste_enfant": [],
    "prix_cession": 0,
    "pp": {
        "taux_pp": 0,
        "conjoint1": {
            "nom": "Conjoint 1",
            "pourcentage_detention": 0,
            "valeur_titre": 0,
            "prix_fiscal": 0,
            "regime_fiscale": 0,
            "taux_eligible": 0,
            "eligible_abattement": 0
        },
        "conjoint2": {
            "nom": "Conjoint 2",
            "pourcentage_detention": 0,
            "valeur_titre": 0,
            "prix_fiscal": 0,
            "regime_fiscale": 0,
            "taux_eligible": 0,
            "eligible_abattement": 0
        },
        "holding": {
            "nom": "",
            "pourcentage_detention": 0,
            "valeur_titre": 0,
            "prix_fiscal": 0,
            "regime_fiscale": 1
        },
        "annee_ouverture": 2022,
        "liste_enfant": []
    },
    "np": {
        "taux_np": 0,
        "conjoint1": {
            "liste_enfant": []
        },
        "conjoint2": {
            "liste_enfant": []
        }
    },
    "payement_plus_value": {
        "conjoint1": 0,
        "conjoint2": 100
    },
    "liste_donation": [
        {
            "liste_enfant": [],
            "conjoint": {
                "nom": "c1",
                "nom_veritable": "Conjoint 1",
                "age": 0,
                "valeur_fiscale": 0,
                "marie": 1,
                "revenu": 0,
                "donation_pp": 0
            }
        },
        {
            "liste_enfant": [],
            "conjoint": {
                "nom": "c2",
                "nom_veritable": "Conjoint 2",
                "age": 0,
                "valeur_fiscale": 0,
                "revenu": 0,
                "donation_pp": 0
            }
        }
    ]
}
));