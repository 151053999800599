import React, { createContext, useReducer } from 'react';

const initialState = { pfStep: null }

export const PropFiContext = createContext(initialState)

const reducer = (state, {type, payload}) => {
  switch (type) {
    case 'UPDATE_PF_STEP':
        return { ...state, pfStep: payload }
    default:
      return state;
  }
}

export const PropFiProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return <PropFiContext.Provider value={{ propfiState: state, propfiDispatch: dispatch }}>{children}</PropFiContext.Provider>
}