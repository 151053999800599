import React, { createContext, useReducer } from 'react';

const initialState = {
  simulation: {},
  buttons: [],
  action: '',
  pdfLoading: false,
  cmpStep: null,
  tableRows: [],
  showFormulairePersonnalisationSimulation: false,
  showShortcutSimulation: false,
  postItData: {
    simulationId: null,
    simulationType: '',
    displayPostitIdNull: false,
    displayBlocPostIt: false,
    currentPostItId: null,
    hasPosted: false,
    state: false,
    currentCommentValue: '',
    elements: [],
    requestView: false,
    switchOn: false,
    changingTab: '',
    currentPin: {
      idPostit:  1,
      date: '',
      commentaire: '',
      isSet: false,
      userId: '',
      idSimulation: 0,
    }
  },
  clientList: [],
  comportementSortieSimulateurData: {
    updated: false
  },
  showCalculatrice: false,
  enableCalculatriceKeyboard: false,
  listSimulations: {
    data: [],
    filter: {
      id: null,
      nomClient: null,
      typeSimulation: null,
      type: null,
      currentPage: 1
    }
  }
}

export const SimulateurContext = createContext(initialState)

const reducer = (state, { type, payload }) => {
  switch (type) {
    case 'SET_CURRENT_SIMULATION':
      return { ...state, simulation: payload }
    case 'UPDATE_CURRENT_SIMULATION':
      return { ...state, simulation: Object.assign({}, state.simulation, payload) }

    case 'ADD_FOOTER_BUTTON':
      return { ...state, buttons: [...state.buttons, payload] }
    case 'DELETE_FOOTER_BUTTON':
      return { ...state, buttons: state.buttons.filter(b => b.type !== payload) }

    case 'CLIENT':
      return { ...state, action: 'client' }
    case 'NOTE':
      return { ...state, action: 'note' }
    case 'SIMULATE':
      return { ...state, action: 'simulate' }
    case 'SAVE_SIMULATION':
      return { ...state, action: 'save_simulation' }
    case 'CLONE_SIMULATION':
      return { ...state, action: 'clone_simulation' }
    case 'EXPORT_PDF':
      return { ...state, action: 'export_pdf' }
    case 'EXPORT_PPT':
      return { ...state, action: 'export_ppt' }
    case 'SHOW_PDF_MODAL':
      return { ...state, action: 'show_pdf_modal' }
    case 'SHOW_PPT_MODAL':
      return { ...state, action: 'show_ppt_modal' }

    case 'BACK':
      return { ...state, action: 'back' }

    case 'CESSION_1':
      return { ...state, action: 'cession1' }

    case 'COMP_NEW':
      return { ...state, action: 'comp_new' }

    case 'COMP':
      return { ...state, action: 'comp' }
    case 'COMP_SSI':
      return { ...state, action: 'comp_ssi' }
    case 'COMP_HST':
      return { ...state, action: 'comp_hst' }
    case 'COMP_HST1':
      return { ...state, action: 'comp_hst' }
    case 'COMP_HST2':
      return { ...state, action: 'comp_hst' }
    case 'COMP_HSF':
      return { ...state, action: 'comp_hsf' }
    case 'COMP_HSF1':
      return { ...state, action: 'comp_hsf' }
    case 'COMP_RSF':
      return { ...state, action: 'simulation_financiere' }
    case 'COMP_RSF1':
      return { ...state, action: 'simulation_financiere' }
    case 'COMP_RSF2':
      return { ...state, action: 'simulation_financiere' }
    case 'COMP_RST':
      return { ...state, action: 'simulation_transmission' }
    case 'COMP_RST1':
      return { ...state, action: 'simulation_transmission' }
    case 'PF_SI':
      return { ...state, action: 'pf_si' }
    case 'PF_RI':
      return { ...state, action: 'pf_ri' }
    case 'PF_PI':
      return { ...state, action: 'pf_pi' }
    case 'PF_PI2':
      return { ...state, action: 'pf_pi' }

    case 'UPDATE_COMP_STEP':
      return { ...state, cmpStep: payload }

    case 'RESET_ACTION':
      return { ...state, action: '' }

    case 'ADD_PDF_LOADING':
      return { ...state, pdfLoading: true }
    case 'REMOVE_PDF_LOADING':
      return { ...state, pdfLoading: false }

    case 'UPDATE_TABLE_ROWS':
      return { ...state, tableRows: payload }

    case 'UPDATE_SHOW_PERSONNALISATION_SIMULATION_STATE':
      return { ...state, showFormulairePersonnalisationSimulation: payload?.showFormulairePersonnalisationSimulation || false }

    case 'UPDATE_SHOW_SHORTCUT_SIMULATION_STATE':
      return { ...state, showShortcutSimulation: payload?.showShortcutSimulation || false }

    case 'UPDATE_POST_IT_DATA' :
      return { ...state, postItData: payload }

    case 'UPDATE_CLIENT_LIST' :
      return { ...state, clientList: payload }

    case 'UPDATE_LIST_SIMULATION_DATA' :
      return { ...state, listSimulations: payload }

    case 'DISPLAY_HIDE_CALCULATRICE' :
      return { ...state, showCalculatrice: payload?.showCalculatrice }

    case 'ENABLE_DISABLE_KEYBOARD_CALCULATRICE' :
      return { ...state, enableCalculatriceKeyboard: payload?.enableCalculatriceKeyboard }

    case 'UPDATE_COMPORTEMENT_SORTIE_SIMULATEUR_DATA' :
      return { ...state, comportementSortieSimulateurData: payload }

    case 'ACTIVE_PAGE_BLOCK' :
      return { ...state, comportementSortieSimulateurData: { ...state.comportementSortieSimulateurData, updated: true } }

    case 'DESACTIVE_PAGE_BLOCK' :
      return { ...state, comportementSortieSimulateurData: { ...state.comportementSortieSimulateurData, updated: false } }

    default:
      return state
  }
}

export const SimulateurProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return <SimulateurContext.Provider value={{ simulateurState: state, simulateurDispatch: dispatch }}>{children}</SimulateurContext.Provider>
}
