import React, { Component, useContext } from "react";
import NumberFormat from 'react-number-format';
import { CInput } from '@coreui/react';
import { SimulateurContext } from "../../store/context/simulateur";
import { activePageBlock, updateComportementSortieSimulateurData } from "../../store/actions/simulateur";
import PostitPin from "../Postit/PostitPin";

const CustomInput = (props) => {
    const { isPostitShow, className, id, hidePin, justifyContent, alignRight, ...inputProps } = props;

    return (
        <div className={className} style={{ padding: 0, position: 'relative', display: "flex", justifyContent: justifyContent ? justifyContent : "flex-end" }}>
            {
              props?.addlabelemprunt && <div style={{ position: 'absolute', fontSize: 9, top: -7, left: 7, background: "#fff", whiteSpace: 'nowrap' }} className="input-text-label-emprunt"><span>{props?.labelemprunt}</span></div>
            }
            <CInput {...inputProps} style={{ width: '100%', paddingRight: 30, textAlign: 'right', fontSize: 13, ...inputProps.style }} />
            <span style={{ position: "absolute", right: alignRight || 15, top: 7, color: inputProps?.style?.color || "#656E7D" }}>%</span>
            {
              !hidePin && (
                <PostitPin isPostitShow={isPostitShow} pinId={id} />
              )
            }
        </div>
    )
}

const InputRate = (props) => {
    const { simulateurState, simulateurDispatch } = useContext(SimulateurContext);

    const handleChange = (event) => {
        // On met à jour l'état de changement de FORMULAIRE
        simulateurDispatch(activePageBlock())

        // Changement de valeur contenue dans le FORMULAIRE
        props.onChange(event);
    }

    return (
        <NumberFormat
            id={props.id}
            customInput={CustomInput}
            max={100}
            decimalScale={2}
            allowNegative={false}
            isAllowed={(values) => {
                const { floatValue } = values;
                return floatValue <= 100 || !floatValue;
            }}
            onKeyDown={event => {
                if (event.keyCode === 13) {
                    const form = event.target.form;
                    let index = Array.prototype.indexOf.call(form, event.target);
                    if (form.elements[index + 1])
                        form.elements[index + 1].focus();
                    event.preventDefault();
                }
            }}
            style={{ textAlign: 'right', ...props.style }}
            disabled={props.disabled}
            invalid={props.invalid}
            value={props.value == '.' ? '0.' : props.value}
            onChange={handleChange}
            onBlur={props.onBlur}
            className={props.className ? props.className : "col-lg-2"}
            isPostitShow={simulateurState?.postItData?.displayBlocPostIt}
            hidePin={props.hidePin}
            justifyContent={props.justifyContent}
            alignRight={props.alignRight}
            addlabelemprunt={props.addlabelemprunt}
            labelemprunt={props.labelemprunt}
        />
    )
}

export default InputRate;
