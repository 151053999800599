import { restApiUri } from "../../../config";

const fiscalite_uri = `${restApiUri}/api/fiscalite`;

export const cloneFiscalite = (data) => {
    const dataToSend = {
        // date_simulation: moment(new Date()).format("DD-MM-YYYY HH:mm"),
        userId: JSON.parse(localStorage.getItem("user"))["id"],
        clientId: data.client && data.client.id ? data.client.id : null,
        ...data,
    };
    delete dataToSend['client']
    return dataToSend
}


export const launchFiscaliteSimulation = async (config) => {
    const query = await fetch(`${fiscalite_uri}/simuler_fiscalite`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${localStorage.getItem("token")}`
        },
        body: JSON.stringify(config),
        cache: 'no-cache'
    })

    return query.json();
}

export const saveFiscalite = async (fiscalite, id=undefined) => {
    console.log(id, "🚀 ********* SAVE ******** ~ file: FiscaliteDataControl.js ~ line 21 ~ saveFiscalite ~ fiscalite", fiscalite)
    const url = id ? `${fiscalite_uri}/${id}` : fiscalite_uri;
    const query = await fetch(url, {
        method: id ? 'PUT' : 'POST',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${localStorage.getItem("token")}`
        },
        body: JSON.stringify(fiscalite),
        cache: 'no-cache'
    })
    return query.json();
} 

export const getFiscalite = async (id=undefined) => {
    if(!id) {        
        return;
    }
    const query = await fetch(`${fiscalite_uri}/findOne/${id}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${localStorage.getItem("token")}`
        },
    })

    return query.json();
}