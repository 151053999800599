import React, { useContext, useState } from 'react';
import Usufruitier from './Usufruitier/Usufruitier';
import { CFormGroup, CLabel } from '@coreui/react';
import { InputText, InputCurrency, InputSelect, InputRate } from '../../../../../components/input';
import { CessionContext } from '../../CessionHaut';
import { getData, fetchAllResult } from '../ProjetCessionSociete';
import { numberFormat } from '../../../../../extra/all_ppt_render';
import Loading from '../../../../../components/loader';
import { structurationTotalSynthese } from '../EnfantConjointHolding/EnfantConjointHolding';
import { conditionAffichageEnfant } from '../TableauSynthese/TableauSynthese';
import ToggleSwitch from '../../../../../components/SwitchGreen/SwitchGreen';
import { formatToUniqueId } from '../../../../../utils';

export const calculNP = (conjoints,enfantsFamiliauxDP) => {
    let somme = 0;

    enfantsFamiliauxDP.map((donnee, indice) => {
        if( indice === 0 || (conjoints[0].marieOuPasce === 'Oui') ){
            donnee.enfants.map((data, index) => {
                somme += (data.pourcentageDetention === 0 || data.pourcentageDetention === "") ? 0 : parseFloat(data.pourcentageDetention);
            });
        }
    });

    return somme;
};

const ActionnaireFamilial = (props) => {
    const regimeFiscalData = [
        "Non éligible",
        "Eligible"
    ];

    const { cessionHaut, setCessionHaut } = useContext(CessionContext);
    const { identiteActionnaire: identite, projetCessionSociete: projet, operationsAvantCession: operations } = cessionHaut;
    const { conjoints, enfants } = identite;
    const {
        prixCession,
        actionnairesFamiliauxPP,
        actionnairesFamiliauxDP,
        conjoints: conjointsProjet,
        enfantsFamiliauxPP,
        enfantsFamiliauxDP
    } = projet;

    const [ loading, setLoading ] = useState(false);

    const handleEnfantsChange = async (event, prop, index, conjoint, prenomEnfant=undefined) => {
        const { value } = event.target;

        projet.enfantsFamiliauxDP[conjoint].enfants[index][prop] = value;
        if(prop === 'pourcentageDetention' || prop === 'prixRevientFiscal'){
            setLoading(true);
            const oldData = await getData(fetchAllResult(conjoints, enfants, projet, operations));
            const dataFrom = oldData.resultatFormulaire;
            setLoading(false);

            const np = calculNP(conjoints, enfantsFamiliauxDP);
            projet.actionnairesFamiliauxDP = np;

            projet.enfantsFamiliauxDP[conjoint].enfants[index]['valeurTitres'] = numberFormat(dataFrom[`table_enfant_np_conjoint${conjoint + 1}`].find((element) => element.nom === prenomEnfant).valeur_titre);

            //projet.enfantsFamiliauxDP[conjoint].enfants[index]['valeurTitres'] = numberFormat(dataFrom[`table_enfant_np_conjoint${conjoint + 1}`][index].valeur_titre);

            projet.total = structurationTotalSynthese(dataFrom, conjoints);
        }

        setCessionHaut((prevState) => ({
            ...prevState,
            projetCessionSociete: projet
        }));
    };

    const marginTopBetweenForm = 5;

    return (
        <>
        {
            props.data && props.data.length > 0 && (
                <>
                { loading && <Loading /> }
                <div style={{display:'flex', flex: 1, flexDirection: 'row', marginTop: -14, fontSize: 13, borderLeft:props.conjoint2 ? "4px solid #F0885D" :  "4px solid #2BA3C8", paddingLeft:19, marginLeft: -23, background:'#FAFAFA', borderRadius:6}}>
                    <div id="" className="col-lg-4 col-md-4 col-sm-4" style={{display: 'flex', flexDirection: 'column', marginTop: 4, padding: 0, maxWidth: 363, minWidth: 363}}>
                        <div style={{ width: '100%' }} >
                        {/* Nu-propriétaire */}
                            <Usufruitier
                                title="Usufruitier"
                                subtitle=""
                                left={true}
                                conjoint2={props.conjoint2}
                            />
                        </div>
                        <CLabel style={{marginTop: 30, minHeight: 32}}>Nom personnalisé</CLabel>
                        <CLabel style={{marginTop: -3, minHeight: 25}}>% Detention en USUF/NP</CLabel>
                        <CLabel style={{marginTop: marginTopBetweenForm, minHeight: 25}}>Valeur des titres</CLabel>
                        <CLabel style={{marginTop: marginTopBetweenForm, minHeight: 25}}>Prix de revient fiscal</CLabel>
                        <CLabel style={{marginTop: marginTopBetweenForm, minHeight: 25}}>Régime fiscal : éligible à l'abattement de 85% ?</CLabel>
                    </div>
                    <div id="form_benificiaire" style={{overflowX: 'auto', display:'flex', flexDirection:'column', flex: 1}}>
                        <CFormGroup className="form-inline" style={{display:'flex', flexWrap:'nowrap', flexDirection:'row'}}>
                            {
                                props.data.map((data, index) => {
                                    let prenom = ![null, undefined, ""].includes(enfants[index].prenom) ? enfants[index].prenom : `ENFANT ${index+1}`

                                    return conditionAffichageEnfant(enfants, props.conjoint2 ? 1 : 0, index) && (
                                        <div key={index} className={`col-12 col-xxl-3 col-xl-3 col-lg-2 col-md-4 col-sm-4 px-0 ${ index > 0 ? "ml-2" : ""} `} style={{marginTop:"20px"}}>
                                            <div style={{ borderBottom: "5px solid gray", fontWeight: 'bold', color: "#000000", padding: 10,height: 30, display: 'flex', justifyContent: 'center', alignItems: 'center' }} className="text-center col-lg-12" id="benificiaire_num">
                                                {prenom.toString().toUpperCase()}
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </CFormGroup>
                        <CFormGroup className="form-inline" style={{display:'flex', flexWrap:'nowrap', flexDirection:'row'}}>
                            {
                                props.data.map((data, index) => {
                                    return conditionAffichageEnfant(enfants, props.conjoint2 ? 1 : 0, index) && (
                                        <div /*style={{ visibility: conditionAffichageEnfant(enfants, props.conjoint2 ? 1 : 0, index) ? 'visible' : 'hidden' }}*/ key={index} className={`col-12 col-xxl-3 col-xl-3 col-lg-2 col-md-4 col-sm-4 px-0 ${ index > 0 ? "ml-2" : ""} `} >
                                            <CFormGroup className="form-inline " style={{justifyContent: 'center', paddingRight: 0 }}>
                                                <InputText
                                                    id={`${formatToUniqueId("ActionnaireFamilial nomPersonnalise")}-text-${index}`}
                                                    disabled={true}
                                                    key={index}
                                                    placeholder={ props.type !== "HOLDING" ? `${props.type} ${index+1}` : "HOLDING IS" }
                                                    value={`${conjoints[props.conjoint2 ? 1 : 0].nomPersonnalise !== "" ? conjoints[props.conjoint2 ? 1 : 0].nomPersonnalise : `Conjoint ${ props.conjoint2 ? 2 : 1 }`} USUF / ${enfants[index].prenom !== "" ? enfants[index].prenom : `Enfant ${index + 1}`} NP`}
                                                    className="col-lg-12 col-md-12 col-sm-12"
                                                />
                                            </CFormGroup>
                                        </div>
                                    )
                                })
                            }
                        </CFormGroup>

                        <CFormGroup className="form-inline" style={{display:'flex', flexWrap:'nowrap', flexDirection:'row'}}>
                            {
                                props.data.map((data, index) => {
                                    return conditionAffichageEnfant(enfants, props.conjoint2 ? 1 : 0, index) && (
                                        <div /*style={{ visibility: conditionAffichageEnfant(enfants, props.conjoint2 ? 1 : 0, index) ? 'visible' : 'hidden' }}*/ key={index} className={`col-12 col-xxl-3 col-xl-3 col-lg-2 col-md-4 col-sm-4 px-0 ${ index > 0 ? "ml-2" : ""} `} >
                                            <CFormGroup className="form-inline " style={{justifyContent: 'end', paddingRight: 0 }}>
                                                <InputRate
                                                    id={`${formatToUniqueId("ActionnaireFamilial detention")}-rate-${index}`}
                                                    key={index}
                                                    value={data.pourcentageDetention}
                                                    onChange={ (event) => handleEnfantsChange(event, 'pourcentageDetention', index, props.conjoint2 ? 1 : 0, enfants[index].prenom) }
                                                    className="col-lg-6"
                                                />
                                            </CFormGroup>
                                        </div>
                                    )
                                })
                            }
                        </CFormGroup>

                        <CFormGroup className="form-inline" style={{display:'flex', flexWrap:'nowrap', flexDirection:'row'}}>
                            {
                                props.data.map((data, index) => {
                                    return conditionAffichageEnfant(enfants, props.conjoint2 ? 1 : 0, index) && (
                                        <div /*style={{ visibility: conditionAffichageEnfant(enfants, props.conjoint2 ? 1 : 0, index) ? 'visible' : 'hidden' }}*/ key={index} className={`col-12 col-xxl-3 col-xl-3 col-lg-2 col-md-4 col-sm-4 px-0 ${ index > 0 ? "ml-2" : ""} `} >
                                            <CFormGroup className="form-inline " style={{justifyContent: 'center', paddingRight: 0 }}>
                                                <InputCurrency
                                                    id={`${formatToUniqueId("AcctionnaireFamilial valeurTitre")}-${index}`}
                                                    disabled={true}
                                                    key={index}
                                                    value={
                                                        cessionHaut.projetCessionSociete.enfantsFamiliauxDP[ props.conjoint2 ? 1 : 0 ].enfants[index].valeurTitres
                                                    }
                                                    className="col-lg-12 col-md-12 col-sm-12"
                                                />

                                            </CFormGroup>
                                        </div>
                                    )
                                })
                            }
                        </CFormGroup>

                        <CFormGroup className="form-inline" style={{display:'flex', flexWrap:'nowrap', flexDirection:'row'}}>
                            {
                                props.data.map((data, index) => {
                                    return conditionAffichageEnfant(enfants, props.conjoint2 ? 1 : 0, index) && (
                                        <div /*style={{ visibility: conditionAffichageEnfant(enfants, props.conjoint2 ? 1 : 0, index) ? 'visible' : 'hidden' }}*/ key={index} className={`col-12 col-xxl-3 col-xl-3 col-lg-2 col-md-4 col-sm-4 px-0 ${ index > 0 ? "ml-2" : ""} `} >
                                            <CFormGroup className="form-inline " style={{justifyContent: 'center', paddingRight: 0 }}>
                                                <InputCurrency
                                                    id={`${formatToUniqueId("AcctionnaireFamilial prixRevientFiscal")}-${index}`}
                                                    key={index}
                                                    value={data.prixRevientFiscal}
                                                    onChange={ (event) => handleEnfantsChange(event, 'prixRevientFiscal', index, props.conjoint2 ? 1 : 0, enfants[index].prenom) }
                                                    className="col-lg-12 col-md-12 col-sm-12"
                                                />

                                            </CFormGroup>
                                        </div>
                                    )
                                })
                            }
                        </CFormGroup>

                        <CFormGroup className="form-inline" style={{display:'flex', flexWrap:'nowrap', flexDirection:'row'}}>
                            {
                                props.data.map((donnee, index) => {
                                    return conditionAffichageEnfant(enfants, props.conjoint2 ? 1 : 0, index) && (
                                        <div style={{ marginTop:-2}} key={index} className={`col-12 col-xxl-3 col-xl-3 col-lg-2 col-md-4 col-sm-4 px-0 ${ index > 0 ? "ml-2" : ""} `} >
                                            <CFormGroup className="form-inline " style={{justifyContent: 'end', paddingRight: 0, height:33.5, marginTop:-2 }}>
                                                {/* <InputSelect custom id="regimeFiscal" className="col-lg-12 col-md-12 col-sm-12" onChange={ (event) => handleEnfantsChange(event, 'regimeFiscal', index, props.conjoint2 ? 1 : 0) } >
                                                    {
                                                        regimeFiscalData.map((data, index) => {
                                                            return (
                                                                <option key={index} selected={ donnee.regimeFiscal === data } >{data}</option>
                                                            )
                                                        })
                                                    }
                                                </InputSelect> */}
                                                <ToggleSwitch
                                                    isToggled={donnee.regimeFiscal === 'Eligible'}
                                                    onToggleChange={ () => {
                                                        let value = '';
                                                        let i = props && props.conjoint2 ? 1 : 0;

                                                        if(donnee.regimeFiscal === 'Eligible') value = 'Non éligible';
                                                        else value = 'Eligible';

                                                        projet.enfantsFamiliauxDP[i].enfants[index].regimeFiscal = value;

                                                        setCessionHaut((prevState) => {
                                                            return  {
                                                                ...prevState,
                                                                projetCessionSociete: projet
                                                            }
                                                        });
                                                    } }
                                                />
                                            </CFormGroup>
                                        </div>
                                    )
                                })
                            }
                        </CFormGroup>

                    </div>
                </div>
                </>
            )
        }
        </>
    );
};

export default ActionnaireFamilial;
