import React, { useContext, useEffect, useState } from 'react';
import { DataControlContext } from '../../../store/context/dataControl';
import Configuration from './Configuration/Configuration';
import CessionDirecte from './CessionDirecte/CessionDirecte';
import CessionIndirecte from './CessionIndirecte/CessionIndirecte';
import CessionParts from './CessionParts/CessionParts';
import Comparaison from './Comparaison/Comparaison';
import { restApiUri } from '../../../config';
import Loading from '../../../components/loader'
import { LayoutContext } from '../../../App2';
import { getSimulateur, saveSimulateur } from './Crud';
import { SimulateurContext } from '../../../store/context/simulateur';
import { UtilsContext } from '../../../store/context/utils';
import { useParams } from 'react-router';
import { desactivePageBlock, resetAction, updatePostItData, updateSimulation } from '../../../store/actions/simulateur';
import { plusValueImmobiliereDefaultData } from '../../../store/context/defaultData/plusValueImmobiliere';
import { comparaisonBetweenDate } from './PageHypothesePvImmo/PageHypothesePvImmo';
import exportPPTPlusValueImmobiliere from '../../../extra/InvestissementsImmobiliers/PlusValueImmobiliere';
import { COULEUR_FOND_SIMULATEUR, messageErreurEnregistrementSimulation, messageSuccesEnregistrementSimulation } from '../../../utils';
import ScrollButton from '../../../components/Button/scrollToTop';
import SimulateurContainerWithMenu from '../../../components/SimulateurContainerWithMenu/SimulateurContainerWithMenu';
import SimulateurContainerWithMenuBis from '../../../components/SimulateurContainerWithMenuBis/SimulateurContainerWithBis';
import PostitService from '../../../services/postitService';
import { sortPostitByIndexCase } from '../../../components/Postit/Postit';
import PersonnalisationExport from '../../../components/PersonnalisationExport/PersonnalisationExport';
import { loadCustomExportPpt, saveCustomExportPpt } from '../../../helpers/customExportPpt';

export const savePvImmobilier = async (simulateurState, infoSimulation, onRequestStart, onRequestSuccess, onRequestError, onRequestEnd, idAfterInsertion, configurationExport) => {
    onRequestStart();
    const data = {
        configurationExport: configurationExport,
        ...infoSimulation,
        ...simulateurState,
    };

    saveSimulateur(data, idAfterInsertion, '/api/immobilier/')
        .then((response) => { onRequestSuccess(response) })
        .catch((error) => onRequestError())
        .finally(() => onRequestEnd())
}

const PlusValueImmobiliere = () => {
    const { headerMenuIndex, pvImmobiliereState, setPvImmobiliereState, setHeaderMenuIndex, profilUtilisateurState, configurationExportState, setConfigurationExportState } = useContext(DataControlContext);
    const { layoutState, setLayoutState } = useContext(LayoutContext);
    const { simulateurState, simulateurDispatch } = useContext(SimulateurContext);
    const { utilsDispatch } = useContext(UtilsContext);
    const { simulation } = simulateurState;
    const { footerButtons } = layoutState;
    const { pvImmobiliere } = headerMenuIndex;
    const { id } = useParams();

    const [resultat, setResultat] = useState(null);
    const [loading, setLoading] = useState(false);
    const [idAfterInsertion, setIdAfterInsertion] = useState(null);

    const { cession_directe, cession_indirecte, cession_sci_ir} = pvImmobiliereState.config;
    const [nombreCessionActive, setNombreCessionActive] = useState(0);

    // Visibilité de la fenêtre de personnalisation de l'export
    const [showPersonnalisationExport, setShowPersonnalisationExport] = useState(false);


    // Changement au niveau du nombre de Cession active
    useEffect(() => {
        let compteur = 0;

        if(cession_directe === 1) compteur++;

        if(cession_indirecte === 1) compteur++;

        if(cession_sci_ir === 1) compteur++;

        setNombreCessionActive(compteur);

    }, [ cession_directe, cession_indirecte, cession_sci_ir ])

    // Simulation
    useEffect(() => {
        setLoading(true);
        fetch(`${restApiUri}/api/immobilier/lancer_simulation_immobilier`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
            body: JSON.stringify(pvImmobiliereState)
        })
            .then((response) => response.json())
            .then((response) => {
                if (response) {
                    setResultat(response);
                }
            })
            .catch((error) => console.error(error))
            .finally(() => setLoading(false))
    }, [JSON.stringify(pvImmobiliereState)])

    // Simulation existante
    useEffect(() => {
        loadCustomExportPpt("plusValue").then((data) => {
          if (data.id) {
            setConfigurationExportState((prevState) => {
              return {
                ...prevState,
                plusValue: data.configurationExport,
            }
            })
          }
        }).catch((err) => {
          console.log(err);
        });

        const fetchPins = async (type, userId, idSimulation) => {
          try {
            const pins = await PostitService.getPostitsByType(type, userId, idSimulation)

            simulateurDispatch(updatePostItData({
              ...(simulateurState?.postItData || {}),
              elements: sortPostitByIndexCase(pins)
            }))
          } catch (error) {
            console.log(error);
          }
        }
        if (id) {
            setLoading(true);
            getSimulateur('/api/immobilier/findOne/', id)
                .then((response) => {
                    if (response) {
                        const pvImmo = JSON.parse(JSON.stringify(pvImmobiliereState));
                        const menu = JSON.parse(JSON.stringify(headerMenuIndex));
                        if (!response.form1.construction) {
                            response.form1.construction = plusValueImmobiliereDefaultData.form1.construction;
                        }

                        if (!response.form2.construction) {
                            response.form2.construction = plusValueImmobiliereDefaultData.form2.construction;
                        }

                        response.form1.construction.frais_acquisition = 1;
                        response.form1.construction.frais_travaux = 1;
                        response.form2.construction.frais_acquisition = 1;
                        response.form2.construction.frais_travaux = 1;

                        if (response.form1.bien_detenu === undefined) {
                            response.form1.bien_detenu = 0;
                        }

                        if ([undefined, 1].includes(response.form2.bien_detenu)) {
                            response.form2.bien_detenu = 0;
                        }

                        if (response.form3.bien_detenu === undefined) {
                            response.form3.bien_detenu = 0;
                        }

                        pvImmo.config = response.config;
                        pvImmo.form1 = response.form1;
                        pvImmo.form2 = response.form2;
                        pvImmo.form3 = response.form3;
                        // menu.pvImmobiliere = 0;

                        // setHeaderMenuIndex(menu);
                        setPvImmobiliereState(pvImmo);
                        simulateurDispatch(updateSimulation({
                            identifiant_simulation: response.identifiant_simulation,
                            commentaire: response.commentaire,
                            clientId: response.client && response.client.id ? response.client.id : null
                        }))
                    }
                })
                .catch((error) => console.error(error))
                .finally(() => {
                  fetchPins("plus-value-immobiliere", localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user'))?.id : null, id);
                  setLoading(false)
                })
        }
    }, [id])

    // Bouton d'enregistrement
    useEffect(() => {
        setLayoutState((prevState) => {
            const button = JSON.parse(JSON.stringify(footerButtons));

            button.pvImmo = [
                {
                    type: 'ADD',
                    text: "Enregistrer",
                    onClick: () => {
                        const userId = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user'))?.id : null;
                        const dataToSend = {
                          userId,
                          type: 'plusValue',
                          configurationExport: configurationExportState?.plusValue
                        }

                        saveCustomExportPpt(dataToSend);

                        const infoSimulation = {
                            "identifiant_simulation": simulation.identifiant_simulation,
                            "commentaire": simulation.commentaire,
                            "terme_simulation": "fin",
                            "clientId": simulation.clientId ? simulation.clientId : null,
                            "userId": JSON.parse(localStorage.getItem('user')).id,
                        }

                        savePvImmobilier(
                            pvImmobiliereState,
                            infoSimulation,
                            () => { setLoading(true) },
                            (response) => {
                                if (response) {
                                    if (response.id) {
                                        setIdAfterInsertion(response.id);
                                    }

                                    utilsDispatch({
                                        type: "ADD_TOAST",
                                        payload: {
                                            type: "success",
                                            title: "OK",
                                            description: messageSuccesEnregistrementSimulation(simulation?.identifiant_simulation)
                                        },
                                    });

                                    simulateurDispatch(updatePostItData({
                                      ...(simulateurState?.postItData || {}),
                                      simulationId: response.id,
                                      simulationType: 'plus-value-immo'
                                    }))

                                    simulateurDispatch(desactivePageBlock())
                                }
                            },
                            () => {
                                utilsDispatch({
                                    type: "ADD_TOAST",
                                    payload: {
                                        type: "danger",
                                        title: "ERREUR",
                                        description: messageErreurEnregistrementSimulation(simulation?.identifiant_simulation)
                                    },
                                });
                            },
                            () => { setLoading(false) },
                            idAfterInsertion || id,
                            null
                        )
                    }
                }
            ]

            return {
                ...prevState,
                footerButtons: button
            }
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pvImmobiliereState, simulation, idAfterInsertion, id, configurationExportState?.plusValue])

    // Réinitialisation en cas de NOUVELLE SIMULATION
    useEffect(() => {
        if (!id) {
            const menu = JSON.parse(JSON.stringify(headerMenuIndex));
            menu.pvImmobiliere = 0;

            setHeaderMenuIndex(menu);
            setPvImmobiliereState(plusValueImmobiliereDefaultData);
            simulateurDispatch(updateSimulation({
                identifiant_simulation: "brouillon",
                commentaire: "",
                clientId: null
            }))
        }
    }, [])

    // Export
    useEffect(() => {
        if (simulateurState.action === 'export_ppt') {
            setShowPersonnalisationExport(!showPersonnalisationExport);
            // exportPPTPlusValueImmobiliere(profilUtilisateurState, pvImmobiliereState, resultat);
            simulateurDispatch(resetAction());
        }
    }, [simulateurState.action])

    const menus = [
        {
            label: "Type de cession",
            content: (
                <Configuration />
            ),
            style: {
                paddingLeft: 0,
                paddingRight: 0,
                background: COULEUR_FOND_SIMULATEUR
            }
        },
        {
            label: "Cession directe",
            content: cession_directe === 1 ? (
                <CessionDirecte
                    data={pvImmobiliereState.form1}
                    resultat={resultat?.form1 || {}}
                />
            ) : null,
            style: {
                paddingLeft: 0,
                paddingRight: 0,
                background: COULEUR_FOND_SIMULATEUR
            }
        },
        {
            label: "Cession indirecte",
            content: cession_indirecte === 1 ? (
                <CessionIndirecte
                    data={pvImmobiliereState.form2}
                    resultat={resultat?.form2 || {}}
                />
            ) : null,
            style: {
                paddingLeft: 0,
                paddingRight: 0,
                background: COULEUR_FOND_SIMULATEUR
            }
        },
        {
            label: <>Cession de parts<br/>de SCI à l'IR</>,
            content: cession_sci_ir === 1 ? (
                <CessionParts
                    data={pvImmobiliereState.form3}
                    resultat={resultat?.form3 || {}}
                />
            ) : null,
            style: {
                paddingLeft: 0,
                paddingRight: 0,
                background: COULEUR_FOND_SIMULATEUR
            }
        },
        {
            label: nombreCessionActive > 1 ? "Comparaison" : "Résultats synthétiques",
            content: nombreCessionActive > 0 ? (
                <Comparaison
                    hypothese={pvImmobiliereState}
                    resultat={resultat?.comparaison || {}}
                />
            ) : null,
            style: {
                paddingLeft: 0,
                paddingRight: 0,
                paddingBottom: 0,
                background: COULEUR_FOND_SIMULATEUR
            },
            resultPage: true
        }
    ]

    // A chaque fois que le menu change
    const handleMenuChange = (index, resultPage) => {
        const headerMenu = JSON.parse(JSON.stringify(headerMenuIndex));

        headerMenu.pvImmobiliere = resultPage ? 4 : index;

        setHeaderMenuIndex(headerMenu);
        if (simulateurState?.postItData.switchOn) {
          simulateurDispatch(updatePostItData({
            ...(simulateurState?.postItData || {}),
            changingTab: Math.floor(Math.random() * 300),
          }))
        }
    }

    return (
        <>
            {loading && <Loading />}
            {/** Fenêtre de personnalisation de l'export Plus value */}
            {
                showPersonnalisationExport && (
                    <PersonnalisationExport
                        data={configurationExportState?.plusValue}
                        onRequestDataChange={(data) => {
                            const configExport = JSON.parse(JSON.stringify(configurationExportState));

                            configExport.plusValue = data;

                            setConfigurationExportState(configExport);
                        }}
                        onRequestExport={() => {
                          exportPPTPlusValueImmobiliere(profilUtilisateurState, pvImmobiliereState, resultat, false, configurationExportState?.plusValue?.elements);
                          setShowPersonnalisationExport(false);
                        }}
                        onRequestClose={() => setShowPersonnalisationExport(!showPersonnalisationExport)}
                    />
                )
            }
            <SimulateurContainerWithMenuBis
                typeTab="pvImmobiliere"
                menus={menus}
                onRequestMenuChange={handleMenuChange}
            />
            {/*<div style={{ marginTop: 20 }} >
                {
                    pvImmobiliere === 0 && (
                        <Configuration />
                    )
                }

                {
                    pvImmobiliere === 1 && (
                        <CessionDirecte
                            data={pvImmobiliereState.form1}
                            resultat={resultat && resultat.form1 ? resultat.form1 : {}}
                        />
                    )
                }

                {
                    pvImmobiliere === 2 && (
                        <CessionIndirecte
                            data={pvImmobiliereState.form2}
                            resultat={resultat && resultat.form2 ? resultat.form2 : {}}
                        />
                    )
                }

                {
                    pvImmobiliere === 3 && (
                        <CessionParts
                            data={pvImmobiliereState.form3}
                            resultat={resultat && resultat.form3 ? resultat.form3 : {}}
                        />
                    )
                }

                {
                    pvImmobiliere === 4 && (
                        <Comparaison
                            hypothese={pvImmobiliereState}
                            resultat={resultat && resultat.comparaison ? resultat.comparaison : {}}
                        />
                    )
                }
            </div>*/}
        </>
    )
}

export default PlusValueImmobiliere;
