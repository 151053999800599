import { CCol, CForm, CFormGroup, CLabel, CRow } from '@coreui/react';
import React, { useContext, useState, useEffect } from 'react';
import { InputCurrency, InputSelect } from '../../../../components/input';
import InputAnnee from '../../../../components/input/InputAnnee';
import { DataControlContext } from '../../../../store/context/dataControl';
import { currencyToSend } from '../../../../store/utilsFunc';
import { numberFormat } from '../../../../extra/all_ppt_render';
import InfoBulle from '../../../../components/InfoBulle/InfoBulle';
import { addToast } from './../../../../store/actions/utils/toast';
import { UtilsContext } from './../../../../store/context/utils';
import DataBlock from '../../../../components/DataBlock/DataBlock';
import { formatToUniqueId } from '../../../../utils';
import EmpruntDataBlock from '../../../../components/DataBlock/EmpruntDataBlock';

const ImpactsIFI = (props) => {
    const { utilsDispatch } = useContext(UtilsContext);
    const [showErrorAnneeImposition, setShowErrorAnneeImposition] = useState(false);

    const { data, openIFI, withoutCalcul, setWithCalcul } = props;
    const { empruntState, setEmpruntState } = useContext(DataControlContext);
    const {
        annee_imposition,
        valeur_immobilier,
        abattement_residence_principale
    } = empruntState;

    const abattementResidencePrincipaleData = [
        {
            value: 0,
            label: 'Non'
        },
        {
            value: 1,
            label: 'Oui'
        },
    ]

    const handleFormChange = (event, prop) => {
        if(prop !== 'abattement_residence_principale'){
            setWithCalcul(false);
        }

        let { value } = event.target;
        const emp = { ...empruntState };

        value = currencyToSend(value) || (prop === 'annee_imposition' ? "" : 0);

        emp[prop] = value;

        setEmpruntState(emp);
    }

    const handleFormBlur = () => { setWithCalcul(true) }

    const classForm = "col-lg-8 col-md-6 col-sm-7";
    const classForm2 = "col-lg-7 col-md-6 col-sm-7";

    useEffect(() => {
        const anneeImpositionInvalide = ((annee_imposition.toString().length === 4) && (annee_imposition < 2018));

        setShowErrorAnneeImposition(anneeImpositionInvalide);
    }, [annee_imposition])

    useEffect(() => {
        if (showErrorAnneeImposition) {
            utilsDispatch(addToast({ type: 'danger', title: 'ERREUR', description: "Veuillez corriger la saisie de l’année d’imposition à l’IFI. L’IFI est entré en vigueur au 01/01/2018." }))
        }
    }, [showErrorAnneeImposition])

    return (
        <EmpruntDataBlock
            title="IMPACTS IFI"
            withCloseButton={true}
            bodyStyle={{
                paddingTop: 0,
                paddingLeft: 0,
                paddingBottom: 0
            }}
        >
            <>
                <div style={{ background: '#FFFFFF', display: 'flex' }} >
                    <div className="formulaire" style={{ background: '#F4F4F4', marginRight: 10, padding: 10, width: '45%', marginTop: 0 }} >
                        <CCol xl={12}>
                            <CForm className="col-lg-12" id="form_pea">
                                <div>
                                    <div>
                                        <CRow style={{ marginRight: -24, marginLeft: -20 }}>
                                            <CCol sm="12">
                                                {/** Année d'imposition */}
                                                <CFormGroup className="form-inline col-lg-12" style={{ display: 'flex', flexWrap: 'nowrap', flexDirection: 'row' }}>
                                                    <CLabel className={classForm}>Année d'imposition</CLabel>
                                                    <InputAnnee
                                                        max={2099}
                                                        disabled={withoutCalcul}
                                                        invalid={showErrorAnneeImposition}
                                                        className='col-lg-2 col-md-5 col-sm-5'
                                                        value={annee_imposition}
                                                        onChange={(event) => handleFormChange(event, 'annee_imposition')}
                                                        onBlur={handleFormBlur}
                                                    />
                                                    {
                                                        showErrorAnneeImposition && (
                                                            <InfoBulle
                                                                type='error'
                                                                information="L'année d'imposition est au minimum 2018."
                                                            />
                                                        )
                                                    }
                                                </CFormGroup>

                                                {/** Valeur du bien/droit immobilier */}
                                                <CFormGroup className="form-inline col-lg-12" style={{ display: 'flex', flexWrap: 'nowrap', flexDirection: 'row' }}>
                                                    <CLabel className={classForm}>Valeur du bien/droit immobilier</CLabel>
                                                    <InputCurrency
                                                        id={`${formatToUniqueId("ImpactIFI valeur_immobilier")}`}
                                                        disabled={withoutCalcul}
                                                        className='col-lg-2 col-md-5 col-sm-5'
                                                        value={valeur_immobilier}
                                                        onChange={(event) => handleFormChange(event, 'valeur_immobilier')}
                                                        onBlur={handleFormBlur}
                                                    />
                                                </CFormGroup>

                                                {/** Abattement résidence principale */}
                                                <CFormGroup className="form-inline col-lg-12" style={{ display: 'flex', flexWrap: 'nowrap', flexDirection: 'row' }}>
                                                    <CLabel className={classForm}>Abattement résidence principale ?</CLabel>
                                                    <div className='col-lg-2 col-md-5 col-sm-5' style={{ textAlign: 'right', margin: 0, padding: 0 }} >
                                                        <InputSelect
                                                            id={`${formatToUniqueId("ImpactIFI abattement_residence_principale")}-select`}
                                                            custom
                                                            disabled={withoutCalcul}
                                                            style={{ width: '50%' }}
                                                            onChange={(event) => handleFormChange(event, 'abattement_residence_principale')}
                                                        >
                                                            {
                                                                abattementResidencePrincipaleData.map((donnee, indice) => {
                                                                    return (
                                                                        <option key={`Abattement${indice + 2}`} value={donnee.value} selected={abattement_residence_principale === donnee.value}>{donnee.label}</option>
                                                                    )
                                                                })
                                                            }
                                                        </InputSelect>
                                                    </div>
                                                </CFormGroup>

                                                {/** Valeur brute imposable */}
                                                <CFormGroup className="form-inline col-lg-12" style={{ display: 'flex', flexWrap: 'nowrap', flexDirection: 'row' }}>
                                                    <CLabel className={classForm}>Valeur brute imposable</CLabel>
                                                    <InputCurrency
                                                        id={`${formatToUniqueId("ImpactIFI Valeur brute imposable")}`}
                                                        disabled={true}
                                                        className='col-lg-2 col-md-5 col-sm-5'
                                                        value={data && data.valeur_brut_imposable ? numberFormat(data.valeur_brut_imposable) : 0}
                                                    />
                                                </CFormGroup>
                                            </CCol>
                                        </CRow>
                                    </div>
                                </div>
                            </CForm>
                        </CCol>
                    </div>
                    <div className='formulaire' style={{ padding: 10, background: '#F4F4F4', width: '45%', marginTop: 0 }} >
                        <CCol xl={12}>
                            <CForm className="col-lg-12" id="form_pea">
                                <div>
                                    <div>
                                        <CRow style={{ marginRight: -24, marginLeft: -20 }}>
                                            <CCol xl={12}>
                                                {/** Capital restant dû */}
                                                <CFormGroup className="form-inline col-lg-12" style={{ display: 'flex', flexWrap: 'nowrap', flexDirection: 'row' }}>
                                                    <CLabel className={classForm2}>Capital restant dû</CLabel>
                                                    <InputCurrency
                                                        id={`${formatToUniqueId("ImpactIFI Capital restant dûe")}`}
                                                        disabled={true}
                                                        max={2099}
                                                        className='col-lg-2 col-md-5 col-sm-5'
                                                        style={{ background: 'rgb(88,92,102)', color: '#FFFFFF' }}
                                                        value={data && data.capital_restant ? numberFormat(data.capital_restant) : 0}
                                                    />
                                                </CFormGroup>

                                                {/** Passif théorique IFI */}
                                                <CFormGroup className="form-inline col-lg-12" style={{ display: 'flex', flexWrap: 'nowrap', flexDirection: 'row' }}>
                                                    <CLabel className={classForm2}>Passif théorique IFI</CLabel>
                                                    <InputCurrency
                                                        id={`${formatToUniqueId("ImpactIFI CPassif théorique IFI")}`}
                                                        disabled={true}
                                                        className='col-lg-2 col-md-5 col-sm-5'
                                                        style={{ background: 'rgb(88,92,102)', color: '#FFFFFF' }}
                                                        value={data && data.passif_theorique ? numberFormat(data.passif_theorique) : 0}
                                                        onBlur={handleFormBlur}
                                                    />
                                                </CFormGroup>

                                                {/** Passif déductible IFI */}
                                                <CFormGroup className="form-inline col-lg-12" style={{ display: 'flex', flexWrap: 'nowrap', flexDirection: 'row' }}>
                                                    <CLabel className={classForm2}>Passif déductible IFI</CLabel>
                                                    <InputCurrency
                                                        id={`${formatToUniqueId("ImpactIFI Passif déductible IFI")}`}
                                                        disabled={true}
                                                        className='col-lg-2 col-md-5 col-sm-5'
                                                        style={{ background: 'rgb(88,92,102)', color: '#FFFFFF' }}
                                                        value={data && data.passif_deductible ? numberFormat(data.passif_deductible) : 0}
                                                    />
                                                    <InfoBulle
                                                        information="Selon le type d'emprunt, amortissable ou in fine"
                                                    />
                                                </CFormGroup>

                                                {/** Solde Net imposable IFI */}
                                                <CFormGroup className="form-inline col-lg-12" style={{ display: 'flex', flexWrap: 'nowrap', flexDirection: 'row' }}>
                                                    <CLabel className={classForm2}>Solde net imposable IFI</CLabel>
                                                    <InputCurrency
                                                        id={`${formatToUniqueId("ImpactIFI Solde net imposable IFI")}`}
                                                        disabled={true}
                                                        className='col-lg-2 col-md-5 col-sm-5'
                                                        value={data && data.solde_net_imposable ? numberFormat(data.solde_net_imposable) : 0}
                                                    />
                                                    <InfoBulle
                                                        information="Déduction limitée à la valeur imposable du bien immobilier"
                                                    />
                                                </CFormGroup>
                                            </CCol>
                                        </CRow>
                                    </div>
                                </div>
                            </CForm>
                        </CCol>
                    </div>
                </div>
                <div style={{ height: '25px', background: '#FFFFFF', borderRadius: '0 0 13px 13px' }} ></div>
            </>
        </EmpruntDataBlock>
    )
}

export default ImpactsIFI;
