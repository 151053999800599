import PPTX from 'pptxgenjs';
import { completeDate, fetchPPTPersonnalise, generateLastPage, now } from '../../all_ppt_render';
import { generatePageHypothese, generateTitleAndSubtitlePVImmo } from './hypothese';
import { generatePageSynthese } from './synthese';
import { generatePageDetail1 } from './detail';
import { generatePageDetail2 } from './detail2';
import { generateComparaison } from './comparaison';
import { defineContactSlideMaster, defineLentionSlideMaster, defineSlideMaster, defineTitleSlideMaster, generateFirstSlideTitle, generateTitleAndSubtitle } from '../../utils';

const exportPPTPlusValueImmobiliere = async (profil, hypothese, resultat, isFicheThematique=false, configurationsExport) => {
    const pptx = new PPTX();
    await defineSlideMaster(pptx);
    await defineTitleSlideMaster(pptx);
    await defineContactSlideMaster(pptx, "");
    await defineLentionSlideMaster(pptx, "");

    // SLIDE1
    const slide1 = pptx.addSlide({ masterName: 'TITLE_SLIDE' });

    const slide1Title = [
        { text: "IMPOSITIONS DE LA PLUS-VALUE IMMOBILIÈRE", options: { breakLine: true } },

    ];

    const slide1Subtitle = [
      { text: "Simulation fiscale", options: {} }
    ]

    const xSlide1 = 3.8;
    const ySlide1 = 2;

    generateFirstSlideTitle(
      pptx,
      slide1,
      slide1Title,
      0.97,
      2.18,
      "SIMULATION",
      0.97,
      8.12,
      slide1Subtitle
    );

    // SLIDES pour la CESSION DIRECTE
    if (hypothese.config.cession_directe) {
        const conditionAffichageCessionDirecte = configurationsExport?.find((element) => (element.type === 'cessiondirecte'));
        const conditionAffichageCaracteristique = conditionAffichageCessionDirecte?.childrens?.find((element) => (element.type === 'caracteristiques' && element.value === true))?.value

        if (conditionAffichageCaracteristique) {
          // Page HYPOTHESE
          const hypotheseSlide = pptx.addSlide({ masterName: 'MASTER_SLIDE' });
          generatePageHypothese(pptx, hypotheseSlide, hypothese.form1, resultat && resultat.form1 ? resultat.form1 : {});
        }

        const conditionAffichageSynthese = conditionAffichageCessionDirecte?.childrens?.find((element) => (element.type === 'impositiondelaplusvalueimmobilière'))

        if (conditionAffichageSynthese?.childrens?.find((element) => (element.type === 'synthese' && element.value === true))?.value) {
          // Page SYNTHESE
          const syntheseSlide = pptx.addSlide({ masterName: 'MASTER_SLIDE' });
          generatePageSynthese(pptx, syntheseSlide, hypothese.form1, resultat && resultat.form1 && resultat.form1.imposition_plus_value_immo ? resultat.form1.imposition_plus_value_immo : {});
        }

        if (conditionAffichageSynthese?.childrens?.find((element) => (element.type === 'detailfiscalite' && element.value === true))?.value) {
          // Page Détail 1
          const detail1Slide = pptx.addSlide({ masterName: 'MASTER_SLIDE' });
          generatePageDetail1(
              pptx,
              detail1Slide,
              hypothese.form1,
              resultat && resultat.form1 && resultat.form1.impot_revenu ? resultat.form1.impot_revenu : {},
              resultat && resultat.form1 && resultat.form1.prelevement_sociaux ? resultat.form1.prelevement_sociaux : {}
          );

          // Page Détail 2
          const detail2Slide = pptx.addSlide({ masterName: 'MASTER_SLIDE' });
          generatePageDetail2(
              pptx,
              detail2Slide,
              hypothese.form1,
              resultat && resultat.form1 && resultat.form1.impot_revenu ? resultat.form1.impot_revenu : {},
              resultat && resultat.form1 && resultat.form1.sur_taxe ? resultat.form1.sur_taxe : {},
              resultat && resultat.form1 && resultat.form1.contribution_exceptionnelle_hauts_revenus ? resultat.form1.contribution_exceptionnelle_hauts_revenus : {},
              resultat && resultat.form1 && resultat.form1.impact_plafonnement_ifi ? resultat.form1.impact_plafonnement_ifi : {},
              resultat && resultat.form1 && resultat.form1.imposition_plus_value_immo && resultat.form1.frais_acquisition_part1 ? resultat.form1.frais_acquisition_part1 : 0
          );
        }

    }

    // SLIDES POUR LA CESSION INDIRECTE
    if (hypothese.config.cession_indirecte) {
        const conditionAffichageCessionIndirecte = configurationsExport?.find((element) => (element.type === 'cessionindirecte'));
        const conditionAffichageCaracteristique = conditionAffichageCessionIndirecte?.childrens?.find((element) => (element.type === 'caracteristiques' && element.value === true))?.value

        if (conditionAffichageCaracteristique) {
          // Page HYPOTHESE
          const hypotheseSlide = pptx.addSlide({ masterName: 'MASTER_SLIDE' });
          generatePageHypothese(pptx, hypotheseSlide, hypothese.form2, resultat && resultat.form2 ? resultat.form2 : {});
        }

        const conditionAffichageSynthese = conditionAffichageCessionIndirecte?.childrens?.find((element) => (element.type === 'impositiondelaplusvalueimmobilière'))

        if (conditionAffichageSynthese?.childrens?.find((element) => (element.type === 'synthese' && element.value === true))?.value) {
          // Page SYNTHESE
          const syntheseSlide = pptx.addSlide({ masterName: 'MASTER_SLIDE' });
          generatePageSynthese(pptx, syntheseSlide, hypothese.form2, resultat && resultat.form2 && resultat.form2.imposition_plus_value_immo ? resultat.form2.imposition_plus_value_immo : {});
        }

        if (conditionAffichageSynthese?.childrens?.find((element) => (element.type === 'detailfiscalite' && element.value === true))?.value) {
          // Page Détail 1
          const detail1Slide = pptx.addSlide({ masterName: 'MASTER_SLIDE' });
          generatePageDetail1(
              pptx,
              detail1Slide,
              hypothese.form2,
              resultat && resultat.form2 && resultat.form2.impot_revenu ? resultat.form2.impot_revenu : {},
              resultat && resultat.form2 && resultat.form2.prelevement_sociaux ? resultat.form2.prelevement_sociaux : {}
          );

          // Page Détail 2
          const detail2Slide = pptx.addSlide({ masterName: 'MASTER_SLIDE' });
          generatePageDetail2(
              pptx,
              detail2Slide,
              hypothese.form2,
              resultat && resultat.form2 && resultat.form2.impot_revenu ? resultat.form2.impot_revenu : {},
              resultat && resultat.form2 && resultat.form2.sur_taxe ? resultat.form2.sur_taxe : {},
              resultat && resultat.form2 && resultat.form2.contribution_exceptionnelle_hauts_revenus ? resultat.form2.contribution_exceptionnelle_hauts_revenus : {},
              resultat && resultat.form2 && resultat.form2.impact_plafonnement_ifi ? resultat.form2.impact_plafonnement_ifi : {},
              resultat && resultat.form2 && resultat.form2.imposition_plus_value_immo && resultat.form2.frais_acquisition_part1 ? resultat.form2.frais_acquisition_part1 : 0
          );

        }
    }

    // SLIDES pour la CESSION DE PARTS DE SCI à l'IR
    if (hypothese.config.cession_sci_ir) {

        const conditionAffichageCessionSciIR = configurationsExport?.find((element) => (element.type === 'cessionindirecte'));
        const conditionAffichageCaracteristique = conditionAffichageCessionSciIR?.childrens?.find((element) => (element.type === 'caracteristiques' && element.value === true))?.value

        if (conditionAffichageCaracteristique) {

          // Page HYPOTHESE
          const hypotheseSlide = pptx.addSlide({ masterName: 'MASTER_SLIDE' });
          generatePageHypothese(pptx, hypotheseSlide, hypothese.form3, resultat && resultat.form3 ? resultat.form3 : {});
        }

        const conditionAffichageSynthese = conditionAffichageCessionSciIR?.childrens?.find((element) => (element.type === 'impositiondelaplusvalueimmobilière'))

        if (conditionAffichageSynthese?.childrens?.find((element) => (element.type === 'synthese' && element.value === true))?.value) {
          // Page SYNTHESE
          const syntheseSlide = pptx.addSlide({ masterName: 'MASTER_SLIDE' });
          generatePageSynthese(pptx, syntheseSlide, hypothese.form3, resultat && resultat.form3 && resultat.form3.imposition_plus_value_immo ? resultat.form3.imposition_plus_value_immo : {});
        }

        if (conditionAffichageSynthese?.childrens?.find((element) => (element.type === 'detailfiscalite' && element.value === true))?.value) {
          // Page Détail 1
          const detail1Slide = pptx.addSlide({ masterName: 'MASTER_SLIDE' });
          generatePageDetail1(
              pptx,
              detail1Slide,
              hypothese.form3,
              resultat && resultat.form3 && resultat.form3.impot_revenu ? resultat.form3.impot_revenu : {},
              resultat && resultat.form3 && resultat.form3.prelevement_sociaux ? resultat.form3.prelevement_sociaux : {}
          );

          // Page Détail 2
          const detail2Slide = pptx.addSlide({ masterName: 'MASTER_SLIDE' });
          generatePageDetail2(
              pptx,
              detail2Slide,
              hypothese.form3,
              resultat && resultat.form3 && resultat.form3.impot_revenu ? resultat.form3.impot_revenu : {},
              resultat && resultat.form3 && resultat.form3.sur_taxe ? resultat.form3.sur_taxe : {},
              resultat && resultat.form3 && resultat.form3.contribution_exceptionnelle_hauts_revenus ? resultat.form3.contribution_exceptionnelle_hauts_revenus : {},
              resultat && resultat.form3 && resultat.form3.impact_plafonnement_ifi ? resultat.form3.impact_plafonnement_ifi : {},
              resultat && resultat.form3 && resultat.form3.imposition_plus_value_immo && resultat.form3.frais_acquisition_part1 ? resultat.form3.frais_acquisition_part1 : 0
          );
        }

    }

    let compteur = 0;

    if (hypothese.config.cession_directe) compteur++;
    if (hypothese.config.cession_indirecte) compteur++;
    if (hypothese.config.cession_sci_ir) compteur++;

    /** Place pour la comparaison */
    const emoji = [
        {
            type: 0,
            valeur: 0,
            src: null
        },
        {
            type: 1,
            valeur: 0,
            src: null
        },
        {
            type: 2,
            valeur: 0,
            src: null
        },
    ];

    const result = resultat && resultat.comparaison ? resultat.comparaison : {};

    let prixCession = [
        {
            type: 0,
            valeur: result.cession_directe && result.cession_directe.prix_net_cession ? result.cession_directe.prix_net_cession : 0,
            src: null
        },
        {
            type: 1,
            valeur: result.cession_indirecte && result.cession_indirecte.prix_net_cession ? result.cession_indirecte.prix_net_cession : 0,
            src: null
        },
        {
            type: 2,
            valeur: result.cession_sci_ir && result.cession_sci_ir.prix_net_cession ? result.cession_sci_ir.prix_net_cession : 0,
            src: null
        }
    ];

    prixCession = prixCession.sort((a, b) => {
        if (a.valeur > b.valeur) return -1;
        else return 1;
    }
    );

    prixCession.map((donnee, indice) => {
        if (indice === 0) prixCession[indice].src = 'HAUT';
        else if (indice === 1) prixCession[indice].src = 'INTERMEDIAIRE';
        else prixCession[indice].src = 'BAS';
    })

    if (hypothese.config.cession_directe === 0) prixCession = prixCession.filter((element) => element.type !== 0);
    if (hypothese.config.cession_indirecte === 0) prixCession = prixCession.filter((element) => element.type !== 1);
    if (hypothese.config.cession_sci_ir === 0) prixCession = prixCession.filter((element) => element.type !== 2);

    if(prixCession.length >= 1){
        if(prixCession.length === 1){
            prixCession[0].src = 'HAUT';
        }

        if (prixCession.length === 2) {
            prixCession.map((donnee, indice) => {
                if (indice === 0) prixCession[indice].src = 'HAUT';
                else prixCession[indice].src = 'BAS';
            })

            if (prixCession[0].valeur === prixCession[1].valeur) {
                prixCession[0].src = null;
                prixCession[1].src = null;
            }
        }

        if (prixCession.length === 3) {
            if (prixCession[0].valeur === prixCession[1].valeur) {
                if (prixCession[2].valeur === prixCession[1].valeur) {
                    prixCession[0].src = null;
                    prixCession[1].src = null;
                    prixCession[2].src = null;
                }
            }
        }
    }
    /*else{
        prixCession[0].src = null;
    }*/

    /** Fin place pour la comparaison */

    const conditionAffichageComparaison = configurationsExport?.find((element) => (element.type === 'comparaison'));

    if (conditionAffichageComparaison?.value) {
      if (compteur >= 2) {
          // Comparaison
          const comparaisonSlide = pptx.addSlide({ masterName: 'MASTER_SLIDE' });
          let x = 0.6;
          let y = 0.9;

          // Cession directe
          if (hypothese.config.cession_directe) {
              generateComparaison(
                  pptx,
                  comparaisonSlide,
                  x,
                  y,
                  hypothese.form1,
                  resultat && resultat.comparaison && resultat.comparaison.cession_directe ? resultat.comparaison.cession_directe : {},
                  prixCession.find((element) => element.type === 0 ).src,
                  compteur
              );

              x += 3.9;
          }

          // Cession indirecte
          if (hypothese.config.cession_indirecte) {
              generateComparaison(
                  pptx,
                  comparaisonSlide,
                  x,
                  y,
                  hypothese.form2,
                  resultat && resultat.comparaison && resultat.comparaison.cession_indirecte ? resultat.comparaison.cession_indirecte : {},
                  prixCession.find((element) => element.type === 1 ).src,
                  compteur
              );

              x += 3.9;
          }

          // Cession de parts de SCI
          if (hypothese.config.cession_sci_ir) {
              generateComparaison(
                  pptx,
                  comparaisonSlide,
                  x,
                  y,
                  hypothese.form3,
                  resultat && resultat.comparaison && resultat.comparaison.cession_sci_ir ? resultat.comparaison.cession_sci_ir : {},
                  prixCession.find((element) => element.type === 2 ).src,
                  compteur
              );
          }
      }
    }

    const conditionAffichageInformation = configurationsExport?.find((element) => (element.type === 'informations'));

    if (conditionAffichageInformation?.value) {

      // Informations
      const informationSlide = pptx.addSlide({ masterName: 'MASTER_SLIDE' });
      const rowProps = {
          fontSize: 12,
          valign: 'middle',
          fontFace: 'Roboto',
          align: 'justify',
          margin: [5, 10, 5, 10]
      };

      const borders = [
          {
              pt: 1,
              color: 'DAE3F3'
          },
          {
              pt: 2,
              color: '4472C4'
          },
          {
              pt: 1,
              color: 'DAE3F3'
          },
          {
              pt: 1,
              color: 'DAE3F3'
          }
      ]

      const text1 = [
          { text: "Certaines plus-values relatives à des opérations de démolition-reconstruction bénéficient d'un ", options: { breakLine: false } },
          { text: "abattement exceptionnel ", options: { breakLine: false, bold: true } },
          { text: "pour la détermination de l'assiette imposable à l'IR et aux PS. Le taux de l'abattement exceptionnel est en principe de 70% et peut être majoré à 85%. Il est appliqué après prise en compte, le cas échéant, de l'abattement pour durée de détention.", options: { breakLine: false } },
      ]

      const data = [
          [
              { text: "Plus-value imposable à l’IR et aux PS", options: { ...rowProps, bold: true, border: [...borders], fill: 'DAE3F3' } },
              { text: text1, options: { ...rowProps } }
          ],
          [
              { text: "", options: { ...rowProps } },
              { text: "", options: { ...rowProps } }
          ],
          [
              { text: "Plus-value imposable à la Surtaxe", options: { ...rowProps, bold: true, border: [...borders], fill: 'DAE3F3' } },
              { text: "Une taxe s'applique sur certaines plus-values immobilières, autres que les cessions de terrains à bâtir. Cette taxe est due lorsque le montant de la plus-value nette imposable à l'IR est supérieure à 50 000 € par cédant. Elle est calculée selon un barème dont les taux varient de 2% à 6% en fonction du montant de la plus-value imposable. A titre d'illustration, si la plus-value imposable est supérieure à 260 000 €, la taxe est due au taux unique de 6% sur la totalité de la plus-value nette (retenue dès le 1er euro).", options: { ...rowProps } }
          ],
          [
              { text: "", options: { ...rowProps } },
              { text: "", options: { ...rowProps } }
          ],
          [
              { text: "Plus-value imposable à la CEHR", options: { ...rowProps, bold: true, border: [...borders], fill: 'DAE3F3' } },
              { text: "La CEHR est calculée sur le revenu fiscal de référence, après réintégration de la plus-value immobilière pour son montant net imposable à l'IR.", options: { ...rowProps } }
          ],
          [
              { text: "", options: { ...rowProps } },
              { text: "", options: { ...rowProps } }
          ],
          [
              { text: "Impact sur le plafonnement de l’IFI", options: { ...rowProps, bold: true, border: [...borders], fill: 'DAE3F3' } },
              { text: "Le mécanisme du plafonnement de l'IFI est calculé en retenant la plus-value immobilière pour son montant brut, avant application éventuelle des abattements pour durée de détention (et abattement exceptionnel) ou de l'exonération s'agissant de la cession de la résidence principale.", options: { ...rowProps } }
          ],
      ]

      generateTitleAndSubtitle(
          "SIMULATION",
          pptx,
          informationSlide,
          {
              text: "INFORMATIONS",
              width: 2.24
          },
          undefined,
          undefined,
          {
            text: 'PLUS-VALUES IMMOBILIÈRES DES PARTICULIERS'
          }
      )

      informationSlide.addTable(
          data,
          {
              x: 0.7,
              y: 1.7,
              colW: [3.5, 6],
          }
      )
    }

    // Dernier SLIDE
    const lastSlide = pptx.addSlide({ masterName: 'CONTACT_SLIDE' });
    // const profil = await getInfoClient();

    generateLastPage(pptx, lastSlide, profil);

    pptx.writeFile("Simulation_PV_Immo_" + completeDate(now) + ".pptx");
}

export default exportPPTPlusValueImmobiliere;
