import info2 from '../assets/images/info2.png';
import simulation_image from "../assets/images/bar_chart_2.png";
import search from "../assets/images/search_add.png";
import moment from 'moment';
import Asset41 from '../assets/images/Asset41.png' //BP
import Asset43 from '../assets/images/Asset43.png' //PP
import Asset44 from '../assets/images/Asset44.png' //PI
import Asset45 from '../assets/images/Asset45.png' //PF
import Asset46 from '../assets/images/Asset46.png' //AA
import Asset57 from '../assets/images/Asset57.png'
import aiguilleBleue from "../assets/images/AIGUILLE_BLEU.png";
import sommaire from "../assets/images/SOMMAIRE.png";
import projetCession from "../assets/images/PROJET_CESSION_BLUE.png";
import grilleBleu from "../assets/images/GRILLE_BLEU.png";
import settingsBleu from "../assets/images/SETTINGS_BLEU.png";
import cessionPicto from "../assets/icons/CESSION_PPT.png";
import financierPicto from "../assets/icons/FINANCIER_PPT.png";
import whiteInfo from "../assets/images/WHITE_INFO.png";
import chartImage from '../assets/images/Export/AV/CHART.png';
import logoBase64 from './logoBase64';
import { restApiUri } from '../config';
import donationPicto from '../assets/images/donationPicto.png';
import level1 from '../assets/images/level1.png';
import level2 from '../assets/images/level2.png';
import level3 from '../assets/images/level3.png';
import titleDonation from '../assets/images/titleDonation.png';
import longTitleDonation from '../assets/images/longTitleDonation.png';
import titleImage from '../assets/images/Export/IFI/HEADER.png';
import Calculatrice from '../assets/images/Export/IFI/CALCULATOR.png';
import Info from '../assets/images/Export/IFI/INFO.png';

export const today = moment();

export const blueColor = '32396c';

export const littlePinkColor = 'ecbbb3';

export const orangeColor = 'df584c';

export const blueBaseColor = '28366D';

// Couleurs à utiliser
export const headerLeftColor = '32396c';
export const headerMiddleColor = 'D8D8D8';
export const headerRightColor = '28366D';//'df584c';
export const grayLetter = '44546a';
export const grayInHeadArray = 'EBECF1';
export const firstBlueInHeadArray = '32396c';
export const lastBlueInHeadArray = '0070c0';
export const chartGrayBackground = 'f3f3f6';
export const similaryHeaderMiddleColor = 'FDF8F7';
export const similaryBlueCell = 'e8ebf4';
export const greenTableCell = '00b050';
export const redTableCell = 'ee584c';
export const blueTableCell = '4472c4';
export const blackColor = '343434';
// Fin couleurs à utiliser

// Police à utiliser
export const gillSansMT = 'Gill Sans MT';
export const liberationSans = 'Liberation Sans';

export const sizeAndTableFamily = {
    fontSize: 10,
    fontFace: gillSansMT
};

export const range = (start, end) => {
    if (start === end) return [start];
    return [start, ...range(start + 1, end)];
}

export const fetchLogoCouleurPrincipale = async () => {
    const user = JSON.parse(localStorage.getItem("user"));
    const url = `${restApiUri}/api/banque/${user.pole.banqueId}`;
    const data = await fetch(url, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${localStorage.getItem("token")}`
        }
    });

    return await data.json();
};

const getDigits = (color) => {
    let col = '';
    for(let i=0; i<color.length; i++){
        if(col.length < 6){
            col += color[i];
        }
    }

    return col;
};

export const fetchPPTPersonnalise = async () => {
    const response = await fetchLogoCouleurPrincipale();
    let data = {
        logo: 'simulabox',
        couleur: orangeColor,
        pptPersonnalise: false
    };

    if(response){
            const logo = response.image_64 && response.image_64 !== "" ? response.image_64 : 'simulabox';
            //const couleur = response.couleur ? (JSON.parse(response.couleur).hex).split('#')[1] : orangeColor;
            const couleur = response.couleur ? getDigits(response.couleur.split('#')[1]) : orangeColor;
            const pptPersonnalise = response.ppt_key;

            data.logo = logo;
            data.couleur = couleur;
            data.pptPersonnalise = pptPersonnalise;
    }

    return data;
};

const subtitleIconByType = (type) => {
    let icone =  {
        image: chartImage,
        x: 0.8,
        y: 4.8,
        w: 0.6,
        h: 0.5,
    };

    if(type === 'CHART'){
        icone.image = chartImage;
        icone.x = 0.95;
        icone.y = 4.6;
        icone.w = 0.6;
        icone.h = 0.5;
    }

    else if(type === 'FISCALITE'){
        icone.image = whiteInfo;
        icone.x = 0.85;
        icone.y = 4.6;
        icone.w = 0.55;
        icone.h = 0.5;
    }

    return icone;
};

export const slideWithOnlyTitleAndSubtitle = (
    slide,
    title,
    subtitle,
    subtitleColor = headerRightColor,
    pptx = undefined,
    isBottom = false,
    longSubtitle = false,
    number = undefined,
    withLogoAndColors = false,
    steps=[],
    type = undefined
    ) => {
    if(number){
        slide.addText(number, {
            x: 0.8,
            y: 4.6,
            w: 0.7,
            h: 0.6,
            color: 'FFFFFF',
            align: 'center',
            fill: subtitleColor,
            fontFace: gillSansMT,
            fontSize: 19.3,
            line: {
                pt: 1,
                color: subtitleColor,
            }
        });
    }

    const titleOptions = {
        x: number ? 1.7 : 0.8,
        y: isBottom ? 3.7 : 2,
        w: '75%',//'100%'
        h: 0.7,//4
        align: 'left',
        fontSize: 24.6,
        fontFace: gillSansMT,
        color: blueBaseColor,
        lineSpacing: 38
    };

    const subtitleOptions = type && isBottom ?
    {
        x: number ? 1.7 : 0.8,
        y: 4.5,
        w: type.textWidth,
        h: 0.7,
        align: 'right',
        valign: 'center',
        fontSize: 20,
        color: subtitleColor,
        fill: headerRightColor,
        fontFace: gillSansMT,
    } :
    {
        x: number ? 1.7 : 0.8,
        y: 3,
        w: '78%',//'88%',
        h: longSubtitle ? 1 : 0.5,
        align: 'left',
        fontSize: subtitleColor === headerRightColor ? 17.3 : 16,
        color: subtitleColor,
        fill: isBottom && headerRightColor,
        fontFace: gillSansMT,
    }

    slide.addText(title, titleOptions);
    slide.addText(subtitle, subtitleOptions);

    if(type){
        const caract = subtitleIconByType(type.type);
        slide.addImage({
            path: caract.image,
            x: caract.x,
            y: caract.y,
            w: caract.w,
            h: caract.h
        });
    }

    if(steps && steps.length > 0){
        slide.addText("", {
            x: 0.6,
            y: 4.5,
            w: '50%',
            h: '0.40%',
            fill: '000000'
        });

        let y = 4.95;

        steps.map((data, index) => {
            slide.addText("", {
                x: 0.7,
                y: y,
                w: '0.4%',
                h: '0.4%',
                fill: '000000'
            });

            slide.addText(data, {
                x: 1,
                y: y - 0.18,
                fontFace: 'Gill Sans MT',
                fontSize: 15,
                w: '60%',
                h: 0.4
            });

            y += 0.42;
        });
    }
};

const caracteristiquesPicto = (type, pptPersonnalise) => {
    let caract = new Object();

    if(type === 'FISCALITE'){
        caract.image = info2;
        caract.x = 0.8;
        caract.y = 0.61;
        caract.w = 0.5;
        caract.h = 0.5;
    }
    else if(type === 'PATRIGLOBALE'){
        caract.image = Asset41;
        caract.x = 0;
        caract.y = 0.48;
        caract.w = '60%';
        caract.h = '10.2%';
    }
    else if(type === 'PATRIFI'){
        caract.image = Asset45;
        caract.x = 0;
        caract.y = 0.48;
        caract.w = '60%';
        caract.h = '10.2%';
    }
    else if(type === 'PATRIIMMO'){
        caract.image = Asset44;
        caract.x = 0;
        caract.y = 0.48;
        caract.w = '60%';
        caract.h = '10.2%';
    }
    else if(type === 'PATRIPRO'){
        caract.image = Asset43;
        caract.x = 0;
        caract.y = 0.48;
        caract.w = '60%';
        caract.h = '10.2%';
    }
    else if(type === 'AUTREACTIF'){
        caract.image = Asset46;
        caract.x = 0;
        caract.y = 0.48;
        caract.w = '60%';
        caract.h = '10.2%';
    }
    else if(type === 'PATRIPASSIF'){
        caract.image = Asset57;
        caract.x = 0;
        caract.y = 0.48;
        caract.w = '60%';
        caract.h = '10.2%';
    }
    else if(type === 'CHART'){
        caract.image = simulation_image;
        caract.x = 0.8;
        caract.y = 0.58;
        caract.w = 0.7;
        caract.h = 0.6;
    }
    else if(type === 'SOMMAIRE'){
        caract.image = sommaire;
        caract.x = 0.8;
        caract.y = 0.65;
        caract.w = 0.5;
        caract.h = 0.4;
    }
    else if(type === 'AIGUILLE'){
        caract.image = aiguilleBleue;
        caract.x = 0.8;
        caract.y = 0.69;
        caract.w = 0.5;
        caract.h = 0.30;
    }
    else if(type === 'BOOK'){
        caract.image = projetCession;
        caract.x = 0.8;
        caract.y = 0.65;
        caract.w = 0.5;
        caract.h = 0.4;
    }
    else if(type === 'GRILLE'){
        caract.image = grilleBleu;
        caract.x = 0.8;
        caract.y = 0.65;
        caract.w = 0.5;
        caract.h = 0.4;
    }
    else if(type === 'SETTINGS'){
        caract.image = settingsBleu;
        caract.x = 0.8;
        caract.y = 0.57;
        caract.w = 0.6;
        caract.h = 0.6;
    }
    else if(type === 'DONATION'){
        caract.image = donationPicto;
        caract.x = 0.8;
        caract.y = 0.57;
        caract.w = 0.6;
        caract.h = 0.6;
    }
    else{
        caract.image = search;
        caract.x = 0.8;
        caract.y = 0.65;
        caract.w = 0.5;
        caract.h = 0.5;
    }

    return caract;
};

const boxPicto = (box) => {
    let picto = {
        image: cessionPicto,
        x: "",
        y: "",
        h: "",
        w: ""
    };

    if(box === 'CESSION'){
        picto = {
            image: cessionPicto,
            x: 9.8,
            y: 0.45,
            h: "10%",
            w: "7%"
        };
    }
    else if(box === 'FINANCIER'){
        picto = {
            image: financierPicto,
            x: 9.8,
            y: 0.5,
            h: "8.5%",
            w: "7%"
        };
    }

    return picto;
};

export const addTitleInPatrimoineG = (
    slide,
    type = 'PATRIGLOBALE',
    withLogoAndColors=false,
) => {
    const picto = caracteristiquesPicto(type, withLogoAndColors);

    slide.addImage({ path: picto.image, x: picto.x, y: picto.y, w: picto.w, h: picto.h });

}

export const addTitleWithoutChartIcon = (
    pptx,
    slide,
    textToDisplay,
    type = 'FISCALITE',
    // numberTitle,
    bannerText = '',
    bannerColor = 'ORANGE',
    bannerTextX=undefined,
    withLogoAndColors=false,
    boxPictoType=undefined
    ) => {
    const picto = caracteristiquesPicto(type, withLogoAndColors);

    slide.addShape(pptx.ShapeType.rect, { x: 0.6, y: 0.38, w: '90%', h: "11%", fill: 'e0e1e9' });
    slide.addShape(pptx.ShapeType.rect, { x: 0.6, y: 1.30, w: '90%', h: '0.4%', fill: '000000' });

    type !== "NO_IMAGE" && slide.addImage({ path: picto.image, x: picto.x, y: picto.y, w: picto.w, h: picto.h });
    slide.addText(
        textToDisplay, {
            x: type !== "NO_IMAGE" ? 1.3 : 0.85,
            y: 0.495,
            w: "85%",
            h:"9%",
            fontSize: 19,
            color: '28366D',
            fontFace: 'roboto'
        }
    );
    if (bannerText !== '') {
        slide.addText(bannerText, {
             x: 8.9,
             y: 1,
             fontSize: 16,
             w: "20%",
             h: "7%",
             fill: bannerColor === 'BLUE' ? '43556d' : bannerColor,
             fontFace: "Gill Sans MT",
             color: 'ffffff',
             align: 'center',
             valign: 'middle',
             line: {
                 color: bannerColor === 'BLUE' ? '43556d' : bannerColor,
             }
        });
    }
};

export const addTitleWithChartIcon = (
    pptx,
    slide,
    textToDisplay,
    type = 'FISCALITE',
    bannerText = '',
    bannerColor = 'ORANGE',
    bannerTextX=undefined,
    withLogoAndColors=false,
    boxPictoType=undefined
    ) => {
    const picto = caracteristiquesPicto(type, withLogoAndColors);

    slide.addShape(pptx.ShapeType.rect, { x: 0.6, y: 0.38, w: '90%', h: "11%", fill: 'e0e1e9' });
    slide.addShape(pptx.ShapeType.rect, { x: 0.6, y: 1.30, w: '90%', h: '0.4%', fill: '000000' });

    type !== "NO_IMAGE" && slide.addImage({ path: picto.image, x: picto.x, y: picto.y, w: picto.w, h: picto.h });
    slide.addText(
        textToDisplay, {
            x: type === "NO_IMAGE" ? 0.6 : 1.5,
            y: 0.38,//0.86,
            w: "80%",
            h: "12%",
            align: 'left',
            valign: 'middle',
            fontSize: 19,
            color: '28366D',
            fontFace: 'Roboto'
        }
    );
    if (bannerText !== '') {
        slide.addText(bannerText, {
             x: 8.9,
             y: 1.15,
             fontSize: 16,
             w: "20%",
             h: "5%",
             fill: bannerColor === 'BLUE' ? '43556d' : bannerColor,
             fontFace: "Gill Sans MT",
             color: 'ffffff',
             align: 'center',
             valign: 'middle',
             line: {
                 color: bannerColor === 'BLUE' ? '43556d' : bannerColor,
             }
        });
    }
};

export const definePageNumber = (slide, numero, withLogoAndColors=false) => {
    slide.addText(numero, {
            x: 2.18,
            y: "94.355%",
            w: "3.5%",
            h: "2%",
            fontSize: 11,
            color: withLogoAndColors ? blackColor : headerLeftColor
        }
    )
};

export const completeDate = (date) => {
    const currentDate = date.split('/');
    let day = currentDate[0];
    let month = currentDate[1];
    const year = currentDate[2];

    if(day.length === 1) day = `0${day}`;
    if(month.length === 1) month = `0${month}`;

    return `${day}/${month}/${year}`;
};

export const couleurOpaquePrincipale = (couleurPrincipale) => {
    return `1a${couleurPrincipale}`;
};

export const defineSlideMaster = (pptx, simulation, withLogoAndColors=false) => {
    const userLogo = "image/png;base64," + simulation.logo;
    simulation.date = now;

    const objects = [
        { text: { text: "Document d’information générale – Non contractuel et Confidentiel", options: { x: 0.6, y: "90.6%", fontSize: 11, color: headerLeftColor, w: 5, h: 0.3 } } },
        { text: { text: `En date du ${completeDate(simulation.date)} ${!simulation.noPageNumber ? '-' : ''} `, options: { x: 0.6, y: "94.3%", fontSize: 11, color: headerLeftColor, w: 3, h: 0.2 } } },
    ];

    if(withLogoAndColors){
        objects.push(
            {
                image: {
                    x: "80%",
                    y: "90.6%",
                    w: 1.8,
                    h: 0.6,
                    data: userLogo
                }
            }
        )
    }
    else{
        objects.push(
            {
                image: {
                    x: "80%",
                    y: "90.6%",
                    w: 1.8,
                    h: 0.6,
                    data: "image/png;base64," + logoBase64
                }
            }
        )
    }

    pptx.defineSlideMaster({
        title: "MASTER_SLIDE",
        bkgd: "FFFFFF",
        objects: objects,
        slideNumber: {
            x: 2.18,
            y: "93.8%",
            //y: "93.8%",
            w: "3.5%",
            h: 0.18,
            bold: false,
            color: headerLeftColor,
            //fontFace: 'Gill Sans MT',
            fontSize: 11
        }
    });
};

export const tableauChart1 = (simulateur, rows, capital, gainsNet) => {
    return [{
            name: simulateur === "IS" ? 'Valorisation' : 'Capital',
            labels: rows ? range(simulateur === "IS" ? rows[0].col1 : rows[0].col2, simulateur === "IS" ? rows[parseInt(rows.length - 1)].col1 : rows[parseInt(rows.length - 1)].col2) : [],
            values: capital
        },
        {
            name: simulateur === "IS" || simulateur === "AV" ? 'Produits nets capitalisés' : 'Gains nets capitalisés',
            labels: rows ? range(simulateur === "IS" ? rows[0].col1 : rows[0].col2, simulateur === "IS" ? rows[parseInt(rows.length - 1)].col1 : rows[parseInt(rows.length - 1)].col2) : [],
            values: gainsNet
        }
    ]
};

export const tableauChart2 = (simulateur, rows, capitalRetire, gainRetire, impotRetrait) => {
    return [{
            name: simulateur === "IS" ? 'Remboursement CCA' : 'Capital Retiré',
            labels: rows ? range(simulateur === "IS" ? rows[0].col1 : rows[0].col2, simulateur === "IS" ? rows[parseInt(rows.length - 1)].col1 : rows[parseInt(rows.length - 1)].col2) : [],
            values: capitalRetire
        },
        {
            name: simulateur === "IS" ? 'Dividentes nets' : simulateur === "AV" ? 'Produits nets retirés' : 'Gains nets retirés',
            labels: rows ? range(simulateur === "IS" ? rows[0].col1 : rows[0].col2, simulateur === "IS" ? rows[parseInt(rows.length - 1)].col1 : rows[parseInt(rows.length - 1)].col2) : [],
            values: gainRetire
        },
        {
            name: simulateur === "IS" ? 'Fiscalité sur dividendes' : 'Impôts sur retraits',
            labels: rows ? range(simulateur === "IS" ? rows[0].col1 : rows[0].col2, simulateur === "IS" ? rows[parseInt(rows.length - 1)].col1 : rows[parseInt(rows.length - 1)].col2) : [],
            values: impotRetrait
        }
    ]
};

export const listItem = (pptx, slide, x, y, listLabel, labelSize=14, labelColor= headerRightColor) => {
    slide.addShape(pptx.ShapeType.rect, { x: x, y: y + 0.08, w: "1%", h: "1%", fill: labelColor });
    slide.addText(listLabel, {
        x: x + 0.3,
        y: y - 0.1,
        w: "65%",
        h: 0.4,
        fontSize: labelSize,
        color: labelColor,
        fontFace: gillSansMT
    });
};

export const listWithTiret = (pptx, slide, x, y, listLabel, labelSize, listColor, width, align, yTiret=undefined, couleurTiret= headerRightColor, height) => {
    slide.addShape(pptx.ShapeType.rect, { x: x, y: yTiret ? yTiret : y + 0.08, w: "0.5%", h: "0.2%", fill: couleurTiret });
    slide.addText(listLabel, {
        align: align,
        x: x + 0.2,
        y: y - 0.1,
        w: width,
        h: height,
        fontSize: labelSize,
        color: listColor,
        fontFace: gillSansMT
    });

};

export const backgroundsTableLastRow = (conditionToBlack) => {
    if(conditionToBlack) return '535353';
    else return '808080';
};

export const backgroundsTableForEachRow = (rowIndex) => {
    if (rowIndex % 2) return 'f7f7f7';
    else return 'ffffff';
};

export const sommaireDesign = (slide, sommaire, color) => {
    let y = 3.0;
    sommaire.map((element) => {
        slide.addText(`${element}`, {
            x: "48.2%",
            y: y,
            color: color,
            fontFace: gillSansMT,
            fontSize: 19.3,
            w: '47%',
            h: 0.6,
            line: {
                pt: 3,
                color: color
            }
        });
        y = y + 1;
    });
};

const date = new Date();
export const now = `${date.getDate()}/${1+date.getMonth()}/${date.getFullYear()}`;

export const numberFormat = (nombre) => {
    if(!nombre){
        return new Intl.NumberFormat('fr-FR').format(0);
    }
    return new Intl.NumberFormat('fr-FR').format(Math.round(nombre));
};

export const monthByIndex = (index) => {
    if(index == 1) return "Janvier";
    else if(index == 2) return "Février";
    else if(index == 3) return "Mars";
    else if(index == 4) return "Avril";
    else if(index == 5) return "Mai";
    else if(index == 6) return "Juin";
    else if(index == 7) return "Juillet";
    else if(index == 8) return "Août";
    else if(index == 9) return "Septembre";
    else if(index == 10) return "Octobre";
    else if(index == 11) return "Novembre";
    else return "Décembre";
};

export const monthByIndex2 = (index) => {
    if(index == 12) return "Janvier";
    else if(index == 11) return "Février";
    else if(index == 10) return "Mars";
    else if(index == 9) return "Avril";
    else if(index == 8) return "Mai";
    else if(index == 7) return "Juin";
    else if(index == 6) return "Juillet";
    else if(index == 5) return "Août";
    else if(index == 4) return "Septembre";
    else if(index == 3) return "Octobre";
    else if(index == 2) return "Novembre";
    else return "Décembre";
};

export const listePuceCarre = (slide, liste, x, y) => {
    let ord = y;
    liste.map((data, index) => {
        slide.addText("", {
            x: x,
            y: ord,
            w: '0.4%',
            h: '0.4%',
            fill: '000000'
        });

        slide.addText(data, {
            x: x + 0.3,
            y: ord - 0.15,
            w: '50%',
            h: 0.3,
            fontSize: 15,
            fontFace: 'Gill Sans MT',
            color: blackColor
        });

        ord += 0.42;
    });
};

export const generateFirstPageDonation = (slide, firstLetter, othersLetters) => {
    const text = [
        { text: firstLetter, options: { fontSize: 46, breakLine: false } },
        { text: othersLetters, options: { fontSize: 25 } }
    ];

    slide.addText(text, {
        x: 0.6,
        y: 3.2,
        w: '90%',
        h: '13%',
        align: 'center',
        valign: 'middle',
        color: '28366D',
        fontFace: 'Roboto'
    });
};

export const generateSommairePageSuccession = (slide, level) => {
    const x = 1;
    const y = 2.8;
    const imageWidth = 0.5;
    const imageHeight = 0.3;
    const textWidth = '70%';
    const textHeight = '8%';
    const complementaireImageX = 0.1;
    const complementaireImageY = 0.15

    // Etape 1
    if(level === 1){
        slide.addImage({
            path: level1,
            x: x - complementaireImageX,
            y: y + complementaireImageY,
            w: imageWidth,
            h: imageHeight
        });
    }

    slide.addText("1 - LE PATRIMOINE", {
        x: x + 0.5,
        y: y,
        fontFace: 'Roboto',
        fontSize: 18,
        w: textWidth,
        h: textHeight,
        fontSize: level === 1 ? 24 : 18,
        color: level === 1 ? "7030A0" : blackColor,
        bold: level === 1
    });

    if(level === 2){
        // Etape 2
        slide.addImage({
            path: level2,
            x: x - complementaireImageX,
            y: y + 1 + complementaireImageY,
            w: imageWidth,
            h: imageHeight
        });
    }

    slide.addText("2- LES HÉRITIERS, LÉGATAIRES ET BÉNÉFICIAIRES", {
        x: x + 0.5,
        y: y + 1,
        fontFace: 'Roboto',
        fontSize: 18,
        w: textWidth,
        h: textHeight,
        fontSize: level === 2 ? 24 : 18,
        color: level === 2 ? "FF8AD8" : blackColor,
        bold: level === 2
    });

    if(level === 3){
        // Etape 3
        slide.addImage({
            path: level3,
            x: x - complementaireImageX,
            y: y + 2 + complementaireImageY,
            w: imageWidth,
            h: imageHeight
        });
    }

    slide.addText("3- LA DÉVOLUTION ET LA FISCALITÉ", {
        x: x + 0.5,
        y: y + 2,
        fontFace: 'Roboto',
        fontSize: 18,
        w: textWidth,
        h: textHeight,
        fontSize: level === 3 ? 24 : 18,
        color: level === 3 ? "4472C4" : blackColor,
        bold: level === 3
    });
};

export const addTitleDonation = (slide, text, large) => {
    const height = '11%';
    slide.addImage({
        path: large ? longTitleDonation : titleDonation,
        x: 0,
        y: 0.38,
        w: large ? '90%' : '60%',
        h: height
    });

    slide.addText(text, {
        x: 2.4,
        y: 0.52,
        h: "9%",
        w: large ? "70%" : "40%",
        fontFace: 'Roboto',
        fontSize: 22,
        align: 'left',
        valign: 'middle',
        color: headerLeftColor
    });
};

export const getInfoClient = async () => {
    let userId = null;

    if(localStorage && localStorage.getItem('user')){
      userId = JSON.parse(localStorage.getItem('user')).id;
    }

    const query = await fetch(`${restApiUri}/api/detailClient/findDetailByUser/?userId=${userId}`, {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${localStorage.getItem("token")}`
      },
    });

    const data = await query.json();

    return {
        nom: data?.detail?.nom || "",
        fonction: data?.detail?.fonction || "",
        telephone: data?.detail?.telephone || "",
        email: data?.detail?.email || "",
        adresse: data?.detail?.adresse || "",
        societe: data?.detail?.societe || "",
        mentionLegale: data?.detail?.mentionLegale || ""
    }
  }

export const generateLastPage = (pptx, slide, info) => {
    let x = 3.5;
    let y = 1.9;

    const societe = info?.societe || "";
    const nom = info?.nom || "";
    const fonction = info?.fonction || "";
    const telephone = info?.telephone || "";
    const email = info?.email || "";
    const adresse = info?.adresse || "";
    const mentionLegale = info?.mentionLegale || "";
    const siteWeb = info?.siteWeb || "";

    const borderHeight = 2;
    const rowH = 0.52;

    const border = [
        {
            pt: 0,
            color: ''
        },
        {
            pt: borderHeight,
            color: 'FFFFFF'
        },
        {
            pt: borderHeight,
            color: 'FFFFFF'
        },
        {
            pt: 0,
            color: ''
        },
    ];

    const margin = [ 0, 0, 0, 10 ]

    const textProps = {
        valign: 'middle',
        color: '44546A',
        fontSize: 12,
        fontFace: 'Arial',
        fill: 'FFFFFF',
        line: {
          color: 'EAEDF2'
        },
        margin: [ ...margin ],
        border: [ ...border ]
    }

    const textPropsA = {
      valign: 'middle',
      color: '44546A',
      fontSize: 12,
      fontFace: 'Arial',
      fill: 'FFFFFF',
      margin: [ ...margin ],
  }

    const data = [
        [
            { text: "Société", options: { ...textPropsA } },
            { text: societe, options: { ...textProps } }
        ],
        [
            { text: "Nom", options: { ...textPropsA } },
            { text: nom, options: { ...textProps } }
        ],
        [
            { text: "Fonction", options: { ...textPropsA } },
            { text: fonction, options: { ...textProps } }
        ],
        [
            { text: "Téléphone", options: { ...textPropsA } },
            { text: telephone, options: { ...textProps } }
        ],
        [
            { text: "Email", options: { ...textPropsA } },
            { text: email, options: { ...textProps } }
        ],
        [
            { text: "Adresse", options: { ...textPropsA } },
            { text: adresse, options: { ...textProps } }
        ],
    ];

    const hauteurCadre = 0.95;
    const texteMargin = [25, 5, 5, 5];

    /** Début de modification */
    // slide.addText("", {
    //     x: x - 0.85,
    //     y: y,
    //     color: '000000',
    //     fontSize: 28,
    //     bold: true,
    //     w: 0.1,
    //     h: hauteurCadre,
    //     valign: 'middle',
    //     align: 'left',
    //     fill: 'D6DCE5'
    // });

    // slide.addText("", {
    //     x: x - 0.7,
    //     y: y,
    //     color: '000000',
    //     fontSize: 28,
    //     bold: true,
    //     w: 0.2,
    //     h: hauteurCadre,
    //     valign: 'middle',
    //     align: 'left',
    //     fill: 'D6DCE5'
    // });

    // slide.addText("", {
    //     x: x - 0.45,
    //     y: y,
    //     color: '000000',
    //     fontSize: 28,
    //     bold: true,
    //     w: 0.41,
    //     h: hauteurCadre,
    //     valign: 'middle',
    //     align: 'left',
    //     fill: 'D6DCE5'
    // });
    /** Fin de modification */

    // slide.addText("Nous contacter", {
    //     x: x,
    //     y: y,
    //     color: '000000',
    //     fontSize: 24,
    //     bold: true,
    //     w: 5.08,
    //     h: hauteurCadre,
    //     valign: 'middle',
    //     align: 'left',
    //     fill: 'D6DCE5',
    //     margin: texteMargin
    // });

    slide.addTable(data, {
        x: 3.41,
        y: 2.23,
        colW: [ 1, 3.65 ],
        rowH: [
            rowH,
            rowH,
            rowH,
            rowH,
            rowH,
            rowH,
        ]
    });

    // Mentions légales
    if(info?.avecMentionLegale){
        const mentionLegaleSlide = pptx.addSlide({ masterName: 'MENTION_SLIDE' });

        mentionLegaleSlide.addText(mentionLegale, {
            x: 0.99,
            y: 2.39,
            fontFace: "Arial",
            fontSize: 12,
            w: 9.64,
            h: 4.39,
            valign: 'top',
            margin: texteMargin
        })
    }
};

export const generateThreeBarres = (slide, x, y, h) => {
    slide.addText("", {
        x: x - 0.85,
        y: y,
        color: '000000',
        fontSize: 28,
        bold: true,
        w: 0.1,
        h: h,//1.2,
        valign: 'middle',
        align: 'left',
        fill: 'D6DCE5'
    });

    slide.addText("", {
        x: x - 0.7,
        y: y,
        color: '000000',
        fontSize: 28,
        bold: true,
        w: 0.2,
        h: h,//1.2,
        valign: 'middle',
        align: 'left',
        fill: 'D6DCE5'
    });

    slide.addText("", {
        x: x - 0.38,
        y: y,
        color: '000000',
        fontSize: 28,
        bold: true,
        w: 0.39,
        h: h,//1.2,
        valign: 'middle',
        align: 'left',
        fill: 'D6DCE5'
    });
}

export const limiteNombreLigneSimulateursAutonomes = (rows) => {
    if(rows.length === 17) return 10;
    else if(rows.length === 22) return 12;
    else return 15;
};


export const generateSlide1SimulateurTitle = (pptx, slide, title, textHeader, x, y, type='SIMULATION', width=undefined) => {
    slide.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
        x: x,
        y: y,
        fill: '28366D',
        w: !width ? textHeader === 'CS' ? 7.4 : 7 : width,
        h: 1,
        rectRadius: 0.4,
        line: {
            color: '28366D'
        }
    });

    slide.addText(title, {
        x: x,
        y: y,
        w: !width ? textHeader === 'CS' ? 8.4 : 8 : width,
        h: 1,
        fontFace: 'Roboto',
        align: 'center',
        valign: 'center',
        fontSize: 16,
        color: 'FFFFFF',
        bold: true
    })

    slide.addImage({
        path: titleImage,
        x: x - 0.25,
        y: y - 0.2,
        w: 1.8,
        h: 1.3
    })

    const image = type === 'SIMULATION' ? Calculatrice : Info;

    const calculatriceWidth = 0.4;
    const calculatriceHeight = 0.5;
    const calculatriceX = x + 0.18;
    const calculatriceY = y + 0.18;

    const infoWidth = 0.17;
    const infoHeight = 0.42;
    const infoX = x + 0.32;
    const infoY = y + 0.22;

    slide.addImage({
        path: image,
        x: type === 'SIMULATION' ? calculatriceX : infoX,
        y: type === 'SIMMULATION' ? calculatriceY : infoY,
        w: type === 'SIMULATION' ? calculatriceWidth : infoWidth,
        h: type === 'SIMULATION' ? calculatriceHeight : infoHeight,
    })
}

export const generateTitleAndSubtitle = (type, pptx, slide, textHeader, title, subtitle, secondSubtitle) => {
    if(secondSubtitle && secondSubtitle.text && secondSubtitle.width && secondSubtitle.x){
        slide.addText(secondSubtitle.text, {
            x: secondSubtitle.x,
            y: 0.3,
            w: secondSubtitle.width,
            h: 0.8,
            fill: '9DC3E6',
            align: 'center',
            fontFace: 'Roboto',
            fontSize: 14,
            color: 'FFFFFF',
            bold: true,
            shape: pptx.ShapeType.roundRect,
            rectRadius: 0.4
        })
    }

    // Texte en bleue ciel
    if(subtitle && subtitle.text && subtitle.width && subtitle.x){
        slide.addText(subtitle.text, {
            x: subtitle.x,
            y: 0.3,
            w: subtitle.width,
            h: 0.8,
            fill: '4472C4',
            align: subtitle.align || 'center',
            margin: subtitle.margin || [],
            fontFace: 'Roboto',
            fontSize: 14,
            color: 'FFFFFF',
            bold: subtitle?.bold === false ? false : true,
            shape: pptx.ShapeType.roundRect,
            rectRadius: 0.4
        })
    }
    // Fin

    // Texte en bleu marine
    if(title && title.text && title.width){
        slide.addText(title.text, {
            x: 0.6,
            y: 0.3,
            w: title.width,
            h: 0.8,
            fill: '28366D',
            align: title.align || 'center',
            fontFace: 'Roboto',
            fontSize: 14,
            color: 'FFFFFF',
            bold: true,
            shape: pptx.ShapeType.roundRect,
            rectRadius: 0.4
        })
    }
    // Fin texte en Bleu marine

    slide.addImage({
        path: titleImage,
        x: 0.35,
        y: 0.3,
        w: 1.2,
        h: 0.85
    })

    const image = type === 'SIMULATION' ? Calculatrice : Info;

    const calculatriceX = 0.62;
    const calculatriceY = 0.5;
    const calculatriceWidth = 0.3;
    const calculatriceHeight = 0.4;

    const infoX = 0.72;
    const infoY = 0.55;
    const infoWidth = 0.1;
    const infoHeight = 0.3;

    slide.addImage({
        path: image,
        x: type === 'SIMULATION' ? calculatriceX : infoX,
        y: type === 'SIMULATION' ? calculatriceY : infoY,
        w: type === 'SIMULATION' ? calculatriceWidth : infoWidth,
        h: type === 'SIMULATION' ? calculatriceHeight : infoHeight,
    })
}
