import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    actuelle: {
        //width: "49%",
        marginRight: "2%",
    },
    apres: {
        //width: "49%",
    },
    title: {
        color: "rgb(102,102,102)",
        textAlign: 'left',
        fontWeight: 'bold'
    },
    line: {
        background: "linear-gradient(285deg,#EDC413,#F67A0B)",
        height: "3px"
    },
    line2: {
        background: "linear-gradient(285deg,#A4EB78,#7FC255)",
        height: "3px"
    },
    actuelleContent: {
        display: 'flex',
        flexDirection: 'column'
    },
    actuelleContent1: {
        display: 'flex',
        justifyContent: 'space-between',
        textAlign: 'center',
        marginTop: 8,
    },
    actuelleContent2: {
        display: 'flex',
        justifyContent: 'space-between',
        //marginTop: 42
    },



    apresContent: {
        display: 'flex',
        flexDirection: 'column'
    },
    apresContent1: {
        //marginTop: 8,
    },
    apresContent2: {
        display: 'flex',
        justifyContent: 'space-between',
        //marginTop: 13
    },

});

export default useStyles;