import React, { useContext, useEffect } from 'react';
import { numberFormat } from '../../../../../extra/all_ppt_render';
import { DataControlContext } from '../../../../../store/context/dataControl';
import './style.scss';
import { activePageBlock } from '../../../../../store/actions/simulateur';
import { SimulateurContext } from '../../../../../store/context/simulateur';

export const valuePeriodiciteEcheance = (index) => {
    switch(index){
        case 0 :
            return 'Mensuelle';
        case 1 :
            return 'Trimestrielle';
        case 2 :
            return 'Semestrielle';
        default :
            return 'Annuelle';
    }
}

const Bloc31 = (props) => {
    const  { data, withoutCalcul } = props;

    const { simulateurDispatch } = useContext(SimulateurContext);
    const { empruntState, setEmpruntState } = useContext(DataControlContext);
    const { avec_assurance_amortiss, periode_echeance } = empruntState;

    const selectData = [
        {
            value: 0,
            label: 'Non'
        },
        {
            value: 1,
            label: 'Oui'
        }
    ]

    const handleChange = (event) => {
        const { value } = event.target;
        const emp = { ...empruntState };

        emp.avec_assurance_amortiss = value;

        simulateurDispatch(activePageBlock());
        setEmpruntState(emp);
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }} >
            <div style={{ fontWeight: 'bold', color: '#7a86a1', borderBottom: '2px solid #e7edf3', padding: "18px 15px 15px 15px" }} >Montant échéance</div>
            <div style={{ height: 200, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', padding: 15 }} >

                <div style={{ display: 'flex', }} >
                    <div style={{ width: '45%', color: '#7a86a1', fontWeight: 'bold' }} >Périodicité</div>
                    <div style={{ background: '#e0ebf3', color: 'rgb(23,157,199)', textAlign: 'center', borderRadius: 15, }} >
                        { valuePeriodiciteEcheance(periode_echeance) }
                    </div>
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', height: '40px', }} >
                    <div style={{ color: '#7a86a1',  }}>Montant</div>
                    <div style={{ color: 'rgb(23,157,199)', fontWeight: 'bold', fontSize: 20 }} >
                        {
                            data &&
                            data.montant_echeance_amortiss ? numberFormat(data.montant_echeance_amortiss) : 0
                        } €
                    </div>
                </div>

                <div style={{ display: 'flex', alignItems: 'center', marginBottom: 6 }} >
                    <div style={{ color: '#7a86a1', width: '45%' }} >Avec assurance</div>
                    <div>
                        <select disabled={withoutCalcul} className='formSelect' onChange={(event) => handleChange(event)} >
                            {
                                selectData.map((data, index) => {
                                    return (
                                        <option key={`SelectData${index}`} value={data.value} selected={avec_assurance_amortiss === data.value}  >{data.label}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                </div>

            </div>
        </div>
    )
};

export default Bloc31;
