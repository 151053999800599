import React, { useContext } from "react";
import NumberFormat from 'react-number-format';
import { CInput } from '@coreui/react';
import { SimulateurContext } from "../../store/context/simulateur";
import { activePageBlock, updateComportementSortieSimulateurData } from "../../store/actions/simulateur";
import PostitPin from "../Postit/PostitPin";

const InputNumber = (props) => {
    const { simulateurState, simulateurDispatch } = useContext(SimulateurContext);

    const handleChange = (event) => {
        // On met à jour l'état de changement de FORMULAIRE
        simulateurDispatch(activePageBlock())

        // Changement de valeur contenue dans le FORMULAIRE
        props.onChange(event);
    }

    return (
        <div className={props.className ? props.className : "col-lg-2 mr-2"} style={{ position: 'relative', padding: 0, marginRight: props.marginRight ? props.marginRight : 0, ...props.style}}>
          <NumberFormat
              id={props.id}
              customInput={CInput}
              style={{ width: "100%", textAlign: 'right', fontSize: 13, ...props.customStyle }}
              allowNegative={false}
              isAllowed={(values) => {
                  const { value } = values;
                  const max = props.max || 99;
                  return props.min ? value > props.min && value < max : (!value || value < max);
              }}
              onKeyDown={event => {
                  if (event.keyCode === 13) {
                      const form = event.target.form;
                      let index = Array.prototype.indexOf.call(form, event.target);
                      if (form.elements[index + 1])
                          form.elements[index + 1].focus();
                      event.preventDefault();
                  }
              }}
              disabled={props.disabled}
              invalid={props.invalid}
              value={props.value}
              onChange={handleChange}
              onBlur={props.onBlur}
              placeholder={props.placeholder ? props.placeholder : ""}
              thousandSeparator=" "
          />
          {
            !props?.hidePin && (
              <PostitPin
                // pinTop="-43px"
                // pinRight="-6px"
                // pinTopHover="-49px"
                // pinRightHover="-11px"
                // pinSelectedTopHover="-45px"
                // pinSelectedRightHover="-11px"
                isPostitShow={simulateurState?.postItData?.displayBlocPostIt}
                pinId={props.id} />
            )

          }
        </div>
    )
}

export default InputNumber
