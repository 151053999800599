import React, { useContext, useEffect } from "react";
import NumberFormat from 'react-number-format';
import { CInput } from '@coreui/react';
import { addToast, deleteToast } from "../../store/actions/utils/toast";
import { UtilsContext } from "../../store/context/utils";
import { SimulateurContext } from "../../store/context/simulateur";
import { activePageBlock } from "../../store/actions/simulateur";
import PostitPin from "../Postit/PostitPin";

export const isCompleteDate = (date) => {
    if (![null, undefined, "", "Invalid date"].includes(date)) {
        const dateToSplit = date.split('/') || [];
        let jour = dateToSplit[0];
        let mois = dateToSplit[1];
        let annee = dateToSplit[2];

        jour = jour.replaceAll(" ", "");
        mois = mois.replaceAll(" ", "");
        annee = annee.replaceAll(" ", "");

        return jour.length === 2 && mois.length === 2 && annee.length === 4;
    }
    else {
        return false;
    }

}

export const invalidDate = (date) => {
    let isInvalidDate = false;

    // Les mois de 30 jours
    const mois30Jours = [4, 6, 9, 11];

    if (!["", "Invalid date", undefined, null].includes(date)) {
        const d = date.split("/");
        const jourString = d[0].replaceAll(" ", "");
        const moisString = d[1].replaceAll(" ", "");
        const anneeString = d[2].replaceAll(" ", "");

        const jour = parseInt(d[0], 10);
        const mois = parseInt(d[1], 10);
        const annee = parseInt(d[2], 10);

        // Les conditions des validités des JOUR, MOIS et ANNEÉ
        const conditionValiditeJour = jourString.length === 2 && !isNaN(jour);
        const conditionValiditeMois = moisString.length === 2 && !isNaN(mois);
        const conditionValiditeAnnee = anneeString.length === 4 && !isNaN(annee);

        // Les données erronées
        const jourErrone = jour === 0 || jour > 31;
        const moisErrone = mois === 0 || mois > 12;
        const anneeErronee = annee === 0 || annee <= 1900 || annee >= 3000;

        // Si la saisie du jour est complète
        if (conditionValiditeJour) {
            isInvalidDate = jourErrone;
        }

        // Si la saisie du mois est complète
        if (conditionValiditeMois) {
            isInvalidDate = moisErrone;
        }

        // Si la saisie de l'année est complète
        if (conditionValiditeAnnee) {
            isInvalidDate = anneeErronee;
        }

        // Condition sur les jours et condition sur les mois
        if (conditionValiditeJour && conditionValiditeMois) {
            // Les mois de 30 jours ne peuvent pas prendre le 31 comme JOUR
            if (mois30Jours.includes(mois)) {
                isInvalidDate = jour >= 31;
            }

            // Cas exceptionnel => Mois de Février
            if(mois === 2){
                isInvalidDate = jour >= 30;
            }

            isInvalidDate = isInvalidDate || jourErrone || moisErrone;
        }

        // Cas exceptionnel => Mois de Février et année bissextile
        if(conditionValiditeJour && conditionValiditeMois && conditionValiditeAnnee){
            if(mois === 2){
                let condition = (annee % 4) === 0;

                isInvalidDate = jour >= (condition ? 30 : 29);
            }

            isInvalidDate = isInvalidDate || jourErrone || moisErrone || anneeErronee;
        }
    }

    return isInvalidDate;
}

const InputDate = (props) => {
    const { simulateurState, simulateurDispatch } = useContext(SimulateurContext);

    const isInvalid = props.disabled ? false : invalidDate(props.value);
    const isComplete = props.disabledDateCompleteCheck === true ? true : isCompleteDate(props.value);
    const { utilsState, utilsDispatch } = useContext(UtilsContext);

    // Changement du FORMULAIRE
    const handleChange = (event) => {
        // On met à jour l'état de changement de FORMULAIRE
        simulateurDispatch(activePageBlock())

        // Mise à jour de DATA
        props.onChange(event)
    }

    // Affichage ou NON de message d'erreur selon la date saisie
    const displayOrHideErrorMessage = (condition, message) => {
        const content = utilsState?.toasts?.find((element) => element.description === message);

        if (condition) {
            if(!content?.id){
                utilsDispatch(addToast({ type: 'danger', title: 'ERREUR', description: message, autoDelete: false }))
            }
        }
        else {
            if (content?.id) {
                utilsDispatch(deleteToast(content.id))
            }
        }
    }

    useEffect(() => {
        const message = "Erreur sur la date, veuillez corriger la saisie."
        displayOrHideErrorMessage(isInvalid, message);
    }, [isInvalid])

    return (
      <div className={props.className ? props.className : "col-lg-12"} style={{ position: 'relative', padding: 0, marginRight: props.marginRight ? props.marginRight : 0, ...props.style}}>
        <NumberFormat
            id={props.id}
            customInput={CInput}
            style={{ width: "100%", paddingRight: 16, fontSize: 13, textAlign: 'right', ...props.customStyle }}
            format="##/##/####"
            placeholder={props.placeholder ? props.placeholder : "jj/mm/aaaa"}
            onKeyPress={props.onKeyPress}
            onKeyDown={event => {
                if (event.keyCode === 13) {
                    if (props.onKeyPress)
                        props.onKeyPress()
                    const form = event.target.form;
                    let index = Array.prototype.indexOf.call(form, event.target);
                    if (form.elements[index + 1])
                        form.elements[index + 1].focus();
                    event.preventDefault();
                }
            }}
            onBlur={props.onBlur}
            onFocus={props.onFocus}
            disabled={props.disabled}
            invalid={(!isComplete && !props.disabled) || isInvalid || props.invalid}
            value={props.value}
            onChange={handleChange}
        />
        {
            !props?.hidePin && (
              <PostitPin
                // pinTop="-43px"
                // pinRight="-6px"
                // pinTopHover="-49px"
                // pinRightHover="-11px"
                // pinSelectedTopHover="-45px"
                // pinSelectedRightHover="-11px"
                isPostitShow={simulateurState?.postItData?.displayBlocPostIt}
                pinId={props.id} />
            )

          }
      </div>
    )
}

export default InputDate;
