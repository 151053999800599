export const donation = ["32 32", `
<g id="svg_1" transform="translate(0, 200) scale(0.1, -0.1)">
   <path id="svg_2" d="m64.36246,1991.58911c-8.81701,-4.7876 -13.59912,-12.66357 -14.34635,-23.16516c-0.89663,-11.73682 1.49441,-18.6864 8.66759,-25.32703c16.28909,-15.13416 40.64795,-8.49365 47.6717,12.81812c0.89663,2.93408 1.64384,6.64038 1.64384,8.49365c0,10.96484 -8.21925,24.24597 -17.4846,28.10681c-7.02373,3.08875 -19.57677,2.62537 -26.15218,-0.92639z"/>
   <path id="svg_3" d="m233.38026,1991.12573c-9.56422,-5.25085 -15.39244,-17.14221 -14.1969,-28.72461c1.79329,-19.92188 21.22063,-32.73962 38.85466,-25.79028c23.31281,9.26599 25.70386,43.08667 3.88547,55.13245c-7.47208,4.16968 -20.17451,3.86096 -28.54323,-0.61755z"/>
   <path id="svg_4" d="m141.17516,1969.65942c-2.83939,-2.93433 -2.09216,-6.79492 2.09218,-11.89136l3.88547,-4.78735l16.13965,0c15.69125,0 16.28903,0.15442 19.27788,4.01538c4.48323,5.86841 5.23041,9.88354 2.54047,12.66333c-2.24161,2.31653 -3.28769,2.16187 -12.40359,-1.08093l-10.162,-3.552l-7.92035,3.24316c-9.11591,3.7063 -10.90921,4.01526 -13.44969,1.38977z"/>
   <path id="svg_5" d="m143.41678,1939.08179c-2.8394,-2.47095 -7.62151,-9.57483 -10.75977,-15.75208c-4.63268,-9.11157 -5.82819,-13.28125 -6.42596,-22.2384c-0.59777,-10.34692 -0.44834,-11.42786 2.98881,-14.97986c4.93156,-4.94202 13.74858,-6.17737 41.24574,-5.40515c23.91057,0.46313 28.24434,2.31641 30.78484,12.50903c2.68994,10.96472 -5.67876,32.58533 -17.48462,45.55762l-4.63266,4.94189l-15.39241,0c-14.79466,0 -15.39241,-0.15442 -20.32397,-4.63306z"/>
   <path id="svg_6" d="m54.20049,1928.11707c-5.52931,-2.31641 -11.65639,-8.95715 -13.30026,-14.36218c-2.09217,-6.33179 -4.63266,-70.26697 -4.63266,-112.89038c0,-18.68628 -1.0461,-50.19055 -2.24163,-70.11243c-1.34495,-19.92175 -1.9427,-37.52698 -1.34495,-39.07141c1.04609,-3.70642 6.42595,-5.86853 14.04745,-5.86853c10.75975,0 10.46087,-1.08093 13.74857,64.7074l1.7933,39.22595l3.43713,-12.97253c3.28772,-12.66345 3.43715,-13.8988 2.09218,-49.1095c-0.89663,-23.16479 -0.89663,-37.37268 0,-39.07141c1.94272,-3.86084 19.42731,-4.01514 22.41616,-0.30884c1.34496,1.85327 2.54049,13.89893 4.0349,41.54236l2.09219,39.07141l-4.6327,25.3269c-3.88545,21.46631 -4.63265,28.87903 -4.48323,48.49194c0.14945,29.65112 0.44834,30.73206 8.06984,20.53955c9.41477,-12.81799 8.81701,-12.66345 36.91191,-10.65588c27.34773,2.16211 29.58932,3.08875 29.58932,12.35461c0,10.34692 -1.64383,11.11914 -24.50832,12.35474c-11.20807,0.61755 -20.62287,1.38989 -20.92175,1.69873c-10.16199,14.05334 -37.95802,46.79309 -41.09628,48.4917c-5.08099,2.62549 -15.39242,2.9342 -21.07117,0.6178z"/>
   <path id="svg_7" d="m250.71541,1926.88171c-2.5405,-1.54443 -12.25414,-12.50928 -21.66893,-24.24597c-9.26534,-11.73694 -17.4846,-22.08398 -18.08235,-22.85608c-0.59778,-0.77209 -10.31145,-1.85327 -21.66896,-2.31641c-17.63405,-0.92688 -20.62286,-1.39001 -22.41617,-4.01538c-2.98883,-4.16968 -2.68994,-13.74438 0.59778,-16.52429c3.2877,-3.08875 49.46497,-6.94946 53.9482,-4.47864c1.4944,0.92676 6.27655,5.86853 10.31142,10.96484l7.62152,9.26599l0.44832,-22.85608c0.59776,-20.38513 0.14946,-25.63574 -4.3338,-52.19812l-4.93155,-29.34241l2.09215,-39.07153c1.34499,-25.01794 2.8394,-39.68909 3.88548,-41.0791c2.54048,-3.0885 19.87566,-3.24292 22.26674,-0.1543c1.34494,1.54431 1.34494,11.4281 0.4483,38.14478c-1.34494,34.12988 -1.19553,36.90955 1.7933,49.26404c1.64383,7.25842 3.28772,11.73694 3.43713,10.03821c0.14938,-1.69873 1.34497,-23.16492 2.68991,-47.87402c1.34503,-24.70935 2.68991,-46.021 3.13828,-47.41077c1.04608,-3.552 6.57544,-4.94202 14.34634,-3.86096c11.20807,1.54431 10.90918,0 8.21927,49.26404c-1.345,24.09155 -2.83939,69.49475 -3.28769,100.8446c-1.19559,78.91504 -1.49445,81.0769 -14.1969,90.03418c-6.27655,4.32385 -18.23184,4.63318 -24.65779,0.46338z"/>
  </g>
`]
