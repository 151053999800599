import React, { useContext, useEffect, useRef, useState } from 'react';
import ElementCalculatrice from './ElementCalculatrice/ElementCalculatrice';

import imgPow from "../../assets/icons/calculators/pow.png";
import imgBackspace from "../../assets/icons/calculators/backspace.png";
import imgDivision from "../../assets/icons/calculators/division.png";
import imgPlus from "../../assets/icons/calculators/plus.png";
import imgMinus from "../../assets/icons/calculators/minus.png";
import imgModulo from "../../assets/icons/calculators/modulo.png";
import imgMultiplication from "../../assets/icons/calculators/multiplication.png";
import imgSquareroot from "../../assets/icons/calculators/squareroot.png";
import imgPlusoumoins from "../../assets/icons/calculators/plusoumoins.png";
import './ElementCalculatrice/style.scss';
import { UtilsContext } from '../../store/context/utils';
import { addToast } from '../../store/actions/utils/toast';
import { SimulateurContext } from '../../store/context/simulateur';

const Calculator = () => {
    const [hypotheses, setHypotheses] = useState([]);
    const [resultat, setResultat] = useState('');
    const { utilsDispatch } = useContext(UtilsContext);
    const calculRef = useRef(null);
    const enableKeyDown = useRef(false);
    const hasEqual = useRef(false);
    const { simulateurState } = useContext(SimulateurContext);

    const texteErreur = "Erreur";

    const elements = [
      {
        key: '(',
        value: <span className='calculator-item-span-container center-first-column'>(</span>
      },
      {
        key: ')',
        value: <span className='calculator-item-span-container center-second-column'>)</span>
      },
      {
        key: 'puissance',
        value: <div className='calculator-item-img-container'><img className='calculator-item-img' alt='pow' src={imgPow} /></div>
      },
      {
        key: 'sqrt',
        value: <div className='calculator-item-img-container'><img className='calculator-item-img' alt='square root' src={imgSquareroot} /></div>,
      },
      {
        key: '%',
        value: <div className='calculator-item-img-container'><img className='calculator-item-img' alt='Modulo' src={imgModulo} /></div>,
      },
      {
        key: '7',
        value: <span className='calculator-item-span-container'>7</span>,
      },
      {
        key: '8',
        value: <span className='calculator-item-span-container'>8</span>,
      },
      {
        key: '9',
        value: <span className='calculator-item-span-container'>9</span>,
      },
      {
        key: 'suppr',
        value: <div className='calculator-item-img-container bg-orange'><img style={{ width: '36%' }} className='calculator-item-img' alt='Suppr' src={imgBackspace} /></div>,
      },
      {
        key: 'AC',
        value: <div className='bg-orange'><span className='calculator-item-span-container'>AC</span></div>,
      },
      {
        key: '4',
        value: <span className='calculator-item-span-container'>4</span>,
      },
      {
        key: '5',
        value: <span className='calculator-item-span-container'>5</span>,
      },
      {
        key: '6',
        value: <span className='calculator-item-span-container'>6</span>,
      },
      {
        key: '*',
        value: <div className='calculator-item-img-container'><img style={{ width: '24%' }} className='calculator-item-img' alt='Multiplication' src={imgMultiplication} /></div>,
      },
      {
        key: '/',
        value: <div className='calculator-item-img-container'><img className='calculator-item-img' alt='Division' src={imgDivision} /></div>,
      },
      {
        key: '1',
        value: <span className='calculator-item-span-container'>1</span>,
      },
      {
        key: '2',
        value: <span className='calculator-item-span-container'>2</span>,
      },
      {
        key: '3',
        value: <span className='calculator-item-span-container'>3</span>,
      },
      {
        key: '+',
        value: <div className='calculator-item-img-container'><img className='calculator-item-img' alt='Plus' src={imgPlus} /></div>,
      },
      {
        key: '-',
        value: <div className='calculator-item-img-container'><img className='calculator-item-img' alt='Minus' src={imgMinus} /></div>,
      },
      {
        key: '0',
        value: <span className='calculator-item-span-container'>0</span>,
      },
      {
        key: '.',
        value: <span className='calculator-item-span-container'>.</span>,
      },
      {
        key: 'plusoumoin',
        value: <div className='calculator-item-img-container'><img style={{ width: '36%' }} className='calculator-item-img' alt='Plus ou moins' src={imgPlusoumoins} /></div>,
      },
      {
        key: '=',
        value: "=",
      },
    ]

    const nFormatter = (number) => {
      // Check if the number is greater than or equal to 1,000,000,000
      if (Math.abs(number) >= 1e9) {
        // Convert the number to scientific notation
        const formattedNumber = number.toExponential();

        // Split the scientific notation into its parts
        const [coefficient, exponent] = formattedNumber.split('e');

        // Format the coefficient part with commas for thousands separation
        const formattedCoefficient = parseFloat(coefficient).toLocaleString();

        // Combine the formatted coefficient and exponent
        return `${formattedCoefficient}e${exponent}`;
      } else {
        // Use default formatting for numbers less than 1,000,000,000
        return number;
      }
    }

    const calculResultat = (elements) => {
      let value = 0;

      if (elements[0] === "*") {
        elements.unshift("0");
      }
      elements = elements.map((el) => {
        if (Number.isInteger(el)) {
          el = customRound(el, 10);
        }
        if (String(el).match('x')) {
          el = String(el).replace('x', '*');
        }
        return el;
      })

      const balancedArray = [];

      let openParenthesesCount = 0;

      for (const item of elements) {
        if (item === "(") {
          openParenthesesCount++;
        } else if (item === ")") {
          if (openParenthesesCount > 0) {
            openParenthesesCount--;
          } else {
            // If there's no matching "(", you can add an opening "(" and a closing ")" here.
            // balancedArray.push("(", ")");
          }
        }
        balancedArray.push(item);
      }

      // Add any missing closing parentheses ")"
      for (let i = 0; i < openParenthesesCount; i++) {
        balancedArray.push(")");
      }

      if (openParenthesesCount > 0) {
        setHypotheses(balancedArray.toString().replace('*', 'x').split(','));
      }


      try{

        let finalForEval = balancedArray.join('');

        if(balancedArray.join('').match('√')) {
          const calcul = balancedArray.join('').match(/(\d+(\.\d+)?)?\s?√\((\d+(\.\d+)?)\)/g);
          const calculSqrt = [];
          if (calcul.length > 0) {
            for (let index = 0; index < calcul.length; index++) {
              const calculN = calcul[index].match(/(\d+(\.\d+)?)?\s?√\((\d+(\.\d+)?)\)/);
              if (calculN[1] === undefined) {
                calculSqrt.push({
                  index: calcul[index],
                  value: Math.sqrt(calculN[3])
                })
              } else {
                calculSqrt.push({
                  index: calcul[index],
                  value: calculN[1] * Math.sqrt(calculN[3])
                })
              }
            }

            if (calculSqrt.length > 0) {
              for (let index = 0; index < calculSqrt.length; index++) {
                const element = calculSqrt[index];
                finalForEval = finalForEval.replace(element.index, element.value);
              }
            }
          }

        }

        if (balancedArray.some(x => String(x).match(/(\d+(\.\d+)?)%/))) {
          const lastNumber = String(balancedArray[balancedArray.length - 1]).replace(/(\d+(\.\d+)?)%/, "$1");
          const beforeNumber = [...balancedArray.slice(0, -1)];

          if (beforeNumber.length > 0 && beforeNumber[0] !== "") {
            const firtNumber = beforeNumber.join('').replace(/[+\-/*]$/, "");

            // eslint-disable-next-line no-eval
            const valueEval = eval(firtNumber);
            let percent = valueEval * lastNumber / 100;

            if (String(beforeNumber).match(/[*/]/)) {
              percent = lastNumber / 100;
            }

            // eslint-disable-next-line no-eval
            finalForEval = `${beforeNumber} ${percent}`;
            console.log(finalForEval);
          } else {
            finalForEval = Number(lastNumber) / 100;
          }

        }

        if (balancedArray.join('').match(/(\d+(\.\d+)?)\^(\d+(\.\d+)?)/g)) {
          const calcul = balancedArray.join('').match(/(\d+(\.\d+)?)\^(\d+(\.\d+)?)/g);
          const calculPow = [];
          if (calcul.length > 0) {
            for (let index = 0; index < calcul.length; index++) {
              const calculN = calcul[index].match(/(\d+\.?\d*)\^(\d+\.?\d*)/);
              calculPow.push({
                index: calcul[index],
                value: Math.pow(calculN[1], calculN[2])
              })
            }
          }

          if (calculPow.length > 0) {
            for (let index = 0; index < calculPow.length; index++) {
              const element = calculPow[index];
              finalForEval = finalForEval.replace(element.index, element.value);
            }
          }
        }

        // eslint-disable-next-line no-eval
        const valueEvaluation = eval(finalForEval);
        value = parseFloat(valueEvaluation);

        if(Number.isNaN(value)) {
          value = customRound(texteErreur, 10);
        }
      }
      catch(exception){
          console.log(exception);
          value = texteErreur;
      }
      // setHypotheses([]);
      return value > 1e9 ? nFormatter(value, 1) : value;
    }

    const customRound = (number, decimalPlaces) => {
      const factor = Math.pow(10, decimalPlaces);
      return Math.round(number * factor) / factor;
    }

    const extractLastNumbers = (arr, isSqrt) => {
      let lastNumberReg = new RegExp(/\(?[-+]?\d+(\.\d+)?\)?$/);
      if (isSqrt) {
        lastNumberReg = new RegExp(/\(?\d+(\.\d+)?\)?$/)
      }

      const lastNumber = arr.join("").match(lastNumberReg) ? arr.join("").match(lastNumberReg)[0] : '';
      const beforeNumber = arr.join("").replace(lastNumberReg, "");

      return { beforeNumber, lastNumber };
    }

    // const handleClose = () => {
    //   simulateurDispatch(displayOrHideCalculatrice({
    //     showCalculatrice: false
    //   }))
    // }

    const handleElementClick = (element, index) => {

      let hyps = JSON.parse(JSON.stringify(hypotheses));

      if (element.key === '*') {
        element.key = 'x';
      }

      if(['+', '*', '/', "x", "-"].includes(element.key)) {
        hasEqual.current = false;
      }

      if (hyps.length === 0 && ['+', '*', '/', "x"].includes(element.key) && [0, texteErreur].includes(resultat)) {
        return;
      }

      if (hyps.length === 1 && hyps[0] === "-" && ['+', '*', '/', "x", '-', '%', '=', 'puissance', 'sqrt'].includes(element.key) && [0, texteErreur].includes(resultat)) {
        return;
      }

      if (hyps.length > 0 &&
        ['+', '*', '/', "x", "-"].some(x => hyps.join("").includes(x)) &&
        ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(element.key) &&
        !['', texteErreur].includes(resultat) && hasEqual.current) {
          hyps = [];
          setHypotheses([]);
          setResultat('');
          hasEqual.current = false;
      }

      if (hyps.length > 0 &&
        hyps.join('').match('√()') &&
        ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.'].includes(element.key) &&
        resultat === '') {
        if(hyps.toString().match(/\)$/)) {
          hyps = hyps.toString().replace(/\)$/, '').split(',');
          hyps.push(element.key + ')');
          setHypotheses(hyps);
          return;
        }
      }

      if (element.key === "AC") {
        // Réinitialisation des éléments
        setHypotheses([])
        setResultat("")
      } else if (element.key === "suppr") {
        // Suppression à partir des derniers éléments du tableau des hypothèses
        hyps.pop();

        setHypotheses(hyps);
      } else if (element.key === "=") {
        if (hyps.length === 0 && resultat === '') {
          return;
        }
        setResultat(calculResultat(hyps));
        // setHypotheses([]);
        hasEqual.current = true;
      } else if (element.key === "plusoumoin") {
        if (hyps.length === 0) return;
        const result = calculResultat(hyps);
        if (!['', texteErreur].includes(resultat)) {
          let plusorminus = result < 0;
          setHypotheses([`${plusorminus ? '+' : '-'}${Math.abs(customRound(result, 3))}`]);
        } else {
          const {lastNumber, beforeNumber } = extractLastNumbers(hyps);
          hyps = [beforeNumber, `${Number(lastNumber) < 0 ? '+' : '-'}${Math.abs(customRound(lastNumber, 3))}`];
          setHypotheses([beforeNumber, `${Number(lastNumber) < 0 ? '+' : '-'}${Math.abs(customRound(lastNumber, 3))}`]);
        }
        setResultat('');

      } else if (element.key === "puissance") {
        if (!['', texteErreur].includes(resultat)) {
          setHypotheses([`${customRound(resultat, 3)}^`]);
          // setResultat(customRound(Math.pow(resultat, 2), 3));
        } else {
          if (hyps.some(h => ['+', '-', '*', '/', "x"].includes(h))) {
            const {lastNumber, beforeNumber } = extractLastNumbers(hyps);
            hyps = [beforeNumber, `${lastNumber}^`];
            setHypotheses([beforeNumber, `${lastNumber}^`]);
          } else {
            const result = calculResultat(hyps);
            if (!isNaN(result)) {
              setHypotheses([`${result}^`]);
              // setResultat(Math.pow(result, 2));
            }
          }
        }

      } else if (element.key === "sqrt") {

        if (hyps.some(h => ['+', '-', '*', '/', "x"].includes(h)) && ['', texteErreur].includes(resultat)) {
          const {lastNumber, beforeNumber } = extractLastNumbers(hyps, true);
          hyps = [beforeNumber, `${lastNumber}√()`];
          setHypotheses([beforeNumber, `${lastNumber}√()`]);
          // setResultat(calculResultat([beforeNumber, Math.sqrt(parseFloat(lastNumber))]));
        } else {
          if (!['', texteErreur].includes(resultat)) {
            setHypotheses([`√(${customRound(resultat, 3)})`]);
            setResultat(customRound(Math.sqrt(resultat), 10));
          } else {
            hyps.push(`√()`)
            setHypotheses(hyps);

          }
        }

      } else if(element.key === "%") {
        if (hyps.some(h => ['+', '-', '*', '/', "x"].includes(h))) {
          const {lastNumber, beforeNumber } = extractLastNumbers(hyps, true);
          hyps = [beforeNumber, `${lastNumber}%`];
          setHypotheses([beforeNumber, `${lastNumber}%`]);
          // setResultat(calculResultat([beforeNumber, Math.sqrt(parseFloat(lastNumber))]));
        } else {
          const result = calculResultat(hyps);
          if (!isNaN(result)) {
            setHypotheses([`${result}%`]);
            setResultat(result / 100);
          }
        }
      } else {
        if(['+', '-', '*', '/', 'x'].includes(element.key) && !['', texteErreur].includes(resultat)){
          const result = calculResultat(hyps);
          setHypotheses([]);
          setResultat('')
          hyps = [];
          hyps.push(customRound(result, 3));
        }

        // if (['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(element.key) && ![0, texteErreur].includes(resultat)) {
        //   // setResultat(0);
        // }

        hyps.push(element.key);

        setHypotheses(hyps);
      }
    }

    useEffect(() => {
      if(!simulateurState?.enableCalculatriceKeyboard) {
        enableKeyDown.current = false;
      } else {
        enableKeyDown.current = true;
      }

      if (enableKeyDown.current) {
        const handleKeyDown = (event) => {
          // Handle the keydown event here
          event.preventDefault();

          let hyps = JSON.parse(JSON.stringify(hypotheses));

          const acceptedKeys= ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '(', ')', 'Backspace', 'Delete', '/', '*', '+', '-', '.', 'Enter'];
          if (acceptedKeys.includes(event.key)) {
            let key = event.key;
            if (key === '*') {
             key = 'x';
            }

            if(['+', '*', '/', "x", "-"].includes(key)) {
              hasEqual.current = false;
            }

            if (hyps.length === 0 && ['+', '*', '/', "x"].includes(key) && ['', texteErreur].includes(resultat)) {
              return;
            }

            if (hyps.length === 1 && hyps[0] === "-" && ['+', '*', '/', "x", '-', '%', '=', 'puissance', 'sqrt'].includes(key) && ['', texteErreur].includes(resultat)) {
              return;
            }

            if (hyps.length > 0 &&
              ['+', '*', '/', "x", "-"].some(x => hyps.join("").includes(x)) &&
              ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(key) &&
              !['', texteErreur].includes(resultat) && hasEqual.current) {
                hyps = [];
                setHypotheses([]);
                setResultat('');
                hasEqual.current = false;
            }

            if (hyps.length === 0 && ['+', '*', '/', "x"].includes(key)) {
              return;
            }

            if (hyps.length === 1 && hyps[0] === "-" && ['+', '*', '/', "x", '-', '%', 'Enter'].includes(key)) {
              return;
            }

            if (key === "Enter") {
              if (hyps.length === 0 && resultat === '') {
                return;
              }
              hasEqual.current = true;
              setResultat(calculResultat(hyps));
            } else if(key === "Backspace") {
              // Suppression à partir des derniers éléments du tableau des hypothèses
              hyps.pop();

              setHypotheses(hyps);
            } else if(key === "Delete") {
              setHypotheses([]);
              setResultat('');
            } else {
              // if (['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(key) && ![0, texteErreur].includes(resultat) && !hypotheses.some(x => ['+', '-', '*', '/'].includes(x))) {
              //   setResultat(0);
              // }

              if(['+', '-', '*', '/', 'x'].includes(key) && !['', texteErreur].includes(resultat)){
                const result = calculResultat(hyps);
                setHypotheses([]);
                setResultat('')
                hyps = [];
                hyps.push(customRound(result, 3));
              }

              // if(['+', '-', '*', '/', 'x'].includes(key) && !['', texteErreur].includes(resultat)) {
              //   setHypotheses([resultat, key]);
              // } else {
              //   setHypotheses([...hypotheses, key]);
              // }
              hyps.push(key);

              setHypotheses(hyps);
            }
          }
        };

        // Attach event listener when the component mounts
        document.addEventListener('keydown', handleKeyDown);

        // Remove event listener when the component unmounts
        return () => {
          document.removeEventListener('keydown', handleKeyDown);
        };
      }


    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hypotheses, resultat, simulateurState?.enableCalculatriceKeyboard]);

    const handleCopyClick = (e) => {
      const textToCopyValue = e.target.textContent; // Get the text content

      // Use the Clipboard API to copy the text to the clipboard
      navigator.clipboard.writeText(textToCopyValue)
        .then(() => {
          // Success message or any other action after copying
          // utilsDispatch(addToast({ type: 'success', title: 'VALIDATION', description: 'Copier dans le presse-papiers' }))
        })
        .catch((error) => {
          // Handle any errors
          console.error('Unable to copy text: ', error);
        });
    };

    const handlePaste = (e) => {
      e.preventDefault();
      const pastedContent = e.clipboardData.getData('text/plain');
      setHypotheses([Number(String(pastedContent).replace(" ", ""))]);
    };

    // Function to format a number using 'fr-FR' locale
    const formatNumber = (number) => {
      if (typeof number === "string") {
        return number;
      }
      const result = String(new Intl.NumberFormat('fr-FR', { maximumFractionDigits: 10 }).format(number)).replace(',', '.');
      // console.log(result);
      return result
    };

    return (
        <div className='calculator-container'>
            {/** Hypothèses */}
            <div suppressContentEditableWarning={true} contentEditable={true} ref={calculRef} onPaste={handlePaste} className='calculator-edit calculator-edit-calcul'>
                {
                    hypotheses?.length > 0 ?
                        hypotheses?.map((hypothese, index) => {
                            let currentElementIsOperation = ['+', '-', '*', '/', 'x', '^'].includes(hypothese);
                            let nextElementIsOperation = ['+', '-', '*', '/', 'x', '^'].includes(hypotheses?.[index + 1]);
                            let conditionWithSpace = currentElementIsOperation || nextElementIsOperation;

                            return (
                                <span key={`Hypothese${index + 1}`}>
                                    {hypothese}{conditionWithSpace ? <>&nbsp;</> : ''}
                                </span>
                            )
                        })
                    : ""
                }
            </div>

            {/** Partie de résultat */}
            <div onClick={handleCopyClick} style={{ color: resultat !== texteErreur ? '' : '#FFDB94'}} className='calculator-edit calculator-edit-result'>
                {![0, '', texteErreur].includes(resultat) ?  formatNumber(resultat) : resultat}
            </div>

            {/** Les chiffres et signe d'opération */}
            <div className='calculator-element'>
              <div className='calculator-element-container'>
                {
                  elements.map((element, i) => {
                    return (
                      <ElementCalculatrice
                          indexElement={i}
                          key={`ElementCalculatrice${i + 1}`}
                          element={element}
                          onRequestElementClick={() => handleElementClick(element, i)}
                      />
                    )
                  })
                }
              </div>
            </div>
        </div>
    )
}

export default Calculator;

