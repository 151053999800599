import React from 'react';
import { WhiteTableSpace } from '../TableRevenuFoncier/TableRevenuFoncier';

const TableSynthese = () => {
    const leftBackground = 'rgb(132,151,176)';

    const grayBorder = {
        border: '1px solid rgb(173,185,202)'
    }

    const titleStyle = { background: leftBackground, textAlign: 'center', fontWeight: 'bold', color: '#FFFFFF', ...grayBorder }

    return (
        <table cellPadding={10} cellSpacing={10} style={{ textAlign: 'left' }} >
            <tr>
                <td style={{ width: '1%', textAlign: 'center', border: 'none' }} ></td>
                <td style={{ width: '10%', textAlign: 'center' }} ></td>
                <td style={{ width: '20%', fontWeight: 'bold', background: 'rgb(255,242,204)', textAlign: 'center', ...grayBorder }} rowSpan={2} >REVENUS FONCIERS</td>
                <td style={{ textAlign: 'center', background: 'rgb(255,242,204)', fontWeight: 'bold', ...grayBorder }} colSpan={2} >LOCATION MEUBLÉE</td>
                <td style={{ width: '20%', textAlign: 'center', background: 'rgb(255,242,204)', fontWeight: 'bold', ...grayBorder }} rowSpan={2} >IMPÔT SUR LES SOCIÉTÉS</td>
            </tr>
            <tr>
                <td></td>
                <td></td>
                <td style={{ width: '12%', fontWeight: 'bold', background: 'rgb(255,248,229)', textAlign: 'center', ...grayBorder }} >LMNP</td>
                <td style={{ width: '12%', fontWeight: 'bold', background: 'rgb(255,248,229)', textAlign: 'center', ...grayBorder }} >LMP</td>
            </tr>
            <tr>
                <td></td>
                <td style={{ ...titleStyle }} >Recettes</td>
                <td style={{ ...grayBorder }} >Encaissées pendant l’année d’imposition</td>
                <td style={{ ...grayBorder }} colSpan={3} >Encaissables au titre de l’année d’imposition</td>
            </tr>
            <tr>
                <td></td>
                <td style={{ ...titleStyle }} >Charges <br />déductibles</td>
                <td style={{ ...grayBorder }}>Régime micro : 30%.<br />OU<br />Régime réel : charges déductibles restreintes</td>
                <td style={{ ...grayBorder }} colSpan={2}>Régime micro : 50% (71% pour les meublés de tourisme classés)<br />OU<br />Régime réel : charges déductibles extensives, dont un amortissement qui ne peut cependant pas créer de déficit</td>
                <td style={{ ...grayBorder }}>Régime réel : charges déductibles extensives, dont un amortissement déductible sans limite.</td>
            </tr>
            <tr>
                <td>
                    <div style={{ background: 'rgb(237,125,49)', borderRadius: 10, width: '5px', height: '100px', marginBottom: 5 }} ></div>
                </td>
                <td style={{ ...titleStyle }} >Déficit</td>
                <td style={{ ...grayBorder }}>
                    <div>Imputé sur le revenu global jusqu’à 10.700€</div>
                    <div>L’excédent et le déficit lié aux intérêts d’emprunt est reporté sur les revenus fonciers jusqu’à 10 ans</div>
                </td>
                <td style={{ ...grayBorder }}>Imputé sur les BIC de location meublée jusqu’à 10 ans</td>
                <td style={{ ...grayBorder }}>Imputé sur le revenu global sans limite</td>
                <td style={{ ...grayBorder }}>Imputé sur le bénéfice des années suivantes.</td>
            </tr>
            <tr>
                <td>
                    <div style={{ background: 'rgb(101,191,127)', borderRadius: 10, width: '5px', height: '60px' }} ></div>
                </td>
                <td style={{ ...titleStyle }}>Bénéfice</td>
                <td style={{ ...grayBorder }}>
                    <div>IR (jusqu’à 45%)</div>
                    <div>+ PS (17,2%)</div>
                </td>
                <td style={{ ...grayBorder }}>
                    <div>IR (jusqu’à 45%)</div>
                    <div>+ CS si recettes de location saisonnière {'>'} 23.000€, sinon PS (17,2%)</div>
                </td>
                <td style={{ ...grayBorder }}>
                    <div>IR (jusqu’à 45%)</div>
                    <div>+ CS</div>
                </td>
                <td style={{ ...grayBorder }}>
                    <div>IS (15% ou 25%)</div>
                    <div>+ CRL (2,5%) sur recettes locatives brutes</div>
                </td>
            </tr>
            <tr>
                <td>
                    <div style={{ background: 'rgb(68,114,196)', borderRadius: 10, width: '5px', height: '80px', marginBottom: 5 }} ></div>
                </td>
                <td style={{ ...titleStyle }}>Distribution <br />du bénéfice</td>
                <td colSpan={3} style={{ ...grayBorder, textAlign: 'center' }}>Non nécessaire</td>
                <td style={{ ...grayBorder }}>
                    <div>L’associé perçoit les revenus par distribution de dividendes </div>
                    <div>PFU (12,8%) ou IR sur option + PS (17,2%)</div>
                </td>
            </tr>
            <WhiteTableSpace />
            <tr>
                <td></td>
                <td style={{ background: 'rgb(214,220,229)' }} colSpan={5}>IR : impôt sur le revenu  ;  PS : prélèvements sociaux  ;  CS : cotisations sociales</td>
            </tr>
        </table>
    )
}

export default TableSynthese;