import React, { Component } from "react";
import { CSelect } from '@coreui/react';

export default class InputSelectComparaison extends Component {
    render() {
        return(
            <CSelect
                id={this.props.id}
                custom={this.props.custom}
                value={this.props.value}
                onKeyPress={this.props.onKeyPress}
                onKeyDown={event=> {
                    if (event.keyCode === 13) {
                        const form = event.target.form;
                        let index = Array.prototype.indexOf.call(form, event.target);
                        if(form.elements[index + 1])
                            form.elements[index + 1].focus();
                        event.preventDefault();
                    }
                }}
                onChange={this.props.onChange}
                className= {this.props.className? this.props.className : "col-lg-12"}
                disabled={this.props.disabled}
                style={{...this.props.style,fontSize:13,minWidth:208,maxWidth:208}}
            >
                {
                    this.props.children
                }
            </CSelect>
        )
    }
}