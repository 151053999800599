import React from 'react';
import ReactDOM from 'react-dom';

import { icons } from './assets/icons'

import App2 from './App2';

React.icons = icons

ReactDOM.render(
  <App2 />, 
  document.getElementById('root')
)