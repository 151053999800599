const generateBlockOrange = (slide, value, x, y, w, bareme=undefined) => {
    let val = [];
    if(bareme){
        val = [
            { text: value, options: { breakLine: true, fontSize: 13 } },
            { text: "avec TMI 45%", options: { fontSize: 7 } }
        ];
    }
    else{
        val = [
            { text: value, options: { fontSize: 13 } }
        ];
    }
    slide.addText(val, {
        x: x,
        y: y,
        w: w,
        fontFace: 'Gill Sans MT',
        h: '5%',
        color: 'FFFFFF',
        align: 'center',
        valign: 'center',
        bold: true,
        fill: 'D4725E',
        line: {
            color: 'D4725E'
        }
    });
};

export default generateBlockOrange;