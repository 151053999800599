import { blackColor, grayLetter } from "../../all_ppt_render";
import { rowHeight as hauteurLigne, rowTitleHeight as hauteurTitreLigne } from "./dimension";

const generateTableWithOneColumn = (values, slide, x, y, w,title, type) => {
    const rowTitleHeight = hauteurTitreLigne;
    const rowHeight = hauteurLigne;
    const heights = [rowTitleHeight];
    const titleFill = type === 'USUF_TEMPORAIRE' ? 'D6DCE5' : 'DAE3F3'

    const rows = [
        [
            { 
                text: title, 
                options: { 
                    align: 'center', 
                    valign: 'middle', 
                    color: blackColor, 
                    bold: true, 
                    fill: titleFill, 
                    fontFace: 'Roboto' 
                } 
            }
        ]
    ];

    values.map((data, index) => {
        heights.push(rowHeight);

        rows.push([
            {
                text: data, options: { 
                    fill: (index % 2 === 0) ? 'F9F9F9' : 'FFFFFF',
                    fontFace: 'Roboto',
                    align: 'center',
                    valign: 'middle',
                    color: grayLetter
                }
            }
        ])
    });

    slide.addTable(rows, {
        x: x,
        y: y,
        w: w,
        rowH: heights
    });
};

export default generateTableWithOneColumn;