import React, { useContext } from 'react';
import { numberFormat } from '../../../../../extra/all_ppt_render';
import { DataControlContext } from '../../../../../store/context/dataControl';
import { valuePeriodiciteEcheance } from '../Bloc3/Bloc3';
import './style.scss';

const Bloc33 = (props) => {
    const  { data } = props;
    const { empruntState, setEmpruntState } = useContext(DataControlContext);
    const { periode_echeance } = empruntState;

    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }} >
            <div style={{ fontWeight: 'bold', color: '#7a86a1', borderBottom: '2px solid #e7edf3', padding: "18px 15px 15px 15px" }} >Montant échéance</div>
            <div style={{ height: 200, display: 'flex', flexDirection: 'column', padding: '15px 15px 25px 15px' }} >

                <div style={{ display: 'flex',  }} >
                    <div style={{ width: '45%', color: '#7a86a1', fontWeight: 'bold'  }} >Périodicité</div>
                    <div style={{ background: '#e0ebf3', color: 'rgb(23,157,199)', textAlign: 'center', borderRadius: 15 }} >
                        { valuePeriodiciteEcheance(periode_echeance) }
                    </div>
                </div>

                <div style={{ display: 'flex', flexDirection: 'column',}} >
                    <div style={{ width: '55%', color: '#7a86a1', marginTop: 25 }} >Amortissement du capital</div>
                    <div style={{ color: 'rgb(23,157,199)', fontWeight: 'bold', fontSize: 20 }} >
                        {
                            data &&
                            data.amortissement_capital ? numberFormat(data.amortissement_capital) : 0
                        } €
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Bloc33;
