export const defaultFinanceDataPropositionFinanciere = JSON.parse(JSON.stringify({
    id: 1,
    montantInvesti: 0,
    banqueOuCompagnieAssurance: "",
    orientationGestion: "",
    objectif1: "",
    objectif2: "",
    objectif3: ""
}))

export const propositionFinanciereDefaultData = JSON.parse(JSON.stringify(
    {
        investisseur: 0,
        repartitionInvestissement: [
            {
                type: 'CT',
                title: "Comptes titres",
                elements: [{...defaultFinanceDataPropositionFinanciere}]
            },
            {
                type: 'CAPI',
                title: "Contrats de capitalisation",
                elements: [{...defaultFinanceDataPropositionFinanciere}]
            },
            {
                type: 'AV',
                title: "Contrats d'assurance-vie",
                elements: [{...defaultFinanceDataPropositionFinanciere}]
            },
            {
                type: 'PEA',
                title: "PEA",
                elements: [{...defaultFinanceDataPropositionFinanciere}]
            },
            {
                type: 'PEA-PME',
                title: "PEA-PME",
                elements: [{...defaultFinanceDataPropositionFinanciere}]
            },
            {
                type: 'CT2',
                title: "Compte titres n°2",
                elements: [{...defaultFinanceDataPropositionFinanciere}]
            }
        ]
    }
))