import React from 'react';
import { leftBackground, rightBackground, WhiteTableSpace } from '../TableRevenuFoncier/TableRevenuFoncier';

const TablePVImmoParticulier = () => {
    return (
        <table cellPadding={10} cellSpacing={10} style={{ width: '100%', textAlign: 'left' }} >
            <tr>
                <td style={{ fontWeight: 'bold', background: leftBackground, textAlign: 'center' }} >Prix de cession</td>
                <td></td>
                <td style={{ background: rightBackground }} >
                    <div style={{ marginBottom: 10 }} >Le prix de cession est le prix mentionné dans l’acte de vente.</div>
                    <div>Il est diminué des frais supportés par le vendeur pour la vente (diagnostics immobiliers, frais d’intermédiaires …).</div>
                </td>
            </tr>
            <WhiteTableSpace />
            <tr>
                <td style={{ fontWeight: 'bold', background: leftBackground, textAlign: 'center' }} >
                    <div style={{ marginBottom: 10 }} >-</div>
                    <div>Prix d’acquisition</div>
                </td>
                <td></td>
                <td style={{ background: rightBackground }} >
                    <div style={{ marginBottom: 10 }} >Le prix d’acquisition est le prix mentionné dans l’acte d’acquisition.</div>
                    <div>Il est majoré :</div>
                    <ul>
                        <li>des frais d’acquisition : frais réels, ou forfait de 7,5% du prix d’acquisition (uniquement si l’acquisition était à titre onéreux)</li>
                        <li>des travaux éligibles : frais réels, ou forfait de 15% (uniquement si le bien a été détenu plus de 5 ans)</li>
                    </ul>
                </td>
            </tr>
            <WhiteTableSpace />
            <tr>
                <td style={{ fontWeight: 'bold', background: leftBackground, textAlign: 'center' }} >
                    <div>=</div>
                    <div>Plus-value brute</div>
                </td>
                <td></td>
                <td></td>
            </tr>
            <WhiteTableSpace />
            <tr>
                <td style={{ background: leftBackground, textAlign: 'center' }} >
                    <div style={{ fontWeight: 'bold' }} >-</div>
                    <div style={{ fontWeight: 'bold' }} >
                        Abattement<br/>
                        <span style={{ fontWeight: 'normal' }} >Durée de détention</span>
                    </div>
                </td>
                <td></td>
                <td style={{ background: rightBackground }} >
                    <div style={{ marginBottom: 10 }} >La plus-value brute est diminuée d’abattements pour durée de détention.</div>
                    <div>La plus-value est ainsi <b>exonérée</b> d’impôt sur le revenu après un délai de détention de 22 ans, et de prélèvements sociaux après un délai de détention de 30 ans.</div>
                </td>
            </tr>
            <WhiteTableSpace />
            <tr>
                <td style={{ background: leftBackground, textAlign: 'center' }} >
                    <div style={{ fontWeight: 'bold' }} >=</div>
                    <div style={{ fontWeight: 'bold' }} >
                        Plus-value imposable
                    </div>
                </td>
                <td></td>
                <td style={{ background: rightBackground }} >
                    <div style={{ marginBottom: 10 }} >La plus-value nette après abattement au titre de l’impôt sur le revenu est soumise à une <b>imposition de 19%</b></div>
                    <div>La plus-value nette après abattement au titre des prélèvements sociaux est soumise aux <b>prélèvements sociaux (17,2%)</b>.</div>
                </td>
            </tr>
        </table>
    )
}

export default TablePVImmoParticulier;