import React from 'react';
import { leftBackground, rightBackground, WhiteTableSpace } from '../TableRevenuFoncier/TableRevenuFoncier';

const TablePlusValueProfessionnelle = () => {
    return (
        <table cellPadding={10} cellSpacing={10} style={{ textAlign: 'left' }} >
            <tr>
                <td style={{ width: '10%' }} ></td>
                <td style={{ width: '2%' }} ></td>
                <td style={{ width: '41%', background: 'rgb(132,151,176)', color: '#FFFFFF', fontWeight: 'bold', textAlign: 'center' }} >LOUEUR EN MEUBLÉ PROFESSIONNEL - LMP</td>
                <td style={{ width: '2%' }} ></td>
                <td style={{ width: '41%', background: 'rgb(132,151,176)', color: '#FFFFFF', fontWeight: 'bold', textAlign: 'center' }} >IMPÔT SUR LES SOCIÉTÉS</td>
            </tr>
            <tr>
                <td style={{ fontWeight: 'bold', textAlign: 'center', background: leftBackground }} >Plus-value court terme</td>
                <td></td>
                <td style={{ background: rightBackground }} >
                    <div style={{ marginBottom: 10 }} ><b>= Prix d’acquisition – Valeur Nette Comptable</b> <br/>Soumise au barème progressif de l’IR (0% à 45%) et aux <b>cotisations sociales</b>.</div>
                    <div style={{ fontStyle: 'italic' }} >Si le bien a été détenu moins de 2 ans, l’intégralité de la plus-value est une plus-value de court terme.</div>
                </td>
                <td></td>
                <td style={{ background: rightBackground }} >
                    <div style={{ fontWeight: 'bold' }} >= Prix de cession – Valeur Nette Comptable</div>
                    <div>Soumise à l’impôt sur les sociétés au taux de 15% jusqu’à 38.120€, puis 25%.</div>
                </td>
            </tr>
            <WhiteTableSpace />
            <tr>
                <td style={{ fontWeight: 'bold', textAlign: 'center', background: leftBackground }} >Plus-value long terme</td>
                <td></td>
                <td style={{ background: rightBackground }} >
                    <div style={{ marginBottom: 10 }} >
                        <div style={{ fontWeight: 'bold' }} >= Prix de cession – Prix d’acquisition</div>
                        <div>Soumise à une imposition de 12,8% et aux prélèvements sociaux de 17,2%.</div>
                    </div>
                    <div>
                        <div>Article 151 septies du CGI : <b>exonération d’impôt et de prélèvements sociaux</b> possible après 5 ans d’exploitation en LMP, si les recettes sont inférieures à 90.000€ (exonération totale) ou 126.000€ (exonération partielle).</div>
                        <div style={{ fontWeight: 'bold' }} >Les cotisations sociales restent dues.</div>
                    </div>
                </td>
                <td></td>
                <td style={{ background: rightBackground }} >
                    <div style={{ fontStyle: 'italic' }} >Régime spécifique à la cession de certains actifs, non applicable aux investissements locatifs.</div>
                </td>
            </tr>
            <WhiteTableSpace />
            <tr>
                <td></td>
                <td></td>
                <td style={{ background: rightBackground, fontWeight: 'bold' }} >La plus-value est imposable en cas de transmission du bien par donation ou succession</td>
                <td></td>
                <td></td>
            </tr>
        </table>
    )
}

export default TablePlusValueProfessionnelle;