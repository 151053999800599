import { blackColor, grayLetter } from "../../all_ppt_render";
import { rowHeight as hauteurLigne, rowTitleHeight as hauteurTitreLigne } from "./dimension";

const generateTableWithTwoColumns = (values, slide, x, y, w,title, type) => {
    const rowTitleHeight = hauteurTitreLigne;
    const rowHeight = hauteurLigne;
    const heights = [rowTitleHeight];
    const titleFill = type === 'USUF_TEMPORAIRE' ? 'D6DCE5' : 'DAE3F3'

    const rows = [
        [
            { text: title, options: { align: 'center', valign: 'middle', color: blackColor, bold: true, fill: titleFill, fontFace: 'Roboto', colspan: 2 } }
        ]
    ];

    values.map((data, index) => {
        heights.push(rowHeight);
        let rowData = [
            {
                text: data.label, 
                options: { 
                    fill: (index % 2 === 0) ? 'F9F9F9' : 'FFFFFF',
                    fontFace: 'Roboto',
                    align: 'center',
                    color: grayLetter,
                    valign: 'middle'
                }
            },
            {
                text: data.value, 
                options: { 
                    fill: (index % 2 === 0) ? 'F6F6F6' : 'F9F9F9',
                    fontFace: 'Roboto',
                    align: 'center',
                    color: grayLetter,
                    valign: 'middle',
                    bold: true
                }
            },
        ];

        if(type === 'USUF_TEMPORAIRE'){
            rowData[0].options.colspan = 2;
            rows.push([rowData[0]]);
        }
        else
            rows.push([...rowData]);
    });

    slide.addTable(rows, {
        x: x,
        y: y,
        w: w,
        rowH: heights
    });
};

export default generateTableWithTwoColumns;