export const cession_societe = ["32 32", `
<g id="svg_1"  transform="translate(0, 225) scale(0.1, -0.1)">
   <path id="svg_2" d="m67.71339,2237.79883c-1.14285,-1.3313 -2.28574,-7.03662 -2.66668,-12.74219l-0.57146,-10.46045l-10.4763,-0.5708c-5.71434,-0.37988 -11.42875,-1.52124 -12.76208,-2.6626c-3.80959,-3.04272 -3.61907,-264.55176 0.19047,-267.59497c1.7143,-1.52161 30.66705,-1.90186 104.19186,-1.71179l101.71567,0.5708l0.57135,11.98169l0.57153,11.79175l11.23822,0c9.14297,0 11.61917,0.7605 13.33347,3.61377c1.14282,2.47229 1.90479,36.7063 1.90479,98.13733c0,80.82959 -0.38098,95.47437 -2.85718,100.60913c-3.80957,7.98804 -63.23888,66.75635 -70.09613,69.41919c-8.38106,3.23291 -131.6208,2.85278 -134.28752,-0.38086zm129.90651,-15.7854c2.09526,-5.51538 1.14285,-11.60156 -3.61914,-20.73022c-2.4762,-5.1355 -4.19049,-9.69946 -3.61905,-10.27026c0.57143,-0.57056 16.19064,-7.03711 34.66708,-14.45459l33.71478,-13.50317l0.57144,-90.71997l0.38095,-90.71985l-90.47737,0l-90.47741,0l0,121.72058l0,121.7207l58.85789,0c51.81021,0 59.04839,-0.38086 60.00082,-3.04321zm-132.19225,-137.31567c0,-85.96545 0.57146,-114.30347 2.28574,-116.01526c1.71434,-1.71204 22.85747,-2.28223 83.81065,-2.28223l81.52489,0l0,-6.65662l0,-6.65662l-90.4774,0l-90.47739,0l0,122.67175l0,122.67114l6.6668,0l6.66672,0l0,-113.73218z"/>
   <path id="svg_3" d="m93.42802,2181.88354c-0.76188,-0.57031 -1.33333,-2.28198 -1.33333,-3.80371c0,-1.90161 8.76202,-2.47241 44.76247,-2.47241c43.42918,0 44.76254,0.19043 44.76254,3.80396c0,3.61377 -1.33336,3.80347 -43.42918,3.80347c-24.00031,0 -44.19104,-0.57031 -44.7625,-1.3313z"/>
   <path id="svg_4" d="m92.0947,2149.93237c0,-2.47266 9.71442,-2.85303 75.23909,-2.85303c65.52466,0 75.23907,0.38037 75.23907,2.85303c0,2.47217 -9.71442,2.85254 -75.23907,2.85254c-65.52467,0 -75.23909,-0.38037 -75.23909,-2.85254z"/>
   <path id="svg_5" d="m92.47568,2121.02344c0.57141,-3.2334 5.71434,-3.42358 74.85811,-3.42358c69.14371,0 74.2867,0.19019 74.85814,3.42358c0.57138,3.04321 -4.00006,3.2334 -74.85814,3.2334c-70.85809,0 -75.42961,-0.19019 -74.85811,-3.2334z"/>
   <path id="svg_6" d="m92.0947,2091.92432c0,-3.80322 1.33333,-3.80322 75.23909,-3.80322c73.90572,0 75.23907,0 75.23907,3.80322c0,3.80396 -1.33336,3.80396 -75.23907,3.80396c-73.90576,0 -75.23909,0 -75.23909,-3.80396z"/>
   <path id="svg_7" d="m93.42802,2065.86865c-0.76188,-0.57031 -1.33333,-2.28198 -1.33333,-3.80347c0,-2.09229 13.71445,-2.47266 75.23909,-2.47266c73.90572,0 75.23907,0 75.23907,3.80396c0,3.80347 -1.33336,3.80347 -73.90569,3.80347c-40.76248,0 -74.66769,-0.57031 -75.23915,-1.3313z"/>
   <path id="svg_8" d="m92.0947,2033.91711c0,-2.47253 9.71442,-2.85278 75.23909,-2.85278c65.52466,0 75.23907,0.38025 75.23907,2.85278c0,2.47266 -9.71442,2.85254 -75.23907,2.85254c-65.52467,0 -75.23909,-0.37988 -75.23909,-2.85254z"/>
   <path id="svg_9" d="m92.0947,2005.38879c0,-2.47229 9.71442,-2.85266 75.23909,-2.85266c65.52466,0 75.23907,0.38037 75.23907,2.85266c0,2.47253 -9.71442,2.85291 -75.23907,2.85291c-65.52467,0 -75.23909,-0.38037 -75.23909,-2.85291z"/>
  </g>
`]
