export const NOM_BOX = {
    FI: "Investissements financiers",
    CESSION: "Cession de société",
    DONATION: "Donations et successions",
    BILAN_PAT: "Patrimoine et fiscalité",
    IMMO: "Investisssements immobiliers"
}

export const SIMU = { 
    SUCC: "succession",
    FISC: "fiscalite",
    BILP: "bilan_patrimonial",
    IFI: "IFI",
    BOX_FI: "box_financiere",
    CESS: "cession",
    DON: "donation",
    BOX_IMMO: "box_immo",
    EMP: "emprunt",
    USUF_ECO: "usuf_eco"
  }