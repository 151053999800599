export const autreDefaultData = JSON.parse(JSON.stringify({
    patrimoineFiscalite: {
        fiscaliteRevenus: {
            fiscaliteAnnuelle: 0
        }
    }
}))

// Les paramètres de l'export
export const configurationExportDefaultData = JSON.parse(JSON.stringify({
    donationSuccession: {
        succession: {
            touteSelection: false,
            elements: [
                {
                    value: false,
                    type: 'configuration',
                    label: "Configuration"
                },
                {
                    value: false,
                    type: 'patrimoine',
                    label: "Patrimoine"
                },
                {
                    value: false,
                    type: 'heritiersLegatairesBeneficiaires',
                    label: "Héritiers, légataires et bénéficiaires",
                    childrens: [
                        {
                            value: false,
                            type: 'heritiers',
                            label: "Héritiers"
                        },
                        {
                            value: false,
                            type: 'autresLegatairesBeneficiaires',
                            label: "Autres légataires et bénéficiaires"
                        }
                    ]
                }
            ],
            enregistrementSelection: false,
        }
    },
    bilanPatrimonial: {
      touteSelection: false,
      elements: [
          {
              value: false,
              type: 'patrimoineglobal',
              label: "Patrimoine global",
              // childrens: [
              //   {
              //       value: false,
              //       type: 'synthese',
              //       label: "Synthèse"
              //   },
              //   {
              //       value: false,
              //       type: 'projectiondesinvestissements',
              //       label: "Projection des investissements"
              //   }
              // ]
          },
          {
              value: false,
              type: 'patrimoineprofessionnel',
              label: "Patrimoine professionnel"
          },
          {
              value: false,
              type: 'patrimoineimmobilier',
              label: "Patrimoine immobilier",
          },
          {
            value: false,
            type: 'patrimoinefinancier',
            label: "Patrimoine Financier",
          },
          {
            value: false,
            type: 'patrimoineautres',
            label: "Autres",
          }
      ],
      enregistrementSelection: false,
    },
    cessionSociete: {
      touteSelection: false,
      elements: [
          {
              value: false,
              type: 'projetdecession',
              label: "Projet de cession",
              childrens: [
                {
                    value: false,
                    type: 'situationfamiliale',
                    label: "Situation familiale"
                },
                {
                    value: false,
                    type: 'tableaudesactionnairesfamilliaux',
                    label: "Tableau des actionnaires familiaux"
                },
                {
                    value: false,
                    type: 'operationapportetdonations',
                    label: "Les opérations d'apports et donations envisagées avant la cession"
                }
              ]
          },
          {
              value: false,
              type: 'simulationduprojetdecession',
              label: "Simulation du projet de cession",
              childrens: [
                {
                    value: false,
                    type: 'tableauxdeborddesimpositionsetgains',
                    label: "Tableaux de bord des impositions et gains"
                },
                {
                    value: false,
                    type: 'presentationdifferentsscenarios',
                    label: "Présentation des différents scénarios",
                    childrens: [
                      {
                        value: false,
                        type: 'scenario1',
                        label: "Scénario 1"
                      },
                      {
                        value: false,
                        type: 'scenario2',
                        label: "Scénario 2"
                      },
                      {
                        value: false,
                        type: 'scenario3',
                        label: "Scénario 3"
                      },
                      {
                        value: false,
                        type: 'scenario4',
                        label: "Scénario 4"
                      },
                    ]
                },
              ]
          },
          {
              value: false,
              type: 'impositiondelaplus-valuedecession',
              label: "L'imposition de la plus-value de cession",
              childrens: [
                {
                    value: false,
                    type: 'environnementfiscal',
                    label: "L'environnement fiscal"
                },
                {
                    value: false,
                    type: 'deuxregimesimpositionIR',
                    label: "Les deux régimes d'imposition à l'IR"
                },
                {
                    value: false,
                    type: 'regimesabattementlecalculdeIR',
                    label: "Les régimes d'abattement le calcul de l'IR"
                },
                {
                    value: false,
                    type: 'choixentrelafattaxetlebaremeIR',
                    label: "Le choix entre la fat tax et le barème de l'IR"
                },
                {
                    value: false,
                    type: 'prelevementssociaux',
                    label: "Les prélèvements sociaux (PS)"
                },
                {
                    value: false,
                    type: 'contributionexceptionnellesurleshautsrevenus',
                    label: "La contribution exceptionnelle sur les hauts revenus (CEHR)"
                },
                {
                    value: false,
                    type: 'calendrierfiscal',
                    label: "Le calendrier fiscal"
                }
              ]
          },
          {
            value: false,
            type: 'operationsavantcession',
            label: "Opérations avant cession",
            childrens: [
              {
                  value: false,
                  type: 'datedecession',
                  label: "La date de cession"
              },
              {
                  value: false,
                  type: 'apportsavantcession',
                  label: "Les apports avant cession"
              },
              {
                  value: false,
                  type: 'donationsavantcession',
                  label: "Les donations avant cession"
              }
            ]
        },
      ],
      enregistrementSelection: false,
    },
    simulateurs: {
      AV: {
        touteSelection: false,
        elements: [
            {
                value: false,
                type: 'simulationinvestissement',
                label: "Simulation d'investissement",
                childrens: [
                  {
                      value: false,
                      type: 'caractéristiquesinvestissement',
                      label: "Caractéristiques de l'investissement"
                  },
                  {
                      value: false,
                      type: 'projectiondesinvestissementsetretraits',
                      label: "Projection des investissements et retraits"
                  },
                  {
                      value: false,
                      type: 'graphiques',
                      label: "Graphiques"
                  }
                ]
            },
            {
                value: false,
                type: 'fiscalitedesinvestissementsetdetransmission',
                label: "Fiscalité des investissements et de transmission",
                childrens: [
                  {
                      value: false,
                      type: 'nouveauxcontrats',
                      label: "Fiscalité des investissements : Nouveaux contrats"
                  },
                  {
                      value: false,
                      type: 'ancienscontrats',
                      label: "Fiscalité des investissements : Anciens contrats"
                  },
                  {
                      value: false,
                      type: 'ftnouveauxcontrats',
                      label: "Fiscalité de transmission : Nouveaux contrats"
                  },
                  {
                    value: false,
                    type: 'ftancienscontrats',
                    label: "Fiscalité de transmission : Selon l'historique de contrats"
                }
                ]
            },
        ],
        enregistrementSelection: false,
      },
      CP: {
        touteSelection: false,
        elements: [
            {
                value: false,
                type: 'simulationinvestissement',
                label: "Simulation d'investissement",
                childrens: [
                  {
                      value: false,
                      type: 'caractéristiquesinvestissement',
                      label: "Caractéristiques de l'investissement"
                  },
                  {
                      value: false,
                      type: 'projectiondesinvestissementsetretraits',
                      label: "Projection des investissements et retraits"
                  },
                  {
                      value: false,
                      type: 'graphiques',
                      label: "Graphiques"
                  }
                ]
            },
            {
                value: false,
                type: 'fiscalitedesinvestissementsetdetransmission',
                label: "Fiscalité des investissements et de transmission",
                childrens: [
                  {
                      value: false,
                      type: 'nouveauxcontrats',
                      label: "Fiscalité des investissements : Nouveaux contrats"
                  },
                  {
                      value: false,
                      type: 'ancienscontrats',
                      label: "Fiscalité des investissements : Anciens contrats"
                  },
                  {
                      value: false,
                      type: 'fiscalitedetransmission',
                      label: "Fiscalité de transmission"
                  },
                ]
            },
        ],
        enregistrementSelection: false,
      },
      IS: {
        touteSelection: false,
        elements: [
            {
                value: false,
                type: 'simulationinvestissement',
                label: "Simulation d'investissement",
                childrens: [
                  {
                      value: false,
                      type: 'caractéristiquesinvestissement',
                      label: "Caractéristiques de l'investissement"
                  },
                  {
                      value: false,
                      type: 'projectiondesinvestissementsetretraits',
                      label: "Projection des investissements et retraits",
                      childrens: [
                        {
                            value: false,
                            type: 'visionsociéte',
                            label: "Vision société"
                        },
                        {
                            value: false,
                            type: 'visionassociespersonnesphysiques',
                            label: "Vision associés personnes physiques"
                        },
                      ]
                  },
                  {
                      value: false,
                      type: 'graphiques',
                      label: "Graphiques"
                  }
                ]
            },
            {
                value: false,
                type: 'fiscalitedesinvestissementsetdetransmission',
                label: "Fiscalité des investissements et de transmission",
                childrens: [
                  {
                      value: false,
                      type: 'OPCVM',
                      label: "Fiscalité des placements : OPCVM de capitalisation"
                  },
                  {
                      value: false,
                      type: 'tauxIS',
                      label: "Fiscalité des placements : Taux de l'IS"
                  },
                  {
                      value: false,
                      type: 'faactionnaires',
                      label: "Fiscalité des associés / actionnaires"
                  },
                  {
                    value: false,
                    type: 'fiscalitedetransmission',
                    label: "Fiscalité de transmission"
                }
                ]
            },
        ],
        enregistrementSelection: false,
      },
      PEA: {
        touteSelection: false,
        elements: [
            {
                value: false,
                type: 'simulationinvestissement',
                label: "Simulation de l'investissement",
                childrens: [
                  {
                      value: false,
                      type: 'caractéristiquesinvestissement',
                      label: "Caractéristiques de l'investissement"
                  },
                  {
                      value: false,
                      type: 'projectiondesinvestissementsetretraits',
                      label: "Projection des investissements et retraits"
                  },
                  {
                      value: false,
                      type: 'graphiques',
                      label: "Graphiques"
                  }
                ]
            },
            {
                value: false,
                type: 'reglesfonctionnementetfiscalite',
                label: "Règles de fonctionnement et fiscalité",
                childrens: [
                  {
                      value: false,
                      type: 'fonctionnement',
                      label: "Règle de fonctionnement"
                  },
                  {
                      value: false,
                      type: 'fiscalite',
                      label: "Fiscalité"
                  },
                ]
            },
        ],
        enregistrementSelection: false,
      },
      CT: {
        touteSelection: false,
        elements: [
            {
                value: false,
                type: 'simulationinvestissement',
                label: "Simulation d'investissement",
                childrens: [
                  {
                      value: false,
                      type: 'caractéristiquesinvestissement',
                      label: "Caractéristiques de l'investissement"
                  },
                  {
                      value: false,
                      type: 'projectiondesinvestissementsetretraits',
                      label: "Projection des investissements et retraits"
                  },
                  {
                      value: false,
                      type: 'graphiques',
                      label: "Graphiques"
                  }
                ]
            },
            {
                value: false,
                type: 'fiscalitedesinvestissementsetdetransmission',
                label: "Fiscalité des investissements et de transmission",
                childrens: [
                  {
                      value: false,
                      type: 'generalite',
                      label: "Généralités"
                  },
                  {
                      value: false,
                      type: 'interetdividendes',
                      label: "Zoom sur intérêts & dividendes"
                  },
                  {
                      value: false,
                      type: 'plus-values',
                      label: "Zoom sur plus-values"
                  },
                  {
                    value: false,
                    type: 'fiscalitedesinvestissements',
                    label: "Fiscalité de transmission"
                }
                ]
            },
        ],
        enregistrementSelection: false,
      },
    },
    fiscaliteRevenus: {
      touteSelection: false,
      elements: [
          {
              value: false,
              type: 'synthese',
              label: "Synthèse"
          },
          {
              value: false,
              type: 'declaration',
              label: "Hypothèses",
            //   childrens: [
            //     {
            //         value: false,
            //         type: 'foyerfiscal',
            //         label: "Foyer fiscal"
            //     },
            //     {
            //         value: false,
            //         type: 'revenusactivitesetretraites',
            //         label: "Revenus d'activités et retraites"
            //     },
            //     {
            //         value: false,
            //         type: 'revenusdupatrimoine',
            //         label: "Revenus du patrimoine (inclus locations meublées)"
            //     },
            //     {
            //         value: false,
            //         type: 'caracteristiquesinvestissement',
            //         label: "Caractéristiques de l'investissement"
            //     },
            //     {
            //         value: false,
            //         type: 'chargesdeductibles',
            //         label: "Charges déductibles"
            //     },
            //     {
            //         value: false,
            //         type: 'reductionsimpotcredits',
            //         label: "Réductions dimpôts et crédits d'impôts"
            //     },
            //     {
            //         value: false,
            //         type: 'autres',
            //         label: "Autres"
            //     }
            // ]
          },
          {
              value: false,
              type: 'detailfiscalite',
              label: "Détail fiscalité"
          },
      ],
      enregistrementSelection: false,
    },
    ifi: {
      touteSelection: false,
      elements: [
          {
              value: false,
              type: 'synthese',
              label: "Synthèse",
          },
          {
              value: false,
              type: 'patrimoineimposableIFI',
              label: "Patrimoine imposable à l'IFI",
          },
          {
              value: false,
              type: 'ifitheorique',
              label: "IFI théorique",
          },
          {
              value: false,
              type: 'ifiapayer',
              label: "IFI à payer",
          },
          {
              value: false,
              type: 'annexes',
              label: "Annexes",
              childrens: [
                // {
                //     value: false,
                //     type: 'detaildupatrimoine',
                //     label: "Détail du patrimoine"
                // },
                {
                    value: false,
                    type: 'detailduplafonnementrevenusetproduits',
                    label: "Détail du plafonnement: Revenus et produits"
                },
                {
                    value: false,
                    type: 'detailduplafonnementimpotssurlesrevenusetproduits',
                    label: "Détail du plafonnement: Impôts sur les revenus et produits"
                }
              ]
          },
      ],
      enregistrementSelection: false,
    },
    emprunt: {
      touteSelection: false,
      elements: [
          {
              value: false,
              type: 'caracteristiques',
              label: "Caractéristiques",
          },
          {
              value: false,
              type: 'chiffrescles',
              label: "Chiffres clés"
          },
          {
              value: false,
              type: 'tableauamortissement',
              label: "Tableau d'amortissement",
          },

      ],
      enregistrementSelection: false,
    },
    plusValue: {
      touteSelection: false,
      elements: [
          {
              value: false,
              type: 'cessiondirecte',
              label: "Cession directe",
              childrens: [
                {
                    value: false,
                    type: 'caracteristiques',
                    label: "Caractéristiques"
                },
                {
                    value: false,
                    type: 'calculdelaplusvalueimposable',
                    label: "Calcul de la plus-value imposable"
                },
                {
                    value: false,
                    type: 'impositiondelaplusvalueimmobilière',
                    label: "Imposition de la plus-value immobilière",
                    childrens: [
                      {
                          value: false,
                          type: 'synthese',
                          label: "Synthèse"
                      },
                      {
                          value: false,
                          type: 'detailfiscalite',
                          label: "Détail fiscalité"
                      },
                    ]
                }
              ]
          },
          {
              value: false,
              type: 'cessionindirecte',
              label: "Cession indirecte",
              childrens: [
                {
                    value: false,
                    type: 'caracteristiques',
                    label: "Caractéristiques"
                },
                {
                    value: false,
                    type: 'calculdelaplusvalueimposable',
                    label: "Calcul de la plus-value imposable"
                },
                {
                    value: false,
                    type: 'impositiondelaplusvalueimmobilière',
                    label: "Imposition de la plus-value immobilière",
                    childrens: [
                      {
                          value: false,
                          type: 'synthese',
                          label: "Synthèse"
                      },
                      {
                          value: false,
                          type: 'detailfiscalite',
                          label: "Détail fiscalité"
                      },
                    ]
                }
              ]
          },
          {
              value: false,
              type: 'cessiondepartsdescisoumiseaIR',
              label: "Cession de parts de sci soumise à l'IR",
              childrens: [
                {
                    value: false,
                    type: 'caracteristiques',
                    label: "Caractéristiques"
                },
                {
                    value: false,
                    type: 'calculdelaplusvalueimposable',
                    label: "Calcul de la plus-value imposable"
                },
                {
                    value: false,
                    type: 'impositiondelaplusvalueimmobilière',
                    label: "Imposition de la plus-value immobilière",
                    childrens: [
                      {
                          value: false,
                          type: 'synthese',
                          label: "Synthèse"
                      },
                      {
                          value: false,
                          type: 'detailfiscalite',
                          label: "Détail fiscalité"
                      },
                    ]
                }
              ]
          },
          {
              value: false,
              type: 'comparaison',
              label: "Comparaison",
          },
          {
              value: false,
              type: 'informations',
              label: "Informations",
          },
      ],
      enregistrementSelection: false,
    },
    comparaison: {
      touteSelection: false,
      elements: [
          {
              value: false,
              type: 'hypothèsesdessimulationsinvestissement',
              label: "Hypothèses des simulations d'investissement",
              childrens: [
                {
                  value: false,
                  type: 'caracteristiquesdesinvestissements',
                  label: "Caractéristiques des investissements",
                },
                {
                    value: false,
                    type: 'versementscomplementairesetretraitsannuels',
                    label: "Versements complémentaires et retraits annuels"
                },
                {
                    value: false,
                    type: 'hypothesesdecalcul',
                    label: "Hypothèses de calcul"
                }
              ]
          },
          {
              value: false,
              type: 'hypothesesdessimulationsdetransmission',
              label: "Hypothèses des simulations de transmission",
          },
          {
              value: false,
              type: 'resultatsdessimulations',
              label: "Résultats des simulations",
          },
      ],
      enregistrementSelection: false,
    },
}))
