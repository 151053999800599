import React, { Component, useContext } from "react";
import NumberFormat from 'react-number-format';
import { CInput } from '@coreui/react';
import { SimulateurContext } from "../../../../../store/context/simulateur";
import { activePageBlock } from "../../../../../store/actions/simulateur";

const CustomInput = (props) => {
    const { className, ...inputProps } = props
    return (
        <div className={className} style={{ padding: 0 }}>
            <CInput {...inputProps} style={{ width: '100%', paddingRight: 30, textAlign: 'right', paddingLeft: 19, fontSize: 13, ...inputProps.style }} />
            <span style={{ position: "absolute", right: 16, top: 7, color: inputProps && inputProps.style && inputProps.style.color ? inputProps.style.color : "#768192" }}>€</span>
        </div>
    )
}

const FormulaireMontantEmprunt = (props) => {
    const { simulateurDispatch } = useContext(SimulateurContext);

    const handleChange = (event) => {
        // On met à jour l'état de changement de FORMULAIRE
        simulateurDispatch(activePageBlock())

        // Changement de valeur contenue dans le FORMULAIRE
        props.onChange(event);
    }

    return (
        <NumberFormat
            id={props.id}
            customInput={CustomInput}
            style={{ ...props.style }}
            decimalScale={2}
            isAllowed={(values) => {
                const { value } = values;
                return props.min ? value > props.min : value >= 0 || value == '' || value == '.';
            }}
            allowNegative={false}
            disabled={props.disabled}
            onKeyDown={event => {
                if (event.keyCode === 13) {
                    const form = event.target.form;
                    let index = Array.prototype.indexOf.call(form, event.target);
                    if (form.elements[index + 1])
                        form.elements[index + 1].focus();
                    event.preventDefault();
                }
            }}
            invalid={props.invalid}
            value={props.value == '.' ? '0.' : props.value}
            onChange={handleChange}
            onBlur={props.onBlur}
            className={props.className ? props.className : "col-lg-6"}
            thousandSeparator={' '}
        />
    )
}

export default FormulaireMontantEmprunt