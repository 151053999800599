import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
    },
    title: {
        padding: 3,
        fontWeight: 'bold'
    },
    subtitle: {
        padding: 3
    }
});

export default useStyles;