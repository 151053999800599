import React, { useEffect } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import DocumentMeta from 'react-document-meta';

import './scss/style.scss';
import { UtilsProvider } from './store/context/utils';
import { SimulateurProvider } from './store/context/simulateur';
import { ClientProvider } from './store/context/client';
import { PropFiProvider } from './store/context/propfi';
import { HeaderProvider } from './store/context/theHeader';
import { DataControlProvider } from './store/context/dataControl';
import ExpiredSessionComponent from './views/ExpiredSessionComponent/ExpiredSessionComponent';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./views/login/Login'));

const meta = {
  title: 'Simulabox',
  description: 'Simulation financière',
  canonical: '',
  meta: {
    charset: 'utf-8',
    name: {
      keywords: 'simulation, finance'
    }
  }
}

const App = ({ socket, sendToken, tokenFromApi, withRedirection, setWithRedirection }) => {
  return (
    <DocumentMeta {...meta}>
      <UtilsProvider>
        <DataControlProvider>
          <SimulateurProvider>
            <ClientProvider>
              <HeaderProvider>
                <PropFiProvider>
                  <BrowserRouter>
                    <React.Suspense fallback={loading}>
                      <Switch>
                        <Route path="/login" name="Se connecter" render={props => <Login socket={socket} sendToken={sendToken} {...props} />} />
                        <Route path="/session-interrompue" name="Session interrompue" render={props => <ExpiredSessionComponent {...props} />} />
                        <Route path="" exact={true} name="Accueil" render={props => <TheLayout socket={socket} withRedirection={withRedirection} setWithRedirection={setWithRedirection} tokenFromApi={tokenFromApi} {...props} />} />
                      </Switch>
                    </React.Suspense>
                  </BrowserRouter>
                </PropFiProvider>
              </HeaderProvider>

            </ClientProvider>
          </SimulateurProvider>
        </DataControlProvider>
      </UtilsProvider>
    </DocumentMeta>
  )
}

export default App;
