import { nombreEnfantsParConjoint } from "../../../views/Cession/CessionHaut/ProjectionCessionSociete/TableauSynthese/TableauSynthese";
import { nbLignesHolding, nbLignesScenario1, nbLignesScenario2 } from "../../../views/Cession/CessionHaut/ResultatsDetailles/Synthese";
import { blackColor } from "../../all_ppt_render";
import { formattedCurrency, formatPercent } from "../TableauSynthese/TableauSynthese";

const hasValue = (prixCession, impotsPV, netCession) => {
    return !((prixCession === '- €' && impotsPV === '- €' && netCession === '- €'))
};

const noPlusValueCession = (holding) => {
    return (hasValue(formattedCurrency(Math.round(holding.plus_value_cession)),'- €','- €'))
};

const noPlusValueCessionAndBaseImposableIS = (holding) => {
    return (noPlusValueCession(holding) && (hasValue(formattedCurrency(Math.round(holding.base_imposable_ps)),'- €','- €')));
};

const generateTableauSyntheseResultat = (cession, scenario, s) => {
    const { identiteActionnaire: identite, projetCessionSociete: projet, operationsAvantCession: operations } = cession;
    const { conjoints, enfants, affichageNomPersonnalise: affichage } = identite;
    const { conjoints: conjointsProjet, holding } = projet;
    const synthese = s.synthese;
    const conjointsData = [ synthese.conjoint1, synthese.conjoint2 ];
    const enfantsPPSommeData = synthese.somme_pp;
    const enfantsPPData = synthese.table_pp_enfant;
    const enfantsNPSommeData = [ synthese.somme_np_conjoint1, synthese.somme_np_conjoint2 ];
    const enfantsNPData = [ synthese.table_np_enfant_conjoint1, synthese.table_np_enfant_conjoint2 ];
    const holdingData = synthese.holding;
    const newco = synthese.newco;

    const borderValue = 2;

    const grayBackground = ''

    const boldPurple = "8E5EA8";
    const boldRed = "FF4948";
    const boldGreen = "269757";
    const boldYellow = "EDC414";

    const normalPurple = "EBE3FA";
    const normalRed = "F5DDD9";
    const normalGreen = "DBF0E1";
    const normalYellow = "FFFBE2";

    const rowSpanByScenario = () => {
        if(scenario === 1) return nbLignesScenario1(conjointsData, enfantsPPData, enfantsNPSommeData, enfantsNPData, conjoints[0].marieOuPasce === 'Oui' );
        else if(scenario === 2 || scenario === 3 || scenario === 4) return nbLignesScenario2(conjointsData);
    };

    const noBorder = {
        fill: 'FFFFFF'
    }

    const border = [
        {
            pt: 0,
            color: ''
        },
        {
            pt: borderValue,
            color: 'FFFFFF'
        },
        {
            pt: borderValue,
            color: '809DB4'
        },
        {
            pt: 0,
            color: ''
        }
    ]

    const tableProps = (align) => {
        return {
            align: align,
            fontSize: 11,
            fontFace: 'Roboto',
            color: blackColor,
            valign: 'middle',
            margin: align === 'left' ? [ 0, 0, 0, 5 ] : [ 0, 15, 0, 0 ],
        };
    };
    
    const totalValueProps = {
        fill: 'F3F3F3'
    }

    const rows = [
        [
            { 
                text: "Actionnaires Familiaux", 
                options: { 
                    ...tableProps('left'),
                    color: blackColor,
                    bold: true,
                    border: [ ...border ]
                }
            },
            { 
                text: "Prix de cession", 
                options: { 
                    ...tableProps('center'),
                    bold: true,
                    fill: boldPurple,
                    color: 'FFFFFF',
                    border: [ ...border ]
                }
            },
            { 
                text: "", 
                options: { 
                    ...tableProps('center'), 
                    bold: true
                }
            },
            { 
                text: "Impôts", 
                options: { 
                    ...tableProps('center'),
                    bold: true,
                    fill: boldRed,
                    color: 'FFFFFF',
                    border: [ ...border ]
                }
            },
            { 
                text: "", 
                options: { 
                    ...tableProps('center'), 
                    bold: true
                }
            },
            { 
                text: "Net", 
                options: { 
                    ...tableProps('center'),
                    bold: true,
                    fill: boldGreen,
                    color: 'FFFFFF',
                    border: [ ...border ]
                }
            },
            { 
                text: "", 
                options: { 
                    ...tableProps('center'), 
                    bold: true,
                }
            },
            { 
                text: "Régime fiscal", 
                options: { 
                    ...tableProps('center'), 
                    bold: true,
                    fill: boldYellow,
                    color: 'FFFFFF',
                    border: [ ...border ]
            }},
        ]
    ];

    // Population des conjoints
    conjoints.map((data, index) => {
        if(index === 0 || (index === 1 && conjoints[0].marieOuPasce === 'Oui')){
            let row = [];
            let nom = `${ affichage ? data.nomPersonnalise !== "" ? data.nomPersonnalise : `Conjoint ${index + 1}` : `Conjoint ${index + 1}` } PP`;
            let prixCession = formattedCurrency(Math.round(conjointsData[index].prix_cession));
            let impot = formattedCurrency(Math.round(conjointsData[index].impot_et_droit_flat));
            let net = formattedCurrency(Math.round(conjointsData[index].net_flat));
            
            if(hasValue(
                prixCession,
                impot,
                net
            )){
                row.push(
                    { text: nom, options: { ...tableProps('left'), bold: true } },
                    { text: prixCession, options: { ...tableProps('right'), fill: normalPurple, bold: true } },
                    { text: ``, options: { ...noBorder } },
                    { text: impot, options: { ...tableProps('right'), fill: normalRed, bold: true } },
                    { text: ``, options: { ...noBorder } },
                    { text: net, options: { ...tableProps('right'), fill: normalGreen, bold: true } },
                    { text: ``, options: { ...noBorder } },
                    { text: scenario === 1 ? `Flat tax` : conjointsData[0].regime_fiscale, options: { ...tableProps('right'), color: '808080', bold: true, fill: normalYellow, align: 'center', rowspan: rowSpanByScenario() }},
                );
            }

            if(row.length > 0)
                rows.push(row);
        }
    });

    // Population par la somme des enfants PP
    // Il ne faut pas oublier le TOTAL
    if(enfants.length > 1){
        let nom = "Enfants PP";
        let prixCession = formattedCurrency(Math.round(enfantsPPSommeData.prix_cession));
        let impot = formattedCurrency(Math.round(enfantsPPSommeData.impot_et_droit_flat));
        let net = formattedCurrency(Math.round(enfantsPPSommeData.net_flat))

        if(hasValue(
            prixCession,
            impot,
            net
        )){
            rows.push([
                { text: nom, options: { ...tableProps('left'), bold: true, ...totalValueProps } },
                { text: prixCession, options: { ...tableProps('right'), bold: true, fill: normalPurple, ...totalValueProps } },
                { text: "", options: { ...noBorder } },
                { text: impot, options: { ...tableProps('right'), bold: true, fill: normalRed, ...totalValueProps } },
                { text: "", options: { ...noBorder } },
                { text: net, options: { ...tableProps('right'), bold: true, fill: normalGreen, ...totalValueProps } },
                { text: "", options: { ...noBorder } },
                { text: "", options: { ...tableProps('right'), bold: true }},
            ]);
        }
    }

    // Population par les Enfants PP
    enfants.map((data, index) => {
        let actFam = enfants.length > 1 ? `   ${ affichage ? data.prenom !== "" ? data.prenom : `Enfant ${index + 1}` : `${ affichage ? data.prenom !== "" ? data.prenom : `Enfant ${index + 1}` : `Enfant ${index + 1}` }` } PP` : `${ affichage ? data.prenom !== "" ? data.prenom : `Enfant ${index + 1}` : `Enfant ${index + 1}` } PP`;
        let nom = actFam;
        let prixCession = formattedCurrency(Math.round(enfantsPPData[index].prix_cession));
        let impot = formattedCurrency(Math.round(enfantsPPData[index].impot_et_droit_flat));
        let net = formattedCurrency(Math.round(enfantsPPData[index].net_flat));
        let regimeFiscal = [3, 4].includes(scenario) ? enfantsPPData[index].regime_fiscale : "";
        let bold = enfants.length <= 1;
        let fillNom = enfants.length <= 1 ? totalValueProps : 'FFFFFF';
        let fillPrixCession = enfants.length <= 1 ? totalValueProps.fill : normalPurple;
        let fillImpot = enfants.length <= 1 ? totalValueProps.fill : normalRed;
        let fillNet = enfants.length <= 1 ? totalValueProps.fill : normalGreen;

        if(hasValue(
            prixCession,
            impot,
            net
        )){
            rows.push([
                { text: nom, options: { ...tableProps('left'), bold: bold, fill: fillNom } },
                { text: prixCession, options: { ...tableProps('right'), fill: normalPurple, fill: fillPrixCession } },
                { text: "", options: { ...noBorder } },
                { text: impot, options: { ...tableProps('right'), fill: fillImpot } },
                { text: "", options: { ...noBorder } },
                { text: net, options: { ...tableProps('right'), fill: fillNet } },
                { text: "", options: { ...noBorder } },
                { text: regimeFiscal, options: { ...tableProps('center'), color: '808080', bold: true, fill: scenario === 3 || scenario === 4 ? normalYellow : 'FFFFFF' }},
            ]);
        }
    });

    // Population par la somme des Conjoints USUF ou les enfants USUF
    conjointsProjet.map((donnee, indice) => {
        if(indice === 0 || (indice === 1 && conjoints[0].marieOuPasce === 'Oui')){
            let nb = nombreEnfantsParConjoint(enfants, indice);
            if(nb > 1){
                let nomS = `${ affichage ? conjoints[indice].nomPersonnalise !== "" ? conjoints[indice].nomPersonnalise : `Conjoint ${indice + 1}` : `Conjoint ${indice + 1}` } USUF / Enfants NP`;
                let prixCessionS = formattedCurrency(Math.round(enfantsNPSommeData[indice].prix_cession));
                let impotS = formattedCurrency(Math.round(enfantsNPSommeData[indice].impot_et_droit_flat));
                let netS = formattedCurrency(Math.round(enfantsNPSommeData[indice].net_flat))

                if(hasValue(
                    prixCessionS,
                    impotS,
                    netS
                )){
                    rows.push([
                        { text: nomS, options: { ...tableProps('left'), bold: true, ...totalValueProps } },
                        { text: prixCessionS, options: { ...tableProps('right'), bold: true, fill: normalPurple, ...totalValueProps } },
                        { text: "", options: { ...noBorder } },
                        { text: impotS, options: { ...tableProps('right'), bold: true, fill: normalRed, ...totalValueProps } },
                        { text: "", options: { ...noBorder } },
                        { text: netS, options: { ...tableProps('right'), bold: true, fill: normalGreen, ...totalValueProps } },
                        { text: "", options: { ...noBorder } },
                        { text: "", options: {...tableProps('right'), bold: true }},
                    ]);
                }

            }

            // Population par les enfants en demembrement de propriété (USUF)
            enfantsNPData[indice].map((data, index) => {
                let nb = nombreEnfantsParConjoint(enfants, indice);
                let actFam = `${ affichage ? conjoints[indice].nomPersonnalise !== "" ? conjoints[indice].nomPersonnalise : `Conjoint ${indice + 1}` : `Conjoint ${indice + 1}` } USUF / ${ affichage ? data.nom !== "" ? data.nom : `Enfant ${data.id}` : `Enfant ${data.id}` } NP`
                let nom = nb > 1 ? `   ${actFam}` : actFam;
                let prixCession = formattedCurrency(Math.round(data.prix_cession));
                let impot = formattedCurrency(Math.round(data.impot_et_droit_flat));
                let net = formattedCurrency(Math.round(data.net_flat));
                let bold = nb <= 1;
                let fillNom = nb <= 1 ? totalValueProps.fill : 'FFFFFF';
                let fillPrixCession = nb <= 1 ? totalValueProps.fill : normalPurple;
                let fillImpot = nb <= 1 ? totalValueProps.fill : normalRed;
                let fillNet = nb <= 1 ? totalValueProps.fill : normalGreen;

                if(hasValue(
                    prixCession,
                    impot,
                    net
                )){
                    rows.push([
                        { text: nom, options: { ...tableProps('left'), bold: bold, fill: fillNom } },
                        { text: prixCession, options: { ...tableProps('right'), bold: bold, fill: fillPrixCession } },
                        { text: ``, options: { ...noBorder, bold: true } },
                        { text: impot, options: { ...tableProps('right'), fill: fillImpot, bold: bold } },
                        { text: ``, options: { ...noBorder, bold: true } },
                        { text: net, options: { ...tableProps('right'), fill: fillNet, bold: bold } },
                        { text: ``, options: { ...noBorder, bold: bold } },
                        { text: ``, options: { ...tableProps('right') }},
                    ]);
                }
            });
        }
    });

    // Population par le HOLDING
    let nomHolding = affichage ? holding.nomPersonnalise !== "" ? holding.nomPersonnalise : 'HOLDING IS' : `HOLDING IS`;
    let prixCessionHolding = formattedCurrency(Math.round(holdingData.prix_cession));
    let impotHolding = formattedCurrency(Math.round(holdingData.impot_et_droit_flat));
    let netHolding = formattedCurrency(Math.round(holdingData.net_flat))

    if(hasValue(
        prixCessionHolding,
        impotHolding,
        netHolding
    )){
        rows.push([
            { text: nomHolding, options: { ...tableProps('left'), bold: true } },
            { text: prixCessionHolding, options: { ...tableProps('right'), bold: true, fill: normalPurple } },
            { text: "", options: { ...noBorder } },
            { text: impotHolding, options: { ...tableProps('right'), bold: true, fill: normalRed } },
            { text: "", options: { ...noBorder } },
            { text: netHolding, options: { ...tableProps('right'), bold: true, fill: normalGreen } },
            { text: "", options: { ...noBorder } },
            { text: "", options: {...tableProps('right'), bold: true }},
        ]);
    }
    
    // Plus-value de cession
    let nomPlusValueCession = "   Plus-value de cession";
    let plusValueCession = formattedCurrency(Math.round(holdingData.plus_value_cession));
    let regimeFiscalHolding = holding.regimeFiscal;

    if(hasValue(
        plusValueCession,
        '- €',
        '- €'
    )){
        rows.push([
            { text: nomPlusValueCession, options: { ...tableProps('left') } },
            { text: plusValueCession, options: { ...tableProps('right'), fill: normalPurple } },
            { text: "", options: { ...tableProps('right') } },
            { text: ``, options: { ...tableProps('right'), fill: normalRed } },
            { text: "", options: { ...tableProps('right') } },
            { text: "", options: { ...tableProps('right'), fill: normalGreen } },
            { text: "", options: { ...tableProps('right') } },
            { text: regimeFiscalHolding, options: { ...tableProps('center'), bold: true, color: '808080', fill: normalYellow, rowspan: nbLignesHolding(holdingData) }},
        ]);
    }
    
    // Base imposable IS
    let nomBaseImposableIS = "   Base imposable IS";
    let baseImposableIS = formattedCurrency(holdingData.base_imposable_ps);

    if(hasValue(
        baseImposableIS,
        '- €',
        '- €',
    )){
        rows.push([
            { text: nomBaseImposableIS, options: { ...tableProps('left') } },
            { text: baseImposableIS, options: { ...tableProps('right'), fill: normalPurple } },
            { text: "", options: { ...tableProps('right') } },
            { text: "", options: { ...tableProps('right'), fill: normalRed } },
            { text: "", options: { ...tableProps('right') } },
            { text: "", options: { ...tableProps('right'), fill: normalGreen } },
            { text: "", options: { ...tableProps('right') } },
            !noPlusValueCession(holdingData) && 
            { text: holding.regimeFiscal, options: { ...tableProps('center'), bold: true, color: '808080', fill: normalYellow, rowspan: nbLignesHolding(holdingData) }}
        ]);
    }
    
    // Taux IS
    let nomTauxIS = "   Taux IS";
    let tauxIS = formattedCurrency(Math.round(holdingData.prix_cession));

    if(hasValue(
        tauxIS,
        '- €',
        '- €',
    )){
        rows.push([
            { text: nomTauxIS, options: { ...tableProps('left') } },
            { text: `${holdingData.taux_is} %`, options: { ...tableProps('right'), fill: normalPurple } },
            { text: "", options: { ...noBorder } },
            { text: "", options: { ...tableProps('right'), fill: normalRed } },
            { text: "", options: { ...noBorder } },
            { text: "", options: { ...tableProps('right'), fill: normalGreen } },
            { text: "", options: { ...noBorder }},
            !noPlusValueCessionAndBaseImposableIS(holdingData) && 
            { text: holding.regimeFiscal, options: { ...tableProps('center'), bold: true, color: '808080', fill: normalYellow, rowspan: nbLignesHolding(holdingData) }}
        ]);
    }

    if(scenario === 3 || scenario === 4){
        let nomNewco = "Newco IS";
        let prixCessionNewco = formattedCurrency(Math.round(newco.prix_cession));
        let impotNewco =  formattedCurrency(Math.round(newco.impot_et_droit_flat));
        let netNewco = formattedCurrency(Math.round(newco.net_flat))

        // Population par le Newco
        if(hasValue(
            prixCessionNewco,
            impotNewco,
            netNewco
        )){
            rows.push([
                { text: nomNewco, options: { ...tableProps('left'), bold: true } },
                { text: prixCessionNewco, options: { ...tableProps('right'), bold: true, fill: normalPurple } },
                { text: "", options: { ...noBorder } },
                { text: impotNewco, options: { ...tableProps('right'), bold: true, fill: normalRed } },
                { text: "", options: { ...noBorder } },
                { text: netNewco, options: { ...tableProps('right'), bold: true, fill: normalGreen } },
                { text: "", options: { ...noBorder } },
                { text: "", options: { ...tableProps('right'), bold: true }},
            ]);
        }
        
        // Plus-value en report
        let nomPlusValueReport = "   Plus-value en report";
        let plusValueReport = `${formattedCurrency(Math.round(newco.plus_value_brute))}`;
        if(hasValue(
            plusValueReport,
            '- €',
            '- €',
        )){
            rows.push([
                { text: nomPlusValueReport, options: { ...tableProps('left') } },
                { text: plusValueReport, options: { ...tableProps('right'), fill: normalPurple } },
                { text: "", options: { ...noBorder } },
                { text: ``, options: { ...tableProps('right'), fill: normalRed } },
                { text: "", options: { ...noBorder } },
                { text: "", options: { ...tableProps('right'), fill: normalGreen } },
                { text: "", options: { ...noBorder } },
                { text: "", options: { ...tableProps('center') }},
            ]);
        }
    }

    // Population par le TOTAL
    let totalPrixCession = formattedCurrency(Math.round(synthese.somme_total_prix_cession));
    let totalImpot = formattedCurrency(Math.round(synthese.somme_total_impot_et_droit_flat));
    let totalNet = formattedCurrency(Math.round(synthese.somme_total_net_flat))

    if(hasValue(
        totalPrixCession,
        totalImpot,
        totalNet
    )){
        rows.push([
            { text: "", options: { ...tableProps('left') }},
            { text: totalPrixCession, options: { ...tableProps('right'), bold: true, color: 'FFFFFF', fill: boldPurple }},
            { text: "", options: { ...noBorder }},
            { text: totalImpot, options: { ...tableProps('right'), bold: true, color: 'FFFFFF', fill: boldRed }},
            { text: "", options: { ...noBorder } },
            { text: totalNet, options: { ...tableProps('right'), bold: true, color: 'FFFFFF', fill: boldGreen }},
            { text: "", options: { ...noBorder } },
            { text: "- €", options: { ...tableProps('right'), bold: true, color: 'FFFFFF', fill: 'FFFFFF' }},
        ]);
    }

    // Population par les TOTAUX en %
    // Cette ligne s'affiche tout le temps car le taux correspondant au prix de cession est toujours 100%
    let totalPourcentagePrixCession = `${formatPercent(synthese && synthese.taux_total_prix_cession ? synthese.taux_total_prix_cession : 0)}`;
    let totalPourcentageImpot = `${formatPercent(synthese &&  synthese.taux_impot_et_droit_flat ? synthese.taux_impot_et_droit_flat.toFixed(2) : 0)}`;
    let totalPourcentageNet = `${formatPercent(synthese && synthese.taux_net_flat ? Math.round(synthese.taux_net_flat) : 0)}`;

    rows.push([
        { text: "", options: {...tableProps('left') }},
        { text: totalPourcentagePrixCession, options: { ...tableProps('right'), bold: true, color: boldPurple,fill: normalPurple }},
        { text: "", options: { ...noBorder } },
        { text: totalPourcentageImpot, options: { ...tableProps('right'), bold: true, color: boldRed, fill: normalRed }},
        { text: "", options: { ...noBorder } },
        { text: totalPourcentageNet, options: { ...tableProps('right'), bold: true, color: boldGreen, fill: normalGreen }},
        { text: "", options: { ...noBorder } },
        { text: "",  options: { ...tableProps('right'), bold: true, color: 'FFFFFF', fill: 'FFFFFF' }},
    ]);

    return rows;
};

export default generateTableauSyntheseResultat;