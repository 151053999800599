export const autresDonationsDefaultData = JSON.parse(JSON.stringify({
    withCalcul: true,
    lienParente: 0,
    nombreDonataires: 1,
    donationPP: 0,
    donationNP: 0,
    ageUsufruitier: 0,
    hypotheseFraisNotaire: 0,
    valeurFiscale: 0,
    anneeDonation: 2006,
    abattementApplicable: 50000,
    handicap: 0,
    avec_import: {
      versionFicheClient: null,
      date_donation: "",
      donateur: 0,
      type: "",
      avecImport: 0,
      derniereDateImport: null,
      lienParente: 0,
      client: null,
      donataires: [],
      conjoint2: {
          prenom: "",
          age: 0
      },
      conjoints: [],
      enfants: [],
      petitsEnfants: [],
      donatairesHorsConjoint: [],
      avecDonatairesHorsConjoint: 0
    }
}))