 const rowsConjointsProps = {
    fontSize: 11,
    fontFace: 'Gill Sans MT',
    valign: 'middle'
};
 
 const donationLabels = [
        [
            { text: "Donation antérieure de moins de 15 ans ?", 
              options: { 
                  align: 'left', 
                  ...rowsConjointsProps,
                  valign: 'center',
                  color: '0070C0',
                  bold: true
                }}
        ],
        [
            { text: "Valeurs fiscales données sauf les dons exonérés", options: { align: 'left', ...rowsConjointsProps, color: '28366D' }  }
        ],
        [
            { text: "Année de la donation antérieure", options: { align: 'left', ...rowsConjointsProps, color: '28366D' }  }
        ],
        [
            { text: "Abattement fiscal applicable sur la donation antérieure", options: { align: 'left', ...rowsConjointsProps, color: '28366D' }  }
        ],
        [
            { text: "Nouvelle donation avant cession", 
              options: { 
                  align: 'left', 
                  ...rowsConjointsProps,
                  color: '0070C0',
                  bold: true,
                  underline: true
                }}
        ],
        [
            { text: "Donation en pleine propriété", options: { align: 'left', ...rowsConjointsProps, color: '28366D' }  }
        ],
        [
            { text: "Donation en nue-propriété avec clause de remploi", options: { align: 'left', ...rowsConjointsProps, color: '28366D' }  }
        ],
        [
            { text: "Frais et droits de donation", options: { align: 'left', ...rowsConjointsProps, color: '28366D' }  }
        ],
        [
            { text: "Taux marginal des droits de donation", options: { align: 'left', ...rowsConjointsProps, color: '28366D' }  }
        ],
    ];

const enfantsParPageDonation = (rowsEnfants, enfants) => {
    let titres = [];
    let valeursFiscales = [];
    let anneeDonation = [];
    let abattementFiscal = [];
    let nouvelleDonation = [];
    let donationPP = [];
    let donationNP = [];
    let fraisDonation = [];
    let tauxMarginaux = [];
    
    let indexTitres = 0;
    let indexValeursFiscales = 0;
    let indexAnneeDonation = 0;
    let indexAbattementFiscal = 0;
    let indexNouvelleDonation = 0;
    let indexDonationPP = 0;
    let indexDonationNP = 0;
    let indexFraisDonation = 0;
    let indexTauxMarginaux = 0;


    rowsEnfants.map((donnee, indice) => {
            let currentLine = donnee;
            currentLine.shift();
    
            let i = 0;
    
            while(i < currentLine.length){
                if( i%4 === 0){
                    let data = [];

                    // A transformer en boucle
                    if(currentLine[i]) data.push(currentLine[i]);
                    if(currentLine[i+1]) data.push(currentLine[i+1]);
                    if(currentLine[i+2]) data.push(currentLine[i+2]);
                    if(currentLine[i+3]) data.push(currentLine[i+3]);
                    if((enfants.length%4 === 0) && (currentLine[i+4])) data.push(currentLine[i+4]);

                    data.unshift({ text: donationLabels[indice]});
                    
                    if(indice === 0){
                        titres[indexTitres] = data;
                        indexTitres++;
                    }
                    else if(indice === 1){
                        valeursFiscales[indexValeursFiscales] = data;
                        indexValeursFiscales++;
                    }
                    else if(indice === 2){
                        anneeDonation[indexAnneeDonation] = data;
                        indexAnneeDonation++;
                    }
                    else if(indice === 3){
                        abattementFiscal[indexAbattementFiscal] = data;
                        indexAbattementFiscal++;
                    }
                    else if(indice === 4){
                        nouvelleDonation[indexNouvelleDonation] = data;
                        indexNouvelleDonation++
                    }
                    else if(indice === 5){
                        donationPP[indexDonationPP] = data;
                        indexDonationPP++;
                    }
                    else if(indice === 6){
                        donationNP[indexDonationNP] = data;
                        indexDonationNP++;
                    }
                    else if(indice === 7){
                        fraisDonation[indexFraisDonation] = data;
                        indexFraisDonation++;
                    }
                    else if(indice === 8){
                        tauxMarginaux[indexTauxMarginaux] = data;
                        indexTauxMarginaux++;
                    }

                    i += 4;
                }
                
            }
    });

    const nbEnfantsParPage = 4;//Math.ceil(enfants.length / 2);
    let finalData = [];

    for(let i=0; i<nbEnfantsParPage; i++){
        if(
            titres[i] &&
            valeursFiscales[i] &&
            anneeDonation[i] &&
            abattementFiscal[i] &&
            nouvelleDonation[i] &&
            donationPP[i] &&
            donationNP[i] &&
            fraisDonation[i] &&
            tauxMarginaux[i]
        ){
            finalData.push(
                [
                    titres[i],
                    valeursFiscales[i],
                    anneeDonation[i],
                    abattementFiscal[i],
                    nouvelleDonation[i],
                    donationPP[i],
                    donationNP[i],
                    fraisDonation[i],
                    tauxMarginaux[i]
                ]
            )
        }

        /*finalData[i] = [
            titres[i],
            valeursFiscales[i],
            anneeDonation[i],
            abattementFiscal[i],
            nouvelleDonation[i],
            donationPP[i],
            donationNP[i],
            fraisDonation[i],
            tauxMarginaux[i]
        ];*/
    }

    return finalData;
};

export default enfantsParPageDonation;