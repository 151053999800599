import React from 'react'
import CIcon from '@coreui/icons-react'
import { Editors } from "react-data-grid-addons"
import { TextCurrency } from '../input'

export const moisListe = [
    { id: 12, value: "Janvier" },
    { id: 11, value: "Février" },
    { id: 10, value: "Mars" },
    { id: 9, value: "Avril" },
    { id: 8, value: "Mai" },
    { id: 7, value: "Juin" },
    { id: 6, value: "Juillet" },
    { id: 5, value: "Août" },
    { id: 4, value: "Septembre" },
    { id: 3, value: "Octobre" },
    { id: 2, value: "Novembre" },
    { id: 1, value: "Décembre" }
];
const { DropDownEditor } = Editors;

export const MonthSelectEditor = <DropDownEditor options={moisListe} />;

export const Currency = (value) => {
    return (
        <TextCurrency value={value.value} />
    )
}

export const Header = (value) => {
    return (
        <div className="widget-HeaderCell__value">
            <TextCurrency value={value.value} />
            <CIcon name="cil-pencil" size={'xs'} style={{ position: "absolute", right: 15 }} />
        </div>
    )
}

export const HeaderWithoutPencil = (value) => {
    return (
        <div className="widget-HeaderCell__value">
            <TextCurrency value={value.value} />
        </div>
    )
}

export const HeaderResult = (value) => {
    return (
        <span ><TextCurrency value={value.value} />
            <span style={{ right: "0", position: "absolute", top: "-13px" }}><CIcon name="cil-pencil" style={{ width: "0.7em", height: "0.7em" }} color="grey" /></span>
        </span>
    )
}

export const HeaderRateResult = (value) => {
    return (
        <span>
            <span>{value.value} {value.value !== null ? '%' : ''}</span>
            {/* <span className='crayon' style={{ right: "0", position: "absolute", top: "-13px" }}><CIcon name="cil-pencil" style={{ width: "0.7em", height: "0.7em" }} color="grey" /></span> */}
        </span>
    )
}