const baseURL = process.env.REACT_APP_URL; // Set your API base URL

const handleResponse = async (response) => {
  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.message || 'Request failed');
  }
  return response.json();
};

const PostitService = {
  createPostit: async (postitData) => {
    try {
      const response = await fetch(`${baseURL}/api/postit`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postitData),
      });
      return handleResponse(response);
    } catch (error) {
      console.error('Error creating postit:', error);
      throw error;
    }
  },

  getPostitsByType: async (type, userId, idSimulation) => {
    try {
      const response = await fetch(`${baseURL}/api/postit/byType?type=${type}&userId=${userId}&idSimulation=${idSimulation}`);
      return handleResponse(response);
    } catch (error) {
      console.error('Error getting postits by type:', error);
      throw error;
    }
  },

  getPostitById: async (idPostit, userId, idSimulation) => {
    try {
      const response = await fetch(`${baseURL}/api/postit/byId?idPostit=${idPostit}&userId=${userId}&idSimulation=${idSimulation}`);
      return handleResponse(response);
    } catch (error) {
      console.error('Error getting postit by ID:', error);
      throw error;
    }
  },

  deletePostit: async (postId) => {
    try {
      const response = await fetch(`${baseURL}/api/postit/${postId}`, {
        method: 'DELETE',
      });
      return handleResponse(response);
    } catch (error) {
      console.error('Error deleting postit:', error);
      throw error;
    }
  },

  updatePostit: async (postId, updatedData) => {
    try {
      const response = await fetch(`${baseURL}/api/postit/${postId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedData),
      });
      return handleResponse(response);
    } catch (error) {
      console.error('Error updating postit:', error);
      throw error;
    }
  },

  updatePostitMultiple: async (updatedData) => {
    try {
      const response = await fetch(`${baseURL}/api/postit/multiple`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedData),
      });
      return handleResponse(response);
    } catch (error) {
      console.error('Error updating postit:', error);
      throw error;
    }
  },
};

export default PostitService;
