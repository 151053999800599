export const donsFamiliauxExoneresDefaultData = JSON.parse(JSON.stringify({
    withCalcul: true,
    allSteps: [
      {
        index: 0,
        isCompleted: false
      },
      {
        index: 1,
        isCompleted: false
      },
      {
        index: 2,
        isCompleted: false
      },
      {
        index: 3,
        isCompleted: false
      },
      {
        index: 4,
        isCompleted: false
      },
    ],
    montantExonere: 0,
    config: {
      versionFicheClient: null,
      derniereDateImport: null,
      client: null,
      donateur: null,
      lienParente: 0,
      donataire: null,
      avecImport: 0,
      conjoint2: {
          prenom: "",
          age: 0
      },
      conjoints: [],
      enfants: [],
      petitsEnfants: [],
      donataires: [],
      dateDonation: ""
    }
}))