import { restApiUri } from "../../config"
import { updateSimulation } from "../../store/actions/simulateur";
import { currencyToSend } from "../../store/utilsFunc";
import { calculTaux } from "./calcul/professionnel";

const bilan_uri = `${restApiUri}/api/bilan_patrimonial`;
const ifi_uri = `${restApiUri}/api/ifi`;
const fiscalite_uri = `${restApiUri}/api/fiscalite`

const conditionCheck = (condition) => {
    if (!condition || condition.length == 0) {
        return [];
    }
    return condition.sort((a, b) => a.index - b.index );
}

export const saveBilan = async (cb_request_start, cb_request_finish, cb_request_success, cb_request_fail, bilan, id) => {
    cb_request_start();
    addOrUpdateData(bilan, id).then((response) => {
        if (response) {
            cb_request_success(response);
        }
    })
        .catch(error => cb_request_fail(bilan))
        .finally(() => cb_request_finish());
}

export const getBilanData = async (id) => {
    const query = await fetch(`${bilan_uri}/${id}`, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${localStorage.getItem("token")}`
        }
    })

    return query.json();
}

export const getAllBilanData = async () => {
    const query = await fetch(`${bilan_uri}`, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${localStorage.getItem("token")}`
        }
    });

    return query.json();
}

export const addOrUpdateData = async (bilan, id) => {
    const url = id ? `${bilan_uri}/${id}` : bilan_uri;
    const query = await fetch(url, {
        method: id ? 'PUT' : 'POST',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${localStorage.getItem("token")}`
        },
        body: JSON.stringify(bilan),
        cache: 'no-cache'
    })

    return query.json();
}

export const cloneBilan = (data) => {
    const dataToSend = {
        // date_simulation: moment(new Date()).format("DD-MM-YYYY HH:mm"),
        userId: JSON.parse(localStorage.getItem("user"))["id"],
        clientId: data.client && data.client.id ? data.client.id : null,
        ...data,
    };
    delete dataToSend['client']
    return dataToSend
}

const defaultSaisieDetaillee = JSON.parse(JSON.stringify({
  "montant_emprunt": 0,//
  "taux_annuel": 0,//
  "duree": 0,//
  "date_emprunt": "",//
  "type_emprunt": 0, //
  "type_amortissement": 0,//
  "periode_echeance": 0,//
  "nb_periode": 0,//
  "taux_deces": 0,//
  "taux_dece2": 0,
  "taux_prime": 0,//
  "assiette_prime": 0,//
  "frais_dossier_en_pourcentage": 0,//
  "frais_dossier_en_euro": 0,//
  "rechercher_autre_date": "",
  "avec_assurance_amortiss": 0,//
  "avec_assurance_amortiss_sup": 0,//

  "annee_imposition": "",
  "valeur_immobilier": 0,
  "abattement_residence_principale": 0
}));

export const createBilanToSend = (simulation, bilan, elementsPostIt, configurationExport) => {
    const { patrimoineGlobal: pat_global, professionnel, immobilier: immo, financier, autres } = bilan;

    // Actif pro
    const createListActifProfessional = (actifsProfessionnels) => {
        if (!actifsProfessionnels || actifsProfessionnels.length == 0) {
            return [];
        }
        return actifsProfessionnels.reduce((result, actif, index) => {
            result.push({
                id: index + 1,
                intitule: actif.intitule || '',
                droits_propriete: actif.droitPropriete,
                pourcentage_eligibilite_dutriel: actif.eligibiliteDutreil,
                pourcentage_valeur_fiscal_NP: actif.valeurFiscale,
                valeur_PP: {
                    bien_personnel_conjoint1: currencyToSend(actif.bienPersonnelConjoint1PP),
                    bien_commun_conjoint: currencyToSend(actif.bienCommunPP),
                    bien_personnel_conjoint2: currencyToSend(actif.bienPersonnelConjoint2PP)
                }
            });
            return result;
        }, [])
    }

    const createAutresActifsProfessionnels = (actifsProfessionnels) => {
        const actifs = [];

        actifsProfessionnels.map((actif, index) => {
            let pourcentageDutreil = parseFloat(actif.eligibiliteDutreil) || 0;
            let bienConjoint1 = currencyToSend(actif.bienPersonnelConjoint1PP) || 0;
            let bienCommun = currencyToSend(actif.bienCommunPP) || 0;
            let bienConjoint2 = currencyToSend(actif.bienPersonnelConjoint2PP) || 0;

            // bienConjoint1 -= Math.round((pourcentageDutreil * bienConjoint1) / 100);
            // bienCommun -= Math.round((pourcentageDutreil * bienCommun) / 100);
            // bienConjoint2 -= Math.round((pourcentageDutreil * bienConjoint2) / 100);

            actifs.push({
                id: index + 1,
                intitule: actif.intitule || '',
                droits_propriete: actif.droitPropriete,
                pourcentage_eligibilite_dutriel: parseFloat(actif.eligibiliteDutreil) || 0,
                pourcentage_valeur_fiscal_NP: parseFloat(actif.valeurFiscale) || 0,
                valeur_PP: {
                    bien_personnel_conjoint1: bienConjoint1,
                    bien_commun_conjoint: bienCommun,
                    bien_personnel_conjoint2: bienConjoint2
                }
            })
        })

        return actifs;
    };

    // Résidence principale et autres actifs immobiliers
    const createListResidencePpleImmo = (residencePpals, autreAcif) => {
        let res_ppal = [];
        if (residencePpals.length > 0) {
            for (const [indexPrincipale, residencePpal] of residencePpals.entries()) {
              const res_ppale = {
                id: indexPrincipale,
                intitule: residencePpal.intitule || '',
                droits_propriete: residencePpal.droitPropriete,
                pourcentage_valeur_fiscal_NP: residencePpal.valeurFiscaleNP,
                pourcentage_exoneration_IFI: residencePpal.exonerereIfi,
                valeur_PP: {
                    bien_personnel_conjoint1: currencyToSend(residencePpal.bienPersonnelConjoint1PP) || 0,
                    bien_commun_conjoint: currencyToSend(residencePpal.bienCommunPP) || 0,
                    bien_personnel_conjoint2: currencyToSend(residencePpal.bienPersonnelConjoint2PP) || 0
                },

                emprunt_bien_personnel_conjoint1: residencePpal.emprunts[0].values.length > 0 ? residencePpal.emprunts[0].values.map((element, index) => {
                    return {
                        id: index + 1,
                        intitule_personnalise: element.intitulePersonnalise || "",
                        ammortissable_ou_in_fine: parseInt(element.amortissableFine) || 0,
                        capital_restant_du: currencyToSend(element.capitalRestant) || 0,
                        duree_restante: parseInt(element.dureeRestante) || 0,
                        taux_interets: parseFloat(element.tauxInteret) || 0,
                        taux_assurance: parseFloat(element.tauxAssurance) || 0,
                        assurance_deces_taux_couverture_deces_Client1: parseFloat(element.assurance_deces_taux_couverture_deces_Client1) || 0,
                        assurance_deces_taux_couverture_deces_Client2: parseFloat(element.assurance_deces_taux_couverture_deces_Client2) || 0,
                        assutance_deces: parseInt(element.assuranceDeces) || 0,
                        duree_total_si_in_fine: parseInt(element.dureeTotale) || 0,
                        type_saisie: element?.type_saisie || false,
                        emprunt: element?.emprunt || defaultSaisieDetaillee
                    }
                }) : [],

                emprunt_bien_commun_conjoint: residencePpal.emprunts[1].values.length > 0 ? residencePpal.emprunts[1].values.map((element, index) => {
                    return {
                        id: index + 1,
                        intitule_personnalise: element.intitulePersonnalise || "",
                        ammortissable_ou_in_fine: parseInt(element.amortissableFine) || 0,
                        capital_restant_du: currencyToSend(element.capitalRestant) || 0,
                        duree_restante: parseInt(element.dureeRestante) || 0,
                        taux_interets: parseFloat(element.tauxInteret) || 0,
                        taux_assurance: parseFloat(element.tauxAssurance) || 0,
                        assurance_deces_taux_couverture_deces_Client1: parseFloat(element.assurance_deces_taux_couverture_deces_Client1) || 0,
                        assurance_deces_taux_couverture_deces_Client2: parseFloat(element.assurance_deces_taux_couverture_deces_Client2) || 0,
                        assutance_deces: parseInt(element.assuranceDeces) || 0,
                        duree_total_si_in_fine: parseInt(element.dureeTotale) || 0,
                        type_saisie: element?.type_saisie || false,
                        emprunt: element?.emprunt || defaultSaisieDetaillee
                    }
                }) : [],

                emprunt_bien_personnel_conjoint2: residencePpal.emprunts[2].values.length > 0 ? residencePpal.emprunts[2].values.map((element, index) => {
                    return {
                        id: index + 1,
                        intitule_personnalise: element.intitulePersonnalise,
                        ammortissable_ou_in_fine: parseInt(element.amortissableFine) || 0,
                        capital_restant_du: currencyToSend(element.capitalRestant) || 0,
                        duree_restante: parseInt(element.dureeRestante) || 0,
                        taux_interets: parseFloat(element.tauxInteret) || 0,
                        taux_assurance: parseFloat(element.tauxAssurance) || 0,
                        assurance_deces_taux_couverture_deces_Client1: parseFloat(element.assurance_deces_taux_couverture_deces_Client1) || 0,
                        assurance_deces_taux_couverture_deces_Client2: parseFloat(element.assurance_deces_taux_couverture_deces_Client2) || 0,
                        assutance_deces: parseInt(element.assuranceDeces) || 0,
                        duree_total_si_in_fine: parseInt(element.dureeTotale),
                        type_saisie: element?.type_saisie || false,
                        emprunt: element?.emprunt || defaultSaisieDetaillee
                    }
                }) : []
            }
            res_ppal.push(res_ppale);

          }

        }
        const data_autre = conditionCheck(autreAcif);
        const autres = data_autre.reduce((result, residence, index) => {
            result.push({
                id: index + 1,
                intitule: residence.intitule || '',
                droits_propriete: residence.droitPropriete,
                pourcentage_valeur_fiscal_NP: residence.valeurFiscaleNP,
                pourcentage_exoneration_IFI: residence.exonerereIfi,
                valeur_PP: {
                    bien_personnel_conjoint1: currencyToSend(residence.bienPersonnelConjoint1PP),
                    bien_commun_conjoint: currencyToSend(residence.bienCommunPP),
                    bien_personnel_conjoint2: currencyToSend(residence.bienPersonnelConjoint2PP)
                },

                emprunt_bien_personnel_conjoint1: residence.emprunts.length > 0 ? residence.emprunts[0].values.map((element, index) => {
                    return {
                        id: index + 1,
                        intitule_personnalise: element.intitulePersonnalise || "",
                        ammortissable_ou_in_fine: parseInt(element.amortissableFine) || 0,
                        capital_restant_du: currencyToSend(element.capitalRestant) || 0,
                        duree_restante: parseInt(element.dureeRestante) || 0,
                        taux_interets: parseFloat(element.tauxInteret) || 0,
                        assurance_deces_taux_couverture_deces_Client1: parseFloat(element.assurance_deces_taux_couverture_deces_Client1) || 0,
                        assurance_deces_taux_couverture_deces_Client2: parseFloat(element.assurance_deces_taux_couverture_deces_Client2) || 0,
                        taux_assurance: parseFloat(element.tauxAssurance) || 0,
                        assutance_deces: parseInt(element.assuranceDeces) || 0,
                        duree_total_si_in_fine: parseInt(element.dureeTotale) || 0
                    }
                }) : [],

                emprunt_bien_commun_conjoint: residence.emprunts.length > 0 ? residence.emprunts[1].values.map((element, index) => {
                    return {
                        id: index + 1,
                        intitule_personnalise: element.intitulePersonnalise || "",
                        ammortissable_ou_in_fine: parseInt(element.amortissableFine) || 0,
                        capital_restant_du: currencyToSend(element.capitalRestant) || 0,
                        duree_restante: parseInt(element.dureeRestante) || 0,
                        taux_interets: parseFloat(element.tauxInteret) || 0,
                        taux_assurance: parseFloat(element.tauxAssurance) || 0,
                        assurance_deces_taux_couverture_deces_Client1: parseFloat(element.assurance_deces_taux_couverture_deces_Client1) || 0,
                        assurance_deces_taux_couverture_deces_Client2: parseFloat(element.assurance_deces_taux_couverture_deces_Client2) || 0,
                        assutance_deces: parseInt(element.assuranceDeces) || 0,
                        duree_total_si_in_fine: parseInt(element.dureeTotale) || 0
                    }
                }) : [],

                emprunt_bien_personnel_conjoint2: residence.emprunts.length > 0 ? residence.emprunts[2].values.map((element, index) => {
                    return {
                        id: index + 1,
                        intitule_personnalise: element.intitulePersonnalise || "",
                        ammortissable_ou_in_fine: parseInt(element.amortissableFine) || 0,
                        capital_restant_du: currencyToSend(element.capitalRestant) || 0,
                        duree_restante: parseInt(element.dureeRestante) || 0,
                        taux_interets: parseFloat(element.tauxInteret) || 0,
                        assurance_deces_taux_couverture_deces_Client1: parseFloat(element.assurance_deces_taux_couverture_deces_Client1) || 0,
                        assurance_deces_taux_couverture_deces_Client2: parseFloat(element.assurance_deces_taux_couverture_deces_Client2) || 0,
                        taux_assurance: parseFloat(element.tauxAssurance) || 0,
                        assutance_deces: parseInt(element.assuranceDeces) || 0,
                        duree_total_si_in_fine: parseInt(element.dureeTotale) || 0
                    }
                }) : []
            })
            return result;
        }, []);

        return { residence_principale_liste: res_ppal, autres_actifs_immo_liste: autres || [] }
    }

    // Résidence principale et autres actifs immobiliers
    const createListAutreResidenceImmo = (autreResidence, type) => {
      let res_autres = [];
      if (autreResidence?.length > 0) {
          for (const [indexAutreResidence, autreResidenceData] of autreResidence.entries()) {
            const res_autre = {
              id: indexAutreResidence,
              intitule: autreResidenceData.intitule || '',
              droits_propriete: autreResidenceData.droitPropriete,
              pourcentage_valeur_fiscal_NP: autreResidenceData.valeurFiscaleNP,
              pourcentage_exoneration_IFI: autreResidenceData.exonerereIfi,
              valeur_PP: {
                  valeur_PP_client1: currencyToSend(autreResidenceData.bienPersonnelConjoint1PP) || 0,
                  valeur_PP_couple: currencyToSend(autreResidenceData.bienCommunPP) || 0,
                  valeur_PP_client2: currencyToSend(autreResidenceData.bienPersonnelConjoint2PP) || 0
              },

              emprunt: {
                emprunt_client1: autreResidenceData.emprunts[0].values.length > 0 ? autreResidenceData.emprunts[0].values.map((element, index) => {
                    return {
                        id: index + 1,
                        intitule: element.intitulePersonnalise || "",
                        capital_restant_du: currencyToSend(element.capitalRestant) || 0,
                        assurance_deces_taux_couverture_deces_Client1: parseInt(element.assurance_deces_taux_couverture_deces_Client1) || 0,
                        assurance_deces_taux_couverture_deces_Client2: parseInt(element.assurance_deces_taux_couverture_deces_Client2) || 0,
                        type_saisie: element?.type_saisie || false,
                        emprunt: element?.emprunt || defaultSaisieDetaillee
                    }
                }) : [],

                emprunt_couple: autreResidenceData.emprunts[1].values.length > 0 ? autreResidenceData.emprunts[1].values.map((element, index) => {
                    return {
                        id: index + 1,
                        intitule: element.intitulePersonnalise || "",
                        capital_restant_du: currencyToSend(element.capitalRestant) || 0,
                        assurance_deces_taux_couverture_deces_Client1: parseInt(element.assurance_deces_taux_couverture_deces_Client1) || 0,
                        assurance_deces_taux_couverture_deces_Client2: parseInt(element.assurance_deces_taux_couverture_deces_Client2) || 0,
                        type_saisie: element?.type_saisie || false,
                        emprunt: element?.emprunt || defaultSaisieDetaillee
                    }
                }) : [],

                emprunt_client2: autreResidenceData.emprunts[2].values.length > 0 ? autreResidenceData.emprunts[2].values.map((element, index) => {
                    return {
                        id: index + 1,
                        intitule_personnalise: element.intitulePersonnalise,
                        intitule: element.intitulePersonnalise || "",
                        capital_restant_du: currencyToSend(element.capitalRestant) || 0,
                        assurance_deces_taux_couverture_deces_Client1: parseInt(element.assurance_deces_taux_couverture_deces_Client1) || 0,
                        assurance_deces_taux_couverture_deces_Client2: parseInt(element.assurance_deces_taux_couverture_deces_Client2) || 0,
                        type_saisie: element?.type_saisie || false,
                        emprunt: element?.emprunt || defaultSaisieDetaillee
                    }
                }) : []

              }

          }
          if (["immobilierLocatif"].includes(type)) {
            res_autre.regime_fiscal_loyers = autreResidenceData.regimeFiscalLoyers;
          }

          if (["biensRurauxEtForestiers"].includes(type)) {
            res_autre.categorie = autreResidenceData.categorie;
          }

          if (["monumentsHistoriques"].includes(type)) {
            res_autre.pourcentage_exoneration_DMTG = autreResidenceData.exonererDMTG;
          }

          res_autres.push(res_autre);

        }

      }

      return { res_autres }
  }

    // Comptes épargnes et liquidités
    const createCompteEpargneEtLiquiditeFinance = (comptesEpargnesLiquidites) => {

        return comptesEpargnesLiquidites.reduce((result, actif, index) => {
            result.push({
                id: index + 1,
                intitule: actif.intitule || '',
                dateValorisation: actif.dateValorisation || '',
                droits_propriete: actif.droitPropriete,
                pourcentage_eligibilite_dutriel: actif.eligibiliteDutreil,
                "pourcentage_valeur_fiscal_NP": actif.tauxValeurFiscaleNp,
                valeur_PP: {
                    bien_personnel_conjoint1: currencyToSend(actif.bienPersonnelConjoint1PP),
                    bien_commun_conjoint: currencyToSend(actif.bienCommunPP),
                    bien_personnel_conjoint2: currencyToSend(actif.bienPersonnelConjoint2PP)
                }
            });
            return result;
        }, [])
    }

    // Contrats de capitalisation
    const createContraCapitalisationFinance = (capis) => {
        if (!capis || capis.length == 0) {
            return;
        }
        return capis.reduce((result, capi, index) => {
            result.push({
                "id": index + 1,
                "intitule": capi.intitule || '',
                dateValorisation: capi.dateValorisation || '',
                "droits_propriete": capi.droitPropriete, //0 : PP 1 : USUF 2: NP
                "pourcentage_valeur_fiscal_NP": capi.tauxValeurFiscaleNp,
                "valeur_PP": {
                    "bien_personnel_conjoint1": currencyToSend(capi.bienPersonnelConjoint1PP),
                    "bien_commun_conjoint": currencyToSend(capi.bienCommunPP),
                    "bien_personnel_conjoint2": currencyToSend(capi.bienPersonnelConjoint2PP)
                },
                "actifs_immobiliers_taxables_IFI": {
                    "bien_personnel_conjoint1": currencyToSend(capi?.actifsImmobiliersTaxablesIFIConjoint1) || 0,
                    "bien_commun_conjoint": currencyToSend(capi?.actifsImmobiliersTaxablesIFICommun) || 0,
                    "bien_personnel_conjoint2": currencyToSend(capi?.actifsImmobiliersTaxablesIFIConjoint2) || 0
                },
                "decouvert_bancaire_garantie_contrat_capi": {
                    "bien_personnel_conjoint1": currencyToSend(capi.decouvertBancaireConjoint1),
                    "bien_commun_conjoint": currencyToSend(capi.decouvertBancaireCommun),
                    "bien_personnel_conjoint2": currencyToSend(capi.decouvertBancaireConjoint2)
                }
            })
            return result;

        }, [])
    }

    // Contrats d'assurance-vie
    const createAvFinance = (avs) => {
        const data = conditionCheck(avs);
        return data.reduce((result, av, index) => {

            const fisc = av.fiscalitesTransmission.length > 0 ? av.fiscalitesTransmission.map((element) => {
                return {
                    "renseigner_repartition": {
                        "taux_990ICGI": currencyToSend(element.tauxRepartitionCgi),
                        "taux_757B": currencyToSend(element.tauxRepartition757B),
                        "exoneration": currencyToSend(element.tauxRepartitionExoneration)
                    },
                    "montant_primes_versees_apres_70_ans": currencyToSend(element.montantPrimes),
                }
            }) : {
                "renseigner_repartition": {
                    "taux_990ICGI": 0,
                    "taux_757B": 0,
                    "exoneration": 0
                },
                "montant_primes_versees_apres_70_ans": 0,
                "garantie_bancaire": 0
            };

            const fiC1 = fisc[0] ? fisc[0] : fisc;
            const fiC2 = fisc[1] ? fisc[1] : {
                "renseigner_repartition": {
                    "taux_990ICGI": 0,
                    "taux_757B": 0,
                    "exoneration": 0
                },
                "montant_primes_versees_apres_70_ans": 0,
                "garantie_bancaire": 0
            };

            result.push({
                "id": index + 1,
                "intitule": av.intitule,
                "dateValorisation": av.dateValorisation || '',
                "dateEffetContrat": av.dateEffetContrat || '',
                "souscripter": av.souscripteurAssure,
                "droits_propriete": av.droitPropriete,
                "valeur_PP": {
                    "bien_commun_conjoint": currencyToSend(av.valeurPPConjoint1)
                },
                "actifsImmobiliersTaxablesIFI": {
                  "bien_commun_conjoint": currencyToSend(av?.actifsImmobiliersTaxablesIFIConjoint1) || 0
                },
                "decouvert_bancaire_garantie_CAV": {
                    "bien_commun_conjoint": currencyToSend(av.decouvertBancaireConjoint1)
                },
                "montant_gains_soumis_aux_prelevements_sociaux": {
                    "bien_commun_conjoint": currencyToSend(av.montantGainConjoint1)
                },
                "fiscalite_transmission_denouement_deces_conjoint1": fiC1,
                "fiscalite_transmission_denouement_deces_conjoint2": fiC2
            });
            return result;
        }, [])
    }

    // Contrats d'assurance-décès
    const createAssDeces = (assDeces) => {
      const data = conditionCheck(assDeces);
      return data.reduce((result, av, index) => {


          result.push({
              "id": index + 1,
              "intitule": av.intitule,
              "dateValorisation": av.dateValorisation || '',
              "dateEffetContrat": av.dateEffetContrat || '',
              "souscripter": av.souscripteurAssure,
              "droits_propriete": av.droitPropriete,
              "capitaux_deces": av.valeurPPConjoint1,
              "fiscalite_deces": av.fiscaliteDeces,
              "primes_versees": av.montantGainConjoint1
          });
          return result;
      }, [])
  }

    // Autres actifs financiers
    const autreActifeFinance = (autre) => {
        conditionCheck(autre);

        return autre.reduce((result, el, index) => {
            result.push({
                "id": index + 1,
                "intitule": el.intitule || '',
                "dateValorisation": el.dateValorisation || '',
                "droits_propriete": el.droitPropriete, //0 : PP 1 : USUF 2: NP
                "pourcentage_valeur_fiscal_NP": el.tauxValeurFiscaleNp,
                "valeur_PP": {
                    "bien_personnel_conjoint1": currencyToSend(el.bienPersonnelConjoint1PP),
                    "bien_commun_conjoint": currencyToSend(el.bienCommunPP),
                    "bien_personnel_conjoint2": currencyToSend(el.bienPersonnelConjoint2PP)
                },
                "actifs_immobiliers_taxables_IFI": {
                    "bien_personnel_conjoint1": currencyToSend(el?.actifsImmobiliersTaxablesIFIConjoint1) || 0,
                    "bien_commun_conjoint": currencyToSend(el?.actifsImmobiliersTaxablesIFICommun) || 0,
                    "bien_personnel_conjoint2": currencyToSend(el?.actifsImmobiliersTaxablesIFIConjoint2) || 0
                },
                "passif_associe": {
                    "bien_personnel_conjoint1": currencyToSend(el.decouvertBancaireConjoint1),
                    "bien_commun_conjoint": currencyToSend(el.decouvertBancaireCommun),
                    "bien_personnel_conjoint2": currencyToSend(el.decouvertBancaireConjoint2)
                }
            })
            return result;
        }, [])
    }

    // Les passifs
    const passifFinance = (passif) => {
        conditionCheck(passif);
        return passif.reduce((result, el, index) => {
            result.push({
                "id": index + 1,
                "intitule": el.intitule || '',
                "montant_passif": {
                    "passif_personnel_conjoint1": currencyToSend(el.montantPassifConjoint1),
                    "passif_commun_conjoint": currencyToSend(el.montantPassifCommun),
                    "passif_personnel_conjoint2": currencyToSend(el.montantPassifConjoint2)
                }
            })
            return result;
        }, [])
    }

    // Comptes titres
    const compteTitreFinance = (cts) => {
        conditionCheck(cts);

        return cts.reduce((result, el, index) => {
            result.push({
                "id": index + 1,
                "intitule": el.intitule,
                "dateValorisation": el.dateValorisation || '',
                "droits_propriete": el.droitPropriete, //0 : PP 1 : USUF 2: NP
                "pourcentage_valeur_fiscal_NP": el.tauxValeurFiscaleNp,
                "valeur_PP": {
                    "bien_personnel_conjoint1": currencyToSend(el.bienPersonnelConjoint1PP),
                    "bien_commun_conjoint": currencyToSend(el.bienCommunPP),
                    "bien_personnel_conjoint2": currencyToSend(el.bienPersonnelConjoint2PP)
                },
                "actifs_immobiliers_taxables_IFI": {
                    "bien_personnel_conjoint1": currencyToSend(el?.actifsImmobiliersTaxablesIFIConjoint1) || 0,
                    "bien_commun_conjoint": currencyToSend(el?.actifsImmobiliersTaxablesIFICommun) || 0,
                    "bien_personnel_conjoint2": currencyToSend(el?.actifsImmobiliersTaxablesIFIConjoint2) || 0
                },
                "decouvert_bancaire_garantie_comptes": {
                    "bien_personnel_conjoint1": currencyToSend(el.decouvertBancaireConjoint1),
                    "bien_commun_conjoint": currencyToSend(el.decouvertBancaireCommun),
                    "bien_personnel_conjoint2": currencyToSend(el.decouvertBancaireConjoint2)
                }
            })
            return result;
        }, [])
    }

    // Non cotés
    const createNonCoteFinance = (cts) => {
        conditionCheck(cts);

        return cts.reduce((result, el, index) => {
            result.push({
                "id": index + 1,
                "intitule": el.intitule,
                "dateValorisation": el.dateValorisation || '',
                "droits_propriete": el.droitPropriete, //0 : PP 1 : USUF 2: NP
                "pourcentage_valeur_fiscal_NP": el.tauxValeurFiscaleNp,
                "valeur_PP": {
                    "bien_personnel_conjoint1": currencyToSend(el.bienPersonnelConjoint1PP),
                    "bien_commun_conjoint": currencyToSend(el.bienCommunPP),
                    "bien_personnel_conjoint2": currencyToSend(el.bienPersonnelConjoint2PP)
                },
                "decouvert_bancaire_garantie_comptes": {
                    "bien_personnel_conjoint1": currencyToSend(el.decouvertBancaireConjoint1),
                    "bien_commun_conjoint": currencyToSend(el.decouvertBancaireCommun),
                    "bien_personnel_conjoint2": currencyToSend(el.decouvertBancaireConjoint2)
                }
            })
            return result;
        }, [])
    }

    // Comptes PEA
    const peaFinance = (pea) => {
        conditionCheck(pea);

        return pea.reduce((result, el, index) => {
            result.push({
                "id": index + 1,
                "intitule": el.intitule,
                "dateValorisation": el.dateValorisation || '',

                "cadre_fiscal": index > 0 ? 1 : 0, // 0 : PEA , 1: PEA-PME
                "droits_propriete": el.droitPropriete, //0 : PP 1 : USUF 2: NP
                // "pourcentage_valeur_fiscal_NP": 0,
                "valeur_PP": {
                    "bien_personnel_conjoint1": currencyToSend(el.valeurPPConjoint1),
                    "bien_personnel_conjoint2": currencyToSend(el.valeurPPConjoint2)
                },
                "decouvert_bancaire_garantie_comptes": {
                    "bien_personnel_conjoint1": currencyToSend(el.decouvertBancaireConjoint1),
                    "bien_personnel_conjoint2": currencyToSend(el.decouvertBancaireConjoint2)
                },
                "montant_gains_latents": {
                    "bien_personnel_conjoint1": currencyToSend(el.gainLatentConjoint1),
                    "bien_personnel_conjoint2": currencyToSend(el.gainLatentConjoint2)
                }
            })
            return result;
        }, [])
    }

    // Comptes PEA
    const perinAssurance = (perin) => {
      conditionCheck(perin);

      return perin.reduce((result, el, index) => {
          result.push({
              "id": index + 1,
              "intitule": el.intitule,
              "dateValorisation": el?.dateValorisation,
              "valorisation_client1": currencyToSend(el.valeurPPConjoint1),
              "valorisation_client2": currencyToSend(el.valeurPPConjoint2)
          })
          return result;
      }, [])
  }

    // AUTRES ACTIFS
    const createAutresListes = (actifs) => {
        conditionCheck(actifs);
        return actifs.reduce((result, actif, index) => {
            result.push({
                id: index + 1,
                intitule: actif.intitule || '',
                droits_propriete: actif.droitPropriete,
                pourcentage_valeur_fiscal_NP: currencyToSend(actif.valeurFiscale),
                valeur_PP: {
                    bien_personnel_conjoint1: currencyToSend(actif.bienPersonnelConjoint1PP),
                    bien_commun_conjoint: currencyToSend(actif.bienCommunPP),
                    bien_personnel_conjoint2: currencyToSend(actif.bienPersonnelConjoint2PP)
                }
            });
            return result;
        }, [])
    }

    const pro_liste_actif = createListActifProfessional(professionnel.actifsProfessionnels)

    const immo_residence_principale_liste = createListResidencePpleImmo(immo.residencePrincipaleCollapse, immo.residencesPrincipales).residence_principale_liste;
    const immo_autres_actifs_immo_liste = createListResidencePpleImmo(immo.residencePrincipaleCollapse, immo.residencesPrincipales).autres_actifs_immo_liste;
    const immo_residence_secondaires_liste = createListAutreResidenceImmo(immo.residenceSecondaires, "residenceSecondaires").res_autres;
    const immo_immobilier_locatif_liste = createListAutreResidenceImmo(immo.immobilierLocatif, "immobilierLocatif").res_autres;
    const immo_biens_ruraux_et_forestiers_liste = createListAutreResidenceImmo(immo.biensRurauxEtForestiers, "biensRurauxEtForestiers").res_autres;
    const immo_monuments_historiques_liste = createListAutreResidenceImmo(immo.monumentsHistoriques, "monumentsHistoriques").res_autres;
    const immo_scpi_liste = createListAutreResidenceImmo(immo.scpi, "scpi").res_autres;
    const immo_societes_immobiliers_liste = createListAutreResidenceImmo(immo.societesImmobiliers, "societesImmobiliers").res_autres;

    const autres_autres_actifs = createAutresListes(autres.actifs);

    const compte_epargne_liquidites_finance = createCompteEpargneEtLiquiditeFinance(financier.comptesEpargnesLiquidites) || [];
    const contrat_capi_finance = createContraCapitalisationFinance(financier.capis) || [];
    const av_finance = createAvFinance(financier.avs) || [];
    const autre_finance = autreActifeFinance(financier.autresActifsFinanciers) || [];
    const passif_finance = passifFinance(financier.passifs);
    const compte_titre_finance = compteTitreFinance(financier.comptesTitres) || [];
    const pea_finance = peaFinance(financier.peaPmes) || [];
    const perin_assurance = perinAssurance(financier.perins) || [];
    const liste_assurance_deces = createAssDeces(financier.assuranceDeces || []);
    const autresActifsPros = createAutresActifsProfessionnels(professionnel.actifsProfessionnels);
    const non_cote_finance = createNonCoteFinance(financier.nonCotes) || [];

    const allBilanData = {
        "post_it": elementsPostIt,
        "identifiant_simulation": simulation && simulation.identifiant_simulation ? simulation.identifiant_simulation : "brouillon",
        "terme_simulation": "fin",
        "commentaire": simulation && simulation.commentaire ? simulation.commentaire : "",
        "clientId": simulation && simulation.clientId ? simulation.clientId : null,
        "userId": JSON.parse(localStorage.getItem("user"))["id"],
        "pat_global": {
            "avecImportClient": pat_global.avecImportClient,
            "date_import": pat_global?.date_import || null,
            "situation_familiale": pat_global.situationFamiliale,
            "affichage": bilan.affichage,
            "situation_au": bilan.situationDu,
            "repartitionBrutNet": pat_global.repartitionBrutNet,
            "versionFicheClient": pat_global.versionFicheClient || null
        },

        "professionnel": {
            "liste_actif": pro_liste_actif,
            "liste_autre_actif": autresActifsPros
        },

        "immo": {
            "residence_principale_liste": immo_residence_principale_liste,
            "autres_actifs_immo_liste": immo_autres_actifs_immo_liste,
            "liste_residences_secondaires": immo_residence_secondaires_liste,
            "liste_immobilier_locatif": immo_immobilier_locatif_liste,
            "liste_biens_ruraux_ou_forestiers": immo_biens_ruraux_et_forestiers_liste,
            "liste_monuments_historiques": immo_monuments_historiques_liste,
            "liste_scpi": immo_scpi_liste,
            "liste_societe_immobiliere": immo_societes_immobiliers_liste
        },

        "financier": {
            "indicateur": [
                {
                    "id": 1,
                    "intitule": "horizon de placement",
                    "liste_choix": [
                        "CT",
                        "MT",
                        "LT"
                    ]
                },
                {
                    "id": 2,
                    "intitule": "Gestion fiscale",
                    "liste_choix": [
                        "Rendement",
                        "Capitalisation"
                    ]
                },
                {
                    "id": 3,
                    "intitule": "Devises",
                    "liste_choix": [
                        "Euros",
                        "Devises"
                    ]
                }
            ],
            "compte_epargne_liquidites_liste": compte_epargne_liquidites_finance,
            "comptes_titres_pea_liste": {
                "compte_titre": compte_titre_finance,
                "compte_pea": pea_finance
            },
            "liste_assurance_deces": liste_assurance_deces,
            "non_cotes_liste": non_cote_finance,
            "contrat_capitalisation_liste": contrat_capi_finance,
            "contrat_assurance_vie_liste": av_finance,
            "autres_actifs_financiers_liste": autre_finance,
            "liste_PERin_assurance": perin_assurance,
            "passif_liste": passif_finance
        },

        "autres": {
            "autres_actifs_liste": autres_autres_actifs,
        },

        "configurationExport": configurationExport
    }
    return allBilanData;
}


// Structuration de RESPONSE
export const BilanDestructuring = (bilan, userId) => {
    const { pat_global, professionnel, immo, financier, autres } = bilan;
    let { post_it } = bilan;
    post_it = post_it?.filter((element) => element.userId === userId ) || [];

    const { affichage, situation_familiale } = pat_global;
    const yes = affichage === 1;
    const yesConjoint1 = affichage === 2;
    const yesConjoint2 = affichage === 3;
    const celibataire = ![1, 2, 3].includes(Number(situation_familiale));
    const conditionMarie = yesConjoint1 && !celibataire;
    const conditionConjoint1 = conditionMarie || yes;

    const simulation = {
        identifiant_simulation: bilan.identifiant_simulation,
        commentaire: bilan.commentaire,
        clientId: bilan.client ? bilan.client.id : null,
    }

    const destListAutreActif = (listActif) => {
        const data = conditionCheck(listActif);
        return data.reduce((result, actif, index) => {
            result.push({
                open: true,
                intitule: actif.intitule,
                droitPropriete: actif.droits_propriete,
                eligibiliteDutreil: actif.pourcentage_eligibilite_dutriel,
                valeurFiscale: actif.pourcentage_valeur_fiscal_NP,
                bienPersonnelConjoint1PP: actif.valeur_PP.bien_personnel_conjoint1,
                bienCommunPP: actif.valeur_PP.bien_commun_conjoint,
                bienPersonnelConjoint2PP: actif.valeur_PP['bien_personnel_conjoint2']
            });
            return result;
        }, [])
    }
    const pro_actif_pro = destListAutreActif(professionnel.liste_actif);

    const newStructureEmprunt = (element, index) => {
        if (element.typeSaisie === undefined && !element.saisieRapide && !element.saisieDetaillee) {
            return {
                id: index + 1,
                intitule: element.intitule_personnalise || "",
                typeSaisie: 0,
                assurance_deces_taux_couverture_deces_Client1: parseFloat(element.assurance_deces_taux_couverture_deces_Client1) || 0,
                assurance_deces_taux_couverture_deces_Client2: parseFloat(element.assurance_deces_taux_couverture_deces_Client2) || 0,
                saisieRapide: {
                    capitalRestantDu: currencyToSend(element.capital_restant_du) || 0,
                    dateCapitalRestantDu: "",
                    typeEmprunt: element.ammortissable_ou_in_fine,
                    tauxCouvertureDeces: 0,
                    termeEmprunt: ""
                },
                saisieDetaille: {
                    "montant_emprunt": 0,
                    "taux_annuel": 0,
                    "duree": 0,
                    "date_emprunt": "",
                    "type_emprunt": 0,
                    "type_amortissement": 0,
                    "periode_echeance": 0,
                    "nb_periode": 0,
                    "taux_deces": 0,
                    "taux_deces2": 0,
                    "taux_prime": 0,
                    "assiette_prime": 0,
                    "frais_dossier_en_pourcentage": 0,
                    "frais_dossier_en_euro": 0,
                    "rechercher_autre_date": "",
                    "avec_assurance_amortiss": 0,
                    "avec_assurance_amortiss_sup": 0,

                    "annee_imposition": "",
                    "valeur_immobilier": 0,
                    "abattement_residence_principale": 0
                }
            }
        }
        else {
            return { ...element }
        }
    }

    const calculPourcentageImmo = (sommeTotale, somme) => {
        const currencySomme = currencyToSend(somme) || 0;
        const currencySommeTotale = currencyToSend(sommeTotale) || 0;

        let value = ((currencySomme * 100) / (currencySommeTotale));
        const resp = !isNaN(value) && value != null ? parseFloat(value) : 0;

        return resp;
    }

    const calculTauxImmo = (residence) => {
        const droitPropriete = residence.droits_propriete || 0;
        const bien1 = residence.valeur_PP && residence.valeur_PP.bien_personnel_conjoint1 ? residence.valeur_PP.bien_personnel_conjoint1 : 0;
        const bienCommun = residence.valeur_PP && residence.valeur_PP.bien_commun_conjoint ? residence.valeur_PP.bien_commun_conjoint : 0;
        const bien2 = residence.valeur_PP && residence.valeur_PP.bien_personnel_conjoint2 ? residence.valeur_PP.bien_personnel_conjoint2 : 0;
        const valeurFiscaleNP = parseFloat(residence.pourcentage_valeur_fiscal_NP || 0);
        let total = 0;

        const pourcentagePP = {
            conjoint1: 0,
            commun: 0,
            conjoint2: 0
        }

        const pourcentageUSUF = {
            conjoint1: 0,
            commun: 0,
            conjoint2: 0
        }

        const pourcentageNP = {
            conjoint1: 0,
            commun: 0,
            conjoint2: 0
        }

        const pourcentageValeurFiscaleNP = {
            conjoint1: valeurFiscaleNP,
            commun: valeurFiscaleNP,
            conjoint2: valeurFiscaleNP
        }

        // Cas Global ou Conjoint 1
        if (yes || yesConjoint1) {
            total += currencyToSend(bien1);
        }

        // Données communes Cas Global
        if (yes) {
            total += currencyToSend(bienCommun);
        }

        // Données communes Cas Conjoint 1 ou Conjoint 2
        if (conditionMarie || yesConjoint2) {
            total += currencyToSend(bienCommun) / 2;
        }

        // Cas Global ou Conjoint 2
        if (yes || yesConjoint2) {
            total += currencyToSend(bien2);
        }

        // Cas en PP
        if(droitPropriete == 0){
            pourcentagePP.conjoint1 = calculPourcentageImmo(total, bien1);
            pourcentagePP.commun = calculPourcentageImmo(total, bienCommun);
            pourcentagePP.conjoint2 = calculPourcentageImmo(total, bien2);
        }

        // Cas en USUF
        if(droitPropriete == 1){
            pourcentageUSUF.conjoint1 = calculPourcentageImmo(total, bien1);
            pourcentageUSUF.commun = calculPourcentageImmo(total, bienCommun);
            pourcentageUSUF.conjoint2 = calculPourcentageImmo(total, bien2);
        }

        // Cas en NP
        if(droitPropriete == 2){
            pourcentageNP.conjoint1 = calculPourcentageImmo(total, bien1);
            pourcentageNP.commun = calculPourcentageImmo(total, bienCommun);
            pourcentageNP.conjoint2 = calculPourcentageImmo(total, bien2);
        }

        return {
            valorisation: total,
            pourcentagePP,
            pourcentageUSUF,
            pourcentageNP,
            pourcentageValeurFiscaleNP
        }
    }

    /*const destResPpalImmo = (resPpal) => {
        const data = conditionCheck(resPpal);
        return data.reduce((result, residence, index) => {
            const residencePpalEmprunt = [
                {
                    conjoint: 0,
                    values: residence.emprunt_bien_personnel_conjoint1.map((element, index) => {
                        return { ...newStructureEmprunt(element, index) }
                    })
                },
                {
                    conjoint: 1,
                    values: residence.emprunt_bien_commun_conjoint.map((element, index) => {
                        return { ...newStructureEmprunt(element, index) }
                    })
                },
                {
                    conjoint: 2,
                    values: residence.emprunt_bien_personnel_conjoint2.map((element, index) => {
                        return { ...newStructureEmprunt(element, index) }
                    })
                }
            ];

            result.push({
                open: true,
                intitule: residence.intitule,
                droitPropriete: residence.droits_propriete,
                valorisation: residence.valorisation ? residence.valorisation : calculTauxImmo(residence).valorisation,
                pourcentagePP: residence.pourcentage_pp ? residence.pourcentage_pp : calculTauxImmo(residence).pourcentagePP,
                pourcentageUSUF: residence.pourcentage_usuf ? residence.pourcentage_usuf : calculTauxImmo(residence).pourcentageUSUF,
                pourcentageNP: residence.pourcentage_np ? residence.pourcentage_np : calculTauxImmo(residence).pourcentageNP,
                valeurFiscaleNP: residence.pourcentage_pp ? residence.pourcentage_valeur_fiscale_np : calculTauxImmo(residence).pourcentageValeurFiscaleNP,
                information: residence.information ? residence.information : "",
                emprunts: residencePpalEmprunt
            })
            return result;
        }, [])
    }*/

    const destResPpalImmo = (resPpal, type) => {
        const data = conditionCheck(resPpal);

        return data.reduce((result, residence, index) => {
            const residencePpalEmprunt = [
                {
                    conjoint: 0,
                    values: residence.emprunt_bien_personnel_conjoint1.map((element, index) => {
                        return {
                            intitulePersonnalise: element.intitule_personnalise || element.intitule || "",
                            amortissableFine: (parseInt(element.ammortissable_ou_in_fine) || 0) || (element?.saisieRapide?.typeEmprunt || 0) || 0,
                            capitalRestant: currencyToSend(element.capital_restant_du) || (element?.saisieRapide?.capitalRestantDu || 0) || 0,
                            dureeRestante: parseInt(element.duree_restante) || 0,
                            tauxInteret: parseFloat(element.taux_interets) || 0,
                            tauxAssurance: parseFloat(element.taux_assurance) || 0,
                            assuranceDeces: (parseInt(element.assutance_deces) || 0) || (element?.saisieRapide?.tauxCouvertureDeces > 0 ? 1 : 0),
                            dureeTotale: parseInt(element.duree_total_si_in_fine) || 0,
                            type_saisie: element?.type_saisie || false,
                            assurance_deces_taux_couverture_deces_Client1: parseFloat(element.assurance_deces_taux_couverture_deces_Client1) || 0,
                            assurance_deces_taux_couverture_deces_Client2: parseFloat(element.assurance_deces_taux_couverture_deces_Client2) || 0,
                            emprunt: element?.emprunt || defaultSaisieDetaillee
                        }
                    })
                },
                {
                    conjoint: 1,
                    values: residence.emprunt_bien_commun_conjoint.map((element, index) => {
                        return {
                            intitulePersonnalise: element.intitule_personnalise || element.intitule || "",
                            amortissableFine: (parseInt(element.ammortissable_ou_in_fine) || 0) || (element?.saisieRapide?.typeEmprunt || 0) || 0,
                            capitalRestant: currencyToSend(element.capital_restant_du) || (element?.saisieRapide?.capitalRestantDu || 0) || 0,
                            dureeRestante: parseInt(element.duree_restante) || 0,
                            tauxInteret: parseFloat(element.taux_interets) || 0,
                            tauxAssurance: parseFloat(element.taux_assurance) || 0,
                            assuranceDeces: (parseInt(element.assutance_deces) || 0) || (element?.saisieRapide?.tauxCouvertureDeces > 0 ? 1 : 0) || 0,
                            dureeTotale: parseInt(element.duree_total_si_in_fine) || 0,
                            type_saisie: element?.type_saisie || false,
                            assurance_deces_taux_couverture_deces_Client1: parseFloat(element.assurance_deces_taux_couverture_deces_Client1) || 0,
                            assurance_deces_taux_couverture_deces_Client2: parseFloat(element.assurance_deces_taux_couverture_deces_Client2) || 0,
                            emprunt: element?.emprunt || defaultSaisieDetaillee
                        }
                    })
                },
                {
                    conjoint: 2,
                    values: residence.emprunt_bien_personnel_conjoint2.map((element, index) => {
                        return {
                            intitulePersonnalise: element.intitule_personnalise || element.intitule || "",
                            amortissableFine: (parseInt(element.ammortissable_ou_in_fine) || 0) || (element?.saisieRapide?.typeEmprunt || 0) || 0,
                            capitalRestant: currencyToSend(element.capital_restant_du) || (element?.saisieRapide?.capitalRestantDu || 0) || 0,
                            dureeRestante: parseInt(element.duree_restante) || 0,
                            tauxInteret: parseFloat(element.taux_interets) || 0,
                            tauxAssurance: parseFloat(element.taux_assurance) || 0,
                            assuranceDeces: (parseInt(element.assutance_deces) || 0) || (element?.saisieRapide?.tauxCouvertureDeces > 0 ? 1 : 0) || 0,
                            dureeTotale: parseInt(element.duree_total_si_in_fine) || 0,
                            assurance_deces_taux_couverture_deces_Client1: parseFloat(element.assurance_deces_taux_couverture_deces_Client1) || 0,
                            assurance_deces_taux_couverture_deces_Client2: parseFloat(element.assurance_deces_taux_couverture_deces_Client2) || 0,
                            type_saisie: element?.type_saisie || false,
                            emprunt: element?.emprunt || defaultSaisieDetaillee
                        }
                    })
                }
            ];

            if(residence.valeur_PP === undefined){
                const droitPropriete = residence.droits_propriete;
                const value = residence[`pourcentage_${droitPropriete == 0 ? 'pp' : droitPropriete == 1 ? 'usuf' : 'np'}`];
                const valorisation = residence.valorisation || 0;
                const pourcentageConjoint1 = value.conjoint1;
                const pourcentageCommun = value.commun;
                const pourcentageConjoint2 = value.conjoint2;

                const valorisationConjoint1 = Math.round((pourcentageConjoint1 * valorisation) / 100);
                const valorisationCommun = Math.round((pourcentageCommun * valorisation) / 100);
                const valorisationConjoint2 = Math.round((pourcentageConjoint2 * valorisation) / 100);

                residence.valeur_PP = {
                    bien_personnel_conjoint1: valorisationConjoint1,
                    bien_commun_conjoint: valorisationCommun,
                    bien_personnel_conjoint2: valorisationConjoint2
                }
            }

            if(residence.pourcentage_valeur_fiscal_NP === undefined){
                residence.pourcentage_valeur_fiscal_NP = residence.droits_propriete == 2 ? residence?.pourcentage_valeur_fiscale_np?.conjoint1 || 0 : 0;
            }

            if(residence.emprunt_conjoint1 === undefined){
                residence.emprunt_conjoint1 = residence?.emprunt_bien_personnel_conjoint1?.[0]?.saisieRapide?.capitalRestantDu || 0;
            }

            if(residence.emprunt_commun === undefined){
                residence.emprunt_commun = residence?.emprunt_bien_commun_conjoint?.[0]?.saisieRapide?.capitalRestantDu || 0;
            }

            if(residence.emprunt_conjoint2 === undefined){
                residence.emprunt_conjoint2 = residence?.emprunt_bien_personnel_conjoint2?.[0]?.saisieRapide?.capitalRestantDu || 0;
            }

            const realData = {
              open: true,
              intitule: residence.intitule,
              droitPropriete: residence.droits_propriete,
              valeurFiscaleNP: parseFloat(residence.pourcentage_valeur_fiscal_NP) || 0,
              bienPersonnelConjoint1PP: residence.valeur_PP.bien_personnel_conjoint1,
              bienCommunPP: residence.valeur_PP.bien_commun_conjoint,
              bienPersonnelConjoint2PP: residence.valeur_PP.bien_personnel_conjoint2,
              exonerereIfi: residence.pourcentage_exoneration_IFI,
              // empruntConjoint1: residence.emprunt_conjoint1 || 0,
              // empruntConjoint2: residence.emprunt_conjoint2 || 0,
              // empruntCommun: residence.emprunt_commun || 0,
              // form2: structurationForm2Immobilier(residence.valeur_PP, yes, yesConjoint1, yesConjoint2, conditionMarie),
              emprunts: residencePpalEmprunt
            }

            result.push(realData)
            return result;
        }, [])
    }

    const destResAutres = (resAutres, type) => {
      const data = conditionCheck(resAutres);

      return data.reduce((result, residence, index) => {
          const residenceAutreEmprunt = [
              {
                  conjoint: 0,
                  values: residence.emprunt.emprunt_client1.map((element, index) => {
                      return {
                          intitulePersonnalise: element.intitule_personnalise || element.intitule || "",
                          amortissableFine: (parseInt(element.ammortissable_ou_in_fine) || 0) || (element?.saisieRapide?.typeEmprunt || 0) || 0,
                          capitalRestant: currencyToSend(element.capital_restant_du) || (element?.saisieRapide?.capitalRestantDu || 0) || 0,
                          dureeRestante: parseInt(element.duree_restante) || 0,
                          tauxInteret: parseFloat(element.taux_interets) || 0,
                          tauxAssurance: parseFloat(element.taux_assurance) || 0,
                          assuranceDeces: (parseInt(element.assutance_deces) || 0) || (element?.saisieRapide?.tauxCouvertureDeces > 0 ? 1 : 0),
                          dureeTotale: parseInt(element.duree_total_si_in_fine) || 0,
                          type_saisie: element?.type_saisie || false,
                          assurance_deces_taux_couverture_deces_Client1: parseFloat(element.assurance_deces_taux_couverture_deces_Client1) || 0,
                          assurance_deces_taux_couverture_deces_Client2: parseFloat(element.assurance_deces_taux_couverture_deces_Client2) || 0,
                          emprunt: element?.emprunt || defaultSaisieDetaillee
                      }
                  })
              },
              {
                  conjoint: 1,
                  values: residence.emprunt.emprunt_couple.map((element, index) => {
                      return {
                          intitulePersonnalise: element.intitule_personnalise || element.intitule || "",
                          capitalRestant: currencyToSend(element.capital_restant_du) || (element?.saisieRapide?.capitalRestantDu || 0) || 0,
                          assuranceDeces: (parseInt(element.assutance_deces) || 0) || (element?.saisieRapide?.tauxCouvertureDeces > 0 ? 1 : 0) || 0,
                          assuranceDece2: (parseInt(element.assutance_deces) || 0) || (element?.saisieRapide?.tauxCouvertureDeces > 0 ? 1 : 0) || 0,
                          type_saisie: element?.type_saisie || false,
                          assurance_deces_taux_couverture_deces_Client1: parseFloat(element.assurance_deces_taux_couverture_deces_Client1) || 0,
                          assurance_deces_taux_couverture_deces_Client2: parseFloat(element.assurance_deces_taux_couverture_deces_Client2) || 0,
                          emprunt: element?.emprunt || defaultSaisieDetaillee
                      }
                  })
              },
              {
                  conjoint: 2,
                  values: residence.emprunt.emprunt_client2.map((element, index) => {
                      return {
                          intitulePersonnalise: element.intitule_personnalise || element.intitule || "",
                          amortissableFine: (parseInt(element.ammortissable_ou_in_fine) || 0) || (element?.saisieRapide?.typeEmprunt || 0) || 0,
                          capitalRestant: currencyToSend(element.capital_restant_du) || (element?.saisieRapide?.capitalRestantDu || 0) || 0,
                          dureeRestante: parseInt(element.duree_restante) || 0,
                          tauxInteret: parseFloat(element.taux_interets) || 0,
                          tauxAssurance: parseFloat(element.taux_assurance) || 0,
                          assuranceDeces: (parseInt(element.assutance_deces) || 0) || (element?.saisieRapide?.tauxCouvertureDeces > 0 ? 1 : 0) || 0,
                          dureeTotale: parseInt(element.duree_total_si_in_fine) || 0,
                          type_saisie: element?.type_saisie || false,
                          assurance_deces_taux_couverture_deces_Client1: parseFloat(element.assurance_deces_taux_couverture_deces_Client1) || 0,
                          assurance_deces_taux_couverture_deces_Client2: parseFloat(element.assurance_deces_taux_couverture_deces_Client2) || 0,
                          emprunt: element?.emprunt || defaultSaisieDetaillee
                      }
                  })
              }
          ];

          if(residence.valeur_PP === undefined){
              const droitPropriete = residence.droits_propriete;
              const value = residence[`pourcentage_${droitPropriete == 0 ? 'pp' : droitPropriete == 1 ? 'usuf' : 'np'}`];
              const valorisation = residence.valorisation || 0;
              const pourcentageConjoint1 = value.conjoint1;
              const pourcentageCommun = value.commun;
              const pourcentageConjoint2 = value.conjoint2;

              const valorisationConjoint1 = Math.round((pourcentageConjoint1 * valorisation) / 100);
              const valorisationCommun = Math.round((pourcentageCommun * valorisation) / 100);
              const valorisationConjoint2 = Math.round((pourcentageConjoint2 * valorisation) / 100);

              residence.valeur_PP = {
                  valeur_PP_client1: valorisationConjoint1,
                  valeur_PP_couple: valorisationCommun,
                  valeur_PP_client2: valorisationConjoint2
              }
          }

          if(residence.pourcentage_valeur_fiscal_NP === undefined){
              residence.pourcentage_valeur_fiscal_NP = residence.droits_propriete == 2 ? residence?.pourcentage_valeur_fiscale_np?.conjoint1 || 0 : 0;
          }

          if(residence.emprunt.emprunt_client1 === undefined){
              residence.emprunt.emprunt_client1 = residence?.emprunt_bien_personnel_conjoint1?.[0]?.saisieRapide?.capitalRestantDu || 0;
          }

          if(residence.emprunt.emprunt_couple === undefined){
              residence.emprunt.emprunt_couple = residence?.emprunt_bien_commun_conjoint?.[0]?.saisieRapide?.capitalRestantDu || 0;
          }

          if(residence.emprunt.emprunt_client2 === undefined){
              residence.emprunt.emprunt_client2 = residence?.emprunt_bien_personnel_conjoint2?.[0]?.saisieRapide?.capitalRestantDu || 0;
          }

          const res_autre = {
            open: true,
            intitule: residence.intitule,
            droitPropriete: residence.droits_propriete,
            valeurFiscaleNP: parseFloat(residence.pourcentage_valeur_fiscal_NP) || 0,
            bienPersonnelConjoint1PP: residence.valeur_PP.valeur_PP_client1,
            bienCommunPP: residence.valeur_PP.valeur_PP_couple,
            bienPersonnelConjoint2PP: residence.valeur_PP.valeur_PP_client2,
            type_saisie: residence.type_saisie,
            exonerereIfi: residence.pourcentage_exoneration_IFI,
            emprunts: residenceAutreEmprunt
          }

          if (["immobilier_locatif"].includes(type)) {
            res_autre.regimeFiscalLoyers = residence.regime_fiscal_loyers;
          }

          if (["biens_ruraux_ou_forestiers"].includes(type)) {
            res_autre.categorie = residence.categorie
          }

          if (["monuments_historiques"].includes(type)) {
            res_autre.exonererDMTG = residence.pourcentage_exoneration_DMTG;
          }

          result.push(res_autre)

          return result;
      }, [])
  }

    const destAutreAutresListeActif = (actifs) => {
        const data = conditionCheck(actifs);
        return data.reduce((result, actif, index) => {
            result.push({
                open: true,
                intitule: actif.intitule,
                droitPropriete: actif.droits_propriete,
                valeurFiscale: actif.pourcentage_valeur_fiscal_NP,
                bienPersonnelConjoint1PP: actif.valeur_PP.bien_personnel_conjoint1,
                bienCommunPP: actif.valeur_PP.bien_commun_conjoint,
                bienPersonnelConjoint2PP: actif.valeur_PP.bien_personnel_conjoint2
            })
            return result;
        }, [])
    }

    const destCompteEpargneLiquiditesList = (actifs) => {
        const data = conditionCheck(actifs);
        return data.reduce((result, actif, index) => {
            result.push({
                open: true,
                intitule: actif.intitule,
                dateValorisation: actif.dateValorisation || '',
                tauxValeurFiscaleNp: actif.pourcentage_valeur_fiscal_NP,
                droitPropriete: actif.droits_propriete,
                eligibiliteDutreil: actif.pourcentage_eligibilite_dutriel,
                bienPersonnelConjoint1PP: actif.valeur_PP.bien_personnel_conjoint1,
                bienCommunPP: actif.valeur_PP.bien_commun_conjoint,
                bienPersonnelConjoint2PP: actif.valeur_PP.bien_personnel_conjoint2,
            });
            return result;
        }, [])
    }

    const destComptTitreFinance = (cts) => {
        const data = conditionCheck(cts);
        return data.reduce((result, ct, index) => {
            result.push({
                open: true,
                intitule: ct.intitule,
                dateValorisation: ct.dateValorisation || '',
                droitPropriete: ct.droits_propriete,
                tauxValeurFiscaleNp: ct.pourcentage_valeur_fiscal_NP,
                bienPersonnelConjoint1PP: ct.valeur_PP.bien_personnel_conjoint1,
                bienPersonnelConjoint2PP: ct.valeur_PP.bien_personnel_conjoint2,
                bienCommunPP: ct.valeur_PP.bien_commun_conjoint,
                actifsImmobiliersTaxablesIFIConjoint1: ct?.actifs_immobiliers_taxables_IFI ? ct?.actifs_immobiliers_taxables_IFI.bien_personnel_conjoint1 : 0,
                actifsImmobiliersTaxablesIFICommun: ct?.actifs_immobiliers_taxables_IFI ? ct?.actifs_immobiliers_taxables_IFI.bien_personnel_conjoint1 : 0,
                actifsImmobiliersTaxablesIFIConjoint2: ct?.actifs_immobiliers_taxables_IFI ? ct?.actifs_immobiliers_taxables_IFI.bien_personnel_conjoint1 : 0,
                decouvertBancaireConjoint1: ct.decouvert_bancaire_garantie_comptes.bien_personnel_conjoint1,
                decouvertBancaireConjoint2: ct.decouvert_bancaire_garantie_comptes.bien_personnel_conjoint2,
                decouvertBancaireCommun: ct.decouvert_bancaire_garantie_comptes.bien_commun_conjoint
            })
            return result;
        }, [])
    }

    const destPeaFinance = (peas) => {
        const data = conditionCheck(peas);
        return data.reduce((result, pea, index) => {
            result.push({
                open: true,
                intitule: pea.intitule,
                dateValorisation: pea.dateValorisation || '',
                droitPropriete: pea.droits_propriete,
                valeurPPConjoint1: pea.valeur_PP.bien_personnel_conjoint1,
                valeurPPConjoint2: pea.valeur_PP.bien_personnel_conjoint2,
                decouvertBancaireConjoint1: pea.decouvert_bancaire_garantie_comptes.bien_personnel_conjoint1,
                decouvertBancaireConjoint2: pea.decouvert_bancaire_garantie_comptes.bien_personnel_conjoint2,
                gainLatentConjoint1: pea.montant_gains_latents.bien_personnel_conjoint1,
                gainLatentConjoint2: pea.montant_gains_latents.bien_personnel_conjoint2,
            })
            return result;
        }, [])
    }

    const destPerinAssurance = (perins) => {
      const data = conditionCheck(perins);
      return data.reduce((result, perin, index) => {
        result.push({
            open: true,
            intitule: perin.intitule,
            dateValorisation: perin.dateValorisation || '',
            valeurPPConjoint1: perin.valorisation_client1,
            valeurPPConjoint2: perin.valorisation_client2,
        })
        return result;
    }, [])
    }

    const destNonCote = (cts) => {
        const data = conditionCheck(cts);
        return data.reduce((result, ct, index) => {
            result.push({
                open: true,
                intitule: ct.intitule,
                dateValorisation: ct.dateValorisation || '',
                droitPropriete: ct.droits_propriete,
                tauxValeurFiscaleNp: ct.pourcentage_valeur_fiscal_NP,
                bienPersonnelConjoint1PP: ct.valeur_PP.bien_personnel_conjoint1,
                bienPersonnelConjoint2PP: ct.valeur_PP.bien_personnel_conjoint2,
                bienCommunPP: ct.valeur_PP.bien_commun_conjoint,
                decouvertBancaireConjoint1: ct.decouvert_bancaire_garantie_comptes.bien_personnel_conjoint1,
                decouvertBancaireConjoint2: ct.decouvert_bancaire_garantie_comptes.bien_personnel_conjoint2,
                decouvertBancaireCommun: ct.decouvert_bancaire_garantie_comptes.bien_commun_conjoint
            })
            return result;
        }, [])
    }

    const destCapiFinance = (capis) => {
        const data = conditionCheck(capis);
        return data.reduce((result, capi, index) => {
            result.push({
                open: true,
                intitule: capi.intitule,
                dateValorisation: capi.dateValorisation || '',
                droitPropriete: capi.droits_propriete,
                tauxValeurFiscaleNp: capi.pourcentage_valeur_fiscal_NP,
                bienPersonnelConjoint1PP: capi.valeur_PP.bien_personnel_conjoint1,
                bienPersonnelConjoint2PP: capi.valeur_PP.bien_personnel_conjoint2,
                bienCommunPP: capi.valeur_PP.bien_commun_conjoint,
                actifsImmobiliersTaxablesIFIConjoint1: capi?.actifs_immobiliers_taxables_IFI ? capi.actifs_immobiliers_taxables_IFI.bien_personnel_conjoint1 : 0,
                actifsImmobiliersTaxablesIFICommun: capi?.actifs_immobiliers_taxables_IFI ? capi.actifs_immobiliers_taxables_IFI.bien_personnel_conjoint1 : 0,
                actifsImmobiliersTaxablesIFIConjoint2: capi?.actifs_immobiliers_taxables_IFI ? capi.actifs_immobiliers_taxables_IFI.bien_personnel_conjoint1: 0,
                decouvertBancaireConjoint1: capi.decouvert_bancaire_garantie_contrat_capi.bien_personnel_conjoint1,
                decouvertBancaireConjoint2: capi.decouvert_bancaire_garantie_contrat_capi.bien_personnel_conjoint2,
                decouvertBancaireCommun: capi.decouvert_bancaire_garantie_contrat_capi.bien_commun_conjoint,
            })
            return result;
        }, [])

    }

    const destAutreActifFinance = (autres) => {
        const data = conditionCheck(autres);
        return data.reduce((result, autre, index) => {
            result.push({
                open: true,
                intitule: autre.intitule,
                dateValorisation: autre.dateValorisation || '',
                droitPropriete: autre.droits_propriete,
                tauxValeurFiscaleNp: autre.pourcentage_valeur_fiscal_NP,
                bienPersonnelConjoint1PP: autre.valeur_PP.bien_personnel_conjoint1,
                bienPersonnelConjoint2PP: autre.valeur_PP.bien_personnel_conjoint2,
                bienCommunPP: autre.valeur_PP.bien_commun_conjoint,
                actifsImmobiliersTaxablesIFIConjoint1: autre?.actifs_immobiliers_taxables_IFI ? autre.actifs_immobiliers_taxables_IFI.bien_personnel_conjoint1 : 0,
                actifsImmobiliersTaxablesIFICommun: autre?.actifs_immobiliers_taxables_IFI ? autre.actifs_immobiliers_taxables_IFI.bien_personnel_conjoint1 : 0,
                actifsImmobiliersTaxablesIFIConjoint2: autre?.actifs_immobiliers_taxables_IFI ? autre.actifs_immobiliers_taxables_IFI.bien_personnel_conjoint1 : 0,
                decouvertBancaireConjoint1: autre.passif_associe.bien_personnel_conjoint1,
                decouvertBancaireConjoint2: autre.passif_associe.bien_personnel_conjoint2,
                decouvertBancaireCommun: autre.passif_associe.bien_commun_conjoint,
            })
            return result;
        }, [])
    }

    const destPassifFinance = (passifs) => {
        const data = conditionCheck(passifs);
        return data.reduce((result, passif, index) => {
            result.push({
                open: true,
                intitule: passif.intitule,
                montantPassifConjoint1: passif.montant_passif.passif_personnel_conjoint1,
                montantPassifConjoint2: passif.montant_passif.passif_personnel_conjoint2,
                montantPassifCommun: passif.montant_passif.passif_commun_conjoint,
            })
            return result;
        }, [])
    }

    const destAVFinance = (avs) => {
        const data = conditionCheck(avs);
        return data.reduce((result, av, index) => {
            result.push({
                open: true,
                intitule: av.intitule,
                dateValorisation: av.dateValorisation || '',
                dateEffetContrat: av.dateEffetContrat || '',
                souscripteurAssure: av.souscripter,
                droitPropriete: av.droits_propriete,
                valeurPPConjoint1: av.valeur_PP.bien_commun_conjoint,
                actifsImmobiliersTaxablesIFIConjoint1: av?.actifsImmobiliersTaxablesIFI ? av.actifsImmobiliersTaxablesIFI.bien_commun_conjoint : 0,
                decouvertBancaireConjoint1: av.decouvert_bancaire_garantie_CAV.bien_commun_conjoint,
                montantGainConjoint1: av.montant_gains_soumis_aux_prelevements_sociaux.bien_commun_conjoint,
                fiscalitesTransmission: [{
                    tauxRepartitionCgi: av.fiscalite_transmission_denouement_deces_conjoint1.renseigner_repartition.taux_990ICGI,
                    tauxRepartition757B: av.fiscalite_transmission_denouement_deces_conjoint1.renseigner_repartition.taux_757B,
                    tauxRepartitionExoneration: av.fiscalite_transmission_denouement_deces_conjoint1.renseigner_repartition.exoneration,
                    montantPrimes: av.fiscalite_transmission_denouement_deces_conjoint1.montant_primes_versees_apres_70_ans
                }, {
                    tauxRepartitionCgi: av.fiscalite_transmission_denouement_deces_conjoint2.renseigner_repartition.taux_990ICGI,
                    tauxRepartition757B: av.fiscalite_transmission_denouement_deces_conjoint2.renseigner_repartition.taux_757B,
                    tauxRepartitionExoneration: av.fiscalite_transmission_denouement_deces_conjoint2.renseigner_repartition.exoneration,
                    montantPrimes: av.fiscalite_transmission_denouement_deces_conjoint2.montant_primes_versees_apres_70_ans
                }]
            })
            return result;
        }, [])
    }

    const destAssDeces = (assDeces) => {
      const data = conditionCheck(assDeces);
      return data.reduce((result, assDece, index) => {
          result.push({
              open: true,
              intitule: assDece.intitule,
              dateValorisation: assDece.dateValorisation || '',
              dateEffetContrat: assDece.dateEffetContrat || '',
              souscripteurAssure: assDece.souscripter,
              droitPropriete: assDece.droits_propriete,
              valeurPPConjoint1: assDece.capitaux_deces,
              fiscaliteDeces: assDece.fiscalite_deces,
              montantGainConjoint1: assDece.primes_versees,
          })
          return result;
      }, [])
  }
    const re_passif = immo.passif;
    const immobil = {
        residencesPrincipales: destResPpalImmo(immo.autres_actifs_immo_liste, "autre"),
        residencePrincipaleCollapse: destResPpalImmo(immo.residence_principale_liste),
        residenceSecondaires: destResAutres(immo.liste_residences_secondaires, "residence_secondaires"),
        immobilierLocatif: destResAutres(immo.liste_immobilier_locatif, "immobilier_locatif"),
        biensRurauxEtForestiers: destResAutres(immo.liste_biens_ruraux_ou_forestiers, "biens_ruraux_ou_forestiers"),
        monumentsHistoriques: destResAutres(immo.liste_monuments_historiques, "monuments_historiques"),
        scpi: destResAutres(immo.liste_scpi, "scpi"),
        societesImmobiliers: destResAutres(immo.liste_societe_immobiliere, "societe_immobiliere"),
    }

    const patrimoineGlobal = {
        avecImportClient: pat_global.avecImportClient || 0,
        date_import: pat_global.date_import || null,
        situationFamiliale: pat_global.situation_familiale,
        repartitionBrutNet: pat_global.repartitionBrutNet,
        versionFicheClient: pat_global.versionFicheClient || null
    }

    const nonCotes = financier.non_cotes_liste !== undefined ? destNonCote(financier.non_cotes_liste) : [];

    const allData = {
        indexTab: 0,
        affichage: pat_global.affichage,
        situationDu: pat_global.situation_au,
        patrimoineGlobal,
        professionnel: {
            actifsProfessionnels: pro_actif_pro
        },
        immobilier: immobil,
        financier: {
            comptesEpargnesLiquidites: destCompteEpargneLiquiditesList(financier.compte_epargne_liquidites_liste),
            comptesTitres: destComptTitreFinance(financier.comptes_titres_pea_liste.compte_titre),
            peaPmes: destPeaFinance(financier.comptes_titres_pea_liste.compte_pea),
            nonCotes: nonCotes,
            capis: destCapiFinance(financier.contrat_capitalisation_liste),
            avs: destAVFinance(financier.contrat_assurance_vie_liste),
            assuranceDeces: destAssDeces(financier.liste_assurance_deces),
            perins: destPerinAssurance(financier.liste_PERin_assurance),
            autresActifsFinanciers: destAutreActifFinance(financier.autres_actifs_financiers_liste),
            passifs: destPassifFinance(financier.passif_liste)

        },
        autres: {
            actifs: destAutreAutresListeActif(autres.autres_actifs_liste)
        }
    }
    return {
        simulation,
        allData,
        post_it
    }
};

export const saveIfi = async (element, id) => {
    const url = id ? `${ifi_uri}/${id}` : ifi_uri;
    const query = await fetch(url, {
        method: id ? 'PUT' : 'POST',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${localStorage.getItem("token")}`
        },
        body: JSON.stringify(element),
        cache: 'no-cache'
    })

    return query.json();
}

export const getIfi = async (id) => {
    const query = await fetch(`${ifi_uri}/findOne/${id}`, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${localStorage.getItem("token")}`
        }
    })

    return query.json();
}

export const createIfiFromResponse = async (data) => {
    /******************* SUITE A L'AJOUT DE NOUVELLES DONNEES *********************** */
    const anneeSimulation = new Date(data.date_simulation).getFullYear();

    // Absence de la donnée "import_bilan_patrimonial"
    if([null, undefined].includes(data.form1?.configuration?.import_bilan_patrimonial)){
        const avecImportBP = data.form1?.configuration?.idBilan && data.form1?.configuration?.bilan_patrimonial;
        data.form1.configuration.import_bilan_patrimonial = avecImportBP ? 1 : 0;
    }

    // Absence de la donnée "import_fiscalite_revenus"
    if([null, undefined].includes(data.form1?.configuration?.import_fiscalite_revenus)){
        const avecImportFiscaliteIR = data.form1?.configuration?.idFiscalite && data.form1?.configuration?.fiscalite;
        data.form1.configuration.import_fiscalite_revenus =avecImportFiscaliteIR ? 1 : 0;
    }

    // Année fiscale
    if(!data.anneeFiscale){
        if(anneeSimulation < 2023) data.anneeFiscale = 2022;

        if(anneeSimulation === 2023) data.anneeFiscale = 2023;

        data.affichageMessage = 1;
    }
    else{
        data.anneeFiscale = parseInt(data.anneeFiscale);
        data.affichageMessage = parseInt(data.affichageMessage);
    }

    // Frais professionnels sur les revenus de capitaux mobiliers
    if(data.form5AvecImport?.revenus_et_produits?.revenus_de_capitaux_mobiliers?.frais_professionnels === undefined){
        data.form5AvecImport.revenus_et_produits.revenus_de_capitaux_mobiliers.frais_professionnels = 0;
        data.form5Copie.revenus_et_produits.revenus_de_capitaux_mobiliers.frais_professionnels = 0;
    }

    // Distribution des impôts (IR au taux proportionnel + IR au barème progressif)
    if(data.form5?.impot_sur_les_revenus?.ir?.proportionnel === undefined){
        data.form5.impot_sur_les_revenus.ir.proportionnel = { montant_net: 0 };
        data.form5AvecImport.impot_sur_les_revenus.ir.proportionnel = { montant_net: 0 };
        data.form5Copie.impot_sur_les_revenus.ir.proportionnel = { montant_net: 0 };
    }

    // rp_add dans form2 et form2Copie
    if(data.form2 && data.form2.rp_added === undefined) data.form2.rp_added = null;
    if(data.form2Copie && data.form2Copie.rp_added === undefined) data.form2Copie.rp_added = null;

    // Début des modifications pour les anciennes simulations
    const client = {
        id: undefined,
        nom: undefined
    }

    if(data.form1?.configuration?.client){
        const query = await fetch(`${restApiUri}/api/client/${data.form1.configuration.client}`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
        });

        const response = await query.json();

        client.id = response?.client?.id;
        client.nom = `${response?.client?.nom} ${response?.client?.prenom}`;
    }

    console.log("FORM1 CONFIGURATION", data.form1.configuration);

    return {
        ifi: {
            idSimulation: data.id,
            anneeFiscale: data.anneeFiscale,
            affichageMessage: data.affichageMessage,
            form1: data.form1,
            form2: data.form2,
            form3: data.form3,
            form5: data.form5,
            form5AvecImport: data.form5AvecImport,
            form5Copie: data.form5Copie,
            form6: data.form6,
            form6Copie: data.form6Copie,
            form2Copie: data.form2Copie
        },
        simmulation: {
            clientId: (data.form1?.configuration?.client) || (data.client ? data.client.id : null),
            commentaire: data.commentaire,
            terme_simulation: data.terme,
            identifiant_simulation: data.identifiant_simulation,
            userId: data.userId,
            idClient: client.id,
            nomClient: client.nom
        },
    }
}

export const cloneIfi = (data) => {
    const dataToSend = {
        userId: JSON.parse(localStorage.getItem("user"))["id"],
        clientId: data.client && data.client.id ? data.client.id : null,
        ...data,
    };

    delete dataToSend['client']
    return dataToSend
}

export const delIfi = async (id) => {
    const query = await fetch(`${ifi_uri}/${id}`, {
        method: "DELETE",
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            //Authorization: `Bearer ${localStorage.getItem("token")}`
        }
    });
    return query.json();
}

export const getFiscaliteByClient = async (id) => {
    const query = await fetch(`${fiscalite_uri}/findbyclient/${id}`, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${localStorage.getItem("token")}`
        }
    })
    return query.json();
}

export const getBilanByClient = async (id) => {
    const query = await fetch(`${restApiUri}/api/bilan_patrimonial/findByClient/${id}`, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${localStorage.getItem("token")}`
        }
    })

    return query.json();
}
