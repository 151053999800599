import React from 'react';

export const border = {
    border: '1px solid #FFFFFF'
};

export const LineValue = ({ col1, col2, col3 }) => {
    return (
        <tr>
            <td style={{ ...border, background: 'rgb(244,246,248)', textAlign: 'center' }} >{col1}</td>
            <td style={{ ...border, background: 'rgb(244,246,248)', textAlign: 'center' }} >{col2}</td>
            <td style={{ ...border, background: 'rgb(244,246,248)', textAlign: 'center' }} >{col3}</td>
        </tr>
    )
}

const TableAbattementDureeDetention = () => {
    return (
        <table cellPadding={4} cellSpacing={10} style={{ fontSize: 13, width: '100%' }} >
            <tr>
                <td style={{ ...border, width: '38%', background: 'rgb(132,151,176)', fontWeight: 'bold', color: '#FFFFFF', textAlign: 'center' }} >Délai de détention</td>
                <td style={{ ...border, width: '31%', background: 'rgb(132,151,176)', fontWeight: 'bold', color: '#FFFFFF', textAlign: 'center' }} >IR</td>
                <td style={{ ...border, width: '31%', background: 'rgb(132,151,176)', fontWeight: 'bold', color: '#FFFFFF', textAlign: 'center' }} >PS</td>
            </tr>

            <LineValue 
                col1={<>Jusqu’à la 5e année</>}
                col2="0 %"
                col3="0 %"
            />
            
            <LineValue 
                col1="6e année"
                col2="6 %"
                col3="1,65 %"
            />
            <LineValue 
                col1="7e année"
                col2="12 %"
                col3="3,3 %"
            />
            <LineValue 
                col1="8e année"
                col2="18 %"
                col3="4,95 %"
            />
            <LineValue 
                col1="9e année"
                col2="24 %"
                col3="6,6 %"
            />
            <LineValue 
                col1="10e année"
                col2="30 %"
                col3="8,25 %"
            />
            <LineValue 
                col1="11e année"
                col2="36 %"
                col3="9,9 %"
            />
            <LineValue 
                col1="12e année"
                col2="42 %"
                col3="11,55 %"
            />
            <LineValue 
                col1="13e année"
                col2="48 %"
                col3="13,2 %"
            />
            <LineValue 
                col1="14e année"
                col2="54 %"
                col3="14,85 %"
            />
            <LineValue 
                col1="15e année"
                col2="60 %"
                col3="16,5 %"
            />
            <LineValue 
                col1="16e année"
                col2="66 %"
                col3="18,15 %"
            />
            <LineValue 
                col1="17e année"
                col2="72 %"
                col3="19,8 %"
            />
        </table>
    )
}

export default TableAbattementDureeDetention;