export const plusValueImmobiliereDefaultData = JSON.parse(JSON.stringify({
    "config": {
        "cession_directe": 0,
        "cession_indirecte": 0,
        "cession_sci_ir": 0
    },
    "form1": {
        "type_cession": 0,
        "nature_bien_cede": 0,
        "nb_hectare_vendus": 0,
        "choix_duree": 0,
        "nb_annee_pleines_detention": 0,
        "date_cession": "",
        "date_acquisition": "",
        "prix_cession": 0,
        "frais_cession": 0,
        "prix_acquisition": 0,
        "charge_indemnite": 0,
        "frais_acquisition": 0,
        "frais_acquisition_part2": 0,
        "frais_travaux": 0,
        "frais_travaux_part2": 0,
        "frais_voirie": 0,
        "abattemennt_exceptionnel": 0,
        "bien_detenu": 0,
        "situation_familiale": 0,
        "rfr_annee_hors_pv": 0,
        "activer_mecanisme_lissage_cehr": 1,
        "rfr_annee_n_1": 0,
        "rfr_annee_n_2": 0,
        "impact_plafonnement_ifi": 0,
        "construction": {
            "choix_duree": 0,
            "nb_annee_pleines_detention": 0,
            "date_cession": "",
            "date_acquisition": "",
            "prix_cession": 0,
            "frais_cession": 0,
            "prix_acquisition": 0,
            "charge_indemnite": 0,
            "frais_acquisition": 1,
            "frais_acquisition_part2": 0,
            "frais_travaux": 1,
            "frais_travaux_part2": 0,
            "frais_voirie": 0,
        }
    },
    "form2": {
        "type_cession": 1,
        "nature_bien_cede": 0,
        "nb_hectare_vendus": 0,
        "choix_duree": 0,
        "nb_annee_pleines_detention": 0,
        "date_cession": "",
        "date_acquisition": "",
        "prix_cession": 0,
        "frais_cession": 0,
        "prix_acquisition": 0,
        "charge_indemnite": 0,
        "frais_acquisition": 0,
        "frais_acquisition_part2": 0,
        "frais_travaux": 0,
        "frais_travaux_part2": 0,
        "frais_voirie": 0,
        "abattemennt_exceptionnel": 0,
        "bien_detenu": 0,
        "situation_familiale": 0,
        "rfr_annee_hors_pv": 0,
        "activer_mecanisme_lissage_cehr": 1,
        "rfr_annee_n_1": 0,
        "rfr_annee_n_2": 0,
        "impact_plafonnement_ifi": 0,
        "construction": {
            "choix_duree": 0,
            "nb_annee_pleines_detention": 0,
            "date_cession": "",
            "date_acquisition": "",
            "prix_cession": 0,
            "frais_cession": 0,
            "prix_acquisition": 0,
            "charge_indemnite": 0,
            "frais_acquisition": 1,
            "frais_acquisition_part2": 0,
            "frais_travaux": 1,
            "frais_travaux_part2": 0,
            "frais_voirie": 0,
        }
    },
    "form3": {
        "nb_hectare_vendus": 0,
        "choix_duree": 0,
        "nb_annee_pleines_detention": 0,
        "date_cession": "",
        "date_acquisition": "",
        "prix_cession": 0,
        "frais_cession": 0,
        "prix_acquisition": 0,
        "charge_indemnite": 0,
        "frais_acquisition": 0,
        "frais_acquisition_part2": 0,
        "frais_travaux": 1,
        "frais_travaux_part2": 0,
        "frais_voirie": 0,
        "abattemennt_exceptionnel": 0,
        "bien_detenu": 0,
        "situation_familiale": 0,
        "rfr_annee_hors_pv": 0,
        "activer_mecanisme_lissage_cehr": 1,
        "rfr_annee_n_1": 0,
        "rfr_annee_n_2": 0,
        "impact_plafonnement_ifi": 0
    }
}));
