import { CFormGroup, CLabel } from '@coreui/react';
import React, { useContext, useEffect, useState, Fragment } from 'react';
import { InputCurrency, InputSelect, SelectDonation, InputRate } from '../../../../../components/input';
import { CessionContext } from '../../CessionHaut';
import { moveSpaceIntoCurrency } from '../ApportsAvantCession/ApportsAvantCession';
import Loading from '../../../../../components/loader';
import { restApiUri } from '../../../../../config'
import { conditionAffichageEnfant, nombreEnfantsParConjoint } from '../../ProjectionCessionSociete/TableauSynthese/TableauSynthese';
import { negativeCurrency } from '../TableauDonation/TableauDonation';
import { fetchAllResult, getData } from '../../ProjectionCessionSociete/ProjetCessionSociete';
import { FaInfoCircle } from 'react-icons/fa'
import HtmlTooltip from '../../../../Cession/CessionHaut/IdentiteActionnaire/Conjoint/HTMLTooltip/HTMLTooltip'
import { currencyToSend } from '../../../../DonationSuccession/UsufruitNP/UsufruitViager/UsufruitViager'
import InfoBulle from '../../../../../components/InfoBulle/InfoBulle';
import { formatToUniqueId } from '../../../../../utils';

export const structureDonationData = (conjoints, enfants, operations) => {
    const listeDonation = [];
    operations.donations.map((donnee, indice) => {
        if (indice === 0 || (indice === 1 && conjoints[0].marieOuPasce === 'Oui')) {
            let listeEnfants = [];
            let obj = new Object();
            let conj = new Object();

            donnee.enfants.map((data, index) => {
                if (conditionAffichageEnfant(enfants, indice, index)) {
                    let enf = new Object();
                    enf.id = index + 1;
                    enf.nom = enfants[index].prenom !== "" ? enfants[index].prenom : `Enfant ${index + 1}`;
                    enf.annee = parseInt(data.anneeDonation, 10);
                    enf.handicape = enfants[index].handicap ? 1 : 0;
                    enf.valeur_fiscale = typeof data.valeurFiscale === 'string' ? (data.valeurFiscale === "" ? 0 : moveSpaceIntoCurrency(data.valeurFiscale)) : data.valeurFiscale;
                    enf.abattement_fiscale = currencyToSend(data.valeurFiscale) > 0 ? currencyToSend(data.abattementFiscal) : 0;
                    //enf.abattement_fiscale = typeof data.abattementFiscal === 'string' ? (data.abattementFiscal === "" ? 0 : moveSpaceIntoCurrency(data.abattementFiscal)) : data.abattementFiscal;
                    enf.donation_pp = typeof data.donationPP === 'string' ? (data.donationPP === "" ? 0 : moveSpaceIntoCurrency(data.donationPP)) : data.donationPP;
                    enf.donation_np = typeof data.donationNP === 'string' ? (data.donationNP === "" ? 0 : moveSpaceIntoCurrency(data.donationNP)) : data.donationNP;

                    listeEnfants.push(enf);
                }
            });

            conj.nom = `c${indice + 1}`
            conj.nom_veritable = conjoints[indice].nomPersonnalise !== '' ? conjoints[indice].nomPersonnalise : `Conjoint ${indice + 1}`;
            conj.age = conjoints[indice].age === "" ? 0 : parseInt(conjoints[indice].age, 10);
            conj.valeur_fiscale = currencyToSend(conjoints[indice].valeurFiscale) || 0;

            if (indice === 0) conj.marie = conjoints[0].marieOuPasce === 'Oui' ? 1 : 0;

            conj.revenu = typeof conjoints[indice].revenuAnneeCession === 'string' ? (conjoints[indice].revenuAnneeCession === "" ? 0 : moveSpaceIntoCurrency(conjoints[indice].revenuAnneeCession)) : conjoints[indice].revenuAnneeCession;
            conj.donation_pp = typeof conjoints[indice].donationPP === 'string' ? (conjoints[indice].donationPP === "" ? 0 : moveSpaceIntoCurrency(conjoints[indice].donationPP)) : conjoints[indice].donationPP;

            obj["liste_enfant"] = listeEnfants;
            obj["conjoint"] = conj;

            listeDonation.push(obj);
        }
    });

    const end = new Object();
    end["liste_donation"] = listeDonation;
    return end;
};

export const structurationSyntheseDonation = (enfants, conjoints, dataFromFetch, affichage, nomHolding) => {
    // Structuration des conjoints
    const conjointsTab = [];
    const enfantsPPTab = [];
    const enfantsNPTab = [];

    const holding = dataFromFetch.holding;
    const newco = dataFromFetch.newco;

    const sousPersonnePhysique = dataFromFetch.sous_total_personne_physique;
    const sousPersonneMorale = dataFromFetch.sous_total_personne_morale;

    const findPosByIndex = (prenom) => {
        return enfants.findIndex((element) => element.prenom === prenom);
    };

    [dataFromFetch.conjoint1_pp, dataFromFetch.conjoint2_pp].map((data, index) => {
        if (index === 0 || (index === 1 && conjoints[0].marieOuPasce === 'Oui')) {
            let conj = new Object();
            conj.actionnaireFamilial = `${affichage ? `${data.nom !== "" ? data.nom : `Conjoint ${index + 1}`}` : `Conjoint ${index + 1}`} PP`;
            conj.nomPersonnalise = data.nom;
            conj.valeurTitres = negativeCurrency(data.valeur_titre);
            conj.apportTitres = negativeCurrency(data.apport_titre);
            conj.donationTitres = negativeCurrency(data.donation_titre);
            conj.soldeValeurTitres = negativeCurrency(data.solde_valeur_titre);

            conjointsTab.push(conj);
        }
    });

    // Structuration des Enfants PP
    dataFromFetch.table_enfant_pp.map((data, index) => {
        let enf = new Object();
        enf.actionnaireFamilial = `${affichage ? `${data.nom !== "" ? data.nom : `Enfant ${index + 1}`}` : `Enfant ${index + 1}`} PP`;
        enf.nomPersonnalise = data.nom;
        enf.valeurTitres = negativeCurrency(data.valeur_titre);
        enf.apportTitres = negativeCurrency(data.apport_titre);
        enf.donationTitres = negativeCurrency(data.donation_titre);
        enf.soldeValeurTitres = negativeCurrency(data.solde_valeur_titre);

        enfantsPPTab.push(enf);
    });

    // Structuration des Enfants USUF
    [dataFromFetch.table_enfant_np_conjoint1, dataFromFetch.table_enfant_np_conjoint2].map((donnee, indice) => {
        if (indice === 0 || (indice === 1 && conjoints[0].marieOuPasce === 'Oui')) {
            let tabNP = [];

            donnee.map((data, index) => {
                let i = findPosByIndex(data.nom);
                let enf = new Object();

                enf.id = data.id;
                enf.actionnaireFamilial = `${affichage ? `${conjoints[indice].nomPersonnalise !== "" ? conjoints[indice].nomPersonnalise : `Conjoint ${indice + 1}`}` : `Conjoint ${indice + 1}`} USUF / ${affichage ? `${data.nom !== "" ? data.nom : `Enfant ${data.id}`}` : `Enfant ${data.id}`} NP`;
                enf.nomPersonnalise = `${conjoints[indice].nomPersonnalise} USUF / ${data.nom} NP`;
                enf.valeurTitres = negativeCurrency(data.valeur_titre);
                enf.apportTitres = negativeCurrency(data.apport_titre);
                enf.donationTitres = negativeCurrency(data.donation_titre);
                enf.soldeValeurTitres = negativeCurrency(data.solde_valeur_titre);

                tabNP.push(enf);
            });

            enfantsNPTab.push(tabNP);
        }
    });

    // Structuration des sommes
    // Enfants PP
    const sommePP = new Object();
    sommePP.actionnaireFamilial = `Enfants PP`;
    sommePP.nomPersonnalise = "";
    sommePP.valeurTitres = negativeCurrency(dataFromFetch.somme_enfant_pp.valeur_titre);
    sommePP.apportTitres = negativeCurrency(dataFromFetch.somme_enfant_pp.apport_titre);
    sommePP.donationTitres = negativeCurrency(dataFromFetch.somme_enfant_pp.donation_titre);
    sommePP.soldeValeurTitres = negativeCurrency(dataFromFetch.somme_enfant_pp.solde_valeur_titre);

    // Enfants NP1
    const sommeNP1 = new Object();
    // sommeNP1.actionnaireFamilial = `Conjoint 1 USUF / Enfants NP`;
    sommeNP1.actionnaireFamilial = `${affichage ? `${conjoints[0].nomPersonnalise !== "" ? conjoints[0].nomPersonnalise : `Conjoint 1`}` : `Conjoint 1`} USUF / Enfants NP`;
    sommeNP1.nomPersonnalise = "";
    sommeNP1.valeurTitres = negativeCurrency(dataFromFetch.somme_enfant_np_c1.valeur_titre);
    sommeNP1.apportTitres = negativeCurrency(dataFromFetch.somme_enfant_np_c1.apport_titre);
    sommeNP1.donationTitres = negativeCurrency(dataFromFetch.somme_enfant_np_c1.donation_titre);
    sommeNP1.soldeValeurTitres = negativeCurrency(dataFromFetch.somme_enfant_np_c1.solde_valeur_titre);

    // Enfants NP2
    const sommeNP2 = new Object();
    if (conjoints[0].marieOuPasce === 'Oui') {
        // sommeNP2.actionnaireFamilial = `Conjoint 2 USUF / Enfants NP`;
        sommeNP2.actionnaireFamilial = `${affichage ? `${conjoints[1].nomPersonnalise !== "" ? conjoints[1].nomPersonnalise : `Conjoint 2`}` : `Conjoint 2`} USUF / Enfants NP`;
        sommeNP2.nomPersonnalise = "";
        sommeNP2.valeurTitres = negativeCurrency(dataFromFetch.somme_enfant_np_c2.valeur_titre);
        sommeNP2.apportTitres = negativeCurrency(dataFromFetch.somme_enfant_np_c2.apport_titre);
        sommeNP2.donationTitres = negativeCurrency(dataFromFetch.somme_enfant_np_c2.donation_titre);
        sommeNP2.soldeValeurTitres = negativeCurrency(dataFromFetch.somme_enfant_np_c2.solde_valeur_titre);
    }

    // VALEURS pour le SOUS-TOTAL actionnaires personnes physiques
    const personnePhysique = new Object();
    personnePhysique.valeurTitres = negativeCurrency(sousPersonnePhysique.valeur_titre);
    personnePhysique.apportTitres = negativeCurrency(sousPersonnePhysique.apport_titre);
    personnePhysique.donationTitres = negativeCurrency(sousPersonnePhysique.donation);
    personnePhysique.soldeValeurTitres = negativeCurrency(sousPersonnePhysique.solde_valeur_titre);

    // VALEURS pour le HOLDING
    const hold = new Object();
    hold.actionnaireFamilial = affichage ? (nomHolding !== "" ? nomHolding : 'HOLDING IS') : 'HOLDING IS';
    hold.valeurTitres = negativeCurrency(holding.valeur_titre);
    hold.soldeValeurTitres = negativeCurrency(holding.solde_valeur_titre);

    // VALEURS pour le newCo
    const newCo = new Object();
    newCo.apportTitres = negativeCurrency(newco.apport_titre);
    newCo.soldeValeurTitres = negativeCurrency(newco.solde_valeur_titre);

    // VALEURS pour le SOUS-TOTAL actionnaires personnes morales
    const personneMorale = new Object();
    personneMorale.valeurTitres = negativeCurrency(sousPersonneMorale.valeur_titre);
    personneMorale.apportTitres = negativeCurrency(sousPersonneMorale.apport_titre);
    personneMorale.soldeValeurTitres = negativeCurrency(sousPersonneMorale.solde_valeur_titre);

    // Somme de tous
    const sommeTous = new Object();
    sommeTous.apportTitres = negativeCurrency(dataFromFetch.somme_tous.apport_titre);
    sommeTous.valeurTitres = negativeCurrency(dataFromFetch.somme_tous.valeur_titre);
    sommeTous.donationTitres = negativeCurrency(dataFromFetch.somme_tous.donation);
    sommeTous.soldeValeurTitres = negativeCurrency(dataFromFetch.somme_tous.solde_valeur_titre);

    const somme = {
        pp: sommePP,
        np1: sommeNP1,
        np2: sommeNP2,
        somme: sommeTous
    }

    return {
        conjoints: conjointsTab,
        enfantsPP: enfantsPPTab,
        enfantsNP: enfantsNPTab,
        personnePhysique,
        holding: hold,
        newco: newCo,
        personneMorale,
        somme
    }

};

export const calculDonation = async (conjoints, enfants, operations) => {
    const query = await fetch(`${restApiUri}/api/cession/initCalculDonation`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${localStorage.getItem("token")}`
        },
        body: JSON.stringify({
            ...structureDonationData(conjoints, enfants, operations),
        }),
        cache: 'no-cache'
    });

    return await query.json();
};

export const abattementFiscalSelonAnneeDonation = (annee) => {
    let options = []
    annee = parseInt(annee)
    const year = parseInt(new Date().getFullYear())

    if (isNaN(annee)) {
        options = [0];
    }
    else {
        if (annee === 2006) {
            options = [50000]
        }
        if (annee === 2007) {
            options = [50000, 150000]
        }
        if ([2008, 2009, 2010, 2011].includes(annee)) {
            options = [150000]
        }
        if (annee === 2012) {
            options = [100000, 150000]
        }
        if (annee >= 2013) {
            options = [100000]
        }
    }

    return options;
};

const DonationParConjoint = (props) => {
    const { cessionHaut, setCessionHaut } = useContext(CessionContext);
    const { identiteActionnaire, operationsAvantCession: operations, projetCessionSociete: projet } = cessionHaut;
    const { conjoints, enfants, affichageNomPersonnalise: affichage } = identiteActionnaire;
    const childs = props.donation;
    const nombreEnfants = props.nombreEnfants;
    const { prenomConjoint1, prenomConjoint2 } = props;

    const classFormulaire = "col-lg-12 col-md-12 col-sm-12";// mr-2

    const [loading, setLoading] = useState(false);

    const yearsList = () => {
        let yearStart = 2006;
        let currentYear = new Date().getFullYear() + 1;
        let length = currentYear - yearStart;
        const tabYear = [];

        for (let i = 0; i < length; i++) {
            tabYear.push(yearStart);
            yearStart++;
        }

        return tabYear;
    }

    const [pp, setPP] = useState(0);
    const [np, setNP] = useState(0);

    const marginTopBetweenForm = 10;

    const conditionAffichageConjoint2 = () => {
        const conjointIndex = props.number === 1 ? 0 : 1;
        if (conjointIndex === 1 || (conjointIndex === 0 && conjoints[0].marieOuPasce === 'Oui')) return true;
        else return false;
    };

    const findIndexByPrenom = (prenom) => {
        return enfants.findIndex((element) => element.prenom === prenom);
    };

    const abattementFiscalRealValue = (annee) => {
        let abattement = 0;
        annee = parseInt(annee, 10);
        if ([2006, 2007].includes(annee)) abattement = 50000;

        if ([2008, 2009, 2010, 2011].includes(annee)) abattement = 150000;

        if (annee >= 2012) abattement = 100000;

        return abattement;
    };

    const handleEnfantsChange = async (event, prop, index) => {
        const { value } = event.target;
        const conjoint = props.number === 1 ? 0 : 1;
        if(prop === 'conjoint'){
            //conjoints[conjoint].fraisDonation = value;
            identiteActionnaire.conjoints[conjoint].donationPP = value;
        }
        else if(prop === 'valeurFiscaleConjoint'){
            identiteActionnaire.conjoints[conjoint].valeurFiscale = value;
        }
        else{
            operations.donations[conjoint].enfants[index][prop] = value;

            if(prop === 'valeurFiscale'){
                if(currencyToSend(value) === 0){
                    operations.donations[conjoint].enfants[index].abattementFiscal = 0;
                }
                else{
                    if(currencyToSend(operations.donations[conjoint].enfants[index].abattementFiscal) === 0){
                        operations.donations[conjoint].enfants[index].abattementFiscal = abattementFiscalRealValue(currencyToSend(operations.donations[conjoint].enfants[index].anneeDonation));
                    }
                }
            }

            if (prop === 'anneeDonation') {
                operations.donations[conjoint].enfants[index]['abattementFiscal'] = abattementFiscalRealValue(value);
            }
        }

        setLoading(true);
        const response = await getData(fetchAllResult(conjoints, enfants, projet, operations));

        const donationConjoint = response.donation[conjoint].tableDonationConjoint;
        const dataTableDonation = response.table_donation_avant_cession;
        const nb = nombreEnfantsParConjoint(enfants, conjoint);

        const soldeValeurTitres = response.situation_apres_apport_et_avant_donation[conjoint].table_apport;
        setPP(soldeValeurTitres[nb].solde_valeur_des_titres_en_pleine_propriete);
        setNP(soldeValeurTitres[nb].solde_valeur_des_titres_en_usufruit);

        // Frais et droits de donation pour le Conjoint
        identiteActionnaire.conjoints[conjoint].fraisDonation = Math.round(donationConjoint.frais_et_donation);
        identiteActionnaire.conjoints[conjoint].tauxMarginalImposition = Math.round(donationConjoint.taux_marginal);

        // Frais et droits de donation, Solde valeur des titres PP et USUF des enfants
        const donationEnfants = response.donation[conjoint].tableDonationEnfant;

        donationEnfants.map((data, indice) => {
            // Auparavant c'était .enfants[indice]
            operations.donations[conjoint].enfants[findIndexByPrenom(data.nom)]['fraisDonation'] = Math.round(data.frais_et_donation);
            operations.donations[conjoint].enfants[findIndexByPrenom(data.nom)]['tauxMarginalImposition'] = Math.round(data.taux_marginal);
            operations.donations[conjoint].enfants[findIndexByPrenom(data.nom)]['soldeValeurTitresPP'] = Math.round(soldeValeurTitres[indice].solde_valeur_des_titres_en_pleine_propriete);
            operations.donations[conjoint].enfants[findIndexByPrenom(data.nom)]['soldeValeurTitresUSUF'] = Math.round(soldeValeurTitres[indice].solde_valeur_des_titres_en_usufruit);
        });

        // Tableau des donations avant cession
        operations.synthese = structurationSyntheseDonation(enfants, conjoints, dataTableDonation, affichage, projet.holding.nomPersonnalise);

        setLoading(false);
        setCessionHaut((prevState) => ({
            ...prevState,
            operationsAvantCession: operations,
            identiteActionnaire: identiteActionnaire
        }));
    };

    useEffect(() => {
        const conjoint = props.number === 1 ? 0 : 1;
        setLoading(true);
        getData(fetchAllResult(conjoints, enfants, projet, operations))
            .then((response) => {
                const donationConjoint = response.donation[conjoint].tableDonationConjoint;
                const dataTableDonation = response.table_donation_avant_cession;
                const nb = nombreEnfantsParConjoint(enfants, conjoint);

                const soldeValeurTitres = response.situation_apres_apport_et_avant_donation[conjoint].table_apport;
                setPP(soldeValeurTitres[nb].solde_valeur_des_titres_en_pleine_propriete);
                setNP(soldeValeurTitres[nb].solde_valeur_des_titres_en_usufruit);

                // Donation et PRORATA pour le Conjoint1
                identiteActionnaire.conjoints[conjoint].fraisDonation = Math.round(donationConjoint.frais_et_donation);
                identiteActionnaire.conjoints[conjoint].tauxMarginalImposition = Math.round(donationConjoint.taux_marginal);

                // Donation et PRORATA pour le Conjoint2
                const donationEnfants = response.donation[conjoint].tableDonationEnfant;
                donationEnfants.map((data, indice) => {
                    operations.donations[conjoint].enfants[findIndexByPrenom(data.nom)]['fraisDonation'] = Math.round(data.frais_et_donation);
                    operations.donations[conjoint].enfants[findIndexByPrenom(data.nom)]['soldeValeurTitresPP'] = Math.round(soldeValeurTitres[indice].solde_valeur_des_titres_en_pleine_propriete);
                    operations.donations[conjoint].enfants[findIndexByPrenom(data.nom)]['soldeValeurTitresUSUF'] = Math.round(soldeValeurTitres[indice].solde_valeur_des_titres_en_usufruit);
                    operations.donations[conjoint].enfants[findIndexByPrenom(data.nom)]['tauxMarginalImposition'] = Math.round(data.taux_marginal);

                });

                // Tableau des donations avant cession a
                operations.synthese = structurationSyntheseDonation(enfants, conjoints, dataTableDonation, affichage, projet.holding.nomPersonnalise);

                setLoading(false);
                setCessionHaut((prevState) => ({
                    ...prevState,
                    operationsAvantCession: operations,
                    identiteActionnaire: identiteActionnaire
                }));
            })
            .catch((error) => console.error(error))
            .finally(() => setLoading(false))
    }, [
        // Au cas où les apports des titres des actionnaires changent
        cessionHaut.identiteActionnaire.conjoints[0].apportTitres,
        cessionHaut.identiteActionnaire.conjoints[1].apportTitres,
        JSON.stringify(cessionHaut.projetCessionSociete.enfantsFamiliauxPP),
        JSON.stringify(cessionHaut.projetCessionSociete.enfantsFamiliauxDP[0].enfants),
        JSON.stringify(cessionHaut.projetCessionSociete.enfantsFamiliauxDP[1].enfants),
    ]);

    // Donations par le CONJOINT 1
    /*useEffect(() => {
        const enfs = [ ...cessionHaut.operationsAvantCession.donations[0].enfants ];
        enfs.map((data, index) => {
            if(currencyToSend(data.valeurFiscale) === 0){
                enfs[index].abattementFiscal = 0;
            }
        });

        operations.donations[0].enfants = enfs;
        setCessionHaut((prevState) => {
            return {
                ...prevState,
                operations
            }
        })
    }, [
        JSON.stringify(cessionHaut.operationsAvantCession.donations[0].enfants)
    ])

    // Donations par le CONJOINT 2
    useEffect(() => {
        const enfs = [ ...cessionHaut.operationsAvantCession.donations[1].enfants ];
        enfs.map((data, index) => {
            if(currencyToSend(data.valeurFiscale) === 0){
                enfs[index].abattementFiscal = 0;
            }
        });

        operations.donations[1].enfants = enfs;
        setCessionHaut((prevState) => {
            return {
                ...prevState,
                operations
            }
        })
    }, [
        JSON.stringify(cessionHaut.operationsAvantCession.donations[1].enfants),
    ])*/

    return (
        <div style={{ borderLeft: props.number == 1 ? "4px solid #179DC7" : "4px solid #F47F4F", borderTopLeftRadius: 6, borderBottomLeftRadius: 6 }}>
            {loading && <Loading />}
            <div style={{ backgroundColor: props.number == 1 ? "#179DC7" : "#F47F4F", width: 300, padding: '3px 3px 3px 30px', color: "#fff", fontWeight: "bold", fontSize:15 }}>
                DONATION PAR {props.number === 1 ? ["Conjoint 1"].includes(prenomConjoint1) ? "LE" : "" : ["Conjoint 2"].includes(prenomConjoint2) ? "LE" : ""} {props.number === 1 ? prenomConjoint1.toString().toUpperCase() : prenomConjoint2.toString().toUpperCase()}
                <div className="triAngle" style={{ background: props.number === 1 ? "#179DC7" : "#F47F4F", width: 39, height: 28, position: "absolute", marginLeft: 270, marginTop: -24 }}>

                </div>
            </div>
            <div style={{ display: 'flex', flex: 1, flexDirection: 'row', paddingTop: 5, marginTop: 2, fontSize: 13, marginBottom: -25, background: "#FAFAFA" }}>
                <div id="labeLgOAC" className="col-lg-4 col-md-4 col-sm-4" style={{ display: 'flex', flexDirection: 'column', marginTop: nombreEnfants == 0 ? 15 : 10 }}>
                    {
                        [0].includes(nombreEnfants) && (
                            <>
                                <div>
                                    <CLabel style={{ color: '#53628B', fontWeight: 'bold' }} >Donation antérieure de moins de 15 ans ?</CLabel>
                                    <div style={{ background: "#8E5FA8", width: 28, height: 4, borderRadius: 4, position: "absolute", marginTop: -10 }}></div>
                                </div>
                                <CLabel style={{ marginTop: 7, minHeight: 20, minWidth: 324, maxWidth: 324, marginLeft: 15 }}>Valeurs fiscales données sauf les dons exonérés</CLabel>
                            </>
                        )
                    }
                    {
                        nombreEnfants > 0 && (
                            <>
                                <div>
                                    <CLabel style={{ color: '#53628B', fontWeight: 'bold' }} >Donation antérieure de moins de 15 ans ?</CLabel>
                                    <div style={{ background: "#8E5FA8", width: 28, height: 4, borderRadius: 4, position: "absolute", marginTop: -10 }}></div>
                                </div>
                                <CLabel style={{ marginTop: 7, minHeight: 20, minWidth: 324, maxWidth: 324, marginLeft: 15 }}>Valeurs fiscales données sauf les dons exonérés</CLabel>
                                <CLabel style={{ marginTop: marginTopBetweenForm, minHeight: 20, minWidth: 324, maxWidth: 324, marginLeft: 15 }}>Année de la donation antérieure</CLabel>
                                <CLabel style={{ marginTop: marginTopBetweenForm, minHeight: 20, minWidth: 324, maxWidth: 324, marginLeft: 15 }}>Abattement fiscal applicable sur la donation antérieure</CLabel>

                                <div style={{ marginTop: 0, marginBottom: 20 }} >
                                    <CLabel style={{ marginTop: 10, minHeight: 20, color: '#53628B', fontWeight: 'bold' }}>Situation après apport et avant donation</CLabel><br></br>
                                    <div style={{ background: "#8E5FA8", width: 28, height: 4, borderRadius: 4, position: "absolute", marginTop: -10 }}></div>
                                    <CLabel style={{ marginTop: marginTopBetweenForm, minHeight: 20, minWidth: 324, maxWidth: 324, marginLeft: 15 }}>Solde valeur des titres en pleine propriété</CLabel><br></br>
                                    <CLabel style={{ marginTop: marginTopBetweenForm, minHeight: 20, minWidth: 324, maxWidth: 324, marginLeft: 15 }}>Solde valeur des titres en usufruit (Valeur en PP)</CLabel>
                                </div>
                            </>
                        )
                    }

                    <div>
                        <CLabel style={{ marginTop: -10, minHeight: 20, color: '#53628B', fontWeight: 'bold' }}>Nouvelle donation avant cession</CLabel>
                        <div style={{ background: "#8E5FA8", width: 28, height: 4, borderRadius: 4, position: "absolute", marginTop: -11 }}></div>
                    </div>

                    {/* <div style={{ background: "#8E5FA8", width: 28, height: 4, borderRadius: 4, position:"absolute", marginTop:50 }}></div> */}
                    <CLabel style={{ marginTop: nombreEnfants > 0 ? 6 : 13, minHeight: 22, minWidth: 324, maxWidth: 324, marginLeft: 15 }}>Donation en pleine propriété</CLabel>
                    {nombreEnfants > 0 && (<CLabel style={{ marginTop: marginTopBetweenForm, minHeight: 22, minWidth: 324, maxWidth: 324, marginLeft: 15 }}>Donation en NP avec clause de remploi (Valeur en PP)</CLabel>)}
                    <CLabel style={{ marginTop: 6, minHeight: 22, minWidth: 324, maxWidth: 324, marginLeft: 15 }}>Frais et droits de donation</CLabel>
                    <CLabel style={{ marginTop: 6, minHeight: 22, minWidth: 324, maxWidth: 324, marginLeft: 15 }}>Taux marginal des droits de donation</CLabel>
                </div>
                <div id="form_benificiaire" style={{ overflowX: 'auto', display: 'flex', flexDirection: 'column', flex: 1 }}>
                    {
                        nombreEnfants > 0 && (
                            <CLabel className={`label_title d-flex flex-direction-row align-items-center ${nombreEnfants > 0 ? "" : "mt-1"}`}>&nbsp;</CLabel>
                        )
                    }
                    <CFormGroup className="form-inline" style={{ display: 'flex', flexWrap: 'nowrap', flexDirection: 'row' }}>
                        {
                            childs.map((data, index) => {
                                let prenom = ![null, undefined, ""].includes(enfants[index].prenom) ? enfants[index].prenom.toString().toUpperCase() : `ENFANT ${index+1}`

                                return conditionAffichageEnfant(enfants, props.number - 1, index) && (
                                    <div key={index} className={`col-12 col-xxl-3 col-xl-3 col-lg-2 col-md-4 col-sm-4 px-0 ${index > 0 ? "ml-2" : ""} `} style={{ marginTop: "-34px" }}>
                                        <div style={{ borderBottom: "5px solid gray", fontWeight: 'bold', color: "#000000", padding: 10, height: 30, marginBottom: 10, display: 'flex', justifyContent: 'center', alignItems: 'center' }} className="text-center col-lg-12" id="benificiaire_num">
                                            {prenom}
                                        </div>
                                        <CFormGroup className="form-inline" style={{ visibility: conditionAffichageEnfant(enfants, props.number - 1, index) ? 'visible' : 'hidden', justifyContent: 'center', paddingRight: 0 }}>
                                            <InputCurrency
                                                //style={{ visibility: conditionAffichageEnfant(enfants, props.number - 1, index) ? 'visible' : 'hidden' }}
                                                id={`${formatToUniqueId("DonationParConjoint ")}-${index}`}
                                                value={data.valeurFiscale}
                                                className={classFormulaire}
                                                onChange={(event) => handleEnfantsChange(event, 'valeurFiscale', index)}
                                            />
                                        </CFormGroup>
                                    </div>
                                )
                            })
                        }
                        {
                            (
                                <>
                                    {

                                        (nombreEnfants > 0 && conditionAffichageConjoint2()) && (
                                            <div className={`col-12 col-xxl-3 col-xl-3 col-lg-2 col-md-4 col-sm-4 px-0  ml-2`} style={{ marginTop: "-34px" }}>
                                                <div style={{
                                                    borderBottom: props.number == 1 ? "5px solid #F47F4F" : "5px solid #179DC7",
                                                    fontWeight: 'bold',
                                                    color: props.number == 1 ? "#F47F4F" : "#179DC7",
                                                    padding: 10,
                                                    height: 30,
                                                    marginBottom: 10,
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center'
                                                }}
                                                    className="text-center col-lg-12" id="benificiaire_num"
                                                >
                                                    {props.number === 1 ? prenomConjoint2.toString().toUpperCase() : prenomConjoint1.toString().toUpperCase()}
                                                </div>

                                                <div style={{ marginLeft: 0 }} className={`col-12 col-xxl-3 col-xl-3 col-lg-2 col-md-4 col-sm-4 px-0`} >
                                                    <CFormGroup className="form-inline " style={{ justifyContent: 'center', paddingRight: 0 }}>
                                                        <InputCurrency
                                                            id={`${formatToUniqueId("DonationParConjoint")}`}
                                                            value={conjoints[props.number - 1].valeurFiscale}
                                                            className={classFormulaire}
                                                            onChange={(event) => handleEnfantsChange(event, 'valeurFiscaleConjoint', props.number - 1)}
                                                        />

                                                    </CFormGroup>
                                                </div>

                                            </div>
                                        )
                                    }

                                </>
                            )
                        }

                    </CFormGroup>
                    <CFormGroup className="form-inline" style={{ display: 'flex', flexWrap: 'nowrap', flexDirection: 'row' }}>
                        {
                            childs.map((data, index) => {
                                return conditionAffichageEnfant(enfants, props.number - 1, index) && (
                                    <div key={index} className={`col-12 col-xxl-3 col-xl-3 col-lg-2 col-md-4 col-sm-4 px-0 ${index > 0 ? "ml-2" : ""} `} >
                                        <CFormGroup className="form-inline " style={{ justifyContent: 'end', paddingRight: 0 }}>
                                            <InputSelect
                                                id={`${formatToUniqueId("DonationParConjoint childs")}-select-${index}`}
                                                custom
                                                value={data.anneeDonation}
                                                className="col-lg-6"
                                                onChange={(event) => handleEnfantsChange(event, 'anneeDonation', index)}
                                            >
                                                {
                                                    yearsList().map((donnee, index) => {
                                                        return <option selected={donnee === data.anneeDonation} >{donnee}</option>
                                                    })
                                                }
                                            </InputSelect>
                                        </CFormGroup>
                                    </div>
                                )
                            })
                        }
                    </CFormGroup>
                    <CFormGroup className="form-inline" style={{ display: 'flex', flexWrap: 'nowrap', flexDirection: 'row' }}>
                        {
                            childs.map((data, index) => {
                                let displaySelect = data && data.valeurFiscale ? currencyToSend(data.valeurFiscale) > 0 : false;

                                return conditionAffichageEnfant(enfants, props.number - 1, index) && (
                                    <div key={index} className={`col-12 col-xxl-3 col-xl-3 col-lg-2 col-md-4 col-sm-4 px-0 ${index > 0 ? "ml-2" : ""} `} >
                                        <CFormGroup className="form-inline " style={{ justifyContent: 'center', paddingRight: 0 }}>
                                            {
                                                displaySelect ? (
                                                    <SelectDonation
                                                        id={`${formatToUniqueId("DonationParConjoint childs")}-donation-${index}`}
                                                        value={data.abattementFiscal}
                                                        onChange={event => handleEnfantsChange(event, 'abattementFiscal', index)}
                                                        className={classFormulaire}
                                                        options={abattementFiscalSelonAnneeDonation(data.anneeDonation)}
                                                    />
                                                ) : (
                                                    <InputCurrency
                                                        id={`${formatToUniqueId("DonationParConjoint 2")}-${index}`}
                                                        className={classFormulaire}
                                                        disabled={true}
                                                    />
                                                )
                                            }
                                        </CFormGroup>
                                    </div>
                                )
                            })
                        }
                        {
                            nombreEnfants > 0 && (
                                <InfoBulle
                                    information={
                                        <div>
                                            <ul style={{ margin: 0, padding: 0, paddingLeft: 9 }}>
                                                <li>100 000 € pour les donations réalisées depuis le 17/08/2012</li>
                                                <li>150 000 € pour les donations réalisées entre le 22/08/2007 et le 16/08/2012</li>
                                                <li>50 000 € pour les donations réalisées avant le 22/08/2007</li>
                                            </ul>
                                        </div>
                                    }
                                />
                            )
                        }

                    </CFormGroup>
                    {
                        nombreEnfants > 0 && (
                            <div style={{ marginBottom: 20, marginTop: 36 }} >
                                <CFormGroup className="form-inline" style={{ display: 'flex', flexWrap: 'nowrap', flexDirection: 'row' }}>
                                    {
                                        childs.map((data, index) => {
                                            return conditionAffichageEnfant(enfants, props.number - 1, index) && (
                                                <div key={index} className={`col-12 col-xxl-3 col-xl-3 col-lg-2 col-md-4 col-sm-4 px-0 ${index > 0 ? "ml-2" : ""} `} >
                                                    <CFormGroup className="form-inline " style={{ justifyContent: 'center', paddingRight: 0 }}>
                                                        <InputCurrency
                                                            id={`${formatToUniqueId("DonationParConjoint 3")}-${index}`}
                                                            style={{ background: "rgb(242,242,242)", visibility: conditionAffichageEnfant(enfants, props.number - 1, index) ? 'visible' : 'hidden' }}
                                                            disabled={true}
                                                            value={data.soldeValeurTitresPP}
                                                            className={classFormulaire}
                                                        />

                                                    </CFormGroup>
                                                </div>
                                            )
                                        })
                                    }
                                    <div className={`col-12 col-xxl-3 col-xl-3 col-lg-2 col-md-4 col-sm-4 px-0 ml-2`} >
                                        <CFormGroup className="form-inline " style={{ justifyContent: 'center', paddingRight: 0 }}>
                                            <InputCurrency
                                                id={`${formatToUniqueId("DonationParConjoint 4")}`}
                                                style={{ background: "rgb(242,242,242)" }}
                                                disabled={true}
                                                value={pp}
                                                className={classFormulaire}
                                            />
                                        </CFormGroup>
                                    </div>
                                </CFormGroup>
                                <CFormGroup className="form-inline" style={{ display: 'flex', flexWrap: 'nowrap', flexDirection: 'row' }}>
                                    {
                                        childs.map((data, index) => {
                                            return conditionAffichageEnfant(enfants, props.number - 1, index) && (
                                                <div key={index} className={`col-12 col-xxl-3 col-xl-3 col-lg-2 col-md-4 col-sm-4 px-0 ${index > 0 ? "ml-2" : ""} `} >
                                                    <CFormGroup className="form-inline " style={{ justifyContent: 'center', paddingRight: 0 }}>
                                                        <InputCurrency
                                                            id={`${formatToUniqueId("DonationParConjoint 6")}-${index}`}
                                                            style={{ background: "rgb(242,242,242)", visibility: conditionAffichageEnfant(enfants, props.number - 1, index) ? 'visible' : 'hidden' }}
                                                            disabled={true}
                                                            value={data.soldeValeurTitresUSUF}
                                                            className={classFormulaire}
                                                        />

                                                    </CFormGroup>
                                                </div>
                                            )
                                        })
                                    }
                                    <div className={`col-12 col-xxl-3 col-xl-3 col-lg-2 col-md-4 col-sm-4 px-0 ml-2`} >
                                        <CFormGroup className="form-inline " style={{ justifyContent: 'center', paddingRight: 0 }}>
                                            <InputCurrency
                                                id={`${formatToUniqueId("DonationParConjoint 7")}`}
                                                style={{ background: "rgb(242,242,242)" }}
                                                disabled={true}
                                                value={np}
                                                className={classFormulaire}
                                            />

                                        </CFormGroup>
                                    </div>
                                </CFormGroup>
                            </div>
                        )
                    }
                    <div style={{ marginTop: nombreEnfants > 0 ? -26 : 0 }} >
                        {/**Espace vide */}
                        <CFormGroup className="form-inline" style={{ display: 'flex', flexWrap: 'nowrap', flexDirection: 'row', marginBottom: nombreEnfants > 0 ? 0 : -10 }}>
                            {
                                childs.map((data, index) => {
                                    return conditionAffichageEnfant(enfants, props.number - 1, index) && (
                                        <div key={index} style={{ visibility: 'hidden' }} className={`col-12 col-xxl-3 col-xl-3 col-lg-2 col-md-4 col-sm-4 px-0 ${index > 0 ? "ml-2" : ""} `} >
                                            <CFormGroup className="form-inline " style={{ justifyContent: 'center', paddingRight: 0 }}>
                                                <InputSelect
                                                    id={`${formatToUniqueId("DonationParConjoint childsr")}-select-${index}`}
                                                    custom
                                                    className={classFormulaire}
                                                >
                                                </InputSelect>
                                            </CFormGroup>
                                        </div>
                                    )
                                })
                            }
                            {
                                (nombreEnfants == 0) && (
                                    <div className={`col-12 col-xxl-3 col-xl-3 col-lg-2 col-md-4 col-sm-4 px-0  ml-2`} style={{ marginTop: "-7px" }}>
                                        <div style={{
                                            borderBottom: props.number == 1 ? "5px solid #F47F4F" : "5px solid #179DC7",
                                            fontWeight: 'bold',
                                            color: props.number == 1 ? "#F47F4F" : "#179DC7",
                                            padding: 10,
                                            height: 30,
                                            marginBottom: 0,
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}
                                            className="text-center col-lg-12" id="benificiaire_num"
                                        >
                                            {![null, 'null', undefined, 'undefined', ''].includes(conjoints[props.number === 1 ? 1 : 0].nomPersonnalise) ? new String(conjoints[props.number === 1 ? 1 : 0].nomPersonnalise).toUpperCase() : `CONJOINT ${props.number === 1 ? 2 : 1}` }
                                        </div>
                                        <div style={{ marginLeft: 0, marginTop: 5 }} className={`col-12 col-xxl-3 col-xl-3 col-lg-2 col-md-4 col-sm-4 px-0`} >
                                            <CFormGroup className="form-inline " style={{ justifyContent: 'center', paddingRight: 0 }}>
                                                <InputCurrency
                                                    id={`${formatToUniqueId("DonationParConjoint 8")}`}
                                                    value={conjoints[props.number - 1].valeurFiscale}
                                                    className={classFormulaire}
                                                    onChange={(event) => handleEnfantsChange(event, 'valeurFiscaleConjoint', props.number - 1)}
                                                />

                                            </CFormGroup>
                                        </div>
                                    </div>
                                )
                            }
                        </CFormGroup>
                        <CFormGroup className="form-inline" style={{ display: 'flex', flexWrap: 'nowrap', flexDirection: 'row', marginTop: nombreEnfants === 0 ? 38 : 15 }}>
                            {
                                childs.map((data, index) => {
                                    return conditionAffichageEnfant(enfants, props.number - 1, index) && (
                                        <div key={index} className={`col-12 col-xxl-3 col-xl-3 col-lg-2 col-md-4 col-sm-4 px-0 ${index > 0 ? "ml-2" : ""} `} >
                                            <CFormGroup className="form-inline " style={{ justifyContent: 'center', paddingRight: 0 }}>
                                                <InputCurrency
                                                    id={`${formatToUniqueId("DonationParConjoint 9")}-${index}`}
                                                    style={{ visibility: conditionAffichageEnfant(enfants, props.number - 1, index) ? 'visible' : 'hidden' }}
                                                    value={data.donationPP}
                                                    className={classFormulaire}
                                                    onChange={(event) => handleEnfantsChange(event, 'donationPP', index)}
                                                />

                                            </CFormGroup>
                                        </div>
                                    )
                                })
                            }
                            {
                                conditionAffichageConjoint2() && (
                                    <div className={`col-12 col-xxl-3 col-xl-3 col-lg-2 col-md-4 col-sm-4 px-0 ml-2`} >
                                        <CFormGroup className="form-inline " style={{ justifyContent: 'center', paddingRight: 0 }}>
                                            <InputCurrency
                                                id={`${formatToUniqueId("DonationParConjoint 10")}`}
                                                value={conjoints[props.number - 1].donationPP}
                                                className={classFormulaire}
                                                onChange={(event) => handleEnfantsChange(event, 'conjoint', props.number - 1)}
                                            />

                                        </CFormGroup>
                                    </div>
                                )
                            }
                        </CFormGroup>
                        <CFormGroup className="form-inline" style={{ display: 'flex', flexWrap: 'nowrap', flexDirection: 'row' }}>
                            {
                                childs.map((data, index) => {
                                    return conditionAffichageEnfant(enfants, props.number - 1, index) && (
                                        <div key={index} className={`col-12 col-xxl-3 col-xl-3 col-lg-2 col-md-4 col-sm-4 px-0 ${index > 0 ? "ml-2" : ""} `} >
                                            <CFormGroup className="form-inline " style={{ justifyContent: 'center', paddingRight: 0 }}>
                                                <InputCurrency
                                                    id={`${formatToUniqueId("DonationParConjoint 11")}-${index}`}
                                                    style={{ visibility: conditionAffichageEnfant(enfants, props.number - 1, index) ? 'visible' : 'hidden' }}
                                                    value={data.donationNP}
                                                    className={classFormulaire}
                                                    onChange={(event) => handleEnfantsChange(event, 'donationNP', index)}
                                                />

                                            </CFormGroup>
                                        </div>
                                    )
                                })
                            }
                        </CFormGroup>
                        <CFormGroup className="form-inline" style={{ display: 'flex', flexWrap: 'nowrap', flexDirection: 'row' }}>
                            {
                                childs.map((data, index) => {
                                    return conditionAffichageEnfant(enfants, props.number - 1, index) && (
                                        <div key={index} className={`col-12 col-xxl-3 col-xl-3 col-lg-2 col-md-4 col-sm-4 px-0 ${index > 0 ? "ml-2" : ""} `} >
                                            <CFormGroup className="form-inline " style={{ justifyContent: 'center', paddingRight: 0 }}>
                                                <InputCurrency
                                                    id={`${formatToUniqueId("DonationParConjoint 12")}-${index}`}
                                                    style={{ background: '#F5D6D6', color: '#EF7D7D', fontWeight: 'bold', visibility: conditionAffichageEnfant(enfants, props.number - 1, index) ? 'visible' : 'hidden' }}
                                                    disabled={true}
                                                    value={data.fraisDonation}
                                                    className={classFormulaire}
                                                />

                                            </CFormGroup>
                                        </div>
                                    )
                                })
                            }
                            <div className={`col-12 col-xxl-3 col-xl-3 col-lg-2 col-md-4 col-sm-4 px-0 ml-2`} >
                                <CFormGroup className="form-inline " style={{ justifyContent: 'center', paddingRight: 0, marginTop: nombreEnfants == 0 ? -5 : 0 }}>
                                    {
                                        conditionAffichageConjoint2() && (
                                            <>
                                                <InputCurrency
                                                    id={`${formatToUniqueId("DonationParConjoint 13")}`}
                                                    disabled={true}
                                                    value={conjoints[props.number - 1].fraisDonation}
                                                    className={classFormulaire}
                                                    style={{ background: '#F5D6D6', color: '#EF7D7D', fontWeight: 'bold'}}
                                                />
                                            </>
                                        )
                                    }
                                </CFormGroup>
                            </div>
                        </CFormGroup>
                        <CFormGroup className="form-inline" style={{ display: 'flex', flexWrap: 'nowrap', flexDirection: 'row' }}>
                            {
                                childs.map((data, index) => {
                                    return conditionAffichageEnfant(enfants, props.number - 1, index) && (
                                        <div key={index} className={`col-12 col-xxl-3 col-xl-3 col-lg-2 col-md-4 col-sm-4 px-0 ${index > 0 ? "ml-2" : ""} `} >
                                            <CFormGroup className="form-inline " style={{ justifyContent: 'center', paddingRight: 0 }}>
                                                <InputRate
                                                    id={`${formatToUniqueId("DonationParConjoint tauxMarginalImposition")}-rate-${index}`}
                                                    disabled={true}
                                                    value={data.tauxMarginalImposition}
                                                    style={{ background: '#F5D6D6', color: '#EF7D7D', fontWeight: 'bold', visibility: conditionAffichageEnfant(enfants, props.number - 1, index) ? 'visible' : 'hidden' }}
                                                    className={classFormulaire}
                                                />

                                            </CFormGroup>
                                        </div>
                                    )
                                })
                            }
                            <div className={`col-12 col-xxl-3 col-xl-3 col-lg-2 col-md-4 col-sm-4 px-0 ml-2`} >
                                <CFormGroup className="form-inline " style={{ justifyContent: 'center', paddingRight: 0, marginTop: nombreEnfants == 0 ? 0 : 0 }}>
                                    {
                                        conditionAffichageConjoint2() && (
                                            <>
                                                <InputRate
                                                    id={`${formatToUniqueId("DonationParConjoint tauxMarginalImposition 2")}-rate`}
                                                    disabled={true}
                                                    value={conjoints[props.number - 1].tauxMarginalImposition}
                                                    style={{ background: '#F5D6D6', color: '#EF7D7D', fontWeight: 'bold' }}
                                                    className={classFormulaire}
                                                />
                                            </>
                                        )
                                    }
                                </CFormGroup>
                            </div>
                        </CFormGroup>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DonationParConjoint;
