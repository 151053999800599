import React from 'react'
import { CButton, CModal, CModalHeader, CModalTitle, CModalBody, CModalFooter, CImg } from '@coreui/react';
import './ModalConfirmation.scss';

const ModalConfirmation = ({ visible, setVisible, color, background, onOKClick, onNOKClick, text, buttonValidationText, buttonCloseText, closeModal, doNotQuit, onCancelClick, buttonCancelText }) => {
  return (
    <CModal className='popup-confirmation' closeOnBackdrop={false} show={visible}>
      <CModalHeader>
        <CModalTitle className='popup-confirmation-header'>Confirmation</CModalTitle>
        {closeModal && (
          <div onClick={() => { setVisible(false) }} className='cross-stand-alone-container'>
            <span className="cross-stand-alone"></span>
          </div>
        )}
      </CModalHeader>
      <CModalBody className='popup-confirmation-body'>
        {
          text ? text : "Souhaitez-vous vraiment supprimer cette fiche ?"
        }
      </CModalBody>
      <CModalFooter style={{ justifyContent: buttonCancelText ? 'space-between' : 'flex-end' }}>
        {
          buttonCancelText && (
            <CButton style={{ background: '#EBF2F5', color: '#556382', border: 'none' }} color="secondary" onClick={() => {
              if (onCancelClick) {
                onCancelClick();
              }
              setVisible(false);
              }}>
              {buttonCancelText || 'NON'}
            </CButton>
          )
        }
        <CButton style={{ background: '#7A86A1', color: 'white', border: 'none' }} color="secondary" onClick={() => {
          if (onNOKClick) {
            onNOKClick();
          }
          setVisible(false);
          }}>
          {buttonCloseText || 'NON'}
        </CButton>
        <CButton style={{ background: background ? background : '#EBF2F5', color: color ? color : '#556382', border: 'none' }} onClick={() => { onOKClick(); setVisible(false) }} >{buttonValidationText || 'OUI'}</CButton>
        { doNotQuit && (
          <CButton style={{ background: '#EBF2F5', color: '#556382', border: 'none' }} onClick={() => { setVisible(false) }}>Ne pas quitter</CButton>
        )}
      </CModalFooter>
    </CModal>
  )
}

export default ModalConfirmation

