import { CCard, CCardBody, CCardHeader, CCol, CForm, CFormGroup, CLabel, CRow } from '@coreui/react';
import React, { useContext, useEffect, useState } from 'react';
import { LayoutContext } from '../../../../App2';
import { SimulateurContext } from '../../../../store/context/simulateur';
import { UtilsContext } from '../../../../store/context/utils';
import { CessionContext } from '../CessionHaut';
import { nombreEnfantsParConjoint } from '../ProjectionCessionSociete/TableauSynthese/TableauSynthese';
import ApportsAvantCession from './ApportsAvantCession/ApportsAvantCession';
import DonationParConjoint from './DonationParConjoint/DonationParConjoint';
import TableauDonation from './TableauDonation/TableauDonation';
import { saveSimulationCession } from '../ResultatSimulation/ResultatSimulation';
import { messageErreurEnregistrementSimulation, messageSuccesEnregistrementSimulation } from '../../../../utils';
import ScrollButton from '../../../../components/Button/scrollToTop';
import { updatePostItData } from '../../../../store/actions/simulateur';

const OperationAvantCession = () => {
    const { layoutState, setLayoutState } = useContext(LayoutContext);
    const { simulateurState, simulateurDispatch } = useContext(SimulateurContext);
    const { utilsDispatch } = useContext(UtilsContext);
    const { footerButtons, menuLevel } = layoutState;

    const [syntheseData, setSyntheseData] = useState(undefined);
    const [load, setLoad] = useState(false);
    const { cessionHaut } = useContext(CessionContext);
    const { identiteActionnaire: identite, operationsAvantCession: operations, projetCessionSociete: projet, idSimulation } = cessionHaut;
    const { enfants, conjoints } = identite;
    const { donations } = operations;

    const prenomConjoint1 = conjoints[0] &&
        ![null, undefined, ""].includes(conjoints[0].nomPersonnalise) ? conjoints[0].nomPersonnalise : 'Conjoint 1';

    const prenomConjoint2 = conjoints[1] &&
        ![null, undefined, ""].includes(conjoints[1].nomPersonnalise) ? conjoints[1].nomPersonnalise : 'Conjoint 2';

    const conditionAffichageDonneesConjoint1 = () => {
        let display = false;
        const conjoint1Marie = conjoints[0].marieOuPasce === 'Oui';
        const nbEnfantsConjoint1 = nombreEnfantsParConjoint(enfants, 0);

        if (nbEnfantsConjoint1 > 0 || conjoint1Marie) display = true;


        return display;
    };

    const conditionAffichageDonneesConjoint2 = () => {
        return conjoints[0].marieOuPasce === 'Oui';
    };

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        setLayoutState((prevState) => {
            footerButtons.cs = [
                {
                    type: 'PREVIOUS',
                    text: "Précédent",
                    onClick: () => {
                        setLayoutState((prev) => {
                            menuLevel.cs = 2;

                            return {
                                ...prev,
                                menuLevel
                            };
                        })
                    }
                },
                {
                    type: 'ADD',
                    text: "Enregistrer",
                    onClick: () => {
                        saveSimulationCession(
                            cessionHaut,
                            simulateurState,
                            () => { setLoad(true) },
                            () => { setLoad(false) },
                            (response) => {
                                const id = response.id;
                                if (id) {
                                    identite.idSimulationOnSave = id;
                                    projet.idSimulationOnSave = id;
                                    operations.idSimulationOnSave = id;
                                    simulateurDispatch(updatePostItData({
                                      ...(simulateurState?.postItData || {}),
                                      simulationId: response.id,
                                      simulationType: 'operation-avant-cession'
                                    }))
                                }
                                utilsDispatch({ type: 'ADD_TOAST', payload: { type: 'success', title: 'OK', description: messageSuccesEnregistrementSimulation(simulateurState?.simulation?.identifiant_simulation) } })
                            },
                            () => { utilsDispatch({ type: 'ADD_TOAST', payload: { type: 'danger', title: 'ERREUR', description: messageErreurEnregistrementSimulation(simulateurState?.simulation?.identifiant_simulation) } }) },
                            idSimulation || projet.idSimulationOnSave
                        )

                    }
                },
                {
                    type: 'NEXT',
                    text: "Suivant",
                    onClick: () => {
                        setLayoutState((prev) => {
                            menuLevel.cs = 4;

                            return {
                                ...prev,
                                menuLevel
                            };
                        })
                    }
                },
            ]

            return {
                ...prevState,
                footerButtons
            };
        });
    }, [simulateurState]);

    const conditionAffichageDonation = conditionAffichageDonneesConjoint1() || conditionAffichageDonneesConjoint2();

    return (
        <>
            <div id="formulaireOperationAvantCession">
                <CForm action="/" method="post" className="col-lg-12" id="formOperationAvantCession">
                    <div style={{ padding: 15, background: "#fff", borderRadius: 6 }}>
                        <div style={{ paddingBottom: 10 }}>
                            <div style={{ color: `#000`, fontWeight: 'bold', fontSize: 15 }}>
                                APPORTS AVANT CESSION
                            </div>
                            <div style={{ background: "linear-gradient(265deg,#80BA4B,#2B9957)", width: 28, height: 4, borderRadius: 4 }}></div>
                        </div>
                        <div>
                            <CRow>
                                <CCol sm={12}>
                                    <div className="structureBlock" style={{ background: "#fff", padding: 10, borderRadius: 6 }}>
                                        <ApportsAvantCession
                                            prenomConjoint1={prenomConjoint1}
                                            prenomConjoint2={prenomConjoint2}
                                        />
                                    </div>
                                </CCol>
                            </CRow>
                        </div>
                    </div>
                </CForm>
                {
                    conditionAffichageDonation && (
                        <div id="formulaireDonation" className='formulaire'>
                            <CForm action="/" method="post" className="col-lg-12" id="donationAvantCession">
                                <div style={{ padding: "15px 15px 25px 15px", background: "#fff", borderRadius: 6 }}>
                                    <div style={{ color: `#000`, fontWeight: 'bold', paddingBottom: 16, fontSize: 15 }}>
                                        DONATIONS AVANT CESSION
                                    </div>

                                    <div style={{ marginTop: -31, marginBottom: 15 }}>
                                        <CRow>
                                            <CCol sm={12}>

                                                {
                                                    // On affiche uniquement ceci si le Conjoint 1 a des Enfants
                                                    conditionAffichageDonneesConjoint1() && (
                                                        <div style={{ marginTop: 25 }}>
                                                            <DonationParConjoint
                                                                number={1}
                                                                donation={donations[0].enfants}
                                                                nombreEnfants={nombreEnfantsParConjoint(enfants, 0)}
                                                                prenomConjoint1={prenomConjoint1}
                                                                prenomConjoint2={prenomConjoint2}
                                                            />
                                                        </div>
                                                    )
                                                }
                                                {
                                                    // On affiche uniquement ceci si le Conjoint 2 a des Enfants
                                                    conditionAffichageDonneesConjoint2() && (
                                                        <div style={{ marginTop: 35 }}>
                                                            <DonationParConjoint
                                                                number={2}
                                                                donation={donations[1].enfants}
                                                                nombreEnfants={nombreEnfantsParConjoint(enfants, 1)}
                                                                prenomConjoint1={prenomConjoint1}
                                                                prenomConjoint2={prenomConjoint2}
                                                            />
                                                        </div>
                                                    )
                                                }
                                            </CCol>
                                        </CRow>
                                    </div>
                                </div>
                            </CForm>
                        </div>
                    )
                }
                <CForm action="/" method="post" className="col-lg-12" id="formOperationAvantCession">
                    <div style={{ padding: 15, background: "#fff", marginTop: 8, borderRadius: 6, marginBottom: 8 }}>
                        <div style={{ paddingBottom: 8 }}>
                            <div style={{ color: `#000`, fontWeight: 'bold', fontSize: 15 }}>
                                TABLEAU DE SYNTHESE
                            </div>
                            <div style={{ background: "linear-gradient(265deg,#80BA4B,#2B9957)", width: 28, height: 4, borderRadius: 4 }}></div>
                        </div>
                        <div>
                            <CRow>
                                <CCol sm={12}>
                                    <TableauDonation />
                                </CCol>
                            </CRow>
                        </div>
                    </div>
                </CForm>
            </div>
        </>
    );
};

export default OperationAvantCession;
