import { formattedCurrency } from "../../../../../extra/cession/TableauSynthese/TableauSynthese";

// Ces fonctions sont nécéssaires pour le Tableau de synthèse pour chaque scénarion des résultats détaillés

const hasValue = (prixCession, impotsPV, netCession) => {
    if((prixCession === '- €' && impotsPV === '- €' && netCession === '- €')) return false;
    else return true;
};

const nbConjoints = (conjoints, withConjoint2) => {
    let nombre = 0;

    conjoints.map((data, index) => {
        if(index === 0 || (index === 1 && withConjoint2)){
            if(data){
                if(hasValue(
                    formattedCurrency(Math.round(data.prix_cession)),
                    formattedCurrency(Math.round(data.impot_et_droit_flat)),
                    formattedCurrency(Math.round(data.net_flat)),
                )){
                    nombre++
                }
            }
        }
    });

    return nombre;
};

const nbEnfants = (enfants) => {
    let nombre = 0;
    enfants.map((data, index) => {
        if(data){
            if(hasValue(
                formattedCurrency(Math.round(data.prix_cession)),
                formattedCurrency(Math.round(data.impot_et_droit_flat)),
                formattedCurrency(Math.round(data.net_flat)),
            )){
                nombre++
            }
        }
    });

    return nombre;
};

const nbEnfantsConjoints = (conjoints, enfantsNP, withConjoint2) => {
    let nombre = 0;

    conjoints.map((donnee, indice) => {
        if(indice === 0 || (indice === 1 && withConjoint2)){
            if(hasValue(
                formattedCurrency(Math.round(donnee.prix_cession)),
                formattedCurrency(Math.round(donnee.impot_et_droit_flat)),
                formattedCurrency(Math.round(donnee.net_flat)),
            ) && enfantsNP[indice].length > 1){
                nombre++
            }
            enfantsNP[indice].map((data, index) => {
                if(hasValue(
                    formattedCurrency(Math.round(data.prix_cession)),
                    formattedCurrency(Math.round(data.impot_et_droit_flat)),
                    formattedCurrency(Math.round(data.net_flat)),
                )){
                    nombre++
                }
            });
        }
    });

    return nombre;
}; 

const nbLignesScenario1 = (conjointsPP, enfantsPP, conjointsNP, enfantsNP, withConjoint2) => {
    const nombreConjoints = nbConjoints(conjointsPP, withConjoint2); // Pour les 2 Conjoints
    const nbSupplementaire = enfantsPP.length > 1 ? 1 : 0; // Pour la somme des Enfants PP
    const nombreEnfants = nbEnfants(enfantsPP);
    const nombreEnfantsParConjoints = nbEnfantsConjoints(conjointsNP, enfantsNP, withConjoint2);

    return nombreConjoints + nbSupplementaire + nombreEnfants + nombreEnfantsParConjoints;
};

const nbLignesScenario2 = (conjoints, withConjoint2) => {
    return nbConjoints(conjoints, withConjoint2);
};

const nbLignesHolding = (holding) => {
    let nombre = 0;

    // Pour la plus value de cession
    if(hasValue(
        formattedCurrency(Math.round(holding.plus_value_cession)),
        '- €',
        '- €'
    )) nombre++;

    // Pour la base imposable IS
    if(hasValue(
        formattedCurrency(Math.round(holding.base_imposable_ps)),
        '- €',
        '- €'
    )) nombre++;

    // Pour le taux IS
    if(hasValue(
        formattedCurrency(Math.round(holding.prix_cession)),
        '- €',
        '- €'
    )) nombre++;

    return nombre;
};

const noPlusValueCession = (holding) => {
    return (hasValue(formattedCurrency(Math.round(holding.plus_value_cession)),'- €','- €'))
};

const noPlusValueCessionAndBaseImposableIS = (holding) => {
    return (noPlusValueCession(holding) && (hasValue(formattedCurrency(Math.round(holding.base_imposable_ps)),'- €','- €')));
};

const borderBottomEnfant = (enfants) => {
    let id = 0;
    enfants.map((data, index) => {
        if(hasValue(
            formattedCurrency(Math.round(data.prix_cession)),
            formattedCurrency(Math.round(data.impot_et_droit_flat)),
            formattedCurrency(Math.round(data.net_flat)),
        )){
            id = index + 1;
        }
    });

    return id;
};

export {
    nbLignesScenario1,
    nbLignesScenario2,
    nbLignesHolding,
    borderBottomEnfant
};