import React from 'react';
import useStyles from './style';
import classnames from 'classnames';

const Usufruitier = (props) => {
    const classes = useStyles();

    return (
        <div className={classnames("col-lg-12 col-md-12 col-sm-12", classes.root)} style={{ padding: 0 }} >
            <div style={{ width: props.left ? "50%" : "100%" }} >
                <div className={classes.title} style={{ textAlign: props.left ? "left" : "center", background: props.conjoint2 ? "#F0885D" : "#2BA3C8", color: "white", marginLeft: -19, paddingLeft: 19, marginTop: -4, textTransform:"uppercase" }} >
                    {props.title + " " + (props.conjoint2 ? "conjoint 2" : "conjoint 1")}
                    <div className="triAngle" style={{ width: 42, height: 26, position: "absolute", background: props.conjoint2 ? "#F0885D" : "#2BA3C8", marginLeft: 181, marginTop: -23 }}>

                    </div>
                </div>
                <div className={classes.subtitle} style={{ textAlign: props.left ? "left" : "center", marginLeft: -3 }} >
                    {props.subtitle}
                </div>
            </div>
        </div>
    );
};

export default Usufruitier;