import React from 'react';
import { InputCheckBox } from '../input';
import RefreshIcon from '../../assets/images/refresh-icon-personnalisation-export.svg';
import { CImg } from '@coreui/react';
import { OrdinaryTooltip } from '../InfoBulle/Tooltip/Tooltip';
import ExportWhiteIcon from '../../assets/images/ExportWhiteIcon.svg';
import BoutonFermeture from '../../assets/images/fermeture-personnalisation-information-simulation.svg';

const PersonnalisationExport = ({
    data,
    onRequestClose,
    onRequestDataChange,
    onRequestExport
}) => {
    const { touteSelection, elements, enregistrementSelection } = data;

    // Changement dans les formulaires
    const handleDataChange = (event, prop, ordre, indexParent, index, indexChild) => {
        const value = event.target.value === 'true' ? false : true;
        const info = JSON.parse(JSON.stringify(data));

        if (['touteSelection', 'enregistrementSelection'].includes(prop)) {
            info[prop] = value;

            // S'il y a de modification sur "Toute sélection" => On modifie les autres restants
            if (prop === 'touteSelection') {
                // info.enregistrementSelection = value;

                info.elements.map((element) => {
                    element.value = value;

                    element.childrens?.map((children) => {
                        children.value = value;

                        children.childrens?.map((child) => {
                          child.value = value;
                      })
                    })
                })
            }
        }
        else {
            if (ordre === 'parent') {
                info.elements[indexParent].value = value;

                if (info.elements[indexParent].childrens?.length > 0) {
                    info.elements[indexParent].childrens.map((children) => {

                        children.value = value;

                        if (children?.childrens) {
                          if (children?.childrens.length > 0) {
                            children.childrens.map((child) => {
                              child.value = value;
                            })
                          }
                        }
                    })
                }

            } else if (ordre === 'children') {
                info.elements[indexParent].childrens[index].value = value;
                if (info.elements[indexParent].childrens[index]?.childrens) {
                  if (info.elements[indexParent].childrens[index].childrens.length > 0) {

                    info.elements[indexParent].childrens[index].childrens.map((child) => {
                      child.value = value;
                    })
                  }
                }

            } else {
                info.elements[indexParent].childrens[index].childrens[indexChild].value = value;
            }
        }

        onRequestDataChange(info);
    }

    // Réinitialisation des données
    const handleReinitData = () => {
        const info = JSON.parse(JSON.stringify(data));

        info.touteSelection = false;
        info.enregistrementSelection = false;

        info.elements.map((elem) => {
            elem.value = false;

            if (elem.childrens?.length > 0) {
                elem.childrens.map((children) => {
                    children.value = false
                })
            }
        })

        onRequestDataChange(info);
    }

    const LARGEUR_INTITULE = 370;

    return (
        <div style={{ maxHeight: '73vh', overflowY: 'auto', background: 'rgb(242,249,252)', padding: 20, paddingLeft: 30, paddingRight: 30, borderRadius: 5, position: 'fixed', bottom: 62, right: 20, zIndex: 100 }}>
            {/** Bloc de titre */}
            <div style={{ display: 'flex', alignItems: 'center', borderBottom: '2px solid rgb(234,241,244)', padding: 10 }}>
                <div style={{ fontWeight: 'bold', fontSize: 14, marginRight: 15, width: LARGEUR_INTITULE }}>COCHER LES SECTIONS QUE VOUS SOUHAITEZ EXPORTER</div>
                <div style={{ marginRight: 25 }}>
                    <InputCheckBox
                        style={{ width: 28, height: 28 }}
                        checked={touteSelection}
                        value={touteSelection}
                        onChange={(event) => handleDataChange(event, 'touteSelection')}
                    />
                </div>
                {/** Demande à Hery l'icône de réinitialisation */}
                {/*<div style={{ marginRight: 30 }}>
                    <OrdinaryTooltip arrow title="Réinitialiser">
                        <div>
                            <CImg className='footerButton' src={RefreshIcon} style={{ width: 22 }} onClick={handleReinitData} />
                        </div>
                    </OrdinaryTooltip>
                </div>*/}
                {/** Bouton de fermeture */}
                <div style={{ position: 'absolute', right: 10 }}>
                    <OrdinaryTooltip arrow title="Fermer">
                        <div>
                            <CImg className='footerButton' src={BoutonFermeture} style={{ width: 20 }} onClick={onRequestClose} />
                        </div>
                    </OrdinaryTooltip>
                </div>
            </div>

            {/** Les éléments */}
            {
                elements.map((element, index) => {
                    let elementHasChilds = element.childrens?.length > 0;

                    return (
                        <div key={`ElementExport${index + 1}`} style={{ borderBottom: `${elementHasChilds ? 0 : 2}px solid rgb(234,241,244)` }}>
                            {/** Catégorie */}
                            <div style={{ display: 'flex', alignItems: 'center', padding: 5, paddingLeft: 10, borderBottom: `${elementHasChilds ? 2 : 0}px solid rgb(234,241,244)` }}>
                                <div style={{ fontWeight: 'bold', fontSize: 13, marginRight: 15, width: LARGEUR_INTITULE }}>{element.label}</div>
                                <div>
                                    <InputCheckBox
                                        style={{ width: 28, height: 28 }}
                                        checked={element.value}
                                        value={element.value}
                                        onChange={(event) => handleDataChange(event, 'value', 'parent', index)}
                                    />
                                </div>
                            </div>
                            {/** Les sous catégories */}
                            {
                                element.childrens?.map((children, i) => {
                                    return (
                                        <div key={`ElementChildrenExport${index + 1}${i + 1}`} style={{  }}>
                                            <div style={{ display: 'flex', alignItems: 'center', paddingRight: 10, paddingTop: 5, paddingBottom: 5, borderBottom: `2px solid rgb(234,241,244)`, paddingLeft: 30 }}>
                                                <div style={{ fontSize: 13, marginRight: 15, width: LARGEUR_INTITULE - 20 }}>{children.label}</div>
                                                <div>
                                                    <InputCheckBox
                                                        style={{ width: 28, height: 28 }}
                                                        checked={children.value}
                                                        value={children.value}
                                                        onChange={(event) => handleDataChange(event, 'value', 'children', index, i)}
                                                    />
                                                </div>
                                            </div>
                                            {/** Les sous sous catégories */}
                                            {
                                              children?.childrens && children?.childrens?.map((childrenChildren, iChild) => {
                                                return (
                                                  <div key={`ElementChildrenExport${index + 1}${i + 1}${iChild + 1}`} style={{ paddingLeft: 45, borderBottom: `2px solid rgb(234,241,244)` }}>
                                                    <div style={{ display: 'flex', alignItems: 'center', paddingRight: 10, paddingTop: 5, paddingBottom: 5 }}>
                                                        <div style={{ fontSize: 13, marginRight: 0, width: LARGEUR_INTITULE - 20 }}>{childrenChildren.label}</div>
                                                        <div>
                                                            <InputCheckBox
                                                                style={{ width: 28, height: 28 }}
                                                                checked={childrenChildren.value}
                                                                value={childrenChildren.value}
                                                                onChange={(event) => handleDataChange(event, 'value', 'childrenChildren', index, i, iChild)}
                                                            />
                                                        </div>
                                                    </div>
                                                  </div>
                                                )
                                              })
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>
                    )
                })
            }
            {/** Enregistrement des sélections */}
            <div style={{ display: 'flex', alignItems: 'center', padding: 5, paddingLeft: 10, marginTop: 20, position: 'relative' }}>
                <div style={{ fontWeight: 'bold', fontSize: 13, marginRight: 15, width: '200px' }}>Enregistrer la sélection</div>
                <div style={{ marginRight: 25 }}>
                    <InputCheckBox
                        style={{ width: 28, height: 28 }}
                        checked={enregistrementSelection}
                        value={enregistrementSelection}
                        onChange={(event) => handleDataChange(event, 'enregistrementSelection')}
                    />
                </div>

                {/** Bouton Exporter */}
                <div style={{ position: 'absolute', right: 0, display: 'flex', alignItems: 'center' }}>
                    <div /*style={{ marginRight: 30 }}*/>
                        <button onClick={onRequestExport} style={{ width: 100, display: 'flex', justifyContent: 'center', alignItems: 'center', height: 35, background: 'rgb(119,197,10)', borderRadius: 5, color: '#FFFFFF', fontWeight: 'bold', border: 'none' }}>
                            <div style={{ marginRight: 10 }}><CImg src={ExportWhiteIcon} style={{ width: 15 }} /></div>
                            <div style={{ marginTop: 3 }}>Exporter</div>
                        </button>
                    </div>

                    {/** Bouton de fermeture */}
                    {/*<div>
                        <OrdinaryTooltip arrow title="Fermer">
                            <div>
                                <CImg className='footerButton' src={BoutonFermeture} style={{ width: 25 }} onClick={onRequestClose} />
                            </div>
                        </OrdinaryTooltip>
                    </div>*/}
                </div>
            </div>
        </div>
    )
}

export default PersonnalisationExport;
