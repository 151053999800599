import React from 'react';

const BlocResultatDetaille = (props) => {
    const { values, marginBottom, construction } = props;

    return (
        <div style={{ marginBottom: marginBottom ? marginBottom : 20, background: '#FFFFFF', width: '100%', borderRadius: 4, boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px' }} >
            {
                values.map((data, index) => {
                    return (
                        <div style={{ padding: 10, paddingTop: 15, display: 'flex', justifyContent: !construction && 'space-between', borderBottom: "1px solid #D8D8D8", fontWeight: data.bold ? 'bold' : 'normal' }} >
                            <div style={{ width: (construction || (!construction && data.withPercent)) && '50%', paddingLeft: data.padding && 30, fontWeight: data.labelBold ? 'bold' : 'normal', color: data.labelColor && data.labelColor }} >{data.label}</div>
                            {
                                (!construction && data.withPercent) && (
                                    <div style={{ textAlign: 'right', width: '33.33%' }} >{data.valuePercent ? parseFloat(data.valuePercent).toFixed(2) : 0} %</div>
                                )
                            }
                            <div style={{ width: (construction || (!construction && data.withPercent)) && '16.67%', textAlign: (construction || (!construction && data.withPercent)) && 'right', fontWeight: data.valueBold ? 'bold' : 'normal', color: data.valueColor && data.valueColor, borderRight: construction && "4px solid #FFFFFF" }} >{`${data.value} ${data.unite || ""}`}</div>
                            {
                                construction && (
                                    <div style={{ width: construction && '16.67%', textAlign: construction && 'right', fontWeight: data.valueBold ? 'bold' : 'normal', color: data.valueColor && data.valueColor }} >{`${data.value2} ${data.unite || ""}`}</div>
                                )
                            }{
                                (construction && data.value3 !== undefined ) && (
                                    <div style={{ width: construction && '16.66%', textAlign: construction && 'right', fontWeight: data.valueBold ? 'bold' : 'normal', color: data.valueColor && data.valueColor }} >{`${data.value3} ${data.unite || ""}`}</div>
                                )
                            }
                        </div>
                    )
                })
            }
        </div>
    )
}

export default BlocResultatDetaille;