import React, { useContext } from 'react';
import { CFormGroup, CLabel, CImg } from '@coreui/react';
import { InputText, InputAgeAvcAns, InputCurrency } from '../../../../../components/input';
import TSwitch from '../../../../../components/SwitchGreen/SwitchGreen';
import moinsPleine from '../../../../../assets/icons/svg/moinsPleine.svg'
import plusPleine from '../../../../../assets/icons/svg/plusPleine.svg'
import InfoBulle from '../../../../../components/InfoBulle/InfoBulle'
import { currencyToSend } from '../../../../DonationSuccession/UsufruitNP/UsufruitViager/UsufruitViager'
import { CessionContext } from '../../CessionHaut'
import { formatToUniqueId } from '../../../../../utils';

export const ouiOuNonData = [
    "Oui",
    "Non"
];

const Conjoint = (props) => {
    const { cessionHaut, setCessionHaut } = useContext(CessionContext);
    const { identiteActionnaire } = cessionHaut;
    const { conjoints, nombreEnfants } = identiteActionnaire;

    const handleFormChange = (event, index, prop) => {
        let { value } = event.target;
        const cnjs = [...conjoints];

        if (prop === 'marieOuPasce') {
            const situationFamilialeActuelle = cnjs[0].marieOuPasce;

            if (situationFamilialeActuelle === 'Oui') value = 'Non';
            else value = 'Oui';
        }

        cnjs[index][prop] = value;

        identiteActionnaire.conjoints = cnjs;

        setCessionHaut((prevState) => {
            return {
                ...prevState,
                identiteActionnaire
            }
        });
    };

    const updateNombreEnfants = (operation) => {
        let nbEnfants = nombreEnfants;

        if (operation === '+') nbEnfants += 1;
        else {
            if (nbEnfants > 0) nbEnfants -= 1;
        }

        identiteActionnaire.nombreEnfants = nbEnfants;

        setCessionHaut((prevState) => {
            return {
                ...prevState,
                identiteActionnaire
            }
        });
    };

    const prenomConjoint1 = conjoints[0] &&
        !["", null, undefined].includes(conjoints[0].nomPersonnalise) ? conjoints[0].nomPersonnalise : `Conjoint 1`;


    const prenomConjoint2 = conjoints[1] &&
        !["", null, undefined].includes(conjoints[1].nomPersonnalise) ? conjoints[1].nomPersonnalise : `Conjoint 2`;

    return (
        <>
            <CFormGroup className="form-inline col-lg-12" style={{ display: 'flex', flexWrap: 'nowrap', flexDirection: 'row' }}>
                <CLabel htmlFor="nom" id="labeldentiteAct" className="col-lg-4 col-md-6 col-sm-7"></CLabel>
                <div className="mr-2" style={{ justifyContent: 'start', paddingRight: 0, flexDirection: "column", width: 170 }}>
                    <CLabel htmlFor="nom" className="col-lg-2 col-md-5 col-sm-5" style={{ fontWeight: "bold", paddingLeft: 0 }}>
                        {
                            prenomConjoint1.toString().toUpperCase()
                        }
                    </CLabel>
                    <div style={{ width: 27, height: 4, background: "#318FCB", justifyContent: 'start', borderRadius: 4 }}></div>
                </div>
                {
                    conjoints[0].marieOuPasce === 'Oui' && (
                        <div style={{ justifyContent: 'start', paddingRight: 0, flexDirection: "column", width: 170 }}>
                            <CLabel htmlFor="nom" className="col-lg-2 col-md-5 col-sm-5" style={{ fontWeight: "bold", paddingLeft: 0 }}>
                                {
                                    prenomConjoint2.toString().toUpperCase()
                                }
                            </CLabel>
                            <div style={{ width: 27, height: 4, background: "#F47F4F", justifyContent: 'start', borderRadius: 4 }}></div>
                        </div>
                    )
                }
            </CFormGroup>
            {
                <CFormGroup className="form-inline col-lg-12" style={{ display: 'flex', flexWrap: 'nowrap', flexDirection: 'row' }}>
                    <CLabel htmlFor={`${formatToUniqueId("Conjoint nom")}-text`} id="labeldentiteAct" className="col-lg-4 col-md-6 col-sm-7">Nom personnalisé</CLabel>
                    <CFormGroup className="form-inline mr-2" style={{ justifyContent: 'center', paddingRight: 0 }}>
                        <InputText
                            id={`${formatToUniqueId("Conjoint nom")}-text`}
                            placeholder='Conjoint 1'
                            className="col-lg-2 col-md-5 col-sm-5"
                            value={conjoints[0].nomPersonnalise !== 'Conjoint 1' ? conjoints[0].nomPersonnalise : ""}
                            onChange={(event) => handleFormChange(event, 0, 'nomPersonnalise')}
                            capitalize={true}
                        />
                    </CFormGroup>

                    {
                        conjoints[0].marieOuPasce === 'Oui' && (
                            <CFormGroup className="form-inline " style={{ justifyContent: 'center', paddingRight: 0 }}>
                                <InputText
                                    id={`${formatToUniqueId("Conjoint nom 2")}-text`}
                                    placeholder="Conjoint 2"
                                    className="col-lg-2 col-md-5 col-sm-5"
                                    value={conjoints[1].nomPersonnalise !== 'Conjoint 2' ? conjoints[1].nomPersonnalise : ""}
                                    onChange={(event) => handleFormChange(event, 1, 'nomPersonnalise')}
                                    capitalize={true}
                                />
                            </CFormGroup>
                        )
                    }
                </CFormGroup>
            }
            {
                <CFormGroup className="form-inline col-lg-12" style={{ display: 'flex', flexWrap: 'nowrap', flexDirection: 'row' }}>
                    <CLabel htmlFor="age" id="labeldentiteAct" className="col-lg-4 col-md-6 col-sm-7">Age</CLabel>
                    <div className={`col-lg-1 pl-0 ${conjoints[0].marieOuPasce === 'Oui' ? 'mr-2' : ''}`} style={{ padding: 0, maxWidth: 170, minWidth: 170 }}>
                        <CFormGroup className="form-inline" style={{ justifyContent: 'end', paddingRight: 0 }}>
                            <InputAgeAvcAns
                                id="age"
                                className={`col-lg-6 col-md-6`}
                                value={currencyToSend(conjoints[0].age) === 0 ? '' : conjoints[0].age}
                                onChange={(event) => handleFormChange(event, 0, 'age')}
                            />
                        </CFormGroup>
                    </div>
                    {
                        conjoints[0].marieOuPasce === 'Oui' && (
                            <div className=" col-lg-1 pl-0" style={{ padding: 0, maxWidth: 170, minWidth: 170 }}>
                                <CFormGroup className="form-inline" style={{ justifyContent: 'end', paddingRight: 0 }}>
                                    <InputAgeAvcAns
                                        id="age"
                                        className="col-lg-6 col-md-6"
                                        value={currencyToSend(conjoints[1].age) === 0 ? '' : conjoints[1].age}
                                        onChange={(event) => handleFormChange(event, 1, 'age')}
                                    />
                                </CFormGroup>
                            </div>
                        )
                    }
                    <InfoBulle
                        information="Info nécessaire en cas de démembrement de propriété"
                    />
                </CFormGroup>
            }

            {
                <CFormGroup className="form-inline col-lg-12" style={{ display: 'flex', flexWrap: 'nowrap', flexDirection: 'row' }}>
                    <CLabel htmlFor="mois" id="labeldentiteAct" className="col-lg-4 col-md-6 col-sm-7">
                        Revenu fiscal de référence
                    </CLabel>
                    <CFormGroup className="form-inline ">
                        <InputCurrency
                            id={`${formatToUniqueId("Conjoint Revenu fiscal de référence")}`}
                            value={conjoints[0].revenuAnneeCession}
                            onChange={(event) => handleFormChange(event, 0, 'revenuAnneeCession')}
                            className={`col-lg-2 col-md-5 col-sm-5 ${conjoints[0].marieOuPasce === 'Oui' ? 'mr-2' : ''}`}
                        />
                    </CFormGroup>
                    {
                        conjoints[0].marieOuPasce === 'Oui' && (
                            <CFormGroup className="form-inline">
                                <InputCurrency
                                    id={`${formatToUniqueId("Conjoint Revenu fiscal de référence pacse")}`}
                                    value={conjoints[1].revenuAnneeCession}
                                    onChange={(event) => handleFormChange(event, 1, 'revenuAnneeCession')}
                                    className="col-lg-2 col-md-5 col-sm-5"
                                />
                            </CFormGroup>
                        )
                    }
                    <InfoBulle
                        information="Revenus autres que la plus-value sur la cession de société"
                    />
                </CFormGroup>
            }

            <CFormGroup className="form-inline col-lg-12" style={{ display: 'flex', flexWrap: 'nowrap', flexDirection: 'row' }}>
                <CLabel htmlFor="situationMatrimoniale" id="labeldentiteAct" className="col-lg-4 col-md-6 col-sm-7">Marié ou pacsé ?</CLabel>
                <CFormGroup className="form-inline col-lg-2 mr-2" style={{ display: "flex", justifyContent: "end", padding: 0 }}>
                    <TSwitch
                        isToggled={conjoints[0].marieOuPasce === 'Oui'}
                        onToggleChange={(event) => handleFormChange(event, 0, 'marieOuPasce')}
                    />
                </CFormGroup>
            </CFormGroup>


            <CFormGroup className="form-inline col-lg-12" style={{ display: 'flex', flexWrap: 'nowrap', flexDirection: 'row' }}>
                <CLabel htmlFor="situationMatrimoniale" id="labeldentiteAct" className="col-lg-4 col-md-6 col-sm-7" >Présence d'enfant ?</CLabel>
                <div className="col-6 col-xxl-2 col-xl-2 col-lg-3 col-md-3 col-sm-3 pl-0" style={{ padding: 0, maxWidth: 170, minWidth: 170 }}>
                    <div style={{
                        backgroundColor: "#fff",
                        width: 170,
                        height: 33,
                        marginLeft: 0,
                        border: '1px solid #DFDFDF',
                        borderRadius: 4,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}>
                        <div style={{ width: 150, heigth: 33, display: "flex", justifyContent: "space-between" }}>
                            <div>
                                <CImg
                                    className="plus"
                                    src={moinsPleine}
                                    style={{ width: 20, heigth: 20, cursor: "pointer", zIndex: 2 }}
                                    onClick={() => updateNombreEnfants('-')}
                                />
                            </div>
                            <div style={{ fontWeight: "bold" }}>{nombreEnfants}</div>
                            <div>
                                <CImg
                                    className="plus"
                                    src={plusPleine}
                                    style={{ width: 20, heigth: 20, cursor: "pointer" }}
                                    onClick={() => updateNombreEnfants('+')}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </CFormGroup>

        </>
    );
};

export default Conjoint;
