import React from 'react';

const Table = (props) => {
    const {
        headerWithHeight,
        isTable1,
        tableTitle, 
        data 
    } = props;

    return (
        <table style={{ width: '100%' }} >
            <tr>
                <th colSpan={2} style={{ color: 'rgb(88,92,102)', textAlign: 'center', background: 'rgb(216,216,216)', padding: 17, borderRadius: '5px 5px 0 0', height: 80 }} >{tableTitle}</th>
            </tr>
            {
                !isTable1 && data.map((row, index) => {
                    return (
                        <tr style={{ borderBottom: `${(index === data.length - 1) ? "5" : "0"}px solid #D8D8D8` }} >
                            <td style={{ textAlign: 'center', padding: 6, background: (index % 2 === 0) ? "rgb(249,249,249)" : "white" }} width="40%">{row.label}</td>
                            <td style={{ textAlign: 'right', padding: 6, background: (index % 2 === 0) ? "rgb(246,246,246)" : "rgb(249,249,249)", fontWeight: 'bold', paddingRight: '15px' }} width="50%" >{row.value}</td>
                        </tr>
                    )
                })
            }
            {
                isTable1 && data.map((row, index) => {
                    return (
                        <tr style={{ borderBottom: `${(index === data.length - 1) ? "5" : "0"}px solid #D8D8D8` }} >
                            <td style={{ textAlign: 'center', padding: 6, background: (index % 2 === 0) ? "rgb(249,249,249)" : "white" }} width="40%">{row}</td>
                        </tr>
                    )
                })
            }
        </table>
    );
};

export default Table;