import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        marginTop:5
    },
    part1: {
        marginTop: 6,
        textAlign: 'left',
        background: 'white',
        fontWeight: 'bold',
        padding: 7,
        borderRadius: 10,
        marginLeft:7
    },
    part2: {
        marginTop: -6,
        color: "#FFFFFF",
        display:"grid",
        gridTemplateColumns:"2fr 1fr",
        width:"85%",
        alignSelf:"center"
    },
    part3: {
        marginTop: -6,
        color: "#FFFFFF",
        display:"grid",
        gridTemplateColumns:"2fr 1fr",
        width:"85%",
        alignSelf:"center",
        marginTop:-2,
    },
    part4: {
        marginTop: -6,
        color: "#FFFFFF",
        display:"grid",
        gridTemplateColumns:"2fr 1fr",
        width:"85%",
        alignSelf:"center",
        marginTop:8,
        marginBottom:15
    }

});

export default useStyles;