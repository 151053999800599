import { blackColor } from "../../all_ppt_render";

const textColor = '666666';

const realLabel = (label, value) => {
    return [
        { text: label, options: { breakLine: true, color: textColor } },
        { text: " ", options: { breakLine: true } },
        { text: `${value} €`, options: { color: blackColor, bold: true } }
    ];
}

const generateTableauBord2 = (
    pptx, 
    slide, 
    x, 
    y, 
    prixCession,
    plusValueBrute,
    apportsHolding,
    donationPP,
    donationNP
) => {
    const width = "14.2%";
    const hauteurBloc = "12%";
    const ecartEntreBloc = 1.75;
    const distance = 0.42;
    
    // Prix de cession de la société
    slide.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
        x: x,
        y: y,
        w: width,
        h: hauteurBloc,
        rectRadius: 0.05,
        line: {
            color: 'D8D8D8'
        }
    });

    slide.addText(realLabel("Prix de cession", `${prixCession}`)
        , {
        x: x,
        y: y,
        fontFace: 'Gill Sans MT',
        w: width,
        h: hauteurBloc,
        valign: 'middle',
        fontSize: 11
    });
    
    slide.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
        x: x + 0.1,
        y: y + distance,
        w: "6%",
        fill: '8E5FA8',
        h: '0.75%',
        rectRadius: 0.05,
        line: {
            color: 'D8D8D8'
        }
    });
    
    // Plus-value Brute
    x += ecartEntreBloc;
    slide.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
        x: x,
        y: y,
        w: width,
        h: hauteurBloc,
        rectRadius: 0.05,
        line: {
            color: 'D8D8D8'
        }
    });

    slide.addText(realLabel("Plus-value brute", `${plusValueBrute}`)
        , {
        x: x,
        y: y,
        fontFace: 'Gill Sans MT',
        w: width,
        h: hauteurBloc,
        valign: 'middle',
        fontSize: 11
    });
    
    slide.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
        x: x + 0.1,
        y: y + distance,
        w: "6%",
        fill: '318FCC',
        h: '0.75%',
        rectRadius: 0.05,
        line: {
            color: 'D8D8D8'
        }
    });
    
    // Apports à Holding
    x += 3.65;
    slide.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
        x: x,
        y: y,
        w: width,
        h: hauteurBloc,
        rectRadius: 0.05,
        line: {
            color: 'D8D8D8'
        }
    });

    slide.addText(realLabel("Apports à Holding", `${apportsHolding}`)
        , {
        x: x,
        y: y,
        fontFace: 'Gill Sans MT',
        w: width,
        h: hauteurBloc,
        valign: 'middle',
        fontSize: 11
    });
    
    slide.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
        x: x + 0.1,
        y: y + distance,
        w: "6%",
        fill: textColor,
        h: '0.75%',
        rectRadius: 0.05,
        line: {
            color: 'D8D8D8'
        }
    });
    
    // Donation en PP
    x += ecartEntreBloc;
    slide.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
        x: x,
        y: y,
        w: width,
        h: hauteurBloc,
        rectRadius: 0.05,
        line: {
            color: 'D8D8D8'
        }
    });

    slide.addText(realLabel("Donation en PP", `${donationPP}`)
        , {
        x: x,
        y: y,
        fontFace: 'Gill Sans MT',
        w: width,
        h: hauteurBloc,
        valign: 'middle',
        fontSize: 11
    });
    
    slide.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
        x: x + 0.1,
        y: y + distance,
        w: "6%",
        fill: textColor,
        h: '0.75%',
        rectRadius: 0.05,
        line: {
            color: 'D8D8D8'
        }
    });
    
    // Donation en NP
    x += ecartEntreBloc;
    slide.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
        x: x,
        y: y,
        w: width,
        h: hauteurBloc,
        rectRadius: 0.05,
        line: {
            color: 'D8D8D8'
        }
    });

    slide.addText(realLabel("Donation en NP", `${donationNP}`)
        , {
        x: x,
        y: y,
        fontFace: 'Gill Sans MT',
        w: width,
        h: hauteurBloc,
        valign: 'middle',
        fontSize: 11
    });
    
    slide.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
        x: x + 0.1,
        y: y + distance,
        w: "6%",
        fill: textColor,
        h: '0.75%',
        rectRadius: 0.05,
        line: {
            color: 'D8D8D8'
        }
    });
    
};

export default generateTableauBord2;