import React, { useEffect, useContext, useState, Fragment } from 'react';
import { CFormGroup, CLabel } from '@coreui/react';
import { InputCurrency, InputRate, InputSelect, InputText } from '../../../../../components/input';
import { CessionContext } from '../../CessionHaut';
import { numberFormat } from '../../../../../extra/all_ppt_render';
import { fetchAllResult, getData } from '../ProjetCessionSociete';
import Loading from '../../../../../components/loader';
import { calculPP } from '../../IdentiteActionnaire/IdentiteActionnaire';
import ToggleSwitch from '../../../../../components/SwitchGreen/SwitchGreen';
import InfoBulle from '../../../../../components/InfoBulle/InfoBulle';
import { formatToUniqueId } from '../../../../../utils';

export const structurationTotalSynthese = (dataFrom, conjoints) => {
    const total = new Object();
    const sommeEnfantsPP = new Object();
    const sommeEnfantsUSUF1 = new Object();
    const sommeEnfantsUSUF2 = new Object();
    const somme = new Object();

    // Somme des enfants PP
    sommeEnfantsPP.pourcentageDetention = typeof dataFrom["somme_enfant_pp"].repartition_en_pourcentage === 'number' ? dataFrom["somme_enfant_pp"].repartition_en_pourcentage : parseFloat(dataFrom["somme_enfant_pp"].repartition_en_pourcentage).toFixed(2);
    sommeEnfantsPP.valeurTitres = parseInt(dataFrom["somme_enfant_pp"].valeur_titre, 10);
    sommeEnfantsPP.prixRevientFiscal = parseInt(dataFrom["somme_enfant_pp"].prix_de_revient_fiscale, 10);
    sommeEnfantsPP.plusValueBrute = parseInt(dataFrom["somme_enfant_pp"].plus_valeur_brut, 10);

    // Somme des USUF1
    sommeEnfantsUSUF1.pourcentageDetention = typeof dataFrom["somme_enfant_np_c1"].repartition_en_pourcentage === 'number' ? dataFrom["somme_enfant_np_c1"].repartition_en_pourcentage : parseFloat(dataFrom["somme_enfant_np_c1"].repartition_en_pourcentage).toFixed(2);
    sommeEnfantsUSUF1.valeurTitres = parseInt(dataFrom["somme_enfant_np_c1"].valeur_titre, 10);
    sommeEnfantsUSUF1.prixRevientFiscal = parseInt(dataFrom["somme_enfant_np_c1"].prix_de_revient_fiscale, 10);
    sommeEnfantsUSUF1.plusValueBrute = parseInt(dataFrom["somme_enfant_np_c1"].plus_valeur_brut, 10);

    // Somme des USUF2 au cas où il y de conjoint2
    if (conjoints[0].marieOuPasce === "Oui") {
        sommeEnfantsUSUF2.pourcentageDetention = typeof dataFrom["somme_enfant_np_c2"].repartition_en_pourcentage ? dataFrom["somme_enfant_np_c2"].repartition_en_pourcentage : parseFloat(dataFrom["somme_enfant_np_c2"].repartition_en_pourcentage).toFixed(2);
        sommeEnfantsUSUF2.valeurTitres = parseInt(dataFrom["somme_enfant_np_c2"].valeur_titre, 10);
        sommeEnfantsUSUF2.prixRevientFiscal = parseInt(dataFrom["somme_enfant_np_c2"].prix_de_revient_fiscale, 10);
        sommeEnfantsUSUF2.plusValueBrute = parseInt(dataFrom["somme_enfant_np_c2"].plus_valeur_brut, 10);
    }

    // Somme total
    somme.pourcentageDetention = typeof dataFrom["somme_tous"].repartition_en_pourcentage ? dataFrom["somme_tous"].repartition_en_pourcentage : parseFloat(dataFrom["somme_tous"].repartition_en_pourcentage).toFixed(2);
    somme.valeurTitres = parseInt(dataFrom["somme_tous"].valeur_titre, 10);
    somme.prixRevientFiscal = parseInt(dataFrom["somme_tous"].prix_de_revient_fiscale, 10);
    somme.plusValueBrute = parseInt(dataFrom["somme_tous"].plus_valeur_brut, 10);

    total.pp = sommeEnfantsPP;
    total.np1 = sommeEnfantsUSUF1;
    total.np2 = sommeEnfantsUSUF2;
    total.somme = somme;

    return total;
};

const EnfantConjointHolding = (props) => {
    const regimeFiscalData = [
        "Non éligible",
        "Eligible"
    ];

    const regimeFiscalHoldingData = [
        "Régime IS de droit commun",
        "Régime des PV LT",
    ];

    const anneeOuvertureHoldingData = [
        2020,
        2021,
        "2022 et suivants",
    ];

    const { prenomConjoint1, prenomConjoint2 } = props;

    const { cessionHaut, setCessionHaut } = useContext(CessionContext);
    const { identiteActionnaire: identite, projetCessionSociete: projet, operationsAvantCession: operations } = cessionHaut;
    const { conjoints, enfants, dateSimulation } = identite;
    const { holding, prixCession, conjoints: conjointsP, enfantsFamiliauxPP: enfantsP, enfantsFamiliauxDP } = projet;
    const [loading, setLoading] = useState(false);
    const isSimulationExistante = cessionHaut.idSimulation;

    const marginTopBetweenForm = 5;

    const handleNomPersonnaliseHoldingChange = (event) => {
        const { value } = event.target;
        projet.holding.nomPersonnalise = value;
        setCessionHaut((prevState) => {
            return {
                ...prevState,
                projetCessionSociete: projet
            }
        });
    };


    const handleEnfantsConjointHoldingChange = async (event, prop, index) => {
        const { value } = event.target;
        const withConjoint2 = conjoints[0].marieOuPasce === 'Oui';
        if (props.type === 'CONJOINT') projet.conjoints[index][prop] = value;
        else if (props.type === 'ENFANT') projet.enfantsFamiliauxPP[index][prop] = value;
        else if (props.type === 'HOLDING') projet.holding[prop] = value;

        if (prop === 'pourcentageDetention' || prop === 'prixRevientFiscal') {
            setLoading(true);
            const oldData = await getData(fetchAllResult(conjoints, enfants, projet, operations));
            const dataFrom = oldData.resultatFormulaire;
            setLoading(false);

            if (props.type === 'CONJOINT') projet.conjoints[index]['valeurTitres'] = numberFormat(dataFrom[`conjoint${index + 1}_pp`].valeur_titre);
            else if (props.type === 'ENFANT') projet.enfantsFamiliauxPP[index]['valeurTitres'] = numberFormat(dataFrom["table_enfant_pp"][index].valeur_titre);
            else if (props.type === 'HOLDING') projet.holding['valeurTitres'] = numberFormat(dataFrom["somme_holding"].valeur_titre);

            const pp = calculPP(projet.conjoints, projet.enfantsFamiliauxPP, holding, conjoints);

            projet.actionnairesFamiliauxPP = pp.pp;
            projet.total = structurationTotalSynthese(dataFrom, conjoints);
        }

        if (props.type === "CONJOINT" && prop === 'pourcentageDetention') {
            if (index === 0) {
                projet.conjoints[0].ipv.default = (parseFloat(value) * 100) /
                    (parseFloat(value) + (withConjoint2 ? parseFloat(projet.conjoints[1].pourcentageDetention !== "" ? projet.conjoints[1].pourcentageDetention : 0) : 0));
            }
            else {
                projet.conjoints[0].ipv.default = (parseFloat(projet.conjoints[0].pourcentageDetention !== "" ? projet.conjoints[0].pourcentageDetention : 0) * 100) /
                    (parseFloat(value) + (parseFloat(projet.conjoints[0].pourcentageDetention !== "" ? projet.conjoints[0].pourcentageDetention : 0)));
            }

            if (!props.isIpv1RealUpdated) {
                projet.conjoints[0].ipv.real = projet.conjoints[0].ipv.default;
            }
        }

        setCessionHaut((prevState) => ({
            ...prevState,
            projetCessionSociete: projet
        }));
    };

    const findPosByPrenom = (prenom) => {
        return enfants.findIndex((element) => element.prenom === prenom);
    };

    useEffect(() => {
        if (prixCession !== "") {
            setLoading(true);
            getData(fetchAllResult(conjoints, enfants, projet, operations))
                .then((response) => {
                    const conjoint1PP = response.resultatFormulaire.conjoint1_pp;
                    const conjoint2PP = response.resultatFormulaire.conjoint2_pp;
                    const enfantsPP = response.resultatFormulaire.table_enfant_pp;
                    const enfantsNP1 = response.resultatFormulaire.table_enfant_np_conjoint1;
                    const enfantsNP2 = response.resultatFormulaire.table_enfant_np_conjoint2;
                    const hold = response.resultatFormulaire.somme_holding;

                    // Pour les conjoints PP
                    projet.conjoints[0].valeurTitres = conjoint1PP && conjoint1PP.valeur_titre ? conjoint1PP.valeur_titre : 0;
                    projet.conjoints[1].valeurTitres = conjoint2PP && conjoint2PP.valeur_titre ? conjoint2PP.valeur_titre : 0;

                    // Pour les enfants PP
                    projet.enfantsFamiliauxPP.map((data, index) => {
                        projet.enfantsFamiliauxPP[index].valeurTitres = enfantsPP[index].valeur_titre;
                    });

                    // Pour les enfants NP1
                    enfantsNP1 && enfantsNP1.map((data, index) => {
                        projet.enfantsFamiliauxDP[0].enfants[findPosByPrenom(data.nom)].valeurTitres = data.valeur_titre;
                    });

                    /*projet.enfantsFamiliauxDP[0].enfants.map((data, index) => {
                        projet.enfantsFamiliauxDP[0].enfants[index].valeurTitres = enfantsNP1[index].valeur_titre;
                    });*/

                    // Pour les enfants NP2
                    enfantsNP2 && enfantsNP2.map((data, index) => {
                        projet.enfantsFamiliauxDP[1].enfants[findPosByPrenom(data.nom)].valeurTitres = data.valeur_titre;
                    });

                    /*projet.enfantsFamiliauxDP[1].enfants.map((data, index) => {
                        projet.enfantsFamiliauxDP[1].enfants[index].valeurTitres = enfantsNP2[index].valeur_titre;
                    });*/

                    // Pour le HOLDING
                    projet.holding.pourcentageDetention = hold && hold.repartition_en_pourcentage ? hold.repartition_en_pourcentage : 0;
                    projet.holding.valeurTitres = hold && hold.valeur_titre ? hold.valeur_titre : 0;
                    projet.holding.prixRevientFiscal = hold && hold.prix_de_revient_fiscale ? hold.prix_de_revient_fiscale : 0;

                    // Pour le TOTAL
                    projet.total = structurationTotalSynthese(response["resultatFormulaire"], conjoints);
                    setCessionHaut((prevState) => ({
                        ...prevState,
                        projetCessionSociete: projet
                    }));
                })
                .catch((error) => console.log(error))
                .finally(() => setLoading(false))
        }
    }, [prixCession]);

    const style = {
        height: '33.5px',
        display: 'flex',
        alignItems: 'center',
        marginBottom: 5,
        padding: 0
    }

    const conditionAnnee = dateSimulation ? new Date(dateSimulation) < new Date(2023, 0, 1) : false;
    const conditionAffichageAnneeOuvertureHolding = props.type === 'HOLDING' && isSimulationExistante && conditionAnnee;

    return (
        <>
            {
                props.data && props.data.length > 0 && (
                    <>
                        {loading && <Loading />}
                        <div style={{ display: 'flex', flex: 1, flexDirection: 'row', marginTop: -37, fontSize: 13 }}>
                            <div id="labeLgl" className="col-lg-4 col-md-4 col-sm-4" style={{ display: 'flex', flexDirection: 'column', marginTop: -2, padding:0, maxWidth:363, minWidth:363 }}>
                                <CLabel style={{ ...style, marginBottom: 18 }} className="label_title d-flex flex-direction-row align-items-center">&nbsp;</CLabel>
                                <CLabel style={{ ...style }}>Nom personnalisé</CLabel>
                                <CLabel style={{ ...style }}>% Detention en PP</CLabel>
                                <CLabel style={{ ...style }}>Valeur des titres</CLabel>
                                <CLabel style={{ ...style }}>Prix de revient fiscal</CLabel>
                                {
                                    props.type === "CONJOINT" && (
                                        <CLabel style={{ ...style, fontWeight: 'bold', textDecoration: 'underline', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'left' }}>Régime fiscal de faveur</CLabel>
                                    )
                                }
                                {
                                    props.type !== 'HOLDING' ? (
                                        <CLabel style={{ ...style }}>{props.type === "CONJOINT" ? "Eligible à l'abattement de 85% ?" : "Régime fiscal : éligible à l'abattement de 85% ?"}</CLabel>
                                    ) : (
                                        <CLabel style={{ ...style }} >Régime fiscal</CLabel>
                                    )
                                }
                                {
                                    props.type === 'CONJOINT' && <CLabel style={{ ...style }}>% éligibilité à l'abattement de 85%</CLabel>
                                }
                                {
                                    props.type === 'CONJOINT' && <CLabel style={{ ...style }}>Eligible à l'abattement de 500 000 € ?</CLabel>
                                }
                                {
                                    conditionAffichageAnneeOuvertureHolding && <CLabel style={{ ...style }} >Année d'ouverture de l'exercice de cession</CLabel>
                                }
                            </div>
                            <div id="form_benificiaire" style={{ overflowX: 'auto', display: 'flex', flexDirection: 'column', flex: 1, marginTop:(projet.enfantsFamiliauxPP.length == 0) ? 0 : 10 }}>
                                <CLabel className="label_title d-flex flex-direction-row align-items-center mt-1">&nbsp;</CLabel>

                                <CFormGroup className="form-inline" style={{ display: 'flex', flexWrap: 'nowrap', flexDirection: 'row' }}>
                                    {
                                        props.data.map((data, index) => {
                                            let prenom = '';

                                            if(props.type !== 'HOLDING'){
                                                if(props.type === 'CONJOINT'){
                                                    prenom = ![null, undefined, ""].includes(conjoints[index].nomPersonnalise) ? conjoints[index].nomPersonnalise : `Conjoint ${index+1}`
                                                }
                                                else{
                                                    prenom = ![null, undefined, ""].includes(enfants[index].prenom) ? enfants[index].prenom : `Enfant ${index+1}`
                                                }
                                            }
                                            else{
                                                prenom = ![null, undefined, ""].includes(data.nomPersonnalise) ? data.nomPersonnalise : 'Holding is'
                                            }

                                            return (
                                                <div key={index} className={`col-12 col-xxl-3 col-xl-3 col-lg-2 col-md-4 col-sm-4 px-0   ${index > 0 ? "ml-2" : ""} `} style={{ marginTop: "-34px" }}>
                                                    <div
                                                        style={{
                                                            borderBottom: props.type === 'CONJOINT' ? index === 0 ? '5px solid rgb(23, 157, 199)' : '5px solid rgb(244, 127, 79)' : "5px solid gray",
                                                            fontWeight: 'bold',
                                                            color: "#000000",
                                                            padding: 10,
                                                            height: 30,
                                                            marginBottom: 10,
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center'
                                                        }}
                                                        className="text-center col-lg-12"
                                                        id="benificiaire_num"
                                                    >
                                                        {prenom.toString().toUpperCase()}
                                                    </div>
                                                    <CFormGroup className="form-inline " style={{ justifyContent: 'center', paddingRight: 0 }}>
                                                        <InputText
                                                            id={`${formatToUniqueId("EnfantConjoinHolding nomPersonnalise")}-text-${index}`}
                                                            placeholder={ props.type === 'HOLDING' ? 'HOLDING IS' : '' }
                                                            disabled={props.type !== 'HOLDING'}
                                                            value={props.type === "ENFANT" ? (enfants[index].prenom !== "" ? enfants[index].prenom : `Enfant ${index + 1}`) : props.type === "HOLDING" ? holding.nomPersonnalise : (conjoints[index].nomPersonnalise !== "" ? conjoints[index].nomPersonnalise : `Conjoint ${index + 1}`)}
                                                            className="col-lg-12 col-md-12 col-sm-12"
                                                            // L'évènement suivant est uniquement pour le HOLDING
                                                            onChange={handleNomPersonnaliseHoldingChange}
                                                        />
                                                    </CFormGroup>
                                                </div>
                                            )
                                        })
                                    }
                                </CFormGroup>

                                <CFormGroup className="form-inline" style={{ display: 'flex', flexWrap: 'nowrap', flexDirection: 'row' }}>
                                    {
                                        props.data.map((data, index) => {
                                            return (
                                                <div key={index} className={`col-12 col-xxl-3 col-xl-3 col-lg-2 col-md-4 col-sm-4 px-0   ${index > 0 ? "ml-2" : ""} `} >
                                                    <CFormGroup className="form-inline " style={{ justifyContent: 'end', paddingRight: 0 }}>
                                                        <InputRate
                                                            id={`${formatToUniqueId("EnfanConjoinHolding detention")}-rate-${index}`}
                                                            key={index}
                                                            className="col-lg-6"
                                                            value={data.pourcentageDetention}
                                                            onChange={(event) => handleEnfantsConjointHoldingChange(event, 'pourcentageDetention', index)}
                                                        />
                                                    </CFormGroup>
                                                </div>
                                            )
                                        })
                                    }
                                </CFormGroup>

                                <CFormGroup className="form-inline" style={{ display: 'flex', flexWrap: 'nowrap', flexDirection: 'row' }}>
                                    {
                                        props.data.map((data, index) => {
                                            return (
                                                <div key={index} className={`col-12 col-xxl-3 col-xl-3 col-lg-2 col-md-4 col-sm-4 px-0   ${index > 0 ? "ml-2" : ""} `} >
                                                    <CFormGroup className="form-inline " style={{ justifyContent: 'center', paddingRight: 0 }}>
                                                        <InputCurrency
                                                            disabled={true}
                                                            key={index}
                                                            id={`${formatToUniqueId("EnfanConjoinHolding prixRevientFiscali")}-${index}`}
                                                            className="col-lg-12 col-md-12 col-sm-12"
                                                            value={data.valeurTitres}
                                                            value={
                                                                props.type === 'CONJOINT' ? cessionHaut.projetCessionSociete.conjoints[index].valeurTitres :
                                                                    props.type === 'ENFANT' ? cessionHaut.projetCessionSociete.enfantsFamiliauxPP[index].valeurTitres :
                                                                        cessionHaut.projetCessionSociete.holding.valeurTitres
                                                            }
                                                        />

                                                    </CFormGroup>
                                                </div>
                                            )
                                        })
                                    }
                                </CFormGroup>

                                <CFormGroup className="form-inline" style={{ display: 'flex', flexWrap: 'nowrap', flexDirection: 'row' }}>
                                    {
                                        props.data.map((data, index) => {
                                            return (
                                                <div key={index} className={`col-12 col-xxl-3 col-xl-3 col-lg-2 col-md-4 col-sm-4 px-0   ${index > 0 ? "ml-2" : ""} `} >
                                                    <CFormGroup className="form-inline " style={{ justifyContent: 'center', paddingRight: 0 }}>
                                                        <InputCurrency
                                                            id={`${formatToUniqueId("EnfanConjoinHolding prixRevientFiscal")}-${index}`}
                                                            key={index}
                                                            className="col-lg-12 col-md-12 col-sm-12"
                                                            value={data.prixRevientFiscal}
                                                            onChange={(event) => handleEnfantsConjointHoldingChange(event, 'prixRevientFiscal', index)}
                                                        />

                                                    </CFormGroup>
                                                </div>
                                            )
                                        })
                                    }
                                </CFormGroup>
                                {
                                    props.type === 'CONJOINT' && (
                                        <CFormGroup className="form-inline" style={{ display: 'flex', flexWrap: 'nowrap', flexDirection: 'row' }}>
                                            {
                                                props.data.map((data, index) => {
                                                    return (
                                                        <div key={index} style={{ visibility: 'hidden' }} className={`col-12 col-xxl-3 col-xl-3 col-lg-2 col-md-4 col-sm-4 px-0   ${index > 0 ? "ml-2" : ""} `} >
                                                            <CFormGroup className="form-inline " style={{ justifyContent: 'center', paddingRight: 0, marginBottom:10 }}>
                                                                <InputCurrency
                                                                    id={`${formatToUniqueId("EnfanConjoinHolding prixRevientFisca l")}-${index}`}
                                                                    key={index}
                                                                    className="col-lg-12 col-md-12 col-sm-12"
                                                                //value={data.prixRevientFiscal}
                                                                //onChange={ (event) => handleEnfantsConjointHoldingChange(event, 'prixRevientFiscal', index) }
                                                                />
                                                            </CFormGroup>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </CFormGroup>
                                    )
                                }

                                <CFormGroup className="form-inline" style={{ display: 'flex', flexWrap: 'nowrap', flexDirection: 'row',marginTop:(projet.enfantsFamiliauxPP.length == 0) ? 5 : 0 }}>
                                    {
                                        props.data.map((data, index) => {
                                            return (
                                                <>
                                                    <div key={index} className={`col-12 col-xxl-3 col-xl-3 col-lg-2 col-md-4 col-sm-4 px-0 ${index > 0 ? "ml-2" : ""} `} style={{}}>
                                                        <CFormGroup className="form-inline " style={{ justifyContent: 'end', paddingRight: 0,height:28.5 }}>
                                                            {
                                                                props.type !== 'HOLDING' ? (
                                                                    // <InputSelect custom id="regimeFiscal" className="col-lg-12 col-md-12 col-sm-12" onChange={(event) => handleEnfantsConjointHoldingChange(event, 'regimeFiscal', index)} >
                                                                    //     {
                                                                    //         regimeFiscalData.map((donnee, index) => {
                                                                    //             return (
                                                                    //                 <option key={index} selected={donnee === data.regimeFiscal} >{donnee}</option>
                                                                    //             )
                                                                    //         })
                                                                    //     }
                                                                    // </InputSelect>
                                                                    <ToggleSwitch
                                                                        isToggled={data.regimeFiscal === 'Eligible'}
                                                                        onToggleChange={ () => {
                                                                            let value = '';
                                                                            if(data.regimeFiscal === 'Eligible') value = 'Non éligible';
                                                                            else value = 'Eligible';

                                                                            if(props.type === 'CONJOINT'){
                                                                                projet.conjoints[index].regimeFiscal = value;
                                                                            }
                                                                            else{
                                                                                // Pour les enfants
                                                                                projet.enfantsFamiliauxPP[index].regimeFiscal = value;
                                                                            }

                                                                            setCessionHaut((prevState) => {
                                                                                return {
                                                                                    ...prevState,
                                                                                    projetCessionSociete: projet
                                                                                }
                                                                            });
                                                                        }}
                                                                    />

                                                                ) : (
                                                                    <InputSelect id={`${formatToUniqueId("EnfaConjoinHolding regimeFiscal")}-select-${index}`} custom value={holding.regimeFiscal} className="col-lg-12 col-md-12 col-sm-12" onChange={(event) => handleEnfantsConjointHoldingChange(event, 'regimeFiscal', index)} >
                                                                        {
                                                                            regimeFiscalHoldingData.map((data, index) => {
                                                                                return (
                                                                                    <option key={index} selected={data === holding.regimeFiscal} >{data}</option>
                                                                                )
                                                                            })
                                                                        }
                                                                    </InputSelect>
                                                                )
                                                            }
                                                        </CFormGroup>
                                                    </div>
                                                    {
                                                        props.type !== 'HOLDING' &&  (((props.data.length - 1) == index  ) || (props.data.length == 1)) && (
                                                            <InfoBulle
                                                                information="Régime des cessions de titres de PME acquis au cours des 10 ères années de la création de la société et détenus depuis plus de 8 ans."
                                                            />
                                                        )
                                                    }
                                                </>
                                            )
                                        })
                                    }
                                </CFormGroup>
                                {
                                    props.type === 'CONJOINT' && (
                                        <CFormGroup className="form-inline" style={{ display: 'flex', flexWrap: 'nowrap', flexDirection: 'row' }}>
                                            {
                                                props.data.map((data, index) => {
                                                    return (
                                                        <div key={index} className={`col-12 col-xxl-3 col-xl-3 col-lg-2 col-md-4 col-sm-4 px-0   ${index > 0 ? "ml-2" : ""} `} >
                                                            <CFormGroup className="form-inline " style={{ justifyContent: 'end', paddingRight: 0 }}>
                                                                <InputRate
                                                                    id={`${formatToUniqueId("EnfanConjoinHolding pourcentage85")}-rate-${index}`}
                                                                    disabled={data.regimeFiscal === "Non éligible"}
                                                                    className="col-lg-6"
                                                                    value={data.regimeFiscal === "Non éligible" ? 0 : data.pourcentage85}
                                                                    onChange={(event) => handleEnfantsConjointHoldingChange(event, 'pourcentage85', index)}
                                                                />
                                                            </CFormGroup>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </CFormGroup>
                                    )
                                }
                                {
                                    props.type === 'CONJOINT' && (
                                        <CFormGroup className="form-inline" style={{ display: 'flex', flexWrap: 'nowrap', flexDirection: 'row', marginBottom: 18 }}>
                                            {
                                                props.data.map((data, index) => {
                                                    return (
                                                        <>
                                                            <div key={index} className={`col-12 col-xxl-3 col-xl-3 col-lg-2 col-md-4 col-sm-4 px-0 ${index > 0 ? "ml-2" : ""} `} >
                                                                <CFormGroup className="form-inline " style={{ justifyContent: 'end', paddingRight: 0, height:24.5 }}>
                                                                    {/* <InputSelect custom onChange={(event) => handleEnfantsConjointHoldingChange(event, 'eligible500', index)} >
                                                                        {
                                                                            regimeFiscalData.map((donnee, i) => {
                                                                                return <option key={i} selected={donnee === data.eligible500} >{donnee}</option>
                                                                            })
                                                                        }
                                                                    </InputSelect> */}
                                                                    <ToggleSwitch
                                                                        isToggled={data.eligible500 === 'Eligible'}
                                                                        onToggleChange={ () => {
                                                                            let value = '';

                                                                            if(data.eligible500 === 'Eligible') value = 'Non éligible'
                                                                            else value = 'Eligible';

                                                                            projet.conjoints[index].eligible500 = value;

                                                                            setCessionHaut((prevState) => {
                                                                                return {
                                                                                    ...prevState,
                                                                                    projetCessionSociete: projet
                                                                                }
                                                                            });

                                                                        } }
                                                                    />
                                                                </CFormGroup>
                                                            </div>
                                                            {
                                                                index == 1 && (
                                                                    <InfoBulle
                                                                        information="Régime des cessions de titres de PME réalisées jusqu’au 31/12/2024 par des dirigeants prenant leur retraite."
                                                                    />
                                                                )
                                                            }

                                                        </>
                                                    )
                                                })
                                            }
                                        </CFormGroup>
                                    )
                                }
                                {
                                    conditionAffichageAnneeOuvertureHolding && (
                                        <CFormGroup className="form-inline" style={{ display: 'flex', flexWrap: 'nowrap', flexDirection: 'row', marginTop: 5 }}>
                                            {
                                                props.data.map((data, index) => {
                                                    return (
                                                        <div key={index} className={`col-12 col-xxl-3 col-xl-3 col-lg-2 col-md-4 col-sm-4 px-0 ${index > 0 ? "ml-2" : ""} `} >
                                                            <CFormGroup className="form-inline " style={{ justifyContent: 'end', paddingRight: 0 }}>
                                                                <InputSelect id={`${formatToUniqueId("EnfaConjoinHolding regimeFiscal 2")}-select-${index}`} custom className="col-lg-6" onChange={(event) => handleEnfantsConjointHoldingChange(event, 'anneeOuverture', index)} >
                                                                    {
                                                                        anneeOuvertureHoldingData.map((data, index) => {
                                                                            return (
                                                                                <option key={index} selected={data === holding.anneeOuverture} >{data}</option>
                                                                            )
                                                                        })
                                                                    }
                                                                </InputSelect>
                                                            </CFormGroup>
                                                        </div>

                                                    )
                                                })
                                            }
                                        </CFormGroup>
                                    )
                                }
                            </div>
                        </div>
                        {/*<div id="form_benificiaire" style={{overflowX: 'auto', display:'flex', flexDirection:'column', flex: 1}}>
                    <CLabel className="label_title d-flex flex-direction-row align-items-center mt-1">&nbsp;</CLabel>

                    <CFormGroup className="form-inline" style={{display:'flex', flexWrap:'nowrap', flexDirection:'row'}}>
                    <CLabel className="col-lg-4 col-md-6 col-sm-7" >Nom personnalisé</CLabel>
                        {
                            props.data.map((data, index) => {
                                return(
                                    <div key={index} className={`col-12 col-xxl-2 col-xl-2 col-lg-2 col-md-3 col-sm-3 pl-0 ${ index > 0 ? "ml-2" : ""} `} style={{marginTop:"-34px"}}>
                                        <div style={{ borderBottom: `5px solid ${ props.type === "CONJOINT" ? index === 0 ? "rgb(127,144,188)" : "rgb(212,114,94)" : "gray" }`, fontWeight: 'bold', background: "#FFFFFF", color: "#000000", padding: 10,height: 30, marginBottom: 10, display: 'flex', justifyContent: 'center', alignItems: 'center' }} className="text-center col-lg-12" id="benificiaire_num"> {` ${props.type !== "HOLDING" ? props.type +" "+ (index + 1) : "HOLDING" }`}</div>
                                        <CFormGroup className="form-inline " style={{justifyContent: 'center', paddingRight: 0 }}>
                                            <InputText
                                                disabled={true}
                                                value={ props.type === "ENFANT" ? enfants[index].prenom : props.type === "HOLDING" ? "HOLDING IS" : conjoints[index].nomPersonnalise}
                                                className="col-lg-12 col-md-12 col-sm-12"
                                            />
                                        </CFormGroup>
                                    </div>
                                )
                            })
                        }
                    </CFormGroup>
                    <CFormGroup className="form-inline" style={{display:'flex', flexWrap:'nowrap', flexDirection:'row'}}>
                        <CLabel className="col-lg-4 col-md-6 col-sm-7" >% detention</CLabel>
                        {
                            props.data.map((data, index) => {
                                return(
                                    <div key={index} className={`col-12 col-xxl-2 col-xl-2 col-lg-2 col-md-3 col-sm-3 pl-0 ${ index > 0 ? "ml-2" : ""} `} >
                                        <CFormGroup className="form-inline " style={{justifyContent: 'center', paddingRight: 0 }}>
                                        <InputRate
                                            key={index}
                                            id="detention"
                                            className="col-lg-12 col-md-12 col-sm-12"
                                            value={data.pourcentageDetention}
                                            onChange={ (event) => handleEnfantsConjointHoldingChange(event, 'pourcentageDetention', index)}
                                        />
                                        </CFormGroup>
                                    </div>
                                )
                            })
                        }
                    </CFormGroup>
                    <CFormGroup className="form-inline" style={{display:'flex', flexWrap:'nowrap', flexDirection:'row'}}>
                        <CLabel className="col-lg-4 col-md-6 col-sm-7" >Valeur des titres</CLabel>
                        {
                            props.data.map((data, index) => {
                                return(
                                    <div key={index} className={`col-12 col-xxl-2 col-xl-2 col-lg-2 col-md-3 col-sm-3 pl-0 ${ index > 0 ? "ml-2" : ""} `} >
                                        <CFormGroup className="form-inline " style={{justifyContent: 'center', paddingRight: 0 }}>
                                        <InputCurrency
                                            disabled={true}
                                            key={index}
                                            id="valeurTitre"
                                            className="col-lg-12 col-md-12 col-sm-12"
                                            value={data.valeurTitres}
                                            value={
                                                props.type === 'CONJOINT' ? cessionHaut.projetCessionSociete.conjoints[index].valeurTitres :
                                                props.type === 'ENFANT' ? cessionHaut.projetCessionSociete.enfantsFamiliauxPP[index].valeurTitres :
                                                cessionHaut.projetCessionSociete.holding.valeurTitres
                                            }
                                        />
                                        </CFormGroup>
                                    </div>
                                )
                            })
                        }
                    </CFormGroup>
                    <CFormGroup className="form-inline" style={{display:'flex', flexWrap:'nowrap', flexDirection:'row'}}>
                        <CLabel className="col-lg-4 col-md-6 col-sm-7" >Prix de revient fiscal</CLabel>
                        {
                            props.data.map((data, index) => {
                                return(
                                    <div key={index} className={`col-12 col-xxl-2 col-xl-2 col-lg-2 col-md-3 col-sm-3 pl-0 ${ index > 0 ? "ml-2" : ""} `} >
                                        <CFormGroup className="form-inline " style={{justifyContent: 'center', paddingRight: 0 }}>
                                        <InputCurrency
                                            key={index}
                                            id="prixRevientFiscal"
                                            className="col-lg-12 col-md-12 col-sm-12"
                                            value={data.prixRevientFiscal}
                                            onChange={ (event) => handleEnfantsConjointHoldingChange(event, 'prixRevientFiscal', index) }
                                        />
                                        </CFormGroup>
                                    </div>
                                )
                            })
                        }
                    </CFormGroup>
                    {
                        props.type === "CONJOINT" && (
                            <>
                                <br></br>
                                <CFormGroup className="form-inline" style={{display:'flex', flexWrap:'nowrap', flexDirection:'row'}}>
                                    <CLabel className="col-lg-4 col-md-6 col-sm-7" style={{ fontWeight: 'bold' }} ><u>Régime fiscal de valeur ?</u></CLabel>
                                </CFormGroup>
                            </>
                        )
                    }
                    {
                        props.type !== "HOLDING" ? (
                            <>
                                <CFormGroup className="form-inline" style={{display:'flex', flexWrap:'nowrap', flexDirection:'row'}}>
                                    <CLabel className="col-lg-4 col-md-6 col-sm-7" >
                                        {
                                            props.type === "CONJOINT" ? "Eligible à l'abattement de 85% ?" : "Régime fiscal : éligible à l'abattement de 85% ?"
                                        }
                                    </CLabel>
                                    {
                                        props.data.map((data, index) => {
                                            return(
                                                <div key={index} className={`col-12 col-xxl-2 col-xl-2 col-lg-2 col-md-3 col-sm-3 pl-0 ${ index > 0 ? "ml-2" : ""} `} >
                                                    <CFormGroup className="form-inline " style={{justifyContent: 'center', paddingRight: 0 }}>
                                                        <InputSelect custom id="regimeFiscal" className="col-lg-12 col-md-12 col-sm-12" onChange={ (event) => handleEnfantsConjointHoldingChange(event, 'regimeFiscal', index) } >
                                                            {
                                                                regimeFiscalData.map((donnee, index) => {
                                                                    return (
                                                                        <option key={index} selected={ donnee === data.regimeFiscal } >{donnee}</option>
                                                                    )
                                                                })
                                                            }
                                                        </InputSelect>
                                                    </CFormGroup>
                                                </div>
                                            )
                                        })
                                    }
                                </CFormGroup>
                                {
                                    props.type === "CONJOINT" && (
                                        <>
                                            <CFormGroup className="form-inline" style={{display:'flex', flexWrap:'nowrap', flexDirection:'row'}}>
                                                <CLabel className="col-lg-4 col-md-6 col-sm-7" >
                                                    % éligibilité
                                                </CLabel>
                                                {
                                                    props.data.map((data, index) => {
                                                        return(
                                                            <div key={index} style={{ visibility: data.regimeFiscal === "Non éligible" ? "hidden" : "visible" }} className={`col-12 col-xxl-2 col-xl-2 col-lg-2 col-md-3 col-sm-3 pl-0 ${ index > 0 ? "ml-2" : ""} `} >
                                                                <CFormGroup className="form-inline " style={{justifyContent: 'center', paddingRight: 0 }}>
                                                                    <InputRate
                                                                        className="col-lg-12 col-md-12 col-sm-12"
                                                                        value={data.pourcentage85}
                                                                        onChange={ (event) => handleEnfantsConjointHoldingChange(event, 'pourcentage85', index) }
                                                                    />
                                                                </CFormGroup>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </CFormGroup>
                                            <CFormGroup className="form-inline" style={{display:'flex', flexWrap:'nowrap', flexDirection:'row'}}>
                                                <CLabel className="col-lg-4 col-md-6 col-sm-7" >
                                                    Eligible à l'abattement
                                                    de 500 000 € ?
                                                </CLabel>
                                                {
                                                    props.data.map((data, index) => {
                                                        return(
                                                            <div key={index} className={`col-12 col-xxl-2 col-xl-2 col-lg-2 col-md-3 col-sm-3 pl-0 ${ index > 0 ? "ml-2" : ""} `} >
                                                                <CFormGroup className="form-inline " style={{justifyContent: 'center', paddingRight: 0 }}>
                                                                    <InputSelect custom onChange={ (event) => handleEnfantsConjointHoldingChange(event, 'eligible500', index) } >
                                                                        {
                                                                            regimeFiscalData.map((donnee, i) => {
                                                                                return <option key={i} selected={ donnee === data.eligible500 } >{donnee}</option>
                                                                            })
                                                                        }
                                                                    </InputSelect>
                                                                </CFormGroup>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </CFormGroup>
                                        </>
                                    )
                                }
                            </>
                        ) : (
                            <>
                                <CFormGroup className="form-inline" style={{display:'flex', flexWrap:'nowrap', flexDirection:'row'}}>
                                    <CLabel className="col-lg-4 col-md-6 col-sm-7" >
                                        Régime fiscal
                                    </CLabel>
                                    {
                                        props.data.map((donnee, index) => {
                                            return(
                                                <div key={index} className={`col-12 col-xxl-2 col-xl-2 col-lg-2 col-md-3 col-sm-3 pl-0 ${ index > 0 ? "ml-2" : ""} `} >
                                                    <CFormGroup className="form-inline " style={{justifyContent: 'center', paddingRight: 0 }}>
                                                        <InputSelect custom id="regimeFiscal" value={holding.regimeFiscal} className="col-lg-12 col-md-12 col-sm-12" onChange={ (event) => handleEnfantsConjointHoldingChange(event, 'regimeFiscal', index) } >
                                                            {
                                                                regimeFiscalHoldingData.map((data, index) => {
                                                                    return (
                                                                        <option key={index} selected={ data === holding.regimeFiscal } >{data}</option>
                                                                    )
                                                                })
                                                            }
                                                        </InputSelect>
                                                    </CFormGroup>
                                                </div>
                                            )
                                        })
                                    }
                                </CFormGroup>
                                <CFormGroup className="form-inline" style={{display:'flex', flexWrap:'nowrap', flexDirection:'row'}}>
                                    <CLabel className="col-lg-4 col-md-6 col-sm-7" >
                                        Année d'ouverture de l'exercice de cession
                                    </CLabel>
                                    {
                                        props.data.map((data, index) => {
                                            return(
                                                <div key={index} className={`col-12 col-xxl-2 col-xl-2 col-lg-2 col-md-3 col-sm-3 pl-0 ${ index > 0 ? "ml-2" : ""} `} >
                                                    <CFormGroup className="form-inline " style={{justifyContent: 'center', paddingRight: 0 }}>
                                                        <InputSelect custom id="regimeFiscal" className="col-lg-12 col-md-12 col-sm-12" onChange={ (event) => handleEnfantsConjointHoldingChange(event, 'anneeOuverture', index) } >
                                                            {
                                                                anneeOuvertureHoldingData.map((data, index) => {
                                                                    return (
                                                                        <option key={index} selected={ data === holding.anneeOuverture } >{data}</option>
                                                                    )
                                                                })
                                                            }
                                                        </InputSelect>
                                                    </CFormGroup>
                                                </div>
                                            )
                                        })
                                    }
                                </CFormGroup>
                            </>
                        )
                    }
                </div>*/}
                    </>
                )
            }
        </>
    );
};

export default EnfantConjointHolding;
