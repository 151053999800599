export const login = () => {
    return {
        type: "LOGIN_USER"
    }
}

export const logout = () => {
    return {
        type: "LOGOUT_USER"
    }
}