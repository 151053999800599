import { CModal, CModalBody, CModalHeader } from '@coreui/react';
import React, { Children } from 'react'

const ModalWindow = ({show, onClose, title, children, noPadding, height, customClass }) => {
    return (
        <CModal className={customClass} show={show} centered={true} onClose={onClose} id="ft" size={'xl'} style={{ height: height ? height : 600, overflowY: 'scroll' }}>
            <CModalHeader closeButton={true} style={{ fontSize: 15, fontWeight: "bold" }}>
                {title}
            </CModalHeader>
            <CModalBody style={{ padding: noPadding ? 0 : 10 }}>
                {children}
            </CModalBody>
        </CModal>
    )
}

export default ModalWindow;
