import { blackColor, numberFormat } from "../../all_ppt_render";
import { currencyToSend } from "../../../store/utilsFunc";

const textProps = {
    fontSize: 11,
    fontFace: 'Gill Sans MT',
    valign: 'middle',
    color: blackColor
}

const defaultData = [
    [
        { 
            text: "Donation antérieure de moins de 15 ans ?", 
            options: { 
                align: 'left', 
                ...textProps,
                valign: 'center',
                color: '0070C0',
                bold: true
            }
        }
    ],
    [
        { text: "Valeurs fiscales données sauf les dons exonérés", options: { align: 'left', ...textProps, color: '28366D' }  }
    ],
    [
        { text: "Année de la donation antérieure", options: { align: 'left', ...textProps, color: '28366D' }  }
    ],
    [
        { text: "Abattement fiscal applicable sur la donation antérieure", options: { align: 'left', ...textProps, color: '28366D' }  }
    ],
    [
        { text: "Nouvelle donation avant cession", 
          options: { 
              align: 'left', 
              ...textProps,
              color: '0070C0',
              bold: true,
              underline: true
            }}
    ],
    [
        { text: "Donation en pleine propriété", options: { align: 'left', ...textProps, color: '28366D' }  }
    ],
    [
        { text: "Donation en nue-propriété avec clause de remploi", options: { align: 'left', ...textProps, color: '28366D' }  }
    ],
    [
        { text: "Frais et droits de donation", options: { align: 'left', ...textProps, color: '28366D' }  }
    ],
    [
        { text: "Taux marginal des droits de donation", options: { align: 'left', ...textProps, color: '28366D' }  }
    ],
];

const beginningData = [
    [
        { 
            text: "Donation antérieure de moins de 15 ans ?", 
            options: { 
                align: 'left', 
                ...textProps,
                valign: 'center',
                color: '0070C0',
                bold: true
            }
        }
    ],
    [
        { text: "Valeurs fiscales données sauf les dons exonérés", options: { align: 'left', ...textProps, color: '28366D' }  }
    ],
    [
        { text: "Année de la donation antérieure", options: { align: 'left', ...textProps, color: '28366D' }  }
    ],
    [
        { text: "Abattement fiscal applicable sur la donation antérieure", options: { align: 'left', ...textProps, color: '28366D' }  }
    ],
    [
        { text: "Nouvelle donation avant cession", 
          options: { 
              align: 'left', 
              ...textProps,
              color: '0070C0',
              bold: true,
              underline: true
            }}
    ],
    [
        { text: "Donation en pleine propriété", options: { align: 'left', ...textProps, color: '28366D' }  }
    ],
    [
        { text: "Donation en nue-propriété avec clause de remploi", options: { align: 'left', ...textProps, color: '28366D' }  }
    ],
    [
        { text: "Frais et droits de donation", options: { align: 'left', ...textProps, color: '28366D' }  }
    ],
    [
        { text: "Taux marginal des droits de donation", options: { align: 'left', ...textProps, color: '28366D' }  }
    ],
];


const enfantsParPage = (rowsEnfants) => {
    let titres = [];
    let valeursFiscales = [];
    let anneeDonation = [];
    let abattementFiscal = [];
    let nouvelleDonation = [];
    let donationPP = [];
    let donationNP = [];
    let fraisDonation = [];
    let tauxMarginaux = [];
    
    let indexTitres = 0;
    let indexValeursFiscales = 0;
    let indexAnneeDonation = 0;
    let indexAbattementFiscal = 0;
    let indexNouvelleDonation = 0;
    let indexDonationPP = 0;
    let indexDonationNP = 0;
    let indexFraisDonation = 0;
    let indexTauxMarginaux = 0;


    rowsEnfants.map((donnee, indice) => {
            let currentLine = donnee;
            currentLine.shift();
    
            let i = 0;
    
            while(i < currentLine.length){
                if( i%5 === 0){
                    let data = [];

                    // A transformer en boucle
                    if(currentLine[i]) data.push(currentLine[i]);
                    if(currentLine[i+1]) data.push(currentLine[i+1]);
                    if(currentLine[i+2]) data.push(currentLine[i+2]);
                    if(currentLine[i+3]) data.push(currentLine[i+3]);
                    if(currentLine[i+4]) data.push(currentLine[i+4]);

                    data.unshift({ text: beginningData[indice]});
                    
                    if(indice === 0){
                        titres[indexTitres] = data;
                        indexTitres++;
                    }
                    else if(indice === 1){
                        valeursFiscales[indexValeursFiscales] = data;
                        indexValeursFiscales++;
                    }
                    else if(indice === 2){
                        anneeDonation[indexAnneeDonation] = data;
                        indexAnneeDonation++;
                    }
                    else if(indice === 3){
                        abattementFiscal[indexAbattementFiscal] = data;
                        indexAbattementFiscal++;
                    }
                    else if(indice === 4){
                        nouvelleDonation[indexNouvelleDonation] = data;
                        indexNouvelleDonation++
                    }
                    else if(indice === 5){
                        donationPP[indexDonationPP] = data;
                        indexDonationPP++;
                    }
                    else if(indice === 6){
                        donationNP[indexDonationNP] = data;
                        indexDonationNP++;
                    }
                    else if(indice === 7){
                        fraisDonation[indexFraisDonation] = data;
                        indexFraisDonation++;
                    }
                    else if(indice === 8){
                        tauxMarginaux[indexTauxMarginaux] = data;
                        indexTauxMarginaux++;
                    }

                    i += 5;
                }
                
            }
    });

    const nbEnfantsParPage = 4;
    let finalData = [];

    for(let i=0; i<nbEnfantsParPage; i++){
        if(
            titres[i] &&
            valeursFiscales[i] &&
            anneeDonation[i] &&
            abattementFiscal[i] &&
            nouvelleDonation[i] &&
            donationPP[i] &&
            donationNP[i] &&
            fraisDonation[i] &&
            tauxMarginaux[i]
        ){
            finalData.push(
                [
                    titres[i],
                    valeursFiscales[i],
                    anneeDonation[i],
                    abattementFiscal[i],
                    nouvelleDonation[i],
                    donationPP[i],
                    donationNP[i],
                    fraisDonation[i],
                    tauxMarginaux[i]
                ]
            )
        }
    }

    return finalData;
};

const checkId = (enfs, id) => {
    let trouve = false;

    enfs.map((data, index) => {
        if(data.id === id) trouve = true;
    });

    return trouve;
};

const triageEnfantsConjoint1Conjoint2 = (enfants, enfantsDonations, indexConjoint) => {
    const enfs = [];

    const enfantsConjoint1 = enfants.filter((element) => ["Enfant commun", "Enfant du conjoint 1"].includes(element.parents) );
    const enfantsConjoint2 = enfants.filter((element) => ["Enfant commun", "Enfant du conjoint 2"].includes(element.parents) );

    // Pour le Conjoint 1
    if(indexConjoint === 0){
        enfantsDonations.map((data, index) => {
            if(checkId(enfantsConjoint1, data.id)){
                enfs.push(data);
            }
        })
    }
    
    // Pour le Conjoint 2
    if(indexConjoint === 1){
        enfantsDonations.map((data, index) => {
            if(checkId(enfantsConjoint2, data.id)){
                enfs.push(data);
            }
        })
    }

    return enfs;
}

export const structurationDataDonationAvantCession = (enfants, conjointData, indexConjoint, marieOuPacse=true, enfs, prenomConjoint1, prenomConjoint2) => {
    enfants = triageEnfantsConjoint1Conjoint2(enfs, enfants, indexConjoint);

    const data = [
        [
            { 
                text: "Donation antérieure de moins de 15 ans ?", 
                options: { 
                    align: 'left', 
                    ...textProps,
                    valign: 'center',
                    color: '0070C0',
                    bold: true
                }
            }
        ],
        [
            { text: "Valeurs fiscales données sauf les dons exonérés", options: { align: 'left', ...textProps, color: '28366D' }  }
        ],
        [
            { text: "Année de la donation antérieure", options: { align: 'left', ...textProps, color: '28366D' }  }
        ],
        [
            { text: "Abattement fiscal applicable sur la donation antérieure", options: { align: 'left', ...textProps, color: '28366D' }  }
        ],
        [
            { text: "Nouvelle donation avant cession", 
              options: { 
                  align: 'left', 
                  ...textProps,
                  color: '0070C0',
                  bold: true,
                  underline: true
                }}
        ],
        [
            { text: "Donation en pleine propriété", options: { align: 'left', ...textProps, color: '28366D' }  }
        ],
        [
            { text: "Donation en nue-propriété avec clause de remploi", options: { align: 'left', ...textProps, color: '28366D' }  }
        ],
        [
            { text: "Frais et droits de donation", options: { align: 'left', ...textProps, color: '28366D' }  }
        ],
        [
            { text: "Taux marginal des droits de donation", options: { align: 'left', ...textProps, color: '28366D' }  }
        ],
    ];

    const border = [
        {
            pt: 0,
            color: ''
        },
        {
            pt: 2,
            color: 'FFFFFF'
        },
        {
            pt: 2,
            color: 'FFFFFF'
        },
        {
            pt: 0,
            color: ''
        },
    ]

    const headerProps = {
        ...textProps,
        color: 'FFFFFF',
        align: 'center',
        fill: '808080',
        bold: true,
        border: [ ...border ]
    }

    const valueProps = {
        ...textProps,
        align: 'right',
        margin: [ 0, 10, 0, 0 ],
        fill: 'F2F2F2',
        border: [ ...border ]
    }

    data.map((donnee, indice) => {
        // Les titres ENFANT 1, ENFANT 2,...., CONJOINT x
        if(indice === 0){
            enfants.map((enfant, i) => {
                let id = enfant && enfant.id ? enfant.id : "";
                let enf = enfs.find((element) => element.id == id );

                let prenom = ![null, undefined, ""].includes(enf && enf.prenom ? enf.prenom : "") ? enf.prenom : `ENFANT ${id}` 

                data[indice].push({
                    text: `${prenom.toString().toUpperCase()}`,
                    options: { ...headerProps }
                })
            })

            // Titre pour le Conjoint en question
            if((indexConjoint === 0 && marieOuPacse) || indexConjoint === 1){
                data[indice].push({
                    text: `${indexConjoint === 0 ? prenomConjoint2.toString().toUpperCase() : prenomConjoint1.toString().toUpperCase()}`,
                    options: { 
                        ...headerProps,
                        fill: indexConjoint === 0 ? 'F47F4F' : '318FCC' 
                    }
                })
            }
        }
        
        // Les valeurs fiscales
        if(indice === 1){
            enfants.map((enfant, i) => {
                let value = `${enfant && enfant.valeurFiscale && currencyToSend(enfant.valeurFiscale) > 0 ? numberFormat(currencyToSend(enfant.valeurFiscale)) : '-'} €`;

                data[indice].push({
                    text: value,
                    options: { ...valueProps }
                })
            })
            
            // Valeur fiscale du CONJOINT en question
            if((indexConjoint === 0 && marieOuPacse) || indexConjoint === 1){
                const val = `${conjointData && conjointData.valeurFiscale && currencyToSend(conjointData.valeurFiscale) > 0 ? numberFormat(currencyToSend(conjointData.valeurFiscale)) : '-'} €`

                data[indice].push({
                    text: val,
                    options: { ...valueProps }
                })
            }
        }
        
        // Année de la donation antérieure
        if(indice === 2){
            enfants.map((enfant, i) => {
                let value = `${enfant && enfant.valeurFiscale && currencyToSend(enfant.valeurFiscale) > 0 ? (enfant && enfant.anneeDonation ? enfant.anneeDonation : "") : ''}`;

                data[indice].push({
                    text: value,
                    options: { ...valueProps }
                })
            })
            
            // Partie Conjoint
            if((indexConjoint === 0 && marieOuPacse) || indexConjoint === 1){
                const val = ""

                data[indice].push({
                    text: val,
                    options: { 
                        ...valueProps,
                        fill: 'FFFFFF' 
                    }
                })
            }
        }
        
        // Abattement fiscal applicable à la donation antérieure
        if(indice === 3){
            enfants.map((enfant, i) => {
                let value = `${enfant && enfant.valeurFiscale && currencyToSend(enfant.valeurFiscale) > 0 ? (enfant && enfant.abattementFiscal ? `${numberFormat(currencyToSend(enfant.abattementFiscal))} €` : "") : ''}`;

                data[indice].push({
                    text: value,
                    options: { ...valueProps }
                })
            })
            
            // Partie Conjoint
            if((indexConjoint === 0 && marieOuPacse) || indexConjoint === 1){
                const val = ""

                data[indice].push({
                    text: val,
                    options: { 
                        ...valueProps,
                        fill: 'FFFFFF' 
                    }
                })
            }
        }
        
        // Nouvelle donation avant Cession
        if(indice === 4){
            enfants.map((enfant, i) => {
                let value = ''

                data[indice].push({
                    text: value,
                    options: { 
                        ...valueProps,
                        fill: 'FFFFFF'
                    }
                })
            })
            
            // Partie Conjoint
            if((indexConjoint === 0 && marieOuPacse) || indexConjoint === 1){
                const val = ""

                data[indice].push({
                    text: val,
                    options: { 
                        ...valueProps,
                        fill: 'FFFFFF' 
                    }
                })
            }
        }
        
        // Donation en pleine propriété
        if(indice === 5){
            enfants.map((enfant, i) => {
                let value = `${enfant && enfant.donationPP && currencyToSend(enfant.donationPP) > 0 ? numberFormat(currencyToSend(enfant.donationPP)) : '-'} €`;

                data[indice].push({
                    text: value,
                    options: { ...valueProps }
                })
            })
            
            // Donation en PP du Conjoint en question
            if((indexConjoint === 0 && marieOuPacse) || indexConjoint === 1){
                const val = `${conjointData && conjointData.donationPP && currencyToSend(conjointData.donationPP) > 0 ? numberFormat(currencyToSend(conjointData.donationPP)) : '-'} €`

                data[indice].push({
                    text: val,
                    options: { ...valueProps }
                })
            }
        }
        
        // Donation en NP
        if(indice === 6){
            enfants.map((enfant, i) => {
                let value = `${enfant && enfant.donationNP && currencyToSend(enfant.donationNP) > 0 ? numberFormat(currencyToSend(enfant.donationNP)) : '-'} €`;

                data[indice].push({
                    text: value,
                    options: { ...valueProps }
                })
            })
            
            // Partie Conjoint
            if((indexConjoint === 0 && marieOuPacse) || indexConjoint === 1){
                const val = "";

                data[indice].push({
                    text: val,
                    options: { 
                        ...valueProps,
                        fill: 'FFFFFF'
                    }
                })
            }
        }
        
        // Frais et droits de donation
        if(indice === 7){
            enfants.map((enfant, i) => {
                let value = `${enfant && enfant.fraisDonation && currencyToSend(enfant.fraisDonation) > 0 ? numberFormat(currencyToSend(enfant.fraisDonation)) : '-'} €`;

                data[indice].push({
                    text: value,
                    options: { 
                        ...valueProps,
                        color: 'EF7D7D',
                        bold: true 
                    }
                })
            })
            
            // Partie Conjoint
            if((indexConjoint === 0 && marieOuPacse) || indexConjoint === 1){
                const val = `${conjointData && conjointData.fraisDonation && currencyToSend(conjointData.fraisDonation) > 0 ? numberFormat(currencyToSend(conjointData.fraisDonation)) : '-'} €`

                data[indice].push({
                    text: val,
                    options: { 
                        ...valueProps,
                        color: 'EF7D7D',
                        bold: true
                    }
                })
            }
        }
        
        // Taux marginal des droits de donation
        if(indice === 8){
            enfants.map((enfant, i) => {
                let value = `${enfant && enfant.tauxMarginalImposition && currencyToSend(enfant.tauxMarginalImposition) > 0 ? numberFormat(currencyToSend(enfant.tauxMarginalImposition)) : '-'} %`;

                data[indice].push({
                    text: value,
                    options: { 
                        ...valueProps,
                        color: 'EF7D7D',
                        bold: true 
                    }
                })
            })
            
            // Partie Conjoint
            if((indexConjoint === 0 && marieOuPacse) || indexConjoint === 1){
                const val = `${conjointData && conjointData.tauxMarginalImposition && currencyToSend(conjointData.tauxMarginalImposition) > 0 ? numberFormat(currencyToSend(conjointData.tauxMarginalImposition)) : '-'} %`

                data[indice].push({
                    text: val,
                    options: { 
                        ...valueProps,
                        color: 'EF7D7D',
                        bold: true
                    }
                })
            }
        }
    })

    if(indexConjoint === 0) console.log("Default data", defaultData);
    if(indexConjoint === 1) console.log("Default data 2", defaultData);

    return enfantsParPage(data);
};