import { nombreEnfantsParConjoint } from "../../../views/Cession/CessionHaut/ProjectionCessionSociete/TableauSynthese/TableauSynthese";
import { blackColor } from "../../all_ppt_render";

const hasValue = (valeurTitres, apportTitres, donationTitres, soldeValeurTitres) => {
    return !(valeurTitres === '- €' && apportTitres === '- €' && donationTitres === '- €' && soldeValeurTitres === '- €');
};

const generateTableauDonation = (cession, synthese) => {
    const { identiteActionnaire: identite, projetCessionSociete: projet, operationsAvantCession: operations } = cession;
    const { conjoints, enfants, affichageNomPersonnalise: affichage } = identite;
    const { holding } = projet;
    const grayBackground = '585C66';
    const borderValue = 2;
    const headerTextProps = { 
        bold: true,
        color: blackColor,
        fill: 'FFFFFF',
        border: [
            {
                pt: 0,
                color: ''
            },
            {
                pt: borderValue,
                color: 'FFFFFF'
            },
            {
                pt: borderValue,
                color: '809DB4'
            },
            {
                pt: 0,
                color: ''
            }
        ]
    }
    
    const totalValueProps = {
        fill: 'F3F3F3'
    }

    const tableProps = (align, level) => {
        const rightBorderColor = [1, 2, 3].includes(level) ? grayBackground : 'FFFFFF';

        return {
            align: align,
            fontSize: 11,
            fontFace: 'Roboto',
            color: blackColor,
            valign: 'middle',
            margin: align === 'left' ? [ 0, 0, 0, 5 ] : [ 0, 15, 0, 0 ],
            border: [
                {
                    pt: 0,
                    color: ''
                },
                {
                    pt: borderValue,
                    color: rightBorderColor
                },
                {
                    pt: borderValue,
                    color: 'FFFFFF'
                },
                {
                    pt: 0,
                    color: ''
                },
            ]
        };
    };

    const rows = [
        [
            { 
                text: "Actionnaire Familial", 
                options: { 
                    ...tableProps('left'),
                    ...headerTextProps
                }
            },
            { 
                text: [ { text: "Valeur des titres", options: { breakLine: true } }, { text: 'situation actuelle' } ], 
                options: { 
                    ...tableProps('center'),
                    ...headerTextProps
                }
            },
            { 
                text: "Apports de titres", 
                options: { 
                  ...tableProps('center'),
                  ...headerTextProps,
                  fill: grayBackground,
                  color: 'FFFFFF'
                }
            },
            { 
                text: "Donation de titres", 
                options: { 
                  ...tableProps('center'),
                  ...headerTextProps,
                  fill: grayBackground,
                  color: 'FFFFFF'
                }
            },
            { 
                text: [ { text: "Solde valeur", options: { breakLine: true } }, { text: "des titres" } ], 
                options: { 
                    ...tableProps('center'),
                    ...headerTextProps
                }
            },
        ]
    ];

    // Population des conjoints
    synthese.conjoints.map((data, index) => {
        if(index === 0 || (index === 1 && conjoints[0].marieOuPasce === 'Oui')){
            let row = [];
            let nom = `${ affichage ? conjoints[index].nomPersonnalise !== "" ? conjoints[index].nomPersonnalise : `Conjoint ${index + 1}` : `Conjoint ${index + 1}` } PP`;
            let valeurTitres = data.valeurTitres;
            let apportTitres = data.apportTitres;
            let donationTitres = data.donationTitres;
            let soldeValeurTitres = data.soldeValeurTitres;

            if(hasValue(
                valeurTitres,
                apportTitres,
                donationTitres,
                soldeValeurTitres
            )){
                row.push(
                    { text: nom, options: { ...tableProps('left'), ...totalValueProps, bold: true } },
                    { text: `${valeurTitres}`, options: { ...tableProps('right', 1), ...totalValueProps, bold: true }},
                    { text: `${apportTitres}`, options: { ...tableProps('right', 2), ...totalValueProps, bold: true }},
                    { text: `${donationTitres}`, options: { ...tableProps('right', 3), ...totalValueProps, bold: true }},
                    { text: `${soldeValeurTitres}`, options: { ...tableProps('right'), ...totalValueProps, bold: true }},
                );
            }

            if(row.length > 0)
                rows.push(row);
        }
    });

    // Population par la somme des Enfants PP
    if(enfants.length > 1){
        let nom = "Enfants PP";
        let valeurTitres = synthese.somme.pp.valeurTitres;
        let apportTitres = synthese.somme.pp.apportTitres;
        let donationTitres = synthese.somme.pp.donationTitres;
        let soldeValeurTitres = synthese.somme.pp.soldeValeurTitres;

        if(hasValue(
            valeurTitres,
            apportTitres,
            donationTitres,
            soldeValeurTitres
        )){
            rows.push([
                { text: nom, options: { ...tableProps('left'), bold: true, ...totalValueProps } },
                { text: `${valeurTitres}`, options: {...tableProps('right', 1), bold: true, ...totalValueProps }},
                { text: `${apportTitres}`, options: { ...tableProps('right', 2), bold: true, ...totalValueProps }},
                { text: `${donationTitres}`, options: {...tableProps('right', 3),bold: true, ...totalValueProps}},
                { text: `${soldeValeurTitres}`, options: {...tableProps('right'), bold: true, ...totalValueProps}},
            ]);
        }
    }

    // Population par les Enfants PP
    synthese.enfantsPP.map((data, index) => {
        let actFam = enfants.length > 1 ? `   ${ affichage ? enfants[index].prenom !== "" ? enfants[index].prenom : `Enfant ${index + 1}` : `Enfant ${index + 1}` } PP` : `${ affichage ? enfants[index].prenom !== "" ? enfants[index].prenom : `Enfant ${index + 1}` : `Enfant ${index + 1}` } PP`;
        let nom = actFam;
        let valeurTitres = data.valeurTitres;
        let apportTitres = data.apportTitres;
        let donationTitres = data.donationTitres;
        let soldeValeurTitres = data.soldeValeurTitres;
        let bold = enfants.length <= 1;
        let fill = enfants.length <= 1 ? totalValueProps.fill : 'FFFFFF'

        if(hasValue(
            valeurTitres,
            apportTitres,
            donationTitres,
            soldeValeurTitres
        )){
            rows.push([
                { text: nom, options: { ...tableProps('left'), bold: bold, fill: fill } },
                { text: `${valeurTitres}`, options: {...tableProps('right', 1), bold: bold, fill: fill }},
                { text: `${apportTitres}`, options: {...tableProps('right', 2), bold: bold, fill: fill}},
                { text: `${donationTitres}`, options: { ...tableProps('right', 3), bold: bold, fill: fill}},
                { text: `${soldeValeurTitres}`, options: {...tableProps('right'), bold: bold, fill: fill}},
            ]);
        }
    });

    // Population par la somme des Conjoints USUF ou les enfants USUF
    // Somme des Conjoints USUF
    synthese.enfantsNP.map((donnee, indice) => {
        if(indice === 0 || (indice === 1 && conjoints[0].marieOuPasce === 'Oui')){
            let sommeNP = indice === 0 ? synthese.somme.np1 : synthese.somme.np2;
            let nb = nombreEnfantsParConjoint(enfants, indice);
            let nomS = `${ affichage ? conjoints[indice].nomPersonnalise !== "" ? conjoints[indice].nomPersonnalise : `Conjoint ${indice + 1}` : `Conjoint ${indice + 1}` } USUF / Enfants NP`;
            let valeurTitresS = sommeNP.valeurTitres;
            let apportTitresS = sommeNP.apportTitres;
            let donationTitresS = sommeNP.donationTitres;
            let soldeValeurTitresS = sommeNP.soldeValeurTitres

            if(nb > 1){
                if(hasValue(
                    valeurTitresS,
                    apportTitresS,
                    donationTitresS,
                    soldeValeurTitresS
                )){
                    rows.push([
                        { text: nomS, options: { ...tableProps('left'), bold: true, ...totalValueProps } },
                        { text: `${valeurTitresS}`, options: {...tableProps('right', 1),bold: true, ...totalValueProps}},
                        { text: `${apportTitresS}`, options: {...tableProps('right', 2),bold: true, ...totalValueProps}},
                        { text: `${donationTitresS}`, options: {...tableProps('right', 3),bold: true, ...totalValueProps}},
                        { text: `${soldeValeurTitresS}`, options: {...tableProps('right'),bold: true, ...totalValueProps}},
                    ]);
                }
            }

            // Enfants du Conjoint x USUF
            donnee.map((data, index) => {
                    let nb = nombreEnfantsParConjoint(enfants, indice);
                    let nom = nb > 1 ? `   ${data.actionnaireFamilial}` : `${data.actionnaireFamilial}`;
                    let valeurTitres = data.valeurTitres;
                    let apportTitres = data.apportTitres;
                    let donationTitres = data.donationTitres;
                    let soldeValeurTitres = data.soldeValeurTitres
                    let bold = nb <= 1;
                    let fill = nb <= 1 ? totalValueProps.fill : 'FFFFFF';

                    if(hasValue(
                        valeurTitres,
                        apportTitres,
                        donationTitres,
                        soldeValeurTitres
                    )){
                        rows.push([
                            { text: nom, options: { ...tableProps('left'), bold: bold, fill: fill } },
                            { text: `${valeurTitres}`, options: { ...tableProps('right', 1), bold: bold, fill: fill }},
                            { text: `${apportTitres}`, options: {...tableProps('right', 2), bold: bold, fill: fill }},
                            { text: `${donationTitres}`, options: { ...tableProps('right', 3), bold: bold, fill: fill }},
                            { text: `${soldeValeurTitres}`, options: {...tableProps('right'), bold: bold, fill: fill }},
                        ]);
                    }
            });
        }
    });

    // Population par le SOUS-TOTAL actionnaires personnes physiques
    let nomPersonnePhysique = "   SOUS-TOTAL actionnaires personnes physiques";
    let valeurTitresPersonnePhysique = synthese.personnePhysique.valeurTitres;
    let apportTitresPersonnePhysique = synthese.personnePhysique.apportTitres;
    let donationTitresPersonnePhysique = synthese.personnePhysique.donationTitres;
    let soldeValeurTitresPersonnePhysique = synthese.personnePhysique.soldeValeurTitres;

    if(hasValue(
        valeurTitresPersonnePhysique,
        apportTitresPersonnePhysique,
        donationTitresPersonnePhysique,
        soldeValeurTitresPersonnePhysique
    )){
        rows.push([
            { text: nomPersonnePhysique, options: { ...tableProps('left'), fill: grayBackground, color: 'FFFFFF', bold: true }},
            { text: `${valeurTitresPersonnePhysique}`, options: { ...tableProps('right'), fill: grayBackground, color: 'FFFFFF', bold: true }},
            { text: `${apportTitresPersonnePhysique}`, options: { ...tableProps('right'), fill: grayBackground, color: 'FFFFFF', bold: true }},
            { text: `${donationTitresPersonnePhysique}`, options: { ...tableProps('right'), fill: grayBackground, color: 'FFFFFF', bold: true }},
            { text: `${soldeValeurTitresPersonnePhysique}`, options: { ...tableProps('right'), fill: grayBackground, color: 'FFFFFF', bold: true }},
        ]);
    }

    // Population par le HOLDING
    let nomHolding = affichage ? holding.nomPersonnalise !== "" ? holding.nomPersonnalise : 'HOLDING IS' : `HOLDING IS`;
    let valeurTitresHolding = synthese.holding.valeurTitres;
    let soldeValeurTitresHolding = synthese.holding.soldeValeurTitres;

    if(hasValue(
        valeurTitresHolding,
        '- €',
        '- €',
        soldeValeurTitresHolding
    )){
        rows.push([
            { text: nomHolding, options: { ...tableProps('left'), bold: true}},
            { text: `${valeurTitresHolding}`, options: { ...tableProps('right', 1), bold: true }},
            { text: ``, options: {...tableProps('right'), bold: true, fill: 'FFFFFF'}},
            { text: ``, options: {...tableProps('right', 3), bold: true, fill: 'FFFFFF'}},
            { text: `${soldeValeurTitresHolding}`, options: { ...tableProps('right'), bold: true } },
        ]);
    }
    
    // Population par le Newco
    let nomNewco = "NEWCO IS(bénéficiaire.s des apports avant cession)";
    let apportTitresNewco = synthese.newco.apportTitres;
    let soldeValeurTitresNewco = synthese.newco.soldeValeurTitres;

    if(hasValue(
        '- €',
        apportTitresNewco,
        '- €',
        soldeValeurTitresNewco
    )){
        rows.push([
            { text: nomNewco, options: { ...tableProps('left'), bold: true, colspan: 2 }},
            { text: `${apportTitresNewco}`, options: { ...tableProps('right'), bold: true } },
            { text: "", options: { ...tableProps('right'), fill: 'FFFFFF' } },
            { text: `${soldeValeurTitresNewco}`, options: { ...tableProps('right'), bold: true } },
        ]);
    }

    
    // Population par le SOUS-TOTAL actionnaires personnes morales
    let nomPersonneMorale = "   SOUS-TOTAL actionnaires personnes morales";
    let valeurTitresPersonneMorale = synthese.personneMorale.valeurTitres;
    let apportTitresPersonneMorale = synthese.personneMorale.apportTitres;
    let soldeValeurTitresPersonneMorale = synthese.personneMorale.soldeValeurTitres

    if(hasValue(
        valeurTitresPersonneMorale,
        apportTitresPersonneMorale,
        '- €',
        soldeValeurTitresPersonneMorale
    )){
        rows.push([
            { text: nomPersonneMorale, options: { ...tableProps('left'), bold: true, fill: grayBackground, color: 'FFFFFF' }},
            { text: `${valeurTitresPersonneMorale}`, options: { ...tableProps('right'), bold: true, fill: grayBackground, color: 'FFFFFF' }},
            { text: `${apportTitresPersonneMorale}`, options: { ...tableProps('right'), bold: true, fill: grayBackground, color: 'FFFFFF' }},
            { text: "", options: { ...tableProps('right'), bold: true, fill: 'FFFFFF', color: 'FFFFFF' }},
            { text: `${soldeValeurTitresPersonneMorale}`, options: { ...tableProps('right'), bold: true, fill: grayBackground, color: 'FFFFFF' }},
        ]);
    }

    // Population par le TOTAL
    let nomTotal = "TOTAL";
    let totalValeurTitres = synthese.somme.somme.valeurTitres;
    let totalApportTitres = synthese.somme.somme.apportTitres;
    let totalDonationTitres = synthese.somme.somme.donationTitres;
    let totalSoldeValeurTitres = synthese.somme.somme.soldeValeurTitres

    if(hasValue(
        totalValeurTitres,
        totalApportTitres,
        totalDonationTitres,
        totalSoldeValeurTitres
    )){
        rows.push([
            { text: nomTotal, options: { ...tableProps('right'), bold: true, fill: grayBackground, color: 'FFFFFF' }},
            { text: `${totalValeurTitres}`, options: {...tableProps('right'), bold: true, fill: grayBackground, color: 'FFFFFF' }},
            { text: `${totalApportTitres}`, options: { ...tableProps('right'), bold: true, fill: grayBackground, color: 'FFFFFF' }},
            { text: `${totalDonationTitres}`, options: { ...tableProps('right'), bold: true, fill: grayBackground, color: 'FFFFFF' }},
            { text: `${totalSoldeValeurTitres}`, options: { ...tableProps('right'), bold: true, fill: grayBackground, color: 'FFFFFF' }},
        ]);
    }

    return rows;
};

export default generateTableauDonation;