import { CCol, CForm, CFormGroup, CLabel, CRow } from '@coreui/react';
import React, { useContext, useEffect } from 'react';
import { InputCurrency, InputDate, InputRate, InputSelect } from '../../../../components/input';
import { DataControlContext } from '../../../../store/context/dataControl';
import { currencyToSend } from '../../../../store/utilsFunc';
import FormulaireNombreEmprunt from '../FormulaireNombreEmprunt/FormulaireNombreEmprunt';
import './style.scss';
import FormulaireMontantEmprunt from './FormulaireMontantEmprunt/FormulaireMontantEmprunt';
import { addToast, deleteToast } from './../../../../store/actions/utils/toast';
import { UtilsContext } from './../../../../store/context/utils';
import DataBlock from './../../../../components/DataBlock/DataBlock';
import { formatToUniqueId } from '../../../../utils';
import EmpruntDataBlock from '../../../../components/DataBlock/EmpruntDataBlock';

const Caracteristique = ({ indiceCurrentEmprunt, nombreTotalVersement, withoutCalcul, setWithoutCalcul, setWithCalcul, setEmpruntSaisieDetaille }) => {
    const { empruntState, setEmpruntState } = useContext(DataControlContext);
    const { utilsState, utilsDispatch } = useContext(UtilsContext);
    const {
        montant_emprunt,
        taux_annuel,
        duree,
        date_emprunt,
        type_emprunt,
        type_amortissement,
        periode_echeance,
        nb_periode,
        taux_deces,
        taux_deces2,
        taux_prime,
        assiette_prime,
        frais_dossier_en_pourcentage,
        frais_dossier_en_euro,
    } = empruntState;

    const typeEmpruntData = [
        {
            value: 0,
            label: 'Amortissable avec annuités constantes'
        },
        {
            value: 1,
            label: 'Amortissable avec amortissements constants'
        },
        {
            value: 2,
            label: 'In fine'
        },
    ];

    const typePeriodiciteEcheanceData = [
        {
            value: 0,
            label: 'Mensuelle'
        },
        {
            value: 1,
            label: 'Trimestrielle'
        },
        {
            value: 2,
            label: 'Semestrielle'
        },
        {
            value: 3,
            label: 'Annuelle'
        },
    ]

    const assiettePrimeAssData = [
        {
            value: 0,
            label: 'Capital emprunté'
        },
        {
            value: 1,
            label: 'Capital restant dû'
        },
    ]

    const handleFormChange = (event, prop) => {
        if(!['type_emprunt', 'periode_echeance', 'assiette_prime'].includes(prop)){
            setWithCalcul(false);
        }

        let { value } = event.target;
        const alternativeEmprunt = { ...empruntState };
        const entiersProps = ['type_emprunt', 'montant_emprunt', 'duree', 'type_amortissement', 'periode_echeance', 'nb_periode', 'assiette_prime', 'frais_dossier_en_euro'];
        const decimauxProps = ['taux_annuel', 'taux_deces', 'taux_prime', 'frais_dossier_en_pourcentage'];

        // Pour les valeurs entières
        if (entiersProps.includes(prop)) value = currencyToSend(value) || 0;

        // Pour les valeurs décimales
        if (decimauxProps.includes(prop)) value = parseFloat(value) || 0;

        alternativeEmprunt[prop] = value;

        setEmpruntState(alternativeEmprunt);
        if (setEmpruntSaisieDetaille) {
          setEmpruntSaisieDetaille(alternativeEmprunt)

        }
    }

    const handleFormBlur = () => { setWithCalcul(true) }

    useEffect(() => {
        if (empruntState.type_emprunt === 2) {
            const emp = { ...empruntState };
            emp.nb_periode = 0;
            emp.type_amortissement = 0;

            setEmpruntState(emp);
        }
    }, [empruntState.type_emprunt])

    // Message d'erreur sur le differé d'amortissement
    useEffect(() => {
        const message = "Erreur sur le nombre de périodes : Veuillez corriger votre saisie. Le nombre de périodes différées ne peut pas être supérieur au nombre total des versements.";

        if (nombreTotalVersement > 0 && (nb_periode > nombreTotalVersement)) {
            setWithoutCalcul(true);
            utilsDispatch(addToast({ type: 'danger', title: 'ERREUR', description: message, autoDelete: false }))
        }
        else {
            const content = utilsState?.toasts?.find((element) => element.description === message);
            if (content?.id) {
                setWithoutCalcul(false);
                utilsDispatch(deleteToast(content.id))
            }
        }
    }, [nb_periode, nombreTotalVersement])

    const styleCommun = {
        padding: 0,
        margin: 0,
        display: 'flex',
        justifyContent: 'flex-start'
    };

    return (
        <EmpruntDataBlock
            title="CARACTÉRISTIQUES DE L'EMPRUNT"
            defaultDisplay={true}
            bodyStyle={{
                paddingLeft: 0,
                paddingTop: 0,
                paddingBottom: 0,
                background: '#FFFFFF'
            }}
        >
            <div className='bloc'>
                <div className='gauche' >
                    <div className="formulaire">
                        <CCol xl={12}>
                            <CForm className="col-lg-12" id="form_pea">
                                <div>
                                    <div>
                                        <CRow style={{ marginRight: -24, marginLeft: -20 }}>
                                            <CCol xl={12}>
                                                {/** Date de l'emprunt */}
                                                <CFormGroup className="form-inline col-lg-12" style={{ display: 'flex', flexWrap: 'nowrap', flexDirection: 'row' }}>
                                                    <CLabel className="col-lg-4 col-md-6 col-sm-7 ml-3">Date de l'emprunt</CLabel>
                                                    <div className='col-lg-6 col-md-5 col-sm-5' style={{ display: 'flex', justifyContent: 'flex-start', padding: 0 }} >
                                                        <InputDate
                                                            id={`${formatToUniqueId("Caracteritique date_emprunt")}-date`}
                                                            disabled={withoutCalcul}
                                                            className='col-lg-6'
                                                            value={date_emprunt}
                                                            onChange={(event) => handleFormChange(event, 'date_emprunt')}
                                                            onBlur={handleFormBlur}
                                                        />
                                                    </div>
                                                </CFormGroup>

                                                {/** Type de l'emprunt */}
                                                <CFormGroup className="form-inline col-lg-12" style={{ display: 'flex', flexWrap: 'nowrap', flexDirection: 'row' }}>
                                                    <CLabel className="col-lg-4 col-md-6 col-sm-7 ml-3">Type d'emprunt</CLabel>
                                                    <InputSelect
                                                        id={`${formatToUniqueId("Caracteristique type_emprunt")}-select`}
                                                        disabled={withoutCalcul}
                                                        custom
                                                        className='col-lg-6 col-md-5 col-sm-5'
                                                        onChange={(event) => handleFormChange(event, 'type_emprunt')}
                                                    >
                                                        {
                                                            typeEmpruntData.map((data, index) => {
                                                                return (
                                                                    <option key={`TypeEmprunt${index + 1}`} value={data.value} selected={type_emprunt === data.value} >{data.label}</option>
                                                                )
                                                            })
                                                        }
                                                    </InputSelect>
                                                </CFormGroup>

                                                {/** Montant de l'emprunt */}
                                                <CFormGroup className="form-inline col-lg-12" style={{ display: 'flex', flexWrap: 'nowrap', flexDirection: 'row' }}>
                                                    <CLabel className="col-lg-4 col-md-6 col-sm-7 ml-3">Montant de l'emprunt</CLabel>
                                                    <div className='col-lg-6 col-md-5 col-sm-5' style={{ ...styleCommun, width: '50%' }} >
                                                        <FormulaireMontantEmprunt
                                                            disabled={withoutCalcul}
                                                            className='col-lg-6'
                                                            value={montant_emprunt}
                                                            onChange={(event) => handleFormChange(event, 'montant_emprunt')}
                                                            onBlur={handleFormBlur}
                                                        />
                                                    </div>
                                                </CFormGroup>

                                                {/** Taux d'intérêt annuel */}
                                                <CFormGroup className="form-inline col-lg-12" style={{ display: 'flex', flexWrap: 'nowrap', flexDirection: 'row' }}>
                                                    <CLabel className="col-lg-4 col-md-6 col-sm-7 ml-3">Taux d'intérêt annuel</CLabel>
                                                    <div className='col-lg-6 col-md-5 col-sm-5' style={styleCommun} >
                                                        <InputRate
                                                            justifyContent={'flex-start'}
                                                            id={`${formatToUniqueId("Caracteristique taux_annuel")}-rate`}
                                                            disabled={withoutCalcul}
                                                            style={{ width: '50%' }}
                                                            value={taux_annuel}
                                                            onChange={(event) => handleFormChange(event, 'taux_annuel')}
                                                            onBlur={handleFormBlur}
                                                            alignRight="100px"
                                                        />
                                                    </div>
                                                </CFormGroup>

                                                {/** Périodicité */}
                                                <CFormGroup className="form-inline col-lg-12" style={{ display: 'flex', flexWrap: 'nowrap', flexDirection: 'row' }}>
                                                    <CLabel className="col-lg-4 col-md-6 col-sm-7 ml-3">Périodicité</CLabel>
                                                    <div className='col-lg-6 col-md-5 col-sm-5' style={styleCommun} >
                                                        <InputSelect
                                                            id={`${formatToUniqueId("Caracteristique periode_echeance")}-select`}
                                                            disabled={withoutCalcul}
                                                            custom
                                                            className='col-lg-6'
                                                            onChange={(event) => handleFormChange(event, 'periode_echeance')}
                                                        >
                                                            {
                                                                typePeriodiciteEcheanceData.map((data, index) => {
                                                                    return (
                                                                        <option key={`PeriodiciteEcheance${index + 1}`} value={data.value} selected={periode_echeance === data.value} >{data.label}</option>
                                                                    )
                                                                })
                                                            }
                                                        </InputSelect>
                                                    </div>
                                                </CFormGroup>

                                                {/** Durée */}
                                                <CFormGroup className="form-inline col-lg-12" style={{ display: 'flex', flexWrap: 'nowrap', flexDirection: 'row' }}>
                                                    <CLabel className="col-lg-4 col-md-6 col-sm-7 ml-3">Durée totale (nb de périodes)</CLabel>
                                                    <div className='col-lg-6 col-md-5 col-sm-5' style={styleCommun} >
                                                        <FormulaireNombreEmprunt
                                                            justifyContent={'flex-start'}
                                                            max={360}
                                                            disabled={withoutCalcul}
                                                            invalid={duree === 0}
                                                            style={{ width: '50%' }}
                                                            value={duree}
                                                            onChange={(event) => handleFormChange(event, 'duree')}
                                                            onBlur={handleFormBlur}
                                                        />
                                                    </div>
                                                </CFormGroup>

                                                {/** Différé d'amortissement */}
                                                {
                                                    ([0, 1].includes(type_emprunt)) && (
                                                        <CFormGroup className="form-inline col-lg-12" style={{ display: 'flex', flexWrap: 'nowrap', flexDirection: 'row' }}>
                                                            <CLabel className="col-lg-4 col-md-6 col-sm-7 ml-3">Différé d'amortissement<br />Nb de périodes différées</CLabel>
                                                            <div className='col-lg-6 col-md-5 col-sm-5' style={styleCommun} >
                                                                <FormulaireNombreEmprunt
                                                                    justifyContent={'flex-start'}
                                                                    max={360}
                                                                    invalid={nombreTotalVersement > 0 && (nb_periode > nombreTotalVersement)}
                                                                    style={{ width: '50%' }}
                                                                    value={nb_periode}
                                                                    onChange={(event) => handleFormChange(event, 'nb_periode')}
                                                                    onBlur={handleFormBlur}
                                                                />
                                                            </div>
                                                        </CFormGroup>
                                                    )
                                                }
                                            </CCol>
                                        </CRow>
                                    </div>
                                </div>
                            </CForm>
                        </CCol>
                    </div>
                </div>
                <div className='droite'>
                    <div className='haut' >
                        <div className='titreBlocHautDroite' >Assurance</div>
                        <div>
                            <div className="formulaire">
                                <CCol xl={12}>
                                    <CForm className="col-lg-12" id="form_pea">
                                        <div>
                                            <div>
                                                <CRow style={{ marginRight: -24, marginLeft: -20 }}>
                                                    <CCol xl={12}>
                                                        {/** Taux de couverture décès Client 1 */}
                                                        <CFormGroup className="form-inline col-lg-12" style={{ display: 'flex', flexWrap: 'nowrap', flexDirection: 'row' }}>
                                                            <CLabel className="col-lg-6 col-md-6 col-sm-7">Taux de couverture décès {[0, 1].includes(indiceCurrentEmprunt) ? (<span>Client1</span>) : indiceCurrentEmprunt === 2 ? (<span>Client2</span>) : ""}</CLabel>
                                                            <div className='col-lg-2 col-md-5 col-sm-5' style={{ margin: 0, padding: 0 }} >
                                                                <InputRate
                                                                    justifyContent={'flex-start'}
                                                                    id={`${formatToUniqueId("Caracteristique taux_deces")}-rate`}
                                                                    disabled={withoutCalcul}
                                                                    style={{ width: '50%' }}
                                                                    value={indiceCurrentEmprunt === 2 ? taux_deces2 || 0 : taux_deces}
                                                                    onChange={(event) => handleFormChange(event,  indiceCurrentEmprunt === 2 ? 'taux_deces2' : 'taux_deces')}
                                                                    onBlur={handleFormBlur}
                                                                    alignRight="100px"
                                                                />
                                                            </div>
                                                        </CFormGroup>

                                                        {/** Taux de couverture décès */}
                                                        { indiceCurrentEmprunt === 1 && (
                                                          <CFormGroup className="form-inline col-lg-12" style={{ display: 'flex', flexWrap: 'nowrap', flexDirection: 'row' }}>
                                                            <CLabel className="col-lg-6 col-md-6 col-sm-7">Taux de couverture décès Client2</CLabel>
                                                            <div className='col-lg-2 col-md-5 col-sm-5' style={{ margin: 0, padding: 0 }} >
                                                                <InputRate
                                                                    justifyContent={'flex-start'}
                                                                    id={`${formatToUniqueId("Caracteristique taux_deces2")}-rate`}
                                                                    disabled={withoutCalcul}
                                                                    style={{ width: '50%' }}
                                                                    value={taux_deces2 || 0}
                                                                    onChange={(event) => handleFormChange(event, 'taux_deces2')}
                                                                    onBlur={handleFormBlur}
                                                                    alignRight="100px"
                                                                />
                                                            </div>
                                                        </CFormGroup>
                                                        )}

                                                        {/** Taux de la prime d'ass. */}
                                                        <CFormGroup className="form-inline col-lg-12" style={{ display: 'flex', flexWrap: 'nowrap', flexDirection: 'row' }}>
                                                            <CLabel className="col-lg-6 col-md-6 col-sm-7">Taux de la prime ass.</CLabel>
                                                            <div className='col-lg-2 col-md-5 col-sm-5' style={{ margin: 0, padding: 0 }} >
                                                                <InputRate
                                                                    justifyContent={'flex-start'}
                                                                    alignRight="100px"
                                                                    id={`${formatToUniqueId("Caracteristique taux_prime")}-rate`}
                                                                    disabled={withoutCalcul}
                                                                    style={{ width: '50%' }}
                                                                    value={taux_prime}
                                                                    onChange={(event) => handleFormChange(event, 'taux_prime')}
                                                                    onBlur={handleFormBlur}
                                                                />
                                                            </div>
                                                        </CFormGroup>

                                                        {/** Assiette de la prime d'ass. */}
                                                        <CFormGroup className="form-inline col-lg-12" style={{ display: 'flex', flexWrap: 'nowrap', flexDirection: 'row' }}>
                                                            <CLabel className="col-lg-6 col-md-6 col-sm-7">Assiette de la prime d'ass.</CLabel>
                                                            <InputSelect
                                                                id={`${formatToUniqueId("Caracteristique assiette_prime")}-select`}
                                                                disabled={withoutCalcul}
                                                                custom
                                                                className="col-lg-2 col-md-5 col-sm-5"
                                                                onChange={(event) => handleFormChange(event, 'assiette_prime')}
                                                            >
                                                                {
                                                                    assiettePrimeAssData.map((data, index) => {
                                                                        return (
                                                                            <option key={`AssiettePrimeAss${index + 1}`} value={data.value} selected={assiette_prime === data.value} >{data.label}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </InputSelect>
                                                        </CFormGroup>
                                                    </CCol>
                                                </CRow>
                                            </div>
                                        </div>
                                    </CForm>
                                </CCol>
                            </div>
                        </div>
                    </div>
                    <div className='bas' >
                        <div className='titreBlocHautDroite' >Frais de dossier et de garantie</div>
                        <div>
                            <div className="formulaire">
                                <CCol xl={12}>
                                    <CForm className="col-lg-12" id="form_pea">
                                        <div>
                                            <div>
                                                <CRow style={{ marginRight: -24, marginLeft: -20 }}>
                                                    <CCol sm="12">
                                                        {/** En % du capital emprunté */}
                                                        <CFormGroup className="form-inline col-lg-12" style={{ display: 'flex', flexWrap: 'nowrap', flexDirection: 'row' }}>
                                                            <CLabel className="col-lg-6 col-md-6 col-sm-7">En % du capital emprunté</CLabel>
                                                            <InputRate
                                                                id={`${formatToUniqueId("Caracteristique frais_dossier_en_pourcentage")}-rate`}
                                                                disabled={withoutCalcul}
                                                                className='col-lg-2 col-md-5 col-sm-5'
                                                                value={frais_dossier_en_pourcentage}
                                                                onChange={(event) => handleFormChange(event, 'frais_dossier_en_pourcentage')}
                                                                onBlur={handleFormBlur}
                                                            />
                                                        </CFormGroup>

                                                        {/**En € */}
                                                        <CFormGroup className="form-inline col-lg-12" style={{ display: 'flex', flexWrap: 'nowrap', flexDirection: 'row' }}>
                                                            <CLabel className="col-lg-6 col-md-6 col-sm-7">En € du capital emprunté</CLabel>
                                                            <InputCurrency
                                                                id={`${formatToUniqueId("Caracteristique frais_dossier_en_euro")}`}
                                                                disabled={withoutCalcul}
                                                                className='col-lg-2 col-md-5 col-sm-5'
                                                                value={frais_dossier_en_euro}
                                                                onChange={(event) => handleFormChange(event, 'frais_dossier_en_euro')}
                                                                onBlur={handleFormBlur}
                                                            />
                                                        </CFormGroup>
                                                    </CCol>
                                                </CRow>
                                            </div>
                                        </div>
                                    </CForm>
                                </CCol>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </EmpruntDataBlock>
    )
}

export default Caracteristique;
