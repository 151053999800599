import React from 'react';
import useStyles from './style';
import CircleGraph from '../../../../../components/simulateur/CircleGraph';

/**
 * props.background
 * props.color
 * props.subDivBackground
 * 
 */
const Div1Component = (props) => {
    const classes = useStyles();

    return (
        <div  className='structureBlock' style={{fontSize: 13,display: "grid",gridTemplateColumns:props.label == "Optimisation fiscale" ? '1fr' :'2fr 1fr', padding:"10px 0 10px 17px", borderRadius:6, background: `${props.background}`}}>
            <div style={{ }}>
                <div style={{ height: "25px", marginTop:8, color: `${props.colorTitle}` }} >{props.label}</div>
                <div style={{position:'absolute', width:33, height:4, backgroundColor: `${props.colorGraph}`, borderRadius:4, marginTop:-4}}></div>
                <div style={{ paddingTop: 15, fontWeight: 'bold', fontSize:19, color: `${props.color}` }} >{props.value} €</div>
            </div>
            {
                props.opacity && (
                    <div 
                        style={{ 
                
                        }}>
                            <div style={{ display: 'flex', justifyContent: 'center' }} >
                                <CircleGraph
                                    style={{ height: '80px', width: '80px' }}
                                    titre={props.label}
                                    pourcentage={props.pourcentage}
                                    baseColor={props.colorGraph}
                                    baseColorOpacity={props.opacity}
                                />
                            </div>
                    </div>
                )
            }
        </div>
    );
};

export default Div1Component;