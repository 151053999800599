import React from 'react';
import PVImmo from './views/fiches_thematiques/page_ft/PV-IMMO';

const Home = React.lazy(() => import('./views/Accueil/Accueil').catch(e => window.location.reload()))
const Simulations = React.lazy(() => import('./views/simulations/ListeSimulation/ListeSimulation').catch(e => window.location.reload()))
const Simulateurs = React.lazy(() => import('./views/simulateurs').catch(e => window.location.reload()))
const FichesThematiques = React.lazy(() => import('./views/fiches_thematiques').catch(e => window.location.reload()))
const Pea = React.lazy(() => import('./views/fiches_thematiques/page_ft/Pea').catch(e => window.location.reload()))
const Ct = React.lazy(() => import('./views/fiches_thematiques/page_ft/CT/Ct').catch(e => window.location.reload()))
const Av = React.lazy(() => import('./views/fiches_thematiques/page_ft/AV/Av').catch(e => window.location.reload()))
const Capi = React.lazy(() => import('./views/fiches_thematiques/page_ft/CAPI/Capi').catch(e => window.location.reload()))
const Is = React.lazy(() => import('./views/fiches_thematiques/page_ft/IS/Is').catch(e => window.location.reload()))
const Cs = React.lazy(() => import('./views/fiches_thematiques/page_ft/CS/Cs').catch(e => window.location.reload()))
const DMTG = React.lazy(() => import('./views/fiches_thematiques/page_ft/DMTG/DMTG').catch(e => window.location.reload()))
const DMTGAV = React.lazy(() => import('./views/fiches_thematiques/page_ft/DMTG-AV/DMTG-AV-2').catch(e => window.location.reload()))
const DMTGDemembreeAV = React.lazy(() => import('./views/fiches_thematiques/page_ft/DMTG-AV-DEMEMBREE/dmtg-av-demembree2').catch(e => window.location.reload()))
const DMTGAV990I = React.lazy(() => import('./views/fiches_thematiques/page_ft/DMTG-AV-DEMEMBREE-9901/dmtg-av-demembree-9901').catch(e => window.location.reload()))
const FTIFI = React.lazy(() => import('./views/fiches_thematiques/page_ft/IFI/index').catch(e => window.location.reload()))
const Mentions = React.lazy(() => import('./views/mentions').catch(e => window.location.reload()))
const PEA = React.lazy(() => import('./views/simulateurs/pea').catch(e => window.location.reload()))
const FormulairePEA = React.lazy(() => import('./views/simulateurs/pea/formulairePEA').catch(e => window.location.reload()))
const Clients = React.lazy(() => import('./views/clients').catch(e => window.location.reload()))
const FicheClient = React.lazy(() => import('./views/FicheClient/FicheClient').catch(e => window.location.reload()))
const CT = React.lazy(() => import('./views/simulateurs/ct').catch(e => window.location.reload()))
const FormulaireCT = React.lazy(() => import('./views/simulateurs/ct/formulaireCT').catch(e => window.location.reload()))
const AV = React.lazy(() => import('./views/simulateurs/av').catch(e => window.location.reload()))
const FormulaireAV = React.lazy(() => import('./views/simulateurs/av/formulaireAV').catch(e => window.location.reload()))
const CP = React.lazy(() => import('./views/simulateurs/cp').catch(e => window.location.reload()))
const FormulaireCP = React.lazy(() => import('./views/simulateurs/cp/formulaireCP').catch(e => window.location.reload()))
const IS = React.lazy(() => import('./views/simulateurs/is').catch(e => window.location.reload()))
const FormulaireIS = React.lazy(() => import('./views/simulateurs/is/formulaireIS').catch(e => window.location.reload()))
const FicheThematiqueStrategieLimitationIR = React.lazy(() => import('./views/fiches_thematiques/page_ft/StrategiesLimitationIR/StrategiesLimitationIR').catch(e => window.location.reload()))

const accueilSCP = React.lazy(() => import('./views/simulateurs/AccueilFinancier/AccueilFinancier').catch(e => window.location.reload()))
const Comparaisons = React.lazy(() => import('./views/simulateurs/comparaisons').catch(e => window.location.reload()))
const FormulaireCMP = React.lazy(() => import('./views/simulateurs/comparaisons/formulaireCMP').catch(e => window.location.reload()))
const ResultatsCMPF = React.lazy(() => import('./views/simulateurs/comparaisons/resultats_financier').catch(e => window.location.reload()))
const ResultatsCMPT = React.lazy(() => import('./views/simulateurs/comparaisons/resultats_transmission').catch(e => window.location.reload()))
const AideComparaisonStructures = React.lazy(() => import('./views/simulateurs/comparaisons/index').catch(e => window.location.reload()))
const PropositionFinanciere = React.lazy(() => import('./views/InvestissementsFinanciers/PropositionFinanciere/PropositionFinanciere').catch(e => window.location.reload()))
const AccueilPropositionFinanciere = React.lazy(() => import('./views/InvestissementsFinanciers/PropositionFinanciere/Accueil/Accueil').catch(e => window.location.reload()))

// Cession de société
const AccueilCessionSociete = React.lazy(() => import('./views/CessionSociete/Accueil/Accueil').catch(e => window.location.reload()))
const SimulateurCessionSociete = React.lazy(() => import('./views/CessionSociete/CessionSociete').catch(e => window.location.reload()))
const AideCessionSociete = React.lazy(() => import('./views/CessionSociete/Aide/Aide').catch(e => window.location.reload()))
const NoticeCalculCessionSociete = React.lazy(() => import('./views/CessionSociete/Notice/Notice').catch(e => window.location.reload()))

// Donation et Succession
const AccueilDSU = React.lazy(() => import('./views/DonationSuccession/AccueilDSU/AccueilDSU').catch(e => window.location.reload()))
const USUFNPAccueil = React.lazy(() => import('./views/DonationSuccession/UsufruitNP/Accueil').catch(e => window.location.reload()))
const UsufruitViager = React.lazy(() => import('./views/DonationSuccession/UsufruitNP/UsufruitViager/UsufruitViager').catch(e => window.location.reload()))
const UsufruitTemporaire = React.lazy(() => import('./views/DonationSuccession/UsufruitNP/UsufruitTemporaire/UsufruitTemporaire').catch(e => window.location.reload()))
const DonationAccueil = React.lazy(() => import('./views/DonationSuccession/Donation/Accueil').catch(e => window.location.reload()))
const DonsFamiliauxExoneres = React.lazy(() => import('./views/DonationSuccession/Donation/DonsFamiliauxExoneres/DonsFamiliauxExoneres').catch(e => window.location.reload()));
const DonationsOrdinaires = React.lazy(() => import('./views/DonationSuccession/Donation/DonationsOrdinaires/DonationsOrdinaires').catch(e => window.location.reload()));
const DonationsEntreprise = React.lazy(() => import('./views/DonationSuccession/Donation/DonationsEntreprises/DonationsEntreprises').catch(e => window.location.reload()));

// Patrimoine et Fiscalité
const MyTabs = React.lazy(() => import('./views/BilanPatrimonial/Tabs/MyTabs').catch(e => window.location.reload()));
const FiscaliteRevenu = React.lazy(() => import('./views/BilanPatrimonial/FiscaliteRevenu/FiscaliteRevenu').catch(e => window.location.reload()));
const IFI = React.lazy(() => import('./views/BilanPatrimonial/IFI/IFI').catch(e => window.location.reload()));
const AccueilPF = React.lazy(() => import('./views/BilanPatrimonial/AccueilPF/AccueilPF').catch(e => window.location.reload()));
const Succession = React.lazy(() => import('./views/DonationSuccession/Succession/Succession').catch(e => window.location.reload()));

// Box Immobilière
const AccueilImmo = React.lazy(() => import('./views/InvestissementsImmobiliers/InvestissementsImmobiliers').catch(e => window.location.reload()));
const Emprunt = React.lazy(() => import('./views/InvestissementsImmobiliers/Emprunt/Emprunt').catch(e => window.location.reload()));
const UsufruitEconomique = React.lazy(() => import('./views/InvestissementsImmobiliers/UsufruitEconomique/UsufruitEconomique').catch(e => window.location.reload()));
const PlusValueImmobiliere = React.lazy(() => import('./views/InvestissementsImmobiliers/PlusValueImmobiliere/PlusValueImmobiliere').catch(e => window.location.reload()));

const user = JSON.parse(localStorage.getItem('user'));
const menus = user?.menus || [];

// Tous les liens des simulateurs => BOX FINANCIÈRE
export const lienFinanciere = "/box-financiere";
export const lienSimulateurs = "/simulateurs";
export const lienPEA = `${lienSimulateurs}/pea`;
export const lienFormulairePEA = `${lienPEA}/formulaire`;
export const lienCompteTitres = `${lienSimulateurs}/ct`;
export const lienFormulaireCompteTitres = `${lienCompteTitres}/formulaire`;
export const lienAV = `${lienSimulateurs}/av`;
export const lienFormulaireAV = `${lienAV}/formulaire`;
export const lienCAPI = `${lienSimulateurs}/cp`;
export const lienFormulaireCAPI = `${lienCAPI}/formulaire`;
export const lienIS = `${lienSimulateurs}/is`;
export const lienFormulaireIS = `${lienIS}/formulaire`;
export const lienPropositionFinanciere = `${lienFinanciere}/proposition-financiere`;

export const lienComparaison = "/comparaisons";
export const lienFormulaireComparaison = `${lienComparaison}/formulaire`;
export const lienResultatFinancierComparaison = `${lienComparaison}/resultat_financier`;
export const lienResultatTransmissionComparaison = `${lienComparaison}/resultat_transmission`;

// Tous les liens CESSION DE SOCIÉTÉ
export const lienCessionSociete = "/cession-societe";
export const lienSimulateurCessionSociete = `${lienCessionSociete}/simulation`;
export const lienNoticeCalculCessionSociete = `${lienCessionSociete}/notice-calcul`;
export const lienAideCessionSociete = `${lienCessionSociete}/aide`;

// Tous les liens des simulateurs => BOX DONATION ET SUCCESSION
export const lienDonationSuccession = "/box-donation-succession";
export const lienUsufruitNuePropriete = `${lienDonationSuccession}/usufruit-nue-propriete`;
export const lienUsufruitViager = `${lienUsufruitNuePropriete}/usufruit-viager`;
export const lienUsufruitTemporaire = `${lienUsufruitNuePropriete}/usufruit-temporaire`;
export const lienDonation = `${lienDonationSuccession}/donation`;
export const lienDonsFamiliauxExoneres = `${lienDonation}/dons-familiaux-exoneres`;
export const lienDonationsEntreprise = `${lienDonation}/donations-entreprise`;
export const lienAutresDonations = `${lienDonation}/autres-donations`;
export const lienSuccession = `${lienDonationSuccession}/succession`;

// Tous les liens des simulateurs => BOX PATRIMOINE ET FISCALITÉ
export const lienPatrimoineFiscalite = "/box-patrimoine-fiscalite";
export const lienBilanPatrimonial = `${lienPatrimoineFiscalite}/bilan-patrimonial`;
export const lienFiscaliteRevenus = `${lienPatrimoineFiscalite}/fiscalite-revenus`;
export const lienIFI = `${lienPatrimoineFiscalite}/ifi`;

// Tous les liens des simulateurs => BOX IMMOBILIÉRE
export const lienImmobiliere = `/box-immobiliere`;
export const lienEmprunt = `${lienImmobiliere}/emprunt`;
export const lienUsufruitEconomique = `${lienImmobiliere}/usufruit-economique`;
export const lienPlusValueImmobiliere = `${lienImmobiliere}/plus-value-immobiliere`;

// Tous les liens des FICHES THÉMATIQUES
export const lienFicheThematique = `/fiches-thematiques`;
export const lienFicheThematiquePEA = `${lienFicheThematique}/pea`;
export const lienFicheThematiqueCompteTitres = `${lienFicheThematique}/compte-titres`;
export const lienFicheThematiqueAssuranceVie = `${lienFicheThematique}/assurance-vie`;
export const lienFicheThematiqueCapi = `${lienFicheThematique}/contrat-de-capitalisation`;
export const lienFicheThematiqueSocieteIS = `${lienFicheThematique}/societe-is`;
export const lienFicheThematiqueCessionSociete = `${lienFicheThematique}/cession-societe`;
export const lienFicheThematiqueDMTG = `${lienFicheThematique}/DMTG`;
export const lienFicheThematiqueClauseBeneficiaireAV = `${lienFicheThematique}/clause-beneficiaire-assurance-vie`;
export const lienFicheThematiqueClauseBeneficiaireDemembreeAV = `${lienFicheThematique}/clause-beneficiaire-demembree-assurance-vie`;
export const lienFicheThematiqueClauseBeneficiaireDemembreeAV990I = `${lienFicheThematique}/demembrement-de-la-clause-beneficiaire-et-prelevement-de-article-990I-du-CGI`;
export const lienFicheThematiqueIFI = `${lienFicheThematique}/IFI`;
export const lienFicheThematiqueRevLocatif = `${lienFicheThematique}/rev-locatif`;
export const lienFicheThematiqueStrategiesLimitationIR = `${lienFicheThematique}/strategies-limitation-ir`

// Tous les liens de la Fiche Client
export const lienListeClient = `/clients`;
export const lienFicheClient = `${lienListeClient}/fiche`;

// Autres liens
export const lienPageAccueilSimulabox = "/";
export const lienListeSimulations = "/simulations";
export const lienMentionsLegales = "/mentions"

// Liens indépendament des BOX
export const everywhereLinks = [
  { path: lienFicheThematique, name: 'Fiches thématiques', component: FichesThematiques, exact: true },
  { path: lienFicheThematiquePEA, name: 'PEA', component: Pea, exact: true },
  { path: lienFicheThematiqueCompteTitres, name: 'Compte titres', component: Ct, exact: true },
  { path: lienFicheThematiqueAssuranceVie, name: 'Assurance-vie', component: Av, exact: true },
  { path: lienFicheThematiqueCapi, name: 'Contrat de capitalisation', component: Capi, exact: true },
  { path: lienFicheThematiqueSocieteIS, name: "Société à l'IS", component: Is, exact: true },
  { path: lienFicheThematiqueCessionSociete, name: "Cession de société", component: Cs, exact: true },
  { path: lienFicheThematiqueDMTG, name: 'DMTG', component: DMTG, exact: true },
  { path: lienFicheThematiqueClauseBeneficiaireAV, name: "Clauses bénéficiaires des contrats d'assurance-vie", component: DMTGAV, exact: true },
  { path: lienFicheThematiqueClauseBeneficiaireDemembreeAV, name: "Clauses bénéficiaires démembrées des contrats d'assurance-vie", component: DMTGDemembreeAV, exact: true },
  { path: lienFicheThematiqueClauseBeneficiaireDemembreeAV990I, name: "Démembrement de la clause bénéficiaire et prélèvement de l’article 990I du CGI", component: DMTGAV990I, exact: true },
  { path: lienFicheThematiqueIFI, name: 'IFI', component: FTIFI, exact: true },
  { path: lienFicheThematiqueStrategiesLimitationIR, name: "Stratégies pour limiter l'IR", component: FicheThematiqueStrategieLimitationIR, exact: true },
  { path: lienFicheThematiqueRevLocatif, name: "Revenus locatifs", component: PVImmo, exact: true },
  { path: lienPageAccueilSimulabox, exact: true, name: 'Accueil', component: Home },
  { path: lienListeSimulations, name: 'Simulations', component: Simulations, exact: true },
  { path: lienMentionsLegales, name: 'Mentions Légales', component: Mentions, exact: true },
  { path: lienListeClient, name: 'Clients', component: Clients, exact: true },
  { path: lienFicheClient, name: 'Fiche client', component: FicheClient, exact: true },
  { path: `${lienFicheClient}/:id`, component: FicheClient, exact: true },
];

export const boxFinanciersLinks = [
  { path: '/accueilSCP', name: 'Investissements financiers', component: accueilSCP, exact: true },
  // SIMULATEUR et PEA
  { path: '/simulateurs', name: 'Simulateurs', component: Simulateurs, exact: true },
  { path: '/simulateurs/pea', name: 'PEA', component: PEA, exact: true },
  { path: '/simulateurs/pea/formulaire', name: 'Ajout Formulaire', component: FormulairePEA, exact: true },
  { path: '/simulateurs/pea/:id', name: 'Modification Formulaire', component: FormulairePEA, exact: true },

  // CT
  { path: '/simulateurs/ct', name: 'Compte titres', component: CT, exact: true },
  { path: '/simulateurs/ct/formulaire', name: 'Ajout Formulaire', component: FormulaireCT, exact: true },
  { path: '/simulateurs/ct/:id', name: 'Modification Formulaire', component: FormulaireCT, exact: true },

  // AV
  { path: '/simulateurs/av', name: 'Assurance-vie', component: AV, exact: true },
  { path: '/simulateurs/av/formulaire', name: 'Ajout Formulaire', component: FormulaireAV, exact: true },
  { path: '/simulateurs/av/:id', name: 'Modification Formulaire', component: FormulaireAV, exact: true },

  // CAPI
  { path: '/simulateurs/cp', name: 'Contrat de capitalisation', component: CP, exact: true },
  { path: '/simulateurs/cp/formulaire', name: 'Ajout Formulaire', component: FormulaireCP, exact: true },
  { path: '/simulateurs/cp/:id', name: 'Modification Formulaire', component: FormulaireCP, exact: true },

  // Société IS
  { path: '/simulateurs/is', name: "Société à l'IS", component: IS, exact: true },
  { path: '/simulateurs/is/formulaire', name: 'Ajout Formulaire', component: FormulaireIS, exact: true },
  { path: '/simulateurs/is/:id', name: 'Modification Formulaire', component: FormulaireIS, exact: true },

  // Comparaison
  { path: '/comparaisons', name: 'Comparaisons', component: Comparaisons, exact: true },
  { path: '/comparaisons/formulaire/:id', name: 'Modification Cmp Formulaire', component: FormulaireCMP, exact: true },
  { path: '/comparaisons/formulaire', name: 'Cmp Formulaire', component: FormulaireCMP, exact: true },
  { path: '/comparaisons/resultat_financier', name: 'Cmp Resultats Simulations Financières', component: ResultatsCMPF, exact: true },
  { path: '/comparaisons/resultat_transmission', name: 'Cmp Resultats Simulations de Transmission', component: ResultatsCMPT, exact: true },
  { path: '/comparaison-structures-aide', name: 'Aide', component: AideComparaisonStructures, exact: true },

  // Proposition financière
  { path: '/proposition-financiere', name: "Proposition financière", exact: true, component: PropositionFinanciere },
  { path: '/proposition-financiere/:id', exact: true, component: PropositionFinanciere },
  { path: '/accueil-proposition-financiere', name: "Proposition financière", exact: true, component: AccueilPropositionFinanciere },
  { path: '/proposition-financiere-aide', name: 'Aide', exact: true, component: AccueilPropositionFinanciere }
];

// BOX Cession de société
export const boxCessionSocieteLinks = [
  { path: lienCessionSociete, name: "Cession de société", component: AccueilCessionSociete, exact: true },
  { path: lienSimulateurCessionSociete, name: "Simulateur", component: SimulateurCessionSociete, exact: true },
  { path: `${lienSimulateurCessionSociete}/:id`, component: SimulateurCessionSociete, exact: true },
  { path: lienNoticeCalculCessionSociete, name: "Documentation", component: NoticeCalculCessionSociete, exact: true },
  { path: lienAideCessionSociete, name: "Aide", component: AideCessionSociete, exact: true },
];

// Box Donation et Succession
export const boxDonationsSuccessionLinks = [
  { path: lienDonationSuccession, name: "Donation et succession", component: AccueilDSU, exact: true },

  // Liens pour Usufruit et nue-propriété
  { path: lienUsufruitNuePropriete, name: 'Usufruit et nue-propriété', component: USUFNPAccueil, exact: true },
  { path: lienUsufruitViager, name: 'Usufruit Viager', component: UsufruitViager, exact: true },
  { path: `${lienUsufruitViager}/:id`, component: UsufruitViager, exact: true },
  { path: lienUsufruitTemporaire, name: 'Usufruit Temporaire', component: UsufruitTemporaire, exact: true },
  { path: `${lienUsufruitTemporaire}/:id`, component: UsufruitTemporaire, exact: true },

  // Liens pour Donation
  { path: lienDonation, name: 'Donation', component: DonationAccueil, exact: true },
  { path: lienDonsFamiliauxExoneres, name: "Dons familiaux exonérés", component: DonsFamiliauxExoneres, exact: true },
  { path: `${lienDonsFamiliauxExoneres}/:id`, component: DonsFamiliauxExoneres, exact: true },
  { path: lienDonationsEntreprise, name: "Donations d'entreprises", component: DonationsEntreprise, exact: true },
  { path: `${lienDonationsEntreprise}/:id`, component: DonationsEntreprise, exact: true },
  { path: lienAutresDonations, name: "Autres donations", component: DonationsOrdinaires, exact: true },
  { path: `${lienAutresDonations}/:id`, component: DonationsOrdinaires, exact: true },

  //Lien pour la succession
  { path: lienSuccession, name: 'Succession', component: Succession, exact: true },
  { path: `${lienSuccession}/:id`, component: Succession, exact: true }

];

// Box Patrimoine et Fiscalité
export const bilanPatrimonialLinks = [
  { path: lienPatrimoineFiscalite, name: "Patrimoine et fiscalité", component: AccueilPF, exact: true },
  { path: lienBilanPatrimonial, name: 'Bilan patrimonial', component: MyTabs, exact: true },
  { path: `${lienBilanPatrimonial}/:id`, component: MyTabs, exact: true },
  { path: lienFiscaliteRevenus, name: 'Fiscalité des revenus', component: FiscaliteRevenu, exact: true },
  { path: `${lienFiscaliteRevenus}/:id`, component: FiscaliteRevenu, exact: true },
  { path: lienIFI, name: 'IFI', component: IFI, exact: true },
  { path: `${lienIFI}/:id`, component: IFI, exact: true }
]

// Box Immobilière
export const boxImmobilierLinks = [
  { path: lienImmobiliere, name: "Investissements immobiliers", component: AccueilImmo, exact: true },
  { path: lienEmprunt, name: 'Emprunt', component: Emprunt, exact: true },
  { path: `${lienEmprunt}/:id`, component: Emprunt, exact: true },
  { path: lienUsufruitEconomique, name: 'Usufruit économique', component: UsufruitEconomique, exact: true },
  { path: `${lienUsufruitEconomique}/:id`, component: UsufruitEconomique, exact: true },
  { path: lienPlusValueImmobiliere, name: 'Plus-value immobilière', component: PlusValueImmobiliere, exact: true },
  { path: `${lienPlusValueImmobiliere}/:id`, component: PlusValueImmobiliere, exact: true }
]

const routes = [
  ...everywhereLinks
];

// S'il y a la BOX Investissements financiers
if (menus.find(element => parseInt(element.ordre) === 3)) {
  routes.push(
    ...boxFinanciersLinks
  )
}

// S'il y a la BOX Cession de société
if (menus.find(element => parseInt(element.ordre) === 5)) {
  routes.push(
    ...boxCessionSocieteLinks
  )
}

// S'il y a la BOX Donations et Succession

if (menus.find(element => parseInt(element.ordre) === 2)) {
  routes.push(
    ...boxDonationsSuccessionLinks
  );
}

// S'il y a la Box Patrimoine et Fiscalité
if (menus.find(element => parseInt(element.ordre) === 1)) {
  routes.push(
    ...bilanPatrimonialLinks
  )

}

// if(condition){
  routes.push(
    ...boxImmobilierLinks
  )
// }

export default routes;
