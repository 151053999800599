import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: "#FFFFFF",
      color: '#1D51A1',
      maxWidth: 320,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #1D51A1'
    },
}))(Tooltip);

export const HtmlTooltipError = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#FFFFFF",
    color: 'rgb(229,83,83)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid rgb(229,83,83)'
  },
}))(Tooltip);

export default HtmlTooltip;