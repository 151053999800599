import PPTX from 'pptxgenjs';
import {
    addTitleWithChartIcon,
    definePageNumber,
    completeDate,
    now,
    fetchPPTPersonnalise,
    blackColor,
    numberFormat,
    generateLastPage,
    getInfoClient
} from '../../all_ppt_render';
import { textPropsUsuf } from './dimension';
import generateGraph from './generateGraph';
import generateTableWithOneColumn from './generateTableWithOneColumn';
import generateTableWithTwoColumns from './generateTableWithTwoColumns';
import infoImage from '../../../assets/images/info2_gris_clair.png';
import { currencyToSend } from '../../../views/DonationSuccession/UsufruitNP/UsufruitViager/UsufruitViager';
import { defineContactSlideMaster, defineLentionSlideMaster, defineSlideMaster, defineTitleSlideMaster, generateFirstSlideTitle, generateTitleAndSubtitle } from '../../utils';

const exportUsufruitViager = async (
    profil,
    simulateurState,
    rows,
    rows2,
    rows3,
    circleGrapheData
) => {
    const pptx = new PPTX();
    await defineSlideMaster(pptx);
    await defineTitleSlideMaster(pptx);
    await defineContactSlideMaster(pptx, "");
    await defineLentionSlideMaster(pptx, "");

    // Traitement SLIDE1
    const slide1 = pptx.addSlide({ masterName: 'TITLE_SLIDE' });

    const slide1Title = [
        { text: "DROITS DE MUTATION À TITRE GRATUIT", options: { breakLine: true } },

    ];

    const slide1Subtitle = [
      { text: "Valorisation fiscale de l’usufruit viager", options: { breakLine: true } }
    ]

    generateFirstSlideTitle(
        pptx,
        slide1,
        slide1Title,
        0.97,
        2.18,
        "SIMULATION",
        0.97,
        8.12,
        slide1Subtitle
    )

    // Traitement SLIDE X
    const slidex = pptx.addSlide({ masterName: 'MASTER_SLIDE' });

    generateTitleAndSubtitle(
        'SIMULATION',
        pptx,
        slidex,
        undefined,
        undefined,
        undefined,
        {
            text: "VALORISATION FISCALE DE L'USUFRUIT VIAGER",
            width: 6
        }
    )

    const ySlideX = 1.7;

    // Couleur degradé
    slidex.addText("", {
        x: 0.7,
        y: ySlideX,
        w: 0.25,
        h: "14%",
        fill: "0070C0"
    });

    // Cadre gris
    slidex.addText("", {
        x: 0.76,
        y: ySlideX,
        w: "39%",
        h: "14%",
        fill: 'FAFAFA'
    });

    // Barre rose
    slidex.addText("", {
        x: 6,
        y: ySlideX,
        w: 0.09,
        h: 4.3,
        fill: "0070C0",
        line: {
            color: '0070C0'
        }
    });

    slidex.addText("Age de l'usufruitier", {
        x: 0.84,
        y: ySlideX + 0.2,
        w: "25%",
        h: "4%",
        fontSize: 12,
        bold: true,
        color: blackColor,
        align: 'left',
        valign: 'middle',
        fontFace: 'Roboto'
    });

    // Durée de l'usufruit
    slidex.addText(simulateurState && simulateurState.firstForm && simulateurState.firstForm.value !== "" ? simulateurState.firstForm.value : "", {
        x: 4.27,
        y: ySlideX + 0.2,
        w: "7.5%",
        h: "4%",
        align: 'right',
        valign: 'middle',
        bold: true,
        fontSize: 12,
        fill: 'FFFFFF',
        fontFace: 'Roboto',
        line: {
            color: 'D8D8D8'
        }
    });

    slidex.addText("Contre-valeur en PP", {
        x: 0.84,
        y: ySlideX + 0.6,
        w: "25%",
        bold: true,
        color: blackColor,
        h: "4%",
        fontSize: 12,
        align: 'left',
        valign: 'middle',
        fontFace: 'Roboto'
    });

    // Contre-valeur en PP
    slidex.addText(`${simulateurState && simulateurState.lastForm && simulateurState.lastForm.value !== "" ? numberFormat(currencyToSend(simulateurState.lastForm.value)) : "- "} €`, {
        x: 3.4,
        y: ySlideX + 0.6,
        w: "15%",
        h: "4%",
        align: 'right',
        valign: 'middle',
        fontSize: 12,
        bold: true,
        fill: 'FFFFFF',
        fontFace: 'Roboto',
        line: {
            color: 'D8D8D8'
        }
    });

    // Cadre Orange
    slidex.addText("", {
        x: 0.7,
        y: ySlideX + 1.5,
        w: 4.7,
        h: 2.8,
        fontFace: 'Roboto',
        fill: 'F2F2F2'
    });

    generateGraph(pptx, slidex, 0.7, ySlideX + 1.5, 4.7, 2.8, circleGrapheData[0].label, circleGrapheData[1].label, circleGrapheData[0].value, circleGrapheData[1].value, "44546A", "4472C4", "Valorisations fiscales", circleGrapheData[0].realValue, circleGrapheData[1].realValue);
    generateTableWithOneColumn(rows, slidex, 6.15, 1.7, '15%', "Age de l'usufruitier", 'USUF_TEMPORAIRE');
    generateTableWithTwoColumns(rows2, slidex, 8, 1.7, '13%', [{ text: "Valeur fiscale de ", options:{ breakLine: true } }, { text: "l'Usufruit" }], 'USUF_TEMPORAIRE');
    generateTableWithTwoColumns(rows3, slidex, 9.6, 1.7, '13%', [{ text: "Valeur fiscale de ", options: { breakLine: true } }, { text: "la nue-propriété" }], 'USUF_TEMPORAIRE' );

    const yInformation = 6.2;
    const information = [
        { text: "La valorisation fiscale de l'usufruit", options: { breakLine: false, bold: true, underline: true } },
        { text: " et de la nue-propriété est ", options: { breakLine: false } },
        { text: "obligatoire ", options: { breakLine: false, bold: true }  },
        { text: "pour l'ensemble des droits d'enregistrement et notamment pour le calcul de la valeur imposable aux droits de donation et de succession. Elle est déterminée en application du barème de l'article 669 du Code Général des Impôts(CGI) en fonction de l'âge de l'usufruitier s'agissant d'un usufruit viager. Pour les opérations à titre onéreux (vente, apport, remboursement, etc), la valorisation fiscale peut servir de référence mais elle n'est pas obligatoire et une valorisation économique est souvent privilégiée.", options: { breakLine: false } }
    ];

    slidex.addText("", {
        x: 0.7,
        y: yInformation,
        w: "7.3%",
        h: "12.5%",
        fill: 'E6E9EF',
        line: {
            color: 'E6E9EF'
        }
    });

    slidex.addImage({
        path: infoImage,
        x: 0.85,
        y: yInformation + 0.3,
        w: 0.5,
        h: 0.5
    });

    slidex.addText(information, {
        x: 1.56,
        y: yInformation,
        fill: 'E6E9EF',
        w: '82%',
        h: '12.5%',
        fontSize: 11,
        color: '4D5C71',
        fontFace: 'Roboto'
    });

    // Dernière page
    const lastSlide = pptx.addSlide({ masterName: 'CONTACT_SLIDE' });

    // profil = await getInfoClient();

    generateLastPage(pptx, lastSlide, profil);

    // Export et téléchargement automatique du fichier de simulation
    pptx.writeFile("Usufruit_Viager_" + completeDate(now) + ".pptx");
};

export default exportUsufruitViager;
