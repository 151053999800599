import { currencyToSend } from "../../../../store/utilsFunc";

const actifProfessionnel = {
    open: true,
    rotate: false,
    intitule: "",
    form1: '',// à supprimer
    form2: '',// à supprimer
    droitPropriete: 0,
    eligibiliteDutreil: 0,
    valeurFiscale: 0,
    bienPersonnelConjoint1PP: 0,
    bienCommunPP: 0,
    bienPersonnelConjoint2PP: 0,
    dontEligibleDutreilConjoint1: 0,
    dontEligibleDutreilCommun: 0,
    dontEligibleDutreilConjoint2: 0
}

const calculDontEligibleDutreil = (pourcentageDutreil, somme) => {
    if (pourcentageDutreil === '') pourcentageDutreil = 0;
    else pourcentageDutreil = parseFloat(pourcentageDutreil);

    return parseFloat((pourcentageDutreil * (currencyToSend(somme) || 0) ) / 100);
};

export const calculTaux = (sommeTotale, somme) => {
    const currencySomme = currencyToSend(somme) || 0;
    const currencySommeTotale = currencyToSend(sommeTotale) || 0;

    let value = ((currencySomme * 100) / (currencySommeTotale));
    const resp = !isNaN(value) && value != null ? Math.round(value) : 0;

    return resp;
};

export const calculProfessionnel = (structure) => {
    const { affichage, patrimoineGlobal, professionnel } = structure;
    const { situationFamiliale } = patrimoineGlobal;
    const yes = affichage === 1; // Affichage Global
    const yesConjoint1 = affichage === 2; // Affichage Conjoint 1
    const yesConjoint2 = affichage === 3; // Affichage Conjoint 2
    const celibataire = ![1, 2, 3].includes(Number(situationFamiliale));
    const conditionMarie = yesConjoint1 && !celibataire;
    const conditionConjoint1 = conditionMarie || yes;

    // Traitement
    const { actifsProfessionnels } = professionnel;
    const actifs = [];
    let sommeDutreil = 0;
    let sommeValeurPP = 0;
    let sommeConjoint1 = 0;
    let sommeConjoint2 = 0;

    actifsProfessionnels.map((data, index) => {
        /**************** CALCUL SUR L'ACTIF**************************** */

        let total = 0;

        let dutreil = {
            conjoint1: 0,
            commun: 0,
            conjoint2: 0
        };

        // Cas Global ou Conjoint 1
        if(yes || yesConjoint1){
            total += currencyToSend(data.bienPersonnelConjoint1PP) || 0;
        }

        // Données communes Cas Global
        if(yes){
            total += currencyToSend(data.bienCommunPP) || 0;
        }

        //Données communes Cas Conjoint 1 ou Conjoint 2
        if(conditionMarie || yesConjoint2){
            total += (currencyToSend(data.bienCommunPP) || 0) /2;
        }

        // Cas Global ou Conjoint 2
        if (yes || yesConjoint2){
            total += currencyToSend(data.bienPersonnelConjoint2PP) || 0;
        }

        /****************CALCUL sur la SYNTHESE************************* */
        // Cas Global ou Conjoint 1.
        if(yes || yesConjoint1){
            dutreil.conjoint1 += calculDontEligibleDutreil(data.eligibiliteDutreil, data.bienPersonnelConjoint1PP);
            sommeDutreil += calculDontEligibleDutreil(data.eligibiliteDutreil, data.bienPersonnelConjoint1PP);
            sommeValeurPP += currencyToSend(data.bienPersonnelConjoint1PP) || 0;
            sommeConjoint1 += currencyToSend(data.bienPersonnelConjoint1PP) || 0;
            if(conditionConjoint1){
                sommeConjoint1 += (currencyToSend(data.bienCommunPP) || 0) / 2;
            }
        }

        // Données communes Cas Global
        if(yes){
            dutreil.commun += calculDontEligibleDutreil(data.eligibiliteDutreil, data.bienCommunPP);
            sommeDutreil += calculDontEligibleDutreil(data.eligibiliteDutreil, data.bienCommunPP);
            sommeValeurPP += currencyToSend(data.bienCommunPP) || 0;
        }

        // Données communes Cas Conjoint 1 ou Conjoint 2
        if(conditionMarie || yesConjoint2){
            dutreil.commun += calculDontEligibleDutreil(data.eligibiliteDutreil, data.bienCommunPP)/2;
            sommeDutreil += calculDontEligibleDutreil(data.eligibiliteDutreil, data.bienCommunPP)/2;
            sommeValeurPP += (currencyToSend(data.bienCommunPP) || 0)/2;
        }

        // Cas Global ou Conjoint 2.
        if(yes || yesConjoint2){
            dutreil.conjoint2 += calculDontEligibleDutreil(data.eligibiliteDutreil, data.bienPersonnelConjoint2PP);
            sommeDutreil += calculDontEligibleDutreil(data.eligibiliteDutreil, data.bienPersonnelConjoint2PP);
            sommeValeurPP += currencyToSend(data.bienPersonnelConjoint2PP) || 0;
            sommeConjoint2 += currencyToSend(data.bienPersonnelConjoint2PP) || 0;
            sommeConjoint2 += (currencyToSend(data.bienCommunPP) || 0) / 2;
        }

        actifs.push({
            dutreil: dutreil,
            valorisation: total,
            droitPropriete: data.droitPropriete
        })
    });

    // Valorisation
    const valorisation = sommeValeurPP;
    const valorisationActifDutreil = sommeDutreil;
    const valorisationAutresActifsProfessionnels = sommeValeurPP - sommeDutreil;

    // Taux des valorisations et taux des Conjoints
    const tauxActifDutreil = calculTaux(valorisation, valorisationActifDutreil);
    const tauxAutresActifsProfessionnels = calculTaux(valorisation, valorisationAutresActifsProfessionnels);
    const tauxConjoint1 = calculTaux(valorisation, sommeConjoint1);
    const tauxConjoint2 = calculTaux(valorisation, sommeConjoint2);

    return {
        valorisation,
        valorisationActifDutreil,
        valorisationAutresActifsProfessionnels,
        valorisationConjoint1: sommeConjoint1,
        valorisationConjoint2: sommeConjoint2,
        tauxActifDutreil,
        tauxAutresActifsProfessionnels,
        tauxConjoint1,
        tauxConjoint2,
        actifs
    }
}
