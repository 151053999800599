import React, { useEffect } from 'react';
import { elementsPerPage } from '../../extra/utils';
import { useState } from 'react';
import './style.scss';
import Fleche from './Fleche/Fleche';
import ButtonWithAction from '../Button/ButtonWithAction';
import { OrdinaryTooltip } from '../InfoBulle/Tooltip/Tooltip';
import ModalConfirmation from '../ModalConfirmation/ModalConfirmation';
import FlecheTri from '../../assets/images/fleche-tri-tableau.svg';
import { CImg } from '@coreui/react';
import { dateParOrdreAntiChronologique, dateParOrdreChronologique, elementsParOrdreAlphabetique, elementsParOrdreAntiAlphabetique } from '../../utils';
import Eye from './../Eye/Eye';
import { numberFormat } from '../../extra/all_ppt_render';

const TableElements = ({
    type,
    nombreElementsParPage,
    columns,
    lignes,
    onRequestRowClick,
    onRequestEdit,
    onRequestClone,
    onRequestDelete,
    onRequestVisualise,
    actualPageNumber = 1,
    onEmitPageChange
}) => {
    lignes = lignes?.map((ligne, index) => { return { ...ligne, numeroLigne: index + 1 } })
    const [rows, setRows] = useState(lignes);
    const [currentPage, setCurrentPage] = useState(actualPageNumber);
    const [compteursElements, setCompteursElements] = useState([]);
    const [elementsPagination, setElementsPagination] = useState([]);
    const [lastPageNumber, setLastPageNumber] = useState(Math.ceil(rows.length / nombreElementsParPage));
    const [modalVisible, setModalVisible] = useState(false);
    const [elementToDelete, setElementToDelete] = useState(null);
    const [triages, setTriages] = useState([]);

    const elements = elementsPerPage(rows, nombreElementsParPage);

    // à chaque changement de LIGNES
    useEffect(() => {
        setRows(lignes);
    }, [JSON.stringify(lignes)])

    // Les triages
    useEffect(() => {
        const tabs = [];

        columns.map((column, i) => {
            tabs.push(type === 'client' && i === 0 ? true : null)
        })

        setTriages(tabs);
    }, [])

    // Dernière page
    useEffect(() => {
        setCurrentPage(1);
        setLastPageNumber(Math.ceil(rows.length / nombreElementsParPage));
    }, [JSON.stringify(elements)])

    // Les compteurs
    useEffect(() => {
        const compteurs = [];

        if (elements?.length >= 5) {
            elements.map((element, index) => {
                if (index !== 0 && index % 5 === 0) compteurs.push(index);
            })
        }
        else {
            const tabs = [];

            if (elements.length === 1) tabs.push(5);

            if (elements.length === 2) tabs.push(5, 10);

            if (elements.length === 3) tabs.push(5, 10, 15);

            if (elements.length === 4) tabs.push(5, 10, 15, 20);

            compteurs.push(...tabs);
        }

        if (elements?.length > compteurs?.[compteurs?.length - 1]) {
            compteurs.push(compteurs[compteurs.length - 1] + 5);
        }

        setCompteursElements(compteurs);
    }, [JSON.stringify(elements)])

    // Les éléments en chiffre
    useEffect(() => {
        const elems = [];

        compteursElements.map((element, index) => {
            let limite = (element - 5) + 1;

            if (currentPage <= element && currentPage >= limite) {
                for (let i = limite; i <= element; i++) {
                    if (elements[i - 1] !== undefined) {
                        elems.push(i);
                    }
                }
            }
        })

        if (elems.length > 0) {
            setElementsPagination(elems)
        };
    }, [
        currentPage,
        JSON.stringify(compteursElements)
    ])

    // Edition
    const handleEdit = (element) => {
        if (onRequestEdit) {
            onRequestEdit(element)
        }
    }

    // Duplication
    const handleClone = (element) => {
        if (onRequestClone) {
            onRequestClone(element)
        }
    }

    // Suppression
    const handleDelete = () => {
        if (onRequestDelete && elementToDelete) {
            onRequestDelete(elementToDelete)
        }
    }

    // Triage
    const handleSort = (index) => {
        const tris = JSON.parse(JSON.stringify(triages));
        const column = columns?.[index] || {};
        const isDate = column.isDate || false;
        const key = column.key || null;

        tris[index] = !tris[index];

        // Si c'est par ordre alphabétique ou par ordre chronologique
        if (tris[index]) {
            if (isDate) {
                setRows(dateParOrdreChronologique(rows, key));
            }
            else {
                setRows(elementsParOrdreAlphabetique(rows, key));
            }
        }

        // Si c'est par ordre anti-alphabétique ou par ordre anti-chronologique
        if (tris[index] === false) {
            if (isDate) {
                setRows(dateParOrdreAntiChronologique(rows, key));
            }
            else {
                setRows(elementsParOrdreAntiAlphabetique(rows, key));
            }
        }

        // Réinitialisation des autres tris
        tris.map((tri, i) => {
            if (index !== i) {
                tris[i] = false;
            }
        })

        setTriages(tris);
    }

    const onPageChange = (value) => {
      setCurrentPage(value);
      if(onEmitPageChange) {
        onEmitPageChange(value)
      }
    }

    const elementsActuels = elements?.[currentPage - 1] || [];
    const premierNumero = elementsActuels?.[0]?.numeroLigne || 0;
    const dernierNumero = elementsActuels?.[elementsActuels?.length - 1]?.numeroLigne || 0;
    const nombreElements = lignes?.length || 0;

    return (
        <>
            <div className='tableau-elements'>
                <ModalConfirmation
                    visible={modalVisible}
                    setVisible={setModalVisible}
                    text="Êtes-vous sûr de vouloir supprimer cet enregistrement ?"
                    color='#556382'
                    background='#EBF2F5'
                    onOKClick={handleDelete}
                />
                {/** Titre du tableau */}
                <div className='titre'>
                    {
                        columns.map((column, i) => {
                            let isLastColumn = i === columns.length - 1;

                            return (
                                <div style={{ display: 'flex', justifyContent: `flex-${isLastColumn ? 'end' : 'start'}`, width: column.width, marginLeft: i === 0 ? 10.5 : 0 }} className='colonne-titre-tableau' key={`ColonneTableau${i + 1}`}>
                                    <div style={{ display: 'flex' }}>
                                        <div>{column.label}</div>
                                        {
                                            column.sorted && (
                                                <OrdinaryTooltip arrow placement='top' title={`Trier par ordre ${triages?.[i] === true ? 'anti-' : ''}${column.isDate ? 'chronologique' : 'alphabétique'}`}>
                                                    <div style={{ marginLeft: 10, fontWeight: 'bold', cursor: 'pointer' }} onClick={() => handleSort(i)}>
                                                        <CImg src={FlecheTri} width={15} style={{ transition: 'transform 150ms ease', transform: `rotate(${triages?.[i] ? '180deg' : '0'})` }} />
                                                    </div>
                                                </OrdinaryTooltip>
                                            )
                                        }
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>

                {/** Contenu du tableau */}
                <div style={{ marginBottom: 10 }}>
                    {
                        elements?.[currentPage - 1]?.map((element, index) => {
                            return (
                                <div key={`LigneValeur${index + 1}`} className={`ligne-tableau ${type === 'client' ? 'ligne-tableau-type-client' : type === 'fiche-thematique' ? 'ligne-tableau-type-fiche-thematique' : 'ligne-tableau-type-simulation'}`}>
                                    {
                                        columns.map((column, i) => {
                                            let value = element?.[column.key] || "";
                                            let isLastColumn = columns.length - 1 === i;

                                            return !isLastColumn ? (
                                                <div key={`ColonneValeur${i + 1}${index + 1}`} className='colonne-valeur-tableau' style={{ width: column.width, marginLeft: i === 0 ? 10.5 : 0, overflowWrap: 'anywhere' }} onClick={() => onRequestRowClick(element)}>
                                                    {
                                                        column.elementWithCadre ? (
                                                            <div className='element-with-cadre'>
                                                                <div className='bulle-ronde'></div>
                                                                <div>{column?.value(value)}</div>
                                                            </div>
                                                        ) :
                                                            column.formatter ? column.formatter(value) : value
                                                    }
                                                </div>
                                            ) : (
                                                <div key={`ActionLigne${i + 1}`} className='colonne-valeur-tableau' style={{ width: column.width, display: 'flex', justifyContent: 'flex-end' }} >
                                                    {
                                                        (onRequestVisualise !== undefined) && (
                                                            <Eye
                                                                onRequestClick={() => onRequestVisualise(element)}
                                                            />
                                                        )
                                                    }
                                                    {
                                                        (onRequestEdit !== undefined) && (
                                                            <div style={{ marginRight: 20 }}>
                                                                <ButtonWithAction
                                                                    type='EDIT'
                                                                    onRequestClick={() => handleEdit(element)}
                                                                />
                                                            </div>
                                                        )
                                                    }
                                                    {
                                                        (onRequestClone !== undefined) && (
                                                            <div style={{ marginRight: 20 }}>
                                                                <ButtonWithAction
                                                                    type='CLONE'
                                                                    onRequestClick={() => handleClone(element)}
                                                                />
                                                            </div>
                                                        )
                                                    }
                                                    {
                                                        (onRequestDelete !== undefined) && (
                                                            <ButtonWithAction
                                                                type='DELETE'
                                                                onRequestClick={() => {
                                                                    setElementToDelete(element);
                                                                    setModalVisible(!modalVisible)
                                                                }}
                                                            />
                                                        )
                                                    }
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            )
                        })
                    }
                </div>

                {/** Pagination */}
                {/*
                elementsPagination?.length > 0 && (
                    <div className='racine-pagination'>
                        <div className='contenu-pagination'>
                            <Fleche hidden={currentPage === 1} type='first-page' onRequestClick={() => setCurrentPage(1)} />
                            <Fleche hidden={currentPage === 1} type='previous' onRequestClick={() => setCurrentPage(currentPage - 1)} />
                            {
                                elementsPagination?.map((element, index) => {
                                    return (
                                        <div
                                            key={`ElementPagination${element}`}
                                            className={`${currentPage === element ? 'element-pagination-active' : 'element-pagination'}`}
                                            onClick={() => {
                                                if (currentPage !== element) {
                                                    setCurrentPage(element);
                                                }
                                            }}
                                        >{element}</div>
                                    )
                                })
                            }
                            <Fleche hidden={currentPage === lastPageNumber} type='next' onRequestClick={() => setCurrentPage(currentPage + 1)} />
                            <Fleche hidden={currentPage === lastPageNumber} type='last-page' onRequestClick={() => setCurrentPage(lastPageNumber)} />
                        </div>
                    </div>
                )
            */}
            </div>

            {/** Pagination => Dans le footer */}
            {
                lignes?.length > 0 && (
                    <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                        <div style={{ marginRight: '15px', fontWeight: 'bold' }}>{`${premierNumero}-${dernierNumero} sur ${numberFormat(nombreElements)}`}</div>
                        <div className='contenu-pagination-tableau-liste-elements'>
                            <Fleche hidden={currentPage === 1} type='first-page' onRequestClick={() => onPageChange(1)} />
                            <Fleche hidden={currentPage === 1} type='previous' onRequestClick={() => onPageChange(currentPage - 1)} />
                            <Fleche hidden={currentPage === lastPageNumber} type='next' onRequestClick={() => onPageChange(currentPage + 1)} />
                            <Fleche hidden={currentPage === lastPageNumber} type='last-page' onRequestClick={() => onPageChange(lastPageNumber)} />
                        </div>
                    </div>
                )
            }
        </>
    )
}

export default TableElements;
