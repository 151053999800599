import React, { useContext, useEffect, useState } from 'react';
import BlocComparaison from './BlocComparaison/BlocComparaison';
import { DataControlContext } from '../../../../store/context/dataControl';

const styleParentBorder = { width: '2%', height: '430px', display: 'flex', justifyContent: 'center', marginTop: 120 };
const styleChildrenBorder = { width: '15%', borderLeft: '2px dashed rgb(212,212,212)' }

const Comparaison = ({ hypothese, resultat }) => {
    const { pvImmobiliereState } = useContext(DataControlContext);
    const { config } = pvImmobiliereState;
    const [ emoji, setEmoji ] = useState([
        {
            type: 0,
            valeur: 0,
            src: null
        },
        {
            type: 1,
            valeur: 0,
            src: null
        },
        {
            type: 2,
            valeur: 0,
            src: null
        },
    ])
    
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        let prixCession = [
            {
                type: 0,
                valeur: resultat.cession_directe && resultat.cession_directe.prix_net_cession ? resultat.cession_directe.prix_net_cession : 0,
            },
            {
                type: 1,
                valeur: resultat.cession_indirecte && resultat.cession_indirecte.prix_net_cession ? resultat.cession_indirecte.prix_net_cession : 0
            },
            {
                type: 2,
                valeur: resultat.cession_sci_ir && resultat.cession_sci_ir.prix_net_cession ? resultat.cession_sci_ir.prix_net_cession : 0
            }
        ];

        prixCession = prixCession.sort((a, b) => { 
            if(a.valeur > b.valeur) return -1;
            else return 1; 
        } 
        );

        prixCession.map((donnee, indice) => {
            if(indice === 0) prixCession[indice].src = 'HAUT';
            else if(indice === 1) prixCession[indice].src = 'INTERMEDIAIRE';
            else prixCession[indice].src = 'BAS';
        })

        if(config.cession_directe === 0) prixCession = prixCession.filter((element) => element.type !== 0 );
        if(config.cession_indirecte === 0) prixCession = prixCession.filter((element) => element.type !== 1 );
        if(config.cession_sci_ir === 0) prixCession = prixCession.filter((element) => element.type !== 2 );

        // if(prixCession.length > 1){
            if(prixCession.length === 1){
                prixCession[0].src = 'HAUT';
            }
            
            if(prixCession.length === 2){
                prixCession.map((donnee, indice) => {
                    if(indice === 0) prixCession[indice].src = 'HAUT';
                    else prixCession[indice].src = 'BAS';
                })
    
                if(prixCession[0].valeur === prixCession[1].valeur){
                    prixCession[0].src = null;
                    prixCession[1].src = null;
                }
            }
    
            if(prixCession.length === 3){
                if(prixCession[0].valeur === prixCession[1].valeur){
                    if(prixCession[2].valeur === prixCession[1].valeur){
                        prixCession[0].src = null;
                        prixCession[1].src = null;
                        prixCession[2].src = null;}
                }
            }
    
            setEmoji(prixCession);
        // }
    }, [ JSON.stringify(resultat) ])

    return (
        <div style={{ width: '100%', display: 'flex' }} >
            {
                config.cession_directe === 1 && (
                    <>
                        <div style={{ width: '32%' }} >
                            <BlocComparaison
                                type={0}
                                data={resultat.cession_directe ? resultat.cession_directe : {}}
                                emoji={emoji.find((element) => element.type === 0 ).src}
                                hypothese={hypothese.form1}
                            />
                        </div>
                        {
                            (config.cession_indirecte === 1 || config.cession_sci_ir === 1) && (
                                <div style={styleParentBorder} >
                                    <div style={styleChildrenBorder} ></div>
                                </div>
                            )
                        }
                    </>
                )
            }
            {
                config.cession_indirecte === 1 && (
                    <>
                        <div style={{ width: '32%' }} >
                            <BlocComparaison
                                type={1}
                                data={resultat.cession_indirecte ? resultat.cession_indirecte : {}}
                                emoji={emoji.find((element) => element.type === 1 ).src}
                                hypothese={hypothese.form2}
                            />
                        </div>
                        {
                            config.cession_sci_ir === 1 && (
                                <div style={styleParentBorder} >
                                    <div style={styleChildrenBorder} ></div>
                                </div>
                            )
                        }
                    </>
                )
            }
            {
                config.cession_sci_ir === 1 && (
                    <div style={{ width: '32%' }} >
                        <BlocComparaison
                            type={2}
                            data={resultat.cession_sci_ir ? resultat.cession_sci_ir : {}}
                            emoji={emoji.find((element) => element.type === 2 ).src}
                            hypothese={hypothese.form3}
                        />
                    </div>
                )
            }
        </div>
    )
}

export default Comparaison;