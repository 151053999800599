import React from 'react';
import loading from '../../assets/images/loader.gif';

const Loader = ({id = "loading"}) => (
    <div id={id} style={{position:'fixed', backgroundColor:'transparent', zIndex: 10000, display:'flex', justifyContent:'center', alignItems:'center', top: 0, left: 0, width:'100%', height:'100%'}}>
        <img alt="Patientez..." src={loading} style={{height: 150}}/>
    </div>
);

export default Loader;
