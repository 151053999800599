export const addToast = (payload) => {
    return {
        type: "ADD_TOAST",
        payload
    }
}

export const deleteToast = (payload) => {
    return {
        type: "DELETE_TOAST",
        payload
    }
}